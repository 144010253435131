export class LabelValue {
     label:string;
     value:any;
     public description?:string;
     public id?:number;
     public item?:any;
     constructor(data:any) {
        Object.assign(this, data);
        if (this.description){
            this.label = this.description;
        }
        if (this.id){
            this.value = this.id;
        }
     }
}
 