import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Tabs } from '@devinforius/super-compos/lib/models/tabs';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from 'src/app/core/services/api.service';
import { Contact } from '../../models/iContact/contact';
import { Point } from '../../models/point';
import { Vote } from '../../models/vote';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-votes',
  templateUrl: './votes.component.html',
  styleUrls: ['./votes.component.scss'],
})
export class VotesComponent {
  @Input() contacts: Array<Contact> = [];
  @Input() contactsAdmin: Array<Contact> = [];
  @Input() point: Point;
  @Input() set participants(value) {
    this.participantsPresentsCount = 0;
    this.votesNominatif =
      value && value.sort((a, b) => a.nonPresent - b.nonPresent || a.pasDeDroitDeVote - b.pasDeDroitDeVote);
    if (value && value.length > 0) {
      value.map((vote) => {
        if ((vote.nonPresent === false || vote.nonPresent === null) && !vote.pasDeDroitDeVote) {
          this.participantsPresentsCount += 1;
        }
        const positif = [];
        const negatif = [];
        const abstention = [];
        if (vote.voteAbstention) {
          this.voteArray = {
            abstention: abstention.push(vote.voteAbstention),
          };
        }
        if (vote.votePositif) {
          this.voteArray = {
            positif: positif.push(vote.votePositif),
          };
        }
        if (vote.voteNegatif) {
          this.voteArray = {
            negatif: negatif.push(vote.voteNegatif),
          };
        }
      });
    } else {
      this.voteArray = [];
    }
  }
  @Input() set votes(value) {
    this.voteObject = value;
    if (value) {
      this.allVotesCount =
        (value.nbrVotesGlobalAbstention ? value.nbrVotesGlobalAbstention : 0) +
        (value.nbrVotesGlobalNegatif ? value.nbrVotesGlobalNegatif : 0) +
        (value.nbrVotesGlobalPositif ? value.nbrVotesGlobalPositif : 0);
      if (this.voteObject?.voteType === 'PasDeVote') {
        this.activeTabVote = 0;
        this.tabChange(this.activeTabVote, true);
      }
      if (this.voteObject?.voteType === 'VoteNominatifGlobal') {
        this.activeTabVote = 1;
        this.tabChange(this.activeTabVote, true);
      }
      if (this.voteObject?.voteType === 'VoteNominatif') {
        this.activeTabVote = 2;
        this.tabChange(this.activeTabVote, true);
      }
      if (this.voteObject?.voteType === 'VoteScrutinSecret ') {
        this.activeTabVote = 3;
        this.tabChange(this.activeTabVote, true);
      }
    }
  }
  @Output() setVote: EventEmitter<any> = new EventEmitter();
  @Output() reloadParticipants: EventEmitter<any> = new EventEmitter();
  public participantsPresentsCount = 0;
  public allVotesCount = null;
  public voteArray: any = {
    positif: 0,
    abstention: 0,
    negatif: 0,
  };
  public votesNominatif: Array<Vote> = [];
  public voteObject: any;
  public votesColumns: any = [
    {
      field: 'contactName',
      header: this.translate.instant('contact'),
      format: (value, item) => {
        let contact;
        if (item.contactIdiAdmin) {
          contact = this.contactsAdmin.find((x) => x.id === item.contactIdiAdmin);
        } else if (item.contactId) {
          contact = this.contacts.find((x) => x.id === item.contactId);
        }
        if (contact) {
          return contact?.prenom + ' ' + contact?.nom;
        } else {
          return this.translate.instant('vote.noName');
        }
      },
    },
    {
      field: 'votePositif',
      header: this.translate.instant('vote.approuve'),
      format: (value, item) => {
        if (item.nonPresent || item.pasDeDroitDeVote) {
          return '';
        } else {
          return `<div class='vote__button ${'vote__button--' + (value ? value : false) + '--green'}'> Approuvé </div>`;
        }
      },
      click: (item) => {
        this.changeVote('votePositif', item);
      },
    },
    {
      field: 'voteAbstention',
      header: this.translate.instant('vote.abstention'),
      format: (value, item) => {
        if (item.nonPresent || item.pasDeDroitDeVote) {
          return '';
        } else {
          return `<div class='vote__button ${'vote__button--' + (value ? value : false) + '--orange'}'> Abstention </div>`;
        }
      },
      click: (item) => {
        this.changeVote('voteAbstention', item);
      },
    },
    {
      field: 'voteNegatif',
      header: this.translate.instant('vote.refuse'),
      format: (value, item) => {
        if (item.nonPresent || item.pasDeDroitDeVote) {
          return '';
        } else {
          return `<div class='vote__button ${'vote__button--' + (value ? value : false) + '--red'}'> Refusé </div>`;
        }
      },
      click: (item) => {
        this.changeVote('voteNegatif', item);
      },
    },
    {
      field: 'nonPresent',
      header: this.translate.instant('vote.present'),
      format: (value, item) => {
        return this.showBoolean(!item.nonPresent);
      },
    },
    {
      field: 'pasDeDroitDeVote',
      header: this.translate.instant('vote.voteRight'),
      format: (value, item) => {
        return this.showBoolean(!item.pasDeDroitDeVote);
      },
    },
  ];
  public activeTabVote: number = 1;
  public tabsVotes: Array<Tabs> = [
    { title: this.translate.instant('vote.noVote'), active: this.activeTabVote === 0, slug: 'PasDeVote', icon: '' },
    {
      title: this.translate.instant('sidebar.overall'),
      active: this.activeTabVote === 1,
      slug: 'VoteNominatifGlobal',
      icon: '',
    },
    {
      title: this.translate.instant('vote.normativeVote'),
      active: this.activeTabVote === 2,
      slug: 'VoteNominatif',
      icon: '',
    },
    {
      title: this.translate.instant('vote.secretBallot'),
      active: this.activeTabVote === 3,
      slug: 'VoteScrutinSecret',
      icon: '',
    },
  ];
  public form: FormGroup = new FormGroup({
    voteType: new FormControl('PasDeVote'),
    nbrVotesGlobalPositif: new FormControl(null),
    nbrVotesGlobalNegatif: new FormControl(null),
    nbrVotesGlobalAbstention: new FormControl(null),
    nbrVotesValables: new FormControl(null),
    nbrTours: new FormControl(null),
    nbrVotants: new FormControl(null),
    votes: new FormArray([
      new FormGroup({
        contactId: new FormControl(null),
        votePositif: new FormControl(null),
        voteAbstention: new FormControl(null),
        voteNegatif: new FormControl(null),
      }),
    ]),
  });
  constructor(
    public api: ApiService,
    public confirm: ConfirmationService,
    public toastr: ToastrService,
    public translate: TranslateService
  ) {}

  submitGlobalVote() {
    this.setVote.emit(this.form.value);
  }

  forceReloadParticipants() {
    this.reloadParticipants.emit(true);
  }

  changeVote(voteType: string, item) {
    const data = { voteType: 'VoteNominatif', votes: [] };
    if (item.nonPresent === '<b style="color: #FF5671;"><i class="bi bi-x-circle-fill"></i></b>') {
      this.toastr.error(this.translate.instant('vote.participantAbsentCannotUpdateVote'));
    } else if (item.pasDeDroitDeVote === '<b style="color: #FF5671;"><i class="bi bi-x-circle-fill"></i></b>') {
      this.toastr.error(this.translate.instant('vote.participantNoRightVoteCannotUpdate'));
    } else {
      if (voteType === 'votePositif') {
        data.votes = [{ participantId: item.id, [voteType]: !item.votePositifBool }];
      }
      if (voteType === 'voteAbstention') {
        data.votes = [{ participantId: item.id, [voteType]: !item.voteAbstentionBool }];
      }
      if (voteType === 'voteNegatif') {
        data.votes = [{ participantId: item.id, [voteType]: !item.voteNegatifBool }];
      }
      this.setVote.emit(data);
    }
  }

  tabChange(index, reloadData: boolean = false) {
    if (reloadData === true) {
      this.tabsVotes.find((tab) => tab.active === true).active = false;
      this.tabsVotes[this.activeTabVote].active = true;
    }
    // Si reloadData est a true, on doit garder la valeur des votes encodés et donc on ouvre juste le tab actif mais sans lancer de changeVoteType
    if (index === 0 || index === 'PasDeVote') {
      const activeTab = this.activeTabVote;
      this.confirm.confirm({
        message: this.translate.instant('vote.withoutVoting'),
        key: 'confirmsModal',
        accept: () => {
          this.changeVoteType('PasDeVote');
          this.activeTabVote = 0;
        },
        reject: () => {
          this.tabsVotes[0].active = false;
          this.tabsVotes[activeTab].active = true;
          this.activeTabVote = activeTab;
        },
      });
    }
    if (index === 1 || index === 'VoteNominatifGlobal') {
      // Vote global
      this.form.patchValue({
        nbrVotesGlobalPositif: this.voteObject?.nbrVotesGlobalPositif,
        nbrVotesGlobalNegatif: this.voteObject?.nbrVotesGlobalNegatif,
        nbrVotesGlobalAbstention: this.voteObject?.nbrVotesGlobalAbstention,
        voteType: 'VoteNominatifGlobal',
      });
      if (reloadData === false) {
        this.changeVoteType('VoteNominatifGlobal');
        this.activeTabVote = 1;
      }
    }
    if ((index === 2 || index === 'VoteNominatif') && reloadData === false) {
      this.changeVoteType('VoteNominatif');
      this.activeTabVote = 2;
    }
    if (index === 3 || index === 'VoteScrutinSecret') {
      // Vote global
      this.form.patchValue({
        nbrVotesGlobalPositif: this.voteObject?.nbrVotesGlobalPositif,
        nbrVotesGlobalNegatif: this.voteObject?.nbrVotesGlobalNegatif,
        nbrVotesGlobalAbstention: this.voteObject?.nbrVotesGlobalAbstention,
        nbrTours: this.voteObject?.nbrTours,
        nbrVotants: this.voteObject?.nbrVotants,
        nbrVotesValables: this.voteObject?.nbrVotesValables,
        voteType: 'VoteScrutinSecret',
      });
      if (reloadData === false) {
        this.changeVoteType('VoteScrutinSecret');
        this.activeTabVote = 3;
      }
    }
  }

  private showBoolean(value) {
    if (value) {
      return `<b style="color: #07E299;"><i class="bi bi-check-circle-fill"></i></b>`;
    } else if (value === -1) {
      return `<b style="color: #FFAF2F;"><i class="bi bi-hourglass-split"></i></b>`;
    } else {
      return `<b style="color: #FF5671;"></b>`;
    }
  }

  changeVoteType(type) {
    const data = {
      voteType: type,
    };
    this.api.changeVotes(this.point.seanceId, this.point.id, data).subscribe(() => {
      if (type === 'VoteNominatifGlobal' || type === 'VoteNominatif') {
        this.forceReloadParticipants();
      }
    });
  }
}
