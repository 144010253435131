import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentEditorService {
  // public baseUrlDocumentEditor = environment.baseUrlDocumentEditor;
  public baseUrlDocumentEditor: string;
  public headers: any;

  constructor(private http: HttpClient) {
    const config = JSON.parse(sessionStorage.getItem('globalConfig'));
    this.baseUrlDocumentEditor = config.baseUrlDocumentEditor;
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  public openTemplateDoc(location: string) {
    return this.http.get(location, { responseType: 'blob' });
  }

  // $$$$$$$$$$$$$$$$$$$$$$$$$ DOCUMENT EDITOR $$$$$$$$$$$$$$$$$$

  // Convert WORD document to SFDT then open text in document editor
  public loadFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('files', file);
    return this.http.post<any>(`${this.baseUrlDocumentEditor}DocumentEditor/Import`, formData);
  }

  public loadBlob(blob: Blob): Observable<any> {
    this.headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });
    const formData: FormData = new FormData();
    formData.append('files', blob);
    return this.http.post<any>(`${this.baseUrlDocumentEditor}DocumentEditor/Import`, formData, this.headers);
  }

  public parseDocumentToSfdt(document: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('files', document);
    return this.http.post(`${this.baseUrlDocumentEditor}DocumentEditor/ParseDocumentToSfdt`, formData);
  }

  // EXPORT .mail!!!!!!!!!
  public exportDocument(sfdt: any, selectedType: string): Observable<any> {
    return this.http.post(
      `${this.baseUrlDocumentEditor}DocumentEditor/Export`,
      { Content: sfdt, Type: selectedType },
      { responseType: 'blob' }
    );
  }

  // Convert SFDT to WORD document then save file in local
  public saveAsDocument(sfdt: any, selectedType: string): Observable<any> {
    return this.http.post(
      `${this.baseUrlDocumentEditor}DocumentEditor/ParseSfdtToDocument`,
      { Content: sfdt, Type: selectedType },
      { responseType: 'blob' }
    );
  }

  public saveToSystemClipBoard(sfdt: any, selectedType: string): Observable<any> {
    return this.http.post(
      `${this.baseUrlDocumentEditor}DocumentEditor/SystemClipBoard`,
      { Content: sfdt, Type: selectedType },
      { responseType: 'blob' }
    );
  }

  public parseContentToSfdt(content: any, selectedType: string): Observable<any> {
    return this.http.post(
      `${this.baseUrlDocumentEditor}DocumentEditor/ParseContentToSfdt`,
      { Content: content, Type: selectedType },
      { responseType: 'blob' }
    );
  }

  public getLocalizedLabels(): Observable<any> {
    return this.http.get(`${this.baseUrlDocumentEditor}DocumentEditor/LocalizedLabels`);
  }

  public postOCR(document): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('image', document, 'image.png');
    return this.http.post(`${this.baseUrlDocumentEditor}DocumentProcessor/OcrImage`, formData);
  }

  public postCreateQRCode(data: any) {
    return this.http.post(`${this.baseUrlDocumentEditor}DocumentProcessor/CreateQRCode`, data, {
      responseType: 'blob',
    });
  }

  public ConvertDocument(data: any) {
    this.headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });
    const body = new FormData();
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        body.append(property, data[property]);
      }
    }
    return this.http.post(`${this.baseUrlDocumentEditor}DocumentProcessor/Convert`, body, { responseType: 'blob' });
  }
}
