export class Decision_Type {
     description:string;
     utilisable:boolean;
     texteApprobationComplete: string;
     texteApprobationPartielle: string;
     texteRefus: string;
     texteRefusPartiel: string;
     texteReport: string;
     texteNonCloture: string;
     texteUnanimite: string;
     texteApprobationComplete_short: string;
     texteApprobationPartielle_short: string;
     texteRefus_short: string;
     texteRefusPartiel_short: string;
     texteReport_short: string;
     texteNonCloture_short: string;
     texteUnanimite_short: string;
     id:number;
     constructor(data:any) {
         Object.assign(this, data);
          if ( this.texteApprobationComplete ){
              this.texteApprobationComplete_short = this.texteApprobationComplete.substring(0,20) + '...';
          }
          if ( this.texteApprobationPartielle ){
               this.texteApprobationPartielle_short = this.texteApprobationPartielle.substring(0,20) + '...';
          }
          if ( this.texteRefus ){
               this.texteRefus_short = this.texteRefus.substring(0,20) + '...';
          }
          if ( this.texteRefusPartiel ){
               this.texteRefusPartiel_short = this.texteRefusPartiel.substring(0,20) + '...';
          }
          if ( this.texteReport ){
               this.texteReport_short = this.texteReport.substring(0,20) + '...';
          }
          if ( this.texteNonCloture ){
               this.texteNonCloture_short = this.texteNonCloture.substring(0,20) + '...';
          }
          if ( this.texteUnanimite ){
               this.texteUnanimite_short = this.texteUnanimite.substring(0,20) + '...';
          }
     }
}
 