import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of, throwError } from 'rxjs';
import { Decision_Type } from 'src/app/shared/models/decision_type';
import { DestinataireInput } from 'src/app/shared/models/destinataire_input';
import { Echevinat } from 'src/app/shared/models/echevinat';
import { Groupe_Politique } from 'src/app/shared/models/groupe_pol';
import { Matieres } from 'src/app/shared/models/matieres';
import { Meeting } from 'src/app/shared/models/meeting';
import { Model_Event } from 'src/app/shared/models/model_event';
import { Place } from 'src/app/shared/models/place';
import { Point } from 'src/app/shared/models/point';
import { Signatures } from 'src/app/shared/models/signature';
import { TypePoint } from 'src/app/shared/models/type_point';
import { UrgenceLevel } from 'src/app/shared/models/urgence_level';
import { IRecordTreatedId } from 'src/app/shared/models/api-request/IRecordTreatedId';
import { catchError, map } from 'rxjs/operators';
import { TenantInfo } from 'src/app/shared/models/tenant-info';
import {
  IPointSearchActionsQuery,
  ISeanceSearchActionsQuery,
  Point3PSearchQuery,
} from 'src/app/shared/models/request/SearchActionsQuery';
import { IAppConfiguration } from 'src/app/shared/models/response/IAppConfiguration';
import { IManagementRight } from 'src/app/shared/models/response/IMyManagementRight';
import { IPagedResult } from 'src/app/shared/models/response/IPagedResult';
import {
  IPointActionSearchResult,
  ISeanceActionSearchResult,
  Points3PSearchResult,
} from 'src/app/shared/models/response/ActionsSearchResult';
import { DashboardTypes, UserAppConfigurationRequest } from 'src/app/shared/models/api-request/me.models';
import { WithId } from 'src/app/shared/models/common/WithId';
import { ParticipantLowInfo } from 'src/app/shared/models/participantLowInfo';
import { ModelesDocuments } from 'src/app/shared/models/modelesDocuments.model';
import { ParticipantExtraitConforme } from 'src/app/shared/models/participantExtraitConforme.model';
import { MeRightPoint } from 'src/app/shared/models/point/me-right-point.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public apiBaseUrl: string;
  public icourrierBaseUrl: string;
  public body: FormData;
  public headers: any; //  = new HttpHeaders();
  public params: HttpParams = new HttpParams();
  renumerotationSuccess = new Subject<boolean>();

  constructor(public http: HttpClient) {
    const config = JSON.parse(sessionStorage.getItem('globalConfig'));
    this.apiBaseUrl = config.apiGatewayBaseUrl + '/api/v1';
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.body = new FormData();
    this.params = new HttpParams();
  }

  setIcourrierApiURL(url) {
    this.icourrierBaseUrl = url;
  }

  //* Gets the infos about the user's avatar
  getAvatar() {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Me/Avatar`, { responseType: 'blob' as 'json' });
  }
  getGlobalTypesSeances(id?): Observable<any[]> {
    if (id) {
      return this.http.get<any[]>(`${this.apiBaseUrl}/Global/TypesSeances`, { params: { id: id } });
    } else {
      return this.http.get<any[]>(`${this.apiBaseUrl}/Global/TypesSeances`);
    }
  }
  getModeleIdByTypeSeance(typeSeanceId: number, typesPointSeance: string, onlyExtrait?: boolean): Observable<number[]> {
    const baseUrl = `${this.apiBaseUrl}/Global/TypesSeances/${typeSeanceId}/ModelesDocuments?typesPointSeance=${typesPointSeance}`;
    const url = onlyExtrait === true ? `${baseUrl}&onlyExtrait=${onlyExtrait}` : baseUrl;
    return this.http.get<number[]>(url);
  }
  getGlobalParticipantTypeSeance(typeSeanceId: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/TypesSeances/${typeSeanceId}/Participants`);
  }
  getGlobalEchevinat(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Echevinats`);
  }
  getGlobalGroupesPol(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/GroupesPolitiques`);
  }
  getGlobalMatieres(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Matieres`);
  }
  getGlobalSeances(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Seances`);
  }
  getGlobalTypePoints(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/TypesPoints`);
  }
  getGlobalUrgenceLevel(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/NiveauxUrgences`);
  }
  getGlobalAllPointsStatuts(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/StatutsPoints`);
  }
  getGlobalDecisionType(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/TypesDecisions`);
  }
  getGlobalPointParameters(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Points/Parameters`);
  }
  getGlobalLieux(id?: number): Observable<any[]> {
    if (id) {
      return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Lieux`, { params: { id: id } });
    } else {
      return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Lieux`);
    }
  }
  getGlobalSignature(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Signatures`);
  }

  getManagementEchevinats(): Observable<Echevinat[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Management/Echevinats`);
  }

  postManagementEchevinats(data: Echevinat): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Management/Echevinats`, data);
  }

  putManagementEchevinats(id: number, data: Echevinat): Observable<any[]> {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Management/Echevinats/${id}`, data);
  }

  deleteManagementEchevinats(id: number): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Management/Echevinats/${id}`);
  }

  getManagementGroupesPolitiques(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Management/GroupesPolitiques`);
  }

  postManagementGroupesPolitiques(data: Groupe_Politique): Observable<Groupe_Politique[]> {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Management/GroupesPolitiques`, data);
  }

  putManagementGroupesPolitiques(id: number, data: Groupe_Politique): Observable<any[]> {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Management/GroupesPolitiques/${id}`, data);
  }

  deleteManagementGroupesPolitiques(id: number): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Management/GroupesPolitiques/${id}`);
  }

  getManagementMatieres(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Management/Matieres`);
  }

  postManagementMatieres(data: Matieres): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Management/Matieres`, data);
  }

  putManagementMatieres(id: number, data: Matieres): Observable<any[]> {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Management/Matieres/${id}`, data);
  }

  deleteManagementMatieres(id: number): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Management/Matieres/${id}`);
  }

  getManagementNiveauxUrgences(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Management/NiveauxUrgences`);
  }

  postManagementNiveauxUrgences(data: UrgenceLevel): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Management/NiveauxUrgences`, data);
  }

  putManagementNiveauxUrgences(id: number, data: UrgenceLevel): Observable<any[]> {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Management/NiveauxUrgences/${id}`, data);
  }

  deleteManagementNiveauxUrgences(id: number): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Management/NiveauxUrgences/${id}`);
  }

  getManagementRoles(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Management/Roles`);
  }

  postManagementRoles(data: UrgenceLevel): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Management/Roles`, data);
  }

  putManagementRoles(id: number, data: UrgenceLevel): Observable<any[]> {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Management/Roles/${id}`, data);
  }

  deleteManagementRoles(id: number): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Management/Roles/${id}`);
  }

  getManagementSignatures(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Management/Signatures`);
  }

  postManagementSignatures(data: Signatures): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Management/Signatures`, data);
  }

  putManagementSignatures(id: number, data: Signatures): Observable<any[]> {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Management/Signatures/${id}`, data);
  }

  deleteManagementSignatures(id: number): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Management/Signatures/${id}`);
  }

  getManagementTypesPoints(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Management/TypesPoints`);
  }

  postManagementTypesPoints(data: TypePoint): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Management/TypesPoints`, data);
  }

  putManagementTypesPoints(id: number, data: TypePoint): Observable<any[]> {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Management/TypesPoints/${id}`, data);
  }

  deleteManagementTypesPoints(id: number): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Management/TypesPoints/${id}`);
  }

  getManagementModelesEvenements(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Management/ModelesEvenements`);
  }

  postManagementModelesEvenements(data: Model_Event) {
    this.headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });
    const body = new FormData();
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        body.append(property, data[property]);
      }
    }
    return this.http.post<any[]>(`${this.apiBaseUrl}/Management/ModelesEvenements`, body, this.headers);
  }

  putManagementModelesEvenements(id: number, data: Model_Event) {
    this.headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });
    const body = new FormData();
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        body.append(property, data[property]);
      }
    }
    return this.http.put<any[]>(`${this.apiBaseUrl}/Management/ModelesEvenements/${id}`, body, this.headers);
  }

  deleteManagementModelesEvenements(id: number): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Management/ModelesEvenements/${id}`);
  }

  getManagementTypesSeances(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Management/TypesSeances`);
  }

  getManagementTypesSeancesById(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Management/TypesSeances/${id}`);
  }

  postManagementTypesSeances(data: TypePoint): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Management/TypesSeances`, data);
  }

  putManagementTypesSeances(id: number, data: TypePoint): Observable<any[]> {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Management/TypesSeances/${id}`, data);
  }

  deleteManagementTypesSeances(id: number): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Management/TypesSeances/${id}`);
  }

  postManagementTypesSeancesParticipants(id: number, data: any): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Management/TypesSeances/${id}/Participants`, data);
  }

  putManagementTypesSeancesParticipants(id: number, participantID: number, data: any): Observable<any[]> {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Management/TypesSeances/${id}/Participants/${participantID}`, data);
  }

  deleteManagementTypesSeancesParticipants(id: number, data: any): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Management/TypesSeances/${id}/Participants`);
  }

  putManagementTypesSeancesTypesPoints(id: number, data: any): Observable<any[]> {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Management/TypesSeances/${id}/TypesPoints`, data);
  }

  getManagementTypesDecisions(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Management/TypesDecisions`);
  }

  postManagementTypesDecisions(data: Decision_Type): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Management/TypesDecisions`, data);
  }

  putManagementTypesDecisions(id: number, data: Decision_Type): Observable<any[]> {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Management/TypesDecisions/${id}`, data);
  }

  getManagementTypesDecisionsLogo(id: number) {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Management/TypesDecisions/${id}/Logo`, {
      responseType: 'blob' as 'json',
    });
  }

  putManagementTypesDecisionsLogo(id: number, data: any) {
    this.headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });
    const body = new FormData();
    body.append('Logo', data);
    return this.http.put<any[]>(`${this.apiBaseUrl}/Management/TypesDecisions/${id}/Logo`, body, this.headers);
  }

  deleteManagementTypesDecisions(id: number): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Management/TypesDecisions/${id}`);
  }

  getManagementStatutsObjets(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Management/StatutsObjets`);
  }

  postManagementStatutsObjets(data: Decision_Type): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Management/StatutsObjets`, data);
  }

  putManagementStatutsObjets(id: number, data: Decision_Type): Observable<any[]> {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Management/StatutsObjets/${id}`, data);
  }

  deleteManagementStatutsObjets(id: number): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Management/StatutsObjets/${id}`);
  }

  getManagementLieu(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Management/Lieux`);
  }

  postManagementLieu(data: Place): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Management/Lieux`, data);
  }

  putManagementLieu(id: number, data: Decision_Type): Observable<any[]> {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Management/Lieux/${id}`, data);
  }

  deleteManagementLieu(id: number): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Management/Lieux/${id}`);
  }

  //rights
  getRightsManagement(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Me/Rights/Management`);
  }

  getRightsPointsSeances(): Observable<any> {
    return this.http.get<any>(`${this.apiBaseUrl}/Me/Rights/pointsSeances`);
  }
  getRightsSeance(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Me/Rights/Seances/${id}`);
  }
  getRightsPoint(id: number): Observable<MeRightPoint> {
    return this.http.get<MeRightPoint>(`${this.apiBaseUrl}/Me/Rights/Points/${id}`);
  }
  getRightManagement() {
    return this.http.get<IManagementRight>(`${this.apiBaseUrl}/Me/Rights/Management`);
  }
  getSeancesStates(ids: any): Observable<any[]> {
    let params = new HttpParams();
    if (ids.length > 0) {
      params = params.set('ids', ids);
    }
    return this.http.get<any[]>(`${this.apiBaseUrl}/Me/Rights/Seances/States?${params}`);
  }

  //personal config
  getPersonalParams(type: DashboardTypes, name?: string): Observable<WithId<UserAppConfigurationRequest>[]> {
    if (name) {
      return this.http.get<WithId<UserAppConfigurationRequest>[]>(
        `${this.apiBaseUrl}/Me/Configurations/PersonalParams?type=${type}&name=${name}`
      );
    } else {
      return this.http.get<WithId<UserAppConfigurationRequest>[]>(
        `${this.apiBaseUrl}/Me/Configurations/PersonalParams?type=${type}`
      );
    }
  }

  postPersonalParams(type: DashboardTypes, data: UserAppConfigurationRequest) {
    return this.http.post<WithId<UserAppConfigurationRequest>>(
      `${this.apiBaseUrl}/Me/Configurations/PersonalParams?type=${type}`,
      data
    );
  }

  putPersonalParams(type: DashboardTypes, personalParamsId: number, data: UserAppConfigurationRequest) {
    return this.http.put<WithId<UserAppConfigurationRequest>>(
      `${this.apiBaseUrl}/Me/Configurations/PersonalParams/${personalParamsId}?type=${type}`,
      data
    );
  }

  deletePersonalParams(type: DashboardTypes, id: number) {
    return this.http.delete(`${this.apiBaseUrl}/Me/Configurations/PersonalParams/${id}?type=${type}`);
  }

  //Seance
  getMeetingsByID(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Seances/${id}`);
  }
  getMeetingsByIDArray(id: any[]): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Seances/${id}`);
  }
  postSeance(data: any) {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Seances`, data);
  }
  putSeance(id: number, data: Meeting) {
    return this.http.put(`${this.apiBaseUrl}/Seances/${id}`, data);
  }
  deleteMeeting(id: number) {
    return this.http.delete(`${this.apiBaseUrl}/Seances/${id}`);
  }

  getMeetingHistoric(idSeance) {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Seances/${idSeance}/History`);
  }
  getMeetingsSearch(data): Observable<any> {
    let params = new HttpParams();
    if (data) {
      Object.keys(data).forEach((key) => (params = params.set(key, data[key])));
    }
    return this.http.get<any>(`${this.apiBaseUrl}/Seances/Search?${params}`);
  }
  putRenumerotationPoints(id, data): Observable<any[]> {
    return this.http.put<Point[]>(`${this.apiBaseUrl}/Seances/${id}/ReNumerotationsPoints`, data);
  }
  getSeanceRTFDatasStatic(id, type): Observable<any> {
    return this.http.get<any>(`${this.apiBaseUrl}/Seances/${id}/ContenuStaticRTFFiles`, {
      params: { fileType: type },
      responseType: 'blob' as 'json',
    });
  }
  putOneSeanceRTFDatasStatic(id, data, documentName): Observable<any> {
    const body = new FormData();
    this.headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });
    body.append('files', data, documentName + '.rtf');
    return this.http.put<any>(`${this.apiBaseUrl}/Seances/${id}/ContenuStaticRTFFiles`, body);
  }
  getSeanceFiles(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Seances/${id}/Files`);
  }

  addSeanceFiles(seanceId: number, document, file, extension: string, isAnnexe: boolean): Observable<any[]> {
    this.headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });
    const body = new FormData();
    for (const property in document) {
      if (document.hasOwnProperty(property)) {
        body.append(property, document[property]);
      }
    }
    body.append('file', file, file.name);
    return this.http.post<any[]>(
      `${this.apiBaseUrl}/Seances/${seanceId}/Files?extension=${extension}&annexe=${isAnnexe}`,
      body
    );
  }

  addSeanceFilesMultiple(seanceId: number, files: File[], isAnnexe: boolean): Observable<any[]> {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });
    return this.http.post<any[]>(
      `${this.apiBaseUrl}/Seances/${seanceId}/MultipleFiles?annexe=${isAnnexe}`,
      formData
    );
  }

  downloadSeanceFile(seanceId: number, fileId: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Seances/${seanceId}/Files/${fileId}/Download`, {
      responseType: 'blob' as 'json',
    });
  }
  patchSeanceFile(id, fileId, data): Observable<any[]> {
    return this.http.patch<any>(`${this.apiBaseUrl}/Seances/${id}/Files/${fileId}`, data);
  }
  patchSeancesFile(id, data): Observable<any[]> {
    return this.http.patch<any>(`${this.apiBaseUrl}/Seances/${id}/Files`, data);
  }
  deleteSeanceFile(id, idFile): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Seances/${id}/Files/${idFile}`);
  }
  setSeanceStatutes(data): Observable<any> {
    return this.http.put<any>(`${this.apiBaseUrl}/Seances/ChangeStatutes`, data);
  }
  putReportPoints(id, data) {
    return this.http.put<any>(`${this.apiBaseUrl}/Seances/${id}/Points/Reporter`, data);
  }

  //points
  getPointByID(id: number): Observable<any> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Points/${id}`);
  }
  postCreatePoint(data: Point): Observable<any[]> {
    data.clearDuplicationData();
    return this.http.post<any[]>(`${this.apiBaseUrl}/Points`, data);
  }
  postDuplicatePoint(
    id: number,
    requestBody: Point,
    duplicateDestinataires: boolean,
    duplicateContenu: boolean
  ): Observable<IRecordTreatedId> {
    return this.http.post<IRecordTreatedId>(
      `${this.apiBaseUrl}/Points/${id}/Duplicate?destinataires=${duplicateDestinataires}&contenu=${duplicateContenu}`,
      requestBody
    );
  }
  putUpdatePoint(id: number, data: Point): Observable<any[]> {
    new Point(data).clearDuplicationData();
    return this.http.put<Point[]>(`${this.apiBaseUrl}/Points/${id}`, data);
  }
  modifyPointStatut(id: number, data): Observable<any[]> {
    let params = new HttpParams();
    params = params.set('newStatutId', data);

    return this.http.put<Point[]>(`${this.apiBaseUrl}/Points/${id}/ChangeStatut?${params}`, null);
  }
  getSearchPoint(data: any): Observable<any[]> {
    let params = new HttpParams();
    if (data) {
      Object.keys(data).forEach((key) => (params = params.set(key, data[key])));
    }
    const paramsString: string = params.toString();
    return this.http.get<any[]>(`${this.apiBaseUrl}/Points/Search?${paramsString}`);
  }
  getPointHistoricByID(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Points/${id}/History`);
  }
  getPointFiles(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Points/${id}/Files`);
  }

  addPointFiles(pointId: number, document, file, extension: string, isAnnexe: boolean): Observable<any[]> {
    this.headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });
    const body = new FormData();
    for (const property in document) {
      if (document.hasOwnProperty(property)) {
        body.append(property, document[property]);
      }
    }
    body.append('file', file, file.name);
    return this.http.post<any[]>(
      `${this.apiBaseUrl}/Points/${pointId}/Files?extension=${extension}&annexe=${isAnnexe}`,
      body
    );
  }

  addPointFilesMultiple(pointId: number, files: File[], isAnnexe: boolean): Observable<any[]> {
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append('files', file, file.name);
    });
  
    return this.http.post<any[]>(
      `${this.apiBaseUrl}/Points/${pointId}/MultipleFiles?annexe=${isAnnexe}`,
      formData
    );
  }

  downloadPointFile(pointId: number, fileId: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Points/${pointId}/Files/${fileId}/Download`, {
      responseType: 'blob' as 'json',
    });
  }
  patchPointFile(id, fileId, data): Observable<any[]> {
    return this.http.patch<any>(`${this.apiBaseUrl}/Points/${id}/Files/${fileId}`, data);
  }
  patchPointsFile(id, data): Observable<any[]> {
    return this.http.patch<any>(`${this.apiBaseUrl}/Points/${id}/Files`, data);
  }
  deletePointFile(id, idFile): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Points/${id}/Files/${idFile}`);
  }

  getPointRTFDatasStatic(id, type): Observable<any> {
    return this.http.get<any>(`${this.apiBaseUrl}/Points/${id}/ContenuStaticRTFFiles`, {
      params: { fileType: type },
      responseType: 'blob' as 'json',
    });
  }
  getPointContenuStaticRTFFilesPresents(id): Observable<any> {
    return this.http.get<any>(`${this.apiBaseUrl}/Points/${id}/ContenuStaticRTFFilesPresents`);
  }
  putOnePointRTFDatasStatic(id, data, documentName): Observable<any> {
    const body = new FormData();
    this.headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });
    body.append('files', data, documentName + '.rtf');
    return this.http.put<any>(`${this.apiBaseUrl}/Points/${id}/ContenuStaticRTFFiles`, body);
  }
  putMultiplePointRTFDatasStatic(id, data: Array<Blob>, documentsNames: Array<string>): Observable<any> {
    const body = new FormData();
    this.headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });
    for (let i = 0; i < data.length; i++) {
      body.append('files', data[i], documentsNames[i] + '.rtf');
    }
    return this.http.put<any>(`${this.apiBaseUrl}/Points/${id}/ContenuStaticRTFFiles`, body);
  }

  //services
  getAllServices(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Services`);
  }

  //agent traitant
  getAllHandlers(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Handlers`);
  }

  //urgence
  getAllEmergencies(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Emergencies`);
  }

  //code
  getAllCodes(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Codes`);
  }

  //type de décision
  getAllDecisionTypes(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/DecisionTypes`);
  }

  //Participants
  postParticipantSeance(id: number, data: any): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Seances/${id}/Participants`, data);
  }
  putParticipantsSeance(id: number, idParticipant: number, data: any): Observable<any[]> {
    return this.http.put<Point[]>(`${this.apiBaseUrl}/Seances/${id}/Participants/${idParticipant}`, data);
  }
  deleteParticipantSeance(id: number, idParticipant): Observable<any[]> {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Seances/${id}/Participants?participantIds=${idParticipant}`);
  }

  //destinataires
  getDestinatairesFull(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Points/${id}/Destinataires/Full`);
  }
  setDestinatairePrincipal(id: number, destinataireP: DestinataireInput): Observable<any[]> {
    return this.http.put<any>(`${this.apiBaseUrl}/Points/${id}/Destinataires/SetPrincipal`, destinataireP);
  }

  addDestinatairesInPoint(id: number, data: any): Observable<any[]> {
    return this.http.post<any>(`${this.apiBaseUrl}/Points/${id}/Users`, data);
  }
  addOrReplaceDestinatairesInPoint(id: number, data: any): Observable<any[]> {
    return this.http.post<any>(`${this.apiBaseUrl}/Points/${id}/Users/Actions/AddOrReplace`, data);
  }
  deleteDestinatairePoint(id: number, data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { destinataires: data },
    };
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Points/${id}/Users`, options);
  }

  // Destinataire séances

  getDestinatairesFullMeeting(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Seances/${id}/Destinataires/Full`);
  }
  setDestinatairePrincipalMeeting(id: number, destinataireP: DestinataireInput): Observable<any[]> {
    return this.http.put<any>(`${this.apiBaseUrl}/Seances/${id}/Destinataires/SetPrincipal`, destinataireP);
  }

  addDestinatairesInMeeting(id: number, data: any): Observable<any[]> {
    return this.http.post<any>(`${this.apiBaseUrl}/Seances/${id}/Users`, data);
  }
  addOrReplaceDestinatairesInMeeting(id: number, data: any): Observable<any[]> {
    return this.http.post<any>(`${this.apiBaseUrl}/Seances/${id}/Users/Actions/AddOrReplace`, data);
  }
  deleteDestinataireMeeting(id: number, data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { destinataires: data },
    };
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Seances/${id}/Users`, options);
  }

  // Retrive all action statutes
  getAllActionsStatutes(): Observable<any> {
    return this.http.get(`${this.apiBaseUrl}/Global/Actions/Statutes`);
  }
  getAllActionsTypes(): Observable<any> {
    return this.http.get(`${this.apiBaseUrl}/Global/Actions/Types`);
  }
  replyToPointAction(id: number, actionId: number, actionReply: any): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}/Points/${id}/Actions/${actionId}/Reply`, actionReply);
  }

  // Retrieve configuration (global parameters, etc.)
  getConfiguration(): Observable<IAppConfiguration> {
    return this.http.get<IAppConfiguration>(`${this.apiBaseUrl}/Global/Configuration`);
  }

  // Retrieve the access rights for a specific user
  getAccessRightsForSpecificUser(id: number) {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Management/Users/${id}`);
  }

  getGroupeSecuriteRightsPoints(pointId?) {
    if (pointId) {
      return this.http.get<any[]>(`${this.apiBaseUrl}/Me/GroupeSecuriteRights/Points`, { params: { id: pointId } });
    } else {
      return this.http.get<any[]>(`${this.apiBaseUrl}/Me/GroupeSecuriteRights/Points`);
    }
  }

  getMeetingsByTypes(typeMeetingId) {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Points/SeanceAvailables?typeSeanceId=${typeMeetingId}`);
  }

  getMeetingsPointsInclusWithEvents(id: number) {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Seances/${id}/PointsInclusWithEvents`);
  }
  getMeetingsPointsInclusWithEventsTree(id: number, countEvenements: boolean = false) {
    return this.http.get<any[]>(
      `${this.apiBaseUrl}/Seances/${id}/PointsInclusWithEventsTree?countEvenements=${countEvenements}`
    );
  }

  getMeetingsPointsInclusWithEventsOrdered(id: number, countEvenements: boolean = false) {
    return this.http.get<any[]>(
      `${this.apiBaseUrl}/Seances/${id}/PointsInclusWithEventsOrdered?countEvenements=${countEvenements}`
    );
  }

  getMeetingsPointsInclus(id: number) {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Seances/${id}/PointsInclus`);
  }

  getMeetingsPointsNonInclus(id: number) {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Seances/${id}/PointsNonInclus`);
  }

  postEventByMeetingID(id: number, data: any) {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Seances/${id}/Events`, data, this.headers);
  }

  putEventByMeetingID(id: number, eventId: number, data: any) {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Seances/${id}/Events/${eventId}`, data, this.headers);
  }
  deleteventByMeetingID(id: number, eventId: number) {
    return this.http.delete<any[]>(`${this.apiBaseUrl}/Seances/${id}/Events?eventIds=${eventId}`, this.headers);
  }

  getSeancesByIDCount(meetingID: number, tags: any) {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Seances/${meetingID}/Count?tags=${tags}`, this.headers);
  }

  getParticipantsByMeetingID(meetingID: number) {
    return this.http.get<ParticipantLowInfo[]>(`${this.apiBaseUrl}/Seances/${meetingID}/Participants`, this.headers);
  }
  
  getParticipantsExtraitConformeByMeetingID(meetingID: number): Observable<any> {
    return this.http.get<ParticipantExtraitConforme[]>(`${this.apiBaseUrl}/Seances/${meetingID}/ExtraitConformes/Participants`, this.headers);
  }

  pointsVerrouiller(meetingID: number, data: any) {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Seances/${meetingID}/Points/Verrouiller`, data, this.headers);
  }

  pointsCloturer(meetingID: number, data: any) {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Seances/${meetingID}/Points/Cloturer`, data, this.headers);
  }

  markACorriger(meetingID: number, data: any) {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Seances/${meetingID}/Points/MarkACorriger`, data, this.headers);
  }

  validerCorrection(pointID: number) {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Points/${pointID}/ValiderCorrections`, this.headers);
  }

  removePoint(meetingID: number, pointId: number) {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Seances/${meetingID}/Points/${pointId}/Remove`, this.headers);
  }

  getVotesByPointID(meetingID: number, pointID: number) {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Seances/${meetingID}/Points/${pointID}/Votes`, this.headers);
  }

  getVotesParticipants(meetingID: number, pointID: number) {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Seances/${meetingID}/Points/${pointID}/Votes/Participants`, this.headers);
  }

  changeVotes(meetingID: number, pointID: number, data) {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Seances/${meetingID}/Points/${pointID}/Votes`, data, this.headers);
  }
  changeMassVotes(meetingId, pointIds) {
    const data = {
      pointIds: pointIds,
    };
    return this.http.put<any[]>(`${this.apiBaseUrl}/Seances/${meetingId}/Points/Votes`, data, this.headers);
  }

  postChangeStatusPoint(data) {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Points/ChangeStatutCheck`, data, this.headers);
  }
  putChangeStatusPoint(pointID: number, statusID: number) {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Points/${pointID}/ChangeStatut?newStatutId=${statusID}`, this.headers);
  }

  getModelesDocumentsByPointID(pointID) {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Points/${pointID}/ModelesDocuments`, this.headers);
  }

  previewRequestsModelDoc(pointID: number, modeleId: number, uiOpenRtfToEdit: boolean = false) {
    return this.http.post<any[]>(
      `${this.apiBaseUrl}/Points/${pointID}/ModelesDocuments/${modeleId}/PreviewRequests?uiOpenRtfToEdit=${uiOpenRtfToEdit}`,
      this.headers
    );
  }

  previewRequestsCancelModelDoc(pointID: number, modeleId: number, previewId: string) {
    return this.http.put<any[]>(
      `${this.apiBaseUrl}/Points/${pointID}/ModelesDocuments/${modeleId}/PreviewRequests/${previewId}/Cancel`,
      this.headers
    );
  }

  downloadModeleDocument(pointID: number, modeleId: number) {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Points/${pointID}/ModelesDocuments/${modeleId}/DownloadPdf`, {
      responseType: 'blob' as 'json',
    });
  }

  downloadModeleDocumentRTF(pointID: number, modeleId: number, generatedAndSaved: boolean) {
    return this.http.get<any[]>(
      `${this.apiBaseUrl}/Points/${pointID}/ModelesDocuments/${modeleId}/DownloadRtf?generatedAndSaved=${generatedAndSaved}`,
      { responseType: 'blob' as 'json' }
    );
  }

  getPointsCount(tags) {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Points/Count?tags=${tags}`, this.headers);
  }

  getGlobalModelesDoc() {
    return this.http.get<ModelesDocuments[]>(`${this.apiBaseUrl}/Global/ModelesDocuments`, this.headers);
  }

  generateModelesDoc(pointID: number, modeleId: number, autoSave?: boolean, uiOpenRtfToEdit?: boolean) {
    if (autoSave === null || autoSave === undefined) {
      autoSave = false;
    }
    if (uiOpenRtfToEdit === null || uiOpenRtfToEdit === undefined) {
      uiOpenRtfToEdit = false;
    }

    const params = [];

    if (autoSave !== undefined) {
      params.push(`autoSave=${autoSave}`);
    }
    if (uiOpenRtfToEdit !== undefined) {
      params.push(`uiOpenRtfToEdit=${uiOpenRtfToEdit}`);
    }

    const queryString = params.length > 0 ? `?${params.join('&')}` : '';
    const url = `${this.apiBaseUrl}/Points/${pointID}/ModelesDocuments/${modeleId}/Generate${queryString}`;
    
    return this.http.post<any[]>(url, this.headers);
  }

  generateModelesDocConforme(pointId: number, modeleId: number, requestBody: any) {

    const url = `${this.apiBaseUrl}/Points/${pointId}/ModelesDocuments/${modeleId}/ExtraitConformes/Generate`;
    
    const body = requestBody ? requestBody : {};
    return this.http.post<any[]>(url, body, this.headers);
  }

  getRightsGeneration(seanceId: number, modeleId: number, pointIds: number[]) {
    return this.http.post<any[]>(
      `${this.apiBaseUrl}/Seances/${seanceId}/ExtraitConformes/${modeleId}/MassGenerationCheck`, {pointIds}, this.headers 
    );
  }

  uploadRtfModeleDoc(pointID: number, modeleId: number, data) {
    this.headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });
    const body = new FormData();
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        body.append(property, data[property]);
      }
    }
    return this.http.post<any[]>(
      `${this.apiBaseUrl}/Points/${pointID}/ModelesDocuments/${modeleId}/UploadRtf`,
      body,
      this.headers
    );
  }

  getModelesDocumentsByMeetingID(MeetingID) {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Seances/${MeetingID}/ModelesDocuments`, this.headers);
  }

  previewRequestsMeetingModelDoc(meetingID: number, modeleId: number, uiOpenRtfToEdit: boolean = false) {
    return this.http.post<any[]>(
      `${this.apiBaseUrl}/Seances/${meetingID}/ModelesDocuments/${modeleId}/PreviewRequests?uiOpenRtfToEdit=${uiOpenRtfToEdit}`,
      this.headers
    );
  }

  previewRequestsMeetingCancelModelDoc(meetingID: number, modeleId: number, previewId: string) {
    return this.http.put<any[]>(
      `${this.apiBaseUrl}/Seances/${meetingID}/ModelesDocuments/${modeleId}/PreviewRequests/${previewId}/Cancel`,
      this.headers
    );
  }

  downloadMeetingModeleDocument(meetingID: number, modeleId: number) {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Seances/${meetingID}/ModelesDocuments/${modeleId}/DownloadPdf`, {
      responseType: 'blob' as 'json',
    });
  }

  downloadMeetingModeleDocumentRTF(meetingID: number, modeleId: number, generatedAndSaved: boolean) {
    return this.http.get<any[]>(
      `${this.apiBaseUrl}/Seances/${meetingID}/ModelesDocuments/${modeleId}/DownloadRtf?generatedAndSaved=${generatedAndSaved}`,
      { responseType: 'blob' as 'json' }
    );
  }

  generateModelesDocMeeting(meetingID: number, modeleId: number, autoSave?: boolean, uiOpenRtfToEdit?: boolean, requestBody?: any) {
    if (autoSave === null) {
      autoSave = false;
    }
    if (uiOpenRtfToEdit === null) {
      uiOpenRtfToEdit = false;
    }

    const body = requestBody ? requestBody : {};
    return this.http.post<any[]>(
      `${this.apiBaseUrl}/Seances/${meetingID}/ModelesDocuments/${modeleId}/Generate?autoSave=${autoSave}&uiOpenRtfToEdit=${uiOpenRtfToEdit}`,
      body, this.headers
    );
  }

  uploadRtfModeleDocMeeting(meetingID: number, modeleId: number, data) {
    this.headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });
    const body = new FormData();
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        body.append(property, data[property]);
      }
    }
    return this.http.post<any[]>(
      `${this.apiBaseUrl}/Seances/${meetingID}/ModelesDocuments/${modeleId}/UploadRtf`,
      body,
      this.headers
    );
  }

  //* Gets the infos for the export mandataires of the given meeting
  getExportsDelibeMandataire(meetingID: number) {
    return this.http.get<any[]>(
      `${this.apiBaseUrl}/Connecteurs/IDelibeMandataires/Seances/${meetingID}/ExportHistory`,
      this.headers
    );
  }

  //* Execute the export Mandataire for the given meetingId
  exportMandataireNow(meetingID: number, data) {
    return this.http.post<any[]>(
      `${this.apiBaseUrl}/Connecteurs/IDelibeMandataires/Seances/${meetingID}/Export`,
      data,
      this.headers
    );
  }

  //* get the points checked or not to export for citoyen
  getExportCitoyenPoints(meetingId) {
    return this.http.get<any[]>(
      `${this.apiBaseUrl}/Connecteurs/IDelibeCitoyens/Seances/${meetingId}/PointsToExport`,
      this.headers
    );
  }

  //* Gets the infos for the export mandataires of the given meeting
  getExportsDelibeCitoyen(meetingID: number) {
    return this.http.get<any[]>(
      `${this.apiBaseUrl}/Connecteurs/IDelibeCitoyens/Seances/${meetingID}/ExportHistory`,
      this.headers
    );
  }

  //* Execute the export Mandataire for the given meetingId
  exportCitoyenNow(meetingID: number) {
    return this.http.post<any[]>(
      `${this.apiBaseUrl}/Connecteurs/IDelibeCitoyens/Seances/${meetingID}/Export`,
      null,
      this.headers
    );
  }

  // search actions
  searchPointsActions(searchData: IPointSearchActionsQuery) {
    let params = new HttpParams();
    Object.keys(searchData).forEach((key) => (params = params.set(key, searchData[key])));
    const url = `${this.apiBaseUrl}/Points/Actions/Search?${params}`;
    return this.http.get<IPagedResult<IPointActionSearchResult>>(url);
  }

  searchSeancesActions(searchData: ISeanceSearchActionsQuery) {
    let params = new HttpParams();
    Object.keys(searchData).forEach((key) => (params = params.set(key, searchData[key])));
    const url = `${this.apiBaseUrl}/Seances/Actions/Search?${params}`;
    return this.http.get<IPagedResult<ISeanceActionSearchResult>>(url);
  }

  /**
   * * Methods to get icourrier data based on id
   * @param icourrierID:number
   */
  getIcourrierByIdData(icourrierID: number) {
    return this.http.get<any[]>(`${this.icourrierBaseUrl}/Connecteurs/iDelibe/Mails/${icourrierID}`, this.headers);
  }

  getIcourrierFiles(icourrierID: number) {
    return this.http.get<any[]>(
      `${this.icourrierBaseUrl}/Connecteurs/iDelibe/Mails/${icourrierID}/Files`,
      this.headers
    );
  }

  postPointFromIcourrier(data) {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Points/FromMail`, data, this.headers);
  }

  importFromIcourrierFilesPoint(id: number, data: any) {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Points/${id}/Files/ImportFromIcourrier`, data, this.headers);
  }

  getGlobalFusionsFields() {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Documents/Templates/FusionChamps`, this.headers);
  }

  get3PPointSearch(data: Point3PSearchQuery) {
    let params = new HttpParams();
    if (data) {
      Object.keys(data).forEach((key) => (params = params.set(key, data[key])));
    }
    return this.http.get<IPagedResult<Points3PSearchResult>>(
      `${this.apiBaseUrl}/Points3P/Search?${params}`,
      this.headers
    );
  }

  getContenuStaticFilesFrom3P(id: number, fileType: string) {
    return this.http.get<any[]>(
      `${this.apiBaseUrl}/Points3P/${id}/ContenuStaticRTFFiles/Download?fileType=${fileType}`,
      { responseType: 'blob' as 'json' }
    );
  }
  createFrom3P(data) {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Points3P/CreateFrom3P`, data, this.headers);
  }

  archivePoint3P(id: number[]): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiBaseUrl}/Points3P/Archive`, { ids: id }, { responseType: 'json' });
  }

  numerotationAuto(id: number) {
    return this.http.put<any[]>(`${this.apiBaseUrl}/Seances/${id}/Points/ReNumerotationAuto`, this.headers);
  }

  getGroupeSecurityRightByPointID(id?: number) {
    if (id) {
      return this.http.get<any[]>(`${this.apiBaseUrl}/Me/GroupeSecuriteRights/Points?id=${id} `, this.headers);
    } else {
      return this.http.get<any[]>(`${this.apiBaseUrl}/Me/GroupeSecuriteRights/Points`, this.headers);
    }
  }

  massGenerationCheckDocumentInPoint(seanceId: number, modeleId: number, pointIds: Array<number>) {
    return this.http.post<any[]>(
      `${this.apiBaseUrl}/Seances/${seanceId}/ModelesDocuments/${modeleId}/MassGenerationCheck`, {pointIds},
      this.headers
    );
  }

  massGenerationDocumentInPoint(seanceId: number, modeleId: number, requestBody?: any, overWrite?: boolean) {
    const params = [];

    if (overWrite !== undefined) {
        params.push(`overWrite=${overWrite}`);
    }

    const queryString = params.length > 0 ? `?${params.join('&')}` : '';
    const url = `${this.apiBaseUrl}/Seances/${seanceId}/ModelesDocuments/${modeleId}/MassGeneration${queryString}`;
    
    const body = requestBody ? requestBody : {};
    return this.http.post<any[]>(url, body, this.headers);
}

  massGenerationExtraitConforme(seanceId: number, modeleId: number, requestBody?: any) {
    const url = `${this.apiBaseUrl}/Seances/${seanceId}/ExtraitConformes/${modeleId}/MassGeneration`;
    const body = requestBody ? requestBody : {};
    return this.http.post<any[]>(url, body, this.headers);
  }

  generationExtraitConforme(pointId: number, modeleId: number, requestBody?: any) {
    const url = `${this.apiBaseUrl}/Points/${pointId}/ModelesDocuments/${modeleId}/ExtraitConformes/Generate`;
    const body = requestBody ? requestBody : {};
    return this.http.post<any[]>(url, body, this.headers);
  }

  getGlobalModelesPoints() {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/ModelesPoints`, this.headers);
  }

  postModelePointFromPoint(id: number, data) {
    return this.http.post<any[]>(`${this.apiBaseUrl}/Management/ModelesPointsFromPoint?pointId=${id}`, data);
  }

  /**
   * Locks a point with the given ID by sending a PUT request to the server.
   *
   * @param {number} id - the ID of the point to be locked
   * @return {Observable<Point["writeLock"]>} an observable of the response data from the server
   */
  lockPoint(id: number): Observable<Point['writeLock']> {
    return this.http.put<Point['writeLock']>(`${this.apiBaseUrl}/Points/${id}/WriteLock`, this.headers).pipe(
      map((res) => {
        if (res) {
          return res;
        }
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * Release a write lock for a specific ID.
   *
   * @param {number} id - the ID of the point
   * @return {Observable<Point["writeLock"]>} an observable of the write lock information
   */
  ReleaseWriteLock(id: number): Observable<Point['writeLock']> {
    return this.http.put<any>(`${this.apiBaseUrl}/Points/${id}/ReleaseWriteLock`, this.headers);
  }

  deleteIDelibeLinks(pointId: number, idsCourrier: number[]): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        iCourrierIdsToDeleteLink: idsCourrier,
      },
    };
    return this.http.delete<void>(`${this.apiBaseUrl}/Points/${pointId}/Links`, options);
  }
}
