import { LabelValue } from './labelvalue';

export class Model_Point_Biblio {
  chemiseId: number;
  chemiseLabel?: string = null;
  classementId: number;
  classementLabel?: string = null;
  createdBy: string;
  createdByUserid: number;
  createdOn: string;
  decisionType: number;
  decisionTypeLabel?: string = null;
  defautObjetTypeId: number;
  defautObjetTypeLabel?: string = null;
  description: string;
  fardeId: number;
  fardeLabel?: string = null;
  id: number;
  matiereId: number;
  matiereLabel?: string = null;
  modifiedBy: string;
  modifiedByUserid: number;
  modifiedOn: string;
  objet: string;
  serviceId: number;
  serviceLabel?: string = null;
  typeSeanceId: number;
  typeSeanceLabel?: string = null;
  constructor(
    data: any,
    seanceTypes: Array<LabelValue>,
    typesPoints: Array<LabelValue>,
    services: Array<any>,
    matters: Array<LabelValue>,
    decisionTypes: Array<LabelValue>,
    codes: Array<any>,
    classeurs: Array<any>,
    chemises: Array<any>,
  ) {
    Object.assign(this, data);
    if (this.typeSeanceId) {
      this.typeSeanceLabel = seanceTypes.find((x) => x.value === this.typeSeanceId)?.label;
    }
    if (this.defautObjetTypeId) {
      this.defautObjetTypeLabel = typesPoints.find((x) => x.value === this.defautObjetTypeId)?.label;
    }
    if (this.serviceId) {
      this.serviceLabel = services.find((x) => x.value === this.serviceId || x.id === this.serviceId)?.label;
    }
    if (this.matiereId) {
      this.matiereLabel = matters.find((x) => x.value === this.matiereId)?.label;
    }
    if (this.decisionType) {
      this.decisionTypeLabel = decisionTypes.find((x) => x.value === this.decisionType)?.label;
    }
    if (this.classementId) {
      this.classementLabel = codes.find((x) => x.value === this.classementId || x.id === this.classementId)?.label;
    }
    if (this.fardeId) {
      this.fardeLabel = classeurs.find((x) => x.value === this.fardeId || x.id === this.fardeId)?.label;
    }
    if (this.chemiseId) {
      this.chemiseLabel = chemises.find((x) => x.value === this.chemiseId || x.id === this.chemiseId)?.label;
    }
  }
}
