import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from 'src/app/core/services/api.service';
import { RecipientModalComponent } from '../modals/recipient-modal/recipient-modal.component';
import * as async from 'async';
import { Destinataire } from '../../models/destinataire';
import { ActionResponseModalComponent } from '../modals/action-response-modal/action-response-modal.component';
import { ActionCreateModalComponent } from '../modals/action-create-modal/action-create-modal.component';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { Contact } from '../../models/iContact/contact';
import { Point } from '../../models/point';
import * as moment from 'moment';
import { SuperColumn } from '@devinforius/super-compos';
import { DataService } from 'src/app/core/services/data.service';
import { Global_Class } from '../../models/global';
import { HttpParams } from '@angular/common/http';
import { Meeting } from '../../models/meeting';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
@Component({
  selector: 'destinataire',
  templateUrl: './destinataire.component.html',
  styleUrls: ['./destinataire.component.scss'],
})
export class DestinataireComponent implements OnInit {
  public modalReference: NgbModalRef;
  public modalReference2: NgbModalRef;
  public hasLoaded = false;
  @Input() set items(value) {
    if (value && value.length > 0) {
      this.destinatairesItems = value;
    }
    this.hasLoaded = true;
  }
  @Input() point: Point;
  @Input() meeting: Meeting;
  @Input() set rights(value) {
    this.rightsPoints = value;
    this.setButtonsDestinataires();
  }
  public destinatairesItems: Array<Destinataire> = [];
  public currentWeek = moment().format('ww');
  public columnsDest: Array<SuperColumn> = [];
  public globalElement: any;
  public buttonsDestinataires = [];
  public actionStatus: Array<any> = [];
  public actionTypes: Array<any> = [];
  public reloadFromReply: boolean = false;
  public rightsPoints: any;
  @Output() refreshData: EventEmitter<any> = new EventEmitter();
  constructor(
    public spinner: NgxSpinnerService,
    public api: ApiService,
    public toastr: ToastrService,
    public translate: TranslateService,
    public confirmationService: ConfirmationService,
    private modalService: NgbModal,
    public iContactService: IContactService,
    public dataService: DataService,
    public iAdminService: iAdminService
  ) {}

  ngOnInit(): void {
    this.dataService.getGlobalElements.subscribe((res: any) => {
      this.globalElement = new Global_Class(res);
      if (this.globalElement.isLoaded()) {
        this.getColumns();
        this.getActions();
      }
    });
  }

  setButtonsDestinataires() {
    this.buttonsDestinataires = [];
    if (this.rightsPoints && this.rightsPoints?.distribution.addUser) {
      this.buttonsDestinataires.push(
        {
          text: 'Ajouter un destinataire...',
          styleClass: 'btnAddRecipient green',
          skin: 'super-compos-primary-btn-filter',
          click: () => {
            this.openRecipientModal();
          },
        },
        {
          text: 'Demander des actions',
          styleClass: 'green',
          id: 'fluxBtnActionCreate',
          disabled: false,
          tooltip: 'Demander des actions',
          click: () => {
            this.createAction();
          },
        }
      );
    } else {
      this.buttonsDestinataires = [];
    }
  }

  getActions() {
    this.actionTypes = [];
    this.actionStatus = [];
    this.actionTypes = this.globalElement.actions_type_lv;
    if (
      this.globalElement.configApp &&
      this.globalElement.configApp.mails &&
      this.globalElement.configApp.mails.hideStatutAction === false
    ) {
      this.globalElement.actions_status_lv.map((item) => {
        if (item.item.notUsed === false) {
          this.actionStatus.push(item);
        }
      });
    }
  }
  public deleteSelectionDestinataire(items) {
    this.spinner.show();
    async.waterfall([
      (cb) => {
        if (items && items.length > 0) {
          const data = [];
          items.map((item) => {
            data.push({
              id: item.id,
              contactName: item.name,
            });
          });
          if (this.point) {
            this.api.deleteDestinatairePoint(this.point.id, data).subscribe((res: any) => {
              this.toastr.success(this.translate.instant('success.deletedDestinataires'));
              cb(null);
            });
          } else {
            this.api.deleteDestinataireMeeting(this.meeting.id, data).subscribe((res: any) => {
              this.toastr.success(this.translate.instant('success.deletedDestinataires'));
              cb(null);
            });
          }
        }
      },
      (cb) => {
        this.refreshData.emit(true);
      },
    ]);
  }

  answerToAction(column) {
    // Building dropdown  (label - value)
    const status = [];
    this.actionStatus.map((s) => {
      status.push({
        label: s.description,
        value: s.description,
        id: s.id,
        ordreAffichage: s.ordreAffichage,
      });
    });
    this.modalReference2 = this.modalService.open(ActionResponseModalComponent, {
      windowClass: 'super-compos-modal-content',
      size: 'md',
    });
    this.modalReference2.componentInstance.openingWindow = this.modalReference2;
    this.modalReference2.componentInstance.currentAction = column;
    this.modalReference2.componentInstance.caller = 'courrier';
    this.modalReference2.componentInstance.types = this.actionTypes;
    this.modalReference2.componentInstance.status = status;
    this.modalReference2.componentInstance.pointId = this.point.id;
    this.modalReference2.componentInstance.meetingId = this.meeting?.id;
    this.modalReference2.result.then((value) => {
      this.refreshData.emit(true);
    });
  }

  public openRecipientModal() {
    this.modalReference = this.modalService.open(RecipientModalComponent, {
      windowClass: 'super-compos-modal-content',
      size: 'lg',
    });
    this.modalReference.componentInstance.openingWindow = this.modalReference;
    this.modalReference.componentInstance.actualRecipients = this.items;
    this.modalReference.componentInstance.destinataires = this.destinatairesItems;

    const destinatairesToAdd: Destinataire[] = [];

    this.modalReference.result.then((data) => {
      let arrayIDToCheck = [];
      const remplacantArray = [];
      async.waterfall([
        (cb) => {
          if (data && data.contactArray.length > 0) {
            data.contactArray.map((item) => {
              arrayIDToCheck.push(item.value);
            });
            cb(null);
          } else {
            arrayIDToCheck = [];
            cb(null);
          }
        },
        (cb) => {
          if (arrayIDToCheck && arrayIDToCheck.length > 0) {
            this.iContactService.getRemplacant(arrayIDToCheck).subscribe((res: any) => {
              const hasAbsentPeopleArray = [];
              if (res && res.length > 0) {
                res.map((remplacant) => {
                  data.contactArray.map((dest) => {
                    if (remplacant.absent === true && dest.value === remplacant.contactId) {
                      hasAbsentPeopleArray.push(remplacant);
                    }
                  });
                });
              }
              if (hasAbsentPeopleArray.length > 0) {
                this.confirmationService.confirm({
                  message: 'Souhaitez-vous remplacer automatiquement les personnes qui sont en congés ?',
                  key: 'remplacant',
                  accept: () => {
                    res.map((item) => {
                      if (item.absent) {
                        data.contactArray.map((dest: any) => {
                          if (dest.value === item.contactId) {
                            this.iContactService.get('/contacts/' + item.remplaceParContactId).subscribe((res: any) => {
                              if (res) {
                                remplacantArray.push(
                                  dest,
                                  new Contact({
                                    value: item.remplaceParContactId,
                                    name: (res.prenom ? res.prenom : '') + ' ' + (res.nom ? res.nom : ''),
                                    label: (res.prenom ? res.prenom : '') + ' ' + (res.nom ? res.nom : ''),
                                    type: 'DESTINATAIRE',
                                    lastname: res.prenom,
                                    nom: res.nom,
                                    prenom: res.prenom,
                                  })
                                );
                              }
                            });
                          } else {
                            remplacantArray.push(dest);
                          }
                        });
                      }
                    });
                    setTimeout(() => {
                      cb(null);
                    }, 1000);
                  },
                  reject: () => {
                    cb(null);
                  },
                });
              } else {
                cb(null);
              }
            });
          } else {
            cb(null);
          }
        },
        (cb) => {
          if (remplacantArray.length > 0) {
            data.contactArray = remplacantArray;
            cb(null);
          } else {
            cb(null);
          }
        },
        (cb) => {
          setTimeout(() => {
            this.addDestinataires(data);
          }, 500);
        },
      ]);
    });
  }

  public addDestinataires(data) {
    let groupsMembers = [];
    const groupsMembersIds = [];
    if (data) {
      this.spinner.show();
      async.waterfall([
        (cb) => {
          if (data.contactArray.length > 0) {
            data.contactArray.map((item, i) => {
              const destinataireInput = {
                libre: null,
                contacts: [
                  {
                    contactId: item.value,
                    historiqueDescription: item.label,
                  },
                ],
              };
              if (this.point) {
                this.api.addDestinatairesInPoint(this.point.id, destinataireInput).subscribe(
                  (response) => {
                    if (i + 1 === data.contactArray.length) {
                      cb(null);
                    }
                  },
                  (error) => {
                    console.error(error);
                    this.toastr.error("Erreur lors de l'ajout des nouveaux contacts");
                  }
                );
              } else {
                this.api.addDestinatairesInMeeting(this.meeting.id, destinataireInput).subscribe(
                  (response) => {
                    if (i + 1 === data.contactArray.length) {
                      cb(null);
                    }
                  },
                  (error) => {
                    console.error(error);
                    this.toastr.error("Erreur lors de l'ajout des nouveaux contacts");
                  }
                );
              }
            });
          } else {
            cb(null);
          }
        },
        (cb) => {
          if (data.usersArray.length > 0) {
            data.usersArray.map((item, i) => {
              const destinataireInput = {
                libre: null,
                contacts: [
                  {
                    contactUserId: item.userId ? item.userId : null,
                    contactIdiAdmin: item.id,
                    historiqueDescription: item.label,
                  },
                ],
              };
              if (this.point) {
                this.api.addDestinatairesInPoint(this.point.id, destinataireInput).subscribe(
                  (response) => {
                    if (i + 1 === data.usersArray.length) {
                      cb(null);
                    }
                  },
                  (error) => {
                    console.error(error);
                    this.toastr.error("Erreur lors de l'ajout des nouveaux utilisateurs");
                  }
                );
              } else {
                this.api.addDestinatairesInMeeting(this.meeting.id, destinataireInput).subscribe(
                  (response) => {
                    if (i + 1 === data.usersArray.length) {
                      cb(null);
                    }
                  },
                  (error) => {
                    console.error(error);
                    this.toastr.error("Erreur lors de l'ajout des nouveaux contacts");
                  }
                );
              }
            });
          } else {
            cb(null);
          }
        },
        (cb) => {
          if (data.freeRecipientArray.length > 0) {
            data.freeRecipientArray.map((item, i) => {
              const destinataireInput = {
                libre: item,
                contacts: [],
              };
              if (this.point) {
                this.api.addDestinatairesInPoint(this.point.id, destinataireInput).subscribe(
                  (response) => {
                    if (i + 1 === data.freeRecipientArray.length) {
                      cb(null);
                    }
                  },
                  (error) => {
                    console.error(error);
                    this.toastr.error("Erreur lors de l'ajout des nouveaux destinataires libres");
                  }
                );
              } else {
                this.api.addDestinatairesInMeeting(this.meeting.id, destinataireInput).subscribe(
                  (response) => {
                    if (i + 1 === data.freeRecipientArray.length) {
                      cb(null);
                    }
                  },
                  (error) => {
                    console.error(error);
                    this.toastr.error("Erreur lors de l'ajout des nouveaux destinataires libres");
                  }
                );
              }
            });
          } else {
            cb(null);
          }
        },
        (cb) => {
          if (data.groupsArray.length > 0) {
            const groupsIds = [];
            data.groupsArray.map((item, i) => {
              groupsIds.push(item.id);
            });
            this.iAdminService.getGroupesMembresIAdminSearch(groupsIds).subscribe((res: any) => {
              if (res && res.length > 0) {
                res.map((contact) => {
                  groupsMembersIds.push(contact.contactId);
                });
                cb(null);
              } else {
                cb(null);
              }
            });
          } else {
            cb(null);
          }
        },
        (cb) => {
          if (data.groupsArray.length > 0) {
            this.iContactService.getLowContactsAdmin({ id: groupsMembersIds }).subscribe((res: any) => {
              groupsMembers = res;
              cb();
            });
          } else {
            cb();
          }
        },
        (cb) => {
          if (groupsMembers && groupsMembers.length > 0) {
            const data = {
              libre: null,
              contacts: [],
            };
            groupsMembers.map((item, i) => {
              data.contacts.push({
                contactUserId: item.userId ? item.userId : null,
                contactId: item.id,
                historiqueDescription: (item.prenom ? item.prenom : '') + ' ' + (item.nom ? item.nom : ''),
              });
            });
            if (this.point) {
              this.api.addDestinatairesInPoint(this.point.id, data).subscribe(
                (response) => {
                  cb(null);
                },
                (error) => {
                  console.error(error);
                  this.toastr.error("Erreur lors de l'ajout des nouveaux contacts");
                }
              );
            } else {
              this.api.addDestinatairesInMeeting(this.meeting.id, data).subscribe(
                (response) => {
                  cb(null);
                },
                (error) => {
                  console.error(error);
                  this.toastr.error("Erreur lors de l'ajout des nouveaux contacts");
                }
              );
            }
          } else {
            cb(null);
          }
        },
        (cb) => {
          this.toastr.success('Destinataire(s) ajouté(s)!');
          this.spinner.hide();
          this.refreshData.emit(true);
        },
      ]);
    }
  }

  createAction() {
    this.modalReference = this.modalService.open(ActionCreateModalComponent, {
      windowClass: 'super-compos-modal-content',
      size: 'lg',
    });
    this.modalReference.componentInstance.openingWindow = this.modalReference;
    this.modalReference.componentInstance.currentPoint = this.point;
    this.modalReference.componentInstance.currentMeeting = this.meeting;
    this.modalReference.result.then((value) => {
      this.refreshData.emit(true);
    });
  }

  getColumns() {
    this.columnsDest = [
      {
        field: 'principal',
        header: '',
        width: '5%',
        format: (value) => {
          if (value === true) {
            return '<i class="icon-star-full blue-icon big-icon"></i>';
          } else {
            return '<i class="icon-star-outline blue-icon big-icon"></i>';
          }
        },
        click: (item, value) => {
          //this.setPrincipal(item);
        },
      },
      {
        field: 'transmisDate',
        header: this.translate.instant('transmissionDate'),
        format: (item, value) => {
          if (value && value.transmisDate) {
            return `<span class="${value.traiteLe !== null ? 'text-info' : ''}">${moment(value.transmisDate).format('DD/MM/YYYY')} ${value.statut ? '(' + value.statut + ')' : ''}</span>`;
          }
        },
        sort: (a, b, order) => {
          const val1 = moment(a, 'DD/MM/YYYY').isValid() ? moment(a, 'DD/MM/YYYY').unix() : null;
          const val2 = moment(b, 'DD/MM/YYYY').isValid() ? moment(b, 'DD/MM/YYYY').unix() : null;
          if (val1 === null) return 1;
          else if (val2 === null) return -1;
          else return val1 < val2 ? 1 * order : -1 * order;
        },
        sortable: true,
      },
      {
        field: 'echeance',
        header: this.translate.instant('deadline'),
        format: (value, item) => {
          if (value) {
            if (item.traiteLe === null) {
              const transmissionDate = moment(value).format('ww');
              if (transmissionDate === this.currentWeek) {
                return "<span class='text-success'>" + moment(value).format('DD/MM/YYYY') + '</span>';
              } else if (transmissionDate < this.currentWeek) {
                return "<span class='text-danger'>" + moment(value).format('DD/MM/YYYY') + '</span>';
              }
            } else {
              return "<span class='text-info'>" + moment(value).format('DD/MM/YYYY') + '</span>';
            }
          } else {
            return 'Aucune date renseignée';
          }
        },
        tooltip: (value, item) => {
          if (value.echeance) {
            const transmissionDate = moment(value.echeance).format('ww');
            if (transmissionDate === this.currentWeek) {
              return 'Cette semaine';
            } else if (transmissionDate < this.currentWeek) {
              return 'En retard';
            }
          } else {
            return 'Aucune date renseignée';
          }
        },
      },
      {
        field: 'transmissionUserName',
        header: this.translate.instant('applicant'),
        format: (item, value) => {
          if (item || value.transmissionUserId) {
            return item ? item : value.transmissionUserId;
          } else if (!item && !value.transmissionUserId) {
            return 'Pas de demandeur';
          }
        },
      },
      {
        field: 'recipientName',
        header: this.translate.instant('recipient'),
        format: (item, value) => {
          if (value.action === null) {
            return `<span class="${value.traiteLe !== null ? 'text-info' : ''}">${value.nom ? value.nom + ' ' + (value.prenom ? value.prenom : '') : value.libre} ${value.type === 'DESTINATAIRE' && value.libre ? '(' + value.type + ' LIBRE' : '(' + value.type + ')'}</span>`;
          } else {
            return `<span class="${value.traiteLe !== null ? 'text-info' : ''}">${value.nom ? value.nom + ' ' + (value.prenom ? value.prenom : '') : value.libre} ${'(ACTION)'}</span>`;
          }
        },
      },
      {
        field: 'message',
        header: this.translate.instant('message'),
        format: (item, value) => {
          if (value && value.message) {
            return `<span class="${value.traiteLe !== null ? 'text-info' : ''}">${value.message}</span>`;
          } else {
            return 'Pas de message';
          }
        },
      },
      {
        field: 'action',
        header: this.translate.instant('action'),
        format: (item, value) => {
          if (value && value.action) {
            return `<span class="${value.traiteLe !== null ? 'text-info' : ''}">${value.action}</span>`;
          } else {
            return "Pas d'action";
          }
        },
      },
      {
        field: 'reponse',
        header: this.translate.instant('answer'),
        format: (item, value) => {
          if (value && value.reponse) {
            return `<span class="${value.traiteLe !== null ? 'text-info' : ''}">${value.reponse}</span>`;
          } else {
            return 'Pas de réponse';
          }
        },
      },
      {
        field: 'actions',
        header: this.translate.instant('toAnswer'),
        sortable: false,
        align: 'right',
        format: (value, item) => {
          const index = this.rightsPoints?.distribution.deleteUser.map((x) => x.mailDestinataireId).indexOf(item.id);
          if (
            this.rightsPoints &&
            this.rightsPoints?.distribution &&
            this.rightsPoints.distribution.deleteUser[index]?.replyToAction
          ) {
            if (item && item.contactIdiAdmin) {
              if (item.contactIdiAdmin === this.globalElement.userInfo.contactId && item.traiteLe === null) {
                // item.destinataire === this.userInfos.name
                return `<div class="btn btn-primary d-block w-100">Traiter l’action</div>`;
              } else {
                if (item.traiteLe !== null) {
                  return `<span class="text-info">Aucune action</span>`;
                } else {
                  return '/';
                }
              }
            } else {
              return '/';
            }
          } else {
            return '/';
          }
        },
        tooltip: (item) => {
          if (item && item.contactIdiAdmin === this.globalElement.userInfo.contactId && item.traiteLe === null) {
            // item.destinataire === this.userInfos.name
            return `Traiter l’action`;
          } else {
            return item.reponse ? item.reponse : `Aucune action`;
          }
        },
        click: (column, rowData) => {
          if (column && column.contactIdiAdmin === this.globalElement.userInfo.contactId && column.traiteLe === null) {
            this.answerToAction(column);
          }
        },
      },
    ];
  }
}
