export class Matieres {
     pointConfidentielParDefaut:boolean = false;
     description:string;
     utilisable:boolean = true;
     droitsUtilisation:number = 0;
     droitsVisualisation:number = 0;
     tri:string;
     id:number;
     public echevinats:Array<number> = [];
     constructor(data:any) {
         Object.assign(this, data);
     }
}
 