<div class="modal-header super-compos-modal-header">
  <h4 class="modal-title super-compos-modal-title">
    <span class="super-compos-modal-title super-compos-modal-title-span">Créer une action</span>
  </h4>

  <div class="d-flex align-items-center">
    <super-button (click)="closeActionModal()" text="Annuler" styleClass="mr-3 blue"> </super-button>
    <super-button (click)="submit()" text="Ajouter" styleClass="green"> </super-button>
  </div>
</div>
<div class="modal-body">
  <form [formGroup]="actionForm">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <h4 class="super-compos-section-h4">Message</h4>
          <!-- <input type="text" class="super-compos-input-text form-control w-100" formControlName="Description"
                        placeholder="Insérez le descriptif de l'action"> -->
          <textarea
            rows="5"
            cols="30"
            placeholder="Insérez le descriptif de l'action"
            formControlName="Description"
            class="super-compos-input-text form-control w-100"
            pInputTextarea></textarea>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group" *ngIf="configApp?.delibConfig?.hideStatutAction === false">
          <h4 class="super-compos-section-h4">Statut</h4>
          <p-dropdown
            [virtualScroll]="true"
            itemSize="30"
            styleClass="w-100"
            [options]="status"
            placeholder="Sélectionnez un statut"
            [showClear]="true"
            formControlName="Statut">
            <ng-template let-item pTemplate="item">
              <div class="d-flex align-items-center justify-content-between">
                <div class="truncateLabel">{{ item.label }}</div>
                <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="form-group">
          <h4 class="super-compos-section-h4">Action*</h4>

          <p-dropdown
            [virtualScroll]="true"
            itemSize="30"
            styleClass="w-100"
            [options]="types"
            placeholder="Sélectionnez une action"
            [showClear]="true"
            formControlName="Type">
            <ng-template let-item pTemplate="item">
              <div class="d-flex align-items-center justify-content-between">
                <div class="truncateLabel">{{ item.label }}</div>
                <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <h4 class="super-compos-section-h4">Filtrer destinataire par service</h4>
          <p-dropdown
            [options]="services | sort: 'label' : 'ASC'"
            [showClear]="true"
            placeholder="Sélectionnez un service"
            (onChange)="filterContact($event)"
            styleClass="w-100"
            [virtualScroll]="true"
            itemSize="30">
            <ng-template let-item pTemplate="item">
              <div class="d-flex align-items-center justify-content-between">
                <div class="truncateLabel">{{ item.label }}</div>
                <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <h4 class="super-compos-section-h4">Destinataires*</h4>
          <p-multiSelect
            [options]="actionDestinataire | sort: 'label' : 'ASC'"
            defaultLabel="Sélectionnez un destinataire"
            optionLabel="label"
            (onChange)="show($event)"
            styleClass="w-100"
            formControlName="ActionDestinataire"
            [required]="true"
            [virtualScroll]="true"
            itemSize="30">
            <ng-template let-title pTemplate="item">
              <div pTooltip="{{ title?.label }}" tooltipPosition="right" class="truncateLabel">{{ title.label }}</div>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Réponse : v2 ICOUR-754 -->
      <div class="col-6">
        <div class="p-fluid h-50">
          <h4 class="super-compos-section-h4">Date d'échéance</h4>
          <p-calendar
            [baseZIndex]="100000"
            [firstDayOfWeek]="1"
            [selectOtherMonths]="true"
            styleClass="h-100"
            formControlName="EndDate"
            icon="icon-calendar"
            [showIcon]="true"
            inputStyleClass="super-compos-input-text"
            dateFormat="dd/mm/yy"
            (onSelect)="checkSelectedDate($event)">
          </p-calendar>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <p-checkbox formControlName="AccuseReponse" binary="true"></p-checkbox>
          <span class="super-compos-section-h4 ml-2"> Souhaitez-vous recevoir un accusé de réponse ? </span>
        </div>
      </div>
    </div>
  </form>
</div>
<p-confirmDialog
  key="actionCreateModalConfirm"
  #cd
  appendTo="body"
  styleClass="super-compos-modal-content confirmModal"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  baseZIndex="1000">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Non" (click)="cd.reject()"></button>
    <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="Oui" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>
