import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { Contact } from 'src/app/shared/models/iContact/contact';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { ToastrService } from 'ngx-toastr';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService } from 'primeng/api';
import * as async from 'async';
import * as moment from 'moment';
import * as _ from 'underscore';
import { DialogService } from 'primeng/dynamicdialog';
import { ContactNewComponent } from 'src/app/shared/components/modals/contact-new/contact-new.component';
import { ApiService } from 'src/app/core/services/api.service';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
@Component({
  selector: 'app-contact-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class ContactDetailsComponent implements OnInit {
  @ViewChild(GoogleMap) map: GoogleMap;
  @ViewChild(MapInfoWindow) info: MapInfoWindow;
  public addresses: any[] = [];
  public gmap_options: google.maps.MapOptions = {
    // mapTypeId: 'hybrid',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 20,
  };
  public gmap_center: google.maps.LatLngLiteral = {
    lat: null,
    lng: null,
  };
  public infoContent: any;
  public mapMarker: any;
  public contact: Contact = null;
  public liens: any[] = [];
  public liensSocMembres: any[] = [];
  public liensSocSoc: any[] = [];
  public lat: any;
  public long: any;
  public adressLoaded = false;
  public modalReference: NgbModalRef;
  public contactName: string;
  public mapStyles = [
    {
      featureType: 'all',
      elementType: 'all',
      stylers: [
        {
          hue: '#e7ecf0',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: -70,
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          saturation: -60,
        },
      ],
    },
  ];

  // $$$Update $$$
  public colMbLinked = [
    {
      field: 'nameContact1',
      header: 'Contact 1',
      align: 'center',
      sortable: 'true',
      width: '20px',
    },
    {
      field: 'lien',
      header: 'Relation',
      align: 'center',
      sortable: 'true',
      width: '20px',
    },
    {
      field: 'nameContact2',
      header: 'Contact 2',
      align: 'center',
      sortable: 'true',
      width: '20px',
      // format: (value, item) => {
    },
    {
      field: 'fonction',
      header: 'Fonction',
      align: 'center',
      sortable: 'true',
      width: '20px',
      format: (value, item) => {
        if (item.fonction && item.fonction.fonction) {
          return item.fonction.fonction;
        } else {
          return '';
        }
      },
      export: (value, item) => {
        if (item.fonction) {
          return item.fonction;
        } else {
          return '';
        }
      },
    },
    {
      field: 'preview',
      header: 'Aperçu',
      align: 'center',
      sortable: false,
      width: '5px',
      format: (value, item) => {
        return `<i class="fas fa-eye"></i>`;
      },
      export: (value, item) => {
        return '';
      },
      click: (column, rowData) => {
        //this.openPreviewModal(column);
      },
    },
  ];
  public colSociety = [
    {
      field: 'name',
      header: 'Nom',
    },
    {
      field: 'adresse',
      header: 'Adresse',
    },
    {
      field: 'codePostal',
      header: 'Code postal',
    },
    {
      field: 'localite',
      header: 'Localité',
    },
    {
      field: 'fax',
      header: 'Fax',
    },
    {
      field: 'gsm',
      header: 'GSM',
    },
    {
      field: 'mail',
      header: 'Email',
    },
    {
      field: 'site',
      header: 'Site',
    },
    {
      field: 'telephone',
      header: 'Téléphone',
    },
    {
      field: 'titre',
      header: 'Titre',
    },
    {
      field: 'preview',
      header: 'Aperçu',
      align: 'center',
      sortable: false,
      width: '5px',
      format: (value, item) => {
        return `<i class="fas fa-eye"></i>`;
      },
      export: (value) => {
        return '';
      },
      click: (column, rowData) => {
        //this.openPreviewModal(column);
      },
    },
  ];

  public markers: any[] = [];
  public toggleMbArrow: boolean;
  public toggleScArrow: boolean;
  public principalAdress: any;
  public fullMode = false;
  public $moment = moment;
  public connectedUserRights = null; //* Rights of the user
  public allLangues = [];

  constructor(
    public route: ActivatedRoute,
    private iContactService: IContactService,
    private toastr: ToastrService,
    private router: Router,
    private confirmService: ConfirmationService,
    public ngZone: NgZone,
    public dialog: DialogService,
    public api: ApiService,
    public iAdminService: iAdminService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res: any) => {
      this.getLangues().then(() => {
        this.getContact(res.id);
        this.getConnectedUserRights();
      });
    });
  }

  getLangues() {
    return new Promise((resolve, reject) => {
      this.iAdminService.getGlobalLangues().subscribe((res) => {
        this.allLangues = res;
        resolve(true);
      });
    });
  }

  getContact(id: number) {
    this.iContactService.get(`/contacts/${id}`).subscribe(
      (response) => {
        this.contact = new Contact(response);
        this.adressLoaded = false;

        const contactLanguage = this.allLangues.find((x) => x.id === response.langueId);
        this.contact.langue = contactLanguage;

        this.contact.listContactsAdresses.map((add) => {
          this.codeAdresse(add);
        });
        this.toggleMbArrow = false;
        this.toggleScArrow = false;
        this.getLinksContacts();
      },
      (error) => {
        console.error(error);
      }
    );
  }
  public getLinksContacts() {
    this.liens = [];
    this.liensSocMembres = [];
    this.liensSocSoc = [];
    this.iContactService.getSocietesV2(this.contact.id).subscribe((res: any) => {
      if (res.typeSociete === false) {
        // C'est les sociétés liés
        res.liensMembres.map((item) => {
          this.liensSocSoc.push({
            name:
              (item.lienContact.nom ? item.lienContact.nom + ' ' : '') +
              (item.lienContact.prenom ? item.lienContact.prenom : ''),
            id: item.lienContact.id,
            adresse: item.lienInfo.adresse,
            codePostal: item.lienInfo.codePostal,
            fax: item.lienInfo.fax,
            gsm: item.lienInfo.gsm,
            localite: item.lienInfo.localite,
            mail: item.lienInfo.mail,
            relationId: item.lienInfo.relationId,
            site: item.lienInfo.site,
            telephone: item.lienInfo.telephone,
            titre: item.lienInfo.titre,
          });
        });
      } else {
        res.liensMembres.map((item) => {
          this.liensSocMembres.push({
            name:
              (item.lienContact.nom ? item.lienContact.nom + ' ' : '') +
              (item.lienContact.prenom ? item.lienContact.prenom : ''),
            id: item.lienContact.id,
            adresse: item.lienInfo.adresse,
            codePostal: item.lienInfo.codePostal,
            fax: item.lienInfo.fax,
            gsm: item.lienInfo.gsm,
            localite: item.lienInfo.localite,
            mail: item.lienInfo.mail,
            relationId: item.lienInfo.relationId,
            site: item.lienInfo.site,
            telephone: item.lienInfo.telephone,
            titre: item.lienInfo.titre,
          });
        });
      }
    });
  }

  //* Will retrieve the rights for the active user to display what can be seen
  public getConnectedUserRights() {
    this.api.getRightsManagement().subscribe((res: any) => {
      this.connectedUserRights = res;
    });
  }

  public codeAdresse(address) {
    this.lat = '';
    this.long = '';
    this.markers = [];

    let adress_full = '';
    const address_obj = address.adresse;
    if (address_obj) {
      adress_full =
        address_obj.impression_Adresse +
        ' ' +
        address_obj.impression_CodePostal +
        ' ' +
        address_obj.impression_Localite;
    }
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode(
      {
        address: adress_full,
      },
      (results, status) => {
        geocoder.geocode({ address: adress_full, componentRestrictions: { country: 'BE' } }, (results, status) => {
          if (status !== 'ZERO_RESULTS') {
            this.ngZone.run(() => {
              if (status === google.maps.GeocoderStatus.OK) {
                this.lat = results[0].geometry.location.lat();
                this.long = results[0].geometry.location.lng();
                this.gmap_center = {
                  lat: this.lat,
                  lng: this.long,
                };
                this.markers.push({
                  title: this.contact ? `${this.contact.firstName} ${this.contact.lastName}` : '',
                  position: {
                    lat: this.lat,
                    lng: this.long,
                  },
                  options: { animation: google.maps.Animation.DROP },
                  nbBoite: address_obj.structure_RueBoite,
                  number: address_obj.structure_RueNumero,
                  street: address_obj.impression_Adresse,
                  post_code: address_obj.impression_CodePostal,
                  city: address_obj.impression_Localite,
                });

                this.adressLoaded = true;
              } else {
              }
              if (status === google.maps.GeocoderStatus.REQUEST_DENIED) {
                this.adressLoaded = false;
              }
            });
          }
        });
      }
    );
  }

  onContactSelect(event) {}

  deleteSociety(event) {}

  onSocietySelect(event) {}

  openInfo(marker: any, markerInfo) {
    this.infoContent =
      markerInfo.street +
      ',' +
      (markerInfo.number ? markerInfo.number : '') +
      ' ' +
      (markerInfo.nbBoite ? markerInfo.nbBoite : '') +
      ' ' +
      markerInfo.post_code +
      ' ' +
      markerInfo.city;
    this.info.open(marker);
  }

  deleteContact() {
    this.confirmService.confirm({
      message: 'Voulez-vous vraiment supprimer ce contact?',
      key: 'detailModal',
      accept: () => {
        this.iContactService.delete(`/contacts/${this.contact.id}`).subscribe(
          (response) => {
            this.toastr.success('Merci', "Ce contact n'apparaitra plus dans votre liste de contact");
            async.waterfall([
              (cb) => {
                this.ngOnInit();
                cb(null);
              },
              (cb) => {
                this.router.navigateByUrl('/private/contacts');
              },
            ]);
          },
          (error) => {
            console.error(error);
          }
        );
      },
    });
  }

  goToEdition() {
    const dialog = this.dialog.open(ContactNewComponent, {
      width: '80vw',
      showHeader: false,
      data: {
        contactID: this.contact.id,
        hideSwitch: false,
      },
    });
    dialog.onClose.subscribe((res: any) => {
      this.ngOnInit();
    });
  }
}
