<div class="modal-header super-compos-modal-header">
    <h5 class="modal-title super-compos-modal-title">
        {{header}}
    </h5>
    <div class="d-flex align-items-center">
        <i class="fas fa-times-circle fa-lg cursor-pointer" (click)="ref.close()"></i>
    </div>
</div>
<div class="modal-body">
    <form [formGroup]="form" class="mt-3 mb-3 row participants">
        <div class="col-md-6 mb-2">
            <label class="input-label d-block">{{'seanceDate'|translate}}</label>
            <p-calendar formControlName="date" styleClass="w-100" appendTo="body" [monthNavigator]="true" dateFormat="dd/mm/yy"></p-calendar>
        </div>
        <div class="col-md-6 mb-2">
            <label class="input-label d-block">{{'seanceDate'|translate}}</label>
            <p-calendar formControlName="reunionFixeeLe" styleClass="w-100" appendTo="body" [monthNavigator]="true"  dateFormat="dd/mm/yy"></p-calendar>
        </div>
        <div class="col-md-6 mb-2">
            <label class="input-label d-block">{{'startHour'|translate}}</label>
            <ngb-timepicker formControlName="heureDebut"></ngb-timepicker>

        </div>
        <div class="col-md-6 mb-2">
            <label class="input-label d-block">{{'endHour'|translate}}</label>
            <ngb-timepicker formControlName="heureFin"></ngb-timepicker>
        </div>
        <div class="col-md-12 mb-2">
            <label class="input-label d-block">{{'address'|translate}}</label>
            <p-dropdown [options]="adressList" styleClass="w-100" optionLabel="description" optionValue="id" formControlName="lieuxId" [placeholder]="'selectAnAddress'|translate" appendTo="body"
                [showClear]="true" [filter]="true" [virtualScroll]="true" itemSize="30">
                <ng-template let-item pTemplate="item">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="truncateLabel">{{item.description}}</div>
                        <div> <i class="fas fa-info-circle" [pTooltip]="item.description"></i></div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>

    </form>
    <super-button [text]="'btn.modify'|translate" styleClass="green w-100 justifyButtonTextCenter" (click)="submitModificationMeeting()"></super-button>
</div>