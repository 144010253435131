<div class="container mt-3">
  <div class="row">
    <div class="col-md-12">
      <p-accordion>
        <p-accordionTab [selected]="true" header="Assistance - iDelibe {{ currentVersion }}">
          <div class="assistance">
            <super-assistance
              [text]="AssistanceText"
              [phone]="config?.phone"
              [email]="config?.email"></super-assistance>
          </div>
        </p-accordionTab>
        <p-accordionTab header="Notes de version">
          <super-title text="Notes de version" class="mt-3"></super-title>
          <div class="mb-3">
            <p class="mt-3 mb-3">Afin de voir les diverses notes de version, veuillez cliquer sur ce bouton :</p>
            <super-button styleClass="green" text="Voir les notes de version" (click)="openModal()"> </super-button>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</div>
