import { NgModule } from '@angular/core';
import { CallbackPipe } from './callback.pipe';
import {
  sizeFormat,
  toFixedFormat,
  lengthFormat,
  momentFormat,
  ucFirst,
  Join,
  SafeHtml,
  LabelByIdFilter,
  TriCheckBoxPipe,
  LabelByArrayFilter,
  momentFormatTranslation,
} from './format.pipe';
import { ArraySortPipe, ReversePipe } from './sorts.pipe';
import {
  FilterByKeyValue,
  SearchByValue,
  SearchByKeyValue,
  minDate,
  maxDate,
  isToday,
  isTomorrow,
  FilterByObject,
  isWeek,
  isMonth,
  isYear,
  StartWithByKeyValue,
  AdvancedSearch,
  removeEmptyLabel,
  FilterByStateActiveInactiveContact,
} from './filter.pipe';
import { QrCodeExplode } from './qrCode.pipe';

@NgModule({
  declarations: [
    CallbackPipe,
    sizeFormat,
    toFixedFormat,
    lengthFormat,
    momentFormat,
    momentFormatTranslation,
    FilterByObject,
    ArraySortPipe,
    ReversePipe,
    FilterByKeyValue,
    SearchByValue,
    SearchByKeyValue,
    minDate,
    maxDate,
    ucFirst,
    isToday,
    isTomorrow,
    isWeek,
    isMonth,
    isYear,
    FilterByObject,
    StartWithByKeyValue,
    Join,
    SafeHtml,
    LabelByIdFilter,
    AdvancedSearch,
    TriCheckBoxPipe,
    removeEmptyLabel,
    LabelByArrayFilter,
    FilterByStateActiveInactiveContact,
    QrCodeExplode,
  ],
  imports: [],
  exports: [
    CallbackPipe,
    sizeFormat,
    toFixedFormat,
    lengthFormat,
    momentFormat,
    momentFormatTranslation,
    FilterByObject,
    ArraySortPipe,
    ReversePipe,
    FilterByKeyValue,
    SearchByValue,
    SearchByKeyValue,
    minDate,
    maxDate,
    ucFirst,
    isToday,
    isTomorrow,
    isWeek,
    isMonth,
    isYear,
    FilterByObject,
    StartWithByKeyValue,
    Join,
    SafeHtml,
    LabelByIdFilter,
    AdvancedSearch,
    TriCheckBoxPipe,
    removeEmptyLabel,
    LabelByArrayFilter,
    FilterByStateActiveInactiveContact,
    QrCodeExplode,
  ],
  providers: [],
  entryComponents: [],
})
export class PipesModule {}
