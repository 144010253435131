import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { IContactService } from 'src/app/core/services/i-contact.service';
import * as _ from 'underscore';
import * as async from 'async';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Destinataire } from 'src/app/shared/models/destinataire';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { ContactNewComponent } from '../contact-new/contact-new.component';
declare let $: any;
@Component({
  selector: 'app-recipient-modal',
  templateUrl: './recipient-modal.component.html',
  styleUrls: ['./recipient-modal.component.scss'],
})
export class RecipientModalComponent implements OnInit {
  public openingWindow: any;
  public filteredUsers: Array<any> = [];
  public isServiceMode: boolean;
  public actualRecipients: any[] = [];

  public tabItems = [
    { header: 'Utilisateurs', value: 'users', valueBadge: 0 }, // this.users.length
    { header: 'Services / Groupes', value: 'services&groups', valueBadge: 0 }, // this.services_groups.length
    { header: 'Contacts', value: 'contacts', valueBadge: 0 }, // this.contacts.length
    { header: 'Destinataires libres', value: 'free', valueBadge: null },
  ];
  public searchString: string = '';
  public freeRecipient: string = '';
  public tabNewRecipient: any = [];
  public col = [
    {
      field: 'name',
      header: 'Nom',
    },
  ];
  public contactId: number;
  public transferMode: boolean = false;
  public tabCurrentSelected: number = 0;
  public userLoaded: boolean = false;

  public userAlreadySelected: Array<any>;
  public serviceAlreadySelected: Array<any>;

  public users: any[] = [];
  public contacts: any[] = [];
  public servicesList: any[] = [];
  public selectedItems: any[] = [];
  public selectedContacts: any[] = [];
  public selectedServices_groups: any[] = [];
  public selectedUsers: any[] = [];
  public groups: any = [];
  public services_groups: any[] = [];
  public contactArray: Array<any> = [];
  public serviceArray: Array<any> = [];
  public selectedGroups: Array<any> = [];
  public groupsArray: Array<any> = [];
  public usersArray: Array<any> = [];
  public typeContact: Array<any> = [];
  public searchTypeContact: number;
  public selectedTabs: any = null;
  public filteredGroups: Array<any> = [];
  public filteredContacts: Array<any> = [];
  public filteredServices: Array<any> = [];
  public modalReference: NgbModalRef;
  public contactChecked = [];
  public destinatairesNamePresent: string = '';
  public recipientsNames: Array<any> = [];
  public freeRecipientArray: Array<any> = [];

  public destinataires: Array<Destinataire>;

  constructor(
    private adminApi: iAdminService,
    private contactService: IContactService,
    private toastr: ToastrService,
    private dialogService: DialogService,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit() {
    this.userLoaded = false;
    this.getData();
    this.contactArray = [];
    this.usersArray = [];
    this.serviceArray = [];
    this.groupsArray = [];
  }

  getServiceList() {
    this.adminApi.getGlobalServices().subscribe(
      (response) => {
        const arrayParent = [];
        const arrayChildren = [];
        response.map((item) => {
          if (item.parentId === 0) {
            item.children = [];
            if (item.description !== null) {
              arrayParent.push(item);
            }
          } else {
            if (item.description !== null) {
              arrayChildren.push(item);
            }
          }
        });

        if (arrayParent.length > 0 && arrayChildren.length > 0) {
          arrayParent.map((parent) => {
            arrayChildren.map((child) => {
              child.value = child.id;
              child.label = child.description;
              if (parent.id === child.parentId) {
                parent.label = parent.description;
                parent.value = parent.id;
                parent.children.push({ label: child.description, value: child.id, parent: parent });
              } else {
                parent.label = parent.description;
                parent.value = parent.id;
              }
            });
          });
        } else {
          arrayParent.map((parent) => {
            parent.label = parent.description;
            parent.value = parent.id;
            parent.children = [];
          });
        }
        // Pour avoir les enfants & parents au meme niveau
        let fusionnedArray = [];
        fusionnedArray = arrayParent.concat(arrayChildren);
        fusionnedArray.map((item) => {
          if (item.parent && item.parent.label) {
            item.name = item.parent.label + ' / ' + item.label;
          } else {
            item.name = item.label;
          }
          item.isGroup = 'non';
        });
        this.servicesList = fusionnedArray;

        const nodes = [];
        function recursive(item) {
          if (Array.isArray(item.children)) {
            item.children.map((y) => {
              nodes.push(y);
              recursive(y);
            });
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  /**
   * Initialize all array of the component
   * 1 - get the connected user's info
   * 2 - retreive all town's contact
   * 3 - get all groups
   * 4 - merge all contact to fill the 'this.allContacts
   */
  getData() {
    async.waterfall([
      (cb) => {
        // get connected user's info
        this.adminApi.getUserInfo().subscribe(
          (response: any) => {
            this.contactId = response.contactId;
            cb(null);
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (cb) => {
        // get all groups
        this.services_groups = [];
        this.adminApi.getGroupes().subscribe(
          (response) => {
            let tempServices = [];
            this.groups = response;
            this.groups.map((group) => {
              group.name = group.description;
              group.isGroup = 'oui';
              group.type === 'GROUPE';
            });
            if (this.serviceAlreadySelected && this.serviceAlreadySelected.length > 0) {
              tempServices = this.servicesList.filter(
                (service) => !this.serviceAlreadySelected.some((serviceSelected) => service.id === serviceSelected.id)
              );
            } else {
              tempServices = this.servicesList;
            }
            // tempService = _.uniq(tempService, 'id');
            cb(null);
          },
          (error) => {
            console.error(error);
            this.toastr.error('Impossible de charger les groupes!');
          }
        );
      },
      (cb) => {
        this.adminApi.getContactTypeAdmin().subscribe(
          (response: any) => {
            response.map((typeContact) => {
              typeContact.label = typeContact.typeContact;
              typeContact.value = typeContact.id;
            });
            this.typeContact = response;
            cb(null);
          },
          (error) => {
            console.error(error);
            this.toastr.error('Impossible de charger les types de contact');
          }
        );
      },
      (cb) => {
        this.contactService.post(`/contacts/lowinformation`, {}).subscribe(
          (response) => {
            this.contacts = response;
            this.contacts.map((contact) => {
              contact.checked = false;
              contact.name = (contact.nom ? contact.nom + ' ' : '') + (contact.prenom ? contact.prenom : '');
              contact.value = contact.id;
            });
            this.contacts = this.contacts.filter((x) => x.prenom || x.nom);
            cb(null);
          },
          (error) => {
            console.error(error);
            this.toastr.error('Erreur lors du chargement des contacts');
          }
        );
      },
      (cb) => {
        cb(null);
      },
      (cb) => {
        this.contactService.getUserWithDataAdmin().subscribe(
          (response: any) => {
            const temp = [];
            response.map((item) => {
              temp.push({
                name: item.contactForUser.prenom + ' ' + item.contactForUser.nom,
                nom: item.contactForUser.nom,
                prenom: item.contactForUser.prenom,
                typeContactID: item.contactForUser.contactId,
                id: item.contactForUser.contactId,
                value: item.contactForUser.contactId,
                isUser: true,
                userId: item.userId,
                active: item.active,
              });
            });
            this.users = temp;
            cb(null);
          },
          (error) => {
            console.error(error);
            this.toastr.error('Impossible de charger les types de contact');
          }
        );
      },
      (cb) => {
        this.getTabItems();
        this.userLoaded = true;
        cb(null);
      },
    ]);
  }

  public receive_item(event, tabItem) {
    if (tabItem.value === 'contacts') {
      this.contactArray = event;
      this.selectedContacts.length <= 0 ? (this.selectedContacts = event) : this.selectedContacts.push(event);
    }
    if (tabItem.value === 'services') {
      this.serviceArray = event;
      this.selectedServices_groups.length <= 0
        ? (this.selectedServices_groups = event)
        : this.selectedServices_groups.push(event);
    }
    if (tabItem.value === 'groups') {
      this.groupsArray = event;
      this.selectedGroups.length <= 0 ? (this.selectedGroups = event) : this.selectedGroups.push(event);
    }
    if (tabItem.value === 'users') {
      this.usersArray = event;
      this.selectedUsers.length <= 0 ? (this.selectedUsers = event) : this.selectedUsers.push(event);
    }

    if (this.serviceArray.length > 0) {
      this.serviceArray.map((service) => {
        service.type = 'SERVICE';
      });
    }
    if (this.contactArray.length > 0) {
      this.contactArray.map((contact) => {
        contact.type = 'CONTACT';
      });
    }
    if (this.groupsArray.length > 0) {
      this.groupsArray.map((groupe) => {
        groupe.type = 'GROUPE';
      });
    }
    if (this.usersArray.length > 0) {
      this.usersArray.map((contact) => {
        contact.type = 'CONTACT';
      });
    }
  }

  public getTabItems() {
    async.waterfall([
      (cb) => {
        this.tabItems = [
          { header: 'Utilisateurs', value: 'users', valueBadge: this.users.length }, // this.users.length
          { header: 'Groupes', value: 'groups', valueBadge: this.groups.length },
          { header: 'Contacts', value: 'contacts', valueBadge: this.contacts.length }, // this.contacts.length
          { header: 'Destinataires libres', value: 'free', valueBadge: null },
        ];
        cb(null);
      },
      (cb) => {
        this.switchTabs(this.tabItems[0]);
      },
    ]);
  }

  test2() {}

  switchTabs(e) {
    this.userLoaded = false;
    this.filteredUsers = [];
    this.searchTypeContact = null;
    const tabCurrentSelected = e;
    this.selectedTabs = e;
    tabCurrentSelected.value === 'contacts'
      ? (this.col = [
          { field: 'name', header: 'Nom' },
          { field: 'typeContactDesc', header: 'Type' },
        ])
      : '';
    this.filterBy(tabCurrentSelected);
  }

  filterBy(e) {
    const value = e.value;
    if (this.isServiceMode) {
      this.isServiceMode = false;
    }
    this.filteredUsers = [];
    this.filteredContacts = [];
    this.filteredServices = [];
    this.filteredGroups = [];
    switch (value) {
      case 'contacts':
        this.isServiceMode = false;
        this.filteredUsers = [];
        this.filteredGroups = [];
        this.filteredServices = [];
        this.filteredContacts = this.contacts.filter((x) => !x.nePlusUtiliser);
        this.filteredContacts.map((item) => {
          if (item.name) {
            item.name = item.name.trimStart();
            item.active = !item.nePlusUtiliser;
          }
        });
        this.tabItems.find((x) => x.value === 'contacts').valueBadge = this.contacts.length;
        break;
      case 'all':
        this.isServiceMode = false;
        this.filteredUsers = this.users;
        this.filteredUsers.map((item) => {
          if (item.name) {
            item.name = item.name.trimStart();
          }
        });
        this.filteredUsers.push(this.servicesList);
        break;
      case 'services':
        this.filteredUsers = [];
        this.filteredContacts = [];
        this.filteredGroups = [];
        this.filteredServices = this.servicesList;
        this.tabItems.find((x) => x.value === 'services').valueBadge = this.servicesList.length;
        this.isServiceMode = true;

        break;
      case 'groups':
        this.filteredUsers = [];
        this.filteredContacts = [];
        this.filteredServices = [];
        this.filteredGroups = this.groups;
        this.filteredGroups.map((item) => {
          if (item.name) {
            item.name = item.name.trimStart();
          }
        });
        this.tabItems.find((x) => x.value === 'groups').valueBadge = this.groups.length;
        this.isServiceMode = false;

        break;
      case 'users':
        this.isServiceMode = false;
        this.filteredUsers = [];
        this.filteredContacts = [];
        this.filteredGroups = [];
        this.filteredUsers = this.users;
        this.filteredUsers.map((item) => {
          if (item.name) {
            item.name = item.name.trimStart();
          }
        });
        this.tabItems.find((x) => x.value === 'users').valueBadge = this.users.length;
        break;
      case 'free':
        this.filteredUsers = [];
        break;
    }
    this.userLoaded = true;
  }

  /**
   * Add a free recipient into selectedItems
   * @param form data receive by the form
   */
  addRecipientFree(form) {
    if (this.freeRecipient !== '' && this.freeRecipient !== null) {
      this.freeRecipientArray = [];
      this.freeRecipientArray = this.freeRecipient.split(';');
      if (this.freeRecipientArray.length > 0) {
        this.freeRecipientArray.map((recipient) => {
          this.tabNewRecipient.push({ checked: true, name: recipient, id: recipient });
          this.selectedItems.push({ checked: true, name: recipient, id: recipient });
        });
      }
      if (this.tabNewRecipient.length >= 1) {
        this.tabNewRecipient = _.uniq(this.tabNewRecipient, 'name');
      }
    }
  }

  /**
   * Add selectedItems to mail Destinataire => send it to parent
   */
  addUsers() {
    const data = {
      contactArray: this.contactArray,
      usersArray: this.usersArray,
      groupsArray: this.groupsArray,
      serviceArray: this.serviceArray,
      freeRecipientArray: this.freeRecipientArray,
    };
    this.openingWindow.close(data);
  }

  closeActionModal() {
    this.openingWindow.close();
    sessionStorage.removeItem('showModalDestinataire');
  }

  openNewContact() {
    //TODO new contact + quand on revient sur fiche point => enlever les services évidemment
    const ref = this.dialogService.open(ContactNewComponent, {
      styleClass: 'super-compos-modal-content',
      dismissableMask: false,
      width: '70vw',
      closable: false,
      showHeader: false,
      baseZIndex: 1000,
      data: {
        caller: 'linked',
        hideSwitch: false,
        companyMode: false,
      },
    });
    $(document).ready(function () {
      const modalContent: any = $('.modal-content');
      modalContent.draggable({
        handle: '.modal-header',
        revert: false,
        revertDuration: 300,
      });
    });
    ref.onClose.subscribe((value) => {
      if (value) {
        const temp = this.filteredContacts;
        this.filteredContacts = [];
        setTimeout(() => {
          temp.push({
            id: value.id,
            label: (value.prenom ? value.prenom : '') + ' ' + (value.nom ? value.nom : ''),
            name: (value.prenom ? value.prenom : '') + ' ' + (value.nom ? value.nom : ''),
            nePlusUtiliser: value.nePlusUtiliser,
            nom: value.nom,
            prenom: value.prenom,
            typeContact: null,
            typeContactID: value.typeContactId,
            typeContactName: null,
            typeSociete: value.typeSociete,
            value: value.id,
          });
          this.filteredContacts = temp;
        }, 1500);
      }
    });
  }
}
