import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import * as async from 'async';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { iAdminService } from 'src/app/core/services/iAdmin.service';

@Component({
  selector: 'app-action-response-modal',
  templateUrl: './action-response-modal.component.html',
  styleUrls: ['./action-response-modal.component.scss'],
})
export class ActionResponseModalComponent implements OnInit {
  public openingWindow: any;
  public model: any = {};
  public currentAction: any;
  public actualUser: any;
  public caller: string;
  public status: Array<any>;
  public types: Array<any>;
  public pointId: number;

  public actionResponse: FormGroup = new FormGroup({
    Response: new FormControl(null),
    ResponseValidateAction: new FormControl(null),
    Statut: new FormControl(null),
  });
  public showStatutInput: boolean = false;
  constructor(
    private api: ApiService,
    private iContactService: IContactService,
    private toastr: ToastrService,
    public confirmationService: ConfirmationService,
    public dataService: DataService,
    private adminApi: iAdminService
  ) {}

  ngOnInit() {
    // Set current status of the action
    this.actionResponse.patchValue({
      Statut: this.currentAction.statut,
      Response:
        this.currentAction.reponse === 'Aucune réponse' || this.currentAction.reponse === 'Pas de réponse'
          ? ''
          : this.currentAction.reponse,
    });
    this.dataService.getGlobalElements.subscribe((res: any) => {
      if (res && res.configApp.mails && res.configApp.mails.hideStatutAction === false) {
        this.showStatutInput = true;
      } else {
        this.showStatutInput = false;
      }
    });
    this.getConnectedUser();
  }

  /**
   * Retreive data of connected User + call method to get name
   */
  getConnectedUser() {
    this.adminApi.getUserInfo().subscribe(
      (response) => {
        this.actualUser = response;
        this.actualUser.contactId !== null ? this.getContactById(this.actualUser.contactId) : '';
      },
      (error) => {
        console.error(error);
        this.toastr.error('Impossible de récupérer les informations de la personne connectée!');
      }
    );
  }

  /**
   * Retreive contact with his ID
   * @param id contactId
   * Add name to actualUser connected array
   */
  getContactById(id: number) {
    this.iContactService.getContactAdmin(id).subscribe((response: any) => {
      this.actualUser.name = response.nom + ' ' + response.prenom;
    });
  }

  closeModal() {
    this.openingWindow.close();
  }

  /**
   * Execute the req and send the answer
   */
  sendResponse() {
    // Fill data to push the response
    const data: any = {
      statut: this.actionResponse.value.Statut,
      reponse: this.actionResponse.value.Response,
      validated: this.actionResponse.value.ResponseValidateAction
        ? this.actionResponse.value.ResponseValidateAction
        : false, // Indicates wether the response is validated and will be marked as treated.If False, we will only update Reponse and Statut fields.
      suiviReponseMessage:
        this.actionResponse.value.suiviReponseMessage !== '' ? 'Réponse de ' + this.actualUser.name : '', // Si le champ AccuseReponse / SuiviDeReponse est à 1, alors il faut envoyer « Réponse de xxxxx » où xxxxx = nom de la personne qui a répondu
      transmissionUserContactId: this.actualUser.contactId,
    };
    async.waterfall([
      (cb) => {
        if (this.actualUser.contactId) {
          this.iContactService.getRemplacant(this.actualUser.contactId).subscribe((res: any) => {
            const hasAbsentPeopleArray = [];
            if (res && res.length > 0) {
              res.map((remplacant) => {
                if (remplacant.absent === true && this.actualUser.contactId === remplacant.contactId) {
                  hasAbsentPeopleArray.push(remplacant);
                }
              });
            }
            if (hasAbsentPeopleArray.length > 0) {
              this.confirmationService.confirm({
                message: 'Souhaitez-vous remplacer automatiquement les personnes qui sont en congés ?',
                key: 'actionResponseConfirm',
                accept: () => {
                  res.map((item) => {
                    if (item.absent) {
                      if (this.actualUser.contactId === item.contactId) {
                        this.iContactService.get('/contacts/' + item.remplaceParContactId).subscribe((res: any) => {
                          if (res) {
                            data.replacementContactId = item.remplaceParContactId;
                            cb(null);
                          }
                        });
                      } else {
                      }
                    } else {
                      cb(null);
                    }
                  });
                },
                reject: () => {
                  cb(null);
                },
              });
            } else {
              cb(null);
            }
          });
        }
      },
      (cb) => {
        this.api.replyToPointAction(this.pointId, this.currentAction.id, data).subscribe(
          // mail id , action id , action reply
          (response) => {
            this.toastr.success('Réponse envoyée!');
            this.openingWindow.close(true);
          },
          (error) => {
            console.error(error);
            this.toastr.error("Erreur lors de l'envoi de la réponse!");
            this.openingWindow.close(false);
          }
        );
      },
    ]);
  }
}
