export class Roles {
    public id:number;
     ordreDansPresences:string;
     description:string;
     prefixe:string;
     suffixe:string;
     priorite:number = 0;
     constructor(data:any) {
         Object.assign(this, data);
     }
}
 