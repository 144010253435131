<div class="modal-header super-compos-modal-header">
  <h5 class="modal-title super-compos-modal-title">
    {{ header }}
  </h5>
  <div class="d-flex align-items-center">
    <super-button [text]="'btn.register' | translate" styleClass="blue mr-3" (click)="submit()"></super-button>
    <i class="fas fa-times-circle fa-lg cursor-pointer" (click)="ref.close()"></i>
  </div>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="row mt-3">
    <div class="col-md-12 mb-3" *ngIf="mode === 'solo'">
      <label for="contact" class="super-compos-label-input">{{ 'user' | translate }}</label>
      <p-dropdown
        [virtualScroll]="true"
        itemSize="30"
        styleClass="w-100"
        [options]="contacts | sort: 'label' : 'ASC'"
        placeholder="{{ 'selectAContact' | translate }}"
        formControlName="contactId"
        [filter]="true"
        [showClear]="true"
        appendTo="body">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div>{{ item.label | maxLength: 50 }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col-md-12 mb-3">
      <label for="contact" class="super-compos-label-input">{{ 'newStatus' | translate }}</label>
      <p-dropdown
        [virtualScroll]="true"
        itemSize="30"
        styleClass="w-100"
        [options]="globalElements?.statuts_points_LV"
        placeholder="{{ 'selectAStatus' | translate }}"
        formControlName="statutId"
        [filter]="true"
        [showClear]="true"
        appendTo="body">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div>{{ item.label | maxLength: 50 }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col-md-12 mb-3">
      <label for="contact" class="super-compos-label-input">{{ 'message' | translate }}</label>
      <textarea pInputTextarea formControlName="message" class="form-control" rows="5" cols="20"></textarea>
    </div>
  </form>
</div>
