<div class="modal-header super-compos-modal-header">
  <h4 class="modal-title super-compos-modal-title">
    <span class="super-compos-modal-title super-compos-modal-title-span">{{ 'generateDocForPoint' | translate }}</span>
  </h4>
  <div class="d-flex align-items-center">
    <super-button (click)="ref.close()" text="Annuler" styleClass="mr-3 blue"> </super-button>
    <super-button (click)="submit()" text="Confirmer" styleClass="mr-3 ml-3 green"> </super-button>
  </div>
</div>
<div class="modal-body mt-4">
  <section>
    <div class="row mb-3">
      <div class="col-12 alert alert-warning" *ngIf="arrayOfPointOrderGenerated.length > 0">
        {{ 'info.documentAlreadyGenerated' | translate }}
      </div>
      <div class="col-md-12 mb-3">
        <label class="super-compos-label-input">{{ 'generateDocWithModel' | translate }} :</label>
        <p-dropdown
          [virtualScroll]="true"
          itemSize="30"
          [(ngModel)]="modeleIdToGenerateInMass"
          (onChange)="checkIfDocumentAlreadyForModelPoint($event.value)"
          [options]="modelesDocPoint"
          styleClass="w-100"
          [placeholder]="'selectModeles' | translate"
          appendTo="body"
          [showClear]="true"
          (onClear)="clearModeleId()"
          [filter]="true"
          [readonly]="false"
          optionValue="id">
          <ng-template let-item pTemplate="item">
            <div class="d-flex align-items-center justify-content-between">
              <div class="truncateLabel">{{ item.description }}</div>
              <div><i class="fas fa-info-circle" [pTooltip]="item.description"></i></div>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="selectedItem">
            <span>{{ item.description }}</span>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="col-md-12" *ngIf="items && items.length > 0 && modeleIdToGenerateInMass">
        <!-- vu avec KHA : pour le moment on met l'oeil en 3eme colonne pour visualiser le modèle s'il existe à plus tard :D on fait simple pour l'instant-->
        <i-table
          #superTableListOfPointsToGenerateDocument
          [columns]="columns"
          [config]="tableConfigurations"
          [items]="items"
          id="pointsCheckIfDocumentsGeneratedv1">
          <p-checkbox
            binary="true"
            label="{{ 'global.selectAll' | translate }}"
            [(ngModel)]="selectAll"
            (click)="selectAllDoc()"></p-checkbox>
        </i-table>
      </div>
    </div>
  </section>
</div>

<p-confirmDialog
  #cd
  appendTo="body"
  styleClass="super-compos-modal-content confirmModal"
  header="'confirm.confirmHeader' | translate"
  icon="pi pi-exclamation-triangle"
  key="generateDocLotConfirm">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="'No' | translate" (click)="cd.reject()"></button>
    <button
      class="pButtonRed"
      type="button"
      pButton
      icon="pi pi-check"
      label="'Yes' | translate"
      (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>
