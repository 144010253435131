import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import { Button } from 'src/app/shared/models/button';
import { Point } from 'src/app/shared/models/point';
import * as async from 'async';
import { Service } from 'src/app/shared/models/service';
import { Global_Class } from 'src/app/shared/models/global';
import * as _ from 'underscore';
import { DialogService } from 'primeng/dynamicdialog';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { ITableColumn, ITableConfig, SuperColumn, SuperTableComponent } from '@devinforius/super-compos';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Observable } from 'rxjs';
import { StatusChangeComponent } from 'src/app/shared/components/modals/status-change/status-change.component';
import { ToastrService } from 'ngx-toastr';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { UserInfo } from 'src/app/shared/models/user-info';
import { tap } from 'rxjs/operators';
import { UserRightsService } from 'src/app/core/services/user-rights.service';
import { File } from 'src/app/shared/models/files';
import { FormControl, FormGroup } from '@angular/forms';
import { Dialog } from 'primeng/dialog';
import * as moment from 'moment';
import { TenantInfo } from 'src/app/shared/models/tenant-info';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-point-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss'],
})
export class PointTablesComponent implements OnInit {
  public tableConfigurations: ITableConfig;
  public orderCategoryASC = { open: 1, pending: 2, closed: 3 };
  public orderCategoryDESC = { closed: 1, pending: 2, open: 3 };
  public title;
  public loaded = false;
  public buttons: Array<Button> = [];
  public items: Array<Point> = [];
  //#region Columns
  public columns: Array<ITableColumn> = []; //#endregion
  public menu: MenuItem[] = [
    {
      label: this.translate.instant('btn.openPoint'),
      icon: 'bi bi-arrow-up-right-square',
      command: (data) => {
        this.showDetails(data);
      },
    },
  ];
  public services: Service[] = [];
  public listOfContacts = [];
  public listOfContactsAdmin = [];
  public globalElements;
  public listOfServices: Array<Service> = [];
  public dataToSendToQuery: any = {};
  public isTitle: boolean = false;
  public isSearch: boolean = false;
  public fullData;
  public pageSize: number = 50;
  public pageSizeTemp: number = 100;
  public subscriber: any;
  public loadingTable: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  public points$: Observable<any>;
  @ViewChild('superTable') public superTable: SuperTableComponent;
  @ViewChild('formDialogDuplicatePoint') formDialogDuplicatePoint: Dialog;
  @ViewChild('superTableFilesDuplication') public superTableFilesDuplication: SuperTableComponent;

  public forselection = [];
  public user: UserInfo = null;
  public statutsPoints;
  public totalRecords: number;
  public rows: number;
  public first = 0;
  public globalConfig = JSON.parse(sessionStorage.getItem('globalConfig'));
  public allSortFieldsToBeSortedByFrontend = [
    'service',
    'linkedSeanceLabel',
    'classementLabel',
    'dossierClassementLabel',
    'matiere',
    'typeDecisionLabel',
    'typePoint',
    'typeSeanceLabel',
    'urgenceNiveauLabel',
    'agentTraitantLabel',
    'echevinatLabel',
  ];
  public allSortFieldToBeModifiedForSortFrontend = [
    { label: 'typeSeanceId', value: 'seanceTypeName' },
    { label: 'serviceId', value: 'service' },
    { label: 'linkedSeance', value: 'linkedSeanceLabel' },
    { label: 'classementId', value: 'classementLabel' },
    { label: 'dossierClassementId', value: 'dossierClassementLabel' },
    { label: 'matiereId', value: 'matiere' },
    { label: 'statutId', value: 'statut' },
    { label: 'agentTraitantId', value: 'agentTraitantLabel' },
    { label: 'typeDecisionId', value: 'typeDecisionLabel' },
    { label: 'typeId', value: 'typePoint' },
    { label: 'typeSeanceId', value: 'typeSeanceLabel' },
    { label: 'urgenceNiveauId', value: 'urgenceNiveauLabel' },
    { label: 'echevinatId', value: 'echevinatLabel' },
    { label: 'courrierId', value: 'courrierIdLabel' },
  ];
  public selections: Array<any> = [];
  public isUserAbleToAddPoint: boolean = false;
  public sizeOfPages = [
    { label: 'Maximum 50 points affichés', value: 50 },
    { label: 'Maximum 200 points affichés', value: 200 },
    { label: 'Maximum 500 points affichés', value: 500 },
    { label: 'Maximum 1000 points affichés', value: 1000 },
    { label: 'Maximum 5000 points affichés', value: 5000 },
  ];
  public globalConfiguration;
  public displayCourrierId = false;
  //#region files
  public files: Array<File> = [];
  public columnsFiles: Array<SuperColumn> = [
    {
      field: 'description',
      header: 'description',
      filterable: true,
    },
    {
      field: 'extension',
      header: 'extension',
      filterable: true,
    },
    {
      field: 'fileType',
      header: this.translate.instant('fileType'),
      format: (value, item) => {
        if (item.modeleId && item.extension.toLowerCase() === 'pdf') {
          return this.translate.instant('document');
        } else if (item.annexeNumero) {
          return this.translate.instant('annexN°') + ' ' + item.annexeNumero;
        } else {
          return this.translate.instant('associatedFile');
        }
      },
      filterable: true,
    },
    {
      field: 'dateUpload',
      header: 'dateupload',
      format: (value) => {
        if (value) {
          return moment(value).format('DD/MM/YYYY');
        }
      },
      filterable: true,
    },
  ];
  //#endregion

  //#Region duplicate point
  public openDuplicationManagementModal: boolean = false;
  public duplicationManagementIndex: number = 0;
  public chosenPointToDuplicate = null;
  public formDuplicationPoint: FormGroup = new FormGroup({
    infos: new FormControl(true),
    files: new FormControl(null),
    listOfFiles: new FormControl(null),
    recipients: new FormControl(null),
  });
  public filesForDuplication: Array<File> = [];
  tableState: any;
  //#endregion

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private api: ApiService,
    private dataS: DataService,
    public dialogService: DialogService,
    public apiAdmin: iAdminService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public icontactService: IContactService,
    public userRightsService: UserRightsService,
    private titleService: Title,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Points');
    this.activatedRoute.params.subscribe((route: any) => {
      this.translateForSelection();
      this.spinner.show();
      this.getColumns();
      async.waterfall(
        [
          (callback) => {
            this.apiAdmin.getTenant().subscribe(
              (res: TenantInfo) => {
                res.applications?.map((x) => {
                  if (x.name === 'iCourrier') {
                    this.columns.push({
                      field: 'courrierId',
                      header: 'ID du courrier',
                      visible: false,
                      format: (value, item) => {
                        if (value) {
                          return value;
                        } else {
                          return '';
                        }
                      },
                      export: (value) => {
                        return value;
                      },
                      filterable: true,
                    });
                  }
                });
                callback();
              },
              (err) => {
                this.toastr.error('Une erreur est survenue dans la récupération du tenant!');
                callback();
              }
            );
          },
          (callback) => {
            this.setFirstInLocalStorage(0);
            callback();
          },
          (callback) => {
            this.loadTableState();
            callback();
          },
          (callback) => {
            this.apiAdmin.getUserInfo().subscribe((userInfo) => {
              this.user = new UserInfo(userInfo);
              callback();
            });
          },
          (callback) => {
            this.dataS.getGlobalElements.subscribe((res: any) => {
              this.globalElements = new Global_Class(res);
              if (this.globalElements.isLoaded()) {
                callback(null);
              }
            });
          },
          (callback) => {
            this.icontactService.getLowContacts().subscribe((res: any) => {
              this.listOfContacts = res;
              callback();
            });
          },
          (callback) => {
            this.icontactService.getLowContactsAdmin().subscribe((res: any) => {
              this.listOfContactsAdmin = res;
              callback();
            });
          },
          (callback) => {
            // let arrayOfKeys = ["btn.openPoint","btn.modify","btn.generateDocument","btn.duplicate","btn.encodeVotes","btn.createPoint", "statusModification"]
            const arrayOfKeys = ['btn.openPoint'];
            this.translate.get(arrayOfKeys).subscribe((res) => {
              this.menu[0].label = res['btn.openPoint'];
              callback();
            });
          },
          (callback) => {
            this.tableState.sortOrder = this.tableState.sortOrder ? (this.tableState.sortOrder === 1 ? 'ASC' : 'DESC') : '';
            if (this.dataS.advancedSearchPoint) {
              this.title = 'Recherche avancée sur les points';
              this.dataToSendToQuery = this.dataS.advancedSearchPoint;
              //* Need to encore ObjetSynthese as URI unit 'cause it can contain special characters
              if (this.dataToSendToQuery.ObjetSynthese) {
                this.dataToSendToQuery.RechercheGlobale = this.dataToSendToQuery.ObjetSynthese;
                delete this.dataToSendToQuery.ObjetSynthese;
              }
              this.getPoints(this.dataToSendToQuery, 1, this.tableState.sortField, this.tableState.sortOrder).subscribe(() => {
                // callback();
                //j'ai enlevé cela afin que le reste des queries ne se fasse pas : pas besoin d'envoyer des requêtes api sur route.titre/search comme on a déjà tout ici!
                //En sachant que searchTab/search est déjà utilisé pour la recherche via input... p-ê changer simplement le routing pour autre chose genre searchTab/advancedSearch
                this.getColumns();
                this.getITableConfigurations();
                window.onbeforeunload = () => this.ngOnDestroy();
                this.loaded = true;
                this.spinner.hide();
              });
            } else {
              callback(null);
            }
          },
          (callback) => {
            this.api.getGlobalAllPointsStatuts().subscribe((res: any) => {
              this.statutsPoints = res;
              callback();
            });
          },
          (callback) => {
            if (route.title) {
              this.isTitle = true;
              this.isSearch = false;
              this.items = [];
              this.fullData = null;
              switch (route.title) {
                case 'all': {
                  this.title = this.translate.instant('title.allPoints');
                  this.dataToSendToQuery = {
                    PageSize: this.pageSize,
                    PageNumber: 1,
                  };
                  this.getPoints(this.dataToSendToQuery, 1, this.tableState.sortField, this.tableState.sortOrder).subscribe((res: any) => {
                    callback();
                  });
                  break;
                }
                case 'aCorriger': {
                  this.dataToSendToQuery = {
                    PageSize: this.pageSize,
                    PageNumber: 1,
                    ACorriger: true,
                  };
                  this.title = this.translate.instant('title.aCorriger');
                  this.getPoints(this.dataToSendToQuery, 1, this.tableState.sortField, this.tableState.sortOrder).subscribe((res: any) => {
                    callback();
                  });
                  break;
                }
                case 'pourInfo': {
                  this.dataToSendToQuery = {
                    PageSize: this.pageSize,
                    PageNumber: 1,
                    Tag: 'pourInfo',
                  };
                  this.title = this.translate.instant('title.pourInfo');
                  this.getPoints(this.dataToSendToQuery, 1, this.tableState.sortField, this.tableState.sortOrder).subscribe((res: any) => {
                    callback();
                  });
                  break;
                }
                case 'pourAction': {
                  this.dataToSendToQuery = {
                    PageSize: this.pageSize,
                    PageNumber: 1,
                    Tag: 'pourAction',
                  };
                  this.title = this.translate.instant('title.pourAction');
                  this.getPoints(this.dataToSendToQuery, 1, this.tableState.sortField, this.tableState.sortOrder).subscribe((res: any) => {
                    callback();
                  });
                  break;
                }
                case 'mesPointsEnRedaction': {
                  const statut = this.statutsPoints.find((x) => (x.statutAutiliserLorsCreation = true));
                  this.dataToSendToQuery = {
                    PageSize: this.pageSize,
                    PageNumber: 1,
                    CreeParContactId: this.user.contactId,
                    statutIds: statut?.id,
                  };
                  this.title = this.translate.instant('title.mesPointsEnRedaction');
                  this.getPoints(this.dataToSendToQuery, 1, this.tableState.sortField, this.tableState.sortOrder).subscribe((res: any) => {
                    callback();
                  });
                  break;
                }

                default: {
                  //Si on arrive ici c'est qu'on a l'id du statut
                  const index = this.globalElements.statuts_points_LV.map((x) => x.value).indexOf(+route.title);
                  if (index > -1) {
                    // this.title = this.translate.instant(route.title);
                    this.title = this.globalElements.statuts_points[index].description;
                    this.dataToSendToQuery = {
                      PageSize: this.pageSize,
                      PageNumber: 1,
                      statutIds: route.title,
                    };
                    this.getPoints(this.dataToSendToQuery, 1, this.tableState.sortField, this.tableState.sortOrder).subscribe((res: any) => {
                      callback();
                    });
                    break;
                  } else {
                    callback();
                    break;
                  }
                }
              }
            } else if (route.search) {
              this.activatedRoute.queryParams.subscribe((query: any) => {
                this.isTitle = false;
                this.isSearch = true;
                let rechercheGlobale = '';
                rechercheGlobale = decodeURIComponent(route.search);
                this.title = this.translate.instant('title.searchPoint');
                this.dataToSendToQuery = {
                  PageSize: this.pageSize,
                  PageNumber: 1,
                  RechercheGlobale: rechercheGlobale,
                };
                if (query.CheckContenuRTF) {
                  this.dataToSendToQuery.checkContenuRTF = query.CheckContenuRTF;
                  this.getPoints(this.dataToSendToQuery, 1, this.tableState.sortField, this.tableState.sortOrder).subscribe((response: any) => {
                    callback();
                  });
                }
              });
            }
          },
        ],
        () => {
          this.loaded = true;
          this.spinner.hide();
          this.getITableConfigurations();
          window.onbeforeunload = () => this.ngOnDestroy();
        }
      );
    });
  }

  loadTableState() {
    return new Observable(observer => {
      const tableState = localStorage.getItem('i-table-state-idTableauDeBordPoints');
      if (tableState) {
        this.tableState = JSON.parse(tableState);
      } else {
        this.tableState = { sortField: 'id', sortOrder: 'ASC' };
      }
      observer.next();
      observer.complete();
    });
  }

  getITableConfigurations() {
    this.tableConfigurations = new ITableConfig({
      pageSize: this.rows,
      pageFirst: this.first,
      lazy: true,
      totalRecords: this.totalRecords,
      selectionActions: { export: true, remove: false },
      isRowClickable: true,
      resizableColumns: true,
      reorderableColumns: true,
      rowPerPageOptions: [50, 200, 500, 1000, 5000],
      height: '90vh',
      baseSortColumn: { field: 'id', currentSort: 1 },
      tableStyle: { width: '100%'}
    });
  }

  receive_menu(event) {
    const temp = [
      {
        label: this.translate.instant('btn.openPoint'),
        command: (data) => {
          this.showDetails(event);
        },
      },
    ];
    this.menu = [{}];
    async.waterfall(
      [
        (callback) => {
          this.api.getRightsPoint(event.id).subscribe((rights: any) => {
            if (rights.edit) {
              temp.push({
                label: this.translate.instant('btn.modify'),
                command: (data) => {
                  this.updatePoint(event.id);
                },
              });
            }
            callback();
          });
        },
        (callback) => {
          if (this.isUserAbleToAddPoint) {
            temp.push({
              label: this.translate.instant('btn.duplicate'),
              command: (data) => {
                //! Be careful to always check the files, you don't always come from the files tab
                this.chosenPointToDuplicate = event;
                this.getFiles(event.id);
                this.duplicationManagementIndex = 0; //index used to display a certain part of the modal
                this.openDuplicationManagementModal = true; //opens the html modal to choose the desired settings for duplication
              },
            });
          }
          callback();
        },
      ],
      () => {
        this.menu = temp;
      }
    );
  }

  //* method used when user submits the form on the duplication modal management (whatever part he's in)
  //@params no params needed, all is set by the form
  submitDuplicationManagement() {
    if (this.duplicationManagementIndex === 0) {
      //on va alors vérifier si on veut des fichiers. Si oui, on va faire choisir les fichiers a l'utilisateur
      if (this.formDuplicationPoint.value.files) {
        this.duplicationManagementIndex = 1;
        this.formDialogDuplicatePoint.resetPosition();
      } else {
        //si pas, on va simplement aller dans l'interface de création/duplication
        //* on doit récupérer le point dans ce component a cause du tableau en effet, par ex classementId est le label!!
        this.api.getPointByID(this.chosenPointToDuplicate.id).subscribe((res) => {
          this.dataS.SetDuplicatedPoint(res);
          this.dataS.setDuplicatedPointManagement(this.formDuplicationPoint.value);
          this.router.navigateByUrl('/private/points/duplicate');
        });
      }
    } else if (this.duplicationManagementIndex === 1) {
      //on est ds le tableau des fichiers
      //ici on va envoyer les ids des fichiers cochés dans le formulaire à renvoyer
      //* on doit récupérer le point dans ce component a cause du tableau en effet, par ex classementId est le label!!
      this.api.getPointByID(this.chosenPointToDuplicate.id).subscribe((res) => {
        this.dataS.SetDuplicatedPoint(res);
        this.formDuplicationPoint.patchValue({
          listOfFiles: this.superTableFilesDuplication._selection.map((file) => file.id),
        });
        this.dataS.setDuplicatedPointManagement(this.formDuplicationPoint.value);
        this.router.navigateByUrl('/private/points/duplicate');
      });
    }
  }

  //*get the point's files
  getFiles(pointId) {
    this.spinner.show();
    this.api.getPointFiles(pointId).subscribe(
      (data) => {
        data.map((x) => new File(x));
        this.filesForDuplication = data.filter((x) => !x.modeleId);
        this.spinner.hide();
      },
      (err) => {
        this.filesForDuplication = [];
        this.spinner.hide();
      }
    );
  }

  private showBoolean(value) {
    if (value) {
      return `<b style="color: #07E299;"><i class="bi bi-check-circle-fill"></i></b>`;
    } else if (value === -1) {
      return `<b style="color: #FFAF2F;"><i class="bi bi-hourglass-split"></i></b>`;
    } else {
      return `<b style="color: #FF5671;"></b>`;
    }
  }

  ngOnDestroy() {
    const temp = JSON.parse(localStorage.getItem('state-of-table-idTableauDeBordPoint'));
    if (temp) {
      temp.rows = 50;
      temp.first = 0;
      localStorage.setItem('state-of-table-idTableauDeBordPoint', JSON.stringify(temp));
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  showDetails(event) {
    window.open(this.globalConfig.baseUrl + '/private/points/detail/' + event.id);
  }

  updatePoint(event) {
    this.router.navigateByUrl('/private/points/update/' + event);
  }

  setFirstInLocalStorage(firstValue: number) {
    const tableStateJSON = localStorage.getItem('i-table-state-idTableauDeBordPoints');
    const tableState = tableStateJSON ? JSON.parse(tableStateJSON) : {};
    this.tableState = tableState;
    tableState.first = firstValue;
    localStorage.setItem('i-table-state-idTableauDeBordPoints', JSON.stringify(tableState));
  }

  getPoints(data?: any, pageNumber?: number, sortField?: any, sortOrder?: any) {
    data.PageNumber = pageNumber ? pageNumber : 1;
    sortField && sortField !== '' ? (data.SortFields = sortField) : null;
    sortOrder && sortOrder !== '' ? (data.SortOrder = sortOrder) : null;
    this.loadingTable = true;
    this.items = [];
    return this.api.getSearchPoint(this.removeEmpty(data)).pipe(
      tap((response: any) => {
        const items = Array.isArray(response.items) ? response.items : typeof response === 'object' ? [response] : [];
        this.items = items.map(
          (x) => new Point(x, this.globalElements, null, this.listOfContactsAdmin, this.listOfContacts)
        );
        this.totalRecords = response.totalCount;
        this.loadingTable = false;
        this.rows = response.pageSize;
        this.first = response.pageNumber === 1 ? 0 : this.rows * (response.pageNumber - 1);
      })
    );
  }

  removeEmpty(obj) {
    return Object.keys(obj)
      .filter(function (k) {
        return obj[k] != null;
      })
      .reduce(function (acc, k) {
        if (!Array.isArray(obj[k]) && !(obj[k] instanceof Date)) {
          acc[k] = typeof obj[k] === 'object' ? this.removeEmpty(obj[k]) : obj[k];
        } else {
          acc[k] = obj[k];
        }
        return acc;
      }, {});
  }

  translateForSelection() {
    this.translate.get('statusModification').subscribe((trad) => {
      this.forselection = [
        {
          label: trad,
          tooltip: trad,
          command: (value, item) => {
            this.openPointStatusChangeModal(value);
          },
        },
      ];
    });
  }

  public openPointStatusChangeModal(selection) {
    const ref = this.dialogService.open(StatusChangeComponent, {
      data: {
        points: true,
        listOfPoints: selection,
        header: this.translate.instant('statusModifications'),
      },
      width: '70vw',
      styleClass: 'super-compos-modal-content ',
      showHeader: false,
      closable: false,
      dismissableMask: false,
      baseZIndex: 1000,
    });
    ref.onClose.subscribe((status: number) => {
      if (status) {
        this.spinner.show();
        this.toastr.info('Changement de statut en cours, veuillez patienter');
        async.eachSeries(
          selection,
          (item: Point, nextFile) => {
            this.api.putChangeStatusPoint(item.id, status).subscribe((res: any) => {
              nextFile();
            });
          },
          () => {
            this.spinner.hide();
            this.ngOnInit();
            this.toastr.success('Les statuts des points ont bien été modifiés.');
          }
        );
      }
    });
  }

  getColumns() {
    this.api.getConfiguration().subscribe((res: any) => {
      this.globalConfiguration = res;
      this.columns = [
        // ajouter filterable: true aux colonne que je veux filter
        {
          field: 'id',
          header: 'ID',
          filterable: true,
          sortable: true,
          format: (value, item) => {
            return item.id;
          },
          export: (value, item) => {
            return item.id;
          },
        },
        {
          field: 'objetSynthese',
          header: 'Titre',
          sortable: true,
          maxWidth: '200px',
          type: 'string',
          format: (value, item) => {
            return item.objetSynthese;
          },
          export: (value, item) => {
            return item.objetSynthese;
          },
          filterable: true,
        },
        {
          field: 'cloture',
          header: 'Cloturé',
          sortable: true,
          format: (value, item) => {
            if (item?.type !== 'event') {
              return this.showBoolean(item.cloture);
            }
          },
          export: (value, item) => {
            if (item.cloture) {
              return `✔️`;
            } else if (item.cloture === -1) {
              return `⏳`;
            } else {
              return `❌`;
            }
          },
          filterable: true,
        },
        {
          field: 'confidentiel',
          header: 'Confidentiel',
          sortable: true,
          visible: false,
          format: (value, item) => {
            if (item?.type !== 'event') {
              if (item.confidentiel === true) {
                return `✔️`;
              } else if (item.confidentiel === false) {
                return '';
              } else if (item.confidentiel === null) {
                return '';
              }
            }
          },
          export: (value, item) => {
            if (item.confidentiel === true) {
              return `✔️`;
            } else if (item.confidentiel === false) {
              return `❌`;
            } else if (item.confidentiel === null) {
              return '';
            }
          },
          filterable: true,
        },
        {
          field: 'serviceId',
          header: 'Service',
          sortable: true,
          type: 'string',
          format: (value) => {
            if (value) {
              const service = this.globalElements.services_LV.find((x) => x.value === value);
              if (service) {
                return service.label;
              }
            } else {
              return '';
            }
          },
          export: (value, item) => {
            return value;
          },
          filterable: true,
        },
        {
          field: 'seanceLink.description',
          header: 'Séance',
          sortable: true,
          format: (value, item) => {
            return item.seanceLink?.description;
          },
          filterable: true,
        },
        {
          field: 'classementId',
          header: 'Classement',
          sortable: true,
          visible: false,
          format: (value, item) => {
            if (item?.type !== 'event') {
              if (item.classementId && this.globalElements?.classement_code_LV) {
                const element = this.globalElements.classement_code_LV.find((x) => x.value === item.classementId);
                if (element) {
                  return element?.label;
                } else {
                  return '/';
                }
              } else if (!item.classementId) {
                return '/';
              }
            } else {
              return '/';
            }
          },
          export: (value) => {
            return value;
          },
          filterable: true,
        },
        {
          field: 'dossierClassementId',
          header: 'Dossier',
          sortable: true,
          visible: false,
          format: (value, item) => {
            if (item?.type !== 'event') {
              if (item.dossierClassementId && this.globalElements?.classeurs_LV) {
                const element = this.globalElements.classeurs_LV.find((x) => x.value === item.dossierClassementId);
                if (element) {
                  return element?.label;
                } else {
                  return '/';
                }
              } else if (!item.dossierClassementId) {
                return '/';
              }
            }
          },
          export: (value) => {
            return value;
          },
          filterable: true,
        },
        {
          field: 'seanceLink.date',
          header: 'Date de séance',
          sortable: true,
          visible: false,
          format: (value, item) => {
            if (item?.seanceLink?.date) {
              return this.formatIsoDateToCustom(item.seanceLink.date);
            }
          },
          export: (value, item) => {
            return this.formatIsoDateToCustom(item.seanceLink.date);
          },
          filterable: true,
        },
        {
          field: 'matiereId',
          header: 'Matière',
          sortable: true,
          visible: false,
          format: (value, item) => {
            if (item?.type !== 'event') {
              if (item.matiereId && this.globalElements?.matieres_LV) {
                const element = this.globalElements.matieres_LV.find((x) => x.value === item.matiereId);
                if (element) {
                  return element?.label;
                } else {
                  return '/';
                }
              } else if (!item.matiereId) {
                return '';
              }
            }
          },
          export: (value) => {
            return value;
          },
          filterable: true,
        },
        {
          field: 'statusLabel',
          header: 'Statut',
          sortable: true,
          type: 'string',
          visible: false,
          format: (value) => {
            return value;
          },
          export: (value) => {
            return value;
          },
          filterable: true,
        },
        {
          field: 'typeDecisionId',
          header: 'Type de décision',
          sortable: true,
          format: (value, item) => {
            if (item?.type !== 'event') {
              if (item.typeDecisionId && this.globalElements?.decision_type_LV) {
                const element = this.globalElements?.decision_type_LV.find((x) => x.value === item.typeDecisionId);
                if (element) {
                  return element?.label;
                } else {
                  return '/';
                }
              } else if (!item.typeDecisionId) {
                return '';
              }
            }
          },
          export: (value) => {
            return value;
          },
          filterable: true,
        },
        {
          field: 'typePoint',
          header: 'Type',
          sortable: true,
          visible: false,
          format: (value, item) => {
            if (item?.type !== 'event') {
              return item?.typePoint;
            }
          },
          export: (value) => {
            return value;
          },
          filterable: true,
        },
        {
          field: 'typeSeanceId',
          header: 'Type séance',
          sortable: true,
          format: (value, item) => {
            if (item?.type !== 'event') {
              if (item.typeSeanceId && this.globalElements?.types_seances_LV) {
                const element = this.globalElements.types_seances_LV.find((x) => x.value === item.typeSeanceId);
                if (element) {
                  return element?.label;
                } else {
                  return '/';
                }
              }
            }
          },
          export: (value) => {
            return value;
          },
          filterable: true,
        },
        {
          field: 'verrouille',
          header: 'Verrouillé',
          sortable: true,
          format: (value, item) => {
            if (item?.type !== 'event') {
              return this.showBoolean(item.verrouille);
            }
          },
          export: (value, item) => {
            if (item.verrouille) {
              return `✔️`;
            } else if (item.verrouille === -1) {
              return `⏳`;
            } else {
              return `❌`;
            }
          },
          filterable: true,
        },
        {
          field: 'checkRGPD',
          header: 'RGPD',
          sortable: true,
          visible: false,
          format: (value, item) => {
            if (item?.type !== 'event') {
              return this.showBoolean(item.checkRgpd);
            }
          },
          export: (value, item) => {
            if (item.checkRgpd) {
              return `✔️`;
            } else if (item.checkRgpd === -1) {
              return `⏳`;
            } else {
              return `❌`;
            }
          },
          filterable: true,
        },

        {
          field: 'echevinatId',
          header: 'Echevinat',
          sortable: true,
          visible: false,
          format: (value, item) => {
            if (item?.type !== 'event') {
              if (item.echevinatId && this.globalElements?.echevinats_LV) {
                const element = this.globalElements.echevinats_LV.find((x) => x.value === item.echevinatId);
                if (element) {
                  return element?.label;
                } else {
                  return '/';
                }
              } else if (!item.echevinatId) {
                return '';
              }
            }
          },
          export: (value) => {
            return value;
          },
          filterable: true,
        },
        {
          field: 'origine',
          header: 'Origine',
          sortable: true,
          visible: false,
          export: (value) => {
            return value;
          },
          filterable: true,
        },
        {
          field: 'creeParContactLabel',
          header: 'Createur',
          sortable: true,
          visible: false,
          export: (value, item) => {
            return item.creeParContactLabel;
          },
          filterable: true,
        },
      ];

      if (!this.globalConfiguration?.delibConfig?.cacherAgentTraitant) {
        this.columns.push({
          field: 'agentTraitantIdLabel',
          header: 'Agent traitant',
          visible: false,
          format: (value, item) => {
            if (item?.type !== 'event') {
              return item?.agentTraitantLabel;
            }
          },
          export: (value) => {
            return value;
          },
          filterable: true,
        });
      }

      if (!this.globalConfiguration?.delibConfig?.contenuMasquerUrgence) {
        this.columns.push({
          field: 'urgenceNiveauIdLabel',
          header: 'Urgence',
          visible: false,
          format: (value, item) => {
            if (item?.type !== 'event') {
              if (item.urgenceNiveauId && this.globalElements?.urgence_level_LV) {
                const element = this.globalElements.urgence_level_LV.find(
                  (x) => x.value === item.urgenceNiveauId
                );
                if (element) {
                  return element?.label;
                } else {
                  return '';
                }
              } else if (!item.urgenceNiveauId) {
                return '';
              }
            }
          },
          export: (value) => {
            return value;
          },
          filterable: true,
        });
      }
    });
  }

  formatIsoDateToCustom(isoDateString: string): string {
    const date = new Date(isoDateString);
    // Ajoute un zéro initial pour les jours et les mois < 10
    const pad = (num: number): string => (num < 10 ? `0${num}` : num.toString());
    // Construit la date au format DD/MM/YYYY HH:MM
    const formattedDate = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()} `;
    return formattedDate;
  }

  pageChange(event) {
    this.pageSizeTemp = event.rows;
    const pageNumber = event.first / event.rows + 1;
    let sortField;
    if (event.sortField) {
      const index = this.allSortFieldToBeModifiedForSortFrontend.map((x) => x.label).indexOf(event.sortField);
      if (index > -1) {
        sortField = this.allSortFieldToBeModifiedForSortFrontend[index].value;
      } else {
        sortField = event.sortField;
      }
    }
    const sortOrder = event.sortOrder ? (event.sortOrder === 1 ? 'ASC' : 'DESC') : '';
    this.dataToSendToQuery.PageSize = this.pageSizeTemp;
    this.pageSize = this.pageSizeTemp;
    this.getPoints(this.dataToSendToQuery, pageNumber, sortField, sortOrder).subscribe();
  }

  columnSort(event) {
    const sortOrder = event[0].sort ? (event[0].sort === 1 ? 'ASC' : 'DESC') : '';
    this.getPoints(this.dataToSendToQuery, 1, event[0].field, sortOrder).subscribe();
  }
}
