import { Component, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormGroup, FormControl } from '@angular/forms';
import * as _ from 'underscore';
import { ConfirmationService } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
class Box {
  public index: number;
  public title: string;
  public type: string;
  public icon: string;
  public count?: number;
}

@Component({
  selector: 'super-box-list-custom',
  templateUrl: './super-box-list.component.html',
  styleUrls: ['./sass/super-box-list.component.scss', './sass/basic.scss'],
})
export class SuperBoxListCustomComponent implements OnInit {
  public myBoxes: Array<any> = [];
  public initBoxes: Array<Box> = [];
  public openModal = false;
  public indexes = [];
  public editMode = false;
  public fct: Function;
  public boxesIndex: Array<any> = [];
  public filterForm = new FormGroup({
    Title: new FormControl(null),
  });
  @Input() dragEnabled: boolean;
  @Input() set boxArray(value: Array<Box>) {
    this.myBoxes = value;
    this.initBoxes = value;
  }
  @Input() events;

  constructor(
    private confirmationService: ConfirmationService,
    public toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.refreshBoxes();
  }

  /**
   * Refreshes the boxes in the component.
   * Updates the index property of each box and its items.
   * Assigns the updated boxes to the boxesIndex property.
   */
  refreshBoxes() {
    this.myBoxes.map((item, index) => {
      item.index = index;
      item.map((it, index) => {
        it.index = index;
      });
    });
    this.boxesIndex = this.myBoxes;
  }

  getHeight(items: Array<Box>) {
    return 39 * items.length;
  }

  getOthers(i) {
    return this.indexes.filter((x) => x !== 'list_box_' + i);
  }

  drop(items: Array<any>, event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(items, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, items, event.previousIndex, event.currentIndex);
    }
    this.refreshBoxes();
  }

  submit() {
    const last = _.last(this.myBoxes);
    last.push({ title: this.filterForm.value.Title });
    this.refreshBoxes();
    this.openModal = false;
    this.filterForm.value.Title = null;
  }

  delete(title: string) {
    this.confirmationService.confirm({
      message: this.translate.instant('confirmMessageToDeleteFilter'),
      key: 'boxConfirm',
      accept: () => {
        this.myBoxes.map((boxes) => {
          boxes.filter((box, index) => {
            if (box.title === title) {
              return boxes.splice(index);
            }
          });
        });
        this.refreshBoxes();
      },
      reject: () => {
        return null;
      },
    });
  }
  public function(box, fct: Function, event) {
    fct(event);
  }
}
