export class File {
   description:string;
   fileName:string;
   date:string;
   annexe?:string;
   annexeNumero?:number;
   extension: string;
   id?: number;

   baseSur?: any;
   createdByContactId?: number;
   dateUpload?: string;
   guid?: any;
   isLastVersion?: boolean;
   mailId?: number;
   modeleId?: any
   name?: string;
   version?: number;
   expediteur?:any;
   
   constructor(data:any) {
      Object.assign(this, data);
      if(!this.annexe ){
         this.annexe = null;
      }
      if(!this.expediteur ){
         this.expediteur = null;
      }
   }
}