import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { SelectItem, MenuItem, ConfirmationService } from 'primeng/api';
import { IContactService } from 'src/app/core/services/i-contact.service';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as async from 'async';
import { Dropdown } from 'primeng/dropdown';
import { HttpClient } from '@angular/common/http';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Contact } from 'src/app/shared/models/iContact/contact';
import * as listPhone from 'src/assets/country/phone.json';
import * as countries from 'src/assets/country/phone.json';
import * as cp_fr from 'src/assets/country/cp_fr.json';
import { Pays } from 'src/app/shared/models/iContact/pays';
import { LocaliteCp } from 'src/app/shared/models/iContact/localite-cp';
import { Rue } from 'src/app/shared/models/iContact/rue';
import { UserInfo } from 'src/app/shared/models/user-info';
import { TranslationService } from 'src/app/core/services/translation.service';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import { PostContact } from 'src/app/shared/models/iContact/post-contact';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { ValidatorsComponent } from 'src/app/core/validators/validators.component';

@Component({
  selector: 'app-contact-new',
  templateUrl: './contact-new.component.html',
  styleUrls: ['./contact-new.component.scss'],
})
export class ContactNewComponent implements OnInit, AfterViewInit {
  public filteredContacts: Contact[];
  public contact: any;
  public contacts: Contact[];
  public text: string;
  public caller: string;
  public groupList: SelectItem[];
  public adressMode = 'Principale';
  public contactForm: FormGroup;
  public countryPhoneLV: Array<any> = listPhone['default'];
  public cp_france: Array<any> = cp_fr['default'];
  public isInCourrierCreation: boolean;
  public buttons = [
    {
      text: 'Retour',
      background: 'rgb(96, 205, 255)',
      skin: 'super-compos-secondary-btn',
      disabled: false,
      tooltip: "Retour vers le carnet d'adresse",
      click: () => {
        this.router.navigateByUrl('/private/contacts/');
      },
    },
    {
      text: 'Enregistrer',
      background: 'rgb(96, 205, 255)',
      skin: 'super-compos-secondary-btn',
      disabled: false,
      tooltip: 'Enregistrer ce contact',
      click: () => {
        this.onSubmit();
      },
    },
  ];
  public countriesList: Pays[];
  public filteredCountries: Pays[];
  public postalCodesList: LocaliteCp[];
  public filteredPostalCodes: LocaliteCp[];
  public typeContacts: any[] = [];
  public belgium: Pays;
  public streetsList: Rue[];
  public filteredStreets: Rue[];
  public contactTitresList: any[] = [];
  public companiesList: Contact[];
  public filteredCompanies: Contact[];
  public langues: any[] = [];
  public companyMode: boolean; // default is false
  public isUpdateMode: boolean;
  public contactId: number;
  public secteursList: any[] = [];
  public formesJuridiques: any[] = [];
  public etatCivils: Array<any> = [];
  public sexes: Array<any> = [];
  public nationalites: Array<any> = [];
  public fullMode: boolean; // to see the whole form
  public locale4Calendar: any; // locale for calendar
  public yearRange: string;
  public signatureFile: File;
  public arrayItems: {
    id: number;
    structure_RueId: number;
    structure_RueNumero: number;
    structure_LocaliteId: number;
    pays: string;
  }[];
  public activeIndex = 0;
  public steps: MenuItem[] = [];
  public contactAdresses = [];
  public typesAdresses: Array<any> = [];
  public chgSaved: boolean;
  public userInfos: UserInfo; // Access of the connected user
  public connectedUserID: number;
  public connectedUserRights: any;
  public allcontacts: Array<Contact> = [];
  public codeTvaList: SelectItem[] = [
    { label: 'BE', value: 'BE' },
    { label: 'FR', value: 'FR' },
    { label: 'LU', value: 'LU' },
    { label: 'NL', value: 'NL' },
    { label: 'DE', value: 'DE' },
    { label: 'AUTRE', value: 'OTHER' },
  ];
  public tvaMask = `BE-0999999999`;
  public tvaRegex: '';
  public tvaPlaceholder = 'BE-0999999999';
  public tvaTooltip = 'Code pays + 0 + 9 chiffres.';
  public tvaFrMode = false;
  public bcePlaceholder = '9999999999';
  public bceMask = '9999999999';
  public bceTooltip = '10 chiffres.';
  public tooltipMember = "Veuillez tout d'abord créer une contact afin de lier à un autre membre";
  public tooltipSociety = "Veuillez tout d'abord créer une contact afin de lier à une autre société";
  public tabMemberDisabled = true;
  public tabSocDisabled = true;
  public isFormSubmit: boolean;
  public isDisabled: boolean;
  public btnTooltip = 'Enregistrer ce contact';
  public typeSocieteList = [
    { label: 'Personne physique', value: false },
    { label: 'Société', value: true },
  ];
  public codePays = 'BE';
  public countryPhone: string = '+32';
  public prefixNumber: string = '';
  public countriesJSON = countries;
  public datebirthdefault = new Date();
  public hideSwitch: boolean; // for new contact link to hide typeSociete change
  @ViewChild('Dropdown') stateDropdown: Dropdown;
  @ViewChild('Dropdown2') stateDropdownFrench: Dropdown;
  @ViewChild('DropdownRue') stateDropdownRue: Dropdown;
  public emailUserFromMailFile: string;
  public paysContactID: number;
  public countryID: any;
  public localityID: number;
  public cityName: string;
  public skipWarnings: boolean = false;
  public showImpressionInput: boolean = false;
  public defaultCountry: any;
  public isInLinkEdition: boolean = false;
  public contactID: number;
  public updateMode: boolean = false;
  public clientType = sessionStorage.getItem('clientType');
  public apifrenchresults: Array<any> = [];
  public apistreetfrenchresults: Array<any> = [];
  public currentPostCode: string = '';
  public connectedContactAccessContacts: boolean;
  public showCreateCP = false;
  public showCreateStreet = false;
  public showCreateContactType = false;
  public formCP = new FormGroup({
    codePostal: new FormControl(null, Validators.required),
    localite: new FormControl(null),
    langueID: new FormControl(null),
    paysID: new FormControl(null),
  });
  public formStreet = new FormGroup({
    codePostal: new FormControl(null, Validators.required),
    codeRue: new FormControl(null),
    codeLangue: new FormControl('FR'),
    rue: new FormControl(null),
  });
  public formContactType = new FormGroup({
    typeContact: new FormControl(null, Validators.required),
    ordreTri: new FormControl(null),
    carnetContactID: new FormControl(null),
    nePasInclureArbreDestinataires: new FormControl(false),
    nePasRemplirLaListe: new FormControl(false),
    sanctionTypeVictime: new FormControl(null),
    categorie: new FormControl(false),
    categorieParent: new FormControl(null),
    verrouille: new FormControl(null),
    creationGereeParGroupeID: new FormControl(null),
    modificationGereeParGroupeId: new FormControl(null),
    suppressionGereeParGroupeId: new FormControl(null),
    visualisationGereeParGroupeID: new FormControl(null),
  });
  public countries = [];
  public typesLV = [];
  public groups = [];
  public contactsLinked = [];
  public contactsInit = [];
  public actualAdressToBeModified;
  public typeOfAddressToBeModified;
  public actualStreetToBeModified;
  public typeOfStreetToBeModified;
  public emailCheckPattern = [];
  emailPattern =
    /^[a-zA-Z0-9À-ÖØ-öø-ÿ. !#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9]{1}[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]{1}[.]{1}[a-zA-Z0-9]{1}[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]{1}$/;
  emailPattern2 =
    /^[a-zA-Z0-9. !#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9]{1}[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]{1}[.][a-zA-Z0-9]{1}[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]{1}[.]{1}[a-zA-Z0-9]{1}[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]{1}$/;
  //same than before but with 2 points ex xxxx@aaa.bbb.be

  public listOfDisplayedImpressionAdresses = [false];
  public saveOK: boolean = false;
  constructor(
    private adminApi: iAdminService,
    private formBuilder: FormBuilder,
    private iContactsService: IContactService,
    private toastr: ToastrService,
    private translationService: TranslationService,
    public confirmationService: ConfirmationService,
    private router: Router,
    private api: ApiService,
    private spinner: NgxSpinnerService,
    public dataService: DataService,
    public ref: DynamicDialogRef,
    public dataModal: DynamicDialogConfig
  ) {}

  checkEmailPattern(index) {
    this.emailCheckPattern = [];
    if (this.getAdressPrincipal && !this.getAdressPrincipal.at(index).value.mail) {
      return true;
    } else {
      if (
        this.emailPattern.test(this.getAdressPrincipal.at(index).value.mail) === false &&
        this.emailPattern2.test(this.getAdressPrincipal.at(index).value.mail) === false
      ) {
        this.emailCheckPattern.push(index);
        return false;
      } else {
        return true;
      }
    }
  }

  ngOnInit() {
    if (this.dataModal && this.dataModal.data) {
      this.caller = this.dataModal.data.caller ? this.dataModal.data.caller : null;
      this.emailUserFromMailFile = this.dataModal.data.emailUserFromMailFile
        ? this.dataModal.data.emailUserFromMailFile
        : null;
      this.companyMode = this.dataModal.data.companyMode ? this.dataModal.data.companyMode : null;
      this.hideSwitch = this.dataModal.data.hideSwitch ? this.dataModal.data.hideSwitch : null;
      this.contactID = this.dataModal.data.contactID ? this.dataModal.data.contactID : null;
    }
    this.text = 'Nouveau contact';
    this.contact = new PostContact();
    this.isUpdateMode = false;
    this.getLocale4Calendar();
    this.getYearRange();
    async.waterfall([
      (cb) => {
        this.getConnectedUser();
        this.getContactsTypeAdresse();
        this.getCountries();
        this.initForm();
        cb(null);
      },
      (cb) => {
        this.getTypeContact();
        this.getFormesJuridiques();
        this.getTitres();
        this.getSecteursActivites();
        this.getEtatCivils();
        this.getSexes();
        this.getNationalites();
        if (this.companyMode && this.companyMode === true) {
          this.f.typeSociete.patchValue(true);
        } else {
          this.f.typeSociete.patchValue(false);
        }
        cb(null);
      },
      (cb) => {
        this.getLangues().then(() => {
          cb();
        });
      },
      (cb) => {
        if (this.contactID) {
          this.updateMode = true;
          this.spinner.show();
          this.iContactsService.get('/contacts/' + this.contactID).subscribe((res: any) => {
            this.contact = res;
            this.getStreets();
            if (this.contact.typeContact) {
              this.contact.typeContactId = this.contact.typeContact !== null ? this.contact.typeContactId : null;
            }
            this.contact.paysContact = this.contact.paysContact !== null ? this.contact.paysContact.id : null;
            this.contact.listContactsAdresses.map((item) => {
              const localite = {
                id: item.adresse.structure_Localite ? item.adresse.structure_Localite.id : null,
                codePostal: item.adresse.structure_Localite ? item.adresse.structure_Localite.codePostal : null,
                localite: item.adresse.structure_Localite ? item.adresse.structure_Localite.localite : null,
                autoCompleteField: item.adresse.structure_Localite
                  ? item.adresse.structure_Localite.codePostal + ': ' + item.adresse.structure_Localite.localite
                  : null,
              };
              this.contactAdresses.push({
                contactID: this.contactID,
                type: item.type,
                id: item.id,
                structure_RueId: item.adresse.structure_Rue ? item.adresse.structure_Rue : null,
                structure_RueNumero: item.adresse.structure_RueNumero,
                structure_RueBoite: item.adresse.structure_RueBoite,
                structure_LocaliteId: localite.autoCompleteField !== null ? localite : null,
                impression_Pays: item.adresse.impression_Pays,
                impression_CodePostal: item.adresse.impression_CodePostal,
                impression_Adresse: item.adresse.impression_Adresse,
                impression_Localite: item.adresse.impression_Localite,
                gsm: item.adresse.gsm,
                mail: item.adresse.mail,
                telephone: item.adresse.telephone,
                fax: item.adresse.fax,
                site: item.adresse.site,
                pays: item.adresse.structure_Localite ? item.adresse.structure_Localite.pays : null,
              });
            });
            setTimeout(() => {
              this.contact.listContactsAdresses = [];
              this.contact.listContactsAdresses = this.contactAdresses;
              if (this.contact.accesModuleExterneDelibe) {
                this.contact.loginDelibeWeb === null || this.contact.loginDelibeWeb === ''
                  ? (this.contact.loginDelibeWeb = this.contact.adressePrincipale?.mail)
                  : (this.contact.loginDelibeWeb = this.contact.loginDelibeWeb);
              }
              if (this.contactAdresses && this.contactAdresses[0].mail) {
                this.contact.mail = this.contactAdresses[0].mail;
              }
              this.contactForm.patchValue(this.contact);
              this.spinner.hide();
            }, 1500);
          });
        }
      },
    ]);
    this.contactForm.valueChanges.subscribe((val) => {
      if (val) {
        this.chgSaved = false;
      }
      if (val.typeSociete) {
        if (val.nom !== null && val.langueId !== null && val.typeContactId !== null) {
          this.isDisabled = false;
          this.btnTooltip = 'Enregistrer ce contact';
        } else {
          this.isDisabled = true;
          this.btnTooltip = 'Il y a des erreurs dans le formulaire';
        }
      } else {
        if (val.nom !== null && val.langueId !== null && val.typeContactId !== null) {
          this.isDisabled = false;
          this.btnTooltip = 'Enregistrer ce contact';
        } else {
          this.isDisabled = true;
          this.btnTooltip = 'Il y a des erreurs dans le formulaire';
        }
      }
    });
  }

  ngAfterViewInit() {
    if (this.stateDropdown) {
      const random = 'nope-' + (Math.random() + 1).toString(36).substring(7);
      this.stateDropdown.renderer.setAttribute(
        this.stateDropdown.filterViewChild.nativeElement,
        'autocomplete',
        random
      );
    }
  }

  getLocale4Calendar() {
    // TODO add params for this foncton when others languages
    this.translationService.getCalendartranslate('fr').subscribe((response) => {
      this.locale4Calendar = response;
    });
  }

  public getYearRange() {
    this.yearRange = `${new Date().getFullYear() - 100}:${new Date().getFullYear()}`;
  }

  getConnectedUser() {
    this.adminApi.getUserInfo().subscribe(
      (response: any) => {
        this.userInfos = response;
        this.connectedUserID = response.id;
        this.api.getRightsManagement().subscribe((res: any) => {
          this.connectedUserRights = res;
          res.menu.contacts
            ? (this.connectedContactAccessContacts = true)
            : (this.connectedContactAccessContacts = false);
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  getContactsTypeAdresse() {
    this.iContactsService.get(`/Global/typesAdresses`).subscribe(
      (res: any) => {
        res.map((item) => {
          this.typesAdresses.push({ label: item, value: item });
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public onSubmit() {
    this.isFormSubmit = true;
    const temp_array_adress = [];
    if (!this.isUpdateMode) {
      this.contact = new PostContact(this.contact);
    } else {
    }
    if (!this.paysContactID) {
      this.paysContactID = 1;
    }
    this.contact.listContactsAdresses = []; // TODO change if update?
    if (this.contactForm.value.titre) {
      this.contact.titre = this.contactForm.value.titre.titre;
    }
    let gen_address = null;
    async.waterfall([
      (call) => {
        if (this.contactForm.value.listContactsAdresses.length > 0) {
          const errors = [];
          async.eachSeries(
            this.contactForm.value.listContactsAdresses,
            (item: any, cbEachSeries) => {
              if (item.impression_Adresse !== null && item.structure_RueId === null) {
                this.spinner.hide();
                this.confirmationService.confirm({
                  message:
                    "Pour l'adresse " +
                    item.type.toLowerCase() +
                    ', l’adresse impression est remplie mais l’adresse structurée est vide. Souhaitez-vous sauver tout de même?',
                  key: 'contact',
                  accept: () => {
                    setTimeout(() => {
                      cbEachSeries(null);
                    }, 500);
                  },
                  reject: () => {
                    errors.push('refused');
                    setTimeout(() => {
                      cbEachSeries(null);
                    }, 500);
                  },
                });
              } else if (item.impression_Adresse !== null && item.structure_RueId !== null) {
                this.spinner.show();
                let address = item.structure_RueId;
                const boite = item.structure_RueBoite;
                const number = item.structure_RueNumero;
                this.api.getConfiguration().subscribe((res: any) => {
                  const params = res?.groupContacts?.impressionAddressOrder;

                  if (address) {
                    address = address.rueNom ? address.rueNom : address;
                    if (params === 'R') {
                      gen_address = address;
                    }
                    if (params === 'RB') {
                      gen_address = address + (boite ? ' bte ' + boite : '');
                    }
                    if (params === 'RN') {
                      gen_address = address + ' ' + number;
                    }
                    if (params === 'BR') {
                      gen_address = (boite ? ' bte ' + boite : '') + ' ' + address;
                    }
                    if (params === 'BN') {
                      gen_address = (boite ? ' bte ' + boite : '') + ' ' + number;
                    }
                    if (params === 'NRB') {
                      gen_address = number + ' ' + address + (boite ? ' bte ' + boite : '');
                    }
                    if (params === 'NBR') {
                      gen_address = number + (boite ? ' bte ' + boite : '') + ' ' + address;
                    }
                    if (params === 'RNB') {
                      gen_address = address + ' ' + number + (boite ? ' bte ' + boite : '');
                    }
                    if (params === 'RBN') {
                      gen_address = address + (boite ? ' bte ' + boite : '') + ' ' + number;
                    }
                    if (params === 'BNR') {
                      gen_address = (boite ? ' bte ' + boite : '') + ' ' + number + ' ' + address;
                    }
                    if (params === 'BRN') {
                      gen_address = (boite ? ' bte ' + boite : '') + ' ' + address + ' ' + number;
                    }
                  } else {
                    gen_address = '';
                  }
                  cbEachSeries(null);
                });
              } else {
                cbEachSeries(null);
              }
            },
            () => {
              if (errors.length > 0) {
                //on ne permet pas la suite s'il y a une err
                this.spinner.hide();
              } else {
                call();
              }
            }
          );
        } else {
          call();
        }
      },
      (call) => {
        if (this.contactForm.value.listContactsAdresses.length > 0) {
          const errors = [];
          async.eachSeries(
            this.contactForm.value.listContactsAdresses,
            (item: any, cbAsyncEachSeries) => {
              if (
                (item.impression_Adresse && item.impression_Adresse !== gen_address) ||
                (item.pays && item.impression_Pays && item.pays.description !== item.impression_Pays) ||
                (item.structure_LocaliteId &&
                  item.impression_CodePostal &&
                  item.structure_LocaliteId.codePostal !== item.impression_CodePostal) ||
                (item.structure_LocaliteId &&
                  item.impression_Localite &&
                  item.structure_LocaliteId.localite !== item.impression_Localite)
              ) {
                this.spinner.hide();
                this.confirmationService.confirm({
                  key: 'contact',
                  message:
                    'L’adresse impression est différente de l’adresse structurée. Souhaitez-vous sauver tout de même?',
                  accept: () => {
                    setTimeout(() => {
                      cbAsyncEachSeries(null);
                    }, 500);
                  },
                  reject: () => {
                    errors.push('refused');
                    setTimeout(() => {
                      cbAsyncEachSeries(null);
                    }, 500);
                  },
                });
              } else {
                cbAsyncEachSeries(null);
              }
            },
            () => {
              //on va vérifier s'il y a une err car on ne peut pas break d'un async.each/ eachSeries
              if (errors.length > 0) {
                //on ne permet pas la suite s'il y a une err
                this.spinner.hide();
              } else {
                call();
              }
              call(null);
            }
          );
        } else {
          call();
        }
      },
      (call) => {
        this.spinner.show();
        if (this.contactForm.value.listContactsAdresses.length > 0) {
          this.contactForm.value.listContactsAdresses.map((item, index) => {
            // adresse vide si nulle
            if (this.countryID.codePays !== 'BE') {
              temp_array_adress.push({
                type: item.type && item.type !== null ? item.type : 'PRINCIPALE',
                description: item.type !== 'PRINCIPALE' ? item.type : '',
                contactID: this.contactId,
                adresse: {
                  structure_RueId: item.structure_RueId && item.structure_RueId.id ? item.structure_RueId.id : null,
                  structure_RueNumero:
                    item.structure_RueNumero && item.structure_RueNumero !== null ? item.structure_RueNumero : '',
                  structure_RueBoite:
                    item.structure_RueBoite && item.structure_RueBoite !== null ? item.structure_RueBoite : '',
                  structure_LocaliteId:
                    item.structure_LocaliteId && item.structure_LocaliteId.id && item.structure_LocaliteId !== null
                      ? item.structure_LocaliteId && item.structure_LocaliteId.id
                      : null,
                  impression_Pays: item.pays ? item.pays.description : '',
                  impression_CodePostal: item.impression_CodePostal
                    ? item.impression_CodePostal
                    : item.structure_LocaliteId
                      ? item.structure_LocaliteId.codePostal
                      : '',
                  impression_Localite: item.impression_Localite
                    ? item.impression_Localite
                    : item.structure_LocaliteId
                      ? item.structure_LocaliteId.localite
                      : '',
                  impression_Adresse: item.impression_Adresse
                    ? item.impression_Adresse +
                      (item.structure_RueNumero ? ' ' + item.structure_RueNumero : '') +
                      (item.structure_RueBoite ? ' bte ' + item.structure_RueBoite : '')
                    : (item.structure_RueId ? item.structure_RueId.rueNom : '') +
                      (item.structure_RueNumero ? item.structure_RueNumero : '') +
                      (item.structure_RueBoite ? ' bte ' + item.structure_RueBoite : ''),
                  langueId: 1,
                  gsm: item.gsm && item.gsm !== null ? item.gsm : '',
                  mail: index === 0 ? this.contactForm.value.mail : item.mail && item.mail !== null ? item.mail : '',
                  telephone: item.telephone && item.telephone !== null ? item.telephone : '',
                  fax: item.fax && item.fax !== null ? item.fax : '',
                  site: item.site && item.site !== null ? item.site : '',
                  id: item.id ? item.id : '',
                  fromApiFrench: item.structure_LocaliteId ? item.structure_LocaliteId.fromApiFrench : false,
                  paysId: item.pays ? item.pays.id : 2,
                },
              });
            } else {
              temp_array_adress.push({
                type: item.type && item.type !== null ? item.type : 'PRINCIPALE',
                description: item.type !== 'PRINCIPALE' ? item.type : '',
                contactID: this.contactId,
                adresse: {
                  structure_RueId: item.structure_RueId && item.structure_RueId.id ? item.structure_RueId.id : null,
                  structure_RueNumero:
                    item.structure_RueNumero && item.structure_RueNumero !== null ? item.structure_RueNumero : '',
                  structure_RueBoite:
                    item.structure_RueBoite && item.structure_RueBoite !== null ? item.structure_RueBoite : '',
                  structure_LocaliteId:
                    item.structure_LocaliteId && item.structure_LocaliteId.id && item.structure_LocaliteId !== null
                      ? item.structure_LocaliteId && item.structure_LocaliteId.id
                      : null,
                  impression_Pays: item.structure_LocaliteId ? item.pays && item.pays.description : '',
                  impression_CodePostal: item.impression_CodePostal
                    ? item.impression_CodePostal
                    : item.structure_LocaliteId
                      ? item.structure_LocaliteId.codePostal
                      : '',
                  impression_Localite: item.impression_Localite
                    ? item.impression_Localite
                    : item.structure_LocaliteId
                      ? item.structure_LocaliteId.localite
                      : '',
                  impression_Adresse:
                    item.structure_RueId && item.structure_RueId.rueNom
                      ? item.structure_RueId &&
                        item.structure_RueId.rueNom +
                          ' ' +
                          (item.structure_RueNumero ? item.structure_RueNumero : '') +
                          (item.structure_RueBoite ? ' bte ' + item.structure_RueBoite : '')
                      : '',
                  gsm: item.gsm && item.gsm !== null ? item.gsm : '',
                  mail: index === 0 ? this.contactForm.value.mail : item.mail && item.mail !== null ? item.mail : '',
                  telephone: item.telephone && item.telephone !== null ? item.telephone : '',
                  fax: item.fax && item.fax !== null ? item.fax : '',
                  site: item.site && item.site !== null ? item.site : '',
                  id: item.id ? item.id : '',
                  fromApiFrench: item.structure_LocaliteId ? item.structure_LocaliteId.fromApiFrench : false,
                  paysId: item.pays ? item.pays.id : 2,
                },
              });
            }
          });
        } else {
          temp_array_adress.push({
            type: 'PRINCIPALE',
            description: '',
            contactID: this.contactId,
            adresse: {
              structure_RueId: null,
              structure_RueNumero: '',
              structure_RueBoite: '',
              structure_LocaliteId: null,
              impression_Pays: '',
              impression_CodePostal: '',
              impression_Localite: '',
              impression_Adresse: '',
              gsm: '',
              mail: '',
              telephone: '',
              fax: '',
              site: '',
              id: '',
            },
          });
        }
        this.contact = this.contactForm.value;
        if (this.f['tva'].value !== '' && this.f['tva'].value !== null) {
          this.contact['tva'] = this.f['tva'].value.replace(/\-/g, '').replace(/\./g, '');
          this.contact['tva'] = this.contact['tva'].toUpperCase();
        }
        this.contact.paysContactID = this.paysContactID;
        if (this.paysContactID !== 1) {
          this.contact.pasDeTVABelge = true;
        }
        this.contact.listContactsAdresses = temp_array_adress;
        async.waterfall([
          (cb) => {
            if (!this.isUpdateMode) {
              const check = this.contactForm.value;
              if (check.typeSociete === null || check.typeSociete === false) {
                // C'est un utilisateur lambda qu'on check
                if (
                  check.typeContactId !== null &&
                  check.nom !== null &&
                  check.langueId !== null &&
                  this.emailCheckPattern.length === 0
                ) {
                  let valid: boolean = true;
                  if (!this.contact.nom) {
                    this.toastr.error('Le champ nom est requis');
                    valid = false;
                    this.spinner.hide();
                  }
                  if (!this.contact.typeContactId) {
                    this.toastr.error('Le champ type de contact est requis');
                    valid = false;
                    this.spinner.hide();
                  }
                  if (this.emailCheckPattern.length > 0) {
                    this.toastr.error('Une ou plusieurs adresse(s) email(s) est/sont invalide(s) (exemple@me.com)');
                    valid = false;
                    this.spinner.hide();
                  }
                  if (valid) {
                    this.iContactsService.post(`/contacts?skipWarnings=${this.skipWarnings}`, this.contact).subscribe(
                      (response) => {
                        this.spinner.hide();
                        this.toastr.success('Merci', 'Votre contact a bien été créé!');
                        this.chgSaved = true;
                        this.contact.id = response['id'];
                        this.close(this.contact);
                        if (this.caller !== 'linked') {
                          this.router.navigateByUrl('/private/contacts/details/' + this.contact.id);
                        }
                      },
                      (error) => {
                        this.spinner.hide();
                        console.error(error);
                        if (this.contact.listContactsAdresses) {
                          let noPrincipalAdresse = false;
                          this.contact.listContactsAdresses.map((add) => {
                            if (add.type !== 'PRINCIPALE') {
                              noPrincipalAdresse = true;
                            }
                          });
                          if (noPrincipalAdresse) {
                            this.toastr.error('Si vous renseignez une adresse. Veuillez commencer par la principale !');
                          }
                        } else {
                          this.toastr.error("Une erreur est survenue lors de l'envoi du formulaire");
                        }
                      }
                    );
                  }
                } else {
                  if (check.typeContactId === null) {
                    this.toastr.error('Le type de contact est requis');
                  }
                  if (check.nom === null) {
                    this.toastr.error('Le nom est requis');
                  }
                  if (check.langueId === null) {
                    this.toastr.error('La langue est requise');
                  }
                  if (this.emailCheckPattern.length > 0) {
                    this.toastr.error('Une ou plusieurs adresse(s) email(s) est/sont invalide(s) (exemple@me.com)');
                  }
                  this.spinner.hide();
                }
              } else {
                // C'est une société qu'on check
                if (
                  check.typeContactId !== null &&
                  check.nom !== null &&
                  check.langueId !== null &&
                  this.emailCheckPattern.length === 0
                ) {
                  this.iContactsService.post(`/contacts`, this.contact).subscribe(
                    (response) => {
                      this.spinner.hide();
                      this.toastr.success('Merci', 'Votre contact a bien été créé!');
                      this.chgSaved = true;
                      this.contact.id = response['id'];
                      this.close(this.contact);
                    },
                    (error) => {
                      console.error(error);
                      this.spinner.hide();
                      if (this.contact.listContactsAdresses) {
                        let noPrincipalAdresse = false;
                        this.contact.listContactsAdresses.map((add) => {
                          if (add.type !== 'PRINCIPALE') {
                            noPrincipalAdresse = true;
                          }
                        });
                        if (noPrincipalAdresse) {
                          this.toastr.error('Si vous renseignez une adresse. Veuillez commencer par la principale !');
                        }
                      } else {
                        this.toastr.error("Une erreur est survenue lors de l'envoi du formulaire");
                      }
                    }
                  );
                } else {
                  if (check.typeContactId === null) {
                    this.toastr.error('Le type de contact est requis');
                  }
                  if (check.nom === null) {
                    this.toastr.error('Le nom est requis');
                  }
                  if (check.langueId === null) {
                    this.toastr.error('La langue est requise');
                  }
                  if (this.emailCheckPattern.length > 0) {
                    this.toastr.error('Une ou plusieurs adresse(s) email(s) est/sont invalide(s) (exemple@me.com))');
                  }
                  this.spinner.hide();
                }
              }
            }
          },
        ]);
      },
    ]);
  }

  public isValidEmail(emailString: string): boolean {
    try {
      const pattern = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$');
      const valid = pattern.test(emailString);
      return valid;
    } catch (TypeError) {
      return false;
    }
  }

  public update() {
    this.isFormSubmit = true;
    const temp_array_adress = [];
    if (!this.isUpdateMode) {
      this.contact = new PostContact(this.contact);
    }
    if (!this.paysContactID) {
      this.paysContactID = 1;
    }
    // TODO change if update?

    if (this.contactForm.value.titre) {
      this.contact.titre = this.contactForm.value.titre.titre;
    }
    async.waterfall([
      (call) => {
        this.spinner.show();
        if (this.contactForm.getRawValue().listContactsAdresses.length > 0) {
          this.contactForm.getRawValue().listContactsAdresses.map((item, index) => {
            // adresse vide si nulle
            if (this.countryID.codePays !== 'BE') {
              temp_array_adress.push({
                type: item.type && item.type !== null ? item.type : 'PRINCIPALE',
                description: item.type !== 'PRINCIPALE' ? item.type : '',
                contactID: this.contactID,
                adresse: {
                  structure_RueId: item.structure_RueId && item.structure_RueId.id ? item.structure_RueId.id : null,
                  structure_RueNumero:
                    item.structure_RueNumero && item.structure_RueNumero !== null ? item.structure_RueNumero : '',
                  structure_RueBoite:
                    item.structure_RueBoite && item.structure_RueBoite !== null ? item.structure_RueBoite : '',
                  structure_CodePostal: item.structure_LocaliteId?.codePostal,
                  structure_City: item.structure_LocaliteId?.city,
                  structure_PaysId: item.structure_LocaliteId?.pays?.id,
                  structure_LocaliteId:
                    item.structure_LocaliteId && item.structure_LocaliteId.id && item.structure_LocaliteId !== null
                      ? item.structure_LocaliteId && item.structure_LocaliteId.id
                      : null,
                  impression_Pays: item.pays ? item.pays.description : '',
                  impression_CodePostal: item.impression_CodePostal
                    ? item.impression_CodePostal
                    : item.structure_LocaliteId
                      ? item.structure_LocaliteId.codePostal
                      : '',
                  impression_Localite: item.impression_Localite
                    ? item.impression_Localite
                    : item.structure_LocaliteId
                      ? item.structure_LocaliteId.localite
                      : '',
                  impression_Adresse: item.impression_Adresse
                    ? item.impression_Adresse
                    : item.structure_RueId !== null
                      ? item.structure_RueId.rueNom +
                        ', ' +
                        item.structure_RueNumero +
                        (item.structure_RueBoite ? ' bte ' : '') +
                        item.structure_RueBoite
                      : '',
                  gsm: item.gsm && item.gsm !== null ? item.gsm : '',
                  mail: index === 0 ? this.contactForm.value.mail : item.mail && item.mail !== null ? item.mail : '',
                  telephone: item.telephone && item.telephone !== null ? item.telephone : '',
                  fax: item.fax && item.fax !== null ? item.fax : '',
                  site: item.site ? item.site : '',
                  id: item.id ? item.id : '',
                  fromApiFrench: item.structure_LocaliteId ? item.structure_LocaliteId.fromApiFrench : false,
                },
              });
            } else {
              temp_array_adress.push({
                type: item.type && item.type !== null ? item.type : 'PRINCIPALE',
                description: item.type !== 'PRINCIPALE' ? item.type : '',
                contactID: this.contactID,
                adresse: {
                  structure_RueId: item.structure_RueId && item.structure_RueId.id ? item.structure_RueId.id : null,
                  structure_RueNumero:
                    item.structure_RueNumero && item.structure_RueNumero !== null ? item.structure_RueNumero : '',
                  structure_RueBoite:
                    item.structure_RueBoite && item.structure_RueBoite !== null ? item.structure_RueBoite : '',
                  structure_LocaliteId:
                    item.structure_LocaliteId && item.structure_LocaliteId.id && item.structure_LocaliteId !== null
                      ? item.structure_LocaliteId && item.structure_LocaliteId.id
                      : null,
                  impression_Pays: item.pays && item.pays.description,
                  impression_CodePostal: item.impression_CodePostal
                    ? item.impression_CodePostal
                    : item.structure_LocaliteId
                      ? item.structure_LocaliteId.codePostal
                      : '',
                  impression_Localite: item.impression_Localite
                    ? item.impression_Localite
                    : item.structure_LocaliteId
                      ? item.structure_LocaliteId.localite
                      : '',
                  impression_Adresse: item.impression_Adresse
                    ? item.impression_Adresse
                    : item.structure_RueId !== null
                      ? item.structure_RueId.rueNom +
                        ', ' +
                        item.structure_RueNumero +
                        (item.structure_RueBoite ? ' bte ' : '') +
                        item.structure_RueBoite
                      : '',
                  gsm: item.gsm && item.gsm !== null ? item.gsm : '',
                  mail: index === 0 ? this.contactForm.value.mail : item.mail && item.mail !== null ? item.mail : '',
                  telephone: item.telephone && item.telephone !== null ? item.telephone : '',
                  fax: item.fax && item.fax !== null ? item.fax : '',
                  site: item.site ? item.site : '',
                  id: item.id ? item.id : '',
                  fromApiFrench: item.structure_LocaliteId ? item.structure_LocaliteId.fromApiFrench : false,
                },
              });
            }
          });
        } else {
          temp_array_adress.push({
            type: 'PRINCIPALE',
            description: 'PRINCIPALE',
            contactID: this.contactId,
            adresse: {
              structure_RueString: null,
              structure_RueId: null,
              structure_RueNumero: '',
              structure_RueBoite: '',
              structure_LocaliteId: null,
              structure_CodePostal: '',
              structure_City: '',
              structure_PaysId: null,
              impression_Pays: '',
              impression_CodePostal: '',
              impression_Localite: '',
              impression_Adresse: '',
              gsm: '',
              mail: '',
              telephone: '',
              fax: '',
              site: '',
              id: '',
              fromApiFrench: false,
            },
          });
        }

        this.contact = this.contactForm.value;
        if (this.f['tva'].value !== '' && this.f['tva'].value !== null) {
          this.contact['tva'] = this.f['tva'].value.replace(/\-/g, '').replace(/\./g, '');
          this.contact['tva'] = this.contact['tva'].toUpperCase();
        }
        this.contact.paysContactID = this.paysContactID;
        if (this.paysContactID !== 1) {
          this.contact.pasDeTVABelge = true;
        }

        this.contact.listContactsAdresses = _.clone(temp_array_adress);
        async.waterfall([
          (cb) => {
            this.spinner.show();
            const check = this.contactForm.value;
            // ON CHECK TOUT
            let isDuplicate: boolean;
            let typeArray = [];
            typeArray = this.contact.listContactsAdresses.map(function (item) {
              return item.type;
            });
            isDuplicate = typeArray.some(function (item, idx) {
              return typeArray.indexOf(item) != idx && item !== 'AUTRE';
            });
            if (!isDuplicate) {
              if (check.typeSociete === null || check.typeSociete === false) {
                // C'est un utilisateur lambda qu'on check
                if (
                  check.typeContactId !== null &&
                  check.nom !== null &&
                  check.langueId !== null &&
                  this.emailCheckPattern.length === 0
                ) {
                  let has_main_adress = [];
                  has_main_adress = this.contact.listContactsAdresses.filter((x) => x.type === 'PRINCIPALE');
                  if (has_main_adress.length > 0) {
                    async.waterfall([
                      (cb) => {
                        delete this.contact.typeContact;
                        delete this.contact['langue'];
                        delete this.contact['adressePrincipale'];
                        cb(null);
                      },
                      (cb) => {
                        async.eachSeries(
                          this.contact.listContactsAdresses,
                          (item_form: any, nextAdress) => {
                            if (item_form.adresse['id'] > 0 && !item_form.adresse.toRemove) {
                              const id = item_form.adresse['id'];
                              delete item_form['contactID'];
                              delete item_form.adresse['id'];
                              delete item_form.toRemove;

                              this.iContactsService
                                .put(`/contacts/${this.contactID}/contactAdresses/${id}`, item_form)
                                .subscribe((res) => {
                                  this.toastr.success('une adresse a été mise à jour pour ce contact');
                                  nextAdress();
                                });
                            } else if (item_form.adresse['id'] > 0 && item_form.adresse.toRemove) {
                              const id = item_form.adresse['id'];

                              this.iContactsService
                                .delete(`/contacts/${this.contactID}/contactAdresses/${id}`)
                                .subscribe((res: any) => {
                                  this.toastr.success('une adresse a été supprimé pour ce contact');
                                  nextAdress();
                                });
                            }
                          },
                          () => {
                            this.spinner.hide();
                            cb(null);
                          }
                        );
                      },
                      (cb) => {
                        delete this.contact.listContactsAdresses;
                        if (this.emailCheckPattern.length === 0) {
                          this.iContactsService.put(`/contacts/${this.contactID}`, this.contact).subscribe(
                            (response) => {
                              this.spinner.hide();
                              this.toastr.success('Merci', 'Votre contact a été mis a jour!');
                              this.chgSaved = true;
                              this.ref.close(response);
                              cb(null);
                            },
                            (error) => {
                              console.error(error);
                              this.spinner.hide();
                              this.toastr.error(error.error.errors.map((x) => Object.values(x)).join(', '));
                            }
                          );
                        }
                      },
                    ]);
                  } else {
                    this.toastr.error('Vous devez avoir au moins une adresse principale!');
                  }
                } else {
                  if (check.typeContactId === null) {
                    this.toastr.error('Le type de contact est requis');
                  }
                  if (check.nom === null) {
                    this.toastr.error('Le nom est requis');
                  }
                  if (check.langueId === null) {
                    this.toastr.error('La langue est requise');
                  }
                  if (this.emailCheckPattern.length > 0) {
                    this.toastr.error('Une ou plusieurs adresse(s) email(s) est/sont invalide(s) (exemple@me.com))');
                  }
                  this.spinner.hide();
                }
              } else {
                // C'est une société qu'on check
                if (
                  check.typeContactId !== null &&
                  check.nom !== null &&
                  check.langueId !== null &&
                  this.emailCheckPattern.length === 0
                ) {
                  let has_main_adress = [];
                  has_main_adress = this.contact.listContactsAdresses.filter((x) => x.type === 'PRINCIPALE');
                  if (has_main_adress.length > 0) {
                    async.waterfall([
                      (cb) => {
                        delete this.contact.typeContact;
                        delete this.contact['langue'];
                        delete this.contact['adressePrincipale'];
                        this.contact.prenom = '';
                        cb(null);
                      },
                      (cb) => {
                        this.contact.listContactsAdresses.map((item_form) => {
                          if (item_form.adresse['id'] > 0 && !item_form.adresse.toRemove) {
                            const id = item_form.adresse['id'];
                            delete item_form['contactID'];
                            delete item_form.adresse['id'];
                            if (this.emailCheckPattern.length === 0) {
                              this.iContactsService
                                .put(`/contacts/${this.contactID}/contactAdresses/${id}`, item_form)
                                .subscribe((res) => {
                                  this.toastr.success('une adresse a été mise à jour pour ce contact');
                                });
                            }
                          } else if (item_form.adresse['id'] > 0 && item_form.adresse.toRemove) {
                            const id = item_form.adresse['id'];
                            this.iContactsService
                              .delete(`/contacts/${this.contactID}/contactAdresses/${id}`)
                              .subscribe((res: any) => {
                                this.toastr.success('une adresse a été supprimé pour ce contact');
                              });
                          }
                        });
                        cb(null);
                      },
                      (cb) => {
                        delete this.contact.listContactsAdresses;
                        delete this.contact['listContactsLiensMembres'];
                        if (this.emailCheckPattern.length === 0) {
                          this.iContactsService.put(`/contacts/${this.contactID}`, this.contact).subscribe(
                            (response) => {
                              this.toastr.success('Merci', 'Votre contact a été mis a jour!');
                              this.chgSaved = true;
                              this.spinner.hide();
                              this.ref.close(response);
                              cb(null);
                            },
                            (error) => {
                              console.error(error);
                              this.spinner.hide();
                            }
                          );
                        }
                      },
                    ]);
                  } else {
                    this.toastr.error('Vous devez avoir au moins une adresse principale!');
                    this.spinner.hide();
                  }
                } else {
                  if (check.typeContactId === null) {
                    this.toastr.error('Le type de contact est requis');
                  }
                  if (check.nom === null) {
                    this.toastr.error('Le nom est requis');
                  }
                  if (check.tva === null) {
                    this.toastr.error('Le nom est requis');
                  }
                  if (check.langueId === null) {
                    this.toastr.error('La langue est requise');
                  }
                  if (this.emailCheckPattern.length > 0) {
                    this.toastr.error('Une ou plusieurs adresse(s) email(s) est/sont invalide(s) (exemple@me.com))');
                  }
                  this.spinner.hide();
                }
              }
            } else {
              this.toastr.error(
                "Vous ne pouvez pas avoir deux types d'adresses équivalentes, sauf pour les types 'Autre'"
              );
              this.spinner.hide();
            }
          },
        ]);
      },
    ]);
  }

  public getCountries() {
    this.iContactsService.get(`/Global/pays`).subscribe(
      (response) => {
        this.countriesList = response;
        if (sessionStorage.getItem('clientType') === 'JVS') {
          this.defaultCountry = response.find((x) => x.codePays === 'FR');
        } else {
          this.defaultCountry = response.find((x) => x.codePays === 'BE');
        }
        this.add();

        this.getPostalCodes(this.defaultCountry);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public getStreets(event?: any, index?: any) {
    const localiteId = event
      ? event.id
      : this.contact.adressePrincipale.structure_Localite && this.contact.adressePrincipale.structure_Localite.id;
    this.currentPostCode =
      event && event.codePostal
        ? event.codePostal
        : this.contact.adressePrincipale.structure_Localite &&
          this.contact.adressePrincipale.structure_Localite.codePostal;
    if (localiteId !== 0) {
      if (!event || !event.fromApiFrench) {
        this.streetsList = [];
        try {
          if (this.countryID) {
            this.iContactsService.get(`/Global/pays/${this.countryID.id}/codesPostaux/${localiteId}/rues`).subscribe(
              (response) => {
                let streets = [];
                streets = response.map((item) => new Rue(item));
                this.streetsList = _.sortBy(streets, 'rueNom');
              },
              (error) => {
                console.error(error);
              }
            );
          }
        } catch (error) {
          console.error(error);
          this.toastr.warning('Erreur dans la récupération de la liste des rues');
        }
      } else {
        this.streetsList = [];
        this.getFrenchAPIStreet(event.id);
      }
    } else {
      if (event.codePostal.length >= 5) {
        this.getFrenchAPIPostCode(event.codePostal, index);
      } else {
        this.toastr.warning('Le code postal doit contenir 5 chiffre pour faire une recherche en france');
      }
    }
  }

  public getPostalCodes(country: any) {
    this.countryID = country;
    this.postalCodesList = [];
    if (country === null) {
      country.id = 1;
    } else {
      this.iContactsService.get(`/Global/pays/${country.id}/codesPostaux`).subscribe(
        (response) => {
          this.postalCodesList = response.map((x: LocaliteCp) => new LocaliteCp(x));
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  getFrenchAPIStreet(cityCode, reload: boolean = false) {
    this.iContactsService.getFrenchAPIStreet(cityCode).subscribe((res: any) => {
      if (res && res.features.length > 0) {
        this.apistreetfrenchresults = [];
        res.features.map((item) => {
          this.streetsList.push({
            codeLangue: 'FR',
            codePostal: item.properties.postcode,
            codeRue: item.properties.id,
            id: item.properties.id,
            rueNom: item.properties.name,
          });
        });
        if (reload) {
          this.apistreetfrenchresults = this.streetsList;
          this.search(null, 'frenchstreet');
          if (this.stateDropdownRue) {
            this.stateDropdownRue.show();
          }
        }
      }
    });
  }

  getFrenchAPIPostCode(postCode, index) {
    this.currentPostCode = postCode;
    this.iContactsService.getFrenchAPIPostCode(postCode).subscribe((res: any) => {
      if (res && res.features.length > 0) {
        this.apifrenchresults = [];
        res.features.map((item) => {
          this.apifrenchresults.push({
            id: item.properties.id,
            codePostal: item.properties.citycode,
            localite: item.properties.label,
            city: item.properties.city,
            autoCompleteField: item.properties.citycode + ': ' + item.properties.label,
            pays: this.countryID,
            fromApiFrench: true,
          });
        });
        this.search(null, 'frenchpostcode');
        this.getAdressPrincipal.at(index).patchValue({
          structure_LocaliteId: {
            autoCompleteField: postCode,
            codePostal: postCode,
            id: 0,
            localite: postCode,
          },
        });
        if (this.stateDropdownFrench) {
          this.stateDropdownFrench.show();
        }
      }
    });
  }

  focusAutoComplete() {
    if (this.stateDropdown) {
      const random = 'nope-' + (Math.random() + 1).toString(36).substring(7);
      this.stateDropdown.renderer.setAttribute(this.stateDropdown['inputEL'].nativeElement, 'autocomplete', random);
    }
  }

  public getTypeContact() {
    this.adminApi.getContactTypeAdmin().subscribe(
      (response: any) => {
        if (response.length > 0) {
          response.map((item) => {
            this.typeContacts.push({ label: item.typeContact.toLowerCase(), value: item.id });
          });
          this.typeContacts = _.sortBy(this.typeContacts, 'label');
          this.typeContacts.map((item) => {
            item.label = item.label.charAt(0).toUpperCase() + String(item.label).slice(1).toLowerCase();
          });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public getTitres() {
    this.adminApi.getContactTitres().subscribe(
      (response: any) => {
        if (response.length > 0) {
          response.map((item) => {
            if (item.titre) {
              this.contactTitresList.push({ label: item.titre, value: item.titre });
            }
          });
          this.contactTitresList = _.sortBy(this.contactTitresList, 'label');
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public getFormesJuridiques() {
    this.adminApi.getFormesJuridiquesAdmin().subscribe(
      (response: any) => {
        if (response.length > 0) {
          response.map((item) => {
            this.formesJuridiques.push({ label: item.description, value: item.id });
          });
          this.formesJuridiques = _.sortBy(this.formesJuridiques, 'label');
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public getLangues() {
    return new Promise((resolve, reject) => {
      this.adminApi.getGlobalLangues().subscribe(
        (response) => {
          if (response.length > 0) {
            response.map((item) => {
              this.langues.push({ label: item.description, value: item.id });
            });
            this.langues = _.sortBy(this.langues, 'label');
            resolve(true);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    });
  }

  public getSecteursActivites() {
    this.iContactsService.get(`/Global/secteurActivites`).subscribe(
      (response) => {
        if (response.length > 0) {
          response.map((item) => {
            this.secteursList.push({ label: item, value: item });
          });
          this.secteursList = _.sortBy(this.secteursList, 'label');
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public getEtatCivils() {
    this.iContactsService.get(`/Global/etatCivils`).subscribe(
      (response) => {
        if (response.length > 0) {
          response.map((item) => {
            this.etatCivils.push({ label: item, value: item });
          });
          this.etatCivils = _.sortBy(this.etatCivils, 'label');
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public getSexes() {
    this.iContactsService.get(`/Global/sexes`).subscribe(
      (response) => {
        if (response.length > 0) {
          response.map((item) => {
            this.sexes.push({ label: item, value: item });
          });
          this.sexes = _.sortBy(this.sexes, 'label');
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public getNationalites() {
    this.iContactsService.get(`/Global/nationalites`).subscribe(
      (response) => {
        if (response.length > 0) {
          response.map((item) => {
            this.nationalites.push({ label: item, value: item });
          });
          this.nationalites = _.sortBy(this.nationalites, 'label');
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  // AutoComplete for postalCode and street
  public search(event, mode?: string) {
    const query = event ? event.query : null;
    switch (mode) {
      case 'countries':
        this.filteredCountries = this.filter(query, this.countriesList, mode);
        break;
      case 'postalCode':
        this.filteredPostalCodes = this.filter(query, this.postalCodesList, mode);
        break;
      case 'street':
        this.filteredStreets = this.filter(query, this.streetsList, mode);
      case 'frenchpostcode':
        this.filteredPostalCodes = _.uniq(this.apifrenchresults, 'autoCompleteField');
        break;
      case 'frenchstreet':
        this.filteredStreets = _.uniq(this.apistreetfrenchresults, 'codeRue');
        break;
    }
  }

  public filter(query, list: any[], mode?: string): any[] {
    let filtered: any[] = [];
    switch (mode) {
      case 'contact':
        list.map((contact) => {
          if (contact.name.toLowerCase().includes(query.toLowerCase())) {
            filtered.push(contact);
          }
        });
        break;
      case 'countries':
        list.map((pays) => {
          if (pays.description.toLowerCase().indexOf(query.toLowerCase()) === 0) {
            filtered.push(pays);
          }
        });
        break;
      case 'postalCode':
        if (this.countryID.description == 'France') {
          filtered.push({
            autoCompleteField: 'Voir plus de code postaux',
            codePostal: query,
            id: 0,
            localite: "Voir plus via l'API française",
          });
        }
        list.map((postalCode) => {
          if (
            postalCode &&
            postalCode.localite &&
            postalCode.localite.toLowerCase().indexOf(query.toLowerCase()) === 0
          ) {
            filtered.push(postalCode);
          } else if (postalCode.codePostal && postalCode.codePostal.indexOf(query) === 0) {
            filtered.push(postalCode);
          }
        });
        break;
      case 'street':
        const tempArray = [];
        if (this.countryID.description == 'France') {
          tempArray.push({
            codeLangue: 'FR',
            codePostal: this.currentPostCode,
            codeRue: 0,
            id: 0,
            rueNom: 'Voir plus de rues',
          });
        }
        if (list && list.length > 0) {
          list.map((street) => {
            if (street.rueNom.toLowerCase().includes(query.toLowerCase())) {
              tempArray.push(street);
            }
          });
          const langID = this.contactForm.value.langueId;
          let lang = '';
          if (langID === 1) {
            lang = 'FR';
          } else if (langID === 2) {
            lang = 'NL';
          } else {
            lang = 'EN';
          }
          filtered = tempArray.filter((x) => {
            return x.codeLangue === lang || x.codeLangue == langID;
          });
        }
        break;
    }
    return filtered;
  }

  public showMore() {
    this.fullMode = !this.fullMode;
  }

  initForm() {
    this.contactForm = this.formBuilder.group({
      prenom: new FormControl(null, [Validators.maxLength(200)]),
      nom: new FormControl(null, [Validators.maxLength(200), Validators.required]),
      titre: new FormControl(null),
      fonction: new FormControl(null, [Validators.maxLength(200)]),
      typeContactId: new FormControl(null),
      nomInterne: new FormControl(null),
      typeSociete: new FormControl(0, Validators.required),
      gerantSociete: new FormControl(null),
      membrePersonnel: new FormControl(null),
      nePlusUtiliser: new FormControl(null),
      secteurActivite: new FormControl(null),
      tvaPrefix: new FormControl('BE'),
      tva: new FormControl(null),
      societeFormeJuridiqueId: new FormControl(null),
      bce: new FormControl(null),
      listContactsAdresses: new FormArray([]),
      listSocieteLies: new FormControl(null),
      siteWeb: new FormControl(null, [ValidatorsComponent.URL([])]),
      langueId: new FormControl(1, Validators.required),
      numeroCarteNISS: new FormControl(null),
      dateNaissance: new FormControl(null),
      naissanceLieu: new FormControl(null),
      etatCivil: new FormControl(null),
      sexe: new FormControl(null),
      nationalite: new FormControl(),
      commentaires: new FormControl(null),
      signature1: new FormControl(null),
      signature2: new FormControl(null),
      signatureCertifactNom: new FormControl(null),
      accesModuleExterneDelibe: new FormControl(null),
      loginDelibeWeb: new FormControl(null),
      mail: new FormControl(null),
    });
  }

  get f() {
    return this.contactForm.controls;
  }

  get getAdressPrincipal(): FormArray {
    return this.contactForm.get('listContactsAdresses') as FormArray;
  }
  add(data?: any) {
    async.waterfall([
      (cb) => {
        this.getAdressPrincipal.push(
          this.formBuilder.group({
            type: new FormControl(null, Validators.required),
            structure_RueId: new FormControl(null),
            structure_RueNumero: new FormControl(null),
            structure_RueBoite: new FormControl(null),
            structure_LocaliteId: new FormControl(null),
            impression_Pays: new FormControl(null),
            impression_CodePostal: new FormControl(null),
            impression_Localite: new FormControl(null),
            id: new FormControl(null),
            impression_Adresse: new FormControl(null),
            pays: new FormControl(this.defaultCountry),
            gsm: new FormControl(null),
            mail: new FormControl(null, [Validators.email]),
            telephone: new FormControl(null),
            fax: new FormControl(null),
            site: new FormControl(null),
          })
        );
        this.listOfDisplayedImpressionAdresses.push(false);
        cb(null);
      },
      (cb) => {
        // We patch in the form also because it's the mail principal contact address
        this.contactForm.patchValue({
          mail: this.emailUserFromMailFile ? this.emailUserFromMailFile.toLowerCase() : null,
        });
        this.getAdressPrincipal.controls[this.getAdressPrincipal.length - 1].patchValue({
          mail: this.emailUserFromMailFile ? this.emailUserFromMailFile.toLowerCase() : null,
        });
        // this.contactForm.patchValue({
        //   listContactsAdresses: [
        //     {
        //       mail: this.emailUserFromMailFile ? this.emailUserFromMailFile.toLowerCase() : null,
        //     }
        //   ]
        // });
        cb(null);
      },
    ]);
  }
  remove(index) {
    this.confirmationService.confirm({
      message: 'Voulez-vous vraiment supprimer cette adresse ?',
      key: 'Address',
      accept: () => {
        this.getAdressPrincipal.removeAt(index);
        this.listOfDisplayedImpressionAdresses = this.listOfDisplayedImpressionAdresses.splice(index, 1);
        this.toastr.success(
          'Adresse supprimée temporairement, vous devez cliquer sur enregistrer pour valider vos changements!'
        );
        if (this.getAdressPrincipal.length === 0) {
          const data: any = {
            type: 'PRINCIPALE',
            structure_RueId: '',
            structure_RueNumero: '',
            structure_RueBoite: '',
            structure_LocaliteId: '',
            pays: '',
            gsm: '',
            mail: '',
            telephone: '',
            fax: '',
            site: '',
          };
          this.contactForm.value.listContactsAdresses = data;
        }
      },
    });
  }

  public switchToCompany() {
    this.companyMode = !this.companyMode;
  }

  public onBlurStreet(query, index: number) {
    if (this.filteredStreets && this.filteredStreets.length < 1) {
      this.filteredStreets.push(query);
    }
  }

  getRue(value, index) {
    if (value.id === 0) {
      // On est dans le voir plus de rue
      this.getFrenchAPIStreet(value.codePostal, true);
    }
  }

  public onSelectFiles(event) {}

  onTvaCodeChg(codeTva) {
    this.codePays = codeTva;
    this.tvaFrMode = false;
    const tvaCountry = this.countriesList.find((p) => p.codePays === codeTva);
    this.paysContactID = tvaCountry.id;
    switch (codeTva) {
      case 'BE':
        this.contact.pasDeTVABelge = false;
        this.tvaMask = `BE-0999999999`;
        this.tvaPlaceholder = 'BE-0999999999';
        this.tvaTooltip = 'Code pays + 0 + 9 chiffres.';
        this.bcePlaceholder = '9999999999';
        this.bceMask = '9999999999';
        this.bceTooltip = '10 chiffres.';
        break;
      case 'NL':
        this.contact.pasDeTVABelge = true;
        this.tvaMask = `NL-999999999B99`;
        this.tvaPlaceholder = 'NL-999999999B99';
        this.tvaTooltip = 'Code pays + 11 chiffres + la lettre B.';
        this.bcePlaceholder = '';
        this.bceMask = '';
        this.bceTooltip = '';
        break;
      case 'LU':
        this.contact.pasDeTVABelge = true;
        this.tvaMask = `LU-99999999`;
        this.tvaPlaceholder = 'LU-99999999';
        this.tvaTooltip = 'Code pays + 8 chiffres.';
        this.bcePlaceholder = '';
        this.bceMask = '';
        this.bceTooltip = '';
        break;
      case 'FR':
        this.contact.pasDeTVABelge = true;
        this.tvaFrMode = true;
        this.contactForm.patchValue({ tva: null });
        this.bcePlaceholder = '';
        this.bceMask = '';
        this.bceTooltip = '';
        break;
      case 'DE':
        this.contact.pasDeTVABelge = true;
        this.tvaMask = `DE-999999999`;
        this.tvaPlaceholder = 'DE-999999999';
        this.tvaTooltip = 'Code pays + 9 chiffres.';
        this.bcePlaceholder = '';
        this.bceMask = '';
        this.bceTooltip = '';

        break;
    }
  }

  public close(result?: string) {
    if (this.contactForm.dirty && !this.isFormSubmit) {
      this.confirmationService.confirm({
        message: 'Si vous quittez maintenant vous allez perdre vos changements, voulez-vous continuer?',
        icon: 'fa fa-exclamation-triangle',
        accept: () => {
          this.ref.close(result);
        },
        key: 'Close',
      });
    } else {
      this.ref.close(result);
    }
  }

  changePrefixPhone(event) {}

  onCountryChange(country) {
    let temp = [];
    this.prefixNumber = '';
    setTimeout(() => {
      temp = this.countriesJSON['default'].filter((item) => {
        if (country === item.code) {
          this.prefixNumber = item.value;
        }
      });
    }, 200);
  }

  convertImpression(index) {
    let address = this.getAdressPrincipal.at(index).get('structure_RueId').value;
    const cp = this.getAdressPrincipal.at(index).get('structure_LocaliteId').value;
    const pays = this.getAdressPrincipal.at(index).get('pays').value;
    const number = this.getAdressPrincipal.at(index).get('structure_RueNumero').value;
    const boite = this.getAdressPrincipal.at(index).get('structure_RueBoite').value;
    let gen_address = null;
    this.api.getConfiguration().subscribe((res: any) => {
      const params = res?.groupContacts?.impressionAddressOrder;
      if (address) {
        address = address.rueNom ? address.rueNom : address;
        if (params === 'R') {
          gen_address = address;
        }
        if (params === 'RB') {
          gen_address = address + (boite ? ' bte ' + boite : '');
        }
        if (params === 'RN') {
          gen_address = address + ' ' + number;
        }
        if (params === 'BR') {
          gen_address = (boite ? ' bte ' + boite : '') + ' ' + address;
        }
        if (params === 'BN') {
          gen_address = (boite ? ' bte ' + boite : '') + ' ' + number;
        }
        if (params === 'NRB') {
          gen_address = number + ' ' + address + (boite ? ' bte ' + boite : '');
        }
        if (params === 'NBR') {
          gen_address = number + (boite ? ' bte ' + boite : '') + ' ' + address;
        }
        if (params === 'RNB') {
          gen_address = address + ' ' + number + (boite ? ' bte ' + boite : '');
        }
        if (params === 'RBN') {
          gen_address = address + (boite ? ' bte ' + boite : '') + ' ' + number;
        }
        if (params === 'BNR') {
          gen_address = (boite ? ' bte ' + boite : '') + ' ' + number + ' ' + address;
        }
        if (params === 'BRN') {
          gen_address = (boite ? ' bte ' + boite : '') + ' ' + address + ' ' + number;
        }
        if (!params) {
          gen_address = address + ' ' + (number ? number : '') + (boite ? ' bte ' + boite : '');
        }
      } else {
        gen_address = '';
      }
    });
    setTimeout(() => {
      this.getAdressPrincipal.at(index).patchValue({
        impression_Adresse: gen_address,
        impression_CodePostal: cp.codePostal,
        impression_Localite: cp.city ? cp.city : cp.localite,
        impression_Pays: pays ? pays.description : '',
      });
    }, 1000);
  }

  openNewPostalCodeModal(i, type) {
    this.actualAdressToBeModified = i;
    this.typeOfAddressToBeModified = type;
    this.iContactsService.get('/Global/pays').subscribe((res: any) => {
      this.countries = [];
      if (res && res.length > 0) {
        res.map((item) => {
          this.countries.push({ label: item.description, value: item.id });
        });
      }
      this.showCreateCP = true;
    });
  }
  openNewStreetModal(i, type) {
    this.actualStreetToBeModified = i;
    this.typeOfStreetToBeModified = type;
    this.showCreateStreet = true;
  }

  openNewTypeContact() {
    this.spinner.show();
    async.parallel(
      [
        (callback) => {
          this.typesLV = [];
          this.adminApi.getManagementTypesContacts().subscribe((res: any) => {
            if (res && res.length > 0) {
              res.map((item) => {
                this.typesLV.push({ label: item.typeContact, value: item.id });
              });
            }
            callback();
          });
        },
        (callback) => {
          this.groups = [];
          this.adminApi.getGroupes().subscribe((res: any) => {
            if (res.length > 0) {
              res.map((item) => {
                this.groups.push({ label: item.description, value: item.id });
              });
            }
            callback();
          });
        },
        (callback) => {
          this.iContactsService.post(`/contacts/lowinformation`, { includeContactParent: true }).subscribe(
            (response) => {
              const temp = [];
              if (response.length > 0) {
                response.map((contact) => {
                  if (contact) {
                    if (!(contact.nom === '' && contact.prenom === '')) {
                      contact.label = contact.prenom + ' ' + contact.nom;
                      contact.value = contact.id ? contact.id : null;
                      temp.push({ label: contact.label, value: contact.id });
                    }
                  }
                });
                this.contactsInit = response;
                this.contactsLinked = _.sortBy(temp, 'label');
              }
              callback(null);
            },
            (error) => {
              console.error(error);
            }
          );
        },
      ],
      () => {
        this.spinner.hide();
        this.showCreateContactType = true;
      }
    );
  }

  filterContact(event) {
    if (event && event.value !== null) {
      this.contacts = this.contactsInit.filter((x) => x.typeContactID === event.value);
    } else {
      this.contacts = this.contactsInit;
    }
  }

  submitNewCP() {
    if (this.formCP.valid) {
      this.iContactsService.post('/Management/CodesPostaux', this.formCP.value).subscribe((res: any) => {
        //on va récupérer les codes postaux du pays du formulaire et on vérifie si l'adresse ajoutée est présente dans cette liste. Si pas on affiche rien.
        this.getPostalCodes(this.getAdressPrincipal.at(this.actualAdressToBeModified).value.pays);
        setTimeout(() => {
          if (this.typeOfAddressToBeModified === 'structure') {
            let locToPatch;
            if (this.postalCodesList.find((x) => x.id === res.id)?.id) {
              //si on a bien une localité
              locToPatch = this.postalCodesList.find((x) => x.id === res.id);
              locToPatch.autoCompleteField =
                (locToPatch.codePostal ? locToPatch.codePostal : '') +
                ': ' +
                (locToPatch.localite ? locToPatch.localite : '');
            }
            this.getAdressPrincipal.at(this.actualAdressToBeModified).patchValue({
              structure_LocaliteId: this.postalCodesList.find((x) => x.id === res.id)?.id
                ? locToPatch
                : this.getAdressPrincipal.at(this.actualAdressToBeModified).value.structure_LocaliteId,
            });
            //Une fois le patch effectué, on va aller essayer de récupérer toutes les rues
            //déjà on va le faire ssi il existe un pays && une loc dans le formulaire
            if (
              this.getAdressPrincipal.at(this.actualAdressToBeModified).value.pays &&
              this.getAdressPrincipal.at(this.actualAdressToBeModified).value.pays.id &&
              this.getAdressPrincipal.at(this.actualAdressToBeModified).value.structure_LocaliteId &&
              this.getAdressPrincipal.at(this.actualAdressToBeModified).value.structure_LocaliteId.id
            ) {
              const data = {
                id: this.getAdressPrincipal.at(this.actualAdressToBeModified).value.structure_LocaliteId.id,
                localite: this.getAdressPrincipal.at(this.actualAdressToBeModified).value.structure_LocaliteId.id, //juste pour empêcher getStreets de bugger
                pays: this.getAdressPrincipal.at(this.actualAdressToBeModified).value.pays,
              };
              this.getStreets(data, this.actualAdressToBeModified);
              this.toastr.success('Le code postal a bien été enregistré');
              this.formCP.reset();
              this.showCreateCP = false;
            } else {
              this.toastr.success('Le code postal a bien été enregistré');
              this.formCP.reset();
              this.showCreateCP = false;
            }
          } else {
            this.getAdressPrincipal.at(this.actualAdressToBeModified).patchValue({
              impression_CodePostal: this.postalCodesList.find((x) => x.id === res.id)?.codePostal,
              impression_Localite: this.postalCodesList.find((x) => x.id === res.id)?.localite,
            });
            this.toastr.success('Le code postal a bien été enregistré');
            this.formCP.reset();
            this.showCreateCP = false;
          }
        }, 1000);
      });
    } else {
      this.toastr.warning("Vous n'avez pas rempli tous les champs obligatoires");
    }
  }

  submitNewStreet() {
    if (this.formStreet.valid) {
      this.iContactsService.post('/Management/Rues', this.formStreet.value).subscribe((res: any) => {
        if (
          this.getAdressPrincipal.at(this.actualStreetToBeModified).value.pays &&
          this.getAdressPrincipal.at(this.actualStreetToBeModified).value.pays.id &&
          this.getAdressPrincipal.at(this.actualStreetToBeModified).value.structure_LocaliteId &&
          this.getAdressPrincipal.at(this.actualStreetToBeModified).value.structure_LocaliteId.id
        ) {
          const data = {
            id: this.getAdressPrincipal.at(this.actualStreetToBeModified).value.structure_LocaliteId.id,
            localite: this.getAdressPrincipal.at(this.actualStreetToBeModified).value.structure_LocaliteId.id, //juste pour empêcher le getStreets de bugger
            pays: this.getAdressPrincipal.at(this.actualStreetToBeModified).value.pays,
          };
          this.getStreets(data, this.actualStreetToBeModified);
          setTimeout(() => {
            if (this.typeOfStreetToBeModified === 'structure') {
              this.getAdressPrincipal.at(this.actualStreetToBeModified).patchValue({
                structure_RueId: this.streetsList.find((x) => x.id === res.id)?.id
                  ? this.streetsList.find((x) => x.id === res.id)
                  : this.getAdressPrincipal.at(this.actualStreetToBeModified).value.structure_RueId,
              });
            } else {
              this.getAdressPrincipal.at(this.actualAdressToBeModified).patchValue({
                impression_Adresse: this.streetsList.find((x) => x.id === res.id)?.rueNom,
              });
            }
            this.toastr.success('La rue a bien été enregistrée');
            this.formStreet.reset();
            this.showCreateStreet = false;
          }, 1000);
        } else {
          this.toastr.success('La rue a bien été enregistrée');
          this.formStreet.reset();
          this.showCreateStreet = false;
        }
      });
    } else {
      this.toastr.warning("Vous n'avez pas rempli tous les champs obligatoires");
    }
  }

  submitNewContactType() {
    if (this.formContactType.value.categorie === null) {
      this.formContactType.value.categorie = false;
    }
    if (this.formContactType.value.nePasInclureArbreDestinataires === null) {
      this.formContactType.value.nePasInclureArbreDestinataires = false;
    }
    if (this.formContactType.value.nePasRemplirLaListe === null) {
      this.formContactType.value.nePasRemplirLaListe = false;
    }
    if (this.formContactType.value.sanctionTypeVictime === null) {
      this.formContactType.value.sanctionTypeVictime = false;
    }
    if (this.formContactType.value.verrouille === null) {
      this.formContactType.value.verrouille = false;
    }
    if (this.formContactType.valid) {
      this.adminApi.postManagementTypesContacts(this.formContactType.value).subscribe((res: any) => {
        this.toastr.success('Le type a bien été enregistré');
        this.showCreateContactType = false;
        //on va récupérer tous les types de contacts puis patcher
        this.adminApi.getContactTypeAdmin().subscribe(
          (response: any) => {
            if (response.length > 0) {
              response.map((item) => {
                // Suppression des contacts avec des noms ET Prenoms chaine vide
                if (!(item.nom === '' && item.prenom === '')) {
                  this.typeContacts.push({ label: item.typeContact.toLowerCase(), value: item.id });
                }
              });
              this.typeContacts = _.sortBy(this.typeContacts, 'label');
              this.typeContacts.map((item) => {
                item.label = item.label.charAt(0).toUpperCase() + String(item.label).slice(1).toLowerCase();
              });
              this.contactForm.patchValue({ typeContactId: res.id });
            }
          },
          (error) => {
            console.error(error);
          }
        );
      });
    } else {
      this.toastr.warning("Vous n'avez pas rempli tous les champs obligatoires");
    }
  }

  patchValue(data, formControlName, index?: number) {
    if (data) {
      this.getAdressPrincipal.at(index).patchValue({
        [formControlName]: data,
      });
      if (formControlName.includes('structure_LocaliteId')) {
        this.getStreets(data);
      }
    }
  }
}
