<div class="custom-skeleton">     
     <ul class="m-0 o-0">
          <li class="mb-3">
               <div class="flex">
                    <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                    <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2" ></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                    </div>
               </div>
          </li>
          <li class="mb-3">
               <div class="flex">
                    <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                    <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                    </div>
               </div>
          </li>
          <li class="mb-3">
               <div class="flex">
                    <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                    <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                    </div>
               </div>
          </li>
          <li>
               <div class="flex">
                    <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                    <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                    </div>
               </div>
          </li>
     </ul>
</div>