import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ITableColumn, ITableConfig, ITableItem } from '@devinforius/super-compos';
import { connectionColumn } from './connection-column';
import { iCourrierService } from 'src/app/core/services/iCourrier.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import '@angular/common/locales/global/es';
import { ConfirmationService } from 'primeng/api';
import { forkJoin, of } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { catchError } from 'rxjs/operators';
import { inputs } from '@syncfusion/ej2-angular-documenteditor/src/document-editor/documenteditor.component';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss'],
})
export class ConnectionsComponent implements OnInit {
  @Input() set courriersIds(value) {
    this.listOfCourriersIds = value;
    this.getCourrierLinked();
  }
  @Input() pointId: number;
  public listOfCourriersIds = [];
  public tableConfigurations: ITableConfig;
  public items: ITableItem[] = [];
  public columns: Array<ITableColumn> = connectionColumn(this);
  public loading: boolean = true;
  public noResultInTable: string;

  @Output() emitLinkDeleted: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private translate: TranslateService,
    private apiICourrier: iCourrierService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private apiIDelibe: ApiService
  ) {}

  ngOnInit(): void {
    const translation = this.translate.instant('nomail');
    this.noResultInTable = `<div class="alert alert-primary mt-3" role="alert">${translation}</div>`;
    this.getITableConfigurations();
  }

  getITableConfigurations() {
    this.tableConfigurations = new ITableConfig({
      showAdvancedSearch: false,
      showFastSearch: false,
      viewSearch: false,
      multiselect: false,
      showPaginator: false,
      emptyMessage: this.noResultInTable,
      showColumnSelector: false,
      rowPerPageOptions: [500],
      scrollable: true,
      responsive: true,
      height: 'calc(100vh - 300px)',
    });
  }

  getCourrierLinked() {
    this.items = [];
    if (this.listOfCourriersIds?.length > 0) {
      this.apiICourrier.searchCourriersInfos(this.listOfCourriersIds).subscribe({
        next: (courrierInfos: any) => {
          courrierInfos.forEach((courrier) => {
            this.items.push({
              courrierId: courrier.id,
              object: courrier.objet,
              relatedCourrierType: 'Courrier',
              service: courrier.serviceProprietaire?.description,
            });
          });
          this.items = [...this.items];
        },
        error: (error: HttpErrorResponse) => {
          this.toastr.error(this.translate.instant('error.errorWhileFetchingLinkedMailsInfos'));
        },
      });
    }
  }

  deleteLink(value) {
    console.log(value);
    console.log(this.pointId);
    this.confirmationService.confirm({
      message: this.translate.instant('confirmDeleteLink'),
      accept: () => {
        forkJoin({
          deleteDelibeLinks: this.apiIDelibe.deleteIDelibeLinks(this.pointId, [value.courrierId]).pipe(
            catchError(() => {
              this.toastr.error(this.translate.instant('errorWhileDeletingLink'));
              return of({});
            })
          ),
          deleteConnectorDelibeLinks: this.apiICourrier.deleteIDelibeLinks(value.courrierId, this.pointId).pipe(
            catchError(() => {
              this.toastr.error(this.translate.instant('errorWhileDeletingLink'));
              return of({});
            })
          ),
        }).subscribe(() => {
          this.toastr.success(this.translate.instant('courrierLink.linkDeleted'));
          this.emitLinkDeleted.emit();
        });
      },
      key: 'confirmDeleteLink',
    });
  }
}
