import * as moment from 'moment';
import { Global_Class } from './global';
import { Contact } from './iContact/contact';
import { UserContact } from './iContact/user-contact';

export class Participant {
     id: number;
     name : string;
     typeSeanceId: number;
     contactId: number;
     contactIdiAdmin: number;
     contactLabel:string;
     role: string;
     signatureId: number;
     roleId: number;
     roleName: string;
     sousTri: number;
     pasDeDroitDeVote: number;
     pasDeDroitDeVoteIcon: string;
     ordrePreseance: number;
     ordrePreseanceIcon:string;
     absentSeance:number;
     absentSeanceIcon:string;
     absentDebutSeance:number;
     absentDebutSeanceIcon:string;
     valableDu: string;
     valableAu: string;
     valableDuFormat:string;
     valableAuFormat:string;
     fraisDeplacementTypeIndemnite: number;
     invite: number;
     inviteIcon: string;
     groupePolitiqueId: number;    
     groupePolitiqueLabel: string;
     signatureLabel: string;
     typeSeance: string;
     absent : number;
     public label:string;
     public value:number;
     public roleTri:number;
     constructor(data:any, contacts?:Array<Contact>,usersAdmin?:Array<UserContact>, globalements?:Global_Class) {
          Object.assign(this, data);
          if ( this.valableDu ){
               this.valableDuFormat = moment(this.valableDu).format('DD/MM/YYYY');
          }
          if ( this.valableAu ){
               this.valableAuFormat = moment(this.valableAu).format('DD/MM/YYYY');
          }
          if ( contacts && contacts.length > 0 && usersAdmin && usersAdmin.length >0){
               if(this.contactIdiAdmin){
                    const contact = usersAdmin.find( x => x.contactForUser.contactId === this.contactIdiAdmin);
                    if( contact ){
                         this.name = ( contact.contactForUser?.prenom ? contact.contactForUser?.prenom + ' ' : '') + ( contact.contactForUser?.nom ? contact.contactForUser?.nom : '');
                         this.label = this.name;
                    }
               }else if(this.contactId){
                    const contact = contacts.find( x => x.id === this.contactId);
                    if( contact ){
                         this.name = ( contact.prenom ? contact.prenom + ' ' : '') + ( contact.nom ? contact.nom : '');
                         this.label = this.name;
                    }
               }
          }
          if ( this.id ){
               this.value = this.id;
          }
          if (this.absentDebutSeance || this.absentSeance){
               this.absent = 1;
          }
          
          this.absentDebutSeanceIcon = this.showBoolean(this.absentDebutSeance);
          this.absentSeanceIcon = this.showBoolean(this.absentSeance);
          this.inviteIcon = this.showBoolean(this.invite);
          this.pasDeDroitDeVoteIcon = this.showBoolean(this.pasDeDroitDeVote);
          if (globalements ){
               if (globalements.roles_LV.length > 0){
                    const role = globalements.roles_LV.find( x => x.value === this.roleId);
                    if (role ){
                         this.roleName = role.label;
                         this.roleTri = +role?.item?.ordreDansPresences; 
                    }
               }
               if (globalements.signatures_LV.length > 0){
                    const sign = globalements.signatures_LV.find( x => x.value === this.signatureId);
                    if (sign ){
                         this.signatureLabel = sign.label;
                    }
               }
               if (globalements.groupes_pol_LV.length > 0){
                    const group_pol = globalements.groupes_pol_LV.find( x => x.value === this.groupePolitiqueId);
                    if (group_pol ){
                         this.groupePolitiqueLabel = group_pol.label;
                    }
               }
          }
     }

     private showBoolean(value) {
          if (value) {
            return `<b style="color: #07E299;"><i class="bi bi-check-circle-fill"></i></b>`;
          } else if (value === -1) {
            return `⌛`;
          } else {
            return `<b style="color: #FF6260;"></b>`;
          }
        }
}
 