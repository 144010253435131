<div class="page-wrapper">
  <super-sidebar
    [langLinks]="langLinks"
    (langChg)="receive_langChg($event)"
    [display]="true"
    [isAdmin]="isAdmin"
    [user]="user"
    [name]="name"
    applicationTitle="{{ appConfig?.clientType !== null && appConfig?.clientType !== 'inforius' ? '' : 'iDelibe' }}"
    [logout]="logout"
    [navLinks]="navLinks"
    [theme]="themeName"
    [accountLinks]="accountLinks"
    [hideAppsLinks]="true"
    [settingsLinks]="settingsLinks"
    [logoApp]="image"
    [logoWebsite]="webSite"
    [hideNotif]="true"
    [assistanceRoute]="'/private/assistance'"
    [setSmallSidebar]="hasSmallSidebar"
    [entityName]="tenantInfos?.name"></super-sidebar>
  <main class="page-content h-100">
    <div class="container-fluid h-100">
      <div class="row h-100">
        <div class="col-12 main__content">
          <ngx-spinner
            bdColor="rgb(255,255,255)"
            size="medium"
            color="#60cdff"
            type="ball-clip-rotate"
            [fullScreen]="false"></ngx-spinner>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </main>
</div>
