<div class="modal-header super-compos-modal-header">
    <h4 class="modal-title super-compos-modal-title">
        <span class="noItalic super-compos-modal-title super-compos-modal-title-span">{{'duplicate.duplicationManagement'|translate}}</span>
    </h4>
    <div class="d-flex justify-content-end">
        <super-button styleClass="blue" (click)="ref.close(false);" [text]="'btn.cancel'|translate"></super-button>
          <super-button styleClass="green ml-3" (click)="submitDuplicationManagement()" text="Confirmer"></super-button>
    </div>
</div>
<div class="modal-body">
    <section [formGroup]="formDuplicationPoint" >
         <div class="row" *ngIf="duplicationManagementIndex ===0"> <!-- 1st part of the modal directly accessed when its opened -->
              <div class="col-12 mb-2 mt-2">
                   <p-checkbox [label]="'duplicate.pointContent'|translate" binary="true" formControlName="infos"></p-checkbox>
              </div>
              <div class="col-12 mb-2" *ngIf="filesForDuplication.length >0">
                   <p-checkbox [label]="'duplicate.filesSelection'|translate" binary="true" formControlName="files"></p-checkbox>
              </div>
              <div class="col-12">
                   <p-checkbox [label]="'duplicate.recipients'|translate" binary="true" formControlName="recipients"></p-checkbox>
              </div>
         </div>
         <div class="row" *ngIf="duplicationManagementIndex ===1">  <!-- 2nd part of the modal accessed when the user chose to add files to the duplication-->
              <div class="col-12">
                   <super-table [initialSelection]="filesForDuplication" [forselectionDefaultOption]="false" #superTableFilesDuplication [forselection]="null" [items]="filesForDuplication|sort:'OrdreAffichage':'ASC'" [columns]="columnsFiles" id="super-files-tables-Duplications" [height]="'calc( 100vh - 400px)'" [multiselect]="true">
                   </super-table>
              </div>
         </div>
    </section>
</div>


<p-sidebar [(visible)]="modalPdfViewer" position="right" [style]="{width:'50vw'}" >
     <section>
          <ngx-extended-pdf-viewer 
          *ngIf="blobViewer"
          [src]="blobViewer" 
          height="100vh"
          [useBrowserLocale]="true"
          [zoom]="'auto'"
          [showFindButton]="true"
          [showPagingButtons]="true"
          [showZoomButtons]="true"
          [showPresentationModeButton]="true"
          [showOpenFileButton]="false"
          [showPrintButton]="false"
          [showDownloadButton]="false"
          [showBookmarkButton]="false"
          [showSecondaryToolbarButton]="true"
          [showRotateButton]="true"
          [showHandToolButton]="true"
          [showScrollingButton]="true"
          [showSpreadButton]="false"
          [showPropertiesButton]="true"
          [customToolbar]="additionalButtons"
          [(page)]="currentPage" 
          > 
          </ngx-extended-pdf-viewer>
          <ng-template #additionalButtons>
              <div id="toolbarViewer" >
                <div id="toolbarViewerLeft">
                  <pdf-toggle-sidebar></pdf-toggle-sidebar>
                  <div class="toolbarButtonSpacer"></div>
                  <pdf-find-button [showFindButton]="true" [textLayer]="true"></pdf-find-button>
                  <pdf-paging-area></pdf-paging-area>
                </div>
                <div id="toolbarViewerRight">
                  <pdf-presentation-mode></pdf-presentation-mode>
                  <pdf-select-tool></pdf-select-tool>
                  <pdf-rotate-page></pdf-rotate-page>
                  <pdf-hand-tool></pdf-hand-tool>
                  <pdf-bookmark></pdf-bookmark>
                  <pdf-toggle-secondary-toolbar></pdf-toggle-secondary-toolbar>
                  <!-- <pdf-print></pdf-print> -->
                </div>
                <pdf-zoom-toolbar ></pdf-zoom-toolbar>
              </div>
            </ng-template>
     </section>
</p-sidebar>

