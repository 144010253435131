import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TenantInfo } from 'src/app/shared/models/tenant-info';
import { UserInfo } from 'src/app/shared/models/user-info';
import { map, switchMap, tap } from 'rxjs/operators';
import { GlobalConfig } from 'src/app/shared/models/global';

@Injectable({
  providedIn: 'root',
})
export class iAdminService {
  public apiBaseUrl: string;
  public body: FormData;
  public headers: any;
  public params: HttpParams = new HttpParams();
  private globalConfigSubject$ = new BehaviorSubject<GlobalConfig>(null);
  private userInfoSubject$ = new BehaviorSubject<UserInfo>(null);
  private managmentUser$ = new BehaviorSubject<any>(null);

  constructor(public http: HttpClient) {
    const config = JSON.parse(sessionStorage.getItem('globalConfig'));
    this.apiBaseUrl = config.apiIAdminBaseUrl + '/api/v1';
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.body = new FormData();
    this.params = new HttpParams();
  }

  getGlobalConfig(): Observable<GlobalConfig> {
    if (!this.globalConfigSubject$.value) {
      this.http.get<GlobalConfig>(`${this.apiBaseUrl}/Global/Configuration`).subscribe((res) => {
        this.globalConfigSubject$.next(res);
      });
    }
    return this.globalConfigSubject$.asObservable();
  }

managmentUsers(id: number): Observable<any> {
  return of(this.managmentUser$.value).pipe(
    switchMap((user) => {
      if (!user) {
        return this.http.get<any>(`${this.apiBaseUrl}/Management/Users/${id}`).pipe(
          tap((res) => this.managmentUser$.next(res))
        );
      } else {
        return this.managmentUser$.asObservable();
      }
    })
  );
}

  getRightsManagement(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Me/Rights/Management`);
  }

  //* Gets the infos about the tenant the user's connected in
  getTenant() {
    return this.http.get<TenantInfo>(`${this.apiBaseUrl}/Global/TenantInfo`).pipe(
      map((res) => {
        return new TenantInfo(res);
      })
    );
  }

  getUserInfo(): Observable<UserInfo> {
    if (!this.userInfoSubject$.value) {
      this.http.get<UserInfo>(`${this.apiBaseUrl}/Me/UserInfo`).subscribe((userInfo) => {
        this.userInfoSubject$.next(userInfo);
      });
    }
    return this.userInfoSubject$.asObservable();
  }

  getAvatar() {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Me/Avatar`, { responseType: 'blob' as 'json' });
  }

  // Global
  getGlobalLangues(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Langues`);
  }
  getGlobalServices(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Services`);
  }
  getUsersByServiceId(id): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Services/${id}/Users/ids`);
  }
  getGlobalClassementById(id: number): Observable<any> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/ClassementDossiers/${id}`);
  }
  getGlobalClassementCode(data: Array<any>): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Classements/Search`, { params: { svcIds: data } });
  }
  getGlobalClasseurs(data?: any): Observable<any[]> {
    let params = new HttpParams();
    if (data) {
      Object.keys(data).forEach((key) => (params = params.set(key, data[key])));
    }
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Classeurs?${params}`);
  }
  getGlobalChemises(data?: any): Observable<any[]> {
    let params = new HttpParams();
    if (data) {
      Object.keys(data).forEach((key) => (params = params.set(key, data[key])));
    }
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Chemises?${params}`);
  }

  getGlobalRoles(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Roles`);
  }
  getGroupes(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/Global/Groupes`);
  }

  getFormesJuridiquesAdmin() {
    return this.http.get(`${this.apiBaseUrl}/Global/FormesJuridiques`);
  }

  getContactTitres() {
    return this.http.get(`${this.apiBaseUrl}/Global/contactTitres`);
  }

  getContactTypeAdmin() {
    return this.http.get(`${this.apiBaseUrl}/Global/contactTypes`);
  }

  putContactTypeAdmin(id: number) {
    return this.http.put(`${this.apiBaseUrl}/Global/contactTypes/${id}`, null);
  }

  getContactCategories(id: number) {
    return this.http.get<any>(`${this.apiBaseUrl}/Global/contactCategories/${id}`, this.headers);
  }

  getManagementTypesContacts() {
    return this.http.get<any>(`${this.apiBaseUrl}/Management/TypesContacts`, this.headers);
  }

  postManagementTypesContacts(data) {
    return this.http.post<any>(`${this.apiBaseUrl}/Management/TypesContacts`, data);
  }

  getGroupesMembresIAdminSearch(groupsIds: any) {
    return this.http.get(`${this.apiBaseUrl}/Global/GroupesMembres/Search`, {
      params: new HttpParams().set('GroupeIds', `${groupsIds}`),
    });
  }
}
