import { ActionsContainerComponent } from './actions-container.component';
import { IPagedResult } from 'src/app/shared/models/response/IPagedResult';
import { Observable, forkJoin } from 'rxjs';
import { IPointSearchActionsQuery, ISeanceSearchActionsQuery } from 'src/app/shared/models/request/SearchActionsQuery';
import { tap } from 'rxjs/operators';
import { ActionItem, FetchMode, ITabInfo } from './models';
import { IPointActionSearchResult } from 'src/app/shared/models/response/ActionsSearchResult';
import { Subscription, iif, of } from 'rxjs';
import * as _ from 'underscore';

export const fetchTabsDataObservable = (
  self: ActionsContainerComponent,
  isOkToContinue: boolean,
  onlyType: ITabInfo['slug'] = null // passed when there is a pagination
): Observable<IPagedResult<ActionItem>[]> | null => {
  if (!isOkToContinue) return null;
  const result: Observable<IPagedResult<ActionItem>>[] = [];
  if (self.fetchMode == FetchMode.Points) {
    fetchPointsRelatedTabsData(self, onlyType, result);
  } else if (self.fetchMode == FetchMode.Seances) {
    fetchSeancesRelatedTabsData(self, onlyType, result);
  }

  return forkJoin(result);
};

export const fetchPointsRelatedTabsData = (
  self: ActionsContainerComponent,
  onlyType: ITabInfo['slug'] = null,
  arrOfObservables: Observable<IPagedResult<ActionItem>>[]
): void => {
  if (onlyType == null || onlyType == 'all') {
    // all actions
    const searchAllActionsPayload: IPointSearchActionsQuery = {
      pageSize: self.sizeChoice,
      pageNumber: self.actionsHolder.all.pageNumber,
      calcTotal: true,
      sortOrder: 0,
      contactId: self.userInfos.contactId,
    };
    const searchAllActions$ = self.api.searchPointsActions(searchAllActionsPayload).pipe(
      tap((searchRes) => {
        searchRes.items.map((item) => {
          if (item.objet) {
            item.fullObjet = item.objet;
          }
          if (item.action && item.action.reponse) {
            item.action.fullreponse = item.action.reponse;
          }
        });
        self.actionsHolder.all.items = searchRes.items;
        self.actionsHolder.all.totalCount = searchRes.totalCount;
        self.actionsHolder.all.pageNumber = searchRes.pageNumber;
        self.actionsHolder.all.totalPages = searchRes.totalPages;
        self.actionTabs.find((x) => x.slug === 'all').count = searchRes.totalCount;
      })
    );
    arrOfObservables.push(searchAllActions$);
  }

  if (onlyType == null || onlyType == 'todo') {
    // todo actions
    const searchTodoActionsPayload: IPointSearchActionsQuery = {
      pageSize: self.sizeChoice,
      pageNumber: self.actionsHolder.todo.pageNumber,
      calcTotal: true,
      sortOrder: 0,
      contactId: self.userInfos.contactId,
      traite: false,
    };
    const searchTodoActions$ = self.api.searchPointsActions(searchTodoActionsPayload).pipe(
      tap((searchRes) => {
        searchRes.items.map((item) => {
          if (item.objet) {
            item.fullObjet = item.objet;
          }
          if (item.action && item.action.reponse) {
            item.action.fullreponse = item.action.reponse;
          }
        });
        self.actionsHolder.todo.items = searchRes.items;
        self.actionsHolder.todo.totalCount = searchRes.totalCount;
        self.actionsHolder.todo.pageNumber = searchRes.pageNumber;
        self.actionsHolder.todo.totalPages = searchRes.totalPages;
        self.actionTabs.find((x) => x.slug === 'todo').count = searchRes.totalCount;
      })
    );
    arrOfObservables.push(searchTodoActions$);
  }

  if (onlyType == null || onlyType == 'finished') {
    // finished actions
    const searchFinishedActionsPayload: IPointSearchActionsQuery = {
      pageSize: self.sizeChoice,
      pageNumber: self.actionsHolder.finished.pageNumber,
      calcTotal: true,
      sortOrder: 0,
      contactId: self.userInfos.contactId,
      traite: true,
    };
    const searchFinishedActions$ = self.api.searchPointsActions(searchFinishedActionsPayload).pipe(
      tap((searchRes) => {
        searchRes.items.map((item) => {
          if (item.objet) {
            item.fullObjet = item.objet;
          }
          if (item.action && item.action.reponse) {
            item.action.fullreponse = item.action.reponse;
          }
        });
        self.actionsHolder.finished.items = searchRes.items;
        self.actionsHolder.finished.totalCount = searchRes.totalCount;
        self.actionsHolder.finished.pageNumber = searchRes.pageNumber;
        self.actionsHolder.finished.totalPages = searchRes.totalPages;
        self.actionTabs.find((x) => x.slug === 'finished').count = searchRes.totalCount;
      })
    );
    arrOfObservables.push(searchFinishedActions$);
  }

  if (onlyType == null || onlyType == 'asked') {
    // asked actions
    const searchAskedActionsPayload: IPointSearchActionsQuery = {
      pageSize: self.sizeChoice,
      pageNumber: self.actionsHolder.asked.pageNumber,
      calcTotal: true,
      sortOrder: 0,
      transmissionUserId: self.userInfos.id,
    };
    const searchAskedActions$ = self.api.searchPointsActions(searchAskedActionsPayload).pipe(
      tap((searchRes) => {
        searchRes.items.map((item) => {
          if (item.objet) {
            item.fullObjet = item.objet;
          }
          if (item.action && item.action.reponse) {
            item.action.fullreponse = item.action.reponse;
          }
        });
        self.actionsHolder.asked.items = searchRes.items;
        self.actionsHolder.asked.totalCount = searchRes.totalCount;
        self.actionsHolder.asked.pageNumber = searchRes.pageNumber;
        self.actionsHolder.asked.totalPages = searchRes.totalPages;
        self.actionTabs.find((x) => x.slug === 'asked').count = searchRes.totalCount;
      })
    );
    arrOfObservables.push(searchAskedActions$);
  }
};

export const fetchSeancesRelatedTabsData = (
  self: ActionsContainerComponent,
  onlyType: ITabInfo['slug'] = null,
  arrOfObservables: Observable<IPagedResult<ActionItem>>[]
): void => {
  if (onlyType == null || onlyType == 'all') {
    // all actions
    const searchAllActionsPayload: ISeanceSearchActionsQuery = {
      pageSize: self.sizeChoice,
      pageNumber: self.actionsHolder.all.pageNumber,
      calcTotal: true,
      sortOrder: 0,
      contactId: self.userInfos.contactId,
    };
    const searchAllActions$ = self.api.searchSeancesActions(searchAllActionsPayload).pipe(
      tap((searchRes) => {
        searchRes.items.map((item) => {
          if (item.objet) {
            item.fullObjet = item.objet;
          }
          if (item.action && item.action.reponse) {
            item.action.fullreponse = item.action.reponse;
          }
        });
        self.actionsHolder.all.items = searchRes.items;
        self.actionsHolder.all.totalCount = searchRes.totalCount;
        self.actionsHolder.all.pageNumber = searchRes.pageNumber;
        self.actionsHolder.all.totalPages = searchRes.totalPages;
        self.actionTabs.find((x) => x.slug === 'all').count = searchRes.totalCount;
      })
    );
    arrOfObservables.push(searchAllActions$);
  }

  if (onlyType == null || onlyType == 'todo') {
    // todo actions
    const searchTodoActionsPayload: ISeanceSearchActionsQuery = {
      pageSize: self.sizeChoice,
      pageNumber: self.actionsHolder.todo.pageNumber,
      calcTotal: true,
      sortOrder: 0,
      contactId: self.userInfos.contactId,
      traite: false,
    };
    const searchTodoActions$ = self.api.searchSeancesActions(searchTodoActionsPayload).pipe(
      tap((searchRes) => {
        searchRes.items.map((item) => {
          if (item.objet) {
            item.fullObjet = item.objet;
          }
          if (item.action && item.action.reponse) {
            item.action.fullreponse = item.action.reponse;
          }
        });
        self.actionsHolder.todo.items = searchRes.items;
        self.actionsHolder.todo.totalCount = searchRes.totalCount;
        self.actionsHolder.todo.pageNumber = searchRes.pageNumber;
        self.actionsHolder.todo.totalPages = searchRes.totalPages;
        self.actionTabs.find((x) => x.slug === 'todo').count = searchRes.totalCount;
      })
    );
    arrOfObservables.push(searchTodoActions$);
  }

  if (onlyType == null || onlyType == 'finished') {
    // finished actions
    const searchFinishedActionsPayload: ISeanceSearchActionsQuery = {
      pageSize: self.sizeChoice,
      pageNumber: self.actionsHolder.finished.pageNumber,
      calcTotal: true,
      sortOrder: 0,
      contactId: self.userInfos.contactId,
      traite: true,
    };
    const searchFinishedActions$ = self.api.searchSeancesActions(searchFinishedActionsPayload).pipe(
      tap((searchRes) => {
        searchRes.items.map((item) => {
          if (item.objet) {
            item.fullObjet = item.objet;
          }
          if (item.action && item.action.reponse) {
            item.action.fullreponse = item.action.reponse;
          }
        });
        self.actionsHolder.finished.items = searchRes.items;
        self.actionsHolder.finished.totalCount = searchRes.totalCount;
        self.actionsHolder.finished.pageNumber = searchRes.pageNumber;
        self.actionsHolder.finished.totalPages = searchRes.totalPages;
        self.actionTabs.find((x) => x.slug === 'finished').count = searchRes.totalCount;
      })
    );
    arrOfObservables.push(searchFinishedActions$);
  }

  if (onlyType == null || onlyType == 'asked') {
    // asked actions
    const searchAskedActionsPayload: ISeanceSearchActionsQuery = {
      pageSize: self.sizeChoice,
      pageNumber: self.actionsHolder.asked.pageNumber,
      calcTotal: true,
      sortOrder: 0,
      transmissionUserId: self.userInfos.id,
      traite: false,
    };
    const searchAskedActions$ = self.api.searchSeancesActions(searchAskedActionsPayload).pipe(
      tap((searchRes) => {
        searchRes.items.map((item) => {
          if (item.objet) {
            item.fullObjet = item.objet;
          }
          if (item.action && item.action.reponse) {
            item.action.fullreponse = item.action.reponse;
          }
        });
        self.actionsHolder.asked.items = sortItemsByEcheanceDate(searchRes.items);
        self.actionsHolder.asked.totalCount = searchRes.totalCount;
        self.actionsHolder.asked.pageNumber = searchRes.pageNumber;
        self.actionsHolder.asked.totalPages = searchRes.totalPages;
        self.actionTabs.find((x) => x.slug === 'asked').count = searchRes.totalCount;
      })
    );
    arrOfObservables.push(searchAskedActions$);
  }
};

const sortItemsByEcheanceDate = (arr: ActionItem[]) => {
  arr.sort((a, b) => {
    const dateA = a.action?.echeance ? new Date(a.action.echeance)?.getTime() : null;
    const dateB = b.action?.echeance ? new Date(b.action.echeance)?.getTime() : null;
    if (a == null) return 1;
    if (b == null) return -1;
    if (dateB > dateA) {
      return 1;
    } else if (dateB < dateA) {
      return -1;
    } else {
      return 0;
    }
  });
  return arr;
};

export const fetchThingsRelatedToActions = (
  actionsResult: IPagedResult<ActionItem>[],
  self: ActionsContainerComponent
) => {
  const allActionsItems = actionsResult.map((x) => x.items);
  const flattenActions: IPointActionSearchResult[] = [].concat(...allActionsItems);

  // fetcher les users liée au actions
  const allNeededUsersIds = flattenActions
    .filter((x) => Boolean(x.action.transmissionUserId))
    .map((x) => x.action.transmissionUserId);
  if (!allNeededUsersIds?.length) return of([]);
  const obsUsers = self.icontactService.getUserWithDataAdmin({ UserIds: _.uniq(allNeededUsersIds) }).pipe(
    tap((usersInfosRes) => {
      self.contactInfosService.setUsersByIds(usersInfosRes);
    })
  );
  const ava = of(''); //of va simplement faire un observable de qqch qui n'en est pas

  // fetcher les points liée au actions

  return forkJoin([obsUsers, ava]);
};
