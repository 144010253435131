// Fonction pour ajouter un zéro devant les nombres inférieurs à 10
function padZero(num: number): string {
  return num < 10 ? '0' + num : num.toString();
}

// Fonction pour formater une date en utilisant le format spécifié
export function formatDate(date: Date | string, format: string = 'dd/MM/yyyy'): string {
  const d = new Date(date);

  const day = padZero(d.getDate());
  const month = padZero(d.getMonth() + 1); // Les mois commencent à 0
  const year = d.getFullYear();

  const hours = padZero(d.getHours());
  const minutes = padZero(d.getMinutes());
  const seconds = padZero(d.getSeconds());

  // Remplacer les parties du format par les valeurs correspondantes
  return format
    .replace('dd', day)
    .replace('MM', month)
    .replace('yyyy', year.toString())
    .replace('HH', hours)
    .replace('mm', minutes)
    .replace('ss', seconds);
}