import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { InfoByQrCode } from 'src/app/shared/models/scan';

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root',
})
export class ScanService {
  public issuer: string;
  public apiGatewayBaseUrl: string;

  constructor(private http: HttpClient) {
    const config = JSON.parse(sessionStorage.getItem('globalConfig'));
    this.issuer = config.issuer;
    this.apiGatewayBaseUrl = config.apiGatewayBaseUrl + '/api/v1';
  }

  public getInfoCourrierByIdQrCode(id: { courrierIds: number[] }): Observable<InfoByQrCode[]> {
    return this.http.post(`${this.apiGatewayBaseUrl}/Global/GetSceancesPointsFromQR`, id, { headers }).pipe(
      map((res: InfoByQrCode[]) => {
        return res;
      })
    );
  }
}
