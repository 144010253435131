import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { DataService } from '../services/data.service';
import { DialogService } from 'primeng/dynamicdialog';
import { GuardConfirmModalComponent } from 'src/app/shared/components/modals/guard-confirm-modal/guard-confirm-modal.component';
import { ApiService } from '../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { IScanService } from '@devinforius/super-compos';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDeactivateScanGuard implements CanDeactivate<any> {
  constructor(
    public dialogService: DialogService,
    public dataService: DataService,
    public api: ApiService,
    public translate: TranslateService,
    public iScanService: IScanService
  ) {}

  canDeactivate(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.dataService.checkIfUnsavedChanges === true) {
        const ref = this.dialogService.open(GuardConfirmModalComponent, {
          header: 'Confirmation',
          styleClass: 'super-compos-modal-content confirm-inforius',
          dismissableMask: false,
          width: '30em',
          data: {
            message: 'Etes-vous sûr de vouloir quitter? Les données lié au scan seront perdu.',
          },
        });
        ref.onClose.subscribe((value) => {
          if (value) {
            if (value === true) {
              this.dataService.checkIfUnsavedChanges = false;
              this.iScanService.close().subscribe();
              resolve(true);
              localStorage.removeItem('infoFile');
            } else {
              resolve(false);
            }
          }
        });
      } else {
        resolve(true);
      }
    });
  }
}
