<div class="signBody">
  <p-accordion [multiple]="true">
    <p-accordionTab class="accordionError" [(selected)]="openedAccordionError" [header]="numberPointsOfErrors + ' ' + ('error.errors' | translate)" *ngIf="numberPointsOfErrors >= 1">
      <span class="mb-0 red-text d-flex align-items-center">
        <div [innerHTML]="errorMessagePoint"></div>
      </span>
    </p-accordionTab>
    <p-accordionTab class="accordionSucces" [(selected)]="openedAccordionSuccess" [header]="numberPointsOfSuccess + ' ' + ('success.success' | translate)" *ngIf="successPointIds.length >= 1">
      <span class="mb-0 green-text d-flex align-items-center">
        <div [innerHTML]="successPointIdsArray"></div>
      </span>
    </p-accordionTab>
  </p-accordion>
  <div class="c mb-3 mt-3" *ngIf="config.data.massGeneration">
    <label class="super-compos-label-input">{{ 'generateDocWithModel' | translate }} :</label>
    <p-dropdown
      [virtualScroll]="true"
      itemSize="30"
      [(ngModel)]="modeleIdToGenerateInMass"
      (onChange)="getRightGeneration(); openedAccordionError = false; openedAccordionSuccess = false"
      [options]="modelesDocPoint"
      styleClass="w-100"
      [placeholder]="'selectModeles' | translate"
      appendTo="body"
      [showClear]="true"
      (onClear)="modeleIdToGenerateInMass = null; openedAccordionError = false; openedAccordionSuccess = false; numberPointsOfErrors = 0"
      [filter]="true"
      [readonly]="false"
      optionValue="id">
      <ng-template let-item pTemplate="item">
        <div class="d-flex align-items-center justify-content-between">
          <div class="truncateLabel">{{ item.description }}</div>
          <div><i class="fas fa-info-circle" [pTooltip]="item.description"></i></div>
        </div>
      </ng-template>
      <ng-template let-item pTemplate="selectedItem">
        <span>{{ item.description }}</span>
      </ng-template>
    </p-dropdown>
  </div>
  
  <div class="p-fluid p-grid p-formgrid ml-3 mt-3" *ngIf="modeleIdToGenerateInMass || !config.data.massGeneration">
    <div class="p-field">
      <div
        style="min-height: 40px; border: 1px solid #ff5671; background-color: #fff0f3; border-radius: 6px"
        *ngIf="errorMessageInsideCard"
        class="mr-3 mb-3 p-2">
        <span class="mb-0 red-text d-flex align-items-center">
          <i class="bi bi-exclamation-diamond mr-1"></i> 
          <div [innerHTML]="errorMessageInsideCard"></div>
        </span>
      </div>
      <label class="super-compos-label-input">{{'date' | translate}}</label>
      <div class="d-flex justify-content-start w-100">
        <p-calendar 
          inputId="date"
          dateFormat="dd/mm/yy"
          appendTo="body"
          [minDate]="minDate"
          [(ngModel)]="dateExtrait"
          [firstDayOfWeek]="1"
          [selectOtherMonths]="true"
          icon="bi bi-calendar"
          [showIcon]="true"
        ></p-calendar>
      </div>
    </div>
    <label for="signature" class="super-compos-label-input mt-3">{{'signatures' | translate}}</label>
    <ng-container *ngIf="!loadingPage && signatureArray">
      <div *ngFor="let item of signatureArray; let i = index" class="d-flex align-items-center mr-3 mb-3">
        <p-dropdown 
          [options]="signatures" 
          optionLabel="description" 
          optionValue="id" 
          [(ngModel)]="item.idSignature" 
          [ngClass]="{'error': errorIndices.has(i)}"
          (ngModelChange)="updateLabelSignature()"
          class="mr-3 dropdown-40"
          appendTo="body">
        </p-dropdown>
        <p-toggleButton 
          [onLabel]="'sidebar.contacts' | translate" 
          [offLabel]="'users' | translate"
          onIcon="bi bi-person" 
          offIcon="bi bi-person-lock" 
          [(ngModel)]="item.isContact" 
          (click)="setUserArray(i)" 
          class="toggle-15 custom-toggle-button"
        ></p-toggleButton>
          <p-dropdown 
          [options]="item.userOptions"
          optionLabel="fullName" 
          optionValue="id" 
          [(ngModel)]="item.idAdmin"
          class="mr-3 dropdown-40 custom-dropdown"
          appendTo="body">
          <ng-template let-item pTemplate="item">
            <span [pTooltip]="item.fullNameTooltip" tooltipPosition="top">{{ item.fullName }}</span>
          </ng-template>
        </p-dropdown>
        <button pButton pRipple type="button" icon="bi bi-dash-circle" class="p-button-rounded p-button-danger p-button-text button-5" (click)="editSignatureArray('remove', i)" [pTooltip]="'info.deleteLine' | translate" tooltipPosition="top"></button>    
      </div>
    </ng-container>
    <span *ngIf="loadingPage">
      <div class="skeleton" *ngFor="let skeleton of [1,2,3]">
        <p-skeleton class="skeleton__first" height="40px"></p-skeleton>
        <p-skeleton class="skeleton__second" height="40px"></p-skeleton>
        <p-skeleton shape="circle" size="1.2rem" class="skeleton__third"></p-skeleton>
      </div>
    </span>
    <button pButton pRipple type="button" icon="bi bi-plus-circle" class="p-button-rounded p-button-success p-button-text w-auto text-uppercase" [label]="'info.addSignature' | translate" (click)="editSignatureArray('add')"></button>
    <div class="dialog-footer">
      <super-button 
        styleClass="green" 
        text="{{ 'generateNew' | translate }}"
        [disabled]="isConfirmDisabled"
        (click)="confirmSignatures()">
      </super-button>
    </div>
  </div>
</div>