import { Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { IContactService } from './i-contact.service';
import { Contact } from 'src/app/shared/models/iContact/contact';
import { UserContact } from 'src/app/shared/models/iContact/user-contact';
import { DataService } from './data.service';

@Injectable()
export class ContactInfosService implements OnDestroy {
  contactsAdmin: Contact[];
  contacts: Contact[];
  usersInfos: UserContact[];
  iAdminContactsSub: Subscription;
  iAdminUsersSub: Subscription;
  iDelibContactsSub: Subscription;

  constructor(private dataService: DataService, private icontactService: IContactService) {
    this.iAdminContactsSub = this.icontactService.getLowContactsAdmin().subscribe((contactsAdmin) => {
      this.contactsAdmin = contactsAdmin;
    });
    this.iDelibContactsSub = this.dataService.getLowContacts.subscribe((contacts) => {
      this.contacts = contacts;
    });
  }

  setUsersByIds(usersInfos: UserContact[]) {
    this.usersInfos = usersInfos;
  }

  fetchUserInfosByContactId(obj: { contactIdiAdmin?: number; contactId?: number }): Contact | null {
    if (obj.contactIdiAdmin) {
      return this.contactsAdmin.find((x) => x.id === obj.contactIdiAdmin);
    } else if (obj.contactId) {
      return this.contacts.find((x) => x.id === obj.contactId);
    }
    return null;
  }

  fetchUserInfosByUserId(userId: number): UserContact | undefined {
    return this.usersInfos?.find((x) => x.id === userId);
  }

  ngOnDestroy(): void {
    this.iAdminContactsSub.unsubscribe();
    this.iDelibContactsSub.unsubscribe();
  }
}
