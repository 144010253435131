<section class="meetings-details" *ngIf="currentDisposition === 'side-by-side'">
  <as-split direction="horizontal" [dir]="'ltr'">
    <as-split-area [size]="35">
      <div class="mr-4">
        <super-title
          text="{{ meeting?.description }}"
          level="3"
          sub="{{ meeting?.seanceTypeName }}"
          [hasSubtitle]="true"
          icon="bi bi-arrow-left-circle-fill fa-xl tac-back-icon"
          (iconClick)="back($event)"
          [buttons]="buttons"></super-title>
        <div class="row">
          <div class="col-6">
            <super-button
              [text]="'infos' | translate"
              styleClass="blue w-100 mt-3 d-flex justify-content-center"
              icon="bi bi-info-circle-fill"
              iconPosition="left"
              (click)="showMeetingInfos()"></super-button>
          </div>
          <div class="col-6">
            <super-button
              icon="bi bi-plus-circle-fill"
              iconPosition="left"
              [text]="'btn.add' | translate"
              styleClass="green w-100 mt-3 d-flex justify-content-between"
              [children]="childrensOfAddButton"></super-button>
          </div>
        </div>
        <div class="d-flex align-items-center mt-4 mb-4">
          <span class="p-input-icon-left w-100 mr-3">
            <i class="pi pi-search"></i>
            <input
              type="text"
              styleClass="inputItalicSearchPoint"
              class="input-rounded w-100 text-center"
              pInputText
              [(ngModel)]="titleSearch"
              [placeholder]="'searchPoint' | translate" />
          </span>
          <p-selectButton
            (onChange)="changeJustifyOptions()"
            class="displayByMediaQueries"
            [options]="justifyOptions"
            optionLabel="icon"
            optionValue="value"
            [(ngModel)]="currentDisposition">
            <ng-template let-item>
              <i [class]="item.icon"></i>
            </ng-template>
          </p-selectButton>
        </div>
        <list-element
          (emittedPointValue)="receivePointValue($event)"
          (reloadList)="reload($event)"
          [items]="points | searchByKey: 'title' : titleSearch"
          *ngIf="points.length > 0"></list-element>
        <div *ngIf="points.length === 0" class="text-center mt-5">
          <i class="bi bi-cup-hot-fill mt-5" style="color: #24344b; opacity: 0.2; font-size: 40px"></i>
          <h3 class="text-center">Aucun point</h3>
          <p class="text-muted">Aucun point n'est ajouté à cette séance</p>
        </div>
      </div>
    </as-split-area>
    <as-split-area [size]="65">
      <div class="ml-4" *ngIf="points.length > 0">
        <span *ngIf="displayFichePoint">
          <app-fiche-point [pointId]="pointId" (onChangePoint)="reload(true)"></app-fiche-point>
        </span>
        <span *ngIf="displayRTFEditor">
          <app-seance-RTF [meetingId]="meeting.id" [typeRTF]="titleIntroConclusionType"></app-seance-RTF>
        </span>
      </div>
      <div class="ml-4" *ngIf="displayInfos">
        <div>
          <app-infos-meeting
            [idSent]="meeting.id"
            (emitterChangeDescription)="changedDescriptionOfMeeting($event)"></app-infos-meeting>
        </div>
      </div>
      <div class="ml-4 mt-4 h-100" *ngIf="!somethingIsSelected">
        <section class="pt-3 container-right d-flex flex-column justify-content-center align-items-center">
          <img src="./assets/img/selectPoint.gif" style="height: 200px; width: auto" />
          <h3>Sélectionnez un point</h3>
          <p class="text-muted">
            Sélectionnez un point dans la liste présente sur la gauche de l'écran afin d'en afficher le contenu
          </p>
        </section>
      </div>
    </as-split-area>
  </as-split>
</section>

<section class="meetings-details" *ngIf="currentDisposition === 'table'">
  <div class="fiche-point-header">
    <super-title
      *ngIf="!isReordering"
      text="{{ meeting?.description }}"
      level="1"
      sub="{{ meeting?.seanceTypeName }}"
      icon="bi bi-arrow-left-circle-fill fa-xl tac-back-icon"
      (iconClick)="back($event)"
      [hasSubtitle]="true"
      [buttons]="buttonsTitleTableView"></super-title>

    <p-overlayPanel #overlayButton id="overlayButton">
      <ng-template pTemplate>
        <p *ngIf="documentsOverlayItems.length > 0" class="dropdown-tooltip">
          <span class="fontStyleForSemiTitleOfOverlay">{{ 'clickondocument' | translate }}</span>
        </p>
        <div *ngFor="let item of documentsOverlayItems">
          <a class="dropdown-item">
            <div class="d-flex align-items-center">
              <i
                *ngIf="item.rights.canDownloadPdf === true"
                (click)="downloadModelDoc(item)"
                class="fas fa-download blue-icon mr-2"
                pTooltip="Télécharger le document"
                tooltipPosition="bottom"></i>
              <i
                (click)="openModelDoc(item)"
                class="fas fa-eye blue-icon"
                pTooltip="Prévisualiser le document en plein écran"
                tooltipPosition="bottom"
                *ngIf="item?.isAlreadyGeneratedAndSaved === true || item?.lastFinishedGenerationRequest"></i>
              <div (click)="openModelDoc(item)" class="ml-3">
                <span class="dropdown-item-title">
                  {{ item?.description }}
                </span>
                <span class="dropdown-item-info" *ngIf="!item?.isAlreadyGeneratedAndSaved">
                  {{
                    item?.lastFinishedGenerationRequest
                      ? 'Aperçu du ' +
                        (item?.lastFinishedGenerationRequest?.lastRequestDateTime | momentFormat: 'DD/MM/YYYY à HH:mm')
                      : "Pas encore d'aperçu"
                  }}
                </span>
                <span class="dropdown-item-info" *ngIf="item?.isAlreadyGeneratedAndSaved">
                  Généré
                  {{
                    item?.generatedAndSavedAtDateTime
                      ? 'le ' + (item?.generatedAndSavedAtDateTime | momentFormat: 'DD/MM/YYYY à HH:mm')
                      : ''
                  }}
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <p-tag
                styleClass="ml-3 mr-3"
                *ngIf="item?.myLastGenerationRequest && item?.myLastGenerationRequest?.statutId === 1"
                severity="info"
                value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
                [rounded]="true"></p-tag>
              <p-tag
                styleClass="ml-3 mr-3"
                *ngIf="item?.myLastGenerationRequest && item?.myLastGenerationRequest?.statutId === 2"
                severity="warning"
                value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
                [rounded]="true"></p-tag>
              <p-tag
                styleClass="ml-3 mr-3"
                *ngIf="item?.myLastGenerationRequest && item?.myLastGenerationRequest?.statutId === 3"
                severity="success"
                value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
                [rounded]="true"></p-tag>
              <p-tag
                styleClass="ml-3 mr-3"
                *ngIf="
                  item?.myLastGenerationRequest &&
                  item?.myLastGenerationRequest?.statutId !== 1 &&
                  item?.myLastGenerationRequest?.statutId !== 2 &&
                  item?.myLastGenerationRequest?.statutId !== 3
                "
                severity="danger"
                value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
                [rounded]="true"></p-tag>

              <img
                src="./assets/img/preview-eye.svg"
                width="26"
                height="26"
                class="img-fluid {{ disabledLink ? 'disabled' : '' }}"
                pTooltip="Créer un document pour avoir un aperçu en PDF"
                tooltipPosition="left"
                *ngIf="item?.isAlreadyGeneratedAndSaved === false || item?.rights?.canSubmitModelePreviewRequest"
                (click)="preview(item)" />

              <img
                src="./assets/img/preview-checked.svg"
                width="26"
                height="26"
                class="img-fluid"
                pTooltip="Générer le document"
                tooltipPosition="left"
                *ngIf="item?.isAlreadyGeneratedAndSaved === false && item?.rights?.canGenerateModele"
                (click)="openGenerateAndPatch(item)" />

              <super-button
                pButton
                icon="far fa-ban"
                *ngIf="item?.rights?.canCancelModelePreviewRequest"
                pTooltip="{{ 'cancelPreview' | translate }}"
                tooltipPosition="left"
                (click)="cancelPreview(item)"
                class="button-rounded-custom mr-1"></super-button>

              <super-button
                pButton
                icon="fas fa-edit"
                (click)="editDocument(item)"
                pTooltip="{{'modifyGenerated' | translate}}"
                tooltipPosition="left"
                *ngIf="item?.isAlreadyGeneratedAndSaved"
                class="button-rounded-custom"></super-button>
            </div>
          </a>
        </div>
        <div class="dropdown-other-link">
          <!-- Avoir le droit gestionnaire sur la séance -->
          <span
            (click)="generateModeleDoc(); getModelesDocuments()"
            *ngIf="rightsOfSeance?.menuDocuments"
            class="dropdown-link-span d-block mt-3 mb-2"
            [ngClass]="{
              'setBorderTop mt-3': documentsOverlayItems.length > 0,
              'mt-1': documentsOverlayItems.length === 0
            }"
            >Générer un document</span
          >
          <span (click)="getModelesDocuments(); resetOverlay()" class="dropdown-link-span d-block"
            >Actualiser les documents</span
          >
        </div>
      </ng-template>
    </p-overlayPanel>

    <!-- * Dropdown menu for idelibe mandataire export -->
    <div class="dropdown-menu" [ngClass]="{ 'd-block': showExportOverlay }" data-bs-auto-close="outside">
      <a class="dropdown-item" data-bs-auto-close="outside">
        <div class="d-flex align-items-center">
          <img class="mr-2" src="./assets/img/iDélibéMandataires.svg" style="width: 40px; height: 40px" />
          <div class class="ml-3">
            <span class="dropdown-item-title"> iDélibé Mandataires </span>
            <span class="dropdown-item-info-bold">
              {{
                exportItems?.lastExport?.exportDateTime
                  ? (exportItems?.lastExport?.exportDateTime | momentFormat: 'DD/MM/YYYY à HH:mm')
                  : "Pas encore d'export"
              }}
            </span>
            <div class="dropdown-item-info">
              {{ exportItems?.lastExport?.userId ? exportItems?.lastExport?.userName : '' }}
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="exportItems?.lastExport?.statutId === 1 || exportItems?.lastExport?.statutId === 2"
            severity="info"
            value="{{ statusExportMandataire | label_by_id: exportItems?.lastExport?.statutId | translate }}"
            [rounded]="true"></p-tag>
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="exportItems?.lastExport?.statutId === 3"
            severity="success"
            value="{{ statusExportMandataire | label_by_id: exportItems?.lastExport?.statutId | translate }}"
            [rounded]="true"></p-tag>
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="exportItems?.lastExport?.statutId === 4"
            severity="warning"
            value="{{ statusExportMandataire | label_by_id: exportItems?.lastExport?.statutId | translate }}"
            [rounded]="true"></p-tag>
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="exportItems?.lastExport?.statutId === 5"
            severity="danger"
            value="{{ statusExportMandataire | label_by_id: exportItems?.lastExport?.statutId | translate }}"
            [rounded]="true"></p-tag>
        </div>
      </a>
      <div class="dropdown-other-link">
        <span (click)="displayModalExportMandataireConfirm = true" class="dropdown-link-span d-block"
          >Exporter maintenant</span
        >
        <span (click)="getExportHistoric()" class="dropdown-link-span d-block">Voir l'historique des exportations</span>
      </div>
    </div>

    <div class="dropdown-menu" [ngClass]="{ 'd-block': showExportOverlayCitoyen }" data-bs-auto-close="outside">
      <a class="dropdown-item" data-bs-auto-close="outside">
        <div class="d-flex align-items-center">
          <img class="mr-2" src="./assets/img/iDélibéMandataires.svg" style="width: 40px; height: 40px" />
          <div class class="ml-3">
            <span class="dropdown-item-title"> iDélibé Citoyen </span>
            <span class="dropdown-item-info-bold">
              {{
                exportItemsCitoyen?.lastExport?.exportDateTime
                  ? (exportItemsCitoyen?.lastExport?.exportDateTime | momentFormat: 'DD/MM/YYYY à HH:mm')
                  : "Pas encore d'export"
              }}
            </span>
            <div class="dropdown-item-info">
              {{ exportItemsCitoyen?.lastExport?.userId ? exportItemsCitoyen?.lastExport?.userName : '' }}
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="exportItemsCitoyen?.lastExport?.statutId === 1 || exportItemsCitoyen?.lastExport?.statutId === 2"
            severity="info"
            value="{{ statusExportMandataire | label_by_id: exportItemsCitoyen?.lastExport?.statutId | translate }}"
            [rounded]="true"></p-tag>
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="exportItemsCitoyen?.lastExport?.statutId === 3"
            severity="success"
            value="{{ statusExportMandataire | label_by_id: exportItemsCitoyen?.lastExport?.statutId | translate }}"
            [rounded]="true"></p-tag>
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="exportItemsCitoyen?.lastExport?.statutId === 4"
            severity="warning"
            value="{{ statusExportMandataire | label_by_id: exportItemsCitoyen?.lastExport?.statutId | translate }}"
            [rounded]="true"></p-tag>
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="exportItemsCitoyen?.lastExport?.statutId === 5"
            severity="danger"
            value="{{ statusExportMandataire | label_by_id: exportItemsCitoyen?.lastExport?.statutId | translate }}"
            [rounded]="true"></p-tag>
        </div>
      </a>
      <div class="dropdown-other-link">
        <span (click)="exportNowCitoyen()" class="dropdown-link-span d-block">Exporter maintenant</span>
        <span (click)="getExportHistoricCitoyen(); showExportOverlayCitoyen = false" class="dropdown-link-span d-block"
          >Voir l'historique des exportations</span
        >
      </div>
    </div>

    <div class="mt-4 mb-4">
      <p-selectButton
        (onChange)="changeJustifyOptions()"
        *ngIf="!isReordering"
        class="displayByMediaQueries"
        [options]="justifyOptions"
        optionLabel="icon"
        optionValue="value"
        [(ngModel)]="currentDisposition">
        <ng-template let-item>
          <i [class]="item.icon"></i>
        </ng-template>
      </p-selectButton>
    </div>
    <div
      class="tac-filter-alert text-center p-2 my-3 mx-3"
      *ngIf="meeting?.verrouille === false && amountOfEventsForMeetingNonPreparee > 0">
      <i class="bi bi-bell-fill mr-2 text-muted"></i>
      <span class="text-muted" *ngIf="amountOfEventsForMeetingNonPreparee === 1"
        >{{ amountOfEventsForMeetingNonPreparee }} évènement présent dans la séance. Modifiez le statut de celle-ci en
        “Préparée” afin de pouvoir les visualiser.</span
      >
      <span class="text-muted" *ngIf="amountOfEventsForMeetingNonPreparee > 1"
        >{{ amountOfEventsForMeetingNonPreparee }} évènements présents dans la séance. Modifiez le statut de celle-ci en
        “Préparée” afin de pouvoir les visualiser.</span
      >
    </div>
    <super-tabs-xl [tabs]="itemsTab" (activeTab)="changeTabView($event)" [activeIndex]="activeIndex"></super-tabs-xl>

    <div class="fiche-point-content-info" *ngIf="activeTab === 'pointsInMeetings'">
      <super-table-tree
        #SuperTreeTable
        *ngIf="nombreNiveauxTree >= 1"
        [ngClass]="{ 'size-on-reorder': isReordering }"
        [defaultExpanded]="true"
        [hideDeleteSelection]="true"
        [reorder]="isReordering"
        (onReorderRow)="onReorderRow($event)"
        [forselection]="forSelectionPointsTree"
        [forselectionDefaultOption]="true"
        [items]="pointsTreeWithEvents?.datas"
        [columns]="columnsTree"
        (onClickRow)="openPoint($event, 'linked')"
        id="super-points-linked-table-trees-1"
        [height]="'calc( 100vh - 300px)'"
        [multiselect]="true"
        [menu]="menuPoints"
        [paginator]="false"
        (menuItemsRowClick)="receive_menu($event)"
        class="super-table-tree">
        <div class="d-flex">
          <super-button
            id="buttonsTitleTableViewReorganize"
            [styleClass]="'blue ml-3 no-shadow mr-3'"
            [text]="'Réorganiser'"
            [icon]="'icon-reorder'"
            [iconPosition]="'right'"
            [children]="reorganizeChildren"></super-button>

          <p-selectButton
            (onChange)="changeJustifyOptions()"
            class="displayByMediaQueries"
            [options]="justifyOptions"
            optionLabel="icon"
            optionValue="value"
            [(ngModel)]="currentDisposition">
            <ng-template let-item>
              <i [class]="item.icon"></i>
            </ng-template>
          </p-selectButton>
        </div>
      </super-table-tree>
      <!-- Si on a pas de niveau, on affiche que un tableau simple -->

      <super-table
        *ngIf="nombreNiveauxTree === 0 && points.length > 0"
        [autoLayout]="true"
        [ngClass]="{ 'size-on-reorder': isReordering }"
        #SuperTablePointLinked
        [hideDeleteSelection]="true"
        [reorder]="isReordering"
        (onReorderRow)="onReorderRow($event)"
        [forselection]="forSelectionPoints"
        [forselectionDefaultOption]="true"
        [items]="points"
        [columns]="columns"
        (onClickRow)="openPoint($event, 'linked')"
        id="super-points-linked-table-normal-1"
        [height]="'calc(100vh - 200px)'"
        [multiselect]="true"
        [paginator]="false"
        [menu]="menuPoints"
        (menuItemsRowClick)="receive_menu($event)">
        <div class="d-flex">
          <p-checkbox
            name="groupname"
            value="val1"
            [(ngModel)]="filterTable"
            binary="true"
            (ngModelChange)="onFilterTableChange()"
            label="Afficher Titre, Intro & Conclusion"
            class="mr-4"></p-checkbox>

          <!-- BUTTON REORGANISER -->

          <super-button
            id="buttonsTitleTableViewReorganize"
            [styleClass]="'blue ml-3 no-shadow mr-3'"
            [text]="'Réorganiser'"
            [icon]="'icon-reorder'"
            [iconPosition]="'right'"
            [children]="reorganizeChildren"></super-button>

          <!-- SELECTEUR DE VUE -->
          <p-selectButton
            (onChange)="changeJustifyOptions()"
            *ngIf="!isReordering"
            class="displayByMediaQueries"
            [options]="justifyOptions"
            optionLabel="icon"
            optionValue="value"
            [(ngModel)]="currentDisposition">
            <ng-template let-item>
              <i [class]="item.icon"></i>
            </ng-template>
          </p-selectButton>
          <!-- FIN SELECTEUR DE VUE -->
        </div>
      </super-table>

      <div class="h-100 d-flex flex-column align-items-center justify-content-center" *ngIf="points.length === 0">
        <div class="mt-5" align="center">
          <p-selectButton
            (onChange)="changeJustifyOptions()"
            *ngIf="!isReordering"
            class="displayByMediaQueries mt-5"
            [options]="justifyOptions"
            optionLabel="icon"
            optionValue="value"
            [(ngModel)]="currentDisposition">
            <ng-template let-item>
              <i [class]="item.icon"></i>
            </ng-template> </p-selectButton
          ><br /><br />

          <i class="bi bi-cup-hot-fill mt-5" style="color: #24344b; opacity: 0.2; font-size: 40px"></i>
          <h3 class="text-center">Aucun point</h3>
          <p class="text-muted">Aucun point n'est ajouté à cette séance</p>
        </div>
      </div>

      <div class="mb-3"></div>
    </div>
    <div class="fiche-point-content-info" *ngIf="activeTab === 'plannedPointsInMeetings'">
      <super-table
        #superTablePointsNot
        [forselection]="forSelectionPointsNotLinked"
        [forselectionDefaultOption]="true"
        [hideDeleteSelection]="true"
        (columnSelected)="receive_selected($event)"
        [items]="notLinkedPoints"
        [columns]="columnsPointsNotLinked"
        (onClickRow)="openPoint($event, 'notLinked')"
        [height]="'calc( 100vh - 250px)'"
        [multiselect]="true"
        [menu]="menuPointsNotLinked"
        [paginator]="false"
        (menuItemsRowClick)="receive_menuNotLinked($event)"
        id="super-points-planned-table-1"
        [novalue]="noValuePointsPlannedInMeeting"
        ></super-table>
    </div>
    <div class="fiche-point-content-info" *ngIf="activeTab === 'files' && rightsOfSeance.edit">
      <app-files
        [hasLoaded]="hasLoaded"
        [rights]="rightsOfSeance"
        [files]="files"
        [pointId]="pointId"
        [meeting]="meeting"
        (emitSuccessUpload)="getFiles()"></app-files>
    </div>
    <div class="fiche-point-content-info" *ngIf="activeTab === 'historic' && rightsOfSeance.edit">
      <super-table
        #superTableMeetingHistoric
        [forselectionDefaultOption]="false"
        [multiselect]="false"
        [items]="meetingHistoric"
        [columns]="columnsHistoric"
        id="super-table-meeting-historic"
        [height]="'calc( 100vh - 300px)'"
        [novalue]="noValueHistoricForMeeting"></super-table>
    </div>
    <div class="fiche-point-content-info" *ngIf="activeTab === 'recipients' && rightsOfSeance.edit">
      <destinataire
        [rights]="rightsOfSeance"
        [items]="destinatairesItems"
        [meeting]="meeting"
        (refreshData)="receive_refresh($event)"></destinataire>
    </div>
    <div class="fiche-point-content-info" *ngIf="activeTab === 'infos'">
      <app-infos-meeting [displayingInTab]="true"></app-infos-meeting>
    </div>
  </div>
</section>

<p-confirmDialog
  #cd
  appendTo="body"
  styleClass="super-compos-modal-content confirmModal"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  key="detailsMeetingModal">
  <p-footer>
    <super-button type="button" pButton icon="pi pi-times" label="{{ 'No' | translate }}" (click)="cd.reject()"></super-button>
    <super-button class="pButtonRed" type="button" pButton icon="pi pi-check" label="{{ 'Yes' | translate }}" (click)="cd.accept()"></super-button>
  </p-footer>
</p-confirmDialog>

<p-dialog
  styleClass="super-compos-modal-content"
  [(visible)]="showRegenerateModal"
  [modal]="true"
  [style]="{ width: '30vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">Générer le document</h5>
      <super-button styleClass="red" (click)="showRegenerateModal = false" text="Annuler"></super-button>
    </div>
  </p-header>
  <section [formGroup]="form">
    <label class="super-compos-label-input">Type de document</label>
    <p-dropdown
      [virtualScroll]="true"
      itemSize="30"
      formControlName="modeleId"
      (onChange)="checkIfAlreadyInDocumentListPoint($event); setActualDocument($event)"
      [options]="modelesLV"
      styleClass="w-100"
      [placeholder]="'selectModeles' | translate"
      appendTo="body"
      [filter]="true"
      [readonly]="dropdownDisabled">
      <ng-template let-item pTemplate="item">
        <div class="d-flex align-items-center justify-content-between">
          <div class="truncateLabel">{{ item.label }}</div>
          <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
        </div>
      </ng-template>
    </p-dropdown>
    <section class="separator-border mt-4" *ngIf="documentAlreadyInList === false">
      <div class="mt-3 mb-4 text-center d-flex align-items-center justify-content-center flex-column">
        <p-checkbox
          *ngIf="form.value.modeleId"
          [label]="'updateDocumentBeforeGenerate' | translate"
          binary="true"
          formControlName="updateBefore"></p-checkbox>
        <super-button
          styleClass="green mt-4"
          [disabled]="!form.value.modeleId"
          text="{{ 'generateNew' | translate }}"
          (click)="generateDoc()"></super-button>
      </div>
    </section>
    <section class="separator-border mt-4" *ngIf="documentAlreadyInList">
      <b class="mt-2">Êtes-vous sûr?</b>
      <p>
        Si vous regénérez le document, les modifications que vous avez apportées directement au document seront perdues.
      </p>
      <div>
        <p-radioButton formControlName="regenerateMode" value="replace" inputId="regen"></p-radioButton>
        <label class="mb-0 ml-2" for="regen"><b>Regénérer et remplacer</b></label>
      </div>
      <div class="ml-4 mt-2 mb-2" *ngIf="form.value.regenerateMode === 'replace'">
        <p-checkbox
          [label]="'updateDocumentBeforeGenerate' | translate"
          binary="true"
          formControlName="updateBefore"></p-checkbox>
      </div>
      <div *ngIf="actualDocumentInModal?.isAlreadyGeneratedAndSaved">
        <p-radioButton formControlName="regenerateMode" value="update" inputId="update"></p-radioButton>
        <label class="mb-0 ml-2" for="update"><b>Modifier</b></label>
      </div>
      <div class="mt-3 mb-4 text-center d-flex align-items-center justify-content-center flex-column">
        <super-button
          styleClass="green mt-4"
          text="{{ 'generateNew' | translate }}"
          (click)="generateDoc()"></super-button>
      </div>
    </section>
  </section>
</p-dialog>
<p-dialog
  [(visible)]="displayModalExportMandataireConfirm"
  styleClass="super-compos-modal-content"
  [modal]="true"
  [style]="{ width: '30vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">Exporter la séance vers iDélibé Mandataires</h5>
      <div class="d-flex">
        <super-button styleClass="green mr-2" (click)="exportMandataireNow()" text="Exporter"></super-button>
        <super-button
          styleClass="red"
          (click)="displayModalExportMandataireConfirm = false"
          text="Annuler"></super-button>
      </div>
    </div>
  </p-header>
  <section [formGroup]="formExportMandataire">
    <div class="row mt-2 mb-2">
      <div class="col-12">
        <div>
          <i class="fas fa-exclamation-triangle orange-icon"></i> Rappel : N'oubliez pas de donner aux mandataires
          l'accès à cette séance sur Délibé Web
        </div>
        <div class="mt-1"><a href="https://edelibe.myinforius.be">https://edelibe.myinforius.be</a></div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-1 mb-1">
        <label class="super-compos-label-input">Commentaire associé à ce nouvel export</label>
        <textarea class="w-100" pInputTextarea formControlName="commentaires"></textarea>
      </div>
    </div>
  </section>
</p-dialog>
<p-dialog
  [(visible)]="displayModalExportCitoyenConfirm"
  styleClass="super-compos-modal-content"
  [modal]="true"
  [style]="{ width: '30vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">Exporter la séance vers iDélibé Citoyen</h5>
      <div class="d-flex">
        <super-button styleClass="green mr-2" (click)="exportCitoyenNow()" text="Exporter"></super-button>
        <super-button styleClass="red" (click)="displayModalExportCitoyenConfirm = false" text="Annuler"></super-button>
      </div>
    </div>
  </p-header>
  <section [formGroup]="formExportCitoyen">
    <div class="row mt-2 mb-2">
      <div class="col-12">
        <div class="mt-1"><a href="https://conseilcommunal.be/">https://conseilcommunal.be/</a></div>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-12 mt-1 mb-1">
        <label class="super-compos-label-input">Commentaire associé à ce nouvel export</label>
        <textarea class="w-100" pInputTextarea formControlName="commentaires"></textarea>
      </div> -->
      <div class="col-12 mt-1 mb-1">
        <label class="super-compos-label-input">Liste des points pour export</label>
        <!-- <p-multiSelect appendTo="body" [options]="pointsLV | sort : 'label' : 'ASC'" defaultLabel="Sélectionnez un ou plusieurs points" optionLabel="label" styleClass="w-100" formControlName="points"
          [required]="true" [virtualScroll]="true" itemSize="30">
          <ng-template let-title pTemplate="item">
            <div pTooltip="{{ title?.label }}" tooltipPosition="right" class="truncateLabel">{{ title.label }}</div>
          </ng-template>
        </p-multiSelect> -->
        <super-table
          [items]="listOfPointsCitoyenExportFiltered"
          [columns]="columnsExportCitoyenPoints"
          id="super-table-points-export-delibe-mandataire"
          [height]="'calc( 100vh - 300px)'"
          [multiselect]="false"></super-table>
      </div>
    </div>
  </section>
</p-dialog>
<p-dialog
  [(visible)]="displayModalExportMandataireHistoric"
  styleClass="super-compos-modal-content"
  [modal]="true"
  [style]="{ width: '30vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">Historique des exports vers iDélibé Mandataire</h5>
      <div class="d-flex">
        <super-button
          styleClass="red"
          (click)="displayModalExportMandataireHistoric = false"
          text="Fermer"></super-button>
      </div>
    </div>
  </p-header>
  <section>
    <super-table
      [items]="exportItemsHistoric"
      [columns]="columnsHistoricExportDelibeMandataire"
      id="super-table-historic-export-delibe-mandataire"
      [height]="'calc( 100vh - 300px)'"></super-table>
  </section>
</p-dialog>

<p-dialog
  [(visible)]="displayModalExportCitoyenHistoric"
  styleClass="super-compos-modal-content"
  [modal]="true"
  [style]="{ width: '30vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">Historique des exports vers iDélibé Citoyen</h5>
      <div class="d-flex">
        <super-button styleClass="red" (click)="displayModalExportCitoyenHistoric = false" text="Fermer"></super-button>
      </div>
    </div>
  </p-header>
  <section>
    <super-table
      [items]="exportItemsHistoricCitoyen"
      [columns]="columnsHistoricExportDelibeMandataire"
      id="super-table-historic-export-delibe-mandataire"
      [height]="'calc( 100vh - 300px)'"></super-table>
  </section>
</p-dialog>

<p-sidebar [(visible)]="modalPdfViewer" [fullScreen]="true">
  <section>
    <ngx-extended-pdf-viewer
      *ngIf="blobViewer"
      [src]="blobViewer"
      height="100vh"
      [useBrowserLocale]="true"
      [zoom]="'auto'"
      [showFindButton]="true"
      [showPagingButtons]="true"
      [showZoomButtons]="true"
      [showPresentationModeButton]="true"
      [showOpenFileButton]="false"
      [showPrintButton]="false"
      [showDownloadButton]="false"
      [showBookmarkButton]="false"
      [showSecondaryToolbarButton]="true"
      [showRotateButton]="true"
      [showHandToolButton]="true"
      [showScrollingButton]="true"
      [showSpreadButton]="false"
      [showPropertiesButton]="true"
      [customToolbar]="additionalButtons"
      [(page)]="currentPage">
    </ngx-extended-pdf-viewer>
    <ng-template #additionalButtons>
      <div id="toolbarViewer">
        <div id="toolbarViewerLeft">
          <pdf-toggle-sidebar></pdf-toggle-sidebar>
          <div class="toolbarButtonSpacer"></div>
          <pdf-find-button [showFindButton]="true" [textLayer]="true"></pdf-find-button>
          <pdf-paging-area></pdf-paging-area>
        </div>
        <div id="toolbarViewerRight">
          <pdf-presentation-mode></pdf-presentation-mode>
          <pdf-select-tool></pdf-select-tool>
          <pdf-rotate-page></pdf-rotate-page>
          <pdf-hand-tool></pdf-hand-tool>
          <pdf-bookmark></pdf-bookmark>
          <pdf-toggle-secondary-toolbar></pdf-toggle-secondary-toolbar>
        </div>
        <pdf-zoom-toolbar></pdf-zoom-toolbar>
      </div>
    </ng-template>
  </section>
</p-sidebar>

<app-actions-point
  *ngIf="displayActionsModal"
  [point]="menuEvents"
  (action)="handleClickAction($event)"
  >
</app-actions-point>

<p-dialog
  [(visible)]="displayDocumentsModal"
  modal="true"
  [closable]="true"
  [showHeader]="false"
  [resizable]="false"
  [style]="{ width: '40vw', minWidth: '40vw', maxWidth: '40vw' }">
  <div class="d-flex justify-content-between align-items-center">
    <p class="actions__title mb-0">Documents</p>
    <i class="bi bi-x-circle" (click)="displayDocumentsModal = false" style="font-size: 20px; cursor: pointer"></i>
  </div>
  <ng-template pTemplate>
    <p *ngIf="documentsOverlayItems.length > 0" class="text-muted mb-4">
      <i class="bi bi-info-circle-fill mr-2"></i>{{ 'clickondocument' | translate }}
    </p>
    <div *ngFor="let item of documentsOverlayItems" class="mt-1 border-bottom">
      <a class="dropdown-item my-2">
        <div class="d-flex align-items-center">
          <i
            *ngIf="item.rights.canDownloadPdf === true"
            (click)="downloadModelDoc(item)"
            class="bi bi-download blue-icon biggerIcon mr-2"
            pTooltip="Télécharger le document"
            tooltipPosition="bottom"></i>
          <i
            (click)="openModelDoc(item)"
            class="bi bi-eye-fill blue-icon biggerIcon"
            pTooltip="Prévisualiser le document en plein écran"
            tooltipPosition="bottom"
            *ngIf="item?.isAlreadyGeneratedAndSaved === true || item?.lastFinishedGenerationRequest"></i>
          <div (click)="openModelDoc(item)" class="ml-3">
            <p class="docTitle mb-0">
              {{ item?.description }}
            </p>
            <p class="dropdown-item-info docInfo mb-0" *ngIf="!item?.isAlreadyGeneratedAndSaved">
              {{
                item?.lastFinishedGenerationRequest
                  ? 'Aperçu du ' +
                    (item?.lastFinishedGenerationRequest?.lastRequestDateTime | momentFormat: 'DD/MM/YYYY à HH:mm')
                  : "Pas encore d'aperçu"
              }}
            </p>
            <p class="dropdown-item-info mb-0" *ngIf="item?.isAlreadyGeneratedAndSaved">
              Généré
              {{
                item?.generatedAndSavedAtDateTime
                  ? 'le ' + (item?.generatedAndSavedAtDateTime | momentFormat: 'DD/MM/YYYY à HH:mm')
                  : ''
              }}
            </p>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="item?.myLastGenerationRequest?.statutId === 1"
            severity="info"
            value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
            [rounded]="true"></p-tag>
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="item?.myLastGenerationRequest?.statutId === 2"
            severity="warning"
            value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
            [rounded]="true"></p-tag>
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="item?.myLastGenerationRequest?.statutId === 3"
            severity="success"
            value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
            [rounded]="true"></p-tag>
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="
              item?.myLastGenerationRequest !== null &&
              item?.myLastGenerationRequest?.statutId !== 1 &&
              item?.myLastGenerationRequest?.statutId !== 2 &&
              item?.myLastGenerationRequest?.statutId !== 3
            "
            severity="danger"
            value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
            [rounded]="true"></p-tag>

          <span
            class="documentsActionBtn"
            *ngIf="item?.isAlreadyGeneratedAndSaved === false || item?.rights?.canSubmitModelePreviewRequest"
            (click)="preview(item)"
            pTooltip="Créer un document pour avoir un aperçu en PDF"
            tooltipPosition="left"
            >APERÇU</span
          >

          <span
            class="documentsActionBtn ml-3"
            *ngIf="item?.isAlreadyGeneratedAndSaved === false && item?.rights?.canGenerateModele"
            (click)="openGenerateAndPatch(item)"
            pTooltip="Générer"
            tooltipPosition="left"
            >Générer</span
          >

          <!-- <img src="./assets/img/preview-eye.svg" width="26" height="26"
            class="img-fluid {{disabledLink ? 'disabled' : ''}}"
            pTooltip="Créer un document pour avoir un aperçu en PDF" tooltipPosition="left"
            *ngIf="item?.isAlreadyGeneratedAndSaved == false || item?.rights?.canSubmitModelePreviewRequest"
            (click)="preview(item)"> -->

          <!-- <img src="./assets/img/preview-checked.svg" width="26" height="26" class="img-fluid"
            pTooltip="Générer le document" tooltipPosition="left"
            *ngIf="item?.isAlreadyGeneratedAndSaved == false && item?.rights?.canGenerateModele"
            (click)="openGenerateAndPatch(item)"> -->

          <span
            class="documentsActionBtnCancel ml-3"
            *ngIf="item?.rights?.canCancelModelePreviewRequest"
            pTooltip="Annuler la prévisualisation"
            tooltipPosition="left"
            (click)="cancelPreview(item)"
            tooltipPosition="left"
            >Annuler</span
          >

          <span
            class="documentsActionBtnEdit ml-3"
            (click)="editDocument(item)"
            pTooltip="Modifier le document"
            tooltipPosition="left"
            *ngIf="item?.isAlreadyGeneratedAndSaved"
            >Modifier</span
          >
        </div>
      </a>
    </div>
    <div class="d-flex justify-content-between mt-4">
      <!-- Avoir le droit gestionnaire sur la séance -->
      <super-button
        styleClass="blue"
        text="Générer un document"
        icon="bi bi-file-earmark-plus-fill"
        iconPosition="left"
        (click)="generateModeleDoc(); getModelesDocuments()">
      </super-button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  styleClass="super-compos-modal-content"
  [(visible)]="openCreationModelPointBasedOnExistingPoint"
  [modal]="true"
  [style]="{ width: '30vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">Créer un modèle de point</h5>
      <div class="d-flex">
        <super-button styleClass="green mr-2" (click)="createNewModelPoint()" text="Sauvegarder"></super-button>
        <super-button
          styleClass="red"
          (click)="openCreationModelPointBasedOnExistingPoint = false"
          text="Annuler"></super-button>
      </div>
    </div>
  </p-header>
  <section [formGroup]="formNewModelePoint">
    <div class="row">
      <div class="col-md-12 mb-2">
        <label class="input-label d-block">Description du modèle *</label>
        <input type="text" pInputText formControlName="description" class="w-100" placeholder="Description du modèle" />
      </div>
    </div>
  </section>
</p-dialog>
