<div class="modal-header super-compos-modal-header">
    <h4 class="modal-title super-compos-modal-title">
        <span class="noItalic super-compos-modal-title super-compos-modal-title-span">{{'reportPoint'|translate}}</span>
    </h4>
    <div class="d-flex justify-content-end">
        <super-button styleClass="blue" (click)="ref.close(false);" [text]="'btn.cancel'|translate"></super-button>
        <super-button styleClass="green ml-3" (click)="submitReport()" text="Confirmer"></super-button>
    </div>
</div>
<div class="modal-body">
    <section [formGroup]="formReportPoint" class="mt-4">
         <div class="row">
              <div class="col-6 mb-2 mt-2" >
                   <p-checkbox [label]="'report.duplicateFiles'|translate" binary="true" formControlName="files"></p-checkbox>
              </div>
              <div class="col-6 mb-2 mt-2" >
                   <p-checkbox [label]="'report.duplicateInformationsFiles'|translate" binary="true" formControlName="duplicateInformationsFiles"></p-checkbox>
              </div>
              <div class="col-6 mb-2 mt-2" >
                   <p-checkbox [label]="'report.flagRedaction'|translate" binary="true" formControlName="redaction"></p-checkbox>
              </div>
              <div class="col-6 mb-2 mt-2" >
                   <p-checkbox [label]="'report.keepRedacteur'|translate" binary="true" formControlName="keepPointRedacteur"></p-checkbox>
              </div>
              <div class="col-12 mb-4 mt-2" >
                   <p-checkbox [label]="'report.duplicateAnnexes'|translate" binary="true" formControlName="duplicateAnnexes"></p-checkbox>
              </div>
              <div class="col-6 mb-2">
                <label class="input-label">{{'meetingTypeLinked'|translate}} *</label>
                <p-dropdown [virtualScroll]="true" itemSize="30" formControlName="typeSeanceId" (onChange)="selectedMeetingPoint($event)" [options]="types_seances_LV|sort:'label':'ASC'"  styleClass="w-100" [placeholder]="'selectAMeetingType'|translate" appendTo="body" [showClear]="true" [filter]="true" (onClear)="clearTypeSeance()">
                    <ng-template let-item pTemplate="item">
                        <div class="d-flex align-items-center justify-content-between">
                                <div class="truncateLabel">{{item.label}}</div>
                                <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="col-6 mb-2" >
                <label class="input-label">{{'meetingDate'|translate}} *</label>
                <p-dropdown [virtualScroll]="true" itemSize="30" *ngIf="formReportPoint.value.typeSeanceId" optionLabel="label" optionValue="value" formControlName="targetSeanceId" [options]="seances|sort:'date':'ASC'" styleClass="w-100" [placeholder]="'selectAMeeting'|translate" appendTo="body" [showClear]="true" [filter]="true">
                    <ng-template let-item pTemplate="item">
                        <div class="d-flex align-items-center justify-content-between">
                                <div class="truncateLabel">{{item.label}}</div>
                                <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                        </div>
                    </ng-template>
                </p-dropdown>
                <div class="alert alert-warning" *ngIf="!formReportPoint.value.typeSeanceId"> {{'report.pleaseSelectAMeetingType'|translate}}</div>
            </div>
            <div class="col-12">
                <label class="input-label">{{'message'|translate}}</label>
                <textarea class="w-100" pInputTextarea formControlName="message"></textarea>
            </div>
         </div>
    </section>
</div>
