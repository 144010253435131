import { Component, OnInit, Output, EventEmitter, Input, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import * as async from 'async';
import { SuperButton, SuperRichEditComponent } from '@devinforius/super-compos';
import { DocumentFormatApi } from 'devexpress-richedit/lib/model-api/formats/enum';
import { loadMessages, locale } from 'devextreme/localization';
import RELocalization from 'src/assets/i18n/dx-rich.fr.json';
import { MergeFields } from '@devinforius/super-compos/lib/models/mergeFields';
import { Global_Class } from '../../models/global';
import { Point } from '../../models/point';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit {
  public token;
  public json: any = '';
  public title = 'Editeur de modèle';
  public displayTitle = false;
  @Input() set meetingID(value) {
    if (value) {
      this.meetingId = value;
    }
  }
  @Input() set typeRTF(value) {
    if (value) {
      this.selectedType = value;
      this.load();
    }
  }
  public meetingId;
  public urlParams;
  public queryParams;
  public selectedType;
  public pointId;
  public seanceId;
  public point;
  public showTexts = true;
  public showExport = true;
  public rights: any = { edit: true };
  public loaded: boolean = false;
  public loadedGlobalElements: boolean = false;
  public dataServicePointIds = null;
  public buttons: Array<SuperButton> = [];
  public displayErrorNoRightSave = false;
  public listOfTypes = [
    { label: 'Motivation', value: 'Motivation' },
    { label: 'Décision', value: 'Decision' },
    { label: 'Observations', value: 'Observations' },
    { label: 'Complément de délibération', value: 'ComplementDeDeliberation' },
    { label: 'Notes de synthèse', value: 'NotesDeSynthese' },
  ];
  @Output() editorText: EventEmitter<any> = new EventEmitter();
  public isModelGenMode: boolean = false;
  @ViewChild('editor') public editor: SuperRichEditComponent;
  public fusions: Array<MergeFields> = [];
  public readOnly: boolean = false;
  public globalElement: Global_Class;
  writeLock: any = {};

  constructor(
    private translate: TranslateService,
    private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public api: ApiService,
    private toastr: ToastrService,
    private dataService: DataService,
    public apiAdmin: iAdminService,
    private oAuthService: OAuthService
  ) {
    this.token = this.oAuthService.getAccessToken();
    if (RELocalization) {
      loadMessages({ fr: RELocalization });
      locale('fr');
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.urlParams = this.router.snapshot.params;
    this.queryParams = this.router.snapshot.queryParams;

    this.dataService.getGlobalElements.subscribe(
      (res: any) => {
        this.globalElement = new Global_Class(res);
        this.loadedGlobalElements = true;
        if (res && res.configApp) {
          if (this.urlParams && this.urlParams.pointID && this.urlParams.typeRTF) {
            this.dataService.getPointIdsToBeReloadedAfterRTFChangeInNewTab().subscribe((res: any) => {
              this.dataServicePointIds = res;
            });
            //on vient pour le fullscreen de l'éditor des RTF (motivation, décision,...) d'un point
            this.displayTitle = true;
            this.selectedType = this.urlParams.typeRTF;
            this.pointId = this.urlParams.pointID;
            this.showTexts = false;
            this.showExport = false;
            this.getFusionsField();
            this.api.getRightsPoint(this.pointId).subscribe((rights) => {
              this.rights = rights;
              this.api.getPointByID(this.pointId).subscribe((point: any) => {
                this.point = point;
                this.checkIfThePointIsLocked();
                this.api.getPointRTFDatasStatic(this.pointId, this.selectedType).subscribe((res: any) => {
                  if (this.rights && this.rights.edit) {
                    this.buttons = [
                      {
                        text: 'Enregistrer',
                        styleClass: 'green',
                        click: () => {
                          this.save();
                        },
                      },
                    ];
                  } else {
                    this.readOnly = true;
                  }
                  const type = this.listOfTypes.find((x) => x.value === this.selectedType)?.label;
                  this.title = this.point.objetSynthese + ' (' + type + ')';
                  if (res && res.size > 0) {
                    const blob = new Blob([res], { type: 'application/rtf' });
                    if (this.editor) {
                      this.editor.rich.openDocument(blob, 'document', DocumentFormatApi.Rtf, (loaded) => {
                        if (loaded) {
                          this.spinner.hide();
                          this.loaded = true;
                          if (this.readOnly) {
                            this.editor.rich.readOnly = true;
                          }
                        }
                      });
                    }
                  } else {
                    if (this.readOnly) {
                      this.editor.rich.readOnly = true;
                    }
                    this.spinner.hide();
                  }
                });
              });
            });
          } else if (this.urlParams && this.urlParams.modeleID) {
            //on est dans la génération de modele de document
            this.isModelGenMode = true;
            const generatedAndSaved: boolean = this.queryParams.generatedAndSaved === 'true' ? true : false;
            this.pointId = this.urlParams.pointId ? this.urlParams.pointId : null;
            this.seanceId = this.urlParams.seanceId ? this.urlParams.seanceId : null;
            this.spinner.show();
            this.displayTitle = true;

            if (this.pointId) {
              //1) récup du point pour avoir sa desc
              this.api.getPointByID(this.pointId).subscribe((point: any) => {
                //2) récup des modèles pour avoir le nom du modèle en modif
                this.api.getModelesDocumentsByPointID(this.pointId).subscribe((modeles: any) => {
                  //ici y'a un rights cangenerate. SI oui => on peut save et on peut download le doc
                  const actualModele = modeles.find((x) => x.id == this.urlParams.modeleID);
                  this.title = point.objetSynthese + ' (' + actualModele.description + ')';
                  if (actualModele.rights.canGenerateModele) {
                    this.buttons = [
                      {
                        text: 'Enregistrer',
                        styleClass: 'green',
                        click: () => {
                          this.save();
                        },
                      },
                    ];
                    this.api
                      .downloadModeleDocumentRTF(+this.pointId, this.urlParams.modeleID, generatedAndSaved)
                      .subscribe(
                        (res: any) => {
                          const blob = new Blob([res], { type: 'application/rtf' });
                          if (this.editor) {
                            this.editor.rich.openDocument(blob, 'document', DocumentFormatApi.Rtf, (loaded) => {
                              if (loaded) {
                                this.spinner.hide();
                                this.loaded = true;
                                if (this.readOnly) {
                                  this.editor.rich.readOnly = true;
                                }
                              } else {
                                this.spinner.hide();
                                this.toastr.error(this.translate.instant('error.open'));
                              }
                            });
                          }
                        },
                        (err) => {
                          this.toastr.error('Une erreur est survenue lors de la récupération du modèle');
                          this.spinner.hide();
                        }
                      );
                  } else {
                    this.readOnly = true;
                    this.editor.rich.readOnly = true;
                    this.spinner.hide();
                    this.displayErrorNoRightSave = true;
                  }
                });
              });
            } else if (this.seanceId) {
              //1) récup de la séance pour avoir sa desc
              this.api.getMeetingsByID(this.seanceId).subscribe((meeting: any) => {
                //2) récup des modèles pour avoir le nom du modèle en modif
                this.api.getModelesDocumentsByMeetingID(this.seanceId).subscribe((modeles: any) => {
                  //ici y'a un rights cangenerate. SI oui => on peut save et on peut download le doc
                  const actualModele = modeles.find((x) => x.id == this.urlParams.modeleID);
                  this.title = meeting.description + ' (' + actualModele.description + ')';
                  if (actualModele.rights.canGenerateModele) {
                    this.buttons = [
                      {
                        text: 'Enregistrer',
                        styleClass: 'green',
                        click: () => {
                          this.save();
                        },
                      },
                    ];
                    this.api
                      .downloadMeetingModeleDocumentRTF(+this.seanceId, this.urlParams.modeleID, generatedAndSaved)
                      .subscribe(
                        (res: any) => {
                          const blob = new Blob([res], { type: 'application/rtf' });
                          if (this.editor) {
                            this.editor.rich.openDocument(blob, 'document', DocumentFormatApi.Rtf, (loaded) => {
                              if (loaded) {
                                this.spinner.hide();
                                this.loaded = true;
                                if (this.readOnly) {
                                  this.editor.rich.readOnly = true;
                                }
                              } else {
                                this.spinner.hide();
                                this.toastr.error(this.translate.instant('error.open'));
                              }
                            });
                          }
                        },
                        (err) => {
                          this.toastr.error('Une erreur est survenue lors de la récupération du modèle');
                          this.spinner.hide();
                        }
                      );
                  } else {
                    this.readOnly = true;
                    this.editor.rich.readOnly = true;
                    this.spinner.hide();
                    this.displayErrorNoRightSave = true;
                  }
                });
              });
            }
          } else if (this.urlParams && this.urlParams.pointID3P && this.urlParams.fileType) {
            this.readOnly = true;
            this.api
              .getContenuStaticFilesFrom3P(this.urlParams.pointID3P, this.urlParams.fileType)
              .subscribe((res: any) => {
                const blob = new Blob([res], { type: 'application/rtf' });
                if (this.editor) {
                  this.editor.rich.openDocument(blob, 'document', DocumentFormatApi.Rtf, (loaded) => {
                    if (loaded) {
                      this.spinner.hide();
                      this.loaded = true;
                      if (this.readOnly) {
                        this.editor.rich.readOnly = true;
                      }
                    }
                  }),
                    (err) => {
                      this.spinner.hide();
                    };
                }
              });
          }
        } else {
          setTimeout(() => {
            this.spinner.hide();
          }, 2000);
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  getFusionsField() {
    this.api.getGlobalFusionsFields().subscribe((res: any) => {
      this.fusions = res;
    });
  }

  load() {
    this.displayTitle = false;
    this.showTexts = false;
    this.showExport = false;
    this.spinner.show();
    this.getFusionsField();
    this.api.getRightsSeance(this.meetingId).subscribe((rights) => {
      this.rights = rights;
      this.api.getSeanceRTFDatasStatic(this.meetingId, this.selectedType).subscribe((res: any) => {
        if (res && res.size > 0) {
          const blob = new Blob([res], { type: 'application/rtf' });
          if (this.editor) {
            this.editor.rich.openDocument(blob, 'document', DocumentFormatApi.Rtf, (loaded) => {
              if (loaded) {
                this.spinner.hide();
                this.loaded = true;
              }
            });
          }
        } else {
          this.spinner.hide();
        }
      });
    });
  }

  save(fileSave?: File) {
    if (this.readOnly) {
      this.toastr.error("Vous n'avez pas le droit de sauvegarder");
    } else {
      this.spinner.show();
      let content = null;
      if (this.editor && !fileSave) {
        this.editor.rich.exportToFile((file) => {
          const blob = new Blob([file], { type: 'application/rtf' });
          content = blob;
        }, DocumentFormatApi.Rtf);
      } else {
        const blob = new Blob([fileSave], { type: 'application/rtf' });
        content = blob;
      }
      if (!this.isModelGenMode) {
        if (this.pointId) {
          async.waterfall([
            (callback) => {
              this.dataService.setPointIdsToBeReloadedAfterRTFChangeInNewTab({
                id: this.pointId,
                type: this.selectedType,
              });
              callback();
            },
            (callback) => {
              this.api.putOnePointRTFDatasStatic(this.pointId, content, this.selectedType).subscribe((res: any) => {
                this.toastr.success(this.translate.instant('message.success.dataSaved'));
                this.spinner.hide();
              });
            },
          ]);
        } else if (this.meetingId) {
          this.api.putOneSeanceRTFDatasStatic(this.meetingId, content, this.selectedType).subscribe((res: any) => {
            this.toastr.success(this.translate.instant('message.success.dataSaved'));
            this.spinner.hide();
          });
        }
      } else {
        if (this.pointId) {
          this.api.uploadRtfModeleDoc(+this.pointId, this.urlParams.modeleID, { file: content }).subscribe(
            (res: any) => {
              this.toastr.success('Le document a bien été modifié');
              this.spinner.hide();
            },
            (error) => {}
          );
        }
        if (this.seanceId) {
          this.api.uploadRtfModeleDocMeeting(+this.seanceId, this.urlParams.modeleID, { file: content }).subscribe(
            (res: any) => {
              this.toastr.success('Le document a bien été modifié');
              this.spinner.hide();
            },
            (error) => {}
          );
        }
      }
    }
  }

  //! This is a duplicate of the function in the create.component.ts and accordions-editor.component.ts file
  //TODO: à déplacer dans un service

  checkIfThePointIsLocked() {
    this.apiAdmin.getUserInfo().subscribe((userInfos) => {
      const currentUser: boolean = userInfos.id === this.point.writeLock.writeLockUserId;
      this.writeLock = {
        currentUser: currentUser,
      };
      if (!this.point.writeLock.locked && !currentUser) {
        this.lockPoint();
      } else if (this.point.writeLock.locked && currentUser) {
        null;
      } else {
        this.readOnly = true;
      }
    });
  }

  lockPoint() {
    this.api.lockPoint(this.pointId).subscribe(
      (res: Point['writeLock']) => {
        this.toastr.info(this.translate.instant('info.pointLocked'));
      },
      (error) => {
        if (error.status === 403 && error.error.message === 'PointWriteLockedByOtherUser') {
          this.spinner.hide();
          this.readOnly = true;
          this.toastr.info(this.translate.instant('info.pointLocked'));
        } else {
          this.spinner.hide();
          this.readOnly = true;
          this.toastr.error(this.translate.instant('errorOccurred'));
        }
      }
    );
  }

  releaseWriteLock() {
    this.api.ReleaseWriteLock(this.pointId).subscribe(
      (res: Point['writeLock']) => {
        if (res) {
          this.toastr.info(this.translate.instant('info.pointUnlocked'));
        }
      },
      (error) => {
        this.toastr.error(this.translate.instant('errorOccurred'));
      }
    );
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: Event) {
    this.releaseWriteLock();
  }
}
