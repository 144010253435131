import { formatNumber } from '@angular/common';
import '@angular/common/locales/global/es';
import { ITableColumn } from '@devinforius/super-compos';
import { ConnectionsComponent } from './connections.component';

export const connectionColumn = (self: ConnectionsComponent): Array<ITableColumn> => {
  return [
    {
      field: 'courrierId-scss',
      header: 'ID',
      format: (value, item) => {
        const id = formatNumber(item.courrierId, 'es');
        return `<span class="idContainerForTable">${id}</span>`;
      },
    },
    {
      field: 'object',
      header: 'Objet',
      format: (value) => {
        return value;
      },
    },
    {
      field: 'relatedCourrierType',
      header: 'Type de relation',
      format: (value) => {
        return value;
      },
    },
    {
      field: 'service',
      header: 'Service',
      format: (value) => {
        return value;
      },
    },
    {
      field: 'iconDeleteLink',
      header: '',
      format: (value) => {
        return `<i class="bi bi-trash3 red-color cursor-pointer"></i>`;
      },
      click: (column) => {
        self.deleteLink(column);
      },
    },
  ];
};
