import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/core/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as async from 'async';
import { MenuItem } from 'primeng/api';
import { DataService } from 'src/app/core/services/data.service';
import { Point } from '../../models/point';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Global_Class } from '../../models/global';
import { SuperRichEditComponent } from '@devinforius/super-compos';
import * as _ from 'underscore';
import { loadMessages, locale } from 'devextreme/localization';
import RELocalization from 'src/assets/i18n/dx-rich.fr.json';
import { DocumentFormatApi } from 'devexpress-richedit/lib/model-api/formats/enum';
import { OAuthService } from 'angular-oauth2-oidc';
@Component({
  selector: 'app-accordions-editor',
  templateUrl: './accordions-editor.component.html',
  styleUrls: ['./accordions-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
})
export class AccordionsEditorComponent implements OnInit {
  public loaded: boolean = false;
  public displaySaveBtn: boolean;
  @Input() set point(value: Point) {
    if (value && value?.id) {
      this.pointID = value?.id;
      if (value?.contenuStaticRTFFilesPresents) {
        this.contenuStaticRTFFilesPresents = value?.contenuStaticRTFFilesPresents;
      } else {
        this.reloadRTFPresence();
      }
    }
  }
  @Input() set rights(value) {
    this.rightsOnPoint = value;
  }

  @Input() set gConfig(value) {
    this.globalConfig = value;
  }
  @Output() sendEditors: EventEmitter<any> = new EventEmitter();

  public editorFullScreen = false;
  public fullScreenTitle = null;
  public fullScreenData = null;
  public contenuStaticRTFFilesPresents;
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    event.preventDefault();
    event.returnValue = '';
  }

  //TODO verifier si utilisé
  public mousePosition: MouseEvent;
  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.mousePosition = event;
  }
  public theSfdt: string = '';
  public blob: any;
  public newblob: any;
  public rightsOnPoint: any;
  public globalConfig: any;
  public baseUrlDocumentEditor: string;
  public showDocExportBtn: boolean;
  public enableSpellCheck: boolean;
  public headers: any = {};
  public guid: string;
  public contactLangue: any;
  public languages: MenuItem[];
  public spellCheckLangId: number;
  public isLoaded = false;
  public selectedType = null; //contains the type of accordion on click
  public Motivation = [];
  public Decision = [];
  public Observations = [];
  public NotesDeSynthese = [];
  public ComplementDeDeliberation = [];
  public token;
  public canBeSaved = false;
  public pointID;
  public globalElement: Global_Class = null;
  public restrictEdit: Array<boolean> = [true, true, true, true, true];
  public indexCheckReloadForRTF = [0, 0, 0, 0, 0];
  public currentIndex;
  public fusions: Array<any> = [];
  public activeState: boolean[] = [false, false, false, false, false];
  @ViewChild('document_editor_motivation') public document_editor_motivation: SuperRichEditComponent;
  @ViewChild('document_editor_decision') public document_editor_decision: SuperRichEditComponent;
  @ViewChild('document_editor_observation') public document_editor_observation: SuperRichEditComponent;
  @ViewChild('document_editor_complementDeDeliberation')
  public document_editor_complementDeDeliberation: SuperRichEditComponent;
  @ViewChild('document_editor_notesDeSynthese') public document_editor_notesDeSynthese: SuperRichEditComponent;
  public editedEditors = [];
  public staticEditors = [
    { type: 'Motivation', editor: 'document_editor_motivation' },
    { type: 'Decision', editor: 'document_editor_decision' },
    { type: 'Observations', editor: 'document_editor_observation' },
    { type: 'ComplementDeDeliberation', editor: 'document_editor_complementDeDeliberation' },
    { type: 'NotesDeSynthese', editor: 'document_editor_notesDeSynthese' },
  ];
  public indexEdited = []; //les index de tabs qui ont été édités =>on va mettre dedans les index des tabs que l'on va ouvrir quand on va save
  public contentEditors: Array<any> = [
    { Motivation: null },
    { Decision: null },
    { Observations: null },
    { ComplementDeDeliberation: null },
    { NotesDeSynthese: null },
  ];
  @Input() writeLock: any;
  @Output() writeLockUpdated = new EventEmitter<any>();
  public showAllAccordion = false;
  constructor(
    public translate: TranslateService,
    public route: Router,
    public router: ActivatedRoute,
    private dataService: DataService,
    private api: ApiService,
    private spinner: NgxSpinnerService,
    public dialogService: DialogService,
    private toastr: ToastrService,
    private oAuthService: OAuthService
  ) {}

  ngOnInit(): void {
    this.token = this.oAuthService.getAccessToken();
    const config = JSON.parse(sessionStorage.getItem('globalConfig'));
    this.baseUrlDocumentEditor = config.baseUrlDocumentEditor + 'DocumentEditor/';
    this.enableSpellCheck = config.enableSpellCheck;
    this.showDocExportBtn = config.showDocExportBtn;
    if (RELocalization) {
      loadMessages({ fr: RELocalization });
      locale('fr');
    }
    this.dataService.getGlobalElements.subscribe((res: any) => {
      this.globalElement = new Global_Class(res);
      if (this.globalElement.isLoaded()) {
        this.getFusionsField();
        this.restrictEditionAll();
      }
    });
    //TODO : on va devoir faire un get de configuration afin de récupérer les tooltips de l'accordion ainsi que la config des libellés
  }

  restrictEditionAll() {
    if (this.document_editor_motivation) {
      this.document_editor_motivation.rich.readOnly = true;
    }
    if (this.document_editor_decision) {
      this.document_editor_decision.rich.readOnly = true;
    }
    if (this.document_editor_observation) {
      this.document_editor_observation.rich.readOnly = true;
    }
    if (this.document_editor_complementDeDeliberation) {
      this.document_editor_complementDeDeliberation.rich.readOnly = true;
    }
    if (this.document_editor_notesDeSynthese) {
      this.document_editor_notesDeSynthese.rich.readOnly = true;
    }
  }

  save(data, type) {
    const blob = new Blob([data], { type: 'application/rtf' });
    this.api.putOnePointRTFDatasStatic(this.pointID, blob, type).subscribe((res: any) => {
      this.toastr.success(this.translate.instant('message.success.dataSaved'));
      this.reloadRTFPresence();
      this.dataService.checkIfUnsavedChanges = false;
    });
  }

  openFullScreen(event, title, dataRTF) {
    event.preventDefault();
    event.stopPropagation();
    const config = JSON.parse(sessionStorage.getItem('globalConfig'));
    window.open(config.baseUrl + `/private/editor/point/` + this.pointID + `/` + title);
  }

  reloadRTF(event, type: string, index) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedType = type;
    this.currentIndex = index;
    this.reloadRTFPresence();
    this.loadFile(true, type);
  }

  public accessEdition(type, defaultBoolean = true) {
    if (!this.writeLock.locked || this.writeLock?.currentUser) {
      // On empeche de refermer le tabs quand on reclick sur le bouton d'édition et on change la valeur du restrictEditing en son inverse
      this.lockPoint();
      this.spinner.show();
      switch (type) {
        case 'Motivation': {
          if (this.activeState[0]) {
            event.preventDefault();
            event.stopPropagation();
          }
          if (this.document_editor_motivation) {
            this.document_editor_motivation.rich.readOnly = !defaultBoolean;
            this.editedEditors.push({ type: type, editor: 'document_editor_motivation' });
            this.toastr.info('Vous pouvez éditer le contenu');
          } else {
            setTimeout(() => {
              this.document_editor_motivation.rich.readOnly = !defaultBoolean;
              this.editedEditors.push({ type: type, editor: 'document_editor_motivation' });
              this.toastr.info('Vous pouvez éditer le contenu');
            }, 1500);
          }
          break;
        }
        case 'Decision': {
          if (this.activeState[1]) {
            event.preventDefault();
            event.stopPropagation();
          }
          if (this.document_editor_decision) {
            this.document_editor_decision.rich.readOnly = !defaultBoolean;
            this.editedEditors.push({ type: type, editor: 'document_editor_decision' });
            this.toastr.info('Vous pouvez éditer le contenu');
          } else {
            setTimeout(() => {
              this.document_editor_decision.rich.readOnly = !defaultBoolean;
              this.editedEditors.push({ type: type, editor: 'document_editor_decision' });
              this.toastr.info('Vous pouvez éditer le contenu');
            }, 1500);
          }
          break;
        }
        case 'Observations': {
          if (this.activeState[2]) {
            event.preventDefault();
            event.stopPropagation();
          }
          if (this.document_editor_observation) {
            this.document_editor_observation.rich.readOnly = !defaultBoolean;
            this.editedEditors.push({ type: type, editor: 'document_editor_observation' });
            this.toastr.info('Vous pouvez éditer le contenu');
          } else {
            setTimeout(() => {
              this.document_editor_observation.rich.readOnly = !defaultBoolean;
              this.editedEditors.push({ type: type, editor: 'document_editor_observation' });
              this.toastr.info('Vous pouvez éditer le contenu');
            }, 1500);
          }
          break;
        }
        case 'ComplementDeDeliberation': {
          if (this.activeState[3]) {
            event.preventDefault();
            event.stopPropagation();
          }
          if (this.document_editor_complementDeDeliberation) {
            this.document_editor_complementDeDeliberation.rich.readOnly = !defaultBoolean;
            this.editedEditors.push({ type: type, editor: 'document_editor_complementDeDeliberation' });
            this.toastr.info('Vous pouvez éditer le contenu');
          } else {
            setTimeout(() => {
              this.document_editor_complementDeDeliberation.rich.readOnly = !defaultBoolean;
              this.editedEditors.push({ type: type, editor: 'document_editor_complementDeDeliberation' });
              this.toastr.info('Vous pouvez éditer le contenu');
            }, 1500);
          }
          break;
        }
        case 'NotesDeSynthese': {
          if (this.activeState[4]) {
            event.preventDefault();
            event.stopPropagation();
          }
          if (this.document_editor_notesDeSynthese) {
            this.document_editor_notesDeSynthese.rich.readOnly = !defaultBoolean;
            this.editedEditors.push({ type: type, editor: 'document_editor_notesDeSynthese' });
            this.toastr.info('Vous pouvez éditer le contenu');
          } else {
            setTimeout(() => {
              this.document_editor_notesDeSynthese.rich.readOnly = !defaultBoolean;
              this.editedEditors.push({ type: type, editor: 'document_editor_notesDeSynthese' });
              this.toastr.info('Vous pouvez éditer le contenu');
            }, 1500);
          }
          break;
        }
        default: {
          break;
        }
      }
      setTimeout(() => {
        this.spinner.hide();
        this.dataService.checkIfUnsavedChanges = true;
      }, 500);
    } else {
      switch (type) {
        case 'Motivation': {
          if (this.document_editor_motivation) {
            this.document_editor_motivation.rich.readOnly = true;
          } else {
            setTimeout(() => {
              this.document_editor_motivation.rich.readOnly = true;
            }, 1500);
          }
          break;
        }
        case 'Decision': {
          if (this.document_editor_decision) {
            this.document_editor_decision.rich.readOnly = true;
          } else {
            setTimeout(() => {
              this.document_editor_decision.rich.readOnly = true;
            }, 1500);
          }
          break;
        }
        case 'Observations': {
          if (this.document_editor_observation) {
            this.document_editor_observation.rich.readOnly = true;
          } else {
            setTimeout(() => {
              this.document_editor_observation.rich.readOnly = true;
            }, 1500);
          }
          break;
        }
        case 'ComplementDeDeliberation': {
          if (this.document_editor_complementDeDeliberation) {
            this.document_editor_complementDeDeliberation.rich.readOnly = true;
          } else {
            setTimeout(() => {
              this.document_editor_complementDeDeliberation.rich.readOnly = true;
            }, 1500);
          }
          break;
        }
        case 'NotesDeSynthese': {
          if (this.document_editor_notesDeSynthese) {
            this.document_editor_notesDeSynthese.rich.readOnly = true;
          } else {
            setTimeout(() => {
              this.document_editor_notesDeSynthese.rich.readOnly = true;
            }, 1500);
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  loadFile(forceReload: boolean = false, selectedType?: string) {
    setTimeout(() => {
      const type: string = selectedType ? selectedType : this.selectedType;
      if (this.pointID) {
        if (forceReload || this.indexCheckReloadForRTF[this.currentIndex] === 0) {
          this.indexCheckReloadForRTF[this.currentIndex] += 1;
          //Si on a pas encore cliqué sur l'accordion, on vient d'arriver sur la page => on peut reload
          this.spinner.show();
          this.api.getPointRTFDatasStatic(this.pointID, type).subscribe((res: any) => {
            if (res && res.size > 0) {
              const blob = new Blob([res], { type: 'application/rtf' });
              if (type === 'Motivation') {
                if (this.document_editor_motivation) {
                  this.document_editor_motivation.rich.openDocument(
                    blob,
                    'motivation',
                    DocumentFormatApi.Rtf,
                    (loaded) => {
                      if (loaded) {
                        this.spinner.hide();
                        !forceReload ? (this.document_editor_motivation.rich.readOnly = true) : null;
                      } else {
                        this.activeState[this.currentIndex] = false;
                        this.indexCheckReloadForRTF[this.currentIndex] = 0;
                        this.spinner.hide();
                        this.toastr.error(this.translate.instant('error.cannotGetRTFFile'));
                      }
                    }
                  );
                  setTimeout(() => {
                    this.spinner.hide();
                  }, 500);
                } else {
                  this.spinner.hide();
                }
              }
              if (type === 'Decision') {
                if (this.document_editor_decision) {
                  this.document_editor_decision.rich.openDocument(blob, 'Decision', DocumentFormatApi.Rtf, (loaded) => {
                    if (loaded) {
                      this.spinner.hide();
                      !forceReload ? (this.document_editor_decision.rich.readOnly = true) : null;
                    } else {
                      this.activeState[this.currentIndex] = false;
                      this.indexCheckReloadForRTF[this.currentIndex] = 0;
                      this.spinner.hide();
                      this.toastr.error(this.translate.instant('error.cannotGetRTFFile'));
                    }
                  });
                  setTimeout(() => {
                    this.spinner.hide();
                  }, 500);
                } else {
                  this.spinner.hide();
                }
              }
              if (type === 'Observations') {
                if (this.document_editor_observation) {
                  this.document_editor_observation.rich.openDocument(
                    blob,
                    'Observations',
                    DocumentFormatApi.Rtf,
                    (loaded) => {
                      if (loaded) {
                        this.spinner.hide();
                        !forceReload ? (this.document_editor_observation.rich.readOnly = true) : null;
                      } else {
                        this.activeState[this.currentIndex] = false;
                        this.indexCheckReloadForRTF[this.currentIndex] = 0;
                        this.spinner.hide();
                        this.toastr.error(this.translate.instant('error.cannotGetRTFFile'));
                      }
                    }
                  );
                  setTimeout(() => {
                    this.spinner.hide();
                  }, 500);
                } else {
                  this.spinner.hide();
                }
              }
              if (type === 'ComplementDeDeliberation') {
                if (this.document_editor_complementDeDeliberation) {
                  this.document_editor_complementDeDeliberation.rich.openDocument(
                    blob,
                    'ComplementDeDeliberation',
                    DocumentFormatApi.Rtf,
                    (loaded) => {
                      if (loaded) {
                        this.spinner.hide();
                        !forceReload ? (this.document_editor_complementDeDeliberation.rich.readOnly = true) : null;
                      } else {
                        this.activeState[this.currentIndex] = false;
                        this.indexCheckReloadForRTF[this.currentIndex] = 0;
                        this.spinner.hide();
                        this.toastr.error(this.translate.instant('error.cannotGetRTFFile'));
                      }
                    }
                  );
                  setTimeout(() => {
                    this.spinner.hide();
                  }, 500);
                } else {
                  this.spinner.hide();
                }
              }
              if (type === 'NotesDeSynthese') {
                if (this.document_editor_notesDeSynthese) {
                  this.document_editor_notesDeSynthese.rich.openDocument(
                    blob,
                    'NotesDeSynthese',
                    DocumentFormatApi.Rtf,
                    (loaded) => {
                      if (loaded) {
                        this.spinner.hide();
                        !forceReload ? (this.document_editor_notesDeSynthese.rich.readOnly = true) : null;
                      } else {
                        this.activeState[this.currentIndex] = false;
                        this.indexCheckReloadForRTF[this.currentIndex] = 0;
                        this.spinner.hide();
                        this.toastr.error(this.translate.instant('error.cannotGetRTFFile'));
                      }
                    }
                  );
                  setTimeout(() => {
                    this.spinner.hide();
                  }, 500);
                } else {
                  this.spinner.hide();
                }
              }
            } else {
              this.spinner.hide();
            }
          });
        }
      }
    }, 200);
  }

  onClick(type, index) {
    this.selectedType = type;
    this.currentIndex = index;
    this.displaySaveBtn = true;
  }

  saveAllRTF() {
    if (!this.rightsOnPoint?.edit) {
    } else {
      async.waterfall([
        (cb) => {
          this.getMotivation();
          this.getDecision();
          this.getObservations();
          this.getComplement();
          this.getNotes();
          cb(null);
        },
        (cb) => {
          this.spinner.show();
          const data = [];
          const names = [];
          async.eachSeries(
            this.contentEditors,
            (item: any, cbEachSeries) => {
              if (Object.values(item)[0] === null) {
                //on passe les null
                cbEachSeries(null);
              } else {
                data.push(Object.values(item)[0]);
                const name = Object.keys(item);
                names.push(name);
                cbEachSeries(null);
              }
            },
            () => {
              this.api.putMultiplePointRTFDatasStatic(this.pointID, data, names).subscribe(
                (res: any) => {
                  this.toastr.success(this.translate.instant('message.success.dataSaved'));
                  this.reloadRTFPresence();
                  this.displaySaveBtn = false;
                  this.dataService.checkIfUnsavedChanges = false;
                  this.realeseWriteLock();
                  this.spinner.hide();
                },
                (err) => {
                  this.toastr.error('Une erreur est survenue lors de la sauvegarde');
                  this.spinner.hide();
                }
              );
            }
          );
        },
      ]);
    }
  }
  getFusionsField() {
    this.api.getGlobalFusionsFields().subscribe((res: any) => {
      this.fusions = res;
    });
  }
  reloadRTFPresence() {
    this.api.getPointContenuStaticRTFFilesPresents(this.pointID).subscribe((res) => {
      this.contenuStaticRTFFilesPresents = res;
    });
  }

  getMotivation() {
    if (this.document_editor_motivation) {
      this.document_editor_motivation.rich.exportToFile((file) => {
        const blob = new Blob([file], { type: 'application/rtf' });
        const data = {
          Motivation: blob,
        };
        this.contentEditors.splice(0, 1, data);
      }, DocumentFormatApi.Rtf);
    }
  }
  getDecision() {
    if (this.document_editor_decision) {
      this.document_editor_decision.rich.exportToFile((file) => {
        const blob = new Blob([file], { type: 'application/rtf' });
        const data = {
          Decision: blob,
        };
        this.contentEditors.splice(1, 1, data);
      }, DocumentFormatApi.Rtf);
    }
  }
  getObservations() {
    if (this.document_editor_observation) {
      this.document_editor_observation.rich.exportToFile((file) => {
        const blob = new Blob([file], { type: 'application/rtf' });
        const data = {
          Observations: blob,
        };
        this.contentEditors.splice(2, 1, data);
      }, DocumentFormatApi.Rtf);
    }
  }
  getComplement() {
    if (this.document_editor_complementDeDeliberation) {
      this.document_editor_complementDeDeliberation.rich.exportToFile((file) => {
        const blob = new Blob([file], { type: 'application/rtf' });
        const data = {
          ComplementDeDeliberation: blob,
        };
        this.contentEditors.splice(3, 1, data);
      }, DocumentFormatApi.Rtf);
    }
  }

  getNotes() {
    if (this.document_editor_notesDeSynthese) {
      this.document_editor_notesDeSynthese.rich.exportToFile((file) => {
        const blob = new Blob([file], { type: 'application/rtf' });
        const data = {
          NotesDeSynthese: blob,
        };
        this.contentEditors.splice(4, 1, data);
      }, DocumentFormatApi.Rtf);
    }
  }

  toggleAll(bool: boolean) {
    for (let i = 0; i <= 4; i++) {
      this.activeState[i] = bool;
    }
  }

  // TODO! This is a duplicate of the function in the point-details.component.ts file
  lockPoint() {
    this.api.lockPoint(this.pointID).subscribe(
      (res: Point['writeLock']) => {
        this.writeLockUpdated.emit(res);
        this.toastr.info(this.translate.instant('info.pointLocked'));
      },
      (error) => {
        if (error.status === 403 && error.error.message === 'PointWriteLockedByOtherUser') {
          this.spinner.hide();
          this.writeLock.locked = true;
          this.writeLockUpdated.emit(this.writeLock);
          this.toastr.info(this.translate.instant('info.pointLocked'));
        } else {
          this.spinner.hide();
          this.toastr.error(this.translate.instant('errorOccurred'));
        }
      }
    );
  }

  realeseWriteLock() {
    if (this.writeLock.locked && this.writeLock.currentUser)
      this.api.ReleaseWriteLock(this.pointID).subscribe(
        (res: Point['writeLock']) => {
          if (res) {
            this.toastr.info(this.translate.instant('info.pointUnlocked'));
            this.activeState = [false, false, false, false, false];
            this.writeLockUpdated.emit(res);
          }
        },
        (error) => {
          this.toastr.error(this.translate.instant('errorOccurred'));
        }
      );
  }

  /**
   * Destroy the lock of the point when the user leaves the page
   */
  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: Event) {
    this.realeseWriteLock();
  }

  /**
   * A function that displays all tabs.
   */
  onShowAll() {
    this.spinner.show();
    this.showAllAccordion = !this.showAllAccordion;
    const arrayRTF = ['Motivation', 'Decision', 'Observations', 'ComplementDeDeliberation', 'NotesDeSynthese'];
    if (this.showAllAccordion) {
      arrayRTF.map((type, index) => {
        this.activeState[index] = true;
        this.loadFile(true, type);
      });
    } else {
      arrayRTF.map((type, index) => {
        this.activeState[index] = false;
      });
      this.spinner.hide();
    }
  }
}
