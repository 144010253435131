export class NiveauIntroConcluTitreData {

    childrens : Array<any> = [];
    niveauDescription : string;
    niveauType : number  = 2;
    ordreAffichage : number;
    points : Array<any> = [];
    isTitle : boolean = false;
    isIntroduction : boolean = false;
    isConclusion : boolean = false;
    title : string;
    meetingId : any;
    id:number;
    constructor(data:any) {
        Object.assign(this, data);
        this.childrens = [];
        this.niveauType = 2 ;
        this.points = [];
        this.title = this.niveauDescription;
        this.id =  Math.floor(Math.random() * 10000);
    }
}