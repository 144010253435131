import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/core/services/api.service';
import { Meeting } from '../../models/meeting';
import { Point } from '../../models/point';
import * as async from 'async';

@Component({
  selector: 'app-seance-RTF',
  templateUrl: './intro-conclusion-point.component.html',
  styleUrls: ['./intro-conclusion-point.component.scss'],
})
export class IntroConclusionPointComponent implements OnInit {
  @Input() meetingId;
  @Input() set typeRTF(value) {
    this.type = value;
  }

  public point: Point;
  public type = '';
  public meeting: Meeting = null;
  public loaded = false;
  public urlParams;
  constructor(
    public api: ApiService,
    public route: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public router: Router
  ) {}

  ngOnInit(): void {
    if (this.meetingId) {
      this.getMeetingsByID();
    } else {
      this.urlParams = this.route.snapshot.params;
      this.meetingId = this.meeting ? this.meeting.id : this.urlParams.meetingID;
      this.type = this.urlParams.type;
      this.getMeetingsByID();
    }
  }

  getMeetingIntroConclusion() {
    this.spinner.hide();
    setTimeout(() => {
      //TODO changer le visuel de l'url
    }, 500);
  }

  getMeetingsByID() {
    this.api.getMeetingsByID(this.meetingId).subscribe((meeting: any) => {
      //Besoin pour afficher la description en html
      this.meeting = new Meeting(meeting);
    });
  }

  back(event) {
    if (event) {
      window.history.back();
    }
  }
}
