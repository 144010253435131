import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DataService } from 'src/app/core/services/data.service';
import { Global_Class } from 'src/app/shared/models/global';
import { Contact } from 'src/app/shared/models/iContact/contact';
import { ApiService } from 'src/app/core/services/api.service';
import { LabelValue } from 'src/app/shared/models/labelvalue';
import * as moment from 'moment';
import * as async from 'async';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SessionStorageService } from 'src/app/core/services/sessionStorage.service';
import { DashboardTypes, UserAppConfigurationRequest } from 'src/app/shared/models/api-request/me.models';
import { tap } from 'rxjs/operators';
import { isEmptyObject } from 'src/app/shared/utils/checks.utils';
import { UserContact } from 'src/app/shared/models/iContact/user-contact';
import { combineLatest } from 'rxjs';
import { removeEmpty } from 'src/app/shared/utils/object.utilis';
import { SearchDetailInfos } from './models';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { GroupeSecuritePoint } from 'src/app/shared/models/response/groupeSecurite';
import { UserInfo } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-points-search',
  templateUrl: './points-search.component.html',
  styleUrls: ['./points-search.component.scss'],
})
export class PointsSearchComponent implements OnInit {
  public globalElements: Global_Class = null;
  public contacts: Array<Contact> = [];
  public users: UserContact[] = [];
  public meetings: Array<LabelValue> = [];
  public handlerType = 'users';

  //recherches sauvegardées
  public openSavedSearchs = false;
  public savedSearchsItems: UserAppConfigurationRequest[] = [];
  public savedSearchId: number;
  public savedModal: boolean = false;
  public updateMode: boolean = false;
  public showTitleAndContent: boolean = false;
  public columns: Array<any> = [
    {
      field: 'description',
      header: 'Description',
    },
    {
      field: 'b',
      header: 'Utiliser',
      format: (value, item) => {
        return '<div class="btn btn-primary d-block w-100">Utiliser</div>';
      },
      click: (value) => {
        this.patchFormSearch(value);
      },
    },
    {
      field: 'c',
      header: 'Modifier',
      format: (value, item) => {
        return '<div class="btn btn-primary d-block w-100">Modifier</div>';
      },
      click: (value) => {
        this.patchFromWithSavedData(value);
      },
    },
    {
      field: 'a',
      header: 'Supprimer',
      format: (value, item) => {
        return '<div class="btn btn-danger d-block w-100">Supprimer</div>';
      },
      click: (value) => {
        this.deleteSearch(value);
      },
    },
  ];

  public formSearchSaved = new FormGroup({
    description: new FormControl(null),
    shared: new FormControl(true),
    hideInDashboard: new FormControl(false),
    displayOrder: new FormControl(null),
    value: new FormControl(null),
  });

  // form for value in formSearchSaved form
  public form: FormGroup = new FormGroup({
    //* Use ObjetSynthese and not RechercheGlobale for a reason =>
    //* Even if the user write '**' and then a number it SHOULDN't be considered as an ID
    //* Approved by MPE (1/01/23)
    ObjetSynthese: new FormControl(null),
    TypeSeanceIds: new FormControl(null),
    TypeIds: new FormControl(null),
    ServiceIds: new FormControl(null),
    StatutIds: new FormControl(null),
    MatiereIds: new FormControl(null),
    EchevinatIds: new FormControl(null),
    TypeDecisionIds: new FormControl(null),
    SeanceIds: new FormControl(null),
    ACorriger: new FormControl(null),
    CheckContenuRTF: new FormControl(null),
    Date: new FormControl(null),
    DateDebut: new FormControl(null),
    DateFin: new FormControl(null),
    OJ: new FormControl(null),
    OrdrePv: new FormControl(null),
    CreeParContactId: new FormControl(null),
    AgentTraitantIds: new FormControl(null),
    AgentTraitantIdiAdmins: new FormControl(null),
    ClassementIds: new FormControl(null),
    DossierClassementIds: new FormControl(null),
    ExclurePointsNonInclus: new FormControl(null),
    ExclurePointsSupprimes: new FormControl(null),
  });
  public globalConfiguration;
  public listOfEchevinats = [];
  public listOfMatieres = [];
  public listOfStatuts = [];
  public listOfTypePoint = [];
  public groupeSecuriteRightsPoints: GroupeSecuritePoint;
  public userInfos: UserInfo;

  constructor(
    public dataService: DataService,
    public dialogRef: DynamicDialogRef,
    public translate: TranslateService,
    public api: ApiService,
    public iContactService: IContactService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private storageService: SessionStorageService,
    private apiAdmin: iAdminService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.apiAdmin.getGlobalConfig().subscribe((res: any) => {
      this.globalConfiguration = res;
      const needDataObs = this.makeNeededDataObs();
      needDataObs.subscribe(() => {
        this.gettingRights().then(() => {
          if (this.dataService.advancedSearchPoint) {
            const datas = this.dataService.advancedSearchPoint as SearchDetailInfos & any;
            delete datas.PageNumber;
            delete datas.SortOrder;
            datas.DateDebut ? (datas.DateDebut = moment(datas.DateDebut, 'YYYY-MM-DD').toDate()) : null;
            datas.Date ? (datas.Date = moment(datas.Date, 'YYYY-MM-DD').toDate()) : null;
            datas.DateFin ? (datas.DateFin = moment(datas.DateFin, 'YYYY-MM-DD').toDate()) : null;
            if (datas.TypeId) {
              const typeIdIncluded = this.listOfTypePoint.map((x) => x.value).includes(datas.TypeId);
              typeIdIncluded === false ? delete datas.TypeId : null;
            }
            if (datas.MatiereId) {
              const matiereIncluded = this.listOfMatieres.map((x) => x.value).includes(datas.MatiereId);
              matiereIncluded === false ? delete datas.MatiereId : null;
            }
            if (datas.EchevinatId) {
              const echevinatIncluded = this.listOfEchevinats.map((x) => x.value).includes(datas.EchevinatId);
              echevinatIncluded === false ? delete datas.EchevinatId : null;
            }
            if (datas.StatutId) {
              const statutIncluded = this.listOfStatuts.map((x) => x.value).includes(datas.StatutId);
              statutIncluded === false ? delete datas.StatutId : null;
            }
            this.form.patchValue(datas);
            this.dataService.advancedSearchPoint.AgentTraitantIdiAdmin
              ? (this.handlerType = 'contacts')
              : (this.handlerType = 'users');
          }
          this.spinner.hide();
        });
      });
    });
    this.apiAdmin.getUserInfo().subscribe((res: any) => {
      this.userInfos = res;
      if(!this.userInfos.fullView){
        const servicesPartialView = this.globalElements.services_LV.filter(item => this.userInfos.services.includes(item.value));
        this.userInfos.services = servicesPartialView;
      }
    });
  }

  makeNeededDataObs() {
    const globalElementsObs = this.dataService.getGlobalElements.pipe(
      tap((globalElementsRes) => {
        if (!isEmptyObject(globalElementsRes)) {
          this.globalElements = new Global_Class(globalElementsRes);
        }
      })
    );
    const UserWithDataObs = this.iContactService.getUserWithDataAdmin().pipe(
      tap((res) => {
        this.users = res.filter((x) => x.active);
      })
    );
    const contactsObs = this.iContactService.getLowContacts().pipe(
      tap((res) => {
        this.contacts = res.filter((x) => !x.nePlusUtiliser);
      })
    );
    return combineLatest([globalElementsObs, UserWithDataObs, contactsObs]);
  }

  gettingRights() {
    return new Promise<boolean>((resolve, reject) => {
      this.api.getGroupeSecurityRightByPointID().subscribe((right: any) => {
        this.groupeSecuriteRightsPoints = right as GroupeSecuritePoint;

        if (
          this.groupeSecuriteRightsPoints.viewActualEchevinat &&
          this.groupeSecuriteRightsPoints.viewEchevinatAllowedIds.length > 0
        ) {
          this.listOfEchevinats = this.globalElements.echevinats_LV.filter((x) =>
            this.groupeSecuriteRightsPoints.viewEchevinatAllowedIds.includes(x.value)
          );
        }

        if (
          this.groupeSecuriteRightsPoints.viewActualMatiere &&
          this.groupeSecuriteRightsPoints.viewMatiereAllowedIds.length > 0
        ) {
          this.listOfMatieres = this.globalElements.matieres_LV.filter((x) =>
            this.groupeSecuriteRightsPoints.viewMatiereAllowedIds.includes(x.value)
          );
        }

        if (
          this.groupeSecuriteRightsPoints.viewActualStatut &&
          this.groupeSecuriteRightsPoints.viewStatutAllowedIds.length > 0
        ) {
          this.listOfStatuts = this.globalElements.statuts_points_LV.filter((x) =>
            this.groupeSecuriteRightsPoints.viewStatutAllowedIds.includes(x.value)
          );
        }

        if (
          this.groupeSecuriteRightsPoints.viewActualTypePoint &&
          this.groupeSecuriteRightsPoints.viewTypePointAllowedIds.length > 0
        ) {
          this.listOfTypePoint = this.globalElements.types_points_LV.filter((x) =>
            this.groupeSecuriteRightsPoints.viewTypePointAllowedIds.includes(x.value)
          );
        }
        resolve(true);
      });
    });
  }

  // On matter change we'll get the matter chosen with all its datas.
  // We also get all echevinats where we do have the right to view
  // We only keep echevinats that are in the list of rights AND in the matter list.
  changeMatter(event) {
    if (event?.value) {
      const currentMatter = this.globalElements.matieres_LV.find((x) => x.value === event.value);
      const allEchevinatsWithRights = this.globalElements.echevinats_LV.filter((x) =>
        this.groupeSecuriteRightsPoints.viewEchevinatAllowedIds.includes(x.value)
      );
      this.listOfEchevinats = JSON.parse(JSON.stringify(allEchevinatsWithRights));
      if (currentMatter) {
        this.listOfEchevinats = [];
        async.eachSeries(
          currentMatter.item.echevinats,
          (x, next) => {
            const index = allEchevinatsWithRights.map((x) => x.value).indexOf(x);
            if (index > -1) {
              this.listOfEchevinats.push(allEchevinatsWithRights[index]);
            }
            next();
          },
          () => {
            //En plus de limiter on va vérifier que l'échevinat choisi (s'il y en a un) soit bien dans la liste!!
            if (this.form.value.EchevinatId) {
              const index2 = this.listOfEchevinats.map((x) => x.value).indexOf(this.form.value.EchevinatId);
              index2 === -1 ? this.form.patchValue({ EchevinatId: null }) : null;
            }
          }
        );
      }
    } else {
      if (
        this.groupeSecuriteRightsPoints.viewActualEchevinat &&
        this.groupeSecuriteRightsPoints.viewEchevinatAllowedIds.length > 0
      ) {
        this.listOfEchevinats = this.globalElements.echevinats_LV.filter((x) =>
          this.groupeSecuriteRightsPoints.viewEchevinatAllowedIds.includes(x.value)
        );
      }
    }
  }

  //When we change the echevinats, 1) check if we already chose a matter or not. if yes, DO NOT TOUCH THE LIST. if not reduce the list with the matters that have at least this echevinat linked
  changeEchevinat(event) {
    if (event?.value) {
      if (!this.form.value.MatiereId) {
        const allMattersWithRights = this.globalElements.matieres_LV.filter((x) =>
          this.groupeSecuriteRightsPoints.viewMatiereAllowedIds.includes(x.value)
        );
        this.listOfMatieres = [];
        async.eachOfSeries(allMattersWithRights, (matter: any, index, next) => {
          const indexOf = matter.item.echevinats.indexOf(event.value);
          indexOf > -1 ? this.listOfMatieres.push(allMattersWithRights[index]) : null;
          next();
        });
      }
    } else {
      if (
        this.groupeSecuriteRightsPoints.viewActualMatiere &&
        this.groupeSecuriteRightsPoints.viewMatiereAllowedIds.length > 0
      ) {
        this.listOfMatieres = this.globalElements.matieres_LV.filter((x) =>
          this.groupeSecuriteRightsPoints.viewMatiereAllowedIds.includes(x.value)
        );
      }
    }
  }

  onCloseDialog() {
    if (this.form.value.Date) {
      this.form.value.Date = moment(this.form.value.Date).format('YYYY-MM-DD');
    }
    if (this.form.value.DateDebut) {
      this.form.value.DateDebut = moment(this.form.value.DateDebut).format('YYYY-MM-DD');
    }
    if (this.form.value.DateFin) {
      this.form.value.DateFin = moment(this.form.value.DateFin).format('YYYY-MM-DD');
    }
    const data = removeEmpty(this.form.value);
    this.dialogRef.close(data);
  }

  reset() {
    this.form.reset();
  }

  filterMeeting(event) {
    this.meetings = [];
    if (event && event.value) {
      this.api
        .getMeetingsSearch({ TypeSeanceIds: [event.value], pageSize: 500 })
        .toPromise()
        .then((res) => {
          if (res && res.items.length > 0) {
            res.items.forEach((item) => {
              const formattedDate = moment(item.date, 'YYYYMMDD').format(
                this.translate.instant('format.formatDateSeance')
              );
              this.meetings.push({
                label: item.description
                  ? item.description
                  : this.translate.instant('seanceRemplaceDescriptionEmpty', { date: formattedDate }),
                value: item.id,
              });
            });
          }
        });
    }
  }

  onClickRadioButtonHandler(event) {
    if (this.handlerType === 'users') {
      this.form.patchValue({ AgentTraitantId: null });
    } else if (this.handlerType === 'contacts') {
      this.form.patchValue({ AgentTraitantIdiAdmin: null });
    }
  }

  patchFormSearch(value) {
    try {
      const data = JSON.parse(value.value) as SearchDetailInfos;
      this.form.patchValue({
        ...data,
        Date: data.Date ? moment(data.Date).toDate() : null,
        DateDebut: data.DateDebut ? moment(data.DateDebut).toDate() : null,
        DateFin: data.DateFin ? moment(data.DateFin).toDate() : null,
      } as SearchDetailInfos);
    } catch (error) {
      this.form.patchValue({});
    }
    this.openSavedSearchs = false;
  }

  fetchSavedSearchs() {
    this.openSavedSearchs = true;
    this.api
      .getPersonalParams(DashboardTypes.IDelibeBackofficeRechercheSauvegardee)
      .toPromise()
      .then((res) => {
        this.savedSearchsItems = [];
        if (res?.length) {
          this.savedSearchsItems = res;
        }
      });
  }

  patchFromWithSavedData(value: UserAppConfigurationRequest) {
    this.api
      .getPersonalParams(DashboardTypes.IDelibeBackofficeRechercheSauvegardee, value.name)
      .toPromise()
      .then((res) => {
        if (res?.length) {
          this.savedModal = true;
          this.formSearchSaved.patchValue(res[0]);
          this.updateMode = true;
          this.savedSearchId = res[0].id;
        }
      });
  }

  saveSearch() {
    const createPayload = { ...this.formSearchSaved.value } as UserAppConfigurationRequest;
    createPayload.name = 'iDelibe' + this.formSearchSaved.value.description.toLowerCase();
    createPayload.value = JSON.stringify(this.form.value);
    this.api
      .postPersonalParams(DashboardTypes.IDelibeBackofficeRechercheSauvegardee, createPayload)
      .toPromise()
      .then(() => {
        this.savedModal = false;
        this.toastr.success('Votre recherche a bien été sauvegardée');
        this.storageService.saveData('reloadDashboardPoints', '1');
      });
  }

  updateSearch() {
    const putPayload = { ...this.formSearchSaved.value } as UserAppConfigurationRequest;
    putPayload.name = 'iDelibe' + this.formSearchSaved.value.description.toLowerCase();
    putPayload.value = JSON.stringify(this.form.value);
    this.api
      .putPersonalParams(DashboardTypes.IDelibeBackofficeRechercheSauvegardee, this.savedSearchId, putPayload)
      .toPromise()
      .then(() => {
        this.savedModal = false;
        this.fetchSavedSearchs();
        this.toastr.success('Votre recherche a bien été sauvegardée');
        this.storageService.saveData('reloadDashboardPoints', '1');
      });
  }

  deleteSearch(value) {
    this.api
      .deletePersonalParams(DashboardTypes.IDelibeBackofficeRechercheSauvegardee, value.id)
      .toPromise()
      .then(() => {
        this.openSavedSearchs = false;
        this.toastr.success('Votre recherche a bien été supprimée');
        this.storageService.saveData('reloadDashboardPoints', '1');
      });
  }

  toggleSwitch(event: any): void {
    this.showTitleAndContent = event.checked;
  }
}
