import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Subscription } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
@Injectable({
  providedIn: 'root',
})
export class AuthGuardsService implements CanActivate {
  isAuthorizedSubscription: Subscription | undefined;
  isAuthorized = false;
  onChecksessionChanged: Subscription | undefined;
  checksession = false;
  public userData: any;
  constructor(
    private router: Router,
    private oauthService: OAuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const hasAccessToken = this.oauthService.hasValidAccessToken();
    return new Promise((resolve) => {
      if (hasAccessToken) {
        resolve(true);
      } else {
        this.router.navigateByUrl('/public');
        resolve(false);
        console.warn('un access token invalide expiré');
      }
    });
  }
}
