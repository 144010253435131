export class TypePoint {
     nePasInclureDocuments: boolean;
     inclureDansOj: boolean;
     description: string;
     utilisable: boolean;
     droitsUtilisation: number;
     droitsVisualisation: number;
     tri: string;
     id:number;
     constructor(data:any) {
          Object.assign(this, data);
     }
}
 