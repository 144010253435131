
import { ContactAdresse } from './contact-adresse';
import { BaseContact } from './base-contact';

import { Langue } from './langue';
import { TypeContact } from './type-contact';
import { User } from './user';
import { SocieteFormeJuridique } from './societe-forme-juridique';

export class Contact {
    public listContactsAdresses?: ContactAdresse[];
    public baseSurContact:number;
    public listSocieteLies: BaseContact[];
    public id: number;
    public user: User;
    public typeContact: TypeContact;
    public typeSociete?: boolean; // true if it's a company
    public titre?: string;
    public label:string;
    public value:any;
    public nom?: string;
    public prenom?: string;
    public nomInterne?: string;
    public fonction?: string;
    public langue?: Langue;
    public nePlusUtiliser?: boolean;
    public membrePersonnel?: boolean;
    public numeroCarteNISS?: string;
    public pasDeRNBelge?: boolean;
    public dateNaissance?: string; // ($date-time)
    public naissanceLieu?: string; // ($date-time)
    public dateDeces?: string; // ($date-time)
    public etatCivil?: string;
    public sexe?: string;
    public nationalite?: string;
    public radiationRN?: boolean;
    public radiationRNDate?: string; // ($date - time)
    public numeroMatricule?: string;
    public numeroRegistrePersonnel?: string;
    public dateEntreeFonction?: string; // ($date - time)
    public dateSortieFonction?: string; // ($date - time)
    public fonctionES?: string;
    public secteurActivite: string;
    public gerantSociete?: string;
    public societeFormeJurIdique: SocieteFormeJuridique;
    public societeParente?: number;
    public societeParenteLienEtablissement?: string;
    public commentaires: string;
    public signatureCertifactNom?: string;
    public signatureDescription?: string;
    public signature1?: string;
    public signature2?: string;
    public comptaCodeClient?: string;
    public comptaCodeFournisseur?: string;
    public comptaPaiementClient?: string;
    public comptaPaiementFournisseur?: string;
    public comptaNom1?: string;
    public comptaNom2?: string;
    public comptaBanqueIBAN?: string;
    public compteBanque?: string;
    public compteBanque2?: string;
    public tva?: string;
    public tvA_Digits?: string;
    public pasDeTVABelge?: boolean;
    public siteWeb?: string;
    public siteWeb2?: string;
    public accesModuleExterneDelibe?: boolean;
    public accesModuleExterneCourrier?: boolean;
    public accesModuleExternePST?: boolean;
    public adressePrincipale: ContactAdresse;
    public verrouille?: boolean;
    public eProjectActif?: boolean;
    public eProjectLogin?: string;
    public eProjectPassword?: string;
    public eProjectLangue?: string;
    public bce?: string;
    public exchangeMailLogin?: string;
    public contactIdReferenceInterne?: number;
    public createD_BY?: string;
    public createD_ON?: string; // ($date - time)
    public modifY_BY?: string;
    public modifI_ON?: string; // ($date - time)
    public createD_BY_USERId?: number;
    public modifieD_BY_USERId?: number;
    public typeContactName:string;
    public typeContactID:number;
    public paysContactID: number; // for tva
    public userId: number;
    // Fields for superListSimple
    public name?: string;
    public mail:string;
    public gsm:string;
    public impression_CodePostal:string;
    public impression_Localite:string;
    public impression_Adresse:string;
    public contactParent:any;
    public fullName:string;
    public fullNameForOrdering:string;
    public type:string;
    public isCompany:boolean = false;
    public lastName:string;
    public firstName:string;
    public loginDelibeWeb : string
    constructor(data?: any) {
        for (const prop in data) {
            if (data.hasOwnProperty(prop)) {
                this[prop] = data[prop];
            }
        }

        this.name = (this.prenom ? this.prenom  + ' ' : '') + (this.nom ? this.nom : '');
        this.label = this.name;
        this.value = this.id;
        this.firstName = this.prenom;
        this.lastName = this.nom;
        this.fullName = (this.firstName ? this.firstName + ' ' : '') + (this.lastName ? this.lastName : '');
        this.fullNameForOrdering = (this.nom ? this.nom+ ' ' : '') + (this.prenom ? this.prenom  + ' ' : '');
        this.isCompany = this.typeSociete;
        if ( this.typeContact ){
            this.typeContactName = this.typeContact.typeContact;
            this.typeContactID = this.typeContact.id;
        }
        if ( this.adressePrincipale ){
            this.mail = this.adressePrincipale.mail;
            if ( this.adressePrincipale.gsm ){
                this.gsm = this.adressePrincipale.gsm.replace(/\s/g,'');
            }
            this.impression_CodePostal = this.adressePrincipale.impression_CodePostal;
            if ( this.adressePrincipale.impression_Localite ){
                this.impression_Localite = this.adressePrincipale.impression_Localite.toLowerCase();
            }
            if ( this.adressePrincipale.impression_Adresse ){
                this.impression_Adresse = this.adressePrincipale.impression_Adresse.toLowerCase();
            }
        }
    }

    setName(name: string) {
        return this.name = name;
    }

}
