<div class="page">
  <div>
    <super-title [text]="'IScan'"></super-title>
  </div>

  <div class="body">
    <div>
      <p-fileUpload
        mode="basic"
        styleClass="fileUploadCustomv2"
        accept="application/pdf"
        (onSelect)="uploadFile($event)"
        [auto]="true"
        maxFileSize="200000000"
        invalidFileSizeMessageSummary="{0}: {{'error.invalidFileSizeMessage1' | translate}}"
        invalidFileSizeMessageDetail="{{'error.invalidFileSizeMessage2' | translate}}"
        chooseLabel="NOUVEAU SCAN"></p-fileUpload>
      <div class="text">
        <span><i class="bi bi-qr-code mr-2"></i>{{'AssociateDocumentQRCode' | translate}}</span>
      </div>
    </div>
  </div>
</div>
