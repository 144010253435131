import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IGlobalElements } from 'src/app/shared/models/common/IGlobalElements';
import { Contact } from 'src/app/shared/models/iContact/contact';
import { Point } from 'src/app/shared/models/point';
import { IFromDuplicationPointDetails } from 'src/app/shared/models/point/IFromDuplicationPointDetails';

@Injectable({
  providedIn: 'root'
})
export class DataService {

     private globalelements = new BehaviorSubject<IGlobalElements>({});
     public getGlobalElements = this.globalelements.asObservable();
     private rightGlobal = new BehaviorSubject<any>({});
     public getGlobalRight = this.rightGlobal.asObservable();
     private smallSidebar = new BehaviorSubject<any>(false);
     public hasSmallSidebar = this.smallSidebar.asObservable();
     private contactslow = new BehaviorSubject<Contact[]>([]);
     public getLowContacts = this.contactslow.asObservable();
     public configApp = new BehaviorSubject<any>(null);
     public getConfigApp = this.configApp.asObservable();
     private errorArray = new BehaviorSubject<any>([]);
     public getErrorArray = this.errorArray.asObservable();
     private canLeaveEditor = new BehaviorSubject<boolean>(true);
     public canLeaveEditorInfo = this.canLeaveEditor.asObservable();
     public array = []
     //#region set meeting for creation point based on it
     private currentMeeting = new BehaviorSubject<any>(null);
     public getCurrentMeeting = this.currentMeeting.asObservable();
     //#endregion

     //#region datas for point duplication
     private duplicatedPoint = new BehaviorSubject<Point | null>(null);
     public getDuplicatedPoint = this.duplicatedPoint.asObservable();
     private duplicatedPointManagement = new BehaviorSubject<IFromDuplicationPointDetails | null>(null);
     public getDuplicatedPointManagement = this.duplicatedPointManagement.asObservable();
     //#endregion

     private advancedSearch = new BehaviorSubject<any>(null);
     public advancedSearchPoint = null;
     public advancedSearchMeeting = null;


     pointIdsToBeReloadedAfterRTFChangeInNewTab = new Subject();
     public appVersion:string;
     public hasUnSavedChanges = new BehaviorSubject<boolean>(false);
     public getIfHasUnSavedChanges = this.hasUnSavedChanges.asObservable();
     public checkIfUnsavedChanges:boolean = false;
     // public getPointIdsToBeReloadedAfterRTFChangeInNewTab = this.pointIdsToBeReloadedAfterRTFChangeInNewTab.asObservable();

     public advancedSearchEnabled: boolean = false


     constructor() { }

     setCurrentGlobalElements(value: IGlobalElements) {
          return this.globalelements.next(value);
     }
     setCompleteGlobal(){
          this.globalelements.complete();
     }

     setGlobalsRights(value) {
          return this.rightGlobal.next(value);
     }

     setAllContacts(value){
          return this.contactslow.next(value);
        }

     setError(value) {
          if ( value && value.length === 0){
               return this.errorArray.next([]);
          } else {
               this.array.push(value);
               return this.errorArray.next(this.array);
          }
     }

     setSidebarSmall(value){
          this.smallSidebar.next(false);
          this.smallSidebar.next(value);
     }

     setConfigApp(value){
          return this.configApp.next(value);
     }

     setCanLeaveEditor(value: boolean) {
          return this.canLeaveEditor.next(value);
     }

     //#region for dataservice datas used in creation/modification/duplication points
          //#region set meeting for creation point based on it
          SetCurrentMeeting(value){
               this.duplicatedPoint.next(null); //On set le point a null comme on veut créer via une séance
               return this.currentMeeting.next(value);
          }
          //#endregion

          //#region datas for point duplication
          SetDuplicatedPoint(value: Point | null){
               this.currentMeeting.next(null);//On set la séance a null comme on vient de la duplication d'un point
               return this.duplicatedPoint.next(value);
          }
          setDuplicatedPointManagement(value: IFromDuplicationPointDetails | null){
               return this.duplicatedPointManagement.next(value);
          }
          //#endregion
     //#endregion

     setCurrentAdvancedSearch(value){
          return this.advancedSearch.next(value);
     }
     resetAdvancedSearch(){
          this.advancedSearch.unsubscribe();
     }

     getPointIdsToBeReloadedAfterRTFChangeInNewTab(){
          return this.pointIdsToBeReloadedAfterRTFChangeInNewTab.asObservable();
     }
     setPointIdsToBeReloadedAfterRTFChangeInNewTab(value){
          this.pointIdsToBeReloadedAfterRTFChangeInNewTab.next(value);
     }
     // unsubscribeFromPointIdsToBeReloadedAfterRTFChangeInNewTab(){
     //      this.pointIdsToBeReloadedAfterRTFChangeInNewTab.unsubscribe()
     // }

     setUnSavedChangeBool(value:boolean){
          this.hasUnSavedChanges.next(value);
     }

}
