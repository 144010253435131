import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as async from 'async';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { Global_Class } from '../../models/global';
import { Contact } from '../../models/iContact/contact';
import { UserContact } from '../../models/iContact/user-contact';
import { LabelValue } from '../../models/labelvalue';
import { Meeting } from '../../models/meeting';
import { Participant } from '../../models/participant';
import { InfosMeetingEditComponent } from '../modals/infos-meeting-edit/infos-meeting-edit.component';
import { ParticipantsChangeComponent } from '../modals/participants-change/participants-change.component';

@Component({
  selector: 'app-infos-meeting',
  templateUrl: './infos-meeting.component.html',
  styleUrls: ['./infos-meeting.component.scss'],
})
export class InfosMeetingComponent implements OnInit {
  public meeting: Meeting;
  public globalElement: Global_Class;
  public loaded: boolean = false;

  @Input() displayingInTab: boolean;

  public form: FormGroup = new FormGroup({
    signatureId: new FormControl(),
    roleId: new FormControl(),
    sousTri: new FormControl(),
    groupePolitiqueId: new FormControl(),
    pasDeDroitDeVote: new FormControl(),
    invite: new FormControl(),
    absent: new FormControl(),
    seanceAbsent: new FormControl(), //Radiobuttons d'absence
    contactId: new FormControl(),
    contactIdiAdmin: new FormControl(),
    ordrePreseance: new FormControl(),
    absentSeance: new FormControl(),
    absentDebutSeance: new FormControl(),
  });

  public formDescription: FormGroup = new FormGroup({
    description: new FormControl(),
    urlStreaming: new FormControl(),
    reunionFixeeLe: new FormControl(),
    reunionFixeePar: new FormControl(),
    date: new FormControl(),
    heureDebut: new FormControl(),
    heureFin: new FormControl(),
    lieuxId: new FormControl(),
  });
  public buttons = [];
  public menu = [
    {
      label: this.translate.instant('btn.modify'),
      command: (data) => {
        this.modifyParticipant(data);
      },
    },
  ];
  public allowedToEditSeance = null;

  public actualParticipant: Participant = null;
  public bModifyParticipant: boolean = false;

  public participantsColumns = [
    { field: 'ordrePreseance', header: 'order' },
    { field: 'sousTri', header: 'suborder' },
    { field: 'absentDebutSeanceIcon', header: 'away.start' },
    { field: 'absentSeanceIcon', header: 'away' },
    { field: 'inviteIcon', header: 'guest' },
    { field: 'pasDeDroitDeVoteIcon', header: 'noRightVote' },
    { field: 'name', header: 'name' },
    { field: 'roleName', header: 'role' },
    { field: 'signatureLabel', header: 'signature' },
    { field: 'groupePolitiqueLabel', header: 'politicalGroup' },
  ];

  public meetingId = null;
  public lieuxLV: Array<LabelValue> = [];
  public contacts: Array<Contact> = [];
  public users: UserContact[] = [];
  public listOfStatutsForSeance: any = [];

  public listOfPointsCorrectionDescription;
  public listOfPointsNotValidDescription;

  public changeDescription: boolean = false;
  public titleDescription = '';
  @Output() emitterChangeDescription: EventEmitter<any> = new EventEmitter();
  @Input() idSent: number = null;

  constructor(
    public translate: TranslateService,
    public api: ApiService,
    public dataService: DataService,
    public router: Router,
    public route: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public dialogService: DialogService,
    public apiAdmin: iAdminService,
    public confirmService: ConfirmationService,
    public iContactService: IContactService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    async.waterfall(
      [
        (callback) => {
          this.iContactService.getUserWithDataAdmin().subscribe((res: any) => {
            this.users = res;
            callback();
          });
        },
        (callback) => {
          this.iContactService.getLowContacts().subscribe((res: any) => {
            this.contacts = res;
            callback();
          });
        },
        (callback) => {
          this.dataService.getGlobalElements.subscribe((res: any) => {
            this.globalElement = new Global_Class(res);
            if (this.globalElement.isLoaded()) {
              callback(null);
            }
          });
        },
        (callback) => {
          if (this.idSent) {
            this.meetingId = this.idSent;
            callback();
          } else if (this.route.snapshot.params.id) {
            this.meetingId = this.route.snapshot.params.id;
            callback();
          } else {
            this.route.parent.params.subscribe((res: any) => {
              if (res.id && (!this.meetingId || (this.meetingId && this.meetingId !== res.id))) {
                this.meetingId = res.id;
                callback();
              }
            });
          }
        },
        (callback) => {
          this.api.getMeetingsByID(this.meetingId).subscribe((res: any) => {
            if (res) {
              this.meeting = new Meeting(res, this.globalElement);
              this.formDescription.patchValue(this.meeting);
              const lieuxName = this.globalElement.lieux_LV.find((x) => x.value === this.meeting.lieuxId);
              if (lieuxName) {
                this.meeting.lieuxName = lieuxName.label;
              }
              callback();
            }
          });
        },
        (callback) => {
          this.participantsColumns.map((col) => (col.header = this.translate.instant(col.header)));
          this.getParticipants();
          callback(null);
        },
        (callback) => {
          this.api.getRightsSeance(this.meetingId).subscribe((rights: any) => {
            this.allowedToEditSeance = rights;
            if (this.allowedToEditSeance.edit) {
              this.menu = [
                {
                  label: this.translate.instant('btn.modify'),
                  command: (data) => {
                    this.modifyParticipant(data);
                  },
                },
                {
                  label: this.translate.instant('btn.delete'),
                  command: (data) => {
                    this.deleteParticipant(data);
                  },
                },
              ];
            } else {
              this.menu = [];
            }
            callback();
          });
        },
        (callback) => {
          this.buttons = [];
          if (this.meeting.status === 'open') {
            const state = this.translate.instant('global.open');
            if (this.allowedToEditSeance.edit) {
              this.buttons.push({
                id: 'buttonChangeStatut',
                children: [{}],
                text: state,
                tooltip: state,
                styleClass: 'green',
                click: () => {
                  this.setNewStatutModal();
                },
              });
            } else {
              this.buttons.push({
                id: 'buttonChangeStatut',
                children: [{}],
                text: state,
                tooltip: state,
                styleClass: 'green cursor-default',
              });
            }
          }
          if (this.meeting.status === 'pending') {
            const state = this.translate.instant('global.pending');
            if (this.allowedToEditSeance.edit) {
              this.buttons.push({
                id: 'buttonChangeStatut',
                children: [{}],
                text: state,
                tooltip: state,
                styleClass: 'blue',
                click: () => {
                  this.setNewStatutModal();
                },
              });
            } else {
              this.buttons.push({
                id: 'buttonChangeStatut',
                children: [{}],
                text: state,
                tooltip: state,
                styleClass: 'blue cursor-default',
              });
            }
          }
          if (this.meeting.status === 'closed') {
            const state = this.translate.instant('global.closed');
            if (this.allowedToEditSeance.edit) {
              this.buttons.push({
                id: 'buttonChangeStatut',
                children: [{}],
                text: state,
                tooltip: state,
                styleClass: 'red',
                click: () => {
                  this.setNewStatutModal();
                },
              });
            } else {
              this.buttons.push({
                id: 'buttonChangeStatut',
                children: [{}],
                text: state,
                tooltip: state,
                styleClass: 'red cursor-default',
              });
            }
          }
          callback();
        },
      ],
      () => {
        this.spinner.hide();
        this.loaded = true;
      }
    );
  }

  openModifyMeetingModal() {
    if (this.allowedToEditSeance.edit) {
      const ref = this.dialogService.open(InfosMeetingEditComponent, {
        data: {
          meeting: this.meeting,
          header: this.translate.instant('infosOnMeeting'),
        },
        width: '50vw',
        styleClass: 'super-compos-modal-content ',
        showHeader: false,
        closable: false,
        dismissableMask: false,
        baseZIndex: 1000,
      });
      ref.onClose.subscribe((res: any) => {
        if (res) {
          this.api.putSeance(this.meeting.id, res).subscribe((res: any) => {
            this.toastr.success(this.translate.instant('success.updatedMeeting'));
            async.waterfall([
              (cb) => {
                this.api.getMeetingsByID(this.meetingId).subscribe((res: any) => {
                  if (res) {
                    this.meeting = new Meeting(res, this.globalElement);
                    const lieuxName = this.globalElement.lieux_LV.find((x) => x.value === this.meeting.lieuxId);
                    if (lieuxName) {
                      this.meeting.lieuxName = lieuxName.label;
                    }
                    cb(null);
                  }
                });
              },
              (cb) => {
                this.getParticipants();
              },
            ]);
          });
        }
      });
    }
  }

  modifyParticipant(data) {
    const ref = this.dialogService.open(ParticipantsChangeComponent, {
      data: {
        participant: data,
        header: this.translate.instant('infosOnParticipant'),
      },
      width: '50vw',
      styleClass: 'super-compos-modal-content ',
      showHeader: false,
      closable: false,
      dismissableMask: false,
      baseZIndex: 1000,
    });
    ref.onClose.subscribe((res: any) => {
      if (res) {
        this.submitModificationParticipant('modify', res);
      }
    });
  }

  getParticipants() {
    this.meeting.participants = [];
    this.spinner.show();
    this.api.getParticipantsByMeetingID(this.meetingId).subscribe((res: any) => {
      this.meeting.participants = res.map((x) => new Participant(x, this.contacts, this.users, this.globalElement));
      this.meeting.participants.sort((a, b) => {
        if (a.roleTri !== b.roleTri) {
          return a.roleTri - b.roleTri;
        } else if (a.sousTri !== b.sousTri) {
          return a.sousTri - b.sousTri;
        } else {
        }
      });
      this.spinner.hide();
    });
  }

  addParticipants() {
    const ref = this.dialogService.open(ParticipantsChangeComponent, {
      data: {
        participant: null,
        header: this.translate.instant('btn.addParticipant'),
        contacts: this.contacts.filter((x) => !x.nePlusUtiliser),
        users: this.users.filter((x) => x.active),
        alreadyAddedParticipants: this.meeting.participants,
      },
      width: '50vw',
      styleClass: 'super-compos-modal-content ',
      showHeader: false,
      closable: false,
      dismissableMask: false,
      baseZIndex: 1000,
    });
    ref.onClose.subscribe((res: any) => {
      if (res) {
        this.submitModificationParticipant('add', res);
      }
    });
  }

  submitModificationParticipant(type, resFromModal) {
    this.spinner.show();
    const form = resFromModal.form;
    const actualParticipant = resFromModal.actualParticipant;
    this.form.patchValue(form);

    if (type === 'modify') {
      this.api.putParticipantsSeance(this.meetingId, actualParticipant.id, this.form.value).subscribe((res: any) => {
        const index = this.meeting.participants.map((x) => x.id).indexOf(actualParticipant.id);
        this.meeting.participants[index] = res;
        //TODO vérifier que cela modifie bien le bon participant avec les nouvelles infos
        this.toastr.success(this.translate.instant('success.updatedParticipant'));
        this.getParticipants();
      });
    } else {
      this.api.postParticipantSeance(this.meeting.id, this.form.value).subscribe((res: any) => {
        this.toastr.success(this.translate.instant('success.addedParticipant'));
        this.getParticipants();
      });
    }
  }

  deleteParticipant(data) {
    this.api.deleteParticipantSeance(this.meetingId, data.id).subscribe((res: any) => {
      this.toastr.success(this.translate.instant('success.deletedParticipant'));
      this.getParticipants();
    });
  }
  deleteParticipants(items) {
    const arrayToDelete = [];
    async.waterfall([
      (cb) => {
        if (items && items.length > 0) {
          items.map((item) => {
            arrayToDelete.push(item.id);
          });
          cb(null);
        }
      },
      (cb) => {
        this.api.deleteParticipantSeance(this.meetingId, arrayToDelete).subscribe((res: any) => {
          this.toastr.success(this.translate.instant('success.deletedParticipant'));
          this.getParticipants();
        });
      },
    ]);
  }

  back(event) {
    if (event) {
      window.history.back();
    }
  }
  setNewStatutModal() {
    //En tout premier on va faire une vérification de l'existence du statut "valide pour passer en séance"
    async.waterfall([
      (callback) => {
        let isStatutValidForMeetingExists = false;
        this.api.getGlobalAllPointsStatuts().subscribe((res: any) => {
          for (let i = 0; i < res.length; i++) {
            if (res[i].validePourAjoutSeance) {
              isStatutValidForMeetingExists = true;
              break;
            }
          }
          if (isStatutValidForMeetingExists) {
            callback();
          } else {
            this.toastr.error(this.translate.instant('error.noValidStatutExisting'));
          }
        });
      },
      (callback) => {
        //On va vérifier s'il existe un ou plusieurs points 'en correction' + s'il existe des points en statut non valides pour la séance
        this.api.getSeancesStates([this.meeting.id]).subscribe((res: any) => {
          const enCorrectionIds = [];
          const notValidForSeance = [];
          for (let i = 0; i < res.length; i++) {
            if (res[i].pointsInCorrection.length > 0) {
              enCorrectionIds.push(res[i].pointsInCorrection);
            }
            if (res[i].pointsWithoutStatusValideForSeance.length > 0) {
              notValidForSeance.push(res[i].pointsWithoutStatusValideForSeance);
            }
            //Oui en fait y'a pas besoin de complexifier comme cela mais ca a été réfléchi pour le séance simple et multiple...
          }
          if (enCorrectionIds.length > 0) {
            this.toastr.error(
              "Il existe des points 'en correction'. Veuillez les modifier avant de changer le statut de cette séance"
            );
          } else {
            //S'il n'existe aucun soucis => on va afficher les enfants du bouton
            if (this.meeting.status === 'closed') {
              this.buttons[0].children = [
                {
                  text: this.translate.instant('global.open'),
                  tooltip: this.translate.instant('global.open'),
                  click: () => {
                    this.changeStatut('open', notValidForSeance.length);
                  },
                },
                {
                  text: this.translate.instant('global.pending'),
                  tooltip: this.translate.instant('global.pending'),
                  click: () => {
                    this.changeStatut('pending', notValidForSeance.length);
                  },
                },
              ];
            }
            if (this.meeting.status === 'pending') {
              this.buttons[0].children = [
                {
                  text: this.translate.instant('global.open'),
                  tooltip: this.translate.instant('global.open'),
                  click: () => {
                    this.changeStatut('open', notValidForSeance.length);
                  },
                },
                {
                  text: this.translate.instant('global.closed'),
                  tooltip: this.translate.instant('global.closed'),
                  click: () => {
                    this.changeStatut('closed', notValidForSeance.length);
                  },
                },
              ];
            }
            if (this.meeting.status === 'open') {
              this.buttons[0].children = [
                {
                  text: this.translate.instant('global.pending'),
                  tooltip: this.translate.instant('global.pending'),
                  click: () => {
                    this.changeStatut('pending', notValidForSeance.length);
                  },
                },
                {
                  text: this.translate.instant('global.closed'),
                  tooltip: this.translate.instant('global.closed'),
                  click: () => {
                    this.changeStatut('closed', notValidForSeance.length);
                  },
                },
              ];
            }
            callback();
          }
        });
      },
    ]);
  }

  changeStatut(type, numberOfPointsNotValid) {
    let confirmMessage = '';
    if (type === 'open') {
      confirmMessage = this.translate.instant('confirmMessageToOpenSeance');
      if (numberOfPointsNotValid > 0) {
        confirmMessage =
          confirmMessage +
          this.translate.instant('confirmMessageHasNotValidPoints', { amount: numberOfPointsNotValid });
      }
    }
    if (type === 'pending') {
      confirmMessage = this.translate.instant('confirmMessageToPendingSeance');
      if (numberOfPointsNotValid > 0) {
        confirmMessage =
          confirmMessage +
          this.translate.instant('confirmMessageHasNotValidPoints', { amount: numberOfPointsNotValid });
      }
    }
    if (type === 'closed') {
      confirmMessage = this.translate.instant('confirmMessageToClosedSeance');
      if (numberOfPointsNotValid > 0) {
        confirmMessage =
          confirmMessage +
          this.translate.instant('confirmMessageHasNotValidPoints', { amount: numberOfPointsNotValid });
      }
    }

    this.confirmService.confirm({
      message: confirmMessage,
      key: 'infoMeetingConfirm',
      accept: () => {
        const data = {
          ids: [this.meeting.id],
          statut: type === 'open' ? 'Ouverte' : type === 'pending' ? 'Preparee' : 'Fermee',
        };
        this.api.setSeanceStatutes(data).subscribe(
          (response) => {
            this.toastr.success(this.translate.instant('success.modifiedMeetingsStatus'));
            if(response.pointIdsWithIssues > 0){
              const pointsError = response.pointIdsWithIssues;
              this.toastr.info(this.translate.instant('info.points') + ' : ' + pointsError.join(', ') + ' ' + this.translate.instant('info.cannotLock'));
            }
            this.ngOnInit();
          },
          (error) => {
            console.error(error);
          }
        );
      },
    });
  }

  onClickDescriptionMeeting() {
    this.changeDescription = true;
  }

  cancelDescription() {
    setTimeout(() => {
      this.changeDescription = false;
      this.formDescription.patchValue({ description: this.meeting.description });
    }, 100);
  }

  validateDescription() {
    this.api.putSeance(this.meeting.id, this.formDescription.value).subscribe((res: any) => {
      this.toastr.success(this.translate.instant('success.updatedMeeting'));
      this.meeting.description = this.formDescription.value.description;
      this.changeDescription = false;
      this.emitterChangeDescription.emit(this.formDescription.value.description);
    });
  }
}
