<div class="actions-zone-pagination d-flex align-items-center justify-content-between">
  <div class="actions-zone-pagination-leftside">
    <p-dropdown
      inputStyleClass="w-100"
      styleClass="dropdown-inforius"
      [ngModel]="sizeChoice"
      (ngModelChange)="hanleSizeChoiceChange($event)"
      [options]="sizeOfPages"
    >
    </p-dropdown>
  </div>
  <div class="actions-zone-pagination-rightside">
    <button
      class="super-compos-secondary-btn mr-3"
      *ngIf="+pageNumber !== 1"
      style="border-radius: 6px 0px 0px 6px"
      (click)="handlePageNumberChange(+pageNumber - 1)"
    >
      <img src="./assets/img/arrow_l.svg" class="edeb-arrow-icon" />
    </button>
    <small class="mr-3">Page {{ pageNumber }} sur {{ totalPages }}</small>
    <button
      class="super-compos-secondary-btn"
      style="border-radius: 0px 6px 6px 0px"
      [disabled]="pageNumber === totalPages"
      *ngIf="pageNumber !== totalPages && totalPages > 1"
      (click)="handlePageNumberChange(+pageNumber + 1)"
    >
      <img src="./assets/img/arrow_r.svg" class="edeb-arrow-icon" />
    </button>
  </div>
</div>
