<div class="m-3">
    <i class="fa fa-exclamation-triangle ui-confirmdialog-icon"></i>
    <span class="ui-confirmdialog-message">
        {{config.data.message}}
    </span>

</div>
<div class="ui-dialog-footer">
    <button type="button" pButton icon="pi pi-times" label="Non" (click)="reject()"></button>
    <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="Oui" (click)="accept()"></button>
</div>
