<div class="modal-header super-compos-modal-header">
  <h5 class="modal-title super-compos-modal-title">
    {{ header }}
  </h5>
  <div class="d-flex align-items-center">
    <i class="fas fa-times-circle fa-lg cursor-pointer" (click)="ref.close()"></i>
  </div>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="mt-3 mb-3 row participants">
    <div
      *ngIf="actualParticipant?.id"
      class="col-md-12 d-flex align-items-center justify-content-center participants__name mb-2">
      {{ actualParticipant?.name }}
    </div>
    <div class="col-md-12 mb-2" *ngIf="actualParticipant === null">
      <div class="panel-header mb-3">
        <a
          *ngFor="let tabItem of tabItems; let i = index"
          (click)="switchTabs(tabItem)"
          [ngClass]="{ active: tabItem?.value === selectedTabs?.value }">
          {{ tabItem?.header }}
          <span class="badge badge-pill badge-dark badge-custom">{{ tabItem.valueBadge }}</span>
        </a>
      </div>
      <section class="panel-content">
        <div *ngIf="selectedTabs?.value === 'users'">
          <p-dropdown
            [virtualScroll]="true"
            itemSize="30"
            [options]="users"
            styleClass="w-100"
            formControlName="contactIdiAdmin"
            [placeholder]="'selectAUser' | translate"
            appendTo="body"
            [showClear]="true"
            [filter]="true">
            <ng-template let-item pTemplate="item">
              <div class="d-flex align-items-center justify-content-between">
                <div class="truncateLabel">{{ item.label }}</div>
                <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div *ngIf="selectedTabs?.value === 'contacts'">
          <p-dropdown
            [virtualScroll]="true"
            itemSize="30"
            [options]="contacts"
            styleClass="w-100"
            formControlName="contactId"
            [placeholder]="'selectAContact' | translate"
            appendTo="body"
            [showClear]="true"
            [filter]="true">
            <ng-template let-item pTemplate="item">
              <div class="d-flex align-items-center justify-content-between">
                <div class="truncateLabel">{{ item.label }}</div>
                <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </section>
    </div>

    <div class="col-md-12 mb-2">
      <label for="" class="input-label d-block">{{ 'signature' | translate }}</label>
      <p-dropdown
        [virtualScroll]="true"
        itemSize="30"
        [options]="globalElement?.signatures_LV"
        styleClass="w-100"
        formControlName="signatureId"
        [placeholder]="'selectASignature' | translate"
        appendTo="body"
        [showClear]="true"
        [filter]="true">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="truncateLabel">{{ item.label }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col-md-8 mb-2">
      <label for="" class="input-label d-block">{{ 'role' | translate }}</label>
      <p-dropdown
        [virtualScroll]="true"
        itemSize="30"
        [options]="globalElement?.roles_LV"
        styleClass="w-100"
        formControlName="roleId"
        [placeholder]="'selectARole' | translate"
        appendTo="body"
        [showClear]="true"
        [filter]="true">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="truncateLabel">{{ item.label }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col-md-4 mb-2">
      <label for="" class="input-label d-block">{{ 'subSort' | translate }}</label>
      <input type="number" formControlName="sousTri" class="form-control" />
    </div>
    <div class="col-md-12 mb-2">
      <label for="" class="input-label d-block">{{ 'politicalGroup' | translate }}</label>
      <p-dropdown
        [virtualScroll]="true"
        itemSize="30"
        [options]="globalElement?.groupes_pol_LV"
        styleClass="w-100"
        formControlName="groupePolitiqueId"
        [placeholder]="'selectAPoliticalGroup' | translate"
        appendTo="body"
        [showClear]="true"
        [filter]="true">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="truncateLabel">{{ item.label }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col-md-12 mb-2">
      <p-checkbox
        [label]="'vote.noVoteRight' | translate"
        inputId="noVoteRight"
        binary="true"
        formControlName="pasDeDroitDeVote"></p-checkbox>
    </div>
    <div class="col-md-12 mb-2">
      <p-checkbox [label]="'guest' | translate" inputId="guest" binary="true" formControlName="invite"></p-checkbox>
    </div>
    <div class="col-md-12 mb-2">
      <p-checkbox
        (onChange)="changeCheckbox($event)"
        [label]="'absent' | translate"
        inputId="absent"
        binary="true"
        formControlName="absent"></p-checkbox>
      <div *ngIf="form.value.absent === true" class="ml-4 mt-2">
        <div class="field-radiobutton">
          <p-radioButton value="atStartMeeting" formControlName="absentType" inputId="atStartMeeting"></p-radioButton>
          <label for="atStartMeeting" class="mb-0 ml-2 mr-4">{{ 'atStartMeeting' | translate }}</label>
        </div>
        <div class="field-radiobutton">
          <p-radioButton
            value="duringAllMeeting"
            formControlName="absentType"
            inputId="duringAllMeeting"></p-radioButton>
          <label for="duringAllMeeting" class="mb-0 ml-2 mr-4">{{ 'duringAllMeeting' | translate }}</label>
        </div>
      </div>
    </div>
  </form>
  <super-button
    *ngIf="actualParticipant"
    [text]="'btn.modify' | translate"
    styleClass="green w-100 justifyButtonTextCenter"
    (click)="submitModificationParticipant()"></super-button>
  <super-button
    *ngIf="actualParticipant === null"
    [text]="'btn.add' | translate"
    styleClass="green w-100 justifyButtonTextCenter"
    (click)="submitModificationParticipant()"></super-button>
</div>
