<section class="mx-1" *ngIf="loaded">
  <super-title
    *ngIf="!displayingInTab"
    [text]="'meeting.infos' | translate"
    level="2"
    [hasSubtitle]="false"></super-title>
  <br *ngIf="!displayingInTab" />
  <p class="participantTable__title" [ngClass]="{ 'mt-3': !displayingInTab }">{{ 'infosOnMeeting' | translate }}</p>

  <div class="d-flex align-items-center mb-2" (click)="onClickDescriptionMeeting()">
    <i class="bi bi-tag-fill mr-2 category__icon"></i>
    <p class="mb-0" *ngIf="!changeDescription">
      <strong>Titre : </strong>{{ meeting.description }}
      <i *ngIf="allowedToEditSeance.edit" class="bi bi bi-pen-fill blue-icon ml-2"></i>
    </p>
    <div *ngIf="changeDescription" class="d-flex justify-content-left align-items-center descriptionOfMeeting w-100">
      <form [formGroup]="formDescription" class="w-90">
        <input
          type="text"
          class="form-control w-100"
          formControlName="description"
          autocomplete="new-description"
          (keyup.enter)="validateDescription()" />
      </form>
      <super-button class="ml-1" styleClass="red" icon="fas fa-times" (click)="cancelDescription()"></super-button>
      <super-button class="ml-1" styleClass="green" icon="fas fa-check" (click)="validateDescription()"></super-button>
    </div>
  </div>
  <!-- <div *ngIf="!changeDescription" class="row justify-content-left align-items-center descriptionOfMeeting infos_title" (click)="onClickDescriptionMeeting()">
        <span class="mr-2 descriptionOfMeeting__span">{{meeting.description}}</span> <i *ngIf="allowedToEditSeance.edit" class="icon-edit blue-icon ml-2 infos_title__iconEdit"></i>
    </div> -->

  <div class="d-flex align-items-center mb-2" (click)="openModifyMeetingModal()">
    <i class="bi bi-calendar-fill mr-2 category__icon"></i>
    <p class="mb-0">
      <strong>Date : </strong>{{ meeting?.date | momentFormat: translate.instant('format.formattedDate') }}
      <span class="ml-3">{{
        meeting?.heureDebut ? (meeting.heureDebut | momentFormat: 'HH[:]mm') : ('noStartDate' | translate)
      }}</span>
      <i class="bi bi-arrow-right mx-2"></i>
      <span class="">{{
        meeting?.heureFin ? (meeting.heureFin | momentFormat: 'HH[:]mm') : ('noEndDate' | translate)
      }}</span
      ><i *ngIf="allowedToEditSeance.edit" class="bi bi-pen-fill ml-2 blue-icon"></i>
    </p>
  </div>

  <div class="d-flex align-items-center" (click)="openModifyMeetingModal()">
    <i class="bi bi-geo-alt-fill mr-2 category__icon"></i>
    <p class="mb-0">
      <strong>Lieu : </strong>{{ meeting?.lieuxName ? meeting.lieuxName : translate.instant('noPlaceForThisMeeting') }}
      <i *ngIf="allowedToEditSeance.edit" class="bi bi bi-pen-fill blue-icon ml-2"></i>
    </p>
  </div>
  <!--     
    <div class="row justify-content-center align-items-center sectionInfosTop">
        <div [ngClass]="{'infos_title':allowedToEditSeance.edit, 'info_title--noEdit':!allowedToEditSeance.edit}" (click)="openModifyMeetingModal()">
            <i class="fas fa-calendar-alt fa-2x mr-3"></i>
            <span class="mr-5 sectionInfosTop__span">{{meeting?.date|momentFormat: translate.instant('format.formattedDate')}}</span> <span class="mr-3 sectionInfosTop__span">{{meeting?.heureDebut? (meeting.heureDebut|momentFormat:'HH[:]mm') : 'noStartDate'|translate}}</span> <i class="fas fa-arrow-right mr-3"></i> <span class="sectionInfosTop__span">{{meeting?.heureFin? (meeting.heureFin|momentFormat:'HH[:]mm') : 'noEndDate'|translate}}</span>
            <i *ngIf="allowedToEditSeance.edit" class="icon-edit blue-icon ml-2 infos_title__iconEdit"></i>
        </div>
        <div [ngClass]="{'infos_title':allowedToEditSeance.edit, 'info_title--noEdit':!allowedToEditSeance.edit}" (click)="openModifyMeetingModal()">
            <i class="fas fa-globe fa-2x ml-5 mr-3"></i>
            <span class="sectionInfosTop__span">{{meeting?.lieuxName ? meeting.lieuxName : translate.instant('noPlaceForThisMeeting')}}</span>
            <i *ngIf="allowedToEditSeance.edit" class="icon-edit blue-icon ml-2 infos_title__iconEdit"></i>
        </div>
    </div> -->
  <div>
    <div class="row justify-content-between align-items-center mt-4 ml-2 mr-2 participantTable">
      <span class="participantTable__title"> {{ 'meetingParticipants' | translate }}</span>
      <super-button
        *ngIf="allowedToEditSeance.distribution.addUser"
        [text]="'btn.add' | translate"
        styleClass="green"
        iconPosition="left"
        icon="bi bi-plus-circle-fill"
        (click)="addParticipants()"></super-button>
    </div>
    <super-table
      (onClickRow)="modifyParticipant($event)"
      (onClickDelete)="deleteParticipants($event)"
      [items]="meeting.participants"
      [columns]="participantsColumns"
      id="super-table-of-participant"
      [height]="'calc( 100vh - 340px)'"
      [multiselect]="true"
      [menu]="menu"></super-table>
  </div>
</section>
<p-confirmDialog
  key="infoMeetingConfirm"
  #cd
  appendTo="body"
  styleClass="super-compos-modal-content confirmModal"
  header="Confirmation"
  icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Non" (click)="cd.reject()"></button>
    <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="Oui" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>
