export class UserInfo {
    public guid?: string;  //   IConnect user ID
    public id: number; // Internal icourrier user ID
    public userName?: string; // Internal icourrier userName
    public contactId: number; // User contact ID
    public fullView: boolean; // Indicates wether the connected user has full view mails
    public services: number[]; // User services IDs
    public serviceExploses: number[];
    public encodageNouveauSortant:boolean;
    public encodageNouveauEntrant:boolean;
    public encodageToutService:boolean;
    public hasMultipleUsernames:boolean;
    constructor(data:any) {
        Object.assign(this, data);
    }
}
