export class TreeData {
  data: any;
  children: Array<any> = [];

  constructor(data: any) {
    if (data) {
      this.data = data;
      this.children = data.childrens;
    }
  }
}
