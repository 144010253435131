<div class="content-connections">
  <h4>{{'listOfMailsLinked'|translate}}</h4>
  <i-table
    [id]="'id_table_connections_iCourrier'"
    [config]="tableConfigurations" 
    [items]="items"
    [columns]="columns"
  ></i-table>
</div>

<p-confirmDialog
  key="confirmDeleteLink"
  #cd
  appendTo="body"
  styleClass="super-compos-modal-content confirmModal"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  baseZIndex="1000">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" [label]="'No' | translate" (click)="cd.reject()"></button>
    <button
      class="pButtonRed"
      type="button"
      pButton
      icon="pi pi-check"
      [label]="'Yes' | translate"
      (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>