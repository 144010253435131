import * as moment from 'moment';
import { Global_Class } from './global';

export class Meeting {
     date:Date;
     public description:string;
     heureDebut: string;
     heureFin: string;
     id:number;
     lieu: string;
     lieuxId: number;
     lieuId: number;
     lieuxName: string;
     reunionFixeeLe: string
     reunionFixeePar: string;
     seanceType:any;
     supprime:boolean;
     typeSeanceId:number;
     urlStreaming: string;
     participants : any
     public status:string;
     public rowClass:string;
     public seanceTypeName:string;
     public foreColor:string;
     public cloture : boolean;
     public verrouille:boolean;
     public borderClass: string;

     constructor(data:any, globalElements?:Global_Class) {
         Object.assign(this, data);
          if(this.cloture){
               this.status = 'closed';
               this.rowClass= 'status-table-red';
               this.borderClass = 'red';
          }else if( this.verrouille){
               this.status = 'pending';
               this.rowClass= 'status-table-orange';
               this.borderClass = 'orange';
          }else{
               this.status = 'open';
               this.rowClass = 'status-table-green';
               this.borderClass = 'green';
          }
          if( this.seanceType ){
               this.seanceTypeName = this.seanceType?.description;
          }
          if ( !this.description && this.date ){
               this.description = 'Séance du ' + moment(this.date, 'YYYYMMDD').format('DD/MM/YYYY');
          }
          if ( this.typeSeanceId && globalElements?.types_seances_LV?.length > 0){
               this.seanceTypeName = globalElements.types_seances_LV.find( x => x.value === this.typeSeanceId).label;
          }
          if(!this.date || !moment(this.date).isValid()){
               this.date = null;
          }
          if(!this.heureDebut || !moment(this.heureDebut).isValid()){
               this.heureDebut = null;
          }
          if(!this.heureFin || !moment(this.heureFin).isValid()){
               this.heureFin = null;
          }
          if((this.lieuxId || this.lieuId) && globalElements?.lieux_LV?.length > 0){
               this.lieuxName = globalElements.lieux_LV.find( x => x.value === this.lieuxId || x.value === this.lieuId).label;
          }
          if(!this.lieuxId && !this.lieuId){ this.lieuxName = '';}
     }
}
 