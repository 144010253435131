<div class="modal-header super-compos-modal-header">
    <h4 class="super-compos-modal-title">
        <span class="modal-title noItalic">Destinataires</span>
    </h4>
    <div class="form-group">
        <div class="d-flex align-items-center">
            <super-button (click)="closeActionModal();" text="Annuler" styleClass="mr-3 blue">
            </super-button>
            <super-button id="buttonAddUsersRecipient" (click)="addUsers();" text="Ajouter {{ contactArray?.length + usersArray?.length + serviceArray?.length + groupsArray?.length + freeRecipientArray?.length }}
        destinataires" styleClass="green">
            </super-button>
        </div>
    </div>

</div>

<div class="modal-body modal-hidden">
    <div class="row" *ngIf="userLoaded === false">
        <div class="col-md-12 text-center">
            <img src="./assets/img/loupe-loader.gif" class="img-fluid">
        </div>
    </div>
    <div *ngIf="userLoaded" class="row">
        <div class="panel-header col-md-12 mb-3">
            <a *ngFor="let tabItem of tabItems; let i=index ;" (click)="switchTabs(tabItem)" [ngClass]="{'active': tabItem?.value === selectedTabs.value}">
                {{tabItem?.header}}
                <span *ngIf="tabItem.value !== 'free'"
                class="badge badge-pill badge-dark badge-custom">{{ tabItem.valueBadge }}</span>
            </a>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-12" *ngIf="destinatairesNamePresent">
                    <div class="alert alert-primary">
                        Destinataire(s) déjà présent(s) dans la fiche courrier ({{recipientsNames.length}})
                        <div>
                            {{destinatairesNamePresent}}
                        </div>
                    </div>
                </div>
                <section class="panel-content col-md-12">
                    <div *ngIf="selectedTabs.value !== 'free' ">
                        <div class="d-flex align-items-center" [ngClass]="{'justify-content-between' : filteredContacts.length > 0 || filteredUsers.length > 0, 'justify-content-end':filteredGroups.length > 0 ||filteredServices.length > 0}">
                        <!-- <div class="d-flex align-items-center justify-content-between"> -->
                            <!-- <p-selectButton (onChange)="changeValueActive()" [options]="activeStates" [(ngModel)]="activeStateRecipient" *ngIf="filteredContacts.length > 0|| filteredUsers.length > 0"></p-selectButton> -->
                            <super-button id="addContactRecipientModal" text="Ajouter un contact" styleClass="green mb-3" size="small" (click)="openNewContact()"></super-button>
                        </div>
                        <!-- <div class="d-flex align-items-center justify-content-end">
                            <super-button text="Ajouter un contact" styleClass="green mb-3" size="small" (click)="openNewContact()"></super-button>
                        </div> -->
                        <app-super-list-simple *ngIf="selectedTabs.value === 'users'"
                        [items]="filteredUsers" [isAllowedToFilterByStateActive]="true" [typeOfContact]="'user'" id="userTableDestinataire"
                        [destinataire]="true" [height]="'50vh'" [columns]="col" alignLast="right " [boolMultiSelect]="true"
                        (multiSelectValue)="receive_item($event, selectedTabs)" [boolImageMode]="true " [fieldToFilter]="'active'"
                        icon="icon-start-full">
                        </app-super-list-simple>
                        <app-super-list-simple *ngIf="selectedTabs.value === 'groups'"
                        [items]="filteredGroups" [height]="'50vh'" [showGroup]="true" id="groupeTableDestinataire"
                        [destinataire]="true" [columns]="col" alignLast="right " [boolMultiSelect]="true"
                        (multiSelectValue)="receive_item($event, selectedTabs)" [boolImageMode]="true "
                        icon="icon-start-full">
                    </app-super-list-simple>
                    <app-super-list-simple *ngIf="selectedTabs.value === 'contacts'" [isAllowedToFilterByStateActive]="true" [typeOfContact]="'contact'"
                        [items]="filteredContacts" [height]="'50vh'"  [typeContact]="typeContact" id="contactTableDestinataire"
                        [destinataire]="true" [columns]="col" alignLast="right " [boolMultiSelect]="true"
                        (multiSelectValue)="receive_item($event, selectedTabs)" [boolImageMode]="true " [fieldToFilter]="'active'"
                        icon="icon-start-full">
                    </app-super-list-simple>
                        <!-- <app-super-list-simple *ngIf="selectedTabs.value === 'services'"
                            [items]="filteredServices" [height]="'50vh'" [showGroup]="true" id="serviceTableDestinataire"
                            [destinataire]="true" [columns]="col" alignLast="right " [boolMultiSelect]="true"
                            (multiSelectValue)="receive_item($event, selectedTabs)" [boolImageMode]="true "
                            icon="icon-start-full">
                        </app-super-list-simple> -->
                       
                        
                    </div>
        
                    <div class="py-2" *ngIf="selectedTabs.value==='free'">
        
                        <label for="addFreeRecipientInput " class="super-compos-section-h4 ml-2 ">Destinataire libre</label>
                        <div class="alert alert-primary">
                            Encodez les destinataires séparées par un point virgule.
                        </div>
                        <div class="row">
                            <div class="col-md-12 mb-4">
                                <textarea id="textareaFreeRecipient" class="super-compos-default-btn-noshadow form-control" [(ngModel)]="freeRecipient"></textarea>
                            </div>
                            <div class="col-md-12 d-flex align-items-center justify-content-center">
                                <super-button id="buttonValidateAddFreeRecipient" styleClass="blue" text="Valider les destinataires" (click)="addRecipientFree(this)"></super-button>
                            </div>
        
                        </div>
                        <app-super-list-simple *ngIf="tabNewRecipient && tabNewRecipient.length> 0"
                            [items]="tabNewRecipient | sort:'name':'ASC' " [columns]="col " alignLast="right "
                            [boolShowHeader]="false " [click]="test2 " [boolMultiSelect]="true "
                            (multiSelectValue)="receive_item($event,selectedTabs) " [boolImageMode]="true "
                            icon="icon-start-full" [deletable]="true">
                        </app-super-list-simple>
        
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
