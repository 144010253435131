import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'underscore';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'toFixed',
})
export class toFixedFormat implements PipeTransform {
  transform(value: string, number: number) {
    if (_.isNumber(value)) return Number(value).toFixed(number);
    else return '';
  }
}

@Pipe({
  name: 'momentFormat',
})
export class momentFormat implements PipeTransform {
  transform(value: string, field: string) {
    if (value) return moment(value).format(field);
    else return '';
  }
}

@Pipe({
  name: 'momentFormatTranslation',
})
export class momentFormatTranslation implements PipeTransform {
  constructor(private translation: TranslateService) {}
  transform(value: string) {
    const translate = this.translation.instant('format.formattedDate');
    return moment(value).format(translate);
  }
}

@Pipe({
  name: 'sizeFormat',
})
export class sizeFormat implements PipeTransform {
  transform(fileSizeInBytes: number) {
    let i = -1;
    const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
      fileSizeInBytes = fileSizeInBytes / 1024;
      i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  }
}

@Pipe({
  name: 'maxLength',
})
export class lengthFormat implements PipeTransform {
  transform(value: string, number: number) {
    if (value && value.length > number) return value.substr(0, number) + '...';
    else return value;
  }
}

@Pipe({
  name: 'ucFirst',
})
export class ucFirst implements PipeTransform {
  constructor() {}
  transform(string: string) {
    if (string) {
      return String(string).charAt(0).toUpperCase() + String(string).slice(1).toLowerCase();
    } else {
      return '';
    }
  }
}

@Pipe({
  name: 'join',
})
export class Join implements PipeTransform {
  constructor() {}
  transform(array: any[]) {
    const temp = [];
    if (array.length > 0) {
      array.map((val) => {
        if (val) {
          temp.push(val.name || val);
        }
      });
      return temp.join(',');
    }
  }
}

@Pipe({
  name: 'safeHtml',
})
export class SafeHtml implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(html) {
    let toReturn = null;
    const doc = new DOMParser().parseFromString(html, 'text/html');
    if (doc) {
      toReturn = doc.getElementsByTagName('body')[0].innerHTML;
      return this.sanitizer.bypassSecurityTrustHtml(toReturn);
    }
  }
}

@Pipe({ name: 'label_by_id' })
// | label_by_id:value
// key = la clé de l'objet qui définit la date minimum
// value est la valeur de comparaison
// Si object[key] >= maxDate, on l'enleve de l'array
export class LabelByIdFilter implements PipeTransform {
  transform(array: any[], value: number): string {
    let title = ''; // Si on a pas un array valable, alors ca ne vaut pas la peine;
    if (value !== null) {
      array.map((x) => {
        if (+value === +x['value'] || +value === +x['id']) {
          title = x['label'];
          console;
        }
      });
      return title;
    } else {
      return '/';
    }
  }
}

@Pipe({ name: 'label_by_array' })
// | label_by_id:value
// key = la clé de l'objet qui définit la date minimum
// value est la valeur de comparaison
// Si object[key] >= maxDate, on l'enleve de l'array
export class LabelByArrayFilter implements PipeTransform {
  transform(array: any[], value: Array<any>): string {
    const temp = [];
    if (value.length > 0) {
      array.map((x) => {
        value.map((val) => {
          if (+val === +x['value']) {
            temp.push(x['label']);
          }
        });
      });
      return temp.join(', ');
    } else {
      return '/';
    }
  }
}

@Pipe({
  name: 'triCheckBox',
})
export class TriCheckBoxPipe implements PipeTransform {
  transform(value: boolean, ...args: unknown[]): unknown {
    if (value === true) {
      return 'Oui';
    } else if (value === false) {
      return 'Non';
    } else return 'Non défini';
  }
}

@Pipe({
  name: 'oppositeColor',
})
export class OppositeColor implements PipeTransform {
  transform(actualColor: string): string {
    if (actualColor) {
      const color = actualColor.charAt(0) === '#' ? actualColor.substring(1, 7) : actualColor;
      const r = parseInt(color.substring(0, 2), 16); // hexToR
      const g = parseInt(color.substring(2, 4), 16); // hexToG
      const b = parseInt(color.substring(4, 6), 16); // hexToB
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }
  }
}

@Pipe({
  name: 'toLV',
})
export class toLVFormat implements PipeTransform {
  transform(array: Array<any>, labelKey: string = 'label', valueKey: string = 'id') {
    return array.map((x) => {
      return {
        label: x[labelKey],
        value: x[valueKey],
      };
    });
  }
}
@Pipe({
  name: 'hundredPoint'
})
export class HundredPointPipe implements PipeTransform {
  transform(value: number): string {
    let valueStr = value.toString();
    if (valueStr.length > 3) {
      valueStr = valueStr.slice(0, valueStr.length - 3) + '.' + valueStr.slice(valueStr.length - 3);
    }
    return valueStr;
  }
}