import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { DataService } from '../services/data.service';
import { DialogService } from 'primeng/dynamicdialog';
import { GuardConfirmModalComponent } from 'src/app/shared/components/modals/guard-confirm-modal/guard-confirm-modal.component';
import { ApiService } from '../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDeactivateGuard implements CanDeactivate<any> {
  constructor(public dialogService: DialogService, public dataService: DataService, public api: ApiService, public translate: TranslateService, private toastr: ToastrService) { }

  canDeactivate(component: any): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.dataService.checkIfUnsavedChanges === true) {
        const ref = this.dialogService.open(GuardConfirmModalComponent, {
          header: 'Confirmation',
          styleClass: 'super-compos-modal-content confirm-inforius',
          dismissableMask: false,
          width: '30em',
          data: {
            message: 'Etes-vous sûr de vouloir quitter sans sauver vos changements ?',
          },
        });
        ref.onClose.subscribe((value) => {
          if (value) {
            if (value === true) {
              this.dataService.checkIfUnsavedChanges = false;
              this.api.ReleaseWriteLock(component.pointId).subscribe((res: any) => {
                if (res) {
                  this.toastr.info(this.translate.instant('info.pointUnlocked'));
                }
              }, (error) => {
                this.toastr.error(this.translate.instant('errorOccurred'));
              });
              resolve(true);
            } else {
              resolve(false);
            }
          }
        });
      } else {
        resolve(true);
      }
    });
  }
}
