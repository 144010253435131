import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/core/services/api.service';
import { Meeting } from 'src/app/shared/models/meeting';
import * as async from 'async';
import * as moment from 'moment';
import { iAdminService } from 'src/app/core/services/iAdmin.service';

@Component({
  selector: 'app-infos-meeting-edit',
  templateUrl: './infos-meeting-edit.component.html',
  styleUrls: ['./infos-meeting-edit.component.scss'],
})
export class InfosMeetingEditComponent implements OnInit {
  public header = '';
  public meeting: Meeting = null;
  public adressList = [];

  public form: FormGroup = new FormGroup({
    description: new FormControl(),
    date: new FormControl(),
    heureDebut: new FormControl(),
    heureFin: new FormControl(),
    reunionFixeeLe: new FormControl(),
    reunionFixeePar: new FormControl(),
    lieuxId: new FormControl(),
    urlStreaming: new FormControl(),
  });

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public translate: TranslateService,
    public api: ApiService,
    public apiAdmin: iAdminService
  ) {}

  ngOnInit(): void {
    async.waterfall([
      (callback) => {
        this.header = this.config.data.header;
        this.meeting = this.config.data.meeting;
        callback();
      },
      (callback) => {
        this.api.getGlobalLieux().subscribe((res: any) => {
          this.adressList = res;
          callback();
        });
      },
      (callback) => {
        this.form.patchValue({
          description: this.meeting.description,
          date: this.meeting.date ? moment(this.meeting.date).toDate() : null,
          heureDebut: {
            hour: this.meeting.heureDebut ? new Date(this.meeting.heureDebut).getHours() : null,
            minute: this.meeting.heureDebut ? new Date(this.meeting.heureDebut).getMinutes() : null,
          },
          heureFin: {
            hour: this.meeting.heureFin ? new Date(this.meeting.heureFin).getHours() : null,
            minute: this.meeting.heureFin ? new Date(this.meeting.heureFin).getMinutes() : null,
          },
          reunionFixeeLe: this.meeting.reunionFixeeLe ? moment(this.meeting.reunionFixeeLe).toDate() : null,
          reunionFixeePar: this.meeting.reunionFixeePar,
          lieuxId: this.meeting.lieuxId,
          urlStreaming: this.meeting.urlStreaming,
        });
        callback();
      },
    ]);
  }

  submitModificationMeeting() {
    const data = {
      heureDebut: null,
      heureFin: null,
      description: this.form.value.description,
      date: this.form.value.date,
      reunionFixeeLe: this.form.value.reunionFixeeLe,
      reunionFixeePar: this.form.value.reunionFixeePar,
      lieuxId: this.form.value.lieuxId,
      urlStreaming: this.form.value.urlStreaming,
    };
    const dataTarget1 = new Date();
    const dataTarget2 = new Date();
    async.waterfall([
      (cb) => {
        if (this.form.value.heureDebut && (this.form.value.heureDebut.hour || this.form.value.heureDebut.minute)) {
          const heureDebut = this.form.value.heureDebut;
          dataTarget1.setHours(heureDebut.hour);
          dataTarget1.setMinutes(heureDebut.minute);
          data.heureDebut = moment(dataTarget1).format('YYYY-MM-DDTHH:mm:ss');
        } else {
          data.heureDebut = null;
        }
        cb(null);
      },
      (cb) => {
        if (this.form.value.heureFin && (this.form.value.heureFin.hour || this.form.value.heureFin.minute)) {
          const heureFin = this.form.value.heureFin;
          dataTarget2.setHours(heureFin.hour);
          dataTarget2.setMinutes(heureFin.minute);
          data.heureFin = moment(dataTarget2).format('YYYY-MM-DDTHH:mm:ss');
        } else {
          data.heureFin = null;
        }
        cb(null);
      },
      (cb) => {
        this.ref.close(data);
      },
    ]);
  }
}
