import { Component, ElementRef, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'idelibebackoffice';

  constructor(
    private translate: TranslateService,
    private config: PrimeNGConfig,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    /** SET TRANSLATE CONFIG */
    translate.addLangs(['fr', 'en', 'nl']);
    if (localStorage.getItem('lang') === null) {
      const userLang = navigator.language.split('-').reverse()[0].toLowerCase();
      if (userLang === 'fr' || userLang === 'en' || userLang === 'nl') {
        localStorage.setItem('lang', userLang);
      } else {
        localStorage.setItem('lang', 'fr');
      }
    }

    const langage = localStorage.getItem('lang');
    translate.use(langage);
    translate.setDefaultLang(langage);

    // translation of prime ng
    translate.get('primeng').subscribe((res) => this.config.setTranslation(res));

    moment.locale(this.translate.getDefaultLang().toLowerCase());
  }
  ngOnInit() {
    const loader = this.el.nativeElement.parentElement.querySelector('.loaderPage');
    if (loader) {
      this.renderer.setStyle(loader, 'display', 'none');
    }
  }
}
