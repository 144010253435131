import { Component, OnInit } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-validators',
  templateUrl: './validators.component.html',
  styleUrls: ['./validators.component.scss'],
})
export class ValidatorsComponent implements OnInit {
  constructor() {}

  public static VAT(countries: any): ValidatorFn {
    const res = (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      const varRE = /(DE\s*\d{9})|(AT\s*U\d{8})|(BE\s*0\d{9})|(BG\s*\d{9-10})|(LU\s*\d{8})/;
      if (input && !varRE.test(input)) {
        return { VAT: { Message: 'Le Code TVA doit être sous la forme [Code Pays] [8 à 9 chiffres selon le pays]' } };
      } else {
        // if (input) {
        // 	const correctedinput = (<string>input).replace(' ', '');
        // 	if (correctedinput !== input) { control.setValue(correctedinput); }
        // }
        return null;
      }
    };
    return res;
  }

  public static URL(url: any): ValidatorFn {
    const res = (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      const varRE =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      // const varRE = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
      if (input && !varRE.test(input)) {
        return { URL: { xx: 'test' } };
      } else {
        if (input) {
          const correctedinput = (input as string).replace(' ', '');
          if (correctedinput !== input) {
            control.setValue(correctedinput);
          }
        }
        return null;
      }
    };
    return res;
  }

  public static PHONE(number: any): ValidatorFn {
    const res = (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      const varRE = /^\+(?:[0-9]\/?){6,14}[0-9]$/;
      if (input && !varRE.test(input)) {
        return { PHONE: { xx: 'test' } };
      } else {
        if (input) {
          const correctedinput = (input as string).replace(' ', '');
          if (correctedinput !== input) {
            control.setValue(correctedinput);
          }
        }
        return null;
      }
    };
    return res;
  }
  public static Login(string: any): ValidatorFn {
    const res = (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      const login = /^[a-z0-9-._@+]+$/i;
      if (input && !login.test(input)) {
        return { LOGIN: { Message: 'Le format du login est incorrect' } };
      } else {
        if (input) {
          const correctedinput = (input as string).replace(' ', '');
          if (correctedinput !== input) {
            control.setValue(correctedinput);
          }
        }
        return null;
      }
    };
    return res;
  }

  ngOnInit(): void {}
}
