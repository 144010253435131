<i-modal
  [visible]="openEditDescription"
  [modalParameters]="modalParams"
  [header]="modalHeader"
  class="modal-actions"
  (emitterOnVisibleChange)="closeDialog()">
  <super-tabs class="d-flex justify-content-center mb-3" [items]="tabs" (slug)="tabChange($event)"></super-tabs>

    <ng-container *ngIf="activeTab === 0">
      <div class="divForAbsolute w-full mr-2 mt-3">
        <span class="d-flex align-items-center p-input-icon-left w-100">
          <i class="pi pi-search"></i>
          <input
            type="text"
            class="form-control super-compos-search-field pr-5 pl-5 w-100 advSearch__field"
            [placeholder]="'Recherche' | translate"
            [(ngModel)]="pointSearch"
            (keydown.enter)="searchPoint('Point')"
            (focus)="onFocusInput = true"
            (blur)="onFocusInput = false"/>
        </span>
        <div class="buttonAbsolute">
          <div class="blockButtonDropDown">
            <super-button
              #searchButton
              [text]="dropdownLabel"
              icon="bi bi-arrows-vertical"
              iconPosition="right"
              styleClass="gray tac-nowrap overwirteClassButton advSearch__btn tac-dropdown-search dropdownPosition marginForAbsolute"
              (click)="showOverlayDropdown = !showOverlayDropdown; onFocusInput = !onFocusInput">
            </super-button>
            <div
              class="dropdown-menu dropdownPosition"
              [ngClass]="{ 'd-block': showOverlayDropdown }"
              data-bs-auto-close="outside">
              <a
                class="dropdown-item"
                data-bs-auto-close="outside"
                *ngIf="dropdownValue !== 'Titre'"
                (click)="onClickDropdownInput('Titre')">
                <div class="d-flex align-items-center">
                  <span>Titre</span>
                </div>
              </a>
              <a
                class="dropdown-item"
                data-bs-auto-close="outside"
                *ngIf="dropdownValue !== 'Id'"
                (click)="onClickDropdownInput('Id')">
                <div class="d-flex align-items-center">
                  <span>Id</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    
      <i-table
        *ngIf="activeTab === 0 && tableConfigurations"
        [id]="'iscan-search-point'"
        [config]="tableConfigurations"
        [items]="itemsPoint"
        [columns]="columnsPoint"
        [customActionManualReset]="true"
        [loading]="loadingPointTable"
        (pageChange)="onLazyLoad($event, 'Point')"
        (onSelectedRow)="selectData($event)">
      </i-table>
    </ng-container>
    <ng-container *ngIf="activeTab === 1">
      <div class="mr-2 mt-3 calendar">
        <p-calendar 
          [(ngModel)]="dateSelected" 
          dateFormat="dd/mm/yy" 
          inputId="dateformat"
          styleClass="w-100"
          appendTo="body"
          firstDayOfWeek="1"
          (onSelect)="searchPoint('Séance')"
        ></p-calendar>
      </div>

      <i-table
        *ngIf="activeTab === 1 && tableConfigurations"
        [id]="'iscan-search-meeting'"
        [config]="tableConfigurations"
        [items]="itemsMeeting"
        [columns]="columnsMeeting"
        [customActionManualReset]="true"
        [loading]="loadingMeetingTable"
        (pageChange)="onLazyLoad($event, 'Séance')"
        (onSelectedRow)="selectData($event)">
      </i-table>
    </ng-container>
</i-modal>
