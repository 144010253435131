import { Component, HostListener, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ApiService } from 'src/app/core/services/api.service';
import { Button } from 'src/app/shared/models/button';
import { Point } from 'src/app/shared/models/point';
import * as async from 'async';
import { Meeting } from 'src/app/shared/models/meeting';
import { DataService } from 'src/app/core/services/data.service';
import { Global_Class } from 'src/app/shared/models/global';
import { DialogService } from 'primeng/dynamicdialog';
import { StatusChangeComponent } from 'src/app/shared/components/modals/status-change/status-change.component';
import { SuperTableComponent, SuperTableTreeComponent } from '@devinforius/super-compos';
import { File } from 'src/app/shared/models/files';
import { Historique } from 'src/app/shared/models/historique';
import { ConfirmationService } from 'primeng/api';
import { ParticipantsChangeComponent } from 'src/app/shared/components/modals/participants-change/participants-change.component';
import { ToastrService } from 'ngx-toastr';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { EventAddComponent } from 'src/app/shared/components/modals/event-add/event-add.component';
import { NgxSpinnerService } from 'ngx-spinner';
import 'rxjs/operators/map';
import * as _ from 'underscore';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { Contact } from 'src/app/shared/models/iContact/contact';
import { Destinataire } from 'src/app/shared/models/destinataire';
import { Participant } from 'src/app/shared/models/participant';
import { MarktocorrectionComponent } from 'src/app/shared/components/modals/marktocorrection/marktocorrection.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LabelValue } from 'src/app/shared/models/labelvalue';
import { UserRightsService } from 'src/app/core/services/user-rights.service';
import { saveAs } from 'file-saver';
import { ExportDelibeMandataires } from 'src/app/shared/models/export/export-delibe-mandataires';
import { ExportDelibeMandatairesListDatas } from 'src/app/shared/models/export/export-delibe-mandataire-list-datas';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Title } from '@angular/platform-browser';
import { SignalrService } from 'src/app/core/services/signalr.service';
import {
  NotifyUserSignalrPayload,
  SignalrDocumentMergeCompletedData,
  SignalrPayloadEventId,
} from 'src/app/shared/models/signalr_payloads';
import { Subscription } from 'rxjs';
import { TreeData } from 'src/app/shared/models/treedata';
import { TableWithInputComponent } from 'src/app/shared/components/table-with-input/table-with-input.component';
import { NiveauIntroConcluTitre } from 'src/app/shared/models/niveau_intro_conclusion_titre';
import { SeancePointInclusNiveauType } from 'src/app/shared/models/tree-type';
import { DuplicationPointComponent } from 'src/app/shared/components/modals/duplication/duplication-point.component';
import { PointReportComponent } from 'src/app/shared/components/modals/point-report/point-report.component';
import { GenerateDocLotComponent } from 'src/app/shared/components/modals/generate-doc-lot/generate-doc-lot.component';
import { DetailsService } from '../services/details.service';
import { Location } from '@angular/common';
import { SignFileComponent } from 'src/app/shared/components/signFile/sign-file.component';
import { ActionType } from 'src/app/shared/models/point/category-actions.model';
import { formatDate } from 'src/app/shared/utils/date.utils';

@Component({
  selector: 'app-meeting-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit, OnDestroy {
  //#region ViewChild of the 4  tables (each table in one tab) => points linked; points not linked; files and historic
  @ViewChild('superTablePointsNot') public superTablePointsNotLinkedList: SuperTableComponent;
  @ViewChild('superTableFiles') public superTableFiles: SuperTableComponent;
  @ViewChild('superTableMeetingHistoric') public superTableMeetingHistoric: SuperTableComponent;
  @ViewChild('SuperTreeTable') public SuperTreeTable: SuperTableTreeComponent;
  @ViewChild('SuperTablePointLinked') public SuperTablePointLinked: SuperTableComponent;
  @ViewChild('overlayButton') overlayButton: OverlayPanel;
  @ViewChild('overlayButtonExportMandataire') overlayButtonExportMandataire: OverlayPanel;
  @ViewChild('overlayButtonExportCitoyen') overlayButtonExportCitoyen: OverlayPanel;
  //#endregion

  //#region columns of each table
  public columns: Array<any> = [
    {
      field: 'icon',
      header: '',
      width: '30px',
      maxWidth: '30px',
      export: (value, item) => {
        if (item && item.objetSynthese) {
          return item.objetSynthese;
        }
      },
      format: (value, item) => {
        let icons = '';
        item.verrouille
          ? (icons = icons + '<i class="bi bi-lock-fill tac-status-icon mr-2"></i>')
          : (icons = icons + '');
        item.enCorrection ? (icons = icons + '<i class="bi bi-pen tac-status-icon mr-2"></i>') : (icons = icons);
        item.cloture === true
          ? (icons = '<i class="bi bi bi-check-circle-fill tac-status-icon mr-2"></i>')
          : (icons = icons);
        item.confidentielLabel == '<i class="icon-lock mr-2"></i>'
          ? (icons = icons + '<i class="bi bi-eye-slash-fill tac-status-icon"></i>')
          : (icons = icons);
        if (item.type && item.type === 'event') {
          const Entree = '<i class="bi bi-box-arrow-in-right" style="font-size: 20px;"></i>';
          const Sortie = '<i class="bi bi-box-arrow-right" style="font-size: 20px;"></i>';
          const arrayDetails = [];
          item.details.listForExport = [];
          if (item.details.length > 0) {
            item.details.map((detail) => {
              let participantContactId = null;
              participantContactId = this.participants.find((x) => x.id === detail.participantId);
              let entreeParticipants = null;
              if (participantContactId) {
                //on a contactIdIAdmin => ce sont les contacts iAdmin
                if (participantContactId.contactIdiAdmin) {
                  entreeParticipants = this.contactsAdmin.find((x) => x.id === participantContactId.contactIdiAdmin);
                } else if (participantContactId.contactId) {
                  entreeParticipants = this.contacts.find((x) => x.id === participantContactId.contactId);
                }
                arrayDetails.push(detail.entreeSortie === 'E' ? Entree : Sortie);
                icons = icons + arrayDetails;
              } else {
              }
            });
          } else {
            icons = icons + '<i class="bi bi-bell-fill" style="font-size: 20px;"></i>';
          }
        }
        return icons;
      },
      tooltip: (item) => {
        let tooltipStr = '';
        item.verrouille && !item.cloture ? (tooltipStr = tooltipStr + 'Verrouillé ') : (tooltipStr = tooltipStr);
        item.enCorrection ? (tooltipStr = tooltipStr + ' En correction') : (tooltipStr = tooltipStr);
        item.clotureIcon === '✅' ? (tooltipStr = tooltipStr + ' Cloturé') : (tooltipStr = tooltipStr);
        item.confidentielLabel == '<i class="icon-lock mr-2"></i>'
          ? (tooltipStr = tooltipStr + ' Confidentiel')
          : (tooltipStr = tooltipStr);
        return tooltipStr;
      },
    },
    {
      field: 'ordreReelHTML',
      header: '',
      width: 'fit-content',
      maxWidth: '50px',
      justifyContent: 'center',
      export: (value, item) => {
        if (item.type && item.type === 'event') {
          return '🏴';
        } else {
          if (!isNaN(item.ordre) && item.ordre !== null) return item.ordre;
        }
      },
      format: (value, item) => {
        if (item.type && item.type === 'event') {
          const Entree = '<i class="bi bi-box-arrow-in-right" style="font-size: 20px;"></i>';
          const Sortie = '<i class="bi bi-box-arrow-right" style="font-size: 20px;"></i>';
          const arrayDetails = [];
          item.details.listForExport = [];
          if (item.details.length > 0) {
            item.details.map((detail) => {
              let participantContactId = null;
              participantContactId = this.participants.find((x) => x.id === detail.participantId);
              let entreeParticipants = null;
              if (participantContactId) {
                //on a contactIdIAdmin => ce sont les contacts iAdmin
                if (participantContactId.contactIdiAdmin) {
                  entreeParticipants = this.contactsAdmin.find((x) => x.id === participantContactId.contactIdiAdmin);
                } else if (participantContactId.contactId) {
                  entreeParticipants = this.contacts.find((x) => x.id === participantContactId.contactId);
                }
                item.details.listForExport.push(
                  (detail.entreeSortie === 'E' ? '⇥ ' : '⇤ ') +
                    (entreeParticipants ? entreeParticipants?.prenom + ' ' + entreeParticipants?.nom : '')
                );
              }
            });
          }
          if (arrayDetails.length > 0) return '<div class="" colspan="5">' + arrayDetails.join(', ') + '</div>';
        } else {
          if (!isNaN(item.ordre) && item.ordre !== null) {
            item.ordre = item.ordre.toString().padStart(3, '0');
            return (
              '<div align="center"><b style="font-size: 18px;">' +
              item.ordre +
              '</b>' +
              '<br>' +
              item.ordreDocs +
              '</div>'
            );
          }
        }
      },
      tooltip: (item) => {
        if (item.type && item.type === 'event') {
          return 'Événement';
        } else {
          if (!isNaN(item.ordre) && item.ordre !== null)
            return (
              '<b style="font-size: 16px;"> Ordre Réel : ' +
              item.ordre +
              '</b>' +
              '<br>Ordre document : ' +
              item.ordreDocs
            );
        }
      },
    },
    {
      field: 'id',
      header: 'ID',
      filterable: true,
      format: (value, item) => {
        return value.toString();
      }
    },
    {
      field: 'title',
      header: 'Titre',
      width: '400px',
      minWidth: '400px',
      tooltip: (item) => {
        if (item && item.objetSynthese) {
          return item.objetSynthese;
        }
      },
      export: (value, item) => {
        if (item && item.objetSynthese) {
          return item.objetSynthese;
        }
      },
      format: (value, item) => {
        if (item.type && item.type === 'event') {
          const Entree = '<b>ENTRÉE DE </b><br>';
          const Sortie = '<b>SORTIE DE </b><br>';
          const arrayDetails = [];
          item.details.listForExport = [];
          if (item.details.length > 0) {
            item.details.map((detail) => {
              let participantContactId = null;
              participantContactId = this.participants.find((x) => x.id === detail.participantId);
              let entreeParticipants = null;
              if (participantContactId) {
                //on a contactIdIAdmin => ce sont les contacts iAdmin
                if (participantContactId.contactIdiAdmin) {
                  entreeParticipants = this.contactsAdmin.find((x) => x.id === participantContactId.contactIdiAdmin);
                } else if (participantContactId.contactId) {
                  entreeParticipants = this.contacts.find((x) => x.id === participantContactId.contactId);
                }
                arrayDetails.push(
                  (detail.entreeSortie === 'E' ? Entree : Sortie) +
                    (entreeParticipants ? entreeParticipants?.prenom + ' ' + entreeParticipants?.nom : '') +
                    ' '
                );
                item.details.listForExport.push(
                  (detail.entreeSortie === 'E' ? '⇥ ' : '⇤ ') +
                    (entreeParticipants ? entreeParticipants?.prenom + ' ' + entreeParticipants?.nom : '')
                );
              }
            });
          } else {
            return '<i>' + item.objetSynthese + '</i>';
          }
          if (arrayDetails.length > 0) return '<div class="" colspan="5">' + arrayDetails.join(', ') + '</div>';
        } else {
          return item.objetSynthese;
        }
      },
      filterable: true,
    },
    {
      field: 'triService',
      header: 'Passage service',
      filterable: true,
    },
    {
      field: 'entreeMembreParticipant',
      header: 'Entrée / Sortie',
      width: '10%',
      format: (value, item) => {
        if (item.type && item.type === 'event') {
          const Entree = '<i class="icon-entry mr-2 ml-2"></i>';
          const Sortie = '<i class="icon-exit ml-3"></i>';
          const arrayDetails = [];
          item.details.listForExport = [];
          if (item.details.length > 0) {
            item.details.map((detail) => {
              let participantContactId = null;
              participantContactId = this.participants.find((x) => x.id === detail.participantId);
              let entreeParticipants = null;
              if (participantContactId) {
                //on a contactIdIAdmin => ce sont les contacts iAdmin
                if (participantContactId.contactIdiAdmin) {
                  entreeParticipants = this.contactsAdmin.find((x) => x.id === participantContactId.contactIdiAdmin);
                } else if (participantContactId.contactId) {
                  entreeParticipants = this.contacts.find((x) => x.id === participantContactId.contactId);
                }
                arrayDetails.push(
                  (detail.entreeSortie === 'E' ? Entree : Sortie) +
                    (entreeParticipants ? entreeParticipants?.prenom + ' ' + entreeParticipants?.nom : '') +
                    ' '
                );
                item.details.listForExport.push(
                  (detail.entreeSortie === 'E' ? '⇥ ' : '⇤ ') +
                    (entreeParticipants ? entreeParticipants?.prenom + ' ' + entreeParticipants?.nom : '')
                );
              }
            });
          }
          if (arrayDetails.length > 0) return '<div class="" colspan="5">' + arrayDetails.join(', ') + '</div>';
        } else {
          return value;
        }
      },
      tooltip: (item) => {
        if (item.type && item.type === 'event') {
          const Entree = '<i class="icon-entry mr-2 ml-2"></i>';
          const Sortie = '<i class="icon-exit ml-3"></i>';
          const arrayDetails = [];
          if (item.details.length > 0) {
            item.details.map((detail) => {
              let participantContactId = null;
              participantContactId = this.participants.find((x) => x.id === detail.participantId);
              let entreeParticipants = null;
              if (participantContactId) {
                if (participantContactId.contactIdiAdmin) {
                  entreeParticipants = this.contactsAdmin.find((x) => x.id === participantContactId.contactIdiAdmin);
                } else if (participantContactId.contactId) {
                  entreeParticipants = this.contacts.find((x) => x.id === participantContactId.contactId);
                }
              }
              let role = null;
              let signature = null;
              role = this.globalElement.roles_LV.find((x) => x.value === detail.roleId);
              signature = this.globalElement.signatures_LV.find((x) => x.value === detail.signatureId);
              arrayDetails.push(
                (detail.entreeSortie === 'E' ? Entree : Sortie) +
                  (entreeParticipants ? entreeParticipants.prenom + ' ' + entreeParticipants.nom : '') +
                  ' avec le rôle ' +
                  (role ? role?.label : '/') +
                  ' et la signature ' +
                  (signature ? signature?.label : '/')
              );
            });
          }
          if (arrayDetails.length > 0) return arrayDetails.join(', ');
        }
      },
      export: (value, item) => {
        if (item.type && item.type === 'event') {
          return item.details.listForExport.join(', ');
        }
      },
      filterable: true,
    },
    {
      field: 'observationSummary',
      header: 'Observations',
      format: (value, item) => {
        if (value) {
          return value;
        }
      },
      filterable: true,
    },
    {
      field: 'pointVotesHTML',
      header: 'Votes',
      width: '120px',
      maxWidth: '120px',
      filterable: true,
    },
    {
      field: 'serviceLabel',
      header: 'Service',
      format: (value, item) => {
        if (item?.type !== 'event' && !item.isConclusion && !item.isIntroduction && !item.isTitle) {
          if (item.serviceId && this.globalElement?.services_LV) {
            const service = this.globalElement.services_LV.find((x) => x.value === item.serviceId);
            if (service) {
              return service?.label;
            } else {
              return '/';
            }
          }
        }
      },
      export: (value) => {
        return value;
      },
      filterable: true,
    },
    {
      field: 'classementLabel',
      header: 'Classement',
      visible: false,
      export: (value) => {
        return value;
      },
      filterable: true,
    },
    {
      field: 'dossierClassementLabel',
      header: 'Dossier',
      visible: false,
      format: (value, item) => {
        if (item?.type !== 'event' && !item.isConclusion && !item.isIntroduction && !item.isTitle) {
          if (value && this.globalElement?.classeurs_LV) {
            const element = this.globalElement.classeurs_LV.find((x) => x.value === value);
            if (element) {
              return element?.label;
            } else {
              return '/';
            }
          }
        }
      },
      export: (value) => {
        return value;
      },
      filterable: true,
    },
    {
      field: 'matiere',
      header: 'Matière',
      visible: false,
      export: (value) => {
        return value;
      },
      filterable: true,
    },
    {
      field: 'statusLabel',
      header: 'Statut',
      export: (value) => {
        return value;
      },
      format: (item, value) => {
        return item.type === 'event' ? '' : value.statusLabel;
      },
      filterable: true,
    },
    {
      field: 'typeDecisionLabel',
      header: 'Type de décision',
      export: (value) => {
        return value;
      },
      filterable: true,
    },
    {
      field: 'typePoint',
      header: 'Type',
      visible: false,
      export: (value) => {
        return value;
      },
      filterable: true,
    },
    {
      field: 'creeParContactLabel',
      header: 'Créateur',
      visible: false,
      export: (value) => {
        return value;
      },
      filterable: true,
    },
    {
      field: 'checkRgpd',
      header: 'RGPD',
      visible: false,
      export: (value) => {
        return value;
      },
      filterable: true,
    },
    {
      field: 'courrierId',
      header: 'ID du courrier',
      visible: false,
      export: (value) => {
        return value;
      },
      filterable: true,
    },
    {
      field: 'echevinatLabel',
      header: 'Echevinat',
      visible: false,
      export: (value) => {
        return value;
      },
      filterable: true,
    },
    {
      field: 'noteDeSynthese',
      header: 'Note de synthèse',
      visible: false,
      export: (value) => {
        return value;
      },
      filterable: true,
    },
    {
      field: 'origine',
      header: 'Origine',
      visible: false,
      export: (value) => {
        return value;
      },
      filterable: true,
    },
  ];

  public columnsTree: Array<any> = [
    {
      field: 'statusIcons',
      header: 'Statut',
      type: 'html',
      width: '120px',
      maxWidth: '120px',
      tooltip: (item) => {
        let tooltipStr = '';
        item.verrouille && !item.cloture ? (tooltipStr = tooltipStr + 'Verrouillé ') : (tooltipStr = tooltipStr);
        item.enCorrection ? (tooltipStr = tooltipStr + ' En correction') : (tooltipStr = tooltipStr);
        item.cloture ? (tooltipStr = tooltipStr + ' Cloturé') : (tooltipStr = tooltipStr);
        item.confidentiel ? (tooltipStr = tooltipStr + ' Confidentiel') : (tooltipStr = tooltipStr);
        item.writeLock?.locked ? (tooltipStr = tooltipStr + ' Verrouillage en cours') : (tooltipStr = tooltipStr);
        return tooltipStr;
      },
    },

    {
      field: 'ordreReelHTML',
      header: '',
      width: '40px',
      maxWidth: '40px',
      justifyContent: 'center',
      export: (value, item) => {
        if (item.type && item.type === 'event') {
          return '🏴';
        } else {
          if (!isNaN(item.ordre) && item.ordre !== null) return item.ordre;
        }
      },
      format: (value, item) => {},
      tooltip: (item) => {
        if (item.type && item.type === 'event') {
          return 'Événement';
        } else {
          if (!isNaN(item.ordre) && item.ordre !== null)
            return (
              '<b style="font-size: 16px;"> Ordre Réel : ' +
              item.ordre +
              '</b>' +
              '<br>Ordre document : ' +
              item.ordreDocs
            );
        }
      },
    },
    {
      field: 'id',
      header: 'ID',
      width: '60px',
      maxWidth: '80px',
      justifyContent: 'center',
      format: (value, item) => {
        return item.data.id;
      },
    },

    {
      field: 'title',
      header: 'Titre',
      width: '350px',
      minWidth: '350px',
      export: (value, item) => {
        if (item && item.objetSynthese) {
          return item.objetSynthese;
        }
      },
      format: (value, item) => {
        if (item.type && item.type === 'event') {
          const Entree = '<b>ENTRÉE DE </b><br>';
          const Sortie = '<b>SORTIE DE </b><br>';
          const arrayDetails = [];
          item.details.listForExport = [];
          if (item.details.length > 0) {
            item.details.map((detail) => {
              let participantContactId = null;
              participantContactId = this.participants.find((x) => x.id === detail.participantId);
              let entreeParticipants = null;
              if (participantContactId) {
                //on a contactIdIAdmin => ce sont les contacts iAdmin
                if (participantContactId.contactIdiAdmin) {
                  entreeParticipants = this.contactsAdmin.find((x) => x.id === participantContactId.contactIdiAdmin);
                } else if (participantContactId.contactId) {
                  entreeParticipants = this.contacts.find((x) => x.id === participantContactId.contactId);
                }
                arrayDetails.push(
                  (detail.entreeSortie === 'E' ? Entree : Sortie) +
                    (entreeParticipants ? entreeParticipants?.prenom + ' ' + entreeParticipants?.nom : '') +
                    ' '
                );
                item.details.listForExport.push(
                  (detail.entreeSortie === 'E' ? '⇥ ' : '⇤ ') +
                    (entreeParticipants ? entreeParticipants?.prenom + ' ' + entreeParticipants?.nom : '')
                );
              }
            });
          } else {
            return '<i>' + item + item.objetSynthese + '</i>';
          }
          if (arrayDetails.length > 0) return '<div class="" colspan="5">' + arrayDetails.join(', ') + '</div>';
        } else {
          return item;
        }
      },
    },
    {
      field: 'triService',
      header: 'Passage service',
    },
    {
      field: 'entreeMembreParticipant',
      header: 'Entrée / Sortie',
      width: '10%',
      format: (value, item) => {
        if (item.type && item.type === 'event') {
          const Entree = '<i class="icon-entry mr-2 ml-2"></i>';
          const Sortie = '<i class="icon-exit ml-3"></i>';
          const arrayDetails = [];
          item.details.listForExport = [];
          if (item.details.length > 0) {
            item.details.map((detail) => {
              let participantContactId = null;
              participantContactId = this.participants.find((x) => x.id === detail.participantId);
              let entreeParticipants = null;
              if (participantContactId) {
                //on a contactIdIAdmin => ce sont les contacts iAdmin
                if (participantContactId.contactIdiAdmin) {
                  entreeParticipants = this.contactsAdmin.find((x) => x.id === participantContactId.contactIdiAdmin);
                } else if (participantContactId.contactId) {
                  entreeParticipants = this.contacts.find((x) => x.id === participantContactId.contactId);
                }
                arrayDetails.push(
                  (detail.entreeSortie === 'E' ? Entree : Sortie) +
                    (entreeParticipants ? entreeParticipants?.prenom + ' ' + entreeParticipants?.nom : '') +
                    ' '
                );
                item.details.listForExport.push(
                  (detail.entreeSortie === 'E' ? '⇥ ' : '⇤ ') +
                    (entreeParticipants ? entreeParticipants?.prenom + ' ' + entreeParticipants?.nom : '')
                );
              }
            });
          }
          if (arrayDetails.length > 0) return '<div class="" colspan="5">' + arrayDetails.join(', ') + '</div>';
        } else {
          return value;
        }
      },
      tooltip: (item) => {
        if (item.type && item.type === 'event') {
          const Entree = '<i class="icon-entry mr-2 ml-2"></i>';
          const Sortie = '<i class="icon-exit ml-3"></i>';
          const arrayDetails = [];
          if (item.details.length > 0) {
            item.details.map((detail) => {
              let participantContactId = null;
              participantContactId = this.participants.find((x) => x.id === detail.participantId);
              let entreeParticipants = null;
              if (participantContactId) {
                if (participantContactId.contactIdiAdmin) {
                  entreeParticipants = this.contactsAdmin.find((x) => x.id === participantContactId.contactIdiAdmin);
                } else if (participantContactId.contactId) {
                  entreeParticipants = this.contacts.find((x) => x.id === participantContactId.contactId);
                }
              }
              let role = null;
              let signature = null;
              role = this.globalElement.roles_LV.find((x) => x.value === detail.roleId);
              signature = this.globalElement.signatures_LV.find((x) => x.value === detail.signatureId);
              arrayDetails.push(
                (detail.entreeSortie === 'E' ? Entree : Sortie) +
                  (entreeParticipants ? entreeParticipants.prenom + ' ' + entreeParticipants.nom : '') +
                  ' avec le rôle ' +
                  (role ? role?.label : '/') +
                  ' et la signature ' +
                  (signature ? signature?.label : '/')
              );
            });
          }
          if (arrayDetails.length > 0) return arrayDetails.join(', ');
        }
      },
      export: (value, item) => {
        if (item.type && item.type === 'event') {
          return item.details.listForExport.join(', ');
        }
      },
    },
    {
      field: 'observationSummary',
      header: 'Observations',
      format: (value, item) => {
        if (value) {
          return value;
        }
      },
    },
    {
      field: 'pointVotesHTML',
      header: 'Votes',
      width: '120px',
      maxWidth: '120px',
    },

    {
      field: 'serviceLabel',
      header: 'Service',
      format: (value, item) => {
        if (item?.type !== 'event' && !item.isConclusion && !item.isIntroduction && !item.isTitle) {
          if (item.serviceId && this.globalElement?.services_LV) {
            const service = this.globalElement.services_LV.find((x) => x.value === item.serviceId);
            if (service) {
              return service?.label;
            } else {
              return '/';
            }
          }
        }
      },
      export: (value) => {
        return value;
      },
    },
    {
      field: 'classementLabel',
      header: 'Classement',
      visible: false,
      export: (value) => {
        return value;
      },
    },
    {
      field: 'dossierClassementLabel',
      header: 'Dossier',
      visible: false,
      format: (value, item) => {
        if (item?.type !== 'event' && !item.isConclusion && !item.isIntroduction && !item.isTitle) {
          if (value && this.globalElement?.classeurs_LV) {
            const element = this.globalElement.classeurs_LV.find((x) => x.value === value);
            if (element) {
              return element?.label;
            } else {
              return '/';
            }
          }
        }
      },
      export: (value) => {
        return value;
      },
    },
    {
      field: 'matiere',
      header: 'Matière',
      visible: false,
      export: (value) => {
        return value;
      },
    },
    {
      field: 'statusLabel',
      header: 'Statut',
      export: (value) => {
        return value;
      },
      format: (item, value) => {
        return item.type === 'event' ? '' : value.statusLabel;
      },
    },
    {
      field: 'typeDecisionLabel',
      header: 'Type de décision',
      export: (value) => {
        return value;
      },
    },
    {
      field: 'typePoint',
      header: 'Type',
      visible: false,
      export: (value) => {
        return value;
      },
    },

    {
      field: 'creeParContactLabel',
      header: 'Agent traitant',
      visible: false,
      export: (value) => {
        return value;
      },
    },
    {
      field: 'creeParContactLabel',
      header: 'Créateur',
      visible: false,
      export: (value) => {
        return value;
      },
      filterable: true,
    },
    {
      field: 'checkRgpd',
      header: 'RGPD',
      visible: false,
      export: (value) => {
        return value;
      },
    },
    {
      field: 'courrierId',
      header: 'ID du courrier',
      visible: false,
      export: (value) => {
        return value;
      },
    },
    {
      field: 'echevinatLabel',
      header: 'Echevinat',
      visible: false,
      export: (value) => {
        return value;
      },
    },
    {
      field: 'noteDeSynthese',
      header: 'Note de synthèse',
      visible: false,
      export: (value) => {
        return value;
      },
    },
    {
      field: 'origine',
      header: 'Origine',
      visible: false,
      export: (value) => {
        return value;
      },
    },
  ];

  public columnsPointsNotLinked: Array<any> = [
    {
      field: 'status',
      header: 'Statuts',
      type: 'html',
      minWidth: '40px',
      width: '40px',
      format: (value, item) => {
        let icons = '';
        item.enCorrection ? (icons = icons + '<i class="bi bi-pen tac-status-icon mr-2"></i>') : (icons = icons);
        item.confidentielLabel == '<i class="icon-lock mr-2"></i>'
        ? (icons = icons + '<i class="bi bi-eye-slash-fill tac-status-icon"></i>')
        : (icons = icons);
        if (item.writeLock.locked) {
            icons += '<img src="assets/favicon/editIcon.svg" class="ml-50" alt="Edit Icon">';
          }
        return icons;
      },
      tooltip: (item) => {
        let tooltipStr = '';
        item.verrouille && !item.cloture ? (tooltipStr = tooltipStr + 'Verrouillé ') : (tooltipStr = tooltipStr);
        item.enCorrection ? (tooltipStr = tooltipStr + ' En correction') : (tooltipStr = tooltipStr);
        item.clotureIcon === '✅' ? (tooltipStr = tooltipStr + ' Cloturé') : (tooltipStr = tooltipStr);
        item.confidentielLabel == '<i class="icon-lock mr-2"></i>'
          ? (tooltipStr = tooltipStr + ' Confidentiel')
          : (tooltipStr = tooltipStr);
        return tooltipStr;
      },
    },
    {
      field: 'title',
      header: 'Titre',
      minWidth: '300px',
      width: '300px',
      format: (value, item) => {
        return value;
      },
      tooltip: (item) => {
        return item.title;
      },
      export: (value, item) => {
        return item.title;
      },
    },
    {
      field: 'id',
      header: 'ID',
      width: '60px',
      maxWidth: '80px',
      justifyContent: 'center',
      format: (value, item) => {
        // let valueStr = item.id.toString();
        // if (valueStr.length > 3) {
        //   valueStr = valueStr.slice(0, valueStr.length - 3) + '.' + valueStr.slice(valueStr.length - 3);
        // }
        // return valueStr;
        return value.toString();
      },
    },
    {
      field: 'observationSummary',
      header: 'Observations',
      format: (value, item) => {
        if (value) {
          return value;
        }
      },
    },

    {
      field: 'serviceId',
      header: 'Service',
      type: 'string',
      format: (value) => {
        if (value) {
          const service = this.globalElement.services_LV.find((x) => x.value === value);
          return service?.label;
        }
      },
      export: (value) => {
        return value;
      },
    },
    {
      field: 'classementId',
      header: 'Classement',
      type: 'string',
      visible: false,
      format: (value, item) => {
        if (value && this.globalElement?.classement_code_LV) {
          const element = this.globalElement.classement_code_LV.find((x) => x.value === value);
          if (element) {
            return element?.label;
          } else {
            return '/';
          }
        }
      },
      export: (value) => {
        return value;
      },
    },
    {
      field: 'dossierClassementId',
      header: 'Dossier',
      type: 'string',
      visible: false,
      format: (value, item) => {
        if (value && this.globalElement?.classeurs_LV) {
          const element = this.globalElement.classeurs_LV.find((x) => x.value === value);
          if (element) {
            return element?.label;
          } else {
            return '/';
          }
        }
      },
      export: (value) => {
        return value;
      },
    },
    {
      field: 'matiereId',
      header: 'Matière',
      type: 'string',
      visible: false,
      format: (value, item) => {
        if (value && this.globalElement?.matieres_LV) {
          const element = this.globalElement.matieres_LV.find((x) => x.value === value);
          if (element) {
            return element?.label;
          } else {
            return '/';
          }
        }
      },
      export: (value) => {
        return value;
      },
    },
    {
      field: 'statutIdLabel',
      header: 'Statut',
      type: 'string',
      format: (value, item) => {
        if (item.statutId && this.globalElement?.statuts_points_LV) {
          const element = this.globalElement.statuts_points_LV.find((x) => x.value === item.statutId);
          if (element) {
            return element?.label;
          } else {
            return '/';
          }
        }
      },
      export: (value) => {
        return value;
      },
    },
    {
      field: 'typeDecisionId',
      header: 'Type de décision',
      type: 'string',
      format: (value, item) => {
        if (value && this.globalElement?.decision_type_LV) {
          const element = this.globalElement?.decision_type_LV.find((x) => x.value === value);
          if (element) {
            return element?.label;
          } else {
            return '/';
          }
        }
      },
      export: (value) => {
        return value;
      },
    },
    {
      field: 'typeId',
      header: 'Type',
      type: 'string',
      visible: false,
      format: (value, item) => {
        if (value && this.globalElement?.types_points_LV) {
          const element = this.globalElement.types_points_LV.find((x) => x.value === value);
          if (element) {
            return element?.label;
          } else {
            return '/';
          }
        }
      },
      export: (value) => {
        return value;
      },
    },
    {
      field: 'typeSeanceId',
      header: 'Type séance',
      type: 'string',
      format: (value, item) => {
        if (value && this.globalElement?.types_seances_LV) {
          const element = this.globalElement.types_seances_LV.find((x) => x.value === value);
          if (element) {
            return element?.label;
          } else {
            return '/';
          }
        }
      },
      export: (value) => {
        return value;
      },
    },

    {
      field: 'creeParContactId',
      header: 'Créateur',
      type: 'string',
      visible: false,
      format: (value, item) => {
        if (value && this.contactsAdmin.length > 0) {
          const element = this.contactsAdmin.find((x) => x.id === value);
          if (element) {
            return element?.fullName;
          } else {
            return '/';
          }
        }
      },
      export: (value) => {
        return value;
      },
    },
    {
      field: 'checkRgpd',
      header: 'RGPD',
      visible: false,
      format: (value, item) => {
        return this.showBoolean(value);
      },
      export: (value) => {
        return value;
      },
    },
    {
      field: 'courrierId',
      header: 'ID du courrier',
      visible: false,
      export: (value) => {
        return value;
      },
    },
    {
      field: 'echevinatId',
      header: 'Echevinat',
      type: 'string',
      visible: false,
      format: (value, item) => {
        if (value && this.globalElement?.echevinats_LV) {
          const element = this.globalElement.echevinats_LV.find((x) => x.value === value);
          if (element) {
            return element?.label;
          } else {
            return '/';
          }
        }
      },
      export: (value) => {
        return value;
      },
    },
    {
      field: 'noteDeSynthese',
      header: 'Note de synthèse',
      visible: false,
      export: (value) => {
        return value;
      },
    },
    {
      field: 'origine',
      header: 'Origine',
      visible: false,
      export: (value) => {
        return value;
      },
    },
  ];
  public columnsHistoric: Array<any> = [
    {
      field: 'modifiedTime',
      header: 'Date et heure',
      format: (value) => {
        if (value) {
          return moment(value).format(this.translate.instant('format.formattedDateFull'));
        } else return '/';
      },
    },
    {
      field: 'modifiedBy',
      header: 'Utilisateur',
      type: 'string',
      format: (value) => {
        if (value) {
          const contact = this.contactsAdmin.find((x) => x.id === value);
          if (contact) {
            return (contact.prenom ? contact.prenom + ' ' : '') + (contact.nom ? contact.nom : contact.nom);
          }
        }
      },
    },
    {
      field: 'description',
      header: 'Description',
    },
    {
      field: 'objetId',
      header: 'Point',
      type: 'string',
      format: (value) => {
        if (value) {
          const point = this.points.find((x) => x.id === value);
          if (point) {
            return point.title;
          }
        }
      },
    },
  ];
  public columnsHistoricExportDelibeMandataire: Array<any> = [
    {
      field: 'exportDateTime',
      header: 'Date et heure',
      format: (value) => {
        if (value) {
          return moment(value).format(this.translate.instant('format.formattedDateFull'));
        } else {
          return '/';
        }
      },
    },
    {
      field: 'userName',
      header: 'Exporté par',
    },
    {
      field: 'commentaires',
      header: 'Commentaire',
    },
    {
      field: 'statusLabelTranslated',
      header: 'Statut',
      format: (value, item) => {
        return this.translate.instant(item.statusLabel);
      },
    },
    {
      field: 'errorMessages',
      header: "Type d'erreur(s)",
      format: (value, item) => {
        if (item.jsonData) {
          return item.jsonErrors.join(', ');
        } else {
          return '/';
        }
      },
    },
  ];
  public columnsExportCitoyenPoints: Array<any> = [
    {
      field: 'ordreReel',
      header: 'Ordre Réel',
      width: '10%',
      export: (value, item) => {
        // if (item.type && item.type === "event") {
        //   return "🏴";
        // } else {
        if (!isNaN(item.ordreReel) && item.ordreReel !== null) return item.ordreReel;
        // }
      },
    },
    {
      field: 'ordreDocument',
      header: 'Ordre Document',
    },
    {
      field: 'objetSynthese',
      width: '50%',
      header: 'Titre',
      tooltip: (item) => {
        if (item && item.objetSynthese) {
          return item.objetSynthese;
        }
      },
      export: (value, item) => {
        if (item && item.objetSynthese) {
          return item.objetSynthese;
        }
      },
    },
    {
      field: 'confidentielLabel',
      header: 'Confidentiel',
      format: (value, item) => {
        if (item.confidentiel === true) {
          return '<b style="color: #07E299;"><i class="bi bi-check-circle-fill"></i></b>';
        } else if (item.confidentiel === false) {
          return '<b style="color: #FF5671;"></b>';
        } else if (item.confidentiel === null) {
          return '';
        }
      },
      export: (value, item) => {
        if (item.confidentiel === true) {
          return '<b style="color: #07E299;"><i class="bi bi-check-circle-fill"></i></b>';
        } else if (item.confidentiel === false) {
          return '<b style="color: #FF5671;"><i class="bi bi-x-circle-fill"></i></b>';
        } else if (item.confidentiel === null) {
          return '';
        }
      },
    },
    {
      field: 'typePoint',
      header: 'Type',
      export: (value) => {
        return value;
      },
    },
  ];

  //#endregion

  //#region menus of tables
  public menuPoints: Array<any> = [
    {
      label: 'btn.openPoint',
      command: (data) => {
        this.openPoint(data);
      },
    },
    // {label: 'openPointInBlankTab', command: (data) => {window.open(this.globalConfig.baseUrl + '/private/points/detail/'+ data.id);}},
  ];
  public menuPointsNotLinked: Array<any> = [
    {
      label: 'btn.openPoint',
      command: (data) => {
        this.openPoint(data);
      },
    },
  ];
  public filesMenu: Array<any> = [];
  //#endregion

  //#region items for columns
  public points: Array<Point> = [];
  public notLinkedPoints: Array<any> = [];
  public files: Array<any> = [];
  public meetingHistoric: Array<Historique> = [];
  public selectedElementInTable: any;
  //#endregion

  //#region datas
  public globalConfig = JSON.parse(sessionStorage.getItem('globalConfig'));
  public config;
  public meeting: Meeting;
  public globalElement: Global_Class;
  public pointId: number = null;
  public pointsBackUp: Array<Point> = [];
  public allPoints: Array<Point> = [];
  public contacts: Array<Contact> = [];
  public contactsAdmin: Array<Contact> = [];
  public destinatairesItems: Array<Destinataire> = [];
  public destinataires: Array<Destinataire> = [];
  public participants: Array<Participant> = [];
  public listOfContacts = [];
  public votesParticipants;
  public meetingId;
  public rightsOfSeance;
  public isUserAbleToAddPoint: boolean = false;
  public modelesLV: Array<LabelValue> = [];
  public iAdminUsers; //* Contains all the users from iAdmin

  public saveEventClickForReloadOverlayButton: any;

  public reorganizeChildren: any[] = [];

  public noValuePointsPlannedInMeeting: string;
  public noValueHistoricForMeeting: string;

  //#endregion

  //#region tabs

  public itemsTab: Array<any> = [
    { header: 'pointsInMeetings', count: null, slug: 'pointsInMeetings', icon: 'bi bi-list-ul' },
    { header: 'plannedPointsInMeetings', count: null, slug: 'plannedPointsInMeetings', icon: 'bi bi-hourglass-bottom' },
    { header: 'files', count: null, slug: 'files', icon: 'bi bi-file-earmark-text' },
    { header: 'historic', count: null, slug: 'historic', icon: 'bi bi-clock-history' },
    { header: 'recipients', count: null, slug: 'recipients', icon: 'bi bi-people' },
    { header: 'infos', count: null, slug: 'infos', icon: 'bi bi-info-circle' },
  ];
  public activeTab: string = 'pointsInMeetings';
  public activeIndex: number = 0;
  public count;
  //#endregion

  //#region forselection
  public forSelectionPoints = [];
  public forSelectionPointsTree = [];
  public forSelectionPointsNotLinked = [];
  public actions: Array<any> = [];
  public selectionsItems: Array<any> = [];
  //#endregion

  //#region added points => title, conclusion and intro'
  public titreTree = {
    data: {
      niveauDescription: 'Titre',
      ordreAffichage: 2,
      isTitle: true,
      meetingId: null,
    },
  };
  public introductionTree = {
    data: {
      niveauDescription: 'Introduction',
      ordreAffichage: 1,
      isIntroduction: true,
      meetingId: null,
    },
  };
  public conclusionTree = {
    data: {
      niveauDescription: 'Conclusion',
      ordreAffichage: 999999999,
      isConclusion: true,
      meetingId: null,
    },
  };
  public titre = {
    ordreReel: '',
    objetSynthese: 'Titre',
    isTitle: true,
    service: null,
    confidentiel: null,
    rowClass: 'hide-menu-option',
    meetingId: null,
  };
  public introduction = {
    ordreReel: '',
    objetSynthese: 'Introduction',
    isIntroduction: true,
    service: null,
    confidentiel: null,
    rowClass: 'hide-menu-option',
    meetingId: null,
  };
  public conclusion = {
    ordreReel: '',
    objetSynthese: 'Conclusion',
    isConclusion: true,
    service: null,
    confidentiel: null,
    rowClass: 'hide-menu-option',
    meetingId: null,
  };
  //#endregion

  //#region vars used to display the right component + inside those comp'
  public displayFichePoint: boolean = false;
  public displayRTFEditor: boolean = false;
  public displayInfos: boolean = false;
  public titleIntroConclusionType: string = null;
  private subscription: Subscription;
  //#endregion

  //#region Reorder
  public isReordering = false; //* Boolean that serves to notice of the table is getting reordered or not
  public temporaryPointsRow = null; //* will be used to temp stock the point reorder
  //#endregion

  //#region Disposition for screen side by side or table
  public justifyOptions: Array<any> = [
    { icon: 'bi bi-layout-sidebar-inset', value: 'side-by-side' },
    { icon: 'bi bi-list', value: 'table' },
  ];
  public currentDisposition: string = 'side-by-side';
  public isSmallScreen = false;
  //#endregion
  //#region forms
  public formFilesModification: FormGroup = new FormGroup({
    id: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    annexeNumero: new FormControl(),
    fileType: new FormControl(),
    visibleDelibeWeb: new FormControl(),
    visibleDelibeWebCitoyen: new FormControl(),
  });
  public form: FormGroup = new FormGroup({
    modeleId: new FormControl(null),
    updateBefore: new FormControl(false),
    regenerateMode: new FormControl(null),
  });
  public formExportMandataire = new FormGroup({
    commentaires: new FormControl(),
  });
  public formExportCitoyen = new FormGroup({
    commentaires: new FormControl(),
    points: new FormControl(null),
  });
  //#endregion

  //#region files
  public fileUploadChooseLabel;
  public fileUploadUploadLabel;
  public fileUploadCancelLabel;
  public fileClicked;
  public selectedFile;
  public openModification; //*Opens the modal for file modification
  //#endregion

  //#region document generation
  public documentAlreadyInList: boolean = false; //* var used to know if the document is already present in the list
  public statusDocumentsGlobal: Array<LabelValue> = []; //*Status for document generation
  public showRegenerateModal: boolean = false; //* Boolean to display the modal
  public dropdownDisabled: boolean = false; //* readonly for the dropdown of documents
  public showDocumentOverlay: boolean = false; //*Displaying the menu of Documents
  public documentsOverlayItems: Array<any> = []; //* Items of the overlay
  public actualDocumentInModal: any; //* Item clicked on when opening the modal for regeneration, modif,..
  //#endregion

  //#region export mandataire
  public showExportOverlay; //* displays the menu for export mandataire
  public exportItems: ExportDelibeMandataires = null; //* Items to display in the export menu
  public exportItemsHistoric: Array<ExportDelibeMandatairesListDatas> = []; //* historic of exports in delibe Mandataire
  public displayModalExportMandataireConfirm: boolean = false; //* Will display the modal to add a comment for the export
  public displayModalExportMandataireHistoric: boolean = false; //* Will display the modal to view historic inside a super-table
  public statusExportMandataire; //* list of statuts for the export Mandataire

  //#region export citoyen
  public showExportOverlayCitoyen; //* displays the menu for export citoyen
  public exportItemsCitoyen: ExportDelibeMandataires = null; //* Items to display in the export menu
  public exportItemsHistoricCitoyen: Array<ExportDelibeMandatairesListDatas> = []; //* historic of exports in delibe Citoyen
  public displayModalExportCitoyenConfirm: boolean = false; //* Will display the modal to add a comment for the export
  public displayModalExportCitoyenHistoric: boolean = false; //* Will display the modal to view historic inside a super-table
  public statusExportCitoyen; //* list of statuts for the export Citoyen
  public listOfPointsCitoyenExportNoFilter: Array<any> = [];
  public listOfPointsCitoyenExportFiltered: Array<any> = [];
  public pointsLV: Array<any> = [];
  //#endregion

  public titleSearch: string = ''; //*var used to receive the input of teh point search
  public currentPage: number = 1;
  public hasLoaded = false;
  public modalPdfViewer: boolean = false; //* boolean to display the sidebar viewer
  public blobViewer: any; //* var inside the sidebar viewer containing the src

  //#region buttons
  public buttons: any = [
    {
      text: '',
      styleClass: 'blue',
      icon: 'fas fa-info-circle',
      iconPosition: 'left',
      click: () => {
        this.displayFichePoint = false;
        this.displayRTFEditor = false;
        this.displayInfos = true;
      },
    },
  ];
  public buttonsTitleTableView: Array<Button> = [
    // {
    //   text: "infos",
    //   id: "buttonsTitleTableViewInfos",
    //   styleClass: "blue",
    //   icon: "fas fa-info-circle",
    //   iconPosition: "left",
    //   disabled: this.isReordering,
    //   click: () => {
    //     this.displayFichePoint = false;
    //     this.displayRTFEditor = false;
    //     this.displayInfos = true;
    //     this.router.navigateByUrl("/private/meetings/" + this.meeting.id + "/infos");
    //   },
    // },
  ];
  public buttonsTitleTableViewOnReorder: Array<Button> = [
    {
      text: 'btn.cancel',
      styleClass: 'red',
      icon: 'icon-reorder',
      iconPosition: 'right',
      click: () => {
        this.isReordering = false;
        this.points = this.pointsBackUp;
      },
    },
    {
      text: 'btn.confirm',
      styleClass: 'blue',
      icon: 'icon-reorder',
      iconPosition: 'right',
      click: () => {
        this.onConfirmReorder();
      },
    },
  ];
  public notifyUserSubscription: Subscription;
  public pointsTreeWithEvents: any;
  public nombreNiveauxTree: number;
  public getConfiguration;
  public rightsPointsSeances = null;
  public amountOfEventsForMeetingNonPreparee = 0;
  public amountOfPointsForMeeting = 0;
  public modeleIdToOpen: number;
  showMassUpdateModelGenDocs: boolean = false;
  public modeleIdToGenerateInMass: number;
  public disabledLink: boolean = false;
  public _selectedColumns: any[];
  public showAddButton: boolean;
  public childrensOfAddButton: any = [];
  public tabsArrayXLActiveIndex;
  public somethingIsSelected: boolean = false;
  public displayActionsModal: boolean = false;
  public displayDocumentsModal: boolean = false;
  public menuEvents: any;
  public userCanEdit: boolean = false;
  public userCanDuplicate: boolean = false;
  public filterTable: boolean = false;
  public hasLevels: boolean = false;
  public rightsManagementDelibe;
  public rightsManagementAdmin;
  public allowCreationModelePoint = false;
  public userInfos = null;
  public openCreationModelPointBasedOnExistingPoint = false;
  public formNewModelePoint = new FormGroup({
    description: new FormControl(null, Validators.required),
  });

  //#endregion
  constructor(
    private detailsService: DetailsService,
    public spinner: NgxSpinnerService,
    public router: Router,
    public translate: TranslateService,
    public route: ActivatedRoute,
    private api: ApiService,
    public dataService: DataService,
    public dialogService: DialogService,
    public toastr: ToastrService,
    public confirmationService: ConfirmationService,
    public icontactService: IContactService,
    private titleService: Title,
    public signalRService: SignalrService,
    private userRightsService: UserRightsService,
    private location: Location,
    private adminApi: iAdminService
  ) {}

  //* listens the resize of the screen and adapts the display
  @HostListener('window:resize', []) updateResize() {
    // lg (for laptops and desktops - screens equal to or greater than 1200px wide)// md (for small laptops - screens equal to or greater than 992px wide)// sm (for tablets - screens equal to or greater than 768px wide)// xs (for phones - screens less than 768px wide)
    if (window.innerWidth <= 1024) {
      this.currentDisposition = 'table';
      this.changeJustifyOptions();
    } else {
      this.currentDisposition = localStorage.getItem('iDelibeSavedDisplayViewMeeting')
        ? localStorage.getItem('iDelibeSavedDisplayViewMeeting')
        : 'side-by-side';
    }
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  ngOnInit(): void {
    this.notifyUserSubscription = this.signalRService.notifyUser.subscribe((payload: NotifyUserSignalrPayload) => {
      if (payload.payloadEventId == SignalrPayloadEventId.DocumentMergeCompleted && payload.data.modeleId) {
        const data: SignalrDocumentMergeCompletedData = payload.data;
        if (+data.seanceId === +this.meeting.id) {
          this.getModelesDocuments();
          this.getFiles();
        }
      }
      if (payload.payloadEventId == SignalrPayloadEventId.SeanceExportDelibeMandataireCompletedData) {
        this.getExports();
      }
      if (payload.payloadEventId == SignalrPayloadEventId.SeanceExportDelibeCitoyenCompletedData) {
        this.getExportsCitoyen();
      }
    });
    window.addEventListener('storage', (event) => {
      if (event.key === 'SignalREventsMerge') {
        // comparer les tableaux => tout ce qui a dans newValue qui n'existe pas dans oldvalue
        const oldValues = JSON.parse(event.oldValue ? event.oldValue : '[]');
        const newValues = JSON.parse(event.newValue ? event.newValue : '[]');
        let differentValues = [];
        if (oldValues.length === 0) {
          //si on a pas d'ancienne value, on va juste tester s'il existe au moins une seanceId qui est égale à la notre actuellement
          //si oui on reload les files et modèles
          if (newValues.map((x) => x.seanceId).indexOf(+this.meeting.id) > -1) {
            this.getModelesDocuments();
            this.getFiles();
          }
        } else {
          //si on a une oldValue, alors on va déjà devoir trouver ce qui existe dans newValue qui n'existait pas dans oldValue
          differentValues = this.getDifference(newValues, oldValues);
          if (differentValues.map((x) => x.seanceId).indexOf(+this.meeting.id) > -1) {
            this.getModelesDocuments();
            this.getFiles();
          }
        }
      } else if (event.key === 'SignalRManualReloadSeance' && +event.newValue === +this.meeting.id) {
        this.getModelesDocuments();
        this.getFiles();
      }
    });

    this.buttonsTitleTableView = [];
    this.spinner.show();
    this.dataService.setSidebarSmall(true);

    //Setting the "noresult" string for each table

    this.noValuePointsPlannedInMeeting =
      `<div class="alert alert-primary mt-3" role="alert">` +
      this.translate.instant('info.noValuePointsPlannedInMeeting') +
      `</div>`;
    this.noValueHistoricForMeeting =
      `<div class="alert alert-primary mt-3" role="alert">` +
      this.translate.instant('info.noValueHistoricForMeeting') +
      `</div>`;

    //will set the current display of the meeting, depending on the last time the user clicked on the button in the html (justifyOption button p-selectButton)
    if (localStorage.getItem('iDelibeSavedDisplayViewMeeting')) {
      this.currentDisposition = localStorage.getItem('iDelibeSavedDisplayViewMeeting');
    }

    this.meetingId = this.route.snapshot.params.id;
    if (this.route.children[0]?.snapshot.params.pointID) {
      this.pointId = this.route.children[0]?.snapshot.params.pointID;
    }
    async.waterfall(
      [
        (cb) => {
          this.api.getConfiguration().subscribe((res: any) => {
            this.getConfiguration = res;
            if (res.delibConfig?.masquerOrdrePassageService) {
              const index = this.columns.map((x) => x.field).indexOf('triService');
              this.columns.splice(index, 1);
            }
            if (!res.delibConfig?.contenuMasquerUrgence) {
              this.columns.push({
                field: 'urgenceNiveauId',
                header: 'Urgence',
                visible: false,
                format: (value, item) => {
                  if (item?.type !== 'event' && !item.isConclusion && !item.isIntroduction && !item.isTitle) {
                    if (value && this.globalElement?.urgence_level_LV) {
                      const element = this.globalElement.urgence_level_LV.find((x) => x.value === value);
                      if (element) {
                        return element?.label;
                      } else {
                        return '/';
                      }
                    }
                  }
                },
                export: (value) => {
                  return value;
                },
                filterable: true,
              });
              this.columnsPointsNotLinked.push({
                field: 'urgenceNiveauId',
                header: 'Urgence',
                visible: false,
                format: (value, item) => {
                  if (value && this.globalElement?.urgence_level_LV) {
                    const element = this.globalElement.urgence_level_LV.find((x) => x.value === value);
                    if (element) {
                      return element?.label;
                    } else {
                      return '/';
                    }
                  }
                },
                export: (value) => {
                  return value;
                },
                filterable: true,
              });
            }
            if (!res.delibConfig?.cacherAgentTraitant) {
              this.columns.push({
                field: 'agentTraitantLabel',
                header: 'Agent traitant',
                visible: false,
                format: (value, item) => {
                  if (item?.type !== 'event' && !item.isConclusion && !item.isIntroduction && !item.isTitle) {
                    return value;
                  }
                },
                export: (value) => {
                  return value;
                },
                filterable: true,
              });
              this.columnsPointsNotLinked.push({
                field: 'agentTraitantLabel',
                header: 'Agent traitant',
                visible: false,
                format: (value, item) => {
                  const contact = this.contacts.find((x) => x.value === item.agentTraitantId);
                  const contactAdmin = this.contactsAdmin.find((x) => x.value === item.agentTraitantIdiAdmin);
                  if (contactAdmin) {
                    return contactAdmin.name;
                  } else if (contact) {
                    return contact.name;
                  }
                },
                export: (value) => {
                  return value;
                },
              });
            }
            cb();
          });
        },
        (cb) => {
          this.api.getRightsManagement().subscribe((rightManagement: any) => {
            this.rightsManagementDelibe = rightManagement;
            cb();
          });
        },
        (cb) => {
          this.adminApi.getRightsManagement().subscribe((res: any) => {
            this.rightsManagementAdmin = res;
            cb();
          });
        },
        (cb) => {
          this.adminApi.getUserInfo().subscribe((res: any) => {
            this.userInfos = res;
            cb();
          });
        },
        (cb) => {
          //* getting the datas for globalElements
          this.dataService.getGlobalElements.subscribe((res: any) => {
            this.globalElement = new Global_Class(res);
            if (this.globalElement.isLoaded()) {
              this.statusDocumentsGlobal = this.globalElement?.configApp.modeleGenerationRequestStatutes;
              this.statusExportMandataire = this.globalElement?.configApp.seanceExportIDelibeStatutes;
              cb(null);
            }
          });
        },
        async (callback) => {
          this.isUserAbleToAddPoint = await this.userRightsService.getUserAbleToAddPoint();
          callback();
        },
        (cb) => {
          this.icontactService.getUserWithDataAdmin().subscribe((res: any) => {
            this.iAdminUsers = res;
            cb();
          });
        },
        (cb) => {
          this.getContactsAndContactsAdmin().then(() => {
            cb();
          });
        },
        (cb) => {
          this.api.getMeetingsByID(this.meetingId).subscribe((res: any) => {
            if (res) {
              this.meeting = new Meeting(res, this.globalElement)
              const formattedDate = formatDate(this.meeting.date, 'dd/MM/yyyy');
              this.titleService.setTitle('Séance ' + formattedDate);
              cb();
            }
          });
        },
        (cb) => {
          this.api.getParticipantsByMeetingID(this.meeting.id).subscribe(
            (res: any) => {
              if (res) {
                this.participants = res.map((x) => new Participant(x));
                cb(null);
              }
            },
            (error) => {
              cb(null);
            }
          );
        },
        (cb) => {
          this.setDatasWithRights().then(() => {
            cb();
          });
        },
        (cb) => {
          this.getTradsAndForSelections().then(() => cb());
        },
        (cb) => {
          //Will display the rightTab between "points linked in meeting" and "points planned" depending on the last point clicked. When a user is on a point, pressing 'back' will always
          //route to the meeting with the right setting.
          if (sessionStorage.getItem('iDelibeReloadMeetingOnRightTab')) {
            const rightTab = sessionStorage.getItem('iDelibeReloadMeetingOnRightTab');
            if (rightTab === 'pointIncluded') {
              this.activeIndex = 0;
              this.activeTab = 'pointsInMeetings';
              this.changeTabView();
            } else if (rightTab === 'pointPlanned') {
              this.activeIndex = 1;
              this.activeTab = 'plannedPointsInMeetings';
              this.changeTabView();
            }
            sessionStorage.removeItem('iDelibeReloadMeetingOnRightTab');
            cb();
          } else {
            this.getPointsInclusWithEvents().then(() => {
              cb();
            });
          }
        },
        (cb) => {
          this.getFiles().then(() => {
            cb();
          });
        },
        (cb) => {
          this.api.getSeancesByIDCount(this.meeting.id, 'PointsInclus,PointsNonInclus').subscribe((res: any) => {
            if (this.itemsTab.length > 0) {
              this.itemsTab[0].count = res.pointsInclus;
              this.itemsTab[1].count = res.pointsNonInclus;
            }
            cb(null);
          });
        },
        async (callback) => {
          this.rightsPointsSeances = await this.userRightsService.getUserAbleToAddPoint();
          callback();
        },
        (cb) => {
          if (window.innerWidth <= 1024) {
            this.currentDisposition = 'table';
            this.changeJustifyOptions();
          } else {
            this.currentDisposition = localStorage.getItem('iDelibeSavedDisplayViewMeeting')
              ? localStorage.getItem('iDelibeSavedDisplayViewMeeting')
              : 'side-by-side';
          }
          cb();
          this.spinner.hide();
        },
      ],
      () => {
        this.spinner.hide();
      }
    );

    this._selectedColumns = this.columns;

    this.reorganizeChildren = [
      {
        text: this.translate.instant('reorganize.manual'),
        click: () => {
          this.openModalReorder();
        },
      },
      // {
      //   text: this.translate.instant('reorganize.auto'),
      //   click: () => {
      //     this.reorderAuto();
      //   }
      // },
      {
        text: this.translate.instant('numerotation.auto'),
        click: () => {
          this.numerotationAutoBack();
        },
      },
    ];

    this.location.onUrlChange((url, state) => {
      const localUrl = url.split('/');
      this.pointId = localUrl[localUrl.length - 1] as unknown as number;
      // Ici, vous pouvez effectuer des actions à chaque changement d'URL.
    });

    if (this.hasLevels) {
      // ON PREPARE LA DATA POUR LE TABLEAU TABLETREE

      this.prepareContentForTreeTable();
    }
    this.checkRefreshPoint();
  }

  getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return (
          object1.requestId === object2.requestId &&
          moment(object1.date).unix() === moment(object2.date).unix() &&
          object1.modeleId === object2.modeleId &&
          object1.seanceId === object2.seanceId &&
          object1.pointId === object2.pointId
        );
      });
    });
  }

  //* gets the contacts of the Delibe api and Admin api
  getContactsAndContactsAdmin() {
    return new Promise((resolve, reject) => {
      async.parallel(
        [
          (callback) => {
            this.icontactService.getLowContactsAdmin().subscribe((res: any) => {
              this.contactsAdmin = res;
              callback();
            });
          },
          (callback) => {
            this.icontactService.getLowContacts().subscribe((res: any) => {
              this.contacts = res;
              this.listOfContacts = this.contacts;
              callback();
            });
          },
        ],
        () => {
          resolve(true);
        }
      );
    });
  }

  //* gets the user's meeting's rights and sets the buttons,... with them
  setDatasWithRights() {
    return new Promise((resolve, reject) => {
      //ici on va gérer les droits par query
      this.api.getRightsSeance(this.meeting.id).subscribe((rightSeance: any) => {
        this.rightsOfSeance = rightSeance;
        //* Setting the datas linked to the file edit right
        if (rightSeance.files.edit) {
          this.filesMenu = [
            {
              label: this.translate.instant('btn.modify'),
              command: (item) => {
                window.setTimeout(() => {
                  this.openModification = true;
                  const index = this.files.map((x) => x.id).indexOf(this.fileClicked.id);
                  this.selectedFile = this.files[index];
                  this.formFilesModification.patchValue({
                    id: this.selectedFile.id,
                    description: this.selectedFile.description,
                    annexeNumero: this.selectedFile.annexeNumero,
                    fileType:
                      this.selectedFile.extension === 'pdf' ? (this.selectedFile.annexeNumero ? true : false) : false,
                    visibleDelibeWeb: this.selectedFile.visibleDelibeWeb,
                    visibleDelibeWebCitoyen: this.selectedFile.visibleDelibeWebCitoyen,
                  });
                }, 500);
              },
            },
          ];
        } else {
          this.filesMenu = [];
        }
        //*Setting the rights linked to the edition of the meeting
        if (rightSeance.edit) {
          // this.buttonsTitleTableView.push({
          //   id: "buttonsTitleTableViewReorganize",
          //   text: "btn.reorganize",
          //   styleClass: "blue",
          //   icon: "icon-reorder",
          //   iconPosition: "right",
          //   children: [
          //     {
          //       text: this.translate.instant("reorganize.manual"),
          //       click: () => {
          //         this.openModalReorder();
          //       },
          //     },
          //     // {
          //     //   text: this.translate.instant('reorganize.auto'),
          //     //   click: () => {
          //     //     this.reorderAuto();
          //     //   }
          //     // },
          //     {
          //       text: this.translate.instant("numerotation.auto"),
          //       click: () => {
          //         this.numerotationAutoBack();
          //       },
          //     },
          //   ],
          //   click: () => {},
          // });
        }
        if (rightSeance.menuDocuments) {
          this.buttonsTitleTableView.push({
            text: 'btn.documents',
            id: 'buttonsTitleTableViewDocument',
            styleClass: 'blue',
            icon: 'bi bi-file-earmark-text-fill',
            iconPosition: 'left',
            disabled: false,
            click: (event) => {
              this.saveEventClickForReloadOverlayButton = event;
              this.displayDocumentsModal = true;
              this.getModelesDocuments();
            },
          });
        }
        if (rightSeance.menuExportIDelibeMandataires || rightSeance.menuExportIDelibeCitoyens) {
          this.buttonsTitleTableView.push({
            id: 'buttonsTitleTableViewExport',
            text: 'btn.export',
            styleClass: 'blue',
            icon: 'bi bi-box-arrow-up-right',
            iconPosition: 'left',
            click: () => {
              this.showExportOverlay = false;
              this.showExportOverlayCitoyen = false;
            },
            children: [],
          });
          if (rightSeance.menuExportIDelibeMandataires) {
            this.buttonsTitleTableView
              .find((x) => x.id === 'buttonsTitleTableViewExport')
              .children.push({
                id: 'buttonsTitleTableViewExportMandataire',
                text: 'Export mandataire',
                click: () => {
                  this.showExportOverlay = !this.showExportOverlay;
                  this.getExports();
                },
              });
          }
          if (rightSeance.menuExportIDelibeCitoyens) {
            this.buttonsTitleTableView
              .find((x) => x.id === 'buttonsTitleTableViewExport')
              .children.push({
                id: 'buttonsTitleTableViewExportCitoyen',
                text: 'Export citoyen',
                click: () => {
                  this.showExportOverlayCitoyen = !this.showExportOverlayCitoyen;
                  this.getExportsCitoyen();
                },
              });
          }
        }
        //*setting the datas linked to the addition of a point into the meeting
        const rightTypeSeance = this.globalElement?.rightsPointsSeances?.typeSeanceRights.filter(
          (x) => x.typeSeanceId === this.meeting.typeSeanceId
        );
        if (rightTypeSeance && rightTypeSeance.length > 0 && rightTypeSeance[0].addPoint) {
          const index = this.buttons.map((x) => x.id).indexOf('sideToSideCreateButton');
          if (index === -1) {
            this.buttons.push({
              text: '',
              id: 'sideToSideCreateButton',
              styleClass: 'green',
              icon: 'fas fa-plus-circle',
              iconPosition: 'left',
              click: () => {
                this.dataService.SetCurrentMeeting(this.meeting);
                this.router.navigateByUrl('/private/points/create');
              },
            });
          }
        }

        this.buttons = [];
        if (this.meeting.status === 'open') {
          const state = this.translate.instant('global.open');
          if (this.rightsOfSeance.edit) {
            this.buttons.push({
              id: 'buttonChangeStatut',
              children: [{}],
              text: state,
              tooltip: state,
              styleClass: 'green',
              click: () => {
                this.setNewStatutModal();
              },
            });
          } else {
            this.buttons.push({
              id: 'buttonChangeStatut',
              children: [{}],
              text: state,
              tooltip: state,
              styleClass: 'green cursor-default',
            });
          }
        }
        if (this.meeting.status === 'pending') {
          const state = this.translate.instant('global.pending');
          if (this.rightsOfSeance.edit) {
            this.buttons.push({
              id: 'buttonChangeStatut',
              children: [{}],
              text: state,
              tooltip: state,
              styleClass: 'orange',
              click: () => {
                this.setNewStatutModal();
              },
            });
          } else {
            this.buttons.push({
              id: 'buttonChangeStatut',
              children: [{}],
              text: state,
              tooltip: state,
              styleClass: 'orange cursor-default',
            });
          }
        }
        if (this.meeting.status === 'closed') {
          const state = this.translate.instant('global.closed');
          if (this.rightsOfSeance.edit) {
            this.buttons.push({
              id: 'buttonChangeStatut',
              children: [{}],
              text: state,
              tooltip: state,
              styleClass: 'red',
              click: () => {
                this.setNewStatutModal();
              },
            });
          } else {
            this.buttons.push({
              id: 'buttonChangeStatut',
              children: [{}],
              text: state,
              tooltip: state,
              styleClass: 'red cursor-default',
            });
          }
        }

        //* Finally we'll add the button 'add' if we have either the right to edit or to add a point
        if ((this.rightsOfSeance.edit || rightTypeSeance[0].addPoint) && this.meeting.status != 'closed') {
          this.buttonsTitleTableView.push({
            text: 'btn.add',
            styleClass: 'green superButton__addButton',
            icon: 'bi bi-plus-circle-fill',
            id: 'superButton__addButton',
            iconPosition: 'left',
            click: () => {},
            children: [],
          });
          if (rightTypeSeance[0].addPoint) {
            this.buttonsTitleTableView[this.buttonsTitleTableView.length - 1].children.push({
              text: this.translate.instant('point'),
              tooltip: this.translate.instant('point'),
              id: 'buttonsTitleTableViewPoint',
              icon: 'icon-ul-li-list green-icon',
              iconPosition: 'left',
              click: () => {
                this.dataService.SetCurrentMeeting(this.meeting);
                this.router.navigateByUrl('/private/points/create');
              },
            });
          }
          if (this.rightsOfSeance.edit) {
            this.buttonsTitleTableView[this.buttonsTitleTableView.length - 1].children.push({
              text: this.translate.instant('event'),
              tooltip: this.translate.instant('event'),
              icon: 'icon-milestone green-icon',
              iconPosition: 'left',
              click: () => {
                this.openEventModal();
              },
            });
          }
        }

        //* Finally we'll add the button 'add' if we have either the right to edit or to add a point IN THE SIDEBYSIDE VIEW
        if (this.rightsOfSeance.edit || rightTypeSeance[0].addPoint) {
          this.showAddButton = true;
          if (rightTypeSeance[0].addPoint) {
            this.childrensOfAddButton.push({
              text: this.translate.instant('point'),
              tooltip: this.translate.instant('point'),
              id: 'buttonsTitleTableViewPoint',
              icon: 'icon-ul-li-list green-icon',
              iconPosition: 'left',
              click: () => {
                this.dataService.SetCurrentMeeting(this.meeting);
                this.router.navigateByUrl('/private/points/create');
              },
            });
          }
          if (this.rightsOfSeance.edit) {
            this.childrensOfAddButton.push({
              text: this.translate.instant('event'),
              tooltip: this.translate.instant('event'),
              icon: 'icon-milestone green-icon',
              iconPosition: 'left',
              click: () => {
                this.openEventModal();
              },
            });
          }
        }

        if (this.meeting.status === 'open') {
          if (this.rightsOfSeance.edit) {
            this.buttonsTitleTableView.splice(0, 0, {
              id: 'buttonChangeStatut',
              children: [{}],
              text: this.translate.instant('global.open'),
              tooltip: this.translate.instant('global.open'),
              styleClass: 'green',
              click: () => {
                this.setNewStatutModal();
              },
            });
          } else {
            this.buttonsTitleTableView.splice(0, 0, {
              id: 'buttonChangeStatut',
              children: [{}],
              text: this.translate.instant('global.open'),
              tooltip: this.translate.instant('global.open'),
              styleClass: 'green cursor-default',
              click: () => {},
            });
          }
        }
        if (this.meeting.status === 'pending') {
          if (this.rightsOfSeance.edit) {
            this.buttonsTitleTableView.splice(0, 0, {
              id: 'buttonChangeStatut',
              children: [{}],
              text: this.translate.instant('global.pending'),
              tooltip: this.translate.instant('global.pending'),
              styleClass: 'orange',
              click: () => {
                this.setNewStatutModal();
              },
            });
          } else {
            this.buttonsTitleTableView.splice(0, 0, {
              id: 'buttonChangeStatut',
              children: [{}],
              text: this.translate.instant('global.pending'),
              tooltip: this.translate.instant('global.pending'),
              styleClass: 'orange cursor-default',
              click: () => {},
            });
          }
        }
        if (this.meeting.status === 'closed') {
          if (this.rightsOfSeance.edit) {
            this.buttonsTitleTableView.splice(0, 0, {
              id: 'buttonChangeStatut',
              children: [{}],
              text: this.translate.instant('global.closed'),
              tooltip: this.translate.instant('global.closed'),
              styleClass: 'red',
              click: () => {
                this.setNewStatutModal();
              },
            });
          } else {
            this.buttonsTitleTableView.splice(0, 0, {
              id: 'buttonChangeStatut',
              children: [{}],
              text: this.translate.instant('global.closed'),
              tooltip: this.translate.instant('global.closed'),
              styleClass: 'red cursor-default',
              click: () => {},
            });
          }
        }

        this.buttonsTitleTableView.map((btn) => {
          btn.text = this.translate.instant(btn.text);
        });
        this.buttonsTitleTableViewOnReorder.map((btn) => {
          btn.text = this.translate.instant(btn.text);
        });
        resolve(true);
      });
    });
  }

  setNewStatutModal() {
    //En tout premier on va faire une vérification de l'existence du statut "valide pour passer en séance"
    async.waterfall([
      (callback) => {
        let isStatutValidForMeetingExists = false;
        this.api.getGlobalAllPointsStatuts().subscribe((res: any) => {
          for (let i = 0; i < res.length; i++) {
            if (res[i].validePourAjoutSeance) {
              isStatutValidForMeetingExists = true;
              break;
            }
          }
          if (isStatutValidForMeetingExists) {
            callback();
          } else {
            this.toastr.error(this.translate.instant('error.noValidStatutExisting'));
          }
        });
      },
      (callback) => {
        this.api.getMeetingsPointsInclus(this.meeting.id).subscribe((res: any) => {
          const pointsInclus = res.points;
          let noOrdreReel = false;
          pointsInclus.map((x) => {
            if (!x.ordreReel || x.ordreReel === null || x.ordreReel === '' || x.ordreReel === 0) {
              noOrdreReel = true;
            }
          });
          if (noOrdreReel) {
            this.toastr.error(
              "Attention il existe au moins un point ne possédant pas d'ordre réel.",
              ' Veuillez attribuer un ordre réel à tous les points avant de changer le statut de cette séance',
              { timeOut: 8000 }
            );
          } else {
            callback();
          }
        });
      },
      (callback) => {
        //On va vérifier s'il existe un ou plusieurs points 'en correction' + s'il existe des points en statut non valides pour la séance
        this.api.getSeancesStates([this.meeting.id]).subscribe((res: any) => {
          const enCorrectionIds = [];
          const notValidForSeance = [];
          for (let i = 0; i < res.length; i++) {
            if (res[i].pointsInCorrection.length > 0) {
              enCorrectionIds.push(res[i].pointsInCorrection);
            }
            if (res[i].pointsWithoutStatusValideForSeance.length > 0) {
              notValidForSeance.push(res[i].pointsWithoutStatusValideForSeance);
            }
            //Oui en fait y'a pas besoin de complexifier comme cela mais ca a été réfléchi pour le séance simple et multiple...
          }
          if (enCorrectionIds.length > 0) {
            this.toastr.error(
              "Il existe des points 'en correction'. Veuillez les modifier avant de changer le statut de cette séance"
            );
          } else {
            //S'il n'existe aucun soucis => on va afficher les enfants du bouton
            if (this.meeting.status === 'closed') {
              this.buttonsTitleTableView[0].children = [
                {
                  text: this.translate.instant('global.open'),
                  tooltip: this.translate.instant('global.open'),
                  click: () => {
                    this.changeStatut('open', notValidForSeance.length);
                  },
                },
                {
                  text: this.translate.instant('global.pending'),
                  tooltip: this.translate.instant('global.pending'),
                  click: () => {
                    this.changeStatut('pending', notValidForSeance.length);
                  },
                },
              ];
            }
            if (this.meeting.status === 'pending') {
              this.buttonsTitleTableView[0].children = [
                {
                  text: this.translate.instant('global.open'),
                  tooltip: this.translate.instant('global.open'),
                  click: () => {
                    this.changeStatut('open', notValidForSeance.length);
                  },
                },
                {
                  text: this.translate.instant('global.closed'),
                  tooltip: this.translate.instant('global.closed'),
                  click: () => {
                    this.changeStatut('closed', notValidForSeance.length);
                  },
                },
              ];
            }
            if (this.meeting.status === 'open') {
              this.buttonsTitleTableView[0].children = [
                {
                  text: this.translate.instant('global.pending'),
                  tooltip: this.translate.instant('global.pending'),
                  click: () => {
                    this.changeStatut('pending', notValidForSeance.length);
                  },
                },
                {
                  text: this.translate.instant('global.closed'),
                  tooltip: this.translate.instant('global.closed'),
                  click: () => {
                    this.changeStatut('closed', notValidForSeance.length);
                  },
                },
              ];
            }
            if (this.meeting.status === 'closed') {
              this.buttons[0].children = [
                {
                  text: this.translate.instant('global.open'),
                  tooltip: this.translate.instant('global.open'),
                  click: () => {
                    this.changeStatut('open', notValidForSeance.length);
                  },
                },
                {
                  text: this.translate.instant('global.pending'),
                  tooltip: this.translate.instant('global.pending'),
                  click: () => {
                    this.changeStatut('pending', notValidForSeance.length);
                  },
                },
              ];
            }
            if (this.meeting.status === 'pending') {
              this.buttons[0].children = [
                {
                  text: this.translate.instant('global.open'),
                  tooltip: this.translate.instant('global.open'),
                  click: () => {
                    this.changeStatut('open', notValidForSeance.length);
                  },
                },
                {
                  text: this.translate.instant('global.closed'),
                  tooltip: this.translate.instant('global.closed'),
                  click: () => {
                    this.changeStatut('closed', notValidForSeance.length);
                  },
                },
              ];
            }
            if (this.meeting.status === 'open') {
              this.buttons[0].children = [
                {
                  text: this.translate.instant('global.pending'),
                  tooltip: this.translate.instant('global.pending'),
                  click: () => {
                    this.changeStatut('pending', notValidForSeance.length);
                  },
                },
                {
                  text: this.translate.instant('global.closed'),
                  tooltip: this.translate.instant('global.closed'),
                  click: () => {
                    this.changeStatut('closed', notValidForSeance.length);
                  },
                },
              ];
            }
            callback();
          }
        });
      },
    ]);
  }

  changeStatut(type, numberOfPointsNotValid) {
    let confirmMessage = '';
    if (type === 'open') {
      confirmMessage = this.translate.instant('confirmMessageToOpenSeance');
      if (numberOfPointsNotValid > 0) {
        confirmMessage =
          confirmMessage +
          this.translate.instant('confirmMessageHasNotValidPoints', { amount: numberOfPointsNotValid });
      }
    }
    if (type === 'pending') {
      confirmMessage = this.translate.instant('confirmMessageToPendingSeance');
      if (numberOfPointsNotValid > 0) {
        confirmMessage =
          confirmMessage +
          this.translate.instant('confirmMessageHasNotValidPoints', { amount: numberOfPointsNotValid });
      }
    }
    if (type === 'closed') {
      confirmMessage = this.translate.instant('confirmMessageToClosedSeance');
      if (numberOfPointsNotValid > 0) {
        confirmMessage =
          confirmMessage +
          this.translate.instant('confirmMessageHasNotValidPoints', { amount: numberOfPointsNotValid });
      }
    }

    this.confirmationService.confirm({
      message: confirmMessage,
      key: 'detailsMeetingModal',
      accept: () => {
        const data = {
          ids: [this.meeting.id],
          statut: type === 'open' ? 'Ouverte' : type === 'pending' ? 'Preparee' : 'Fermee',
        };
        this.api.setSeanceStatutes(data).subscribe(
          (response) => {
            this.toastr.success(this.translate.instant('success.modifiedMeetingsStatus'));
            if (response.pointIdsWithIssues > 0) {
              const pointsError = response.pointIdsWithIssues;
              this.toastr.info(
                this.translate.instant('info.points') +
                  ' : ' +
                  pointsError.join(', ') +
                  ' ' +
                  this.translate.instant('info.cannotLock')
              );
            }
            this.ngOnInit();
          },
          (error) => {
            console.error(error);
          }
        );
      },
    });
  }

  //* gets the historic the all the exports
  getExportHistoric() {
    this.api.getExportsDelibeMandataire(this.meetingId).subscribe((res: any) => {
      this.exportItemsHistoric = res.histories;
      this.exportItemsHistoric.map((x) => {
        if (x.userId) {
          const userIndex = this.iAdminUsers.map((adminUsers) => +adminUsers.userId).indexOf(x.userId);
          x.userName = this.iAdminUsers[userIndex].label;
        }
        const statusLabelIndex = this.statusExportMandataire.map((x) => x.id).indexOf(x.statutId);
        if (statusLabelIndex) {
          x.statusLabel = this.statusExportMandataire[statusLabelIndex].label;
        }
        if (x.statutId === 5) {
          //Si le statut est en erreur
          const jsonData = JSON.parse(x.jsonData);
          x.jsonErrors = jsonData?.Errors?.map((x) => x.Message);
        }
      });
      this.displayModalExportMandataireHistoric = true;
    });
  }

  //will get the datas to export for citoyen linked to the meeting
  exportNowCitoyen() {
    this.spinner.show();
    this.showExportOverlayCitoyen = false;
    this.api.getExportCitoyenPoints(this.meetingId).subscribe((data: any) => {
      this.listOfPointsCitoyenExportNoFilter = data;
      this.listOfPointsCitoyenExportFiltered = data
        .filter((x) => x.isPointChecked)
        .map((x) => {
          x.point.pointFichiers = x.pointFichiers;
          x.point.ordreDocument = x.ordreDocument;
          x.point.ordreMatiere = x.ordreMatiere;
          x.point.ordreReel = x.ordreReel;
          const typePointFound = this.globalElement?.types_points_LV.find((type) => {
            return type.value == x.point.typeId;
          });
          if (typePointFound) {
            x.point.typePoint = typePointFound.label;
          } else {
            x.point.typePoint = 'Pas de type';
          }
          return x.point;
        });
      this.displayModalExportCitoyenConfirm = true;
      this.spinner.hide();
    });
  }

  //* gets the historic the all the exports
  getExportHistoricCitoyen() {
    this.api.getExportsDelibeCitoyen(this.meetingId).subscribe((res: any) => {
      this.exportItemsHistoricCitoyen = res.histories;
      this.exportItemsHistoricCitoyen.map((x) => {
        if (x.userId) {
          const userIndex = this.iAdminUsers.map((adminUsers) => +adminUsers.userId).indexOf(x.userId);
          x.userName = this.iAdminUsers[userIndex].label;
        }
        const statusLabelIndex = this.statusExportMandataire.map((x) => x.id).indexOf(x.statutId);
        if (statusLabelIndex) {
          x.statusLabel = this.statusExportMandataire[statusLabelIndex].label;
        }
        if (x.statutId === 5) {
          //Si le statut est en erreur
          const jsonData = JSON.parse(x.jsonData);
          x.jsonErrors = jsonData?.Errors?.map((x) => x.Message);
        }
      });
      this.displayModalExportCitoyenHistoric = true;
    });
  }

  //* triggers the Delibe Mandataires export for the meeting
  exportMandataireNow() {
    this.spinner.show();
    this.api.exportMandataireNow(this.meetingId, this.formExportMandataire.value).subscribe(
      (res: any) => {
        this.displayModalExportMandataireConfirm = false;
        this.toastr.success("Processus d'exportation lancé, vous recevrez un message une fois terminé");
        this.spinner.hide();
        this.getExports();
        this.displayModalExportMandataireConfirm = false;
      },
      () => {
        this.toastr.error("Une erreur est survenue lors de l'export.");
        this.getExports();
      }
    );
  }

  //* triggers the Delibe Mandataires export for the meeting
  exportCitoyenNow() {
    this.spinner.show();
    this.api.exportCitoyenNow(this.meetingId).subscribe(
      (res: any) => {
        //TODO SygnalR pour prévenir l'utilisateur de quand la séance est exportée!
        this.displayModalExportCitoyenConfirm = false;
        this.toastr.success("Processus d'exportation lancé, vous recevrez un message une fois terminé");
        this.spinner.hide();
        this.getExportsCitoyen();
        this.displayModalExportCitoyenConfirm = false;
      },
      () => {
        this.toastr.error("Une erreur est survenue lors de l'export.");
        this.getExportsCitoyen();
      }
    );
  }

  //* triggers on a Participant click in its  table
  public openParticipantModal() {
    const ref = this.dialogService.open(ParticipantsChangeComponent, {
      data: {
        participants: this.meeting.participants,
        header: this.translate.instant('participantsModifications'),
        contacts: this.contacts,
        users: this.contactsAdmin,
      },
      width: '70vw',
      styleClass: 'super-compos-modal-content ',
      showHeader: false,
      closable: false,
      dismissableMask: false,
      baseZIndex: 1000,
    });
    ref.onClose.subscribe((res: any) => {
      if (res) {
        //TODO : modifier les participants
      }
    });
  }

  //* gets the meeting's point included with their linked events
  public getPointsInclusWithEvents() {
    return new Promise((resolve, reject) => {
      this.amountOfEventsForMeetingNonPreparee = 0;
      this.spinner.show();
      this.points = [];
      this.pointsTreeWithEvents = null;
      this.amountOfPointsForMeeting = 0;
      this.api[
        this.meeting.verrouille === false
          ? 'getMeetingsPointsInclusWithEventsTree'
          : 'getMeetingsPointsInclusWithEventsOrdered'
      ](this.meeting.id, true).subscribe((res: any) => {
        this.amountOfEventsForMeetingNonPreparee = res.nombreEvenements;
        this.nombreNiveauxTree = res.nombreNiveaux;
        this.pointsTreeWithEvents = { datas: [] };
        if (res) {
          this.itemsTab[0].count = res.length;
          if (this.nombreNiveauxTree && this.nombreNiveauxTree > 1) {
            this.hasLevels = true;
            res.niveaux.map((item, index) => {
              item.title =
                '<div class="tree-title-parent" style="white-space: nowrap;">(' +
                (SeancePointInclusNiveauType[item.niveauType] ? SeancePointInclusNiveauType[item.niveauType] : '') +
                ') ' +
                (item.niveauTri ? item.niveauTri : '') +
                '</div>';
              item.rowClass = 'parent-row';
              item.id = Math.floor(Math.random() * 10000);
              const formattedItem = new TreeData(item);
              this.recursivePoints(formattedItem);
              this.pointsTreeWithEvents.datas.push(formattedItem);
            });
            this.introductionTree.data.meetingId = this.meeting.id;
            this.titreTree.data.meetingId = this.meeting.id;
            this.conclusionTree.data.meetingId = this.meeting.id;
            this.pointsTreeWithEvents.datas.splice(0, 0, new NiveauIntroConcluTitre(this.introductionTree));
            this.pointsTreeWithEvents.datas.splice(0, 0, new NiveauIntroConcluTitre(this.titreTree));
            this.pointsTreeWithEvents.datas.push(new NiveauIntroConcluTitre(this.conclusionTree));
            this.points.splice(0, 0, new Point(this.introduction));
            this.points.splice(0, 0, new Point(this.titre));
            this.points.push(new Point(this.conclusion));
            this.pointsBackUp = this.pointsTreeWithEvents;
            this.itemsTab[0].count = this.amountOfPointsForMeeting;
          } else if (
            this.nombreNiveauxTree === 1 &&
            res.niveaux.length > 0 &&
            res.niveaux[0].niveauType !== SeancePointInclusNiveauType.None
          ) {
            // on va checker le 1er objet "niveau". Si celui-ci a un niveauType === 1 (SeancePointInclusNiveauType.None) => ca veut dire qu'il n'y a pas de tri. S'il a autre chose que 1, ca veut dire qu'il y a FORCEMENT un tri
            res.niveaux.map((item, index) => {
              item.title =
                '<div class="tree-title-parent" style="white-space: nowrap;">(' +
                (SeancePointInclusNiveauType[item.niveauType] ? SeancePointInclusNiveauType[item.niveauType] : '') +
                ') ' +
                (item.niveauTri ? item.niveauTri : '') +
                '</div>';
              item.rowClass = 'parent-row';
              item.id = Math.floor(Math.random() * 10000);
              const formattedItem = new TreeData(item);
              if (formattedItem?.data?.points) {
                formattedItem?.data?.points.map((item) => {
                  if (item.evenements && item.evenements.length > 0) {
                    item.evenements.map((event) => {
                      event.type = 'event';
                      event.objetSynthese = event.texte;
                      event.entreeMembreParticipant = event.entreeMembreParticipantId;
                      event.sortieMembreParticipant = event.sortieMembreParticipantId;
                      event.rowClass = 'event-class';
                      this.points.push(new Point(event, this.globalElement));
                    });
                  }
                  item.point.type = 'point';
                  item.point.triService = item.triService;
                  item.point.ordreReel = item.ordreReel;
                  const pointItem = new Point(
                    item,
                    this.globalElement,
                    item.pointVotes,
                    this.contactsAdmin,
                    this.contacts,
                    item.pointObservationSummary
                  );
                  formattedItem.children.push({ data: pointItem });
                });
              }
              this.pointsTreeWithEvents.datas.push(formattedItem);
              this.introductionTree.data.meetingId = this.meeting.id;
              this.titreTree.data.meetingId = this.meeting.id;
              this.conclusionTree.data.meetingId = this.meeting.id;
            });
            this.pointsTreeWithEvents.datas.splice(0, 0, new NiveauIntroConcluTitre(this.introductionTree));
            this.pointsTreeWithEvents.datas.splice(0, 0, new NiveauIntroConcluTitre(this.titreTree));
            this.pointsTreeWithEvents.datas.push(new NiveauIntroConcluTitre(this.conclusionTree));
            let point = this.pointsTreeWithEvents.datas.map((x) => x.data);
            point = point
              .map((x) => {
                if (x.niveau) {
                  const data = [];
                  x.childrens.map((y) => {
                    data.push(y.data);
                  });
                  x = data;
                }
                return x;
              })
              .flat();
            this.points = point;
          } else {
            this.nombreNiveauxTree = 0;
            if (res && res.niveaux && res.niveaux.length > 0) {
              res.niveaux.map((item, index) => {
                item.title = item.niveauTri ? item.niveauTri : item.niveauDescription;
                this.nonRecursivePoints(item);
              });
              this.points.splice(0, 0, new Point(this.introduction));
              this.points.splice(0, 0, new Point(this.titre));
              this.points.push(new Point(this.conclusion));
            } else {
              if (res && res.niveaux && res.niveaux.length === 0) {
                this.pointsTreeWithEvents.datas = [];
                this.pointsTreeWithEvents.datas.splice(0, 0, new NiveauIntroConcluTitre(this.introductionTree));
                this.pointsTreeWithEvents.datas.splice(0, 0, new NiveauIntroConcluTitre(this.titreTree));
                this.pointsTreeWithEvents.datas.push(new NiveauIntroConcluTitre(this.conclusionTree));
              }
              if (res && res.points && res.points.length > 0) {
                this.amountOfPointsForMeeting = res.points.length;
                this.itemsTab[0].count = this.amountOfPointsForMeeting;
                res.points.map((item, index) => {
                  item.title = item.niveauDescription;
                  if (item.evenements && item.evenements.length > 0) {
                    item.evenements.map((event) => {
                      event.type = 'event';
                      event.objetSynthese = event.texte;
                      event.entreeMembreParticipant = event.entreeMembreParticipantId;
                      event.sortieMembreParticipant = event.sortieMembreParticipantId;
                      event.rowClass = 'event-class';
                      this.points.push(new Point(event, this.globalElement));
                    });
                  }
                  item.point.type = 'point';
                  item.point.triService = item.triService;
                  item.point.ordreReel = item.ordreReel;
                  this.points.push(
                    new Point(
                      item,
                      this.globalElement,
                      item.pointVotes,
                      this.contactsAdmin,
                      this.contacts,
                      item.pointObservationSummary
                    )
                  );
                });
              }
              this.points.splice(0, 0, new Point(this.introduction));
              this.points.splice(0, 0, new Point(this.titre));
              this.points.push(new Point(this.conclusion));
              this.introduction.meetingId = this.meeting.id;
              this.titre.meetingId = this.meeting.id;
              this.conclusion.meetingId = this.meeting.id;
              this.pointsBackUp = this.points;
            }
          }
          this.points = this.points.filter((x) => !x.isConclusion && !x.isTitle && !x.isIntroduction);

          this.detailsService.idList = this.points
            .filter((point) => point.type !== 'event') // Filtrer les points pour ne garder que ceux dont le type n'est pas 'event'
            .map((point) => point.id); // Appliquer map sur le résultat filtré pour obtenir les IDs

          this.spinner.hide();
        } else {
          this.points = [];
          this.spinner.hide();
        }
      });
      resolve(true);
    });
  }

  recursivePoints(node) {
    for (let i = 0; i < node.children.length; i++) {
      var child = node.children[i];
      child.data = {
        title:
          '<div class="tree-title-secondparent">(' +
          (SeancePointInclusNiveauType[child.niveauType] ? SeancePointInclusNiveauType[child.niveauType] : '') +
          ') ' +
          (child.niveauTri ? child.niveauTri : child.niveauDescription ? child.niveauDescription : '') +
          '</div>',
        niveauDescription: child.niveauDescription,
        niveauType: child.niveauType,
        ordreAffichage: child.ordreAffichage,
        rowClass: 'parent-row',
        children: child.childrens ? child.childrens : [],
      };
      child.children = [];
      const formmatedItem = new TreeData(child);
      this.recursivePoints(formmatedItem);
      if (child.points && child.points.length > 0) {
        child.points.map((point) => {
          if (point.evenements && point.evenements.length > 0) {
            point.evenements.map((event) => {
              event.type = 'event';
              event.objetSynthese = event.texte;
              event.entreeMembreParticipant = event.entreeMembreParticipantId;
              event.sortieMembreParticipant = event.sortieMembreParticipantId;
              event.rowClass = 'event-class';
              const eventItem = new Point(event, this.globalElement);
              child.children.push({ data: eventItem });
            });
          }
          point.point.type = 'point';
          point.point.triService = point.triService;
          point.point.ordreReel = point.ordreReel;
          const pointItem = new Point(
            point,
            this.globalElement,
            point.pointVotes,
            this.contactsAdmin,
            this.contacts,
            point.pointObservationSummary
          );
          this.amountOfPointsForMeeting++;
          child.children.push({ data: pointItem });
          this.points.push(pointItem);
        });
      } else {
        child.children = child.childrens;
      }
    }
  }

  nonRecursivePoints(node) {
    if (node.points && node.points.length > 0) {
      node.points.map((point) => {
        if (point.evenements && point.evenements.length > 0) {
          point.evenements.map((event) => {
            event.type = 'event';
            event.objetSynthese = event.texte;
            event.entreeMembreParticipant = event.entreeMembreParticipantId;
            event.sortieMembreParticipant = event.sortieMembreParticipantId;
            event.rowClass = 'event-class';
            this.points.push(new Point(event, this.globalElement));
          });
        }
        this.points.push(
          new Point(
            point,
            this.globalElement,
            point.pointVotes,
            this.contactsAdmin,
            this.contacts,
            point.pointObservationSummary
          )
        );
      });
    } else {
    }
  }

  //* gets the meeting's not linked points
  public getPointsNonInclus() {
    this.spinner.show();
    this.notLinkedPoints = [];
    this.api.getMeetingsPointsNonInclus(this.meeting.id).subscribe((res: any) => {
      if (res && res.length > 0) {
        const temp = [];
        res.map((item) => {
          const point = new Point(item, this.globalElement, null, null, null, item.pointObservationSummary);
          temp.push(point);
        });
        this.itemsTab[1].count = res.length;
        this.notLinkedPoints = temp;
        this.spinner.hide();
      } else {
        this.notLinkedPoints = [];
        this.spinner.hide();
      }
    });
  }

  //* triggers when a checkbox is selected on the tables of not linked points
  receive_selected($event) {
    this.selectionsItems = $event;
  }

  //* triggers when the user changes tab
  public changeTabView(event?) {
    if (event) {
      this.activeTab = event.slug;
      this.activeIndex = event.index;
    }
    const index = this.buttonsTitleTableView.map((x) => x.id).indexOf('buttonsTitleTableViewReorganize');
    if (this.activeTab === 'pointsInMeetings') {
      if (index === -1 && this.rightsOfSeance.edit) {
        this.buttonsTitleTableView.splice(2, 0, {
          id: 'buttonsTitleTableViewReorganize',
          text: this.translate.instant('btn.reorganize'),
          styleClass: 'blue',
          icon: 'icon-reorder',
          iconPosition: 'right',
          children: [
            {
              text: this.translate.instant('reorganize.manual'),
              click: () => {
                this.openModalReorder();
              },
            },
            {
              text: this.translate.instant('numerotation.auto'),
              click: () => {
                this.numerotationAutoBack();
              },
            },
            // {
            //   text: this.translate.instant('reorganize.auto'),
            //   click: () => {
            //     this.reorderAuto();
            //   }
            // }
          ],
          click: () => {},
        });
      }
      this.getPointsInclusWithEvents();
    }
    if (this.activeTab === 'plannedPointsInMeetings') {
      if (index >= 0) {
        this.buttonsTitleTableView.splice(index, 1);
      }
      this.getPointsNonInclus();
    }
    if (this.activeTab === 'files') {
      if (index >= 0) {
        this.buttonsTitleTableView.splice(index, 1);
      }
      this.getFiles();
    }
    if (this.activeTab === 'historic') {
      if (index >= 0) {
        this.buttonsTitleTableView.splice(index, 1);
      }
      this.api.getMeetingHistoric(this.meeting.id).subscribe((historic: any) => {
        const temp = [];
        historic.map((item) => {
          const user = this.contactsAdmin.find((x) => {
            return x.id === item.modifiedBy;
          });
          item.modifiedByLabel = user ? (user.name ? user.name : user.prenom + ' ' + user.nom) : '';
          temp.push(new Historique(item));
        });
        this.meetingHistoric = temp;
      });
    }
    if (this.activeTab === 'recipients') {
      if (index >= 0) {
        this.buttonsTitleTableView.splice(index, 1);
      }
      this.getDestinataires();
    }
  }

  //* triggers when users wants to change 1 or + point's status
  public openPointStatusChangeModal(selection) {
    //! selection doit déjà être filtrée sur les points => avoir déjà le filtre sur isConclusion, isTitle, isIntroduction et .type !== 'event'
    const ref = this.dialogService.open(StatusChangeComponent, {
      data: {
        points: true,
        listOfPoints: selection,
        header: this.translate.instant('statusModifications'),
      },
      width: '70vw',
      styleClass: 'super-compos-modal-content ',
      showHeader: false,
      closable: false,
      dismissableMask: false,
      baseZIndex: 1000,
    });
    ref.onClose.subscribe((status: number) => {
      if (status) {
        async.eachSeries(
          selection,
          (item: Point, nextFile) => {
            this.api.putChangeStatusPoint(item.id, status).subscribe((res: any) => {
              this.spinner.hide();
              nextFile();
            });
          },
          () => {
            this.toastr.success('Les statuts des points ont bien été modifiés.');
            this.getPointsInclusWithEvents();
            this.getPointsNonInclus();
          }
        );
      }
    });
  }

  //*goes back in Navigator's history
  back(event) {
    //http://localhost:4300/private/meetings/details/1551
    this.router.navigate(['private', 'meetings']);
  }

  //* Return if the given number is odd
  isOdd(num) {
    return num % 2;
  }

  //* trigger when the user clicks on a point
  openPoint(event, type?) {
    const eventIdAsNumber = Number(event.id.toString().replace('.', ''));
    if (event.type === 'event') {
      const ref = this.dialogService.open(EventAddComponent, {
        data: {
          meetingId: event.seanceId,
          header: event.texte, //On prend le texte car le titre a été modifié par scss
          points: this.points,
          mode: 'update',
          pointClicked: event,
          event: event ? event : null,
        },
        width: '80vw',
        styleClass: 'super-compos-modal-content ',
        showHeader: false,
        closable: false,
        dismissableMask: false,
        baseZIndex: 1000,
      });
      ref.onClose.subscribe((res: any) => {
        this.spinner.show();
        type === 'linked' ? this.getPointsInclusWithEvents() : type === 'notLinked' ? this.getPointsNonInclus() : null;
      });
    } else if (event.isIntroduction || event.isConclusion || event.isTitle) {
      const type = event.isIntroduction ? 'Introduction' : event.isConclusion ? 'Conclusion' : 'Titre';
      this.router.navigateByUrl('/private/editor/meeting/' + this.meeting.id + '/' + type);
    } else if (event.id && !event.niveauType) {
      this.router.navigateByUrl('/private/points/detail/' + eventIdAsNumber);
    }
  }

  //* triggers when the user clicks on the "reorder" button
  onReorder() {
    this.points = this.points.filter((x) => !x.isConclusion && !x.isTitle && !x.isIntroduction && x.type !== 'event');
    this.isReordering = true;
  }

  //* Submits the automodifications of reordering points
  reorderAuto() {
    this.confirmationService.confirm({
      message: "Etes-vous certain de vouloir calculer automatiquement l'ordre des points ? ",
      key: 'detailsMeetingModal',
      accept: () => {
        let points = [];
        const arrayReorder = [];
        points = this.points.filter((x) => !x.isConclusion && !x.isTitle && !x.isIntroduction && x.type !== 'event');
        points.map((item, index) => {
          arrayReorder.push({ pointId: item.id, ordreReel: index + 1 });
        });
        const data = { numerotationListe: arrayReorder };
        this.api.putRenumerotationPoints(this.meeting.id, data).subscribe((res: any) => {
          this.toastr.success(this.translate.instant('success.pointsRenumbering'));
          this.getPointsInclusWithEvents();
        });
      },
    });
  }

  //*Triggers when users changes the order of a row
  onReorderRow(event) {
    for (let i = 0; i < event.formattedItems.length; i++) {
      event.formattedItems[i].order = i + 1;
      event.formattedItems[i].ordre = "<div class='order'>" + (i + 1) + '</div>';
      event.formattedItems[i].pointId = event.formattedItems[i].id;
      event.formattedItems[i] = new Point(event.formattedItems[i]);
    }
    this.temporaryPointsRow = event;
  }

  //* triggers when the users submits its reorder
  onConfirmReorder() {
    const arrayReorder = [];
    if (this.temporaryPointsRow && this.temporaryPointsRow.formattedItems.length > 0) {
      this.temporaryPointsRow.formattedItems.map((item, index) => {
        arrayReorder.push({ pointId: item.id, ordreReel: index + 1 });
      });
      this.isReordering = false;
      const data = { numerotationListe: arrayReorder };
      this.api.putRenumerotationPoints(this.meeting.id, data).subscribe((res: any) => {
        this.toastr.success(this.translate.instant('success.pointsRenumbering'));
        this.getPointsInclusWithEvents();
      });
    } else {
      this.isReordering = false;
      this.points = this.pointsBackUp;
    }
  }

  //* gets the datas to display the right component on screen
  public receivePointValue(event) {
    const type = Object.keys(event)[0];
    this.somethingIsSelected = true;
    if (type === 'pointId') {
      this.displayFichePoint = true;
      this.displayRTFEditor = false;
      this.displayInfos = false;
      this.pointId = event[type];
    } else if (type === 'other') {
      this.displayFichePoint = false;
      this.displayRTFEditor = true;
      this.displayInfos = false;
      this.titleIntroConclusionType = event[type];
    }
  }

  //* displays img dependingon the value given
  private showBoolean(value) {
    if (value) {
      return `<b style="color: #07E299;"><i class="bi bi-check-circle-fill"></i></b>`;
    } else if (value === -1) {
      return `<b style="color: #FFAF2F;"><i class="bi bi-hourglass-split"></i></b>`;
    } else {
      return `<b style="color: #FF5671;"></b>`;
    }
  }

  //* triggers when the users clicks on an event
  openEventModal(mode = 'create') {
    const ref = this.dialogService.open(EventAddComponent, {
      data: {
        meetingId: this.meeting.id,
        typeSeanceId: this.meeting.typeSeanceId,
        header: this.translate.instant('insertEvent'),
        points: this.points,
        mode: mode,
        pointClicked: this.selectedElementInTable,
        event: this.selectedElementInTable ? this.selectedElementInTable : null,
      },
      width: '80vw',
      styleClass: 'super-compos-modal-content ',
      showHeader: false,
      closable: false,
      dismissableMask: false,
      baseZIndex: 1000,
    });
    ref.onClose.subscribe((res: any) => {
      if (res) {
        this.getPointsInclusWithEvents();
      }
    });
  }

  //*triggers when the user clicks on the button ... on a table
  receive_menu($event) {
    this.menuEvents = $event;
    this.menuPoints = [{}];
    $event.isIntroduction || $event.isConclusion || $event.isTitle || $event.niveauType
      ? null
      : (this.selectedElementInTable = new Point($event)); //on empêche la créa de point si Titre ou Intro ou Conclusion
    if ($event.type === 'event') {
      this.menuPoints = [{}];
      this.menuPoints = [
        {
          label: "Modifier l'évenement",
          command: (data) => {
            this.openEventModal('update');
          },
        },
        {
          label: "Supprimer l'évenement",
          command: (data) => {
            this.deleteEvent();
          },
        },
      ];
    } else if ($event.isIntroduction || $event.isConclusion || $event.isTitle) {
      this.menuPoints = [{}];
      const type = $event.isIntroduction ? 'Introduction' : $event.isConclusion ? 'Conclusion' : 'Titre';
      const typeLabel = $event.isIntroduction ? "l'introduction" : $event.isConclusion ? 'la conclusion' : 'le titre';
      this.menuPoints = [
        {
          label: 'Ouvrir ' + typeLabel,
          command: (data) => {
            this.router.navigateByUrl('/private/editor/meeting/' + $event.meetingId + '/' + type);
          },
        },
      ];
    } else if ($event?.id) {
      //When you click on a point, it has an id
      // So in this case it's the modal that displays all the actions

      // Reset menuPoint for it not to show any menu. In fact in this case it's the modal that contains everything the action menu needs
      this.menuPoints = [];

      this.api.getRightsPoint($event.id).subscribe((rights: any) => {
        if (rights.edit) {
          this.userCanEdit = true;
          this.userCanDuplicate = true;
        }
        //and just add a little something for the button to still be displayed afterwards :)
        this.menuPoints = [{}];
        //Ensuite on va regarder les droits pour afficher l'action de créer un modèle de document
        if (this.rightsManagementDelibe.menu.modeleObjets) {
          if (
            this.rightsManagementAdmin.menu.delibeRight.gestionBibliotheque ||
            this.rightsManagementAdmin.menu.delibeRight.gestionBibliothequePrive
          ) {
            //on a accès coté délibé a la création d'un modèle et côté iAdmin à tous les services => Pas de check supplémentaire
            this.allowCreationModelePoint = true;
          } else if (this.rightsManagementAdmin.menu.delibeRight.gestionBibliothequeService) {
            //ici on va devoir checker si on a accès au service du point, car gestionBibliothequeService force l'utilisateur à avoir accès au service du point
            if (this.userInfos.services.indexOf(this.selectedElementInTable.serviceId) > -1) {
              this.allowCreationModelePoint = true;
            } else {
              this.allowCreationModelePoint = false;
            }
          } else {
            //Au cas ou :) Y'a des users qui n'ont pas de droit prédéfini :( Pas normal mais ca existe en local
            this.allowCreationModelePoint = false;
          }
        } else {
          //Si pas cet accès, ca sert a rien d'aller plus loin
          this.allowCreationModelePoint = false;
        }
        this.displayActionsModal = true;
      });
    }
  }

  //* trigger on a file click on its table
  receive_menu_Files(event) {
    this.fileClicked = new File(event);
  }

  //* triggers on the click of a ... button in the table of not linked buttons
  receive_menuNotLinked(event) {
    this.menuPointsNotLinked = [
      {
        label: this.translate.instant('btn.openPoint'),
        command: (data) => {
          this.openPoint(event);
        },
      },
      {
        label: this.translate.instant('btn.duplicate'),
        command: (data) => {
          this.duplicatePoint(event);
        },
      },
    ];
    this.api.getRightsPoint(event.id).subscribe((res: any) => {
      if (res.edit) {
        this.menuPointsNotLinked = [
          {
            label: this.translate.instant('btn.openPoint'),
            command: (data) => {
              this.openPoint(event);
            },
          },
          {
            label: this.translate.instant('pointModification'),
            command: (data) => {
              this.router.navigateByUrl('/private/points/update/' + event.id);
            },
          },
        ];
      } else {
        this.menuPointsNotLinked = [
          {
            label: this.translate.instant('btn.openPoint'),
            command: (data) => {
              this.openPoint(event);
            },
          },
        ];
      }
    });
  }

  //* triggers when the user wants to update the point
  updatePoint(event) {
    this.router.navigateByUrl('/private/points/update/' + event.id);
  }

  //* triggers when events should be deleted
  deleteEvent() {
    this.confirmationService.confirm({
      message: "Etes-vous certain de vouloir supprimer l'évènement? ",
      key: 'detailsMeetingModal',
      accept: () => {
        this.api.deleteventByMeetingID(this.meeting.id, this.selectedElementInTable.id).subscribe((res: any) => {
          this.toastr.success("L'évènement à bien été supprimé");
          this.getPointsInclusWithEvents();
        });
      },
    });
  }

  //* gets the recipients of the meeting
  getDestinataires() {
    this.destinataires = [];
    const tempFree = [];
    const tempUser = [];
    let tempUsersIds = [];
    let tempUserNames = [];
    async.waterfall(
      [
        (callback) => {
          this.api.getDestinatairesFullMeeting(this.meeting.id).subscribe(
            (data) => {
              this.destinataires = data;
              callback();
            },
            (error) => {
              console.error(error);
              this.spinner.hide();
              this.toastr.error(this.translate.instant('error.impossibleLoadRecipients'));
            }
          );
        },
        (callback) => {
          this.destinataires.map((dest) => {
            // destinataire libre
            if (dest.type === 'DESTINATAIRE' && !dest.contactId && !dest.contactIdiAdmin) {
              const destinataire = new Destinataire(dest);
              destinataire.libre = dest.libre;
              destinataire.name = dest.libre;
              tempFree.push(destinataire);
            }
            // destinataire basique
            if (dest.type === 'DESTINATAIRE' && dest.libre === null) {
              let contact = null;
              if (dest.contactIdiAdmin && dest.contactIdiAdmin > 0) {
                contact = this.contactsAdmin.find((x) => x.id === dest.contactIdiAdmin);
              } else if (dest.contactId && dest.contactId > 0) {
                contact = this.contacts.find((x) => x.id === dest.contactId);
              }
              if (contact) {
                const destinataire = new Destinataire(dest);
                destinataire.nom = contact.nom ? contact.nom : '';
                destinataire.prenom = contact.prenom ? contact.prenom : '';
                destinataire.name = contact.nom + ' ' + contact.prenom;
                tempUser.push(destinataire);
              }
            }
          });
          callback();
        },
        (callback) => {
          setTimeout(() => {
            tempUser.map((x) => {
              if (x.transmissionUserId) {
                tempUsersIds.push(x.transmissionUserId);
              }
            });
            callback();
          }, 500);
        },
        (callback) => {
          setTimeout(() => {
            tempUsersIds = _.uniq(tempUsersIds);
            if (tempUsersIds.length > 0) {
              this.icontactService.getUserWithDataAdmin({ UserIds: tempUsersIds }).subscribe((res: any) => {
                tempUserNames = res;
                callback();
              });
            }
          }, 500);
        },
        (callback) => {
          tempUser.map((x) => {
            if (x.transmissionUserId) {
              tempUserNames.map((name) => {
                if (name.userId === x.transmissionUserId) {
                  x.transmissionUserName =
                    (name.contactForUser.prenom ? name.contactForUser.prenom : '') +
                    ' ' +
                    (name.contactForUser.nom ? name.contactForUser.nom : '');
                }
              });
            }
          });
          tempFree.map((x) => {
            if (x.transmissionUserId) {
              tempUserNames.map((name) => {
                if (name.userId === x.transmissionUserId) {
                  x.transmissionUserName =
                    (name.contactForUser.prenom ? name.contactForUser.prenom : '') +
                    ' ' +
                    (name.contactForUser.nom ? name.contactForUser.nom : '');
                }
              });
            }
          });
          callback();
        },
        (callback) => {
          setTimeout(() => {
            let temp = [];
            temp = tempFree.concat(tempUser);
            this.destinatairesItems = _.sortBy(temp, 'transmisDate').reverse();
            callback();
          }, 500);
        },
      ],
      () => {
        this.spinner.hide();
      }
    );
  }

  //refresh destinataires
  receive_refresh(event) {
    if (event) {
      this.getDestinataires();
    }
  }

  //* Sets point(s) to be locked/unlocked
  lockUnlock(selections) {
    if (typeof selections === 'object' && !Array.isArray(selections)) {
      selections = [selections];
    }
    const pointsIds = [];
    async.waterfall([
      (cb) => {
        const difference_status = [];
        for (let i = 0; i < selections.length; i++) {
          const item = selections[i];
          if (item.modificationAFaireParContactId && item.modificationAutorisee) {
            this.toastr.error(
              'Il y a au moins un point en correction. La correction doit être terminée avant de verrouiller/déverrouiller les points.'
            );
            break;
          } else {
            difference_status.push(item.verrouille);
            pointsIds.push(item.id);
          }
        }
        if (difference_status.length > 0) {
          if (this.allAreEqual(difference_status)) {
            cb(null);
          } else {
            this.toastr.error("Certains points sont verrouillés et d'autres ne le sont pas.");
          }
        }
      },
      (cb) => {
        this.confirmationService.confirm({
          message: this.translate.instant(
            'Etes-vous certain de vouloir verrouiller/déverrouiller les points sélectionnés ? (Cette action ne supprime pas les documents générés)'
          ),
          key: 'detailsMeetingModal',
          accept: () => {
            this.api.pointsVerrouiller(this.meeting.id, { pointIds: pointsIds }).subscribe((res: any) => {
              if (res.pointIdsWithIssues > 0) {
                const pointsError = res.pointIdsWithIssues;
                this.toastr.info(
                  this.translate.instant('info.points') +
                    ' : ' +
                    pointsError.join(', ') +
                    ' ' +
                    this.translate.instant('info.cannotLock')
                );
              }
              this.toastr.success('Les modifications ont bien été appliquées');
              this.getPointsInclusWithEvents();
            });
          },
        });
      },
    ]);
  }

  //* sets point(s) to be closed/ opened
  finishUnfinishPoint(selections) {
    if (typeof selections === 'object' && !Array.isArray(selections)) {
      selections = [selections];
    }
    const pointsIds = [];
    async.waterfall([
      (cb) => {
        const difference_status = [];
        for (let i = 0; i < selections.length; i++) {
          const item = selections[i];
          if (item.modificationAFaireParContactId && item.modificationAutorisee) {
            this.toastr.error(
              'Il y a au moins un point en correction. La correction doit être terminée avant de clôturer/déclôturer les points.'
            );
            break;
          } else {
            difference_status.push(item.cloture);
            pointsIds.push(item.id);
          }
        }
        if (difference_status.length > 0) {
          if (this.allAreEqual(difference_status)) {
            cb(null);
          } else {
            this.toastr.error("Opération non autorisée. Certains points sont clôturés et d'autres ne le sont pas.");
          }
        }
      },
      (cb) => {
        this.confirmationService.confirm({
          message: this.translate.instant(
            'Etes-vous certain de vouloir clôturer/déclôturer les points sélectionnés ? (Cette action ne supprime pas les documents générés)'
          ),
          key: 'detailsMeetingModal',
          accept: () => {
            this.api.pointsCloturer(this.meeting.id, { pointIds: pointsIds }).subscribe((res: any) => {
              this.toastr.success('Les modifications ont bien été appliquées');
              this.getPointsInclusWithEvents();
            });
          },
        });
      },
    ]);
  }

  //* Allow to delete a point
  deletePoint(point) {
    this.confirmationService.confirm({
      message: this.translate.instant('removeFromMeetingConfirm', { pointName: point.title }),
      key: 'detailsMeetingModal',
      accept: () => {
        this.api.removePoint(this.meeting.id, point.id).subscribe(
          (res: any) => {
            this.toastr.success('Le point a bien été retiré de la séance');
            this.getPointsInclusWithEvents();
          },
          (err) => {
            if (err.error.code === 14101) {
              this.toastr.error(this.translate.instant('nonDeletablePointBecauseEventsLinkedTo'));
            }
          }
        );
      },
    });
  }

  //* reloads the list of point chosen by the activated tab
  reloadDatas() {
    if (this.activeTab === 'pointsInMeetings') {
      this.getPointsInclusWithEvents();
    }
    if (this.activeTab === 'plannedPointsInMeetings') {
      this.getPointsNonInclus();
    }
  }

  //* set points to be 'a corriger'
  MarkACorriger(selections) {
    if (!Array.isArray(selections)) {
      selections = [selections];
    }
    async.waterfall([
      (cb) => {
        // Si on a sélectionner que un seul point
        if (selections.length === 1) {
          const point = _.first(selections);
          if (point.cloture === '✅') {
            this.toastr.error("Le point est clôturé. Il ne peut être renvoyé à l'agent pour modification.");
          } else {
            cb(null);
          }
        } else {
          this.confirmationService.confirm({
            message: 'Voulez-vous envoyer les points aux créateurs pour modification?',
            key: 'detailsMeetingModal',
            accept: () => {
              cb(null);
            },
          });
        }
      },
      (cb) => {
        const ref = this.dialogService.open(MarktocorrectionComponent, {
          showHeader: false,
          width: '60vw',
          styleClass: 'super-compos-modal-content ',
          data: {
            header: 'Envoyer un point en correction',
            selections: selections
              ? selections
              : this.SuperTreeTable?._selection
                ? this.SuperTreeTable?._selection
                : this.superTablePointsNotLinkedList?._selection,
            mode:
              selections.length === 1
                ? 'solo'
                : this.SuperTreeTable?._selection
                  ? this.SuperTreeTable?._selection.length === 1
                    ? 'solo'
                    : 'multiple'
                  : this.superTablePointsNotLinkedList?._selection.length === 1
                    ? 'solo'
                    : 'multiple',
            meetingID: this.meeting.id,
          },
        });
        ref.onClose.subscribe((res: any) => {
          if (res) {
            this.reloadDatas();
            cb();
          }
        });
      },
    ]);
  }

  //* Sets points to have their corrections validated
  validateCorrection() {
    const isVerrouille: string =
      this.selectedElementInTable.verrouille === '✅'
        ? 'Si oui, vous ne serez plus autorisé à modifier le point ultérieurement.'
        : '';
    this.confirmationService.confirm({
      message:
        'Une correction du point vous a été demandée. Confirmez-vous cette modification comme effectuée ?' +
        isVerrouille,
      key: 'detailsMeetingModal',
      accept: () => {
        this.api.validerCorrection(this.selectedElementInTable.id).subscribe((res: any) => {
          this.toastr.success('La correction a été confirmé');
          this.reloadDatas();
        });
      },
    });
  }

  //* verify if items are all equals
  allAreEqual(array) {
    const result = array.every((element) => {
      if (element === array[0]) {
        return true;
      }
    });
    return result;
  }

  //* get the meeting's files
  getFiles(forDuplication: boolean = false) {
    return new Promise((resolve, reject) => {
      this.files = [];
      this.spinner.show();
      this.hasLoaded = false;
      this.api.getSeanceFiles(this.meeting.id).subscribe(
        (data) => {
          data.map((x) => new File(x));
          this.hasLoaded = true;
          this.files = data;
          const fileTab = this.itemsTab.find((x) => x.slug === 'files');
          fileTab ? (fileTab.count = this.files.length) : null;
          this.spinner.hide();
          resolve(true);
        },
        (err) => {
          resolve(true);
          this.files = [];
        }
      );
    });
  }

  //* gets the translations and sets the forSelections buttons
  getTradsAndForSelections() {
    return new Promise((resolve, reject) => {
      //#region setting the forSelectionPoints && forSelectionPointsNotLinked button
      this.forSelectionPointsNotLinked = [
        {
          label: this.translate.instant('statusModification'),
          tooltip: this.translate.instant('statusModification'),
          click: (selection) => {
            const dataTable = this.selectionsItems;
            const selectedData = dataTable.filter((x) => {
              return !x?.isIntroduction && !x?.isTitle && !x?.isConclusion && x?.type !== 'event';
            });
            this.openPointStatusChangeModal(selectedData);
          },
        },
      ];

      //Ajouter tout pour forSelectionPointsTree afin d'ajouter un nouveau formatArraySelection vu que mtn on peut plus se baser sur le nbre de niveaux :D
      if (this.forSelectionPoints.length <= 0)
        this.forSelectionPoints.push(
          {
            label: this.translate.instant('btn.lock'),
            tooltip: this.translate.instant('btn.lock'),
            click: (selection) => {
              const data = this.formatArraySelection(false);
              this.lockUnlock(data);
            },
          },
          {
            label: this.translate.instant('btn.done'),
            tooltip: this.translate.instant('btn.done'),
            click: (selection) => {
              const data = this.formatArraySelection(false);
              this.finishUnfinishPoint(data);
            },
          },
          {
            label: this.translate.instant('btn.marktomodify'),
            tooltip: this.translate.instant('btn.marktomodify'),
            click: (selection) => {
              const data = this.formatArraySelection(false);
              this.MarkACorriger(data);
            },
          },
          {
            label: this.translate.instant('btn.unanimouslyApproved'),
            tooltip: this.translate.instant('btn.unanimouslyApproved'),
            click: (selection) => {
              const data = this.formatArraySelection(false);
              this.approveUnanimously(data);
            },
          },
          {
            label: this.translate.instant('statusModification'),
            tooltip: this.translate.instant('statusModification'),
            click: (value, item) => {
              const data = this.formatArraySelection(false);
              this.openPointStatusChangeModal(data);
            },
          },
          {
            label: this.translate.instant('btn.postponePoints'),
            tooltip: this.translate.instant('btn.postponePoints'),
            click: () => {
              const data = this.formatArraySelection(false);
              this.reportPoint(data);
            },
          },
          {
            label: this.translate.instant('btn.generateDocuments'),
            tooltip: this.translate.instant('btn.generateDocuments'),
            click: () => {
              const data = this.formatArraySelection(false);
              this.showGenerateInLotDocsModal(data);
            },
          },
        );
        if (this.rightsOfSeance?.canGenerateExtraitConf)
          this.forSelectionPoints.push(  
          {
            label:"Générer un extrait conforme",
            tooltip:"Générer un extrait conforme",
            click: (selection) => {
              this.showGenerateExtraitConformeModal(selection);
            },
          }
        );
      if (this.forSelectionPointsTree.length <= 0)
        if(this.rightsOfSeance?.isGestionnaire) {
          this.forSelectionPointsTree.push(
            {
              label: this.translate.instant('btn.lock'),
              tooltip: this.translate.instant('btn.lock'),
              click: (selection) => {
                const data = this.formatArraySelection(true);
                this.lockUnlock(data);
              },
            },
            {
              label: this.translate.instant('btn.done'),
              tooltip: this.translate.instant('btn.done'),
              click: (selection) => {
                const data = this.formatArraySelection(true);
                this.finishUnfinishPoint(data);
              },
            },
            {
              label: this.translate.instant('btn.marktomodify'),
              tooltip: this.translate.instant('btn.marktomodify'),
              click: (selection) => {
                const data = this.formatArraySelection(true);
                this.MarkACorriger(data);
              },
            },
            {
              label: this.translate.instant('btn.unanimouslyApproved'),
              tooltip: this.translate.instant('btn.unanimouslyApproved'),
              click: (selection) => {
                const data = this.formatArraySelection(true);
                this.approveUnanimously(data);
              },
            },
            {
              label: this.translate.instant('statusModification'),
              tooltip: this.translate.instant('statusModification'),
              click: (value, item) => {
                const data = this.formatArraySelection(true);
                this.openPointStatusChangeModal(data);
              },
            },
            {
              label: this.translate.instant('btn.postponePoints'),
              tooltip: this.translate.instant('btn.postponePoints'),
              click: () => {
                const data = this.formatArraySelection(true);
                this.reportPoint(data);
              },
            },
          );
        }
        this.forSelectionPointsTree.push(
          {
            label: this.translate.instant('btn.generateDocuments'),
            tooltip: this.translate.instant('btn.generateDocuments'),
            click: () => {
              const data = this.formatArraySelection(true);
              this.showGenerateInLotDocsModal(data);
            },
          }
        );
        if (this.rightsOfSeance?.canGenerateExtraitConf)
          this.forSelectionPointsTree.push(  
          {
            label:"Générer un extrait conforme",
            tooltip:"Générer un extrait conforme",
            click: (selection) => {
              const data = this.formatArraySelection(true);
              this.showGenerateExtraitConformeModal(data);
            },
          }
        );
      //#endregion

      //#region translations
      this.menuPoints.map((x) => {
        x.label = this.translate.instant('x.label');
      });
      this.menuPointsNotLinked.map((x) => {
        x.label = this.translate.instant(x.label);
      });
      this.itemsTab.map((tab) => {
        tab.header = this.translate.instant(tab.header);
      });
      this.fileUploadChooseLabel = this.translate.instant('btn.choose');
      this.fileUploadUploadLabel = this.translate.instant('btn.upload');
      this.fileUploadCancelLabel = this.translate.instant('btn.cancel');
      //#endregion
      return resolve(true);
    });
  }

  //* reloads the points included
  reload($event) {
    if ($event) {
      this.getPointsInclusWithEvents();
    }
  }

  //* triggers when a change was applied to the meeting that needs to be triggered on this component
  changedDescriptionOfMeeting(event) {
    this.meeting.description = event;
  }

  //* sets points to be approved unanimously
  approveUnanimously(data) {
    let existingVote: boolean = false;
    for (let i = 0; i < data.length; i++) {
      if (
        data[i].pointVotes.nbrVotesGlobalAbstention > 0 ||
        data[i].pointVotes.nbrVotesGlobalNegatif > 0 ||
        data[i].pointVotes.nbrVotesGlobalPositif > 0
      ) {
        existingVote = true;
        break;
      }
    }
    if (existingVote) {
      this.confirmationService.confirm({
        message:
          "Cette opération va écraser tous les votes déjà encodés et va mettre tous les points dans un statut d'approbation à l'unanimité, voulez-vous continuer ?",
        key: 'detailsMeetingModal',
        accept: () => {
          this.setVotesApproved(data);
        },
      });
    } else {
      this.confirmationService.confirm({
        message: "Voulez-vous mettre tous les points dans un statut d'approbation à l'unanimité ?",
        key: 'detailsMeetingModal',
        accept: () => {
          this.setVotesApproved(data);
        },
      });
    }
  }

  //* sets votes to be approved
  public setVotesApproved(data) {
    const pointIds = data.map((x) => {
      return x.id;
    });
    this.api.changeMassVotes(this.meetingId, pointIds).subscribe((res: any) => {
      this.toastr.success(this.translate.instant('success.voteUpdatedUnanimously'));
      this.reloadDatas();
    });
  }
  //* gets the document templates
  getModelesDocuments() {
    this.documentsOverlayItems = [];
    return new Promise((resolve, reject) => {
      this.api.getModelesDocumentsByMeetingID(this.meetingId).subscribe(
        (res: any) => {
          if (res && res.length > 0) {
            this.documentsOverlayItems = res;
            this.modelesLV = res.map((x) => new LabelValue(x));
          }
          resolve(this.modelesLV);
        },
        (error) => {
          this.toastr.error('Impossible de charger les documents');
        }
      );
    });
  }

  generateModeleDoc() {
    this.form.reset();
    this.showRegenerateModal = true;
    this.documentAlreadyInList = false;
    this.form.patchValue({ modeleId: null });
    this.dropdownDisabled = false;
  }

  //* Gets the infos to export
  getExports() {
    this.exportItems = null;
    return new Promise((resolve, reject) => {
      this.api.getExportsDelibeMandataire(this.meetingId).subscribe((res: any) => {
        this.exportItems = res;

        if (res.lastExport?.userId) {
          const userIndex = this.iAdminUsers.map((x) => +x.userId).indexOf(+res.lastExport.userId);
          this.exportItems.lastExport.userName = this.iAdminUsers[userIndex].contactForUser?.label;
          resolve(true);
        } else {
          resolve(true);
        }
      });
    });
  }

  //* Gets the infos to export
  getExportsCitoyen() {
    this.exportItems = null;
    return new Promise((resolve, reject) => {
      async.waterfall([
        (cb) => {
          this.pointsLV = [];
          this.api.getMeetingsPointsInclus(this.meeting.id).subscribe((res: any) => {
            res.points.map((item) => {
              this.pointsLV.push({ label: item.point.objetSynthese, value: item.point.id });
            });
            cb(null);
          });
        },
        (cb) => {
          this.api.getExportsDelibeCitoyen(this.meetingId).subscribe((res: any) => {
            this.exportItemsCitoyen = res;

            if (res.lastExport?.userId) {
              const userIndex = this.iAdminUsers.map((x) => +x.userId).indexOf(+res.lastExport.userId);
              this.exportItemsCitoyen.lastExport.userName = this.iAdminUsers[userIndex].contactForUser?.label;
              resolve(true);
            } else {
              resolve(true);
            }
          });
        },
      ]);
    });
  }

  //* opens a document template
  openModelDoc(item) {
    let blob = null;
    async.waterfall([
      (cb) => {
        if (item.rights.canDownloadPdf === true) {
          this.api.downloadMeetingModeleDocument(this.meetingId, item.id).subscribe((res: any) => {
            blob = res;
            cb(null);
          });
        } else {
          this.toastr.warning("Il n'y a pas de document disponible à télécharger");
        }
      },
      (cb) => {
        //on ouvre dans un pdf viewer
        this.modalPdfViewer = true;
        this.blobViewer = URL.createObjectURL(blob);
      },
    ]);
  }

  downloadModelDoc(item) {
    this.api.downloadMeetingModeleDocument(this.meetingId, item.id).subscribe((res: any) => {
      const downloadURL = URL.createObjectURL(res);
      saveAs(downloadURL, item.description + '.pdf');
    });
  }

  //* Previews the document template
  preview(item) {
    this.spinner.show();
    this.disabledLink = true;
    this.api.previewRequestsMeetingModelDoc(this.meetingId, item.id, false).subscribe(
      (res: any) => {
        this.toastr.success("Demande d'aperçu envoyée");
        this.resetOverlay();
        this.spinner.hide();
        setTimeout(() => {
          this.disabledLink = false;
        }, 1000);
        this.getModelesDocuments();
      },
      (error) => {
        this.toastr.error("Impossible de demander l'aperçu");
        setTimeout(() => {
          this.disabledLink = false;
        }, 1000);
        this.spinner.hide();
      }
    );
  }

  //* cancels the doucment template's preview
  cancelPreview(item) {
    // change to cancel
    this.api
      .previewRequestsMeetingCancelModelDoc(this.meetingId, item.id, item.myLastGenerationRequest.id)
      .subscribe((res: any) => {
        this.toastr.success("Demande d'aperçu annulée");
        this.resetOverlay();
        this.getModelesDocuments();
      });
  }

  //*generate a document template
  generateDoc() {
    this.spinner.show();
    if (this.form.value.regenerateMode === 'update') {
      //si on est sur le radiobutton : Modifier
      window.open(
        this.globalConfig.baseUrl +
          '/private/editor/documentmodel/' +
          this.form.value.modeleId +
          '/seanceId/' +
          this.meetingId.toString() +
          '?generatedAndSaved=' +
          true,
        '_blank'
      );
      this.spinner.hide();
    } else {
      this.api
        .generateModelesDocMeeting(
          this.meetingId,
          this.form.value.modeleId,
          !this.form.value.updateBefore,
          this.form.value.updateBefore
        )
        .subscribe(
          (res: any) => {
            this.toastr.success('Votre demande a bien été enregistrée');
            this.spinner.hide();
            this.getModelesDocuments();
            this.showRegenerateModal = false;
          },
          (error) => {
            if (error.status === 403) {
              this.toastr.warning("Vous n'avez pas le droit de faire ceci");
            }
          }
        );
    }
  }

  editDocument(item) {
    //* On ouvre la modale comme generate sans qu'on puisse modifier le modele dans le dropdown qui est sélectionner.
    //* Si on coche Regénérer et remplacer et on modifie avant de générer : Il faut downloadRtf et l'ouvrir dans l'éditeur ( le document n'est pas encore pret, il va etre recréer, refusionner et remerger et puis il aura une notif.) AutoSave = false
    //* Si on coche Regénérer et remplacer sans modifie avant de générer : méthode generate avec autoSave = true
    //* Si on coche modifier : on fait un downloadRtf déjà générer et l'ouvrir dans l'éditeur
    this.dropdownDisabled = true;
    this.form.reset();
    this.showRegenerateModal = true;
    this.actualDocumentInModal = item;
    this.form.patchValue({
      modeleId: item.id,
    });
    item.value = item.id;
    this.checkIfAlreadyInDocumentListPoint(item);
  }

  //* Checks if the document sent is already in the list
  checkIfAlreadyInDocumentListPoint(event) {
    if (event.value) {
      const check = this.documentsOverlayItems.find((x) => x.id === event.value);
      if (check.isAlreadyGeneratedAndSaved) {
        this.documentAlreadyInList = true;
      } else {
        this.documentAlreadyInList = false;
      }
    }
  }

  //* Changes the justify option and sets it in localStorage
  changeJustifyOptions() {
    localStorage.setItem('iDelibeSavedDisplayViewMeeting', this.currentDisposition);
  }

  numerotationAutoBack() {
    this.api.numerotationAuto(this.meeting.id).subscribe((res: any) => {
      this.getPointsInclusWithEvents().then((res: any) => {
        this.toastr.success('Numérotation des points effectuées avec succès');
      });
    });
  }

  resetOverlay() {
    //on va reset l'overlaypanel pour qu'il s'adapte en taille
    const target = document.getElementById('buttonsTitleTableViewDocument');
    this.overlayButton.toggle(this.saveEventClickForReloadOverlayButton, target);
  }

  setActualDocument(event) {
    this.actualDocumentInModal = this.modelesLV.find((x) => x.id === event.value);
  }

  openModalReorder() {
    let columnsSimple = [];
    async.waterfall([
      (cb) => {
        this.api.getMeetingsPointsInclus(this.meeting.id).subscribe((res: any) => {
          this.points = res.points.map((item) => (item = new Point(item, this.globalElement)));
          cb(null);
        });
      },
      (cb) => {
        columnsSimple = [
          {
            field: 'title',
            header: 'Titre',
          },
          {
            field: 'trueOrder',
            header: 'Ordre Réel',
          },
          {
            field: 'ordreDocs',
            header: 'Ordre Document',
          },
          {
            field: 'triService',
            header: 'Passage service',
          },
          {
            field: 'serviceLabel',
            header: 'Service',
          },
          {
            field: 'matiere',
            header: 'Matière',
          },
        ];
        if (this.getConfiguration.delibConfig.masquerOrdrePassageService) {
          const index = columnsSimple.map((x) => x.field).indexOf('triService');
          columnsSimple.splice(index, 1);
        }
        cb(null);
      },
      (cb) => {
        const ref = this.dialogService.open(TableWithInputComponent, {
          data: {
            points: this.points,
            meetingID: this.meeting.id,
            columns: columnsSimple,
            getConfiguration: this.getConfiguration,
            header: 'Réorganisation manuelle',
          },
          width: '90vw',
          styleClass: 'super-compos-modal-content ',
          showHeader: false,
          closable: false,
          dismissableMask: false,
          baseZIndex: 1000,
        });
        ref.onClose.subscribe((res: any) => {
          if (res) {
            async.eachSeries(
              res.numerotationListe,
              (data: any, next) => {
                data.ordreReel = +data.ordreReel;
                next();
              },
              () => {
                this.api.putRenumerotationPoints(this.meeting.id, res).subscribe((res: any) => {
                  this.getPointsInclusWithEvents().then((res: any) => {
                    this.toastr.success('Renumerotation des points effectuées avec succès');
                  });
                });
              }
            );
          } else {
            this.getPointsInclusWithEvents();
          }
        });
      },
    ]);
  }

  pointUpDown(event) {
    let firstPointIndex = null;
    let firstPoint = null;
    let secondPoint = null;
    let data = null;
    this.api.getMeetingsPointsInclus(this.meeting.id).subscribe((res: any) => {
      this.points = res.points.map((item) => (item = new Point(item, this.globalElement)));

      // On récupère l'index de l'élément qu'on a décider de monter ou descendre
      firstPointIndex = this.points.findIndex((x) => x.id === event.point.id);
      firstPoint = this.points[firstPointIndex];
      if (event.direction === 'up' && firstPointIndex !== 0) {
        secondPoint = this.points[firstPointIndex - 1];
      }
      if (event.direction === 'down' && firstPointIndex !== this.points.length - 1) {
        secondPoint = this.points[firstPointIndex + 1];
      }
      // on a qu'a inverser leurs propriétés ordre et ordreRéel
      if (secondPoint !== null) {
        data = {
          numerotationListe: [
            {
              pointId: firstPoint.id,
              ordre: secondPoint.ordreReel,
              ordreReel: secondPoint.ordreReel,
              triService: firstPoint.triService,
            },
            {
              pointId: secondPoint.id,
              ordre: firstPoint.ordreReel,
              ordreReel: firstPoint.ordreReel,
              triService: secondPoint.triService,
            },
          ],
        };
        this.api.putRenumerotationPoints(this.meeting.id, data).subscribe((res: any) => {
          this.getPointsInclusWithEvents().then((res: any) => {
            this.toastr.success('Renumerotation des points effectuées avec succès');
          });
        });
      }
    });
  }

  //Duplication d'un point
  duplicatePoint(point) {
    const ref = this.dialogService.open(DuplicationPointComponent, {
      data: {
        pointId: point.id,
        globalElements: this.globalElement,
        listOfContactsAdmin: this.contactsAdmin,
        contacts: this.contacts,
      },
      width: '50vw',
      styleClass: 'super-compos-modal-content ',
      showHeader: false,
      closable: false,
      dismissableMask: false,
      baseZIndex: 1000,
    });
    ref.onClose.subscribe((res: any) => {
      if (res !== false) {
        this.router.navigateByUrl('/private/points/duplicate');
      }
    });
  }

  //IDELIBE-557 / IDELIBE-559
  reportPoint(listOfPoints) {
    if (typeof listOfPoints === 'object' && !Array.isArray(listOfPoints)) {
      listOfPoints = [listOfPoints];
    }
    //tester quand l'un des elem du forselection est en reporté
    const arrayOfPointsWithStatusReported = [];
    const statutReport = this.globalElement.statuts_points.find((x) => x.typeReport === true);
    async.eachSeries(
      listOfPoints,
      (point: any, next) => {
        if (point.statutId === statutReport.id) {
          arrayOfPointsWithStatusReported.push(point);
        }
        next();
      },
      () => {
        if (arrayOfPointsWithStatusReported.length === 0) {
          const ref = this.dialogService.open(PointReportComponent, {
            data: {
              points: listOfPoints,
              seanceId: this.meeting.id,
            },
            width: '50vw',
            showHeader: false,
            styleClass: 'super-compos-modal-content ',
            closable: false,
            dismissableMask: false,
            baseZIndex: 1000,
          });
          ref.onClose.subscribe((res: any) => {
            if (res) {
              this.reloadDatas();
            }
          });
        } else {
          const startError =
            arrayOfPointsWithStatusReported.length === 1
              ? 'Un point a le statut de report. Impossible de le reporter de nouveau !'
              : 'Plusieurs points ont le statut de report. Impossible de les reporter de nouveau !';
          const resumePoint = arrayOfPointsWithStatusReported.length === 1 ? 'Point : ' : 'Les points : ';
          const listOfPointsTitles = arrayOfPointsWithStatusReported.map((x) => x.title).join(' ,');
          this.toastr.error(resumePoint + listOfPointsTitles, startError);
        }
      }
    );
  }

  openGenerateAndPatch(item) {
    this.spinner.show();
    this.documentAlreadyInList = false;
    this.form.reset();
    this.showRegenerateModal = true;
    this.form.patchValue({
      modeleId: item.id,
    });
    this.spinner.hide();
  }

  formatArraySelection(isTree: boolean) {
    //SuperTreeTable est l'id de la super-table-tree
    //SuperTablePointLinked est l'id de la super-table
    if (isTree) {
      const dataTable = this.SuperTreeTable._selection;
      const selectedData = [];
      dataTable.map((x) => {
        if (!x.data?.isIntroduction && !x.data?.isTitle && !x.data?.isConclusion && x.data?.type !== 'event') {
          selectedData.push(x.data);
        }
      });
      return selectedData;
    } else {
      const dataTable = this.SuperTablePointLinked._selection;
      const selectedData = dataTable.filter((x) => {
        return !x?.isIntroduction && !x?.isTitle && !x?.isConclusion && x?.type !== 'event';
      });
      return selectedData;
    }
  }

  showGenerateInLotDocsModal(data) {
    if (data && data.length > 0) {
      this.dialogService.open(GenerateDocLotComponent, {
        data: {
          typeSeance: this.meeting.typeSeanceId,
          points: data,
          meetingID: this.meetingId,
        },
        width: '70vw',
        styleClass: 'super-compos-modal-content ',
        showHeader: false,
        closable: false,
        dismissableMask: false,
        baseZIndex: 1000,
      });
    }
  }

  showMeetingInfos() {
    this.displayFichePoint = false;
    this.displayRTFEditor = false;
    this.displayInfos = true;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.columns.filter((col) => val.includes(col));
  }

  onFilterTableChange() {
    if (this.filterTable) {
      this.points = this.pointsBackUp;
    } else {
      this.points = this.points.filter((x) => !x.isConclusion && !x.isTitle && !x.isIntroduction);
    }
  }

  prepareContentForTreeTable(): void {
    this.points.forEach((item) => {
      let icons = '';
      item.verrouille ? (icons = icons + '<i class="bi bi-lock-fill tac-status-icon mr-2"></i>') : (icons = icons + '');
      item.enCorrection ? (icons = icons + '<i class="bi bi-pen tac-status-icon mr-2"></i>') : (icons = icons);
      item.clotureIcon === '✅'
        ? (icons = icons + '<i class="bi bi bi-check-circle-fill tac-status-icon mr-2"></i>')
        : (icons = icons);
      item.statusIcons = icons;
    });
  }

  createNewModelPoint() {
    if (this.formNewModelePoint.valid) {
      this.api
        .postModelePointFromPoint(this.detailsService.currentID, this.formNewModelePoint.value)
        .subscribe((res: any) => {
          this.toastr.success(this.translate.instant('success.modelePointCreated'));
          this.openCreationModelPointBasedOnExistingPoint = false;
        });
    } else {
      this.toastr.error(this.translate.instant('error.emptyDescription'));
    }
  }

  checkRefreshPoint() {
    this.subscription = this.api.renumerotationSuccess.subscribe((success) => {
      if (success) {
        this.getPointsInclusWithEvents();
      }
    });
  }

  showGenerateExtraitConformeModal(data) {
    const ref = this.dialogService.open(SignFileComponent, {
      data: {
        seanceId: data[0].seanceId,
        massGeneration: true,
        points: data
       },
      showHeader: true,
      header: 'Générer les extraits conformes pour les points',
      width: '50vw',
      baseZIndex: 1000,
      
    });
    ref.onClose.subscribe((res: any) => {
      if (res !== false) {
        console.info('close dialog conforme');
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createOutgoingMail(point: Point) {
    //On va juste envoyer l'id car l'objet de la délibé peut être super longue et donc faire dépasser le nbre de caractères acceptés par l'url
    const encodediDelibeID = encodeURIComponent(point.id);
    const targetUrl = this.globalConfig.frontICourrierUrl + `/public/home?infosPoint=${encodediDelibeID}`;
    window.open(targetUrl, '_blank');
  }

  handleClickAction(event: { action: ActionType, dataToSend: any }) {
    switch (event.action) {
      case ActionType.OpenPoint:
        this.openPoint(event.dataToSend);
        break;
      case ActionType.UpdatePoint:
        this.updatePoint(event.dataToSend);
        break;
      case ActionType.DuplicatePoint:
        this.duplicatePoint(event.dataToSend);
        break;
      case ActionType.PostponePoint:
        this.reportPoint(event.dataToSend);
        break;
      case ActionType.OpenCreationModelPointBasedOnExistingPoint:
        this.openCreationModelPointBasedOnExistingPoint = event.dataToSend;
        break;
      case ActionType.CreateOutgoingMail:
        this.createOutgoingMail(event.dataToSend);
        break;
      case ActionType.DeletePoint:
        this.deletePoint(event.dataToSend);
        break;
      case ActionType.Up:
      case ActionType.Down:
        this.pointUpDown({ direction: event.action, point: event.dataToSend });
        break;
      case ActionType.AddEventBefore:
        this.openEventModal('create');
        break;
      case ActionType.FinishUnfinishPoint:
        this.finishUnfinishPoint(event.dataToSend);
        break;
      case ActionType.LockUnlock:
        this.lockUnlock(event.dataToSend);
        break;
      case ActionType.MarkACorriger:
        this.MarkACorriger(event.dataToSend);
        break;
      case ActionType.ValidateCorrection:
        this.validateCorrection();
        break;
      case ActionType.ApproveUnanimously:
        this.approveUnanimously(event.dataToSend);
        break;
      case ActionType.CloseModal:
        this.displayActionsModal = event.dataToSend;
        break;
      default:
        console.error(`Unknown action: ${event.action}`);
    }
  }
}
