import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import * as _ from 'underscore';
import * as async from 'async';
import { PrimeNGConfig } from 'primeng/api';
import * as moment from 'moment';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GlobalRight } from 'src/app/shared/models/global_right';
import { Groupe_Politique } from 'src/app/shared/models/groupe_pol';
import { TypeSeance } from 'src/app/shared/models/type_seance';
import { Place } from 'src/app/shared/models/place';
import { Echevinat } from 'src/app/shared/models/echevinat';
import { Matieres } from 'src/app/shared/models/matieres';
import { TypePoint } from 'src/app/shared/models/type_point';
import { UrgenceLevel } from 'src/app/shared/models/urgence_level';
import { Status_Objets } from 'src/app/shared/models/status_objet';
import { Decision_Type } from 'src/app/shared/models/decision_type';
import { Classeur } from 'src/app/shared/models/classeur';
import { Chemise } from 'src/app/shared/models/chemise';
import { Code } from 'src/app/shared/models/code';
import { UserInfo } from 'src/app/shared/models/user-info';
import { Service } from 'src/app/shared/models/service';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { Contact } from 'src/app/shared/models/iContact/contact';
import { Roles } from 'src/app/shared/models/roles';
import { Signatures } from 'src/app/shared/models/signature';
import { ConfigApp } from 'src/app/shared/models/configApp';
import { Action_Types } from 'src/app/shared/models/actions_type';
import { Action_Status } from 'src/app/shared/models/actions_status';
import { DomSanitizer } from '@angular/platform-browser';
import { IGlobalElements } from 'src/app/shared/models/common/IGlobalElements';
import { TenantInfo } from 'src/app/shared/models/tenant-info';
import { ToastrService } from 'ngx-toastr';
import { SignalrService } from 'src/app/core/services/signalr.service';
import { Subscription, of } from 'rxjs';
import {
  NotifyUserSignalrPayload,
  SignalrDocumentMergeCompletedData,
  SignalrPayloadEventId,
} from 'src/app/shared/models/signalr_payloads';
import { GlobalConfig, Global_Class } from 'src/app/shared/models/global';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogReleasesNotesComponent } from 'src/app/shared/components/modals/dialog-releases-notes/dialog-releases-notes.component';
import { catchError, finalize } from 'rxjs/operators';
import { openWindow } from 'src/app/shared/utils/open-window.utils';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss'],
})
export class PrivateComponent implements OnInit, AfterContentInit {
  public title = 'iDélibé';
  public isAdmin: boolean;
  public user: any = null;
  public name: string;
  // tslint:disable-next-line:ban-types
  public logout: Function;
  public navLinks: Array<any> = [];
  public settingsLinks: Array<any> = [];
  public accountLinks: Array<any> = [];
  public isHome: boolean = false;
  public townName: string;
  public image: any = null;
  public webSite: string;
  public appConfig: any;
  public avatar: any;
  public themeName: string = '';
  public savedBoxID: number = null;
  public urlDoc: string;
  public clientType: string = sessionStorage.getItem('clientType');
  public globalsObject: IGlobalElements = {
    echevinats: null,
    groupes_pol: null,
    types_seances: null,
    matieres: null,
    seances: null,
    types_points: null,
    urgence_level: null,
    lieux: null,
    userInfo: null,
  };
  public globalConfigUser: GlobalConfig;
  public globalAcces: any;
  public langLinks: Array<string> = [];
  public loaded = false;
  public hasSmallSidebar: boolean = false;
  public globalRight: GlobalRight = {
    management: null,
    points_seances: null,
  };

  public notifyUserSubscription: Subscription;
  public globalConfig = JSON.parse(sessionStorage.getItem('globalConfig'));

  public allLangues = [];

  public tenantInfos: TenantInfo = null; //* Will receive all the infos about the tenant the user's in
  public globalElement;
  public statusExportMandataire;
  public currentVersion: string;
  constructor(
    private router: Router,
    private oauthService: OAuthService,
    public api: ApiService,
    public dataS: DataService,
    private translate: TranslateService,
    private config: PrimeNGConfig,
    public apiAdmin: iAdminService,
    public http: HttpClient,
    public icontactService: IContactService,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private signalrService: SignalrService,
    public dialogService: DialogService
  ) {}
  ngAfterContentInit() {
    this.dataS.hasSmallSidebar.subscribe((res: any) => {
      this.hasSmallSidebar = res;
    });
    this.dataS.getGlobalElements.subscribe((res: any) => {
      this.globalElement = new Global_Class(res);
      if (this.globalElement.isLoaded()) {
        this.statusExportMandataire = this.globalElement?.configApp.seanceExportIDelibeStatutes;
      }
    });
  }
  ngOnInit() {
    const config = JSON.parse(sessionStorage.getItem('globalConfig'));
    if (config) {
      this.currentVersion = config.currentVersion;
    }
    this.dataS.appVersion = this.currentVersion;
    this.openReleaseNotesDialog();
    this.notifyUserSubscription = this.signalrService.notifyUser.subscribe((payload: NotifyUserSignalrPayload) => {
      if (payload.payloadEventId == SignalrPayloadEventId.SeanceExportDelibeMandataireCompletedData) {
        const statut = this.statusExportMandataire.find((x) => x.id === payload.data.lastExportStatutId);
        if (statut?.id === 3) {
          this.toastr.success('L\'export mandataire de la séance "' + payload.data.seanceDescription + '" est terminé');
        } else if (statut?.id === 4) {
          this.toastr.info('L\'export mandataire de la séance "' + payload.data.seanceDescription + '" est annulé');
        } else if (statut?.id === 5) {
          this.toastr.error('L\'export mandataire de la séance "' + payload.data.seanceDescription + '" est échoué');
        }
      } else if (payload.payloadEventId == SignalrPayloadEventId.SeanceExportDelibeCitoyenCompletedData) {
        const statut = this.statusExportMandataire.find((x) => x.id === payload.data.lastExportStatutId);
        if (statut?.id === 3) {
          this.toastr.success('L\'export citoyen de la séance "' + payload.data.seanceDescription + '" est terminé');
        } else if (statut?.id === 4) {
          this.toastr.info('L\'export citoyen de la séance "' + payload.data.seanceDescription + '" est annulé');
        } else if (statut?.id === 5) {
          this.toastr.error('L\'export citoyen de la séance "' + payload.data.seanceDescription + '" est échoué');
        }
      }

      //https://inforius.atlassian.net/browse/IDELIBE-1081 pour l'idée pour retenir les infos en sessionStorage afin qu'une et une seule personne ait les notifications même si 2 sur le même compte avec pc différents.
      else if (payload.payloadEventId == SignalrPayloadEventId.DocumentMergeCompleted && payload.data.modeleId) {
        const dataReceived = payload.data as SignalrDocumentMergeCompletedData;

        if (dataReceived.statutId === 3) {
          setTimeout(
            () => {
              const date = new Date();
              const timestamp = date.toISOString();

              const getTimeStampFromLocalStorage = localStorage.getItem('SignalREventsMergeTimeStamp');
              if (
                Date.parse(getTimeStampFromLocalStorage) &&
                Date.parse(getTimeStampFromLocalStorage) > Date.parse(timestamp)
              ) {
              } else {
                localStorage.setItem('SignalREventsMergeTimeStamp', timestamp);
                let SignalREventsMerge = null;
                const isSeance = dataReceived.seanceId ? true : false;
                isSeance
                  ? this.toastr.success(
                      'Le document " ' +
                        dataReceived.modeleDescription +
                        '" est disponible pour la séance "' +
                        dataReceived.seanceDescription +
                        '"'
                    )
                  : this.toastr.success(
                      'Le document "' +
                        dataReceived.modeleDescription +
                        '" est disponible pour le point "' +
                        dataReceived.pointObjetSynthese +
                        '"'
                    );
                //on vérifie si on doit ouvrir le modèle. Si non, bah aucun soucis on s'embête même pas.
                if (dataReceived.uiOpenRtfToEdit === true) {
                  SignalREventsMerge = JSON.parse(
                    localStorage.getItem('SignalREventsMerge') ? localStorage.getItem('SignalREventsMerge') : '[]'
                  );
                  if (SignalREventsMerge && SignalREventsMerge.length > 0) {
                    //1) on vérifie si l'item est déjà dans la liste
                    const isDataPresent = SignalREventsMerge.find((x) => {
                      if (
                        x.modeleId === dataReceived.modeleId &&
                        x.seanceId === dataReceived.seanceId &&
                        x.pointId === dataReceived.pointId &&
                        moment(x.date).unix() === moment(dataReceived.lastRequestDateTime).unix()
                      ) {
                        return true;
                      }
                    });
                    //on va avancer s'il existe pas car s'il existe on ne fait RIEN :)
                    //et donc s'il n'existe pas on va l'ajouter et ouvrir l'onglet
                    if (!isDataPresent) {
                      SignalREventsMerge.push({
                        requestId: dataReceived.requestId,
                        date: dataReceived.lastRequestDateTime,
                        modeleId: dataReceived.modeleId,
                        seanceId: dataReceived.seanceId,
                        pointId: dataReceived.pointId,
                      });
                      localStorage.setItem('SignalREventsMerge', JSON.stringify(SignalREventsMerge)); //ca va faire trigger dans les onglets SAUF l'actuel
                      const event = new StorageEvent('storage', {
                        key: isSeance ? 'SignalRManualReloadSeance' : 'SignalRManualReloadPoint',
                        newValue: isSeance ? dataReceived.seanceId.toString() : dataReceived.pointId.toString(),
                      });
                      window.dispatchEvent(event); //ca va faire aussi trigger dans les onglets mais surtout dans l'onglet ACTUEL

                      isSeance
                        ? openWindow(
                            this.globalConfig.baseUrl +
                              '/private/editor/documentmodel/' +
                              dataReceived.modeleId +
                              '/seanceId/' +
                              dataReceived.seanceId.toString() +
                              '?generatedAndSaved=' +
                              false,
                              this.toastr
                          )
                        : openWindow(
                            this.globalConfig.baseUrl +
                              '/private/editor/documentmodel/' +
                              dataReceived.modeleId +
                              '/pointId/' +
                              dataReceived.pointId.toString() +
                              '?generatedAndSaved=' +
                              false,
                              this.toastr
                          );
                      //2) ensuite on vide les dates antérieures
                      const listOfIndex = [];
                      async.eachOfSeries(SignalREventsMerge, (event: any, index, next) => {
                        if (
                          event.modeleId === dataReceived.modeleId &&
                          event.seanceId === dataReceived.seanceId &&
                          event.pointId === dataReceived.pointId &&
                          moment(event.date).unix() < moment(dataReceived.lastRequestDateTime).unix()
                        ) {
                          listOfIndex.push(index);
                        }
                        next();
                      });
                      const listOfIndexReverse = listOfIndex.reverse();
                      listOfIndexReverse.map((x) => {
                        SignalREventsMerge.splice(x, 1);
                      });
                      localStorage.setItem('SignalREventsMerge', JSON.stringify(SignalREventsMerge));
                    }
                  } else {
                    //on fait un tableau parce qu'on peut avoir plusieurs objets en mm temps
                    localStorage.setItem(
                      'SignalREventsMerge',
                      JSON.stringify([
                        {
                          requestId: dataReceived.requestId,
                          date: dataReceived.lastRequestDateTime,
                          modeleId: dataReceived.modeleId,
                          seanceId: dataReceived.seanceId,
                          pointId: dataReceived.pointId,
                        },
                      ])
                    );
                    //Si ca n'existe pas c'est qu'on a pas encore eu de SygnalR donc 0 pb on ouvre direct! :D
                    isSeance
                      ? openWindow(
                          this.globalConfig.baseUrl +
                            '/private/editor/documentmodel/' +
                            dataReceived.modeleId +
                            '/seanceId/' +
                            dataReceived.seanceId.toString() +
                            '?generatedAndSaved=' +
                            false,
                          this.toastr
                        )
                      : openWindow(
                          this.globalConfig.baseUrl +
                            '/private/editor/documentmodel/' +
                            dataReceived.modeleId +
                            '/pointId/' +
                            dataReceived.pointId.toString() +
                            '?generatedAndSaved=' +
                            false,
                          this.toastr
                        );
                  }
                }
              }
            },
            Math.floor(Math.random() * (1000 - 300) + 300)
          );
          // 1000ms sera le maximum de temps pour le timeout et 300 sera le minimum. Comme cela le timeout sera décalé entre chaque passage
        } else {
          if (dataReceived.statutId === 6) {
            if (
              dataReceived.seanceDescription &&
              dataReceived.seanceDescription !== null &&
              dataReceived.seanceDescription !== ''
            ) {
              this.toastr.error(
                'Une erreur est survenue lors de la génération du document "' +
                  dataReceived.modeleDescription +
                  ' " provenant de la séance "' +
                  dataReceived.seanceDescription +
                  '"'
              );
            }
            if (
              dataReceived.pointObjetSynthese &&
              dataReceived.pointObjetSynthese !== null &&
              dataReceived.pointObjetSynthese !== ''
            ) {
              this.toastr.error(
                'Une erreur est survenue lors de la génération du document "' +
                  dataReceived.modeleDescription +
                  '" provenant du point "' +
                  dataReceived.pointObjetSynthese +
                  '"'
              );
            }
          }
        }
      } else if(payload.payloadEventId == SignalrPayloadEventId.ExtraitConformeMergeCompleted && payload.data.modeleId) {
        this.toastr.success('L\'extrait conforme: "' + payload.data.modeleDescription + '" est terminé pour le point: "' + payload.data.pointObjetSynthese +'"');
      }
    });
    async.waterfall(
      [
        (callback) => {
          this.getRights().then((value) => {
            if (value) {
              callback(null);
            }
          });
        },
        (callback) => {
          this.getTenant().then(() => {
            callback();
          });
        },
        (callback) => {
          this.apiAdmin.getUserInfo().subscribe((userInfo: any) => {
            if (userInfo) {
              this.globalsObject.userInfo = new UserInfo(userInfo);
              callback();
            }
          });
        },
        (callback) => {
          this.apiAdmin
            .getRightsManagement()
            .pipe(
              catchError((error) => {
                this.toastr.error('Une erreur est survenue lors de la récupération des droits de gestion');
                console.error(error);
                return of(null);
              }),
              finalize(() => callback())
            )
            .subscribe((res: any) => {
              if (res) {
                this.globalAcces = res.menu;
              }
            });
        },
        (callback) => {
          const arrayOfKeys = [
            'sidebar.meetings',
            'sidebar.points',
            'sidebar.admin',
            'sidebar.overall',
            'sidebar.support',
            'sidebar.contacts',
            'sidebar.actions',
            'sidebar.iscan',
            'sidebar.3P',
          ];
          this.translate.get(arrayOfKeys).subscribe((res) => {
            if (this.globalRight.management && this.globalRight.management?.menu?.accessDelibe) {
              this.navLinks.push(
                {
                  title: res['sidebar.meetings'],
                  img: './assets/img/sidebar/Seances.svg',
                  click: () => {
                    this.router.navigateByUrl('/private/meetings');
                  },
                },
                {
                  title: res['sidebar.points'],
                  img: './assets/img/sidebar/Points.svg',
                  click: () => {
                    this.router.navigateByUrl('/private/points');
                  },
                },
                {
                  title: res['sidebar.actions'],
                  icon: 'fas fa-calendar-check',
                  click: () => {
                    this.router.navigateByUrl('/private/actions');
                  },
                }
              );
              if (this.globalConfigUser?.delibConfig?.is3PEnabled && this.globalAcces?.delibeRight?.import3P) {
                const newLink = {
                  title: res['sidebar.3P'],
                  icon: 'bi bi-buildings-fill',
                  click: () => {
                    this.router.navigateByUrl('/private/points/import3P');
                  },
                };

                const index = this.navLinks.findIndex((link) => link.title === res['sidebar.meetings']);
                if (index !== -1) {
                  this.navLinks.splice(index + 2, 0, newLink);
                } else {
                  this.navLinks.push(newLink);
                }
              }
            }
            this.navLinks.push({
              title: res['sidebar.iscan'],
              icon: 'fas fa-scanner-image',
              click: () => {
                this.router.navigateByUrl('/private/iscan');
              },
            });

            if (this.globalRight.management && this.globalRight.management?.menu?.contacts) {
              this.navLinks.push({
                title: res['sidebar.contacts'],
                icon: 'fas fa-address-book',
                click: () => {
                  this.router.navigateByUrl('/private/contacts');
                },
              });
            }
            if (this.globalRight.management && this.globalRight.management?.viewMenuIAdmin) {
              this.navLinks.push({
                title: res['sidebar.admin'],
                icon: 'fas fa-user-shield',
                click: () => {
                  this.openAdmin();
                },
              });
            }
            callback();
          });
        },
        (callback) => {
          const arrayOfKeys = ['option.accountSwitch', 'option.profile', 'option.accessDelegation', 'option.logout'];
          this.translate.get(arrayOfKeys).subscribe((res) => {
            this.settingsLinks = [
              {
                title: res['option.accountSwitch'],
                icon: 'fas fa-user-circle',
                click: () => {
                  this.changeAccount();
                },
              },
              {
                title: res['option.accessDelegation'],
                icon: 'bi bi-person-fill-up',
                click: () => {
                  this.delegationAcces();
                },
              },
              // { title: res['option.profile'], icon: 'fas fa-user', click: () => { this.router.navigateByUrl('/private/profil') } },
              {
                title: res['option.logout'],
                icon: 'fas fa-door-open',
                click: () => {
                  this.logoutFct();
                },
              },
            ];
            callback();
          });
        },
        (callback) => {
          this.getUser();
          callback();
        },
        (callback) => {
          if (localStorage.getItem('iDelibe_icourrier_id_send') !== null) {
            this.toastr.info("Création d'un point depuis iCourrier, redirection vers l'écran de création");
            this.router.navigateByUrl('/private/points/create-from-icourrier');
            callback(null);
          } else {
            callback(null);
          }
        },
      ],
      () => {
        this.isAdmin = true;
        this.townName = sessionStorage.getItem('townName');
        this.themeName = null;
        this.logout = () => {
          this.logoutFct();
        };
        this.getGlobals();
        this.getContacts();
      }
    );
  }

  // openWindow(url: string): void {
  //   const popup = window.open(url, '_blank');
  //   if (!popup || popup.closed || typeof popup.closed === 'undefined') {
  //     // Popup was blocked
  //     this.toastr.error('La fenêtre pop-up a été bloquée par votre navigateur.', 'Erreur');
  //   }
  // }

  public receive_item(event) {}

  public logoutFct() {
    this.oauthService.logOut();
    localStorage.removeItem('boxes');
    sessionStorage.clear();
    this.router.navigate(['/public/home']);
  }

  public changeAccount() {
    const config = JSON.parse(sessionStorage.getItem('globalConfig'));
    window.location.href = `${config.issuer}/Account/SwitchUsername?clientId=${
      config.clientId
    }&redirectUri=${encodeURIComponent(config.postLogoutRedirectUri)}`;
    sessionStorage.clear();
  }

  public delegationAcces() {
    const config = JSON.parse(sessionStorage.getItem('globalConfig'));
    window.location.href = `${config.issuer}/Support/DelegationRequests?clientid=${
      config.clientId
    }&clientUri=${encodeURIComponent(config.postLogoutRedirectUri)}`;
  }

  receive_langChg(lang) {
    localStorage.setItem('lang', lang);

    this.translate.setDefaultLang(lang);
    this.translate.get('primeng').subscribe((res) => this.config.setTranslation(res));
    moment.locale(lang.toLowerCase());
    window.location.reload();
  }

  getRights() {
    return new Promise((resolve) => {
      async.waterfall([
        (cb) => {
          this.api.getRightManagement().subscribe(
            (res) => {
              this.globalRight.management = res;
              cb(null);
            },
            (error) => {
              this.globalsObject.management = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.apiAdmin.getGlobalConfig().subscribe((res: GlobalConfig) => {
            this.globalConfigUser = res;
            cb(null);
          });
        },
        (cb) => {
          this.dataS.setGlobalsRights(this.globalRight);
          resolve(true);
        },
      ]);
    });
  }

  getContacts() {
    this.icontactService.post('/contacts/lowinformation', {}).subscribe((res: any) => {
      const contacts = res.map((x) => new Contact(x)).filter((x) => x.nePlusUtiliser === false);
      this.dataS.setAllContacts(contacts);
    });
  }

  getGlobals() {
    async.waterfall(
      [
        (cb) => {
          this.apiAdmin.getUserInfo().subscribe(
            (res: any) => {
              this.globalsObject.userInfo = new UserInfo(res);
              cb(null);
            },
            (error) => {
              this.globalsObject.userInfo = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.api.getConfiguration().subscribe(
            (res: any) => {
              this.globalsObject.configApp = new ConfigApp(res);
              cb(null);
            },
            (error) => {
              this.globalsObject.configApp = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.api.getAllActionsTypes().subscribe(
            (res: any) => {
              this.globalsObject.actions_type = res;
              cb(null);
            },
            (error) => {
              this.globalsObject.actions_type = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.api.getAllActionsStatutes().subscribe(
            (res: any) => {
              this.globalsObject.actions_status = res;
              cb(null);
            },
            (error) => {
              this.globalsObject.actions_status = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.api.getGlobalEchevinat().subscribe(
            (res: any) => {
              this.globalsObject.echevinats = res.map((x) => new Echevinat(x)).filter((x) => x.utilisable === true);
              cb(null);
            },
            (error) => {
              this.globalsObject.echevinats = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.api.getGlobalGroupesPol().subscribe(
            (res: any) => {
              this.globalsObject.groupes_pol = res.map((x) => new Groupe_Politique(x));
              cb(null);
            },
            (error) => {
              this.globalsObject.groupes_pol = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.api.getGlobalTypesSeances().subscribe(
            (res: any) => {
              this.globalsObject.types_seances = res.map((x) => new TypeSeance(x));
              cb(null);
            },
            (error) => {
              this.globalsObject.types_seances = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.api.getGlobalMatieres().subscribe(
            (res: any) => {
              this.globalsObject.matieres = res.map((x) => new Matieres(x)).filter((x) => x.utilisable === true);
              cb(null);
            },
            (error) => {
              this.globalsObject.matieres = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.api.getGlobalTypePoints().subscribe(
            (res: any) => {
              this.globalsObject.types_points = res.map((x) => new TypePoint(x)).filter((x) => x.utilisable === true);
              cb(null);
            },
            (error) => {
              this.globalsObject.types_points = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.api.getGlobalUrgenceLevel().subscribe(
            (res: any) => {
              this.globalsObject.urgence_level = res
                .map((x) => new UrgenceLevel(x))
                .filter((x) => x.deliberation === true);
              cb(null);
            },
            (error) => {
              this.globalsObject.urgence_level = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.api.getGlobalAllPointsStatuts().subscribe(
            (res: any) => {
              this.globalsObject.statuts_points = res
                .map((x) => new Status_Objets(x))
                .filter((x) => x.utilisable === true);
              cb(null);
            },
            (error) => {
              this.globalsObject.statuts_points = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.api.getGlobalDecisionType().subscribe(
            (res: any) => {
              this.globalsObject.decision_type = res
                .map((x) => new Decision_Type(x))
                .filter((x) => x.utilisable === true);
              cb(null);
            },
            (error) => {
              this.globalsObject.decision_type = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.apiAdmin.getGlobalClassementCode([]).subscribe(
            (res: any) => {
              this.globalsObject.classement_code = res.map((x) => new Code(x));
              cb(null);
            },
            (error) => {
              this.globalsObject.classement_code = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.apiAdmin.getGlobalClasseurs({}).subscribe(
            (res: any) => {
              this.globalsObject.classeurs = res.map((x) => new Classeur(x));
              cb(null);
            },
            (error) => {
              this.globalsObject.classeurs = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.apiAdmin.getGlobalChemises({}).subscribe(
            (res: any) => {
              this.globalsObject.chemises = res.map((x) => new Chemise(x));
              cb(null);
            },
            (error) => {
              this.globalsObject.chemises = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.api.getGlobalLieux().subscribe(
            (res: any) => {
              this.globalsObject.lieux = res.map((x) => new Place(x));
              cb(null);
            },
            (error) => {
              this.globalsObject.lieux = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.api.getRightsPointsSeances().subscribe(
            (res: any) => {
              this.globalsObject.rightsPointsSeances = res;
              cb();
            },
            (error) => {
              this.globalsObject.rightsPointsSeances = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.apiAdmin.getGlobalServices().subscribe(
            (res: any) => {
              res.map((item) => {
                new Service(item);
                if (item.description) {
                  let parent = [];
                  parent = res.filter((x) => x.id === item.parentId);
                  item.description =
                    parent.length > 0 ? parent[0].description + ' / ' + item.description : item.description;
                }
              });
              this.globalsObject.services = res;
              cb(null);
            },
            (error) => {
              this.globalsObject.services = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.apiAdmin.getGlobalRoles().subscribe(
            (res: any) => {
              this.globalsObject.roles = res.map((x) => new Roles(x));
              cb(null);
            },
            (error) => {
              this.globalsObject.roles = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.api.getGlobalSignature().subscribe(
            (res: any) => {
              this.globalsObject.signatures = res.map((x) => new Signatures(x));
              cb(null);
            },
            (error) => {
              this.globalsObject.signatures = undefined;
              cb(null);
            }
          );
        },
        (cb) => {
          this.signalrService.register();
          this.signalrService.startConnection(
            () => cb(null),
            () => cb(null)
          );
        },
      ],
      () => {
        this.loaded = true;
        this.dataS.setCurrentGlobalElements(this.globalsObject);
      }
    );
  }

  public getUser() {
    async.waterfall([
      (callback) => {
        this.apiAdmin.getGlobalLangues().subscribe((res: any) => {
          this.allLangues = res;
          callback();
        });
      },
      (callback) => {
        this.apiAdmin.getUserInfo().subscribe((userInfo: any) => {
          this.icontactService.getContactAdmin(userInfo.contactId).subscribe((response: any) => {
            userInfo.firstname = response.prenom;
            userInfo.lastname = response.nom;
            const contactLanguage = this.allLangues.find((x) => x.id === response.langueId);
            if (contactLanguage && contactLanguage !== null && contactLanguage !== undefined) {
              sessionStorage.setItem('langue', JSON.stringify(contactLanguage));
            } else {
              sessionStorage.setItem('langue', JSON.stringify({ codeLangue: 'FR' }));
            }
            setTimeout(() => {
              this.user = userInfo;
              this.getAvatar();
            }, 1000);
            callback();
          });
        });
      },
    ]);
  }

  getAvatar() {
    this.api.getAvatar().subscribe(
      (res: any) => {
        if (res) {
          this.user.avatar = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(res));
        } else {
          this.user.avatar = './assets/img/avatar.svg';
        }
      },
      (error) => {
        if (error) {
          this.user.avatar = './assets/img/avatar.svg';
        }
      }
    );
  }

  openAdmin() {
    const jsonFile = environment.production ? `configProd` : `config`;
    this.http.get(`assets/config/${jsonFile}.json`).subscribe((data: any) => {
      openWindow(data.frontIAdminUrl, this.toastr);
    });
  }

  getTenant() {
    return new Promise((resolve, reject) => {
      this.apiAdmin.getTenant().subscribe(
        (res: TenantInfo) => {
          this.tenantInfos = res;
          if (this.tenantInfos) {
            const urlIcourrierBaseAPI = this.tenantInfos.applications.find((x) => x.name === 'iCourrier');
            if (urlIcourrierBaseAPI) {
              this.api.setIcourrierApiURL(urlIcourrierBaseAPI.apiAddress + '/api/v2');
            }
          }
          resolve(true);
        },
        (err) => {
          this.toastr.error('Une erreur est survenue dans la récupération du tenant!');
          resolve(false);
        }
      );
    });
  }

  openReleaseNotesDialog() {
    if (localStorage.getItem('last_version_loaded') !== this.currentVersion) {
      const dialog = this.dialogService.open(DialogReleasesNotesComponent, {
        width: '60vw',
        data: {
          actualVersion: this.currentVersion,
          url: this.urlDoc,
        },
      });
      dialog.onClose.subscribe(() => {
        localStorage.setItem('last_version_loaded', this.currentVersion);
      });
    }
  }
}
