export class Rue {
    public id: any;
    public codePostal?: string;
    public codeRue?: string;
    public codeLangue?: string;
    public rueNom?: string;
    public nameFr?:string;
    public streetCode?:number;
    public postalCode?:number;
    public municipalityFr?:string;
    public municipalityId?:number;
    public autoCompleteField?:string;
    constructor(data){
        for (const prop in data) {
            if (data.hasOwnProperty(prop)) {
                this[prop] = data[prop];
            }
        }
        this.streetCode = +this.streetCode;
        this.postalCode = +this.postalCode;
        this.municipalityId = +this.municipalityId;
        if (typeof this.id === 'string'){
            const idWithOutString = this.id.substring(1);
            this.id = +idWithOutString;
        }
        if ( this.nameFr ){
            this.autoCompleteField = this.nameFr;
        } else {
            this.autoCompleteField = this.rueNom;
        }
    }
}
