import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import { Global_Class } from 'src/app/shared/models/global';
import * as async from 'async';
import moment from 'moment';
import { LabelValue } from 'src/app/shared/models/labelvalue';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';

@Component({
  selector: 'app-point-report',
  templateUrl: './point-report.component.html',
  styleUrls: ['./point-report.component.scss'],
})
export class PointReportComponent implements OnInit {
  public formReportPoint = new FormGroup({
    files: new FormControl(false),
    duplicateInformationsFiles: new FormControl(false),
    redaction: new FormControl(false),
    keepPointRedacteur: new FormControl(false),
    duplicateAnnexes: new FormControl(false),
    pointIds: new FormControl(null, Validators.required),
    typeSeanceId: new FormControl(null),
    targetSeanceId: new FormControl(null, Validators.required),
    message: new FormControl(null),
  });
  public seances = [];
  public globalElements: Global_Class = null;
  public types_seances_LV: Array<LabelValue> = [];
  public seanceId = null; //seanceId is the seance id from which all the chosen points are from

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public api: ApiService,
    public translate: TranslateService,
    public data: DataService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    async.parallel(
      [
        (callback) => {
          this.data.getGlobalElements.subscribe((res: any) => {
            this.globalElements = new Global_Class(res);
            callback();
          });
        },
        (callback) => {
          this.seanceId = this.config.data.seanceId;
          //J'envoie le(s) point(s) directement au cas ou un jour on voudrait afficher + d'infos, genre la liste des points ;)
          const ids = [];
          const points = this.config.data.points;
          async.eachSeries(
            points,
            (point: any, next) => {
              ids.push(point.id);
              next();
            },
            () => {
              this.formReportPoint.patchValue({ pointIds: ids });
              callback();
            }
          );
        },
        (callback) => {
          this.api.getRightsPointsSeances().subscribe((res: any) => {
            res.typeSeanceRights.forEach((x) => {
              if (x.addPoint) {
                this.globalElements.types_seances_LV.map((x) => x.value).indexOf(x.typeSeanceId) > -1
                  ? this.types_seances_LV.push(
                      this.globalElements.types_seances_LV[
                        this.globalElements.types_seances_LV.map((x) => x.value).indexOf(x.typeSeanceId)
                      ]
                    )
                  : null;
              }
            });
            this.types_seances_LV = _.sortBy(this.types_seances_LV, 'label'); //Bizarre, obligé de faire ca sinon ca sort pas en html... Dans ce compo' uniquement
            callback();
          });
        },
      ],
      () => {
        this.spinner.hide();
      }
    );
  }

  selectedMeetingPoint($event) {
    if ($event?.value) {
      const currentTypeSeanceRight = this.globalElements.rightsPointsSeances.typeSeanceRights.find(
        (x) => x.typeSeanceId === $event.value
      );
      async.parallel([
        (callback) => {
          const data = {
            TypeSeanceIds: [this.formReportPoint.value.typeSeanceId],
            DateDebut: moment().format('YYYY-MM-DD'),
            Cloture: false,
            pageSize: 500,
          };
          if (currentTypeSeanceRight.isGestionnaire) {
            delete data.DateDebut;
            //le gestionnaire à accès à tout => les séances de n'imp quand + les ouvertes + les préparées
          }
          this.api.getMeetingsSearch(data).subscribe((data: any) => {
            this.seances = [];
            data.items.map((x) => {
              const formattedDate = moment(x.date, 'YYYYMMDD').format(this.translate.instant('format.formatDateSeance'));
              const dateForOrdre = moment(x.date, 'YYYYMMDD').unix();
              this.seances.push({
                label: x.description
                  ? x.description
                  : this.translate.instant('seanceRemplaceDescriptionEmpty', { date: formattedDate }),
                value: x.id,
                date: dateForOrdre,
              });
            });
            callback();
          });
        },
      ]);
    }
  }

  clearTypeSeance() {
    this.formReportPoint.patchValue({ targetSeanceId: null });
  }

  submitReport() {
    this.translate
      .get(['error.pleaseCompleteMandatoryFields', 'success.reportedPoint', 'success.reportedPoints', 'error.report'])
      .subscribe((translate: any) => {
        if (this.formReportPoint.valid) {
          const formToSend = {
            pointIds: this.formReportPoint.value.pointIds,
            targetSeanceId: this.formReportPoint.value.targetSeanceId,
            flagPointInRedaction: this.formReportPoint.value.redaction,
            keepPointRedacteur: this.formReportPoint.value.keepPointRedacteur,
            duplicateAnnexes: this.formReportPoint.value.duplicateAnnexes,
            duplicateInformationsFiles: this.formReportPoint.value.duplicateInformationsFiles,
            message: this.formReportPoint.value.message,
          };
          this.api.putReportPoints(this.seanceId, formToSend).subscribe(
            (res: any) => {
              this.formReportPoint.value.pointIds.length === 1
                ? this.toastr.success(translate['success.reportedPoint'])
                : this.toastr.success(translate['success.reportedPoints']);
              this.ref.close(true);
            },
            (HttpErrorResponse) => {
              if (HttpErrorResponse.error.status === 400) {
                HttpErrorResponse.error.errors.forEach((err) => {
                  this.toastr.error(err.message);
                });
              }
            }
          );
        } else {
          this.toastr.error(translate['error.pleaseCompleteMandatoryFields']);
        }
      });
  }
}
