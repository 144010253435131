import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private router: Router,
    public activeRoute: ActivatedRoute,
    private oauthService: OAuthService
  ) {}

  ngOnInit() {
    this.activeRoute.queryParams.subscribe((params: any) => {
      //http://localhost:4300/public/home?icourrier_id=77014&duplicateFile=true
      if (params.duplicateFile === true) {
        localStorage.setItem('iDelibe_duplicateFileFromiCourrier', 'true');
      }
      if (params && params.icourrier_id) {
        localStorage.setItem('iDelibe_icourrier_id_send', params.icourrier_id);
        this.connect();
      } else {
        this.connect();
      }
    });
  }

  login() {
    this.oauthService.initCodeFlow();
  }

  /**
   * * Perform SSO connexion if user is not connected
   */
  connect() {
    setTimeout(() => {
      const ssoClaismObj = this.oauthService.getIdentityClaims();
      if (ssoClaismObj !== null) {
        const hasAccessToken = this.oauthService.hasValidAccessToken();
        if (hasAccessToken) {
          console.info('Token found and valid --> navigate to "/private"');
          this.router.navigateByUrl('/private');
          // this.oauthService.loadUserProfile();
        } else {
          console.info('Token not found or invalid');
          // this.oauthService.initCodeFlow();
          // this.router.navigateByUrl('/');
        }
      } else {
        console.info('SSO claims object not found');
        // this.oauthService.initCodeFlow();
        // this.router.navigateByUrl('/');
      }
    }, 1000);
  }
}
