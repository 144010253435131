import { Component, OnInit, ViewChild } from '@angular/core';
import { ITableConfig, SuperColumn, SuperTableComponent } from '@devinforius/super-compos';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/core/services/api.service';
import * as async from 'async';
import * as _ from 'underscore';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-generate-doc-lot',
  templateUrl: './generate-doc-lot.component.html',
  styleUrls: ['./generate-doc-lot.component.scss'],
})
export class GenerateDocLotComponent implements OnInit {
  @ViewChild('superTableListOfPointsToGenerateDocument')
  public superTableListOfPointsToGenerateDocument: SuperTableComponent;
  public data: any;
  public columns: Array<SuperColumn> = [];
  public modeleIdToGenerateInMass: number;
  public items: Array<any> = [];
  public initialSelectionPoints: Array<any> = []; //liste des points non générés à sélectionner de base dans la super-table
  public arrayOfPointOrderGenerated: Array<any> = []; // à l'initialisation de la liste des points générés ou non au changement de modèle, on va mettre dans cet array la liste des ordres rééls des points déjà générés pour l'afficher dans la div d'infos
  public listOfPointOrderGenerated: string = null; //cette variable va contenir le string en join(', ) de l'array arrayOfPointOrderGenerated
  public atTheDateOf = '';
  public modelesDocPoint = [];
  public idToOverwrite = [];
  public selectAll: boolean = false;
  // public displayDocumentsExtraitModal: boolean = false;
  public actionTabs = [
    {
      title: 'Liste de points',
      slug: 'listPointsTabs',
      active: true,
      count: 0,
      tooltip: '',
      icon: ''
    }
  ];
  activeTabSlug: string = 'listPointsTabs';
  public tableConfigurations: ITableConfig;

  constructor(
    public ref: DynamicDialogRef,
    public dataDialog: DynamicDialogConfig,
    public api: ApiService,
    public toastr: ToastrService,
    public translate: TranslateService,
    public confirmationService: ConfirmationService,
    public spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    let allModelesDoc = [];
    if (this.dataDialog) {
      this.data = this.dataDialog.data;
      async.waterfall(
        [
          (callback) => {
            this.translate.get('atTheDateOf').subscribe((translation) => {
              this.atTheDateOf = translation;
              callback();
            });
          },
          (callback) => {
            this.api.getGlobalModelesDoc().subscribe((res: any) => {
              allModelesDoc = res;
              callback();
            });
          },
          (callback) => {
            this.api.getModeleIdByTypeSeance(this.dataDialog.data.typeSeance, 'Point').subscribe((res: any) => {
              //res est une liste d'id des modèles
              res.map((x) => {
                this.modelesDocPoint.push(allModelesDoc.find((modele) => modele.id === x));
              });
              callback();
            });
          },
        ],
        () => {
          this.getColumns();
          this.getITableConfigurations();
        }
      );
    }
  }

  getITableConfigurations() {
    this.tableConfigurations = new ITableConfig({
      scrollable: false,
      showPaginator: false,
      lazy: false,
      selectionActions: { export: false, remove: false },
      isRowClickable: false,
      height: "calc('-125px + 54vh')",
      resizableColumns: true,
      reorderableColumns: true,
      rowPerPageOptions: [50, 200, 500, 1000, 5000],
      multiselect: true,
      tableStyle: { width: '100%'}
    });
  }

  submit() {
    this.spinner.show();
    let pointsIds = this.data.points.filter((x) => !x.generated).map((x) => x.id);
    pointsIds = pointsIds.concat(this.idToOverwrite);
    const requestBody = {
      pointIds: pointsIds,
      forceGeneration: true
    }
    this.api
      .massGenerationDocumentInPoint(this.data.meetingID, this.modeleIdToGenerateInMass, requestBody)
      .subscribe((res: any) => {
        this.toastr.success('La génération des documents est en cours');
        this.spinner.hide();
        this.ref.close();
      });
  }

  selectAllDoc() {
    this.items.forEach((item) => {
      if (item.generated) {
        const el: HTMLElement = document.getElementById('generate-doc-' + item.id);
        if (this.selectAll) {
          el.classList.remove('bi-square');
          el.classList.add('bi-check-square-fill');
          if (!this.idToOverwrite.includes(item.id)) {
            this.idToOverwrite.push(item.id);
          }
        } else {
          el.classList.add('bi-square');
          el.classList.remove('bi-check-square-fill');
          this.idToOverwrite = this.idToOverwrite.filter((id: number) => id !== item.id);
        }
      }
    });
  }

  getColumns() {
    this.columns = [
      {
        field: 'pointName',
        header: this.translate.instant('Point'),
        filterable: true,
        sortable: true,
        type: 'string',
      },
      {
        field: 'generatedLabel',
        header: this.translate.instant('ducumentGenerated'),
        filterable: true,
        sortable: true,
        format: (value, item) => {
          return this.showBooleanAndDate(item);
        },
        type: 'string',

      },
      {
        field: 'fileToOverwrite',
        header: this.translate.instant('overwriteDocumentGenerated'),
        format: (column, dataModel) => {
          return dataModel.generated ? `<i id="generate-doc-${dataModel.id}" class="bi bi-square"></i>` : '';
        },
        filterable: true,
        sortable: true,
        click: (dataModel) => {
          if (dataModel.generated) {
            const el: HTMLElement = document.getElementById('generate-doc-' + dataModel.id);
            if (el.classList.contains('bi-check-square-fill')) {
              el.classList.add('bi-square');
              el.classList.remove('bi-check-square-fill');
              this.idToOverwrite = this.idToOverwrite.filter((id: number) => id !== dataModel.id);
              if (this.selectAll) {
                this.selectAll = false;
              }
            } else {
              el.classList.remove('bi-square');
              el.classList.add('bi-check-square-fill');
              if (!this.idToOverwrite.includes(dataModel.id)) {
                this.idToOverwrite.push(dataModel.id);
              }
              const count = this.items.reduce((acc, item) => {
                const el: HTMLElement = document.getElementById('generate-doc-' + item.id);
                if ((el && el.classList.contains('bi-check-square-fill')) || !item.generated) {
                  acc += 1;
                }
                return acc;
              }, 0);
              if (count === this.items.length) {
                this.selectAll = true;
              }
            }
          }
        },
      }
    ];
  }

  checkIfDocumentAlreadyForModelPoint(modeleID) {
    this.selectAll = false;
    this.idToOverwrite = [];
    if (modeleID) {
      const pointIds: Array<number> = [];
      this.data.points.map((item) => {
        pointIds.push(item.id);
      });
      this.api.massGenerationCheckDocumentInPoint(this.data.meetingID, modeleID, pointIds).subscribe((res: any) => {
        this.items = [];
        this.initialSelectionPoints = [];
        this.arrayOfPointOrderGenerated = [];
        this.listOfPointOrderGenerated = null;
        if (res && res.length > 0) {
          this.data.points.forEach((point) => {
            const element = res.find((x) => x.pointId === point.id);
            if (element) {
              point.generated = element.generated;
              point.generatedDate = element.generatedDate;
              point.generatedFichierId = element.generatedFichierId;
              point.pointName =
                (point.ordreReel && point.ordreReel !== null ? point.ordreReel : '') + ' ' + point.objetSynthese;
              this.items.push(point);
              if (!point.generated) {
                this.initialSelectionPoints.push(point);
              } else {
                this.arrayOfPointOrderGenerated.push(point.ordreReel);
              }
            }
          });
          this.listOfPointOrderGenerated = this.arrayOfPointOrderGenerated.join(', ');
          this.items = _.uniq(this.items, 'id');
        }
      });
    }
  }

  clearModeleId() {
    this.modeleIdToGenerateInMass = null;
    this.initialSelectionPoints = [];
    this.arrayOfPointOrderGenerated = [];
  }

  //* displays img depending on the value given
  private showBooleanAndDate(value) {
    if (value.generated) {
      let temp = `<b style="color: #07E299;"><i class="bi bi-check-circle-fill"></i></b>`;
      if (moment(value.generatedDate).isValid()) {
        temp = temp + '  ' + this.atTheDateOf + ' ' + moment(value.generatedDate).format('DD/MM/YYYY');
      }
      return temp;
    } else if (value === -1) {
      return `<b style="color: #FFAF2F;"><i class="bi bi-hourglass-split"></i></b>`;
    } else {
      return `<b style="color: #FF5671;"><i class="bi bi-x-circle-fill"></i></b>`;
    }
  }
}
