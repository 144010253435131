import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'underscore';
@Pipe({name: 'sort'})
export class ArraySortPipe implements PipeTransform {
  transform(array: Array<any>, field: string, dir?:string): any[] {

    function _sortDir(A:number,B:number, dir) {
      if (A < B) {
        return dir ? -1 : 1;
      } 
      else if (A > B) {
        return dir ? 1 : -1;
      } 
      else {
        return 0;
      }
    }

    const sortDir = dir === 'ASC' ? 1 : dir === 'DESC' ? 0 : 1;
    
    if(_.isArray(array) && array.length>0) {

      return array.sort( (a: any, b: any) => {
        let A = a[field]; 
        let B = b[field];

        if(_.isBoolean(A) && _.isBoolean(B)) {
          A = A ? 1 : 0;
          B = B ? 1 : 0;
          return _sortDir(A,B, sortDir);
        }
        else if(_.isNumber(A) && _.isNumber(B)) {
          return _sortDir(A,B, sortDir);
        }
        else if(_.isString(A) && _.isString(B)) {
          return A.toLowerCase().localeCompare(B.toLowerCase());
        }
        else if (_.isDate(A) && _.isDate(B)) {
          const _a = moment(A);
          const _b = moment(B);
          if( _a.isValid() && _b.isValid() ) {
            return _sortDir( _a.unix(), _b.unix(), sortDir);
          }
          else {
            return 0;
          }
        }
        
      });
    }
    else return array;
  }
}

@Pipe({ name: 'reverse' })
export class ReversePipe implements PipeTransform {
  transform(array: any[]) {
    return array.slice().reverse();
  }
}
