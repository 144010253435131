import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DetailsService {
  public idList: number[] = [];
  public currentID: number;

  constructor(private router: Router) {}

  public goToPreviousPoint(meetingId: number) {
    // ON VERIFIE SI ON EST DANS LA VUE POINT OU LA VUE MEETING
    //http://localhost:4300/private/meetings/details/1551/point/33147
    //http://localhost:4300/private/points/detail/33146
    const url = this.router.url.split('/');
    const comingFrom = url[2] === 'meetings' ? 'fromAMeeting' : 'fromAPoint';
    const index = this.idList.findIndex((x) => x == this.currentID);

    if (comingFrom === 'fromAPoint') {
      this.router.navigate(['private', 'points', 'detail', this.idList[index - 1]]);
    } else {
      this.router.navigate(['private', 'meetings', 'details', meetingId, 'point', this.idList[index - 1]]);
    }
    this.currentID = this.idList[index - 1];
  }

  public goToNextPoint(meetingId: number) {
    // ON VERIFIE SI ON EST DANS LA VUE POINT OU LA VUE MEETING
    //http://localhost:4300/private/meetings/details/1551/point/33147
    //http://localhost:4300/private/points/detail/33146
    const url = this.router.url.split('/');
    const comingFrom = url[2] === 'meetings' ? 'fromAMeeting' : 'fromAPoint';
    const index = this.idList.findIndex((x) => x == this.currentID);

    if (comingFrom === 'fromAPoint') {
      this.router.navigate(['private', 'points', 'detail', this.idList[index + 1]]);
    } else {
      this.router.navigate(['private', 'meetings', 'details', meetingId, 'point', this.idList[index + 1]]);
    }
    this.currentID = this.idList[index + 1];
  }
}
