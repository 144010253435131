import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'table-with-input',
  templateUrl: './table-with-input.component.html',
  styleUrls: ['./table-with-input.component.scss'],
})
export class TableWithInputComponent implements OnInit {
  public itemsArray: Array<any> = [];
  public columnsArray: Array<any> = [];
  public header: string = '';
  public getConfiguration;
  public form = new FormGroup({
    numerotationListe: new FormArray([]),
  });
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public spinner: NgxSpinnerService
  ) {}

  get numerotationListe(): FormArray {
    return this.form.get('numerotationListe') as FormArray;
  }

  ngOnInit(): void {
    this.header = this.config.data.header;
    this.getConfiguration = this.config.data.getConfiguration;
    this.itemsArray = this.config.data.points;

    this.columnsArray = this.config.data.columns;
    const numerotationListeToPatch = [];
    this.itemsArray.map((item) => {
      const data = {
        title: item.title,
        pointId: item.id,
        ordre: item.ordreDocs,
        ordreReel: item.trueOrder,
        triService: item.triService,
      };
      numerotationListeToPatch.push(data);
      this.add();
    });
    this.form.patchValue({
      numerotationListe: numerotationListeToPatch,
    });
  }

  submit() {
    this.ref.close(this.form.value);
  }

  add() {
    this.numerotationListe.push(
      new FormGroup({
        title: new FormControl(null),
        pointId: new FormControl(null),
        ordre: new FormControl(null),
        ordreReel: new FormControl(null),
        triService: new FormControl(null),
      })
    );
  }

  removeLetters(event) {
    const isNumber = /^[0-9]$/i.test(event.key);
    if (!isNumber) {
      event.preventDefault();
    }
  }
}
