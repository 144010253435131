<section *ngIf="object &&loaded">
     <div class="container-flex">

          <div class="mt-2 ml-1" *ngIf="object?.seanceLink?.id && isPointFullScreen">
               <div class="d-flex justify-content-between w-100">
                    <div class="d-flex align-items-center mr-5">
                         <img src="./assets/img/meetings.svg" style="width: 30px;" class="mr-3" />
                         <div>
                              <label class="fiche-point-content-info__label">{{'desiredPassingDate'|translate}}
                              </label>
                              <br>
                              <p class="fiche-point-content-info__text breakWord"><span class="cursor-pointer"
                                        pTooltip="Afficher la séance"
                                        (click)="goToSeance()">{{object?.seanceLink?.description}} <i
                                             class="bi bi-eye-fill ml-2"></i></span>
                              </p>
                         </div>
                    </div>
                    <div class="d-flex align-items-center">
                         <div *ngIf="allowConfidentialCol">
                              <span class="fiche-point-content-info__label fiche-point-content-info__label--grey">
                                   <span>{{object?.typePoint}}</span>
                                   <br>
                                   <span *ngIf="object?.confidentiel">{{'confidential'|translate}} <i
                                             class="bi bi-eye-slash-fill"></i></span>
                                   <span *ngIf="!object?.confidentiel">{{'notConfidential'|translate}}</span>
                              </span>
                         </div>
                    </div>
               </div>
          </div>

          <div class=" mt-3 ml-1">
               <div class="d-flex justify-content-between w-100">
                    <div class="d-flex align-items-center">
                         <div class="d-flex align-items-center mr-5" *ngIf="object?.service">
                              <img src="./assets/img/links.svg" style="width: 30px;" class="mr-3" />
                              <div>
                                   <label
                                        class="fiche-point-content-info__label">{{'linkedDepartment'|translate}}</label>
                                   <p class="fiche-point-content-info__text breakWord" [pTooltip]="object?.service"
                                        tooltipPosition="bottom">{{object?.service ? (object?.service|maxLength:25) :
                                        'noservice'|translate}}</p>
                              </div>
                         </div>
                         <div class="mr-5 d-flex align-items-center" *ngIf="object?.courrierId && displayCourrierId">
                              <div>
                                   <label class="fiche-point-content-info__label">{{'linkedMail'|translate}}</label>
                                   <p class="fiche-point-content-info__text breakWord">{{ object?.courrierId ?
                                        ('Courrier #' + object.courrierId) : 'nomail'|translate}}</p>
                              </div>
                              <p class="mb-0 orange-text" [pTooltip]="'importFileFromCourrier'|translate" (click)="importFileFromCourrierId()"><i class="bi bi-box-arrow-in-down mb-0 ml-2 tac-back-icon"></i></p>
                         </div>
                         <div class="mr-5" *ngIf="object?.origine">
                              <label class="fiche-point-content-info__label">{{'origin'|translate}}</label>
                              <p class="fiche-point-content-info__text breakWord" [pTooltip]="object?.origine"
                                   tooltipPosition="bottom">{{object?.origine ? (object?.origine|maxLength:15) :
                                   'noorigin'|translate}}</p>
                         </div>
                    </div>
                    <!-- <div class="d-flex align-items-center">
                         <div *ngIf="allowConfidentialCol">
                              <span class="fiche-point-content-info__label fiche-point-content-info__label--grey">
                                   <span>{{object?.typePoint}}</span>
                                   , <br>
                                   <span *ngIf="object?.confidentiel">{{'confidential'|translate}} <i
                                             class="bi bi-eye-slash-fill"></i></span>
                                   <span *ngIf="!object?.confidentiel">{{'notConfidential'|translate}}</span>
                              </span>
                         </div>
                    </div> -->
               </div>
          </div>

          <div class="mt-3 ml-1">
               <div class="d-flex w-100">
                    <div class="d-flex align-items-center mr-5" *ngIf="object?.matiere">
                         <img src="./assets/img/tags.svg" style="width: 30px;" class="mr-3" />
                         <div>
                              <label class="fiche-point-content-info__label">{{'matter'|translate}}</label><br>
                              <p class="fiche-point-content-info__text breakWord" [pTooltip]="object?.matiere"
                                   tooltipPosition="bottom">{{object?.matiere ? (object.matiere|maxLength:25) :
                                   'nomatiere'|translate}}</p>
                         </div>
                    </div>
                    <div *ngIf="object?.echevinatLabel" class="mr-5">
                         <label class="fiche-point-content-info__label">{{'echevinat'|translate}}</label>
                         <p class="fiche-point-content-info__text breakWord" [pTooltip]="object?.echevinatLabel"
                              tooltipPosition="bottom">{{object?.echevinatLabel ? (object.echevinatLabel) :
                              'noechevinat'|translate}}</p>
                    </div>
                    <div *ngIf="object?.agentTraitantLabel && !globalConfiguration?.delibConfig?.cacherAgentTraitant"
                         class="mr-5">
                         <label class="fiche-point-content-info__label">{{'handler'|translate}}</label>
                         <p class="fiche-point-content-info__text breakWord" [pTooltip]="object?.agentTraitantLabel"
                              tooltipPosition="bottom">{{object?.agentTraitantLabel ? (object.agentTraitantLabel) :
                              'noagent'|translate}}</p>
                    </div>
                    <div class="mr-5" *ngIf="object?.typeDecisionLabel">
                         <label class="fiche-point-content-info__label">{{'typeDecision'|translate}}</label>
                         <p class="fiche-point-content-info__text breakWord" [pTooltip]="object?.typeDecisionLabel"
                              tooltipPosition="bottom">{{object?.typeDecisionLabel ? (object?.typeDecisionLabel) :
                              'notypedecision'|translate}}</p>
                    </div>
               </div>
          </div>

          <div class="mt-3 ml-1" *ngIf="object?.classementCode">
               <div class="d-flex w-100">
                    <div class="d-flex align-items-center" *ngIf="object?.classementCode">
                         <img src="./assets/img/folders.svg" style="width: 30px;" class="mr-3" />
                         <div>
                              <p class="fiche-point-content-info__text" [pTooltip]="object?.classementCode"
                                   tooltipPosition="bottom">{{object?.classementCode ? object.classementCode :
                                   'nocode'|translate }}</p>
                              <p class="fiche-point-content-info__text ml-2"
                                   *ngIf="object?.dossierClassementDescription"
                                   [pTooltip]="object?.dossierClassementDescription" tooltipPosition="bottom"><i
                                        class="bi bi-arrow-return-right"></i> {{object?.dossierClassementDescription ?
                                   (object.dossierClassementDescription) : 'nofolder'|translate }}</p>
                              <p class="fiche-point-content-info__text ml-4"
                                   *ngIf="object?.dossierClassement2Description"
                                   [pTooltip]="object?.dossierClassement2Description" tooltipPosition="bottom"><i
                                        class="bi bi-arrow-return-right"></i> {{object?.dossierClassement2Description ?
                                   (object.dossierClassement2Description) : 'noplasticshirt'|translate }}</p>
                         </div>
                    </div>
               </div>
          </div>

          <div>
               <app-accordions-editor [point]="object" [gConfig]="globalConfig" [rights]="rightsOnPoint"
                    [writeLock]="writeLock" (writeLockUpdated)="updateWriteLock($event)"></app-accordions-editor>
          </div>
     </div>
</section>
<skeleton-card *ngIf="!object"></skeleton-card>
<p-dialog [(visible)]="openModalForFilesSelectionCourrier" styleClass="super-compos-modal-content" [modal]="true"
     [style]="{width: '30vw'}" appendTo="body" [closable]="false">
     <p-header>
          <div class="d-flex align-items-center justify-content-between">
               <h5 class="modal-title super-compos-modal-title">
                    Sélection des fichiers à importer
               </h5>
               <div class="d-flex">
                    <super-button styleClass="blue mr-3" (click)="openModalForFilesSelectionCourrier = false"
                         [text]="'btn.close'|translate"></super-button>
                    <super-button styleClass="green"
                         (click)="openModalForFilesSelectionCourrier = false; patchPointFiles()"
                         [text]="'btn.submit'|translate"></super-button>
               </div>
          </div>
     </p-header>
     <section>
          <div class="col-12">
               <super-table [initialSelection]="itemsFiles" [forselectionDefaultOption]="false"
                    #superTableFilesDuplication [forselection]="null" [items]="itemsFiles|sort:'OrdreAffichage':'ASC'"
                    [columns]="columnsFiles" id="super-files-tables-Duplication-2" [height]="'calc( 100vh - 400px)'"
                    [multiselect]="true">
               </super-table>
          </div>
     </section>
</p-dialog>