<section class="point-create mt-4" *ngIf="loaded">
  <super-title [text]="titleComponent | translate" [buttons]="buttons"></super-title>
  <section class="fiche-point-content mt-4">
    <p-tabView [(activeIndex)]="activeTab" [scrollable]="itemsTabs.length >= 6">
      <p-tabPanel *ngFor="let tab of itemsTabs; let i = index" [header]="tab.label">
        <div class="row mt-4 mb-4 justify-content-center scrollableCreate" *ngIf="activeTab === 0">
          <div class="col-lg-9 col-md-12 col-sm-12">
            <div class="alert alert-info mb-2" *ngIf="pointId">
              {{ 'allFilesTaggedAsImportWillBeImportedAsASupplementaryFileInPoint' | translate }}
            </div>
            <div class="info-title">{{ 'createPoint.title.infos' | translate }}</div>
            <section id="fullForm" class="mt-4" [formGroup]="infosForm">
              <div class="row">
                <div class="col-lg-9 col-md-12 col-sm-12">
                  <span class="info-content">{{ 'global.title' | translate }} *</span>
                  <input type="text" pInputText formControlName="objetSynthese" class="w-100" />
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12">
                  <span class="info-content">{{ 'statut' | translate }} *</span>
                  <!--  Pas de *ngIf="groupeSecuriteRightsPoints.changerStatut" car on n'est que dans la création et donc si pas de droits => dropdown vide -->
                  <p-dropdown
                    [virtualScroll]="true"
                    itemSize="30"
                    formControlName="statutId"
                    [options]="listOfStatuts"
                    styleClass="w-100"
                    [placeholder]="'selectAStatus' | translate"
                    appendTo="body"
                    [showClear]="true"
                    [filter]="true">
                    <ng-template let-item pTemplate="item">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="truncateLabel">{{ item.label }}</div>
                        <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mt-4">
                  <span class="info-content">{{ 'meetingTypeLinked' | translate }} *</span>
                  <p-dropdown
                    [virtualScroll]="true"
                    itemSize="30"
                    formControlName="typeSeanceId"
                    (onChange)="selectedMeetingPoint($event)"
                    [options]="types_seances_LV | sort: 'label' : 'ASC'"
                    styleClass="w-100"
                    [placeholder]="'selectAMeetingType' | translate"
                    appendTo="body"
                    [showClear]="true"
                    [filter]="true"
                    (onClear)="clearTypeSeance()">
                    <ng-template let-item pTemplate="item">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="truncateLabel">{{ item.label }}</div>
                        <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mt-4">
                  <span class="info-content">{{ 'meetingDate' | translate }} *</span>
                  <p-dropdown
                    [virtualScroll]="true"
                    itemSize="30"
                    *ngIf="infosForm.value.typeSeanceId"
                    optionLabel="label"
                    optionValue="value"
                    formControlName="seanceId"
                    [options]="seances"
                    styleClass="w-100"
                    [placeholder]="'selectAMeeting' | translate"
                    appendTo="body"
                    [showClear]="true"
                    [filter]="true"
                    (onChange)="displaySeanceSelected()">
                    <ng-template let-item pTemplate="item">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="truncateLabel">{{ item.label }}</div>
                        <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <span
                    *ngIf="seanceSelectedInfos"
                    [ngClass]="{
                      seanceSelectedGreen: !seanceSelectedInfos?.verrouille,
                      seanceSelectedOrange: seanceSelectedInfos?.verrouille
                    }"
                    >{{ seanceSelectedInfos?.verrouille ? 'La séance est préparée' : 'La séance est ouverte' }}</span
                  >
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
                  <span class="info-content">{{ 'department' | translate }} *</span>
                  <p-dropdown
                    formControlName="serviceId"
                    [options]="departments"
                    [virtualScroll]="true"
                    itemSize="30"
                    panelStyleClass="departmentDD"
                    styleClass="w-100"
                    [placeholder]="'selectADepartment' | translate"
                    appendTo="body"
                    [showClear]="true"
                    [filter]="true">
                    <ng-template let-item pTemplate="item">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="truncateLabel">
                          {{ item.label }}
                        </div>
                        <div>
                          <i class="fas fa-info-circle" [pTooltip]="item.label"></i>
                        </div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
                  <span class="info-content"
                    >{{ 'matter' | translate }}
                    <span *ngIf="mandatoryPointParameters.matiereObligatoire"> *</span></span
                  >
                  <p-dropdown
                    [virtualScroll]="true"
                    itemSize="30"
                    formControlName="matiereId"
                    [options]="listOfMatieres"
                    styleClass="w-100"
                    [placeholder]="'selectAMatter' | translate"
                    appendTo="body"
                    [showClear]="true"
                    [filter]="true"
                    (onChange)="changeMatter($event)">
                    <ng-template let-item pTemplate="item">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="truncateLabel">{{ item.label }}</div>
                        <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
                  <span class="info-content"
                    >{{ 'echevinat' | translate }}
                    <span *ngIf="mandatoryPointParameters.echevinatObligatoire"> *</span></span
                  >
                  <p-dropdown
                    *ngIf="infosForm.value.matiereId"
                    [virtualScroll]="true"
                    itemSize="30"
                    formControlName="echevinatId"
                    [options]="listOfEchevinats"
                    styleClass="w-100"
                    [placeholder]="'selectAnEchevinat' | translate"
                    appendTo="body"
                    [showClear]="true"
                    [filter]="true">
                    <ng-template let-item pTemplate="item">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="truncateLabel">{{ item.label }}</div>
                        <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
                  <span class="info-content">{{ 'pointType' | translate }}</span>
                  <p-dropdown
                    *ngIf="!avoidChangeObjetType"
                    [virtualScroll]="true"
                    itemSize="30"
                    formControlName="typeId"
                    [options]="listOfTypePoint"
                    styleClass="w-100"
                    [placeholder]="'selectAPointType' | translate"
                    appendTo="body"
                    [showClear]="true"
                    [filter]="true">
                    <ng-template let-item pTemplate="item">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="truncateLabel">{{ item.label }}</div>
                        <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <p *ngIf="avoidChangeObjetType">{{ avoidChangeObjetTypeLabel }}</p>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
                  <span class="info-content">{{ 'privacy' | translate }}</span>
                  <p-checkbox
                    [label]="'confidentialPoint' | translate"
                    inputId="privacy"
                    binary="true"
                    formControlName="confidentiel"></p-checkbox>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
                  <span class="info-content">{{ 'RGPDControl' | translate }}</span>
                  <p-checkbox
                    [label]="'passedRGPDControl' | translate"
                    inputId="RGPDControl"
                    binary="true"
                    formControlName="checkRgpd"></p-checkbox>
                </div>
                <div class="col-lg-8 col-md-12 col-sm-12 mt-4">
                  <span class="info-content"
                    >{{ 'origin' | translate }}
                    <span *ngIf="mandatoryPointParameters.origineObligatoire"> *</span></span
                  >
                  <input type="text" pInputText formControlName="origine" class="w-100" />
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
                  <span *ngIf="displayCourrierId" class="info-content">{{ 'linkedMail' | translate }}</span>
                  <input
                    *ngIf="displayCourrierId"
                    [readonly]="true"
                    type="number"
                    pInputText
                    formControlName="courrierId"
                    class="w-100"
                    [disabled]="true" />
                </div>
                <div
                  class="col-lg-7 col-md-12 col-sm-12 mt-4"
                  *ngIf="!globalConfiguration?.delibConfig?.cacherAgentTraitant">
                  <span class="info-content">{{ 'handler' | translate }}</span>
                  <div class="field-radiobutton d-flex align-items-center justify-content-left">
                    <div class="d-flex">
                      <p-radioButton
                        (onClick)="onClickRadioButtonHandler($event)"
                        value="users"
                        [(ngModel)]="handlerType"
                        [ngModelOptions]="{ standalone: true }"
                        inputId="users"></p-radioButton>
                      <label for="users" class="mb-0 ml-2 mr-4">{{ 'users' | translate }}</label>
                    </div>
                    <div class="w-100">
                      <p-dropdown
                        [virtualScroll]="true"
                        itemSize="30"
                        [virtualScroll]="true"
                        itemSize="30"
                        *ngIf="handlerType === 'users'"
                        [options]="listeAgentsTraitantsAdmin | sort: 'fullNameForOrdering' : 'ASC'"
                        styleClass="w-100"
                        formControlName="agentTraitantIdiAdmin"
                        [placeholder]="'selectOneOrMoreAUser' | translate"
                        appendTo="body"
                        [showClear]="true"
                        [filter]="true">
                        <ng-template let-item pTemplate="item">
                          <div class="d-flex align-items-center justify-content-between">
                            <div class="truncateLabel">{{ item.label }}</div>
                            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                  </div>
                  <div class="field-radiobutton d-flex align-items-center justify-content-left">
                    <div class="d-flex">
                      <p-radioButton
                        (onClick)="onClickRadioButtonHandler($event)"
                        value="contacts"
                        [(ngModel)]="handlerType"
                        [ngModelOptions]="{ standalone: true }"
                        inputId="contacts"></p-radioButton>
                      <label for="contacts" class="mb-0 ml-2 mr-4">{{ 'contacts' | translate }}</label>
                    </div>
                    <div class="w-100">
                      <p-dropdown
                        [virtualScroll]="true"
                        itemSize="30"
                        *ngIf="handlerType === 'contacts'"
                        styleClass="w-100"
                        [options]="listeAgentsTraitants | sort: 'fullNameForOrdering' : 'ASC'"
                        formControlName="agentTraitantId"
                        [placeholder]="'selectOneOrMoreAContact' | translate"
                        appendTo="body"
                        [showClear]="true"
                        [filter]="true">
                        <ng-template let-item pTemplate="item">
                          <div class="d-flex align-items-center justify-content-between">
                            <div class="truncateLabel">{{ item.label }}</div>
                            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                  </div>
                </div>
                <div
                  [ngClass]="{ 'offset-lg-1': !globalConfiguration?.delibConfig?.cacherAgentTraitant }"
                  class="col-lg-4 col-md-12 col-sm-12 mt-4"
                  *ngIf="!globalConfiguration?.delibConfig?.contenuMasquerUrgence">
                  <span class="info-content"
                    >{{ 'emergency' | translate }}
                    <span *ngIf="mandatoryPointParameters.urgenceObligatoire"> *</span></span
                  >
                  <p-dropdown
                    [virtualScroll]="true"
                    itemSize="30"
                    formControlName="urgenceNiveauId"
                    [options]="globalElements?.urgence_level_LV"
                    styleClass="w-100"
                    [placeholder]="'selectAnEmergency' | translate"
                    appendTo="body"
                    [showClear]="true"
                    [filter]="true">
                    <ng-template let-item pTemplate="item">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="truncateLabel">{{ item.label }}</div>
                        <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
                  <span class="info-content"
                    >{{ 'decisionType' | translate }}
                    <span *ngIf="mandatoryPointParameters.typeDecisionObligatoire"> *</span></span
                  >
                  <p-dropdown
                    [virtualScroll]="true"
                    itemSize="30"
                    formControlName="typeDecisionId"
                    [options]="globalElements?.decision_type_LV"
                    styleClass="w-100"
                    [placeholder]="'selectADecisionType' | translate"
                    appendTo="body"
                    [showClear]="true"
                    [filter]="true">
                    <ng-template let-item pTemplate="item">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="truncateLabel">{{ item.label }}</div>
                        <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </section>
            <section [formGroup]="infosForm" *ngIf="listOfCodesActives.length > 0">
              <div class="info-title-second">{{ 'classifying' | translate }}</div>
              <div class="row">
                <div class="col-12 mt-4">
                  <span class="info-content"
                    >{{ 'code' | translate }}
                    <span *ngIf="mandatoryPointParameters.classementObligatoire"> *</span></span
                  >
                  <p-dropdown
                    (onChange)="changeCode()"
                    formControlName="classementId"
                    [virtualScroll]="true"
                    itemSize="50"
                    [options]="listOfCodesActives | sort: 'label' : 'ASC'"
                    styleClass="w-100"
                    [placeholder]="'selectACode' | translate"
                    appendTo="body"
                    [showClear]="true"
                    [filter]="true">
                    <ng-template let-item pTemplate="item">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="truncateLabel">{{ item.label }}</div>
                        <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="col-12 mt-4" *ngIf="infosForm.value.classementId">
                  <span class="info-content">{{ 'folder' | translate }} </span>
                  <p-dropdown
                    (onChange)="changeFolder()"
                    formControlName="folder"
                    [virtualScroll]="true"
                    itemSize="50"
                    [options]="classeurs | sort: 'label' : 'ASC'"
                    styleClass="w-100"
                    [placeholder]="'selectAFolder' | translate"
                    appendTo="body"
                    [showClear]="true"
                    [filter]="true">
                    <ng-template let-item pTemplate="item">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="truncateLabel">{{ item.label }}</div>
                        <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="col-12 mt-4" *ngIf="infosForm.value.folder">
                  <span class="info-content">{{ 'sleeve' | translate }}</span>
                  <p-dropdown
                    formControlName="sleeve"
                    [virtualScroll]="true"
                    itemSize="50"
                    [options]="chemises | sort: 'label' : 'ASC'"
                    styleClass="w-100"
                    [placeholder]="'selectASleeve' | translate"
                    appendTo="body"
                    [showClear]="true"
                    [filter]="true">
                    <ng-template let-item pTemplate="item">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="truncateLabel">{{ item.label }}</div>
                        <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="row mt-4 mb-4 justify-content-center scrollableCreate" *ngIf="activeTab === 1">
          <div class="col-lg-9 col-md-11 col-sm-12">
            <div class="info-title">{{ 'createPoint.title.joinedFiles' | translate }}</div>
            <section class="mt-1">
              <!-- <div class="row justify-content-center superTableUploadMarginBottom">
                                    <div class="col-12">
                                    <p-fileUpload name="uploader" #uploader [customUpload]="true" [multiple]="true"
                                        (uploadHandler)="onUpload($event, uploader)" [chooseLabel]="fileUploadChooseLabel"
                                        [uploadLabel]="fileUploadUploadLabel" [cancelLabel]="fileUploadCancelLabel"
                                         fileLimit="100">
                                    </p-fileUpload>
                                    </div>
                                </div> -->
              <div class="alert alert-info" *ngIf="pointId">
                {{ 'allFilesTaggedAsImportWillBeImportedAsASupplementaryFileInPoint' | translate }}
              </div>
              <button
                id="idForSelectionButton"
                pButton
                *ngIf="selectedFiles.length > 0"
                icon="far fa-check-square"
                class="ml-2 mr-2"
                (click)="menuActions.toggle($event)"
                [pTooltip]="'btn.forSelection' | translate: { amountSelected: selectedFiles.length }"
                [label]="'btn.forSelection' | translate: { amountSelected: selectedFiles.length }"></button>
              <p-tieredMenu #menuActions [popup]="true" appendTo="body" [model]="actions"></p-tieredMenu>

              <p-table
                id="pTableAVA"
                [value]="itemFiles"
                responsiveLayout="scroll"
                [loading]="!loaded"
                [rows]="25"
                [(selection)]="selectedFiles"
                dataKey="id"
                [scrollable]="true"
                scrollHeight="50vh"
                class="ml-2 super-table w-100"
                tableStyleClass="super-table"
                [scrollable]="true"
                [paginator]="true">
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th style="width: 3rem">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>{{ 'global.title' | translate }}</th>
                    <th>{{ 'extension' | translate }}</th>
                    <th>{{ 'toBeImported' | translate }}</th>
                    <!-- <th class="minWidthFileType">{{'fileType'|translate}}</th>
                                            <th *ngIf="config.delibConfig.myeTeamActiverExport">{{'webVisibility'|translate}}</th>
                                            <th *ngIf="config.delibConfig.iCitoyenActive && !config.delibConfig.myeTeamActiverExport">{{'webVisibility'|translate}}</th>
                                            <th *ngIf="config.delibConfig.iCitoyenActive && config.delibConfig.myeTeamActiverExport"></th> -->
                    <th></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td>
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                    <td>
                      {{ rowData['description'] }}
                    </td>
                    <td>
                      {{ rowData['extension'] }}
                    </td>
                    <td>
                      <span *ngIf="rowData['import'] === true"
                        ><b style="color: #07e299"><i class="bi bi-check-circle-fill"></i></b
                      ></span>
                      <span *ngIf="rowData['import'] === false"
                        ><b style="color: #ff5671"><i class="bi bi-x-circle-fill"></i></b
                      ></span>
                    </td>
                    <!-- <td>
                                                <span *ngIf="rowData.annexeNumero; else fileIsAMainFile">
                                                    {{'annexN°'|translate}} {{rowData.annexeNumero}}
                                                </span>
                                                <ng-template #fileIsAMainFile>
                                                    {{'mainFile'|translate}}
                                                </ng-template>
                                            </td>
                                            <td *ngIf="config.delibConfig.myeTeamActiverExport">
                                                <p-checkbox [readonly]="true" [label]="'webVisibility.mandataire'|translate" inputId="mandataire" binary="true" [(ngModel)]="rowData.visibleDelibeWeb"></p-checkbox>
                                            </td>
                                            <td *ngIf="config.delibConfig.iCitoyenActive" >
                                                <p-checkbox [readonly]="true" [label]="'webVisibility.citoyen'|translate" inputId="citoyen" binary="true" [(ngModel)]="rowData.visibleDelibeWebCitoyen"></p-checkbox>
                                            </td> -->
                    <td>
                      <button
                        id="idForMenuRow{{ rowData.id }}"
                        pButton
                        icon="fas fa-ellipsis-h"
                        (click)="menuActionsRow.toggle($event)"
                        class="p-element p-button-rounded button-elipsis p-button p-component p-button-icon-only"
                        (mousedown)="selectRowData(rowData)"></button>
                      <p-tieredMenu #menuActionsRow [popup]="true" appendTo="body" [model]="actionsRow"></p-tieredMenu>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </section>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </section>
</section>

<p-dialog
  [(visible)]="openModifyAFileInModal"
  styleClass="super-compos-modal-content"
  [modal]="true"
  [style]="{ width: '50vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">
        {{ 'fileModification' | translate }}
      </h5>
      <div class="d-flex">
        <super-button
          styleClass="blue"
          (click)="openModifyAFileInModal = false"
          [text]="'btn.cancel' | translate"></super-button>
        <super-button styleClass="green ml-3" (click)="submitModificationFile()" text="Confirmer"></super-button>
      </div>
    </div>
  </p-header>
  <div [formGroup]="formFilesModification">
    <div class="row">
      <div class="col-md-12 mb-2">
        <label class="input-label d-block">{{ 'global.title' | translate }}/ {{ 'description' | translate }}</label>
        <input type="text" pInputText formControlName="description" class="w-100" />
      </div>
      <div class="col-md-2">
        <label class="input-label d-block mb-2"
          >{{ 'fileType' | translate }} <i class="fas fa-info-circle smallIcon" [pTooltip]="getTooltipContent()"></i
        ></label>
        <div class="d-flex align-items-center justify-content-start">
          <label class="input-label mr-2">{{ 'annex' | translate }}</label>
          <p-inputSwitch
            [readonly]="isAnnexReadonly()"
            formControlName="annexType"
            (onChange)="changeTypeOfFileInModification($event)"></p-inputSwitch>
        </div>
      </div>
      <div class="col-md-2" *ngIf="formFilesModification.value.fileType === true">
        <label class="input-label d-block">{{ 'annexNumber' | translate }}</label>
        <input type="number" pInputText formControlName="annexeNumero" class="w-100" />
      </div>
      <div class="col-md-4" *ngIf="config?.delibConfig.isiDelibeMandatairesEnabled">
        <label class="input-label d-block"
          >{{ 'webVisibility' | translate }} : {{ 'webVisibility.mandataire' | translate }}</label
        >
        <p-checkbox
          [label]="'webVisibility.mandataire' | translate"
          binary="true"
          formControlName="visibleDelibeWeb"></p-checkbox>
      </div>
      <div class="col-md-4" *ngIf="config?.delibConfig.isiDelibeCitoyensEnabled">
        <label class="input-label d-block"
          >{{ 'webVisibility' | translate }} : {{ 'webVisibility.citoyen' | translate }}</label
        >
        <p-checkbox
          [label]="'webVisibility.citoyen' | translate"
          binary="true"
          formControlName="visibleDelibeWebCitoyen"></p-checkbox>
      </div>
    </div>
  </div>
</p-dialog>
<p-confirmDialog
  #cd
  appendTo="body"
  styleClass="super-compos-modal-content confirmModal"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  key="create">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Non" (click)="cd.reject()"></button>
    <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="Oui" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>
<p-dialog
  styleClass="super-compos-modal-content"
  [(visible)]="confirmDialogBool"
  [modal]="true"
  [style]="{ width: '30vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">Confirmation</h5>
      <div class="d-flex">
        <super-button
          styleClass="blue"
          (click)="confirmDialogBool = false"
          [text]="'btn.close' | translate"></super-button>
      </div>
    </div>
  </p-header>
  <section>
    <p>
      Souhaitez-vous ajouter le/les fichiers <b><u>PDF</u></b> comme annexe(s) ?
    </p>
    <p>
      Attention cela ne concerne pas les autres extensions de fichiers qui sont toutes considérées comme des fichiers
      associés.
    </p>
    <div class="d-flex align-items-center justify-content-end">
      <!-- 1st part of the modal directly accessed when its opened -->
      <super-button styleClass="red mr-2" (click)="addFileAnnexe(false)" [text]="'Refuser'"></super-button>
      <super-button styleClass="green" (click)="addFileAnnexe(true)" [text]="'Confirmer'"></super-button>
    </div>
  </section>
</p-dialog>
