<div class="modal-header super-compos-modal-header">
    <h4 class="modal-title super-compos-modal-title">
        <span class="super-compos-modal-title super-compos-modal-title-span">Envoyer une réponse</span>
    </h4>

    <div class="d-flex align-items-center">
        <super-button (click)="closeModal();" text="Annuler" styleClass="mr-3 blue">
        </super-button>
        <super-button (click)="sendResponse();" text="Traiter l’action" styleClass="mr-3 ml-3 green">
        </super-button>



    </div>
</div>
<div class="modal-body">
    <form [formGroup]="actionResponse">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <h4 class="super-compos-section-h4">
                        Réponse
                    </h4>
                    <!-- <input type="text" class="super-compos-input-text form-control w-100" formControlName="Description"
                placeholder="Insérez le descriptif de l'action"> -->
                    <textarea rows="5" cols="30" placeholder="Insérez votre réponse"
                        formControlName="Response" class="super-compos-input-text form-control w-100" pInputTextarea></textarea>
                </div>
            </div>
            <div class="col-12" *ngIf="showStatutInput">
                <div class="form-group">
                    <h4 class="super-compos-section-h4">
                        Statut
                    </h4>
                    <p-dropdown [virtualScroll]="true" itemSize="30" styleClass="w-100" [options]="status|sort:'ordreAffichage':'ASC'" placeholder="Sélectionnez un statut"
                        [showClear]="true" formControlName="Statut">
                        <ng-template let-item pTemplate="item">
                            <div class="d-flex align-items-center justify-content-between">
                                    <div class="truncateLabel">{{item.label}}</div>
                                    <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-12">
              <div class="form-group">
                <p-checkbox formControlName="ResponseValidateAction" binary="true"></p-checkbox>
                  <span class="super-compos-section-h4 ml-2">
                    Marquer l'action comme traitée
                  </span>
              </div>

          </div>
        </div>


    </form>

</div>

<p-confirmDialog key="actionResponseConfirm" #cd appendTo="body" styleClass="super-compos-modal-content confirmModal"
    header="Confirmation" icon="pi pi-exclamation-triangle" baseZIndex="1000">
    <p-footer>
        <button type="button" pButton icon="pi pi-times" label="Non" (click)="cd.reject()"></button>
        <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="Oui" (click)="cd.accept()"></button>
    </p-footer>
</p-confirmDialog>
