import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DataService } from 'src/app/core/services/data.service';
import { Global_Class } from 'src/app/shared/models/global';

@Component({
  selector: 'app-meeting-search',
  templateUrl: './meeting-search.component.html',
  styleUrls: ['./meeting-search.component.scss'],
})
export class MeetingSearchComponent implements OnInit {
  public form: FormGroup = new FormGroup({
    Description: new FormControl(null),
    LieuxIds: new FormControl(null),
    Date: new FormControl(null),
    DateDebut: new FormControl(null),
    DateFin: new FormControl(null),
    TypeSeanceIds: new FormControl(null),
    IncludeDeleted: new FormControl(null),
    statutId: new FormControl(null),
    Verrouille: new FormControl(null),
    Cloture: new FormControl(null),
    Ouvert: new FormControl(null),
    countInclus: new FormControl(true),
  });
  public globalElements: Global_Class = null;
  public seanceStatus = [];
  dataReceived: any;
  @ViewChild('meetingSearchInput') meetingSearchInput: ElementRef;
  constructor(
    public dataService: DataService,
    public dialogRef: DynamicDialogRef,
    public translate: TranslateService,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.dataReceived = this.config.data;
    this.dataService.getGlobalElements.subscribe((res: any) => {
      this.globalElements = new Global_Class(res);
    });

    if (this.dataService.advancedSearchMeeting) {
      const datas = this.dataService.advancedSearchMeeting;
      delete datas.PageNumber;
      delete datas.SortOrder;
      datas.DateDebut ? (datas.DateDebut = moment(datas.DateDebut, 'YYYY-MM-DD').toDate()) : null;
      datas.Date ? (datas.Date = moment(datas.Date, 'YYYY-MM-DD').toDate()) : null;
      datas.DateFin ? (datas.DateFin = moment(datas.DateFin, 'YYYY-MM-DD').toDate()) : null;
      datas.statutId = [];
      // Fonction auxiliaire pour ajouter des éléments sans doublons
      const addUnique = (array, item) => {
        if (!array.includes(item)) {
          array.push(item);
        }
      };

      if (this.dataReceived === 'open') {
        addUnique(datas.statutId, 'open');
      }
      if (this.dataReceived === 'pending') {
        addUnique(datas.statutId, 'pending');
      }
      if (this.dataReceived === 'closed') {
        addUnique(datas.statutId, 'closed');
      }
      if (datas.Ouvert) {
        addUnique(datas.statutId, 'open');
      }
      if (datas.Verrouille) {
        addUnique(datas.statutId, 'pending');
      }
      if (datas.Cloture) {
        addUnique(datas.statutId, 'closed');
      }
      this.form.patchValue(this.dataService.advancedSearchMeeting);
    }
    const arrayOfKeys = ['global.open', 'global.pending', 'global.closed'];
    this.translate.get(arrayOfKeys).subscribe((res) => {
      this.seanceStatus.push(
        { label: this.translate.instant('global.open'), value: 'open' },
        { label: this.translate.instant('global.pending'), value: 'pending' },
        { label: this.translate.instant('global.closed'), value: 'closed' }
      );
    });
    if (!this.dataService.advancedSearchMeeting) {
      this.form.get('statutId').setValue([this.dataReceived]);
    }
  }

  ngAfterViewInit() {
    this.meetingSearchInput.nativeElement.focus();
  }

  onCloseDialog() {
    console.log('this.form.value ==>', this.form);
    const statutId = [this.form.value.statutId];
    if (statutId && statutId.length > 0) {
      statutId[0].map((x) => {
        if (x === 'open') {
          this.form.patchValue({ Ouvert: true });
        }
        if (x === 'pending') {
          this.form.patchValue({ Verrouille: true });
        }
        if (x === 'closed') {
          this.form.patchValue({ Cloture: true });
        }
      });
    }
    this.form.patchValue({ statutId: null }); //on remet a null pour le removeEmpty
    this.dataService.advancedSearchEnabled = true;
    this.dialogRef.close(this.form.value);
    this.reset();
  }

  reset() {
    this.form.reset();
  }
}
