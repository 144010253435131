import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
  public header: string = '';
  public errorArray: Array<string> = [];
  public columns: Array<any> = [
    {
      field: 'status',
      header: 'Status',
    },
    {
      field: 'method',
      header: 'Méthode',
    },
    {
      field: 'route',
      header: 'Route',
    },
    {
      field: 'message',
      header: 'Message',
    },
    {
      field: 'code',
      header: 'Code',
    },
  ];
  constructor(
    public translate: TranslateService,
    public ref: DynamicDialogRef,
    public dataService: DataService,
    public spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.header = this.translate.instant('moreInformationError');
    this.dataService.getErrorArray.subscribe((res: any) => {
      this.errorArray = res;
      this.spinner.hide();
    });
  }
}
