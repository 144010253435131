import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(public http: HttpClient) { }

  // Locale getJSON for calendar
  public getCalendartranslate(locale: string): Observable<any> {
    return this.http.get(`assets/calendar/calendar${this.capitalizeFirstLetter(locale)}.json`);
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
