<div class="modal-header super-compos-modal-header">
  <h5 class="modal-title super-compos-modal-title">
    {{ header | maxLength: 30 }}
  </h5>
  <div class="d-flex align-items-center">
    <super-button [text]="'btn.register' | translate" styleClass="blue mr-3" (click)="submitEvent(mode)"></super-button>
    <i class="fas fa-times-circle fa-lg cursor-pointer" (click)="ref.close()"></i>
  </div>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="mt-3 mb-3 row">
    <section class="col-12 mb-2">
      <label class="super-compos-label-input ml-2">{{ 'insertBeforeThePointNumber' | translate }} *</label>

      <p-dropdown
        *ngIf="!config.data.pointOrder"
        placeholder="Sélectionnez un point"
        id="dd2"
        styleClass="ml-2 w-100"
        [options]="numbers"
        formControlName="PointId"
        appendTo="body"
        [showClear]="true"
        [filter]="true"
        [virtualScroll]="true"
        itemSize="30">
        <ng-template let-point pTemplate="item">
          <div pTooltip="{{ point?.label }}" tooltipPosition="right" class="truncateLabel">{{ point.label }}</div>
        </ng-template>
      </p-dropdown>
      <span *ngIf="config.data.pointOrder"> {{ form.value.PointId }}</span>
    </section>
    <section class="formArray w-100 ml-3 mr-3">
      <div class="row mb-1" formArrayName="participants" *ngFor="let item of getParticipants.controls; let i = index">
        <div [formGroupName]="i" class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              <label class="super-compos-label-input">Participants</label>
              <p-dropdown
                [virtualScroll]="true"
                itemSize="30"
                styleClass="w-100"
                [options]="participants"
                formControlName="participantId"
                appendTo="body"
                [filter]="true"
                placeholder="Sélectionner un participant"
                [showClear]="true">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">{{ item.label }}</div>
                    <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-md-3">
              <label class="super-compos-label-input">Entrée/Sortie</label>
              <p-dropdown
                styleClass="w-100"
                [options]="entry_exit_LV"
                formControlName="entreeSortie"
                appendTo="body"
                [filter]="true"
                placeholder="Sélectionner une option"
                [showClear]="true">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">{{ item.label }}</div>
                    <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-md-3">
              <label class="super-compos-label-input">Rôle</label>
              <p-dropdown
                [virtualScroll]="true"
                itemSize="30"
                styleClass="w-100"
                [options]="globalElements?.roles_LV"
                formControlName="roleId"
                appendTo="body"
                [filter]="true"
                placeholder="Sélectionner une option"
                [showClear]="true">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">{{ item.label }}</div>
                    <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-md-3">
              <label class="super-compos-label-input">Signature</label>
              <p-dropdown
                [virtualScroll]="true"
                itemSize="30"
                styleClass="w-100"
                [options]="globalElements?.signatures_LV"
                formControlName="signatureId"
                appendTo="body"
                [filter]="true"
                placeholder="Sélectionner une option"
                [showClear]="true">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">{{ item.label }}</div>
                    <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-3">
        <super-button text="Ajouter un participant" (click)="add()" styleClass="blue"></super-button>
      </div>

      <div class="d-flex justify-content-center align-items-center mt-3" *ngIf="!displayModelesEventBoolean">
        <super-button
          text="Choisir un modèle d'évènement"
          (click)="displayModelesEvent()"
          styleClass="blue"></super-button>
      </div>

      <section class="col-12 mb-2" *ngIf="displayModelesEventBoolean">
        <div>
          <label class="super-compos-label-input">Choix d'un modèle d'évènement</label>
          <p-dropdown
            styleClass="w-100"
            [options]="listOfModelesEvent"
            [(ngModel)]="modeleDocChosen"
            [ngModelOptions]="{ standalone: true }"
            (onChange)="changeModeleEventRTF()"
            appendTo="body"
            [filter]="true"
            placeholder="Sélectionner un modèle d'évènement">
            <ng-template let-item pTemplate="item">
              <div class="d-flex align-items-center justify-content-between">
                <div class="truncateLabel">{{ item.description }}</div>
                <div><i class="fas fa-info-circle" [pTooltip]="item.description"></i></div>
              </div>
            </ng-template>
            <ng-template let-item pTemplate="selectedItem">
              <span>{{ item?.description }}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </section>
    </section>
    <section class="col-12 mb-2">
      <!-- <super-richedit [font]="globalElements?.configApp?.delibConfig?.defaultMiseEnFormeParams" #document_editor [mergeFields]="fusions"></super-richedit> -->
      <super-richedit
        #document_editor
        [mergeFields]="fusions"
        [font]="globalElements?.configApp?.delibConfig?.defaultMiseEnFormeParams"></super-richedit>
    </section>
  </form>
  <super-button
    [text]="'btn.register' | translate"
    styleClass="blue w-100 justifyButtonTextCenter"
    (click)="submitEvent(mode)"></super-button>
</div>

<p-confirmDialog
  key="addEventConfirm"
  #cd
  appendTo="body"
  styleClass="super-compos-modal-content confirmModal"
  header="Confirmation"
  icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Non" (click)="cd.reject()"></button>
    <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="Oui" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>
