import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Box } from 'src/app/shared/models/box';
import * as async from 'async';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { DataService } from 'src/app/core/services/data.service';
import { ApiService } from 'src/app/core/services/api.service';
import { Status_Point } from 'src/app/shared/models/status_point';
import { PointsSearchComponent } from 'src/app/shared/components/modals/points-search/points-search.component';
import * as _ from 'underscore';
import { Title } from '@angular/platform-browser';
import { SessionStorageService } from 'src/app/core/services/sessionStorage.service';
import { DashboardTypes } from 'src/app/shared/models/api-request/me.models';
import { UserRightsService } from 'src/app/core/services/user-rights.service';
@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.scss'],
})
export class PointsComponent implements OnInit, OnDestroy, AfterViewInit {
  public pointSearch = '';
  public boxes: Array<Box[]> = [];
  public statutsPoints: Array<Status_Point>;
  subscription: Subscription;
  public searchExist: boolean = false;
  public countsTags: any;
  public justifyOptions: Array<any> = [
    { icon: 'icon-side-view', value: 'side-by-side' },
    { icon: 'fas fa-align-justify', value: 'table' },
  ];
  public currentDisposition: string = 'side-by-side';
  public currentRoute = 'searchTab/mesPointsEnRedaction';
  public buttons = [];
  public isUserAbleToAddPoint: boolean = false;
  public isUserHasRightToImport3P: boolean = false;
  public showOverlayDropdown: boolean = false;
  public onFocusInput = false;
  public dropdownValue: string = 'Titre';
  public dropdownLabel: string = 'Titre';
  public searchString: string = '';
  public advancedSearchDatas = null;
  public activeModeSearch = 'normalSearch';
  public checkContenuRTF: boolean;
  @ViewChild('pointSearchInput') pointSearchInput: ElementRef;
a
  constructor(
    private router: Router,
    private translate: TranslateService,
    public dialog: DialogService,
    public dataService: DataService,
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private storageService: SessionStorageService,
    public userRightsService: UserRightsService
  ) {}

  @HostListener('window:resize', []) updateResize() {
    // lg (for laptops and desktops - screens equal to or greater than 1200px wide)// md (for small laptops - screens equal to or greater than 992px wide)// sm (for tablets - screens equal to or greater than 768px wide)// xs (for phones - screens less than 768px wide)
    if (window.innerWidth <= 1024) {
      this.currentDisposition = 'table';
    } else {
      this.currentDisposition = 'side-by-side';
    }
  }

  ngOnInit(): void {
    this.titleService.setTitle('iDélibé - Points');
    async.waterfall(
      [
        async (callback) => {
          this.isUserAbleToAddPoint = await this.userRightsService.getUserAbleToAddPoint();
          // this.isUserHasRightToImport3P = await this.userRightsService.getIAdminGlobalConfig();
          const arrayOfKeys = ['btn.importPoints', 'btn.createPoint'];
          this.translate.get(arrayOfKeys).subscribe((res) => {
            this.buttons = [
              // TODO : Temp disable button 3P because back not ready
              // this.isUserHasRightToImport3P ?
              // {
              //   text: res["btn.importPoints"],
              //   styleClass: "blue",
              //   size:'small',
              //   icon: "fas fa-arrow-square-up",
              //   iconPosition: "left",
              //   id: "importPoint",
              //   tooltip: res["btn.importPoints"],
              //   click: () => {
              //     this.router.navigateByUrl('/private/points/import3P')
              //   },
              // } : null,
              this.isUserAbleToAddPoint
                ? {
                    text: res['btn.createPoint'],
                    styleClass: 'green',
                    size: 'small',
                    icon: 'fas fa-plus-circle',
                    iconPosition: 'left',
                    id: 'createNewPoint',
                    tooltip: res['btn.createPoint'],
                    click: () => {
                      this.dataService.SetCurrentMeeting(null);
                      this.createNewPoint();
                    },
                  }
                : null,
            ];
            callback();
          });
        },
      ],
      () => {
        this.router.navigate(['/private/points/searchTab/all']);
        if (!this.storageService.keyExists('reloadDashboardPoints')) {
          this.storageService.saveData('reloadDashboardPoints', '0');
        }
        this.dataService.setSidebarSmall(true);
        this.getData();
      }
    );
  }

  ngAfterViewInit() {
    this.pointSearchInput.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.currentRoute = null;
  }

  createNewPoint() {
    this.router.navigateByUrl('/private/points/create');
  }

  getData() {
    let tempBoxes = [];
    const tempSearchSavedBoxes = [];
    async.waterfall([
      (callback) => {
        this.api.getGlobalAllPointsStatuts().subscribe((res: any) => {
          this.statutsPoints = res;
          callback();
        });
      },
      (callback) => {
        this.api.getPointsCount('PourInfo,PourAction,ACorriger,EnRedaction').subscribe((res: any) => {
          this.countsTags = res;
          callback();
        });
      },
      (callback) => {
        this.boxes = [];
        //! Quand on fait un changement ds le code des box => delete dans sessionStorage boxesiDelibeBOv1 afin de forcer le reload avec l'envoi de l'input!
        tempBoxes = [
          {
            name: 'title.allPoints',
            menu: 'all',
            title: this.translate.instant('title.allPoints'),
            id: 'all',
            click: ($event) => {
              this.dataService.advancedSearchPoint = null;
              this.searchExist = false;
              this.currentRoute = 'searchTab/all';
              this.router.navigate(['/private/points/searchTab/all']);
            },
          },
          {
            name: 'title.aCorriger',
            menu: 'aCorriger',
            count: this.countsTags.aCorriger,
            title: this.translate.instant('title.aCorriger'),
            id: 'aCorriger',
            click: ($event) => {
              this.dataService.advancedSearchPoint = null;
              this.searchExist = false;
              this.currentRoute = 'searchTab/aCorriger';
              this.router.navigate(['/private/points/searchTab/aCorriger']);
            },
          },
          {
            name: 'title.pourInfo',
            menu: 'pourInfo',
            count: this.countsTags.pourInfo,
            title: this.translate.instant('title.pourInfo'),
            id: 'pourInfo',
            click: ($event) => {
              this.dataService.advancedSearchPoint = null;
              this.searchExist = false;
              this.currentRoute = 'searchTab/pourInfo';
              this.router.navigate(['/private/points/searchTab/pourInfo']);
            },
          },
          {
            name: 'title.pourAction',
            menu: 'pourAction',
            count: this.countsTags.pourAction,
            title: this.translate.instant('title.pourAction'),
            id: 'pourAction',
            click: ($event) => {
              this.dataService.advancedSearchPoint = null;
              this.searchExist = false;
              this.currentRoute = 'searchTab/pourAction';
              this.router.navigate(['/private/points/searchTab/pourAction']);
            },
          },
          {
            name: 'title.mesPointsEnRedaction',
            menu: 'mesPointsEnRedaction',
            count: this.countsTags.enRedaction,
            title: this.translate.instant('title.mesPointsEnRedaction'),
            id: 'mesPointsEnRedaction',
            click: ($event) => {
              this.dataService.advancedSearchPoint = null;
              this.searchExist = false;
              this.currentRoute = 'searchTab/mesPointsEnRedaction';
              this.router.navigate(['/private/points/searchTab/mesPointsEnRedaction']);
            },
          },
        ];
        this.statutsPoints.map((statut) => {
          tempBoxes.push({
            name: statut.description,
            menu: statut.id,
            title: statut.description,
            id: statut.id.toString(),
            click: ($event) => {
              this.dataService.advancedSearchPoint = null;
              this.searchExist = false;
              this.currentRoute = 'searchTab/' + statut.id;
              this.router.navigate(['/private/points/searchTab/' + statut.id]);
            },
          });
        });

        callback();
      },
      (callback) => {
        //on va vérifier si on ne revient pas du component de visualisation. Si on a un children c'est qu'on veut afficher une box précise
        // ex searchTab/aCorriger => aCorriger sera un child de activatedRoute
        const fromIcourrierCreation = localStorage.getItem('iDelibe_icourrier_id_send');
        if (this.activatedRoute.children.length === 0 && !fromIcourrierCreation) {
          this.currentRoute = 'searchTab/mesPointsEnRedaction';
          this.router.navigateByUrl('/private/points/searchTab/mesPointsEnRedaction');
        }
        callback();
      },
      (cb) => {
        if (window.innerWidth <= 1024) {
          this.currentDisposition = 'table';
        } else {
          this.currentDisposition = 'side-by-side';
        }
        cb();
      },
      (cb) => {
        if (this.dataService.advancedSearchPoint !== null) {
          this.searchExist = true;
        }
        cb(null);
      },
      (cb) => {
        this.api
          .getPersonalParams(DashboardTypes.IDelibeBackofficeRechercheSauvegardee)
          .toPromise()
          .then((res) => {
            res.map((x) => {
              if (x.hideInDashboard === false) {
                tempSearchSavedBoxes.push({
                  menu: x.name,
                  title: x.description,
                  name: x.description,
                  iud: x.id,
                  value: JSON.parse(x.value),
                  click: ($event) => {
                    this.router.navigate(['/private/points/searchTab/reset']);
                    setTimeout(() => {
                      this.dataService.advancedSearchPoint = this.removeEmpty(JSON.parse(x.value));
                      const route = '/private/points/search/advancedSearch';
                      this.router.navigate([route]);
                    }, 1000);
                  },
                });
              }
            });
            cb(null);
          });
        this.boxes.push(tempBoxes, tempSearchSavedBoxes);
      },
    ]);
  }

  changeDisplay() {
    if (this.currentDisposition === 'table') {
      if (this.dataService.advancedSearchPoint) {
        const route = '/private/points/display/search/advancedSearch';
        this.router.navigate([route], { queryParams: { CheckContenuRTF: this.checkContenuRTF } });
      } else {
        const route = '/private/points/display/' + this.currentRoute;
        this.router.navigate([route], { queryParams: { CheckContenuRTF: this.checkContenuRTF } });
      }
    }
  }

  searchPoint() {
    //on reset la recherche avancée
    this.dataService.advancedSearchPoint = null;
    this.searchExist = false;
    if (this.pointSearch) {
      this.pointSearch = this.pointSearch.trim();
      if (this.dropdownValue === 'Titre + Contenus') {
        this.checkContenuRTF = true;
        this.router.navigate(['/private/points/search/' + encodeURIComponent(this.pointSearch)], {
          queryParams: { CheckContenuRTF: true },
        });
        this.currentRoute = 'search/' + this.pointSearch;
      } else if (this.dropdownValue === 'Id') {
        if (/^\d+$/.test(this.pointSearch)) {
          const id = `**${this.pointSearch.toString()}`;
          this.checkContenuRTF = false;
          this.router.navigate(['/private/points/search/' + id], { queryParams: { CheckContenuRTF: false } });
          this.currentRoute = 'search/' + id;
        }
      } else {
        this.checkContenuRTF = false;
        this.router.navigate(['/private/points/search/' + encodeURIComponent(this.pointSearch)], {
          queryParams: { CheckContenuRTF: false },
        });
        this.currentRoute = 'search/' + this.pointSearch;
      }
    } else {
      this.router.navigate(['/private/points/searchTab/all']);
    }
  }

  openAdvancedSearch() {
    const dialog = this.dialog.open(PointsSearchComponent, {
      width: '60vw',
      showHeader: false,
    });
    dialog.onClose.subscribe((res: any) => {
      // Petit tricky pour reset le component & qu'il recoivent correctement les infos :)
      if (res) {
        this.dataService.advancedSearchPoint = null;
        this.searchExist = false;
        this.router.navigate(['/private/points/searchTab/reset']);
        this.getData();
        setTimeout(() => {
          this.dataService.advancedSearchPoint = res;
          this.currentRoute = 'search/advancedSearch';
          this.searchExist = true;
          this.router.navigate(['/private/points/search/advancedSearch'], {
            queryParams: { CheckContenuRTF: res.CheckContenuRTF },
          });
        }, 1000);
      }
    });
  }

  reset() {
    this.dataService.advancedSearchPoint = null;
    this.searchExist = false;
    this.router.navigateByUrl('/private/points/searchTab/mesPointsEnRedaction');
  }

  removeEmpty(obj) {
    return Object.keys(obj)
      .filter(function (k) {
        return obj[k] != null;
      })
      .reduce(function (acc, k) {
        if (!Array.isArray(obj[k]) && !(obj[k] instanceof Date)) {
          acc[k] = typeof obj[k] === 'object' ? this.removeEmpty(obj[k]) : obj[k];
        } else {
          acc[k] = obj[k];
        }
        return acc;
      }, {});
  }

  // onSearchButtonClick() {}

  onClickDropdownInput(type: string) {
    this.dropdownLabel = type;
    this.dropdownValue = type;
    this.showOverlayDropdown = false;
    this.searchPoint();
  }
}
