<super-tabs class="d-flex justify-content-center mb-3" [items]="tabsVotes" (slug)="tabChange($event)"></super-tabs>
<ng-container [ngSwitch]="activeTabVote" [formGroup]="form">
  <div class="alert alert-danger mb-3 mt-3" *ngIf="activeTabVote !== 0 && allVotesCount !== participantsPresentsCount">
    {{ 'vote.numberVoteNotCorrespond' | translate }} ({{ participantsPresentsCount }} {{ 'vote.voters' | translate }}) !
  </div>
  <ng-container *ngSwitchCase="1">
    <div class="row mb-3 mt-3">
      <div class="col-md-12 mb-3" *ngIf="voteObject?.resultatVote">
        <div
          class="vote__button vote__button--big"
          [ngClass]="{
            'vote__button--true--green': voteObject?.resultatVote === 'Approuve',
            'vote__button--true--orange': voteObject?.resultatVote === 'Reporte',
            'vote__button--true--red': voteObject?.resultatVote === 'Refuse'
          }">
          {{ voteObject?.resultatVote | translate }}
        </div>
      </div>
      <div class="col-md-4 text-center">
        <label class="super-compos-label-input super-compos-label-input__green">{{
          'vote.approuve' | translate
        }}</label>
        <input
          type="number"
          formControlName="nbrVotesGlobalPositif"
          class="form-control text-center form-control__green"
          pInputText
          placeholder="{{ 'vote.numberVoteApprouve' | translate }}" />
      </div>
      <div class="col-md-4 text-center">
        <label class="super-compos-label-input super-compos-label-input__orange">{{
          'vote.abstention' | translate
        }}</label>
        <input
          type="number"
          formControlName="nbrVotesGlobalAbstention"
          class="form-control text-center form-control__orange"
          pInputText
          placeholder="{{ 'vote.numberVoteAbstention' | translate }}" />
      </div>
      <div class="col-md-4 text-center">
        <label class="super-compos-label-input super-compos-label-input__red">{{ 'vote.refuse' | translate }}</label>
        <input
          type="number"
          formControlName="nbrVotesGlobalNegatif"
          class="form-control text-center form-control__red"
          pInputText
          placeholder="{{ 'vote.numberVoteRefuse' | translate }}" />
      </div>
      <div class="col-md-12 mt-3 d-flex align-items-center justify-content-center">
        <super-button
          text="{{ 'btn.save' | translate }}"
          styleClass="green"
          (click)="submitGlobalVote()"></super-button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="2">
    <div class="row mb-3">
      <div class="col-md-4">
        <div
          class="vote__button vote__button--big vote__button--false--green"
          [ngClass]="{ 'vote__button--true--green': voteObject.resultatVote === 'Approuve' }">
          {{ 'vote.approuve' | translate }}
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="vote__button vote__button--big vote__button--false--orange"
          [ngClass]="{ 'vote__button--true--orange': voteObject.resultatVote === 'Reporte' }">
          {{ 'vote.reporte' | translate }}
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="vote__button vote__button--big vote__button--false--red"
          [ngClass]="{ 'vote__button--true--red': voteObject.resultatVote === 'Refuse' }">
          {{ 'vote.refuse' | translate }}
        </div>
      </div>
    </div>
    <super-table
      #superTableVotes
      [items]="votesNominatif"
      *ngIf="votesNominatif.length > 0"
      [columns]="votesColumns"
      id="super-votes-table"
      [height]="'calc( 100vh - 400px)'"
      [multiselect]="true">
    </super-table>
    <div class="alert alert-primary" *ngIf="votesNominatif.length === 0">
      {{ 'vote.noParticipantInThisPoint' | translate }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="3">
    <div class="row mb-3 mt-3">
      <div class="col-md-6 mb-3 text-center">
        <label class="super-compos-label-input">{{ 'vote.numberVoters' | translate }}</label>
        <input
          type="number"
          class="form-control"
          formControlName="nbrVotants"
          pInputText
          placeholder="{{ 'vote.numberVoters' | translate }}" />
      </div>
      <div class="col-md-6 mb-3 text-center">
        <label class="super-compos-label-input">{{ 'vote.validBallots' | translate }}</label>
        <input
          type="number"
          class="form-control"
          formControlName="nbrVotesValables"
          pInputText
          placeholder="{{ 'vote.validBallots' | translate }}" />
      </div>
      <div class="col-md-4 mb-3 text-center">
        <label class="super-compos-label-input super-compos-label-input__green">{{
          'vote.approuve' | translate
        }}</label>
        <input
          type="number"
          class="form-control form-control__green"
          formControlName="nbrVotesGlobalPositif"
          pInputText
          placeholder="{{ 'vote.numberVoteApprouve' | translate }}" />
      </div>
      <div class="col-md-4 mb-3 text-center">
        <label class="super-compos-label-input super-compos-label-input__orange">{{ 'vote.blank' | translate }}</label>
        <input
          type="number"
          class="form-control form-control__orange"
          formControlName="nbrVotesGlobalAbstention"
          pInputText
          placeholder="{{ 'vote.numberVoteBlank' | translate }}" />
      </div>
      <div class="col-md-4 mb-3 text-center">
        <label class="super-compos-label-input super-compos-label-input__red">{{ 'vote.refuse' | translate }}</label>
        <input
          type="number"
          class="form-control form-control__red"
          formControlName="nbrVotesGlobalNegatif"
          pInputText
          placeholder="{{ 'vote.numberVoteRefuse' | translate }}" />
      </div>
      <div class="col-md-12 text-center">
        <label class="super-compos-label-input">{{ 'vote.numberVoteTurns' | translate }}</label>
        <input
          type="number"
          class="form-control"
          formControlName="nbrTours"
          pInputText
          placeholder="{{ 'vote.numberVoteTurns' | translate }}" />
      </div>
      <div class="col-md-12 mt-3 d-flex align-items-center justify-content-center">
        <super-button
          text="{{ 'btn.save' | translate }}"
          styleClass="green"
          (click)="submitGlobalVote()"></super-button>
      </div>
    </div>
  </ng-container>
</ng-container>

<p-confirmDialog
  baseZIndex="560"
  key="confirmsModal"
  #cd
  styleClass="super-compos-modal-content confirmModal"
  header="{{ 'confirm.confirmHeader' | translate }}"
  icon="pi pi-exclamation-triangle">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="{{ 'No' | translate }}" (click)="cd.reject()"></button>
    <button
      class="pButtonRed"
      type="button"
      pButton
      icon="pi pi-check"
      label="{{ 'Yes' | translate }}"
      (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>
