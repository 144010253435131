import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api.service';
import { ContactInfosService } from 'src/app/core/services/contact-infos.service';
import { Point } from '../../models/point';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { TranslateService } from '@ngx-translate/core';
import { Contact } from '../../models/iContact/contact';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModelesDocuments } from '../../models/modelesDocuments.model';
import { ParticipantExtraitConforme } from '../../models/participantExtraitConforme.model';

@Component({
  selector: 'app-sign-file',
  templateUrl: './sign-file.component.html',
  styleUrls: ['./sign-file.component.scss']
})
export class SignFileComponent implements OnInit {
  public dateExtrait: Date = new Date();
  public minDate: Date;
  public signatures = [];
  public signatureArray = [];
  public errorIndices: Set<number> = new Set();
  public isConfirmDisabled: boolean = false;
  public errorMessageInsideCard: string = ``;
  public errorMessagePoint: string = ``;
  public successPointIds: number[] = [];
  public successPointIdsArray: string = '';
  public loadingPage: boolean = false;
  public modelesDocPoint: ModelesDocuments[] = [];
  public modeleIdToGenerateInMass: number;
  public numberPointsOfErrors: number = 0;
  public numberPointsOfSuccess: number = 0;
  public openedAccordionError: boolean = false;
  public openedAccordionSuccess: boolean = false;
  private allTypesSeances: ParticipantExtraitConforme[] = [];
  private userList: Contact[] = [];
  private adminList: Contact[] = [];
  constructor(
    private icontactService: IContactService,
    private translate: TranslateService,
    public api: ApiService,
    public contactInfosService: ContactInfosService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    this.minDate = new Date();
  }

  ngOnInit(): void {
    if(this.config.data.massGeneration) {
      this.config.data.points = this.config.data.points.filter((point: Point) => point.type === 'point');
      if(this.config.data.points.length === 0) {
        this.toastr.error(this.translate.instant('error.noPointSelected'));
        return this.ref.close();
      }
      this.getModelDocument();
      this.getRightGeneration();
    }
    this.getInfoParticipant(this.config.data.seanceId);
  }

  getRightGeneration(): void {
    const modeleId = this.config.data.massGeneration ? this.modeleIdToGenerateInMass : this.config.data.modelSelected;
    const pointIds = this.config.data.points.map((point: Point) => point.id);
    if (modeleId === undefined) {
      console.error('modeleId is undefined. Stopping function execution.');
      return;
    }
    this.api.getRightsGeneration(this.config.data.seanceId, modeleId, pointIds).subscribe((res: any) => {
      this.isConfirmDisabled = false;
      this.errorMessagePoint = '';
      this.numberPointsOfErrors = 0;
      this.numberPointsOfSuccess = 0;
      this.successPointIds = [];
      this.successPointIdsArray = '';
      if (!res.canGenerateExtraitConf) {
        if (Array.isArray(res.points) && res.points.length === 0) {
          this.isConfirmDisabled = true;
          this.errorMessagePoint = `<i class="bi bi-exclamation-diamond mr-1"></i> - ` + this.translate.instant('error._' + res.errorCode);
          this.numberPointsOfErrors = 1;
        } else if (res.points.length === 1) {
          this.numberPointsOfErrors = res.points.filter((point) => !point.canGenerateExtraitConf).length;
          this.numberPointsOfSuccess = res.points.filter((point) => point.canGenerateExtraitConf).length;
          this.successPointIds = res.points.filter(point => point.canGenerateExtraitConf).map(point => point.pointId);
          this.successPointIdsArray = this.successPointIds.join(', ');
          const pt = res.points[0];
          this.isConfirmDisabled = pt.canUserGenerate || pt.belongToUserService || pt.isExtrait || pt.isPointClosed || pt.isExtraitGenerated || pt.isExtraitRtfFound;
          if (pt.canUserGenerate === false) {
            this.errorMessagePoint = this.translate.instant('error.generateSinglePoint.cantGenerate');
          }
          if (pt.belongToUserService === false) {
            this.errorMessagePoint = this.translate.instant('error.generateSinglePoint.belongToUserService');
          }
          if (pt.isExtrait === false) {
            this.errorMessagePoint = this.translate.instant('error.generateSinglePoint.noExtrait');
          }
          if (pt.isPointClosed === false) {
            this.errorMessagePoint = this.translate.instant('error.generateSinglePoint.pointNotClosed');
          }
          if (pt.isExtraitGenerated === false) {
            this.errorMessagePoint = this.translate.instant('error.generateSinglePoint.extraitNotGenerated');
          }
          if (pt.isExtraitRtfFound === false) {
            this.errorMessagePoint = this.translate.instant('error.generateSinglePoint.rtfNotFound');
          }
        } else {
          this.numberPointsOfErrors = res.points.filter((point) => !point.canGenerateExtraitConf).length;
          this.numberPointsOfSuccess = res.points.filter((point) => point.canGenerateExtraitConf).length;
          this.successPointIds = res.points.filter(point => point.canGenerateExtraitConf).map(point => this.config.data.points.find((p: Point) => p.id === point.pointId).ordreDocs);
          this.successPointIdsArray = `<i class="bi bi-check-circle"></i> - ` + this.successPointIds.join(', ');    
          const errorMessages: { [key: string]: number[] } = {};
          res.points
            .filter((point) => !(point.canUserGenerate && point.belongToUserService && point.isExtrait && point.isPointClosed && point.isExtraitGenerated && point.isExtraitRtfFound))
            .forEach((point) => {
              if (point.canUserGenerate === false) {
                if (!errorMessages['canUserGenerate']) {
                  errorMessages['canUserGenerate'] = [];
                }
                errorMessages['canUserGenerate'].push(this.config.data.points.find((p: Point) => p.id === point.pointId).ordreDocs);
              }
              if (point.belongToUserService === false) {
                if (!errorMessages['belongToUserService']) {
                  errorMessages['belongToUserService'] = [];
                }
                errorMessages['belongToUserService'].push(this.config.data.points.find((p: Point) => p.id === point.pointId).ordreDocs);
              }
              if (point.isExtrait === false) {
                if (!errorMessages['isExtrait']) {
                  errorMessages['isExtrait'] = [];
                }
                errorMessages['isExtrait'].push(this.config.data.points.find((p: Point) => p.id === point.pointId).ordreDocs);
              }
              if (point.isPointClosed === false) {
                if (!errorMessages['isPointClosed']) {
                  errorMessages['isPointClosed'] = [];
                }
                errorMessages['isPointClosed'].push(this.config.data.points.find((p: Point) => p.id === point.pointId).ordreDocs);
              }
              if (point.isExtraitGenerated === false) {
                if (!errorMessages['isExtraitGenerated']) {
                  errorMessages['isExtraitGenerated'] = [];
                }
                errorMessages['isExtraitGenerated'].push(this.config.data.points.find((p: Point) => p.id === point.pointId).ordreDocs);
              }
              if (point.isExtraitRtfFound === false) {
                if (!errorMessages['isExtraitRtfFound']) {
                  errorMessages['isExtraitRtfFound'] = [];
                }
                errorMessages['isExtraitRtfFound'].push(this.config.data.points.find((p: Point) => p.id === point.pointId).ordreDocs);
              }
            });

          this.errorMessagePoint = Object.keys(errorMessages).map((key) => {
            let message = '';
            switch (key) {
              case 'canUserGenerate':
                message = this.translate.instant('error.generateMultiPoints.cantGenerate');
                break;
              case 'belongToUserService':
                message = this.translate.instant('error.generateMultiPoints.belongToUserService');
                break;
              case 'isExtrait':
                message = this.translate.instant('error.generateMultiPoints.noExtrait');
                break;
              case 'isPointClosed':
                message = this.translate.instant('error.generateMultiPoints.pointNotClosed');
                break;
              case 'isExtraitGenerated':
                message = this.translate.instant('error.generateMultiPoints.extraitNoGenerated');
                break;
              case 'isExtraitRtfFound':
                message = this.translate.instant('error.generateMultiPoints.rtfNotFound');
                break;
            }
            return `<i class="bi bi-exclamation-diamond mr-1"></i> - ${message} ${errorMessages[key].join(', ')}`;
          }).join('<br>');
        }
      }
      this.numberPointsOfErrors === res.points.length ? this.isConfirmDisabled = true : null;
    });
  }

  getModelDocument() {
    this.api.getGlobalModelesDoc().subscribe((res: any) => {
      const allModelesDoc = res;
      const typeSeance = this.config.data.points[0].typeSeanceId;
      const onlyExtrait = true;
      this.api.getModeleIdByTypeSeance(typeSeance, 'Point', onlyExtrait).subscribe((res: number[]) => {
        res.map((x) => {
          this.modelesDocPoint.push(allModelesDoc.find((modele) => modele.id === x));
        });
      });
    });
  }


  getInfoParticipant(id: number): void {
    this.loadingPage = true;
    this.api.getParticipantsExtraitConformeByMeetingID(id).subscribe({
      next: (res: ParticipantExtraitConforme[]) => {
        this.getGlobalSignatures();
        this.getGlobalUser();
        this.allTypesSeances = res;
        this.loadingPage = false;
      },
      error: (err: any) => {
        console.error('Error fetching participant info:', err);
        this.toastr.error(this.translate.instant('error.occurred'));
        this.loadingPage = false;
      }
    });
  }

  // Récupérer toute les signatures
  getGlobalSignatures(): void {
    this.api.getGlobalSignature().subscribe((res: any) => {
      this.signatures = res;
      this.setArrayForSignature();
    });
  }

  getGlobalUser(): void {
    const processContacts = (contacts) => {
      return contacts
        .filter(contact => !contact.nePlusUtiliser)
        .map(contact => ({
          ...contact,
          fullName: contact.fullName.length > 40 ? contact.fullName.substring(0, 40) + '...' : contact.fullName,
          fullNameTooltip: contact.fullName
        }));
    };

    this.icontactService.getLowContactsAdmin().subscribe((contactsAdmin) => {
      this.adminList = processContacts(contactsAdmin);
      this.userList = processContacts(this.contactInfosService?.contacts);
      this.contactInfosService.contactsAdmin = contactsAdmin;
      this.setArrayForSignature();
    });
  }

  setArrayForSignature(): void {
    this.signatureArray = this.allTypesSeances
      .filter(participant => this.signatures.some(signature => signature.id === participant.signatureId))
      .map(participant => {
        const signature = this.signatures.find(signature => signature.id === participant.signatureId);
        return {
          idSignature: participant.signatureId,
          labelSignature: signature?.description ?? null,
          baseIdentifiantDocument: signature?.baseIdentifiantDocument ?? null,
          idAdmin: participant.contactIdiAdmin ?? participant.contactId,
          isContact: !participant.contactIdiAdmin,
          userOptions: participant.contactIdiAdmin ? this.adminList : this.userList,
        };
      });
  }

  editSignatureArray(action: string, index?: number) {
    if (index !== undefined && action === 'remove') {
      this.signatureArray.splice(index, 1);
      this.checkForDuplicates();
      if (this.signatureArray.length === 0) {
        this.isConfirmDisabled = true;
        this.errorMessageInsideCard = this.translate.instant('error.noSignatureEntered');
      }
    } else if (action === 'add') {
      this.signatureArray.push({
        idSignature: null,
        labelSignature: null,
        idAdmin: null,
        isContact: true,
        userOptions: this.userList,
      });
      this.checkForDuplicates();
    } else {
      console.error('Action not found');
    }
  }

  // Méthode pour vérifier les doublons
  checkForDuplicates() {
    this.errorMessageInsideCard = '';
    const baseIds = this.signatureArray.map(item => item.baseIdentifiantDocument);
    this.errorIndices.clear();
    baseIds.forEach((baseId, index) => {
      if (baseId && baseIds.indexOf(baseId) !== index) {
        this.errorIndices.add(index);
        this.errorIndices.add(baseIds.indexOf(baseId));
      }
    });
    this.isConfirmDisabled = this.errorIndices.size > 0;
    this.isConfirmDisabled ? this.errorMessageInsideCard = this.translate.instant('error.signatureDuplicate') : null;
  }

  updateLabelSignature() {
    this.signatureArray.forEach(item => {
      const signature = this.signatures.find(signature => signature.id === item.idSignature);
      item.labelSignature = signature ? signature.description : null;
      item.baseIdentifiantDocument = signature ? signature.baseIdentifiantDocument : null;
    });
    this.checkForDuplicates();
  }

  setUserArray(index: number) {
    const item = this.signatureArray[index];
    if (item.isContact) { // si c'est un contact
      item.userOptions = this.userList;
    } else { // si c'est un user admin
      item.userOptions = this.adminList;
    }
  }

  confirmSignatures() {
    const isValidDate = (date: any): boolean => {
      return date instanceof Date && !isNaN(date.getTime());
    };
    if (!isValidDate(this.dateExtrait)) {
      return this.toastr.error(this.translate.instant('error.invalidDate'));
    }
    this.loadingPage = true;
    this.isConfirmDisabled = true;
    this.updateLabelSignature();
    if(!this.config.data.massGeneration){ //! si génération unique
      const requestBody = {
        extraitDate: new Date(this.dateExtrait),
        extraitParticipants: this.signatureArray.map(participant => ({
          signatureId: participant.idSignature,
          contactId: participant.isContact ? participant.idAdmin : 0,
          contactIdiAdmin: participant.isContact ? 0 : participant.idAdmin,
        }))
      };
      const pointId: number = this.config.data.points[0].id;
      this.api.generationExtraitConforme(pointId, this.config.data.modelSelected, requestBody)
      .subscribe(
        (res: any) => {
          this.toastr.success(this.translate.instant('success.requestRecorded'));          
          this.loadingPage = false;
          this.isConfirmDisabled = false;
          this.ref.close(true);
        },
        (error) => {
          if (error.status === 403) {
            this.toastr.warning(this.translate.instant('error.noRightAction'));
          } else {
            this.toastr.error(this.translate.instant('error.occurred'));
          }
          this.loadingPage = false;
        }
      );
    } else if(this.config.data.massGeneration){ // si en génération en masse
      const requestBody = {
        pointIds: this.config.data.points.map((point: Point) => point.id),
        extraitConformeData: {
          extraitDate: new Date(this.dateExtrait),
          extraitParticipants: this.signatureArray.map(participant => ({
            signatureId: participant.idSignature,
            contactId: participant.isContact ? participant.idAdmin : 0,
            contactIdiAdmin: participant.isContact ? 0 : participant.idAdmin,
          }))
        }
      };
      this.api.massGenerationExtraitConforme(this.config.data.seanceId, this.modeleIdToGenerateInMass, requestBody)
      .subscribe(
        (res: any) => {
          this.toastr.success(this.translate.instant('success.requestRecorded'));
          this.loadingPage = false;
          this.isConfirmDisabled = false;
          this.ref.close(true);
        },
        (error) => {
          if (error.status === 403) {
            this.toastr.warning(this.translate.instant('error.noRightAction'));
          } else {
            this.toastr.error(this.translate.instant('error.occurred'));
          }
          this.loadingPage = false;
        }
      );
    }
  }
}
