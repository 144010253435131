<div class="modal-header super-compos-modal-header">
     <h5 class="modal-title super-compos-modal-title">
         {{header}}
     </h5>
     <div class="d-flex align-items-center">
         <i class="fas fa-times-circle fa-lg cursor-pointer" (click)="ref.close()"></i>
     </div>
</div>
<div class="modal-body">
     <div class="row mt-4 mb-4">
          <div class="col-md-12">
               <super-table [items]="errorArray" [columns]="columns" id="super-table-of-errors" [height]="'calc( 100vh - 400px)'" [multiselect]="false"></super-table>
          </div>
     </div>
</div>