import { Component, OnInit } from '@angular/core';
import { SuperColumn } from '@devinforius/super-compos';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as moment from 'moment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
})
export class ReportingComponent implements OnInit {
  public successItems: Array<any> = [];
  public errorItems: Array<any> = [];
  public columns: Array<SuperColumn> = [
    {
      field: 'date',
      header: 'Date de la séance',
      format: (value) => {
        if (value) {
          return 'Séance du ' + moment(value).format('DD/MM/YYYY');
        }
      },
    },
  ];
  constructor(
    public dataModal: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    public router: Router
  ) {}

  ngOnInit(): void {
    if (this.dataModal && this.dataModal.data) {
      this.successItems = this.dataModal.data.successItems;
      this.errorItems = this.dataModal.data.errorItems;
    }
  }

  goTo(item) {
    if (item && item.id) {
      this.router.navigateByUrl('/private/meetings/details/' + item.id);
    }
  }
}
