import * as moment from 'moment';
import { ActionsContainerComponent } from './actions-container.component';
import { IPointActionSearchResult } from 'src/app/shared/models/response/ActionsSearchResult';
import { SuperColumn } from '@devinforius/super-compos';

export const fetchActionsTableColumnsWhenNotHideStatutAction = (self: ActionsContainerComponent): SuperColumn[] => {
  return [
    {
      field: 'mailId',
      header: 'ID courrier',
      format: (value, item: IPointActionSearchResult) => {
        if (item.mailId) {
          return item.mailId.toString();
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
      tooltip: (rowData) => {
        return rowData.mailId;
      },
    },
    {
      field: 'demandeur',
      header: 'Demandeur',
      format: (value, item: IPointActionSearchResult) => {
        const foundUserInfos = self.contactInfosService.fetchUserInfosByUserId(item.action.transmissionUserId);
        if (foundUserInfos) {
          return foundUserInfos.contactForUser.prenom + ' ' + foundUserInfos.contactForUser.nom;
        } else {
          return 'Pas de demandeur';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'contactId',
      header: 'Receveur',
      format: (value, item: IPointActionSearchResult) => {
        const foundContactInfos = self.contactInfosService.fetchUserInfosByContactId(item.action);
        if (foundContactInfos) {
          return foundContactInfos.prenom + ' ' + foundContactInfos.nom;
        } else {
          return 'Pas de receveur';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'seanceLabel',
      header: 'Séance',
      format: (value, item) => {
        if (item?.seanceLink?.description) {
          return item.seanceLink.description;
        } else {
          return '/';
        }
      },
    },
    {
      field: 'action',
      header: 'Type',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.action) {
          return item.action.action;
        } else {
          return 'Pas de type';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'statut',
      header: 'Statut',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.statut) {
          return item.action.statut;
        } else {
          return 'Pas de statut';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'echeance',
      header: "Date d'écheance",
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.echeance) {
          return moment(item.action.echeance).format('DD/MM/YYYY');
        } else {
          return 'Écheance non renseignée';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'message',
      header: 'Message',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.message) {
          return item.action.message && item.action.message.length > 30
            ? item.action.message.substring(0, 30) + '...'
            : item.action.message;
        } else {
          ('Pas de message');
        }
      },
      tooltip: (item: IPointActionSearchResult) => {
        return item.action.message;
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'objet',
      header: 'Objet',
      format: (value, item: IPointActionSearchResult) => {
        if (item.objet) {
          const actionObjet = item.objet;
          return actionObjet.length > 30 ? actionObjet.substring(0, 30) + '...' : actionObjet;
        }
      },
      tooltip: (item: IPointActionSearchResult) => {
        return item.fullObjet;
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'serviceProprietaireId',
      header: 'Service',
      format: (value, item: IPointActionSearchResult) => {
        if (item.serviceProprietaireId && self.services.length > 0) {
          let currentService = [];
          currentService = self.services.filter((x) => x.id === item.serviceProprietaireId);
          return currentService[0].description;
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'reponse',
      header: 'Réponse',
      sortable: false,
      align: 'right',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.reponse) {
          return item.action.reponse;
        } else {
          return `Aucune réponse`;
        }
      },
    },
    {
      field: 'actionId',
      header: 'Action ID',
      visible: false,
      format: (value, item: IPointActionSearchResult) => {
        return item.action.id;
      },
    },
    {
      field: 'actionss',
      header: 'Action',
      sortable: false,
      align: 'right',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.contactIdiAdmin === self.userInfos?.contactId && item.action.traiteLe === null) {
          return `<div class="btn btn-primary d-block w-100">Traiter l'action</div>`;
        } else {
          return `Aucune action disponible`;
        }
      },
      click: (column, rowData) => {
        self.answerToAction(column);
      },
    },
  ];
};

export const fetchActionsTableColumnsWhenNotHideStatutActionNoSeanceColumn = (
  self: ActionsContainerComponent
): SuperColumn[] => {
  return [
    {
      field: 'mailId',
      header: 'ID courrier',
      format: (value, item: IPointActionSearchResult) => {
        if (item.mailId) {
          return item.mailId.toString();
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
      tooltip: (rowData) => {
        return rowData.mailId;
      },
    },
    {
      field: 'demandeur',
      header: 'Demandeur',
      format: (value, item: IPointActionSearchResult) => {
        const foundUserInfos = self.contactInfosService.fetchUserInfosByUserId(item.action.transmissionUserId);
        if (foundUserInfos) {
          return foundUserInfos.contactForUser.prenom + ' ' + foundUserInfos.contactForUser.nom;
        } else {
          return 'Pas de demandeur';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'contactId',
      header: 'Receveur',
      format: (value, item: IPointActionSearchResult) => {
        const foundContactInfos = self.contactInfosService.fetchUserInfosByContactId(item.action);
        if (foundContactInfos) {
          return foundContactInfos.prenom + ' ' + foundContactInfos.nom;
        } else {
          return 'Pas de receveur';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'action',
      header: 'Type',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.action) {
          return item.action.action;
        } else {
          return 'Pas de type';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'statut',
      header: 'Statut',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.statut) {
          return item.action.statut;
        } else {
          return 'Pas de statut';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'echeance',
      header: "Date d'écheance",
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.echeance) {
          return moment(item.action.echeance).format('DD/MM/YYYY');
        } else {
          return 'Écheance non renseignée';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'message',
      header: 'Message',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.message) {
          return item.action.message && item.action.message.length > 30
            ? item.action.message.substring(0, 30) + '...'
            : item.action.message;
        } else {
          ('Pas de message');
        }
      },
      tooltip: (item: IPointActionSearchResult) => {
        return item.action.message;
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'objet',
      header: 'Objet',
      format: (value, item: IPointActionSearchResult) => {
        if (item.objet) {
          const actionObjet = item.objet;
          return actionObjet.length > 30 ? actionObjet.substring(0, 30) + '...' : actionObjet;
        }
      },
      tooltip: (item: IPointActionSearchResult) => {
        return item.fullObjet;
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'serviceProprietaireId',
      header: 'Service',
      format: (value, item: IPointActionSearchResult) => {
        if (item.serviceProprietaireId && self.services.length > 0) {
          let currentService = [];
          currentService = self.services.filter((x) => x.id === item.serviceProprietaireId);
          return currentService[0].description;
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'reponse',
      header: 'Réponse',
      sortable: false,
      align: 'right',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.reponse) {
          return item.action.reponse;
        } else {
          return `Aucune réponse`;
        }
      },
    },
    {
      field: 'actionId',
      header: 'Action ID',
      visible: false,
      format: (value, item: IPointActionSearchResult) => {
        return item.action.id;
      },
    },
    {
      field: 'actionss',
      header: 'Action',
      sortable: false,
      align: 'right',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.contactIdiAdmin === self.userInfos?.contactId && item.action.traiteLe === null) {
          return `<div class="btn btn-primary d-block w-100">Traiter l'action</div>`;
        } else {
          return `Aucune action disponible`;
        }
      },
      click: (column, rowData) => {
        self.answerToAction(column);
      },
    },
  ];
};

export const fetchActionsTableColumnsWhenHideStatutAction = (self: ActionsContainerComponent): SuperColumn[] => {
  return [
    {
      field: 'mailId',
      header: 'ID courrier',
      format: (value, item: IPointActionSearchResult) => {
        if (item.mailId) {
          return item.mailId.toString();
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
      tooltip: (rowData) => {
        return rowData.mailId;
      },
    },
    {
      field: 'demandeur',
      header: 'Demandeur',
      format: (value, item: IPointActionSearchResult) => {
        const foundUserInfos = self.contactInfosService.fetchUserInfosByUserId(item.action.transmissionUserId);
        if (foundUserInfos) {
          return foundUserInfos.contactForUser.prenom + ' ' + foundUserInfos.contactForUser.nom;
        } else {
          return 'Pas de demandeur';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
      tooltip: (rowData) => {
        return rowData.demandeur;
      },
    },
    {
      field: 'contactId',
      header: 'Receveur',
      format: (value, item: IPointActionSearchResult) => {
        const foundContactInfos = self.contactInfosService.fetchUserInfosByContactId(item.action);
        if (foundContactInfos) {
          return foundContactInfos.prenom + ' ' + foundContactInfos.nom;
        } else {
          return 'Pas de receveur';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
      tooltip: (rowData) => {
        return rowData.contactId;
      },
    },
    {
      field: 'seanceLabel',
      header: 'Séance',
      format: (value, item) => {
        if (item?.seanceLink?.description) {
          return item.seanceLink.description;
        } else {
          return '/';
        }
      },
    },
    {
      field: 'action',
      header: 'Type',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.action) {
          return item.action.action;
        } else {
          return 'Pas de type';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
      tooltip: (rowData) => {
        return rowData.action;
      },
    },
    {
      field: 'actionId',
      header: 'Action ID',
      visible: false,
      format: (value, item: IPointActionSearchResult) => {
        return item.action.id;
      },
    },
    {
      field: 'echeance',
      header: "Date d'écheance",
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.echeance) {
          return moment(item.action.echeance).format('DD/MM/YYYY');
        } else {
          return 'Écheance non renseignée';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
      tooltip: (rowData) => {
        return rowData.echeance;
      },
    },
    {
      field: 'message',
      header: 'Message',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.message) {
          return item.action.message && item.action.message.length > 30
            ? item.action.message.substr(0, 30) + '...'
            : item.action.message;
        } else {
          return 'Pas de message';
        }
      },
      tooltip: (item: IPointActionSearchResult) => {
        if (item.message) {
          return item.message;
        } else {
          return 'Pas de message';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'objet',
      header: 'Titre',
      format: (value, item: IPointActionSearchResult) => {
        if (item.objet) {
          const temp = item.objet;
          return temp.length > 30 ? temp.substr(0, 30) + '...' : temp;
        }
      },
      tooltip: (item: IPointActionSearchResult) => {
        return item.fullObjet;
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'serviceProprietaireId',
      header: 'Service',
      format: (value, item: IPointActionSearchResult) => {
        if (item.serviceProprietaireId && self.services.length > 0) {
          let currentService = [];
          currentService = self.services.filter((x) => x.id === item.serviceProprietaireId);
          return currentService[0].description;
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
      tooltip: (item: IPointActionSearchResult) => {
        return item.serviceProprietaireId;
      },
    },
    {
      field: 'reponse',
      header: 'Réponse',
      sortable: false,
      align: 'right',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.reponse) {
          return item.action.reponse;
        } else {
          return `Aucune réponse`;
        }
      },
      tooltip: (item: IPointActionSearchResult) => {
        if (item.action.fullreponse) {
          return item.action.fullreponse;
        } else {
          return `Aucune réponse`;
        }
      },
    },
    {
      field: 'actionss',
      header: 'Action',
      sortable: false,
      align: 'right',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.contactIdiAdmin === self.userInfos?.contactId && item.action.traiteLe === null) {
          return `<div class="btn btn-primary d-block w-100">Traiter l'action</div>`;
        } else {
          return `Aucune action disponible`;
        }
      },
      click: (column, rowData) => {
        self.answerToAction(column);
      },
    },
  ];
};

export const fetchActionsTableColumnsWhenHideStatutActionNoSeanceColumn = (
  self: ActionsContainerComponent
): SuperColumn[] => {
  return [
    {
      field: 'mailId',
      header: 'ID courrier',
      format: (value, item: IPointActionSearchResult) => {
        if (item.mailId) {
          return item.mailId.toString();
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
      tooltip: (rowData) => {
        return rowData.mailId;
      },
    },
    {
      field: 'demandeur',
      header: 'Demandeur',
      format: (value, item: IPointActionSearchResult) => {
        const foundUserInfos = self.contactInfosService.fetchUserInfosByUserId(item.action.transmissionUserId);
        if (foundUserInfos) {
          return foundUserInfos.contactForUser.prenom + ' ' + foundUserInfos.contactForUser.nom;
        } else {
          return 'Pas de demandeur';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
      tooltip: (rowData) => {
        return rowData.demandeur;
      },
    },
    {
      field: 'contactId',
      header: 'Receveur',
      format: (value, item: IPointActionSearchResult) => {
        const foundContactInfos = self.contactInfosService.fetchUserInfosByContactId(item.action);
        if (foundContactInfos) {
          return foundContactInfos.prenom + ' ' + foundContactInfos.nom;
        } else {
          return 'Pas de receveur';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
      tooltip: (rowData) => {
        return rowData.contactId;
      },
    },
    {
      field: 'action',
      header: 'Type',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.action) {
          return item.action.action;
        } else {
          return 'Pas de type';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
      tooltip: (rowData) => {
        return rowData.action;
      },
    },
    {
      field: 'actionId',
      header: 'Action ID',
      visible: false,
      format: (value, item: IPointActionSearchResult) => {
        return item.action.id;
      },
    },
    {
      field: 'echeance',
      header: "Date d'écheance",
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.echeance) {
          return moment(item.action.echeance).format('DD/MM/YYYY');
        } else {
          return 'Écheance non renseignée';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
      tooltip: (rowData) => {
        return rowData.echeance;
      },
    },
    {
      field: 'message',
      header: 'Message',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.message) {
          return item.action.message && item.action.message.length > 30
            ? item.action.message.substr(0, 30) + '...'
            : item.action.message;
        } else {
          return 'Pas de message';
        }
      },
      tooltip: (item: IPointActionSearchResult) => {
        if (item.message) {
          return item.message;
        } else {
          return 'Pas de message';
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'objet',
      header: 'Titre',
      format: (value, item: IPointActionSearchResult) => {
        if (item.objet) {
          const temp = item.objet;
          return temp.length > 30 ? temp.substr(0, 30) + '...' : temp;
        }
      },
      tooltip: (item: IPointActionSearchResult) => {
        return item.fullObjet;
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
    },
    {
      field: 'serviceProprietaireId',
      header: 'Service',
      format: (value, item: IPointActionSearchResult) => {
        if (item.serviceProprietaireId && self.services.length > 0) {
          let currentService = [];
          currentService = self.services.filter((x) => x.id === item.serviceProprietaireId);
          return currentService[0].description;
        }
      },
      click: (column, rowData) => {
        self.openMail(column);
      },
      tooltip: (item: IPointActionSearchResult) => {
        return item.serviceProprietaireId;
      },
    },
    {
      field: 'reponse',
      header: 'Réponse',
      sortable: false,
      align: 'right',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.reponse) {
          return item.action.reponse;
        } else {
          return `Aucune réponse`;
        }
      },
      tooltip: (item: IPointActionSearchResult) => {
        if (item.action.fullreponse) {
          return item.action.fullreponse;
        } else {
          return `Aucune réponse`;
        }
      },
    },
    {
      field: 'actionss',
      header: 'Action',
      sortable: false,
      align: 'right',
      format: (value, item: IPointActionSearchResult) => {
        if (item.action.contactIdiAdmin === self.userInfos?.contactId && item.action.traiteLe === null) {
          return `<div class="btn btn-primary d-block w-100">Traiter l'action</div>`;
        } else {
          return `Aucune action disponible`;
        }
      },
      click: (column, rowData) => {
        self.answerToAction(column);
      },
    },
  ];
};

export const sizeOfPagesOptions: { label: string; value: number }[] = [
  { label: 'Maximum 100 actions affichées pour le tableau', value: 100 },
  { label: 'Maximum 200 actions affichées pour le tableau', value: 200 },
  { label: 'Maximum 500 actions affichées pour le tableau', value: 500 },
  { label: 'Maximum 1000 actions affichées pour le tableau', value: 1000 },
  { label: 'Maximum 5000 actions affichées pour le tableau', value: 5000 },
];
