<div class="modal-header super-compos-modal-header">
     <h5 class="modal-title super-compos-modal-title">
          {{header}}
     </h5>
     <div class="d-flex align-items-center">
          <super-button styleClass="blue mr-3" [text]="'btn.cancel'|translate" (click)="ref.close()"></super-button>
          <super-button styleClass="green" (click)="submit()" [text]="'btn.confirm'|translate"></super-button>
     </div>
</div>
<div class="modal-body">
     <section class="mb-5 mt-5" [formGroup]="form">
          <div class="row">
               <div class="col-md-12">
                    <table class="table">
                         <thead class="thead-light">
                              <tr>
                                   <th *ngFor="let col of columnsArray">
                                        {{col.header}}
                                   </th>
                              </tr>
                         </thead>
                         <tbody>
                              <tr formArrayName="numerotationListe" *ngFor="let item of numerotationListe.controls; let i = index;">
                                   <td>
                                        {{itemsArray[i]?.title}}
                                   </td>

                                   <td [formGroupName]="i">
                                        <input type="number" (keypress)="removeLetters($event)" pInputText formControlName="ordreReel">
                                   </td>
                                   <td [formGroupName]="i">
                                        <input type="number" (keypress)="removeLetters($event)" pInputText formControlName="ordre">
                                   </td>
                                   <td [formGroupName]="i" *ngIf="!getConfiguration.delibConfig.masquerOrdrePassageService">
                                        <input type="number" (keypress)="removeLetters($event)" pInputText formControlName="triService">
                                   </td>
                                   <td>
                                        {{itemsArray[i]?.serviceLabel}}
                                   </td>
                              </tr>
                         </tbody>
                    </table>
               </div>
          </div>
     </section>
</div>