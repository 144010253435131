export interface Action {
  label: string;
  icon?: string;
  img?: string;
  action: () => void;
  condition: boolean;
  isIconClass?: boolean;
}

export interface CategoryActions {
  label: string;
  actions: Action[];
}
export enum ActionType {
  OpenPoint = 'openPoint',
  UpdatePoint = 'updatePoint',
  DuplicatePoint = 'duplicatePoint',
  PostponePoint = 'postponePoint',
  OpenCreationModelPointBasedOnExistingPoint = 'openCreationModelPointBasedOnExistingPoint',
  CreateOutgoingMail = 'createOutgoingMail',
  DeletePoint = 'deletePoint',
  Up = 'up',
  Down = 'down',
  AddEventBefore = 'addEventBefore',
  FinishUnfinishPoint = 'finishUnfinishPoint',
  LockUnlock = 'lockUnlock',
  MarkACorriger = 'markACorriger',
  ValidateCorrection = 'validateCorrection',
  ApproveUnanimously = 'approveUnanimously',
  CloseModal = 'CloseModalActions'
}