<section class="meeting-create mt-4" [formGroup]="form">
  <super-title [text]="'btn.createMeeting' | translate" [buttons]="buttons"></super-title>
  <div class="row mt-4">
    <div class="col-md-12 mb-2">
      <h3 class="super-title-h3">{{ 'infosOnMeeting' | translate }}</h3>
    </div>
    <div class="col-md-12 mb-3">
      <label class="input-label d-block">{{ 'meetingType' | translate }} </label>
      <p-dropdown
        [virtualScroll]="true"
        itemSize="30"
        [options]="types_seances_LV | sort: 'label' : 'ASC'"
        formControlName="typeSeanceId"
        [placeholder]="'selectATypeOfMeeting' | translate"
        appendTo="body"
        [showClear]="true"
        [filter]="true">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="truncateLabel">{{ item.label }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col-md-12 mb-2">
      <h3 class="super-title-h3">{{ 'date' | translate }}</h3>
      <div class="d-flex align-items-center">
        <div class="field-radiobutton">
          <p-radioButton name="dateType" value="manuel" formControlName="dateType" inputId="manuel"></p-radioButton>
          <label for="manuel" class="mb-0 ml-2 mr-4">{{ 'manualSelection' | translate }}</label>
        </div>
        <div class="field-radiobutton">
          <p-radioButton name="dateType" value="hebdo" formControlName="dateType" inputId="recurrence"></p-radioButton>
          <label for="recurrence" class="mb-0 ml-2 mr-4">{{ 'weeklyRecurrence' | translate }}</label>
        </div>
        <div class="field-radiobutton">
          <p-radioButton
            name="dateType"
            value="monthly"
            formControlName="dateType"
            inputId="recurrenceM"></p-radioButton>
          <label for="recurrenceM" class="mb-0 ml-2">{{ 'monthlyRecurrence' | translate }}</label>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-4">
      <div class="create-form row">
        <div class="col-md-9" *ngIf="form.get('dateType').value === 'manuel'">
          <p-calendar
            [firstDayOfWeek]="1"
            formControlName="seanceDates"
            styleClass="w-100"
            appendTo="body"
            [inline]="true"
            [monthNavigator]="true"
            selectionMode="multiple"
            dateFormat="dd/mm/yy"></p-calendar>
        </div>
        <div class="col-md-9" *ngIf="form.get('dateType').value === 'hebdo'">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="" class="input-label">{{ 'firstMeetingDate' | translate }}</label>
              <p-calendar
                [firstDayOfWeek]="1"
                styleClass="w-100"
                formControlName="startDate"
                [monthNavigator]="true"
                selectionMode="single"
                appendTo="body"
                dateFormat="dd/mm/yy"></p-calendar>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="input-label d-block">{{ 'recurrencePeriod' | translate }}</label>
              <p-dropdown
                [virtualScroll]="true"
                itemSize="30"
                [options]="weeks"
                styleClass="w-100"
                formControlName="weeks_reccurence"
                appendTo="body"
                [placeholder]="'chooseAPeriod' | translate">
                <ng-template let-week pTemplate="item">
                  <div pTooltip="{{ week?.label }}" tooltipPosition="right" class="truncateLabel">{{ week.label }}</div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="input-label d-block">{{ 'recurrenceDuration' | translate }}</label>
              <input type="number" formControlName="period" class="form-control" />
            </div>
            <div class="col-md-12 mb-3">
              <super-button
                [text]="'btn.generateDates' | translate"
                styleClass="blue"
                (click)="generateHebdo()"></super-button>
            </div>
          </div>
        </div>
        <div class="col-md-9" *ngIf="form.get('dateType').value === 'monthly'">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="" class="input-label">{{ 'firstMeetingDate' | translate }}</label>
              <p-calendar
                [firstDayOfWeek]="1"
                styleClass="w-100"
                formControlName="startDate"
                [monthNavigator]="true"
                selectionMode="single"
                appendTo="body"
                dateFormat="dd/mm/yy"></p-calendar>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="input-label d-block">{{ 'everyXOfTheMonth' | translate }}</label>
              <p-dropdown
                [virtualScroll]="true"
                itemSize="30"
                [options]="daysWeeks"
                styleClass="w-100"
                formControlName="weeks_reccurence"
                appendTo="body"
                [placeholder]="'chooseADay' | translate">
                <ng-template let-week pTemplate="item">
                  <div pTooltip="{{ week?.label }}" tooltipPosition="right" class="truncateLabel">{{ week.label }}</div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="input-label d-block">{{ 'recurrenceDuration' | translate }}</label>
              <input type="number" formControlName="period" class="form-control" />
            </div>
            <div class="col-md-12 mb-3">
              <super-button
                [text]="'btn.generateDates' | translate"
                styleClass="blue"
                (click)="getDatesOfDayOfWeek()"></super-button>
            </div>
          </div>
        </div>
        <div class="col-md-3 border-left">
          <label class="input-label d-block text-center"
            >{{ 'selectedDates' | translate }}({{ 'format.formattedDate' | translate }})</label
          >
          <div *ngFor="let item of form.getRawValue().seanceDates; let i = index" class="date-element">
            {{ item | momentFormatTranslation }}
            <div class="rounded">
              <i class="fas fa-trash-alt" (click)="deleteIndex(i)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
