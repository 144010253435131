import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor() {}

  public saveData(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  public getData(key: string): string {
    return sessionStorage.getItem(key);
  }

  public keyExists(key: string): boolean {
    return Boolean(this.getData(key));
  }

  public HasValueTrue(key: string): boolean {
    return this.getData(key) === '1';
  }

  public removeData(key: string) {
    sessionStorage.removeItem(key);
  }

  public clearData() {
    sessionStorage.clear();
  }
}
