import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/core/services/api.service';
import * as async from 'async';
import { Point } from 'src/app/shared/models/point';
import { DataService } from 'src/app/core/services/data.service';
import { Global_Class } from 'src/app/shared/models/global';
import { Contact } from 'src/app/shared/models/iContact/contact';
import { NgxSpinnerService } from 'ngx-spinner';
import { SuperColumn, SuperTableComponent } from '@devinforius/super-compos';
import { File } from 'src/app/shared/models/files';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
import { IFromDuplicationPointDetails } from 'src/app/shared/models/point/IFromDuplicationPointDetails';
import { Router } from '@angular/router';

@Component({
  selector: 'app-private',
  templateUrl: './duplication-point.component.html',
  styleUrls: ['./duplication-point.component.scss'],
})
export class DuplicationPointComponent implements OnInit {
  @ViewChild('superTableFilesDuplication') public superTableFilesDuplication: SuperTableComponent;

  public pointId: number;
  public point: Point;
  public globalElements: Global_Class;
  public configGE;
  public listOfContactsAdmin: Array<Contact> = [];
  public contacts: Array<Contact> = [];
  public filesForDuplication: Array<File> = [];
  public openDuplicationManagementModal: boolean = false;
  public duplicationManagementIndex: number = 0;
  public blobViewer: any;
  public modalPdfViewer: boolean = false;
  public formDuplicationPoint = new FormGroup({
    infos: new FormControl(true),
    files: new FormControl(null),
    listOfFiles: new FormControl(null),
    recipients: new FormControl(null),
  });
  public hasLoaded: boolean = false;
  public fileUploadChooseLabel;
  public fileUploadUploadLabel;
  public fileUploadCancelLabel;
  public currentPage: number = 1;
  public columnsFiles: Array<SuperColumn> = [
    {
      field: 'description',
      header: 'description',
    },
    {
      field: 'extension',
      header: 'extension',
    },
    {
      field: 'apercu',
      header: 'Aperçu',
      format: (value, item) => {
        if (item.extension.toLowerCase() === 'pdf') {
          return '<i class="fas fa-eye"></i>';
        }
      },
      click: (value) => {
        if (value.extension.toLowerCase() === 'pdf') {
          this.openPreviewFile(value);
        }
      },
    },
    {
      field: 'fileType',
      header: this.translate.instant('fileType'),
      format: (value, item) => {
        if (item.modeleId && item.extension.toLowerCase() === 'pdf') {
          return this.translate.instant('document');
        } else if (item.annexeNumero) {
          return this.translate.instant('annexN°') + ' ' + item.annexeNumero;
        } else {
          return this.translate.instant('associatedFile');
        }
      },
    },
    {
      field: 'dateUpload',
      header: 'dateupload',
      format: (value) => {
        if (value) {
          return moment(value).format('DD/MM/YYYY');
        }
      },
    },
  ];

  constructor(
    public config: DynamicDialogConfig,
    public api: ApiService,
    public data: DataService,
    public spinner: NgxSpinnerService,
    public translate: TranslateService,
    public route: Router,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit() {
    async.waterfall([
      (callback) => {
        this.pointId = this.config.data.pointId;
        this.globalElements = this.config.data.globalElements;
        this.listOfContactsAdmin = this.config.data.listOfContactsAdmin;
        this.contacts = this.config.data.contacts;
        callback();
      },
      (callback) => {
        this.api.getPointByID(this.pointId).subscribe((res: any) => {
          this.point = new Point(res, this.globalElements, null, this.listOfContactsAdmin, this.contacts);
          callback();
        });
      },
      (callback) => {
        this.setFilesRights().then(() => {
          callback();
        });
      },
      (callback) => {
        this.spinner.show();
        this.hasLoaded = false;
        this.getTranslation();
        this.api.getPointFiles(this.pointId).subscribe(
          (data) => {
            data.map((x) => new File(x));
            this.filesForDuplication = data.filter((x) => !x.modeleId);
            this.hasLoaded = true;
            this.spinner.hide();
            callback();
          },
          (err) => {
            this.filesForDuplication = [];
            this.spinner.hide();
            callback();
          }
        );
      },
    ]);
  }

  setFilesRights() {
    return new Promise((resolve, reject) => {
      this.configGE = this.globalElements.configApp;
      if (this.configGE.delibConfig.isiDelibeMandatairesEnabled) {
        this.columnsFiles.push({
          field: 'visibleDelibeWeb',
          header: 'webVisibility',
          format: (value) => {
            return this.showBoolean(value);
          },
        });
      }
      if (this.configGE.delibConfig.isiDelibeCitoyensEnabled) {
        this.columnsFiles.push({
          field: 'visibleDelibeWebCitoyen',
          header: 'webVisibility.citoyen',
          format: (value) => {
            return this.showBoolean(value);
          },
        });
      }
      resolve(true);
    });
  }

  getTranslation() {
    this.fileUploadChooseLabel = this.translate.instant('btn.choose');
    this.fileUploadUploadLabel = this.translate.instant('btn.upload');
    this.fileUploadCancelLabel = this.translate.instant('btn.cancel');
    this.columnsFiles.map((x) => (x.header = this.translate.instant(x.header)));
  }

  openPreviewFile(item) {
    if (item && item.id) {
      this.api.downloadPointFile(this.pointId, item.id).subscribe((res: any) => {
        this.blobViewer = URL.createObjectURL(res);
        this.modalPdfViewer = true;
      });
    }
  }

  //* return img for the given value
  private showBoolean(value) {
    if (value) {
      return `<b style="color: #07E299;"><i class="bi bi-check-circle-fill"></i></b>`;
    } else if (value === -1) {
      return `<b style="color: #FFAF2F;"><i class="bi bi-hourglass-split"></i></b>`;
    } else {
      return `<b style="color: #FF5671;"></b>`;
    }
  }

  //* method used when user submits the form on the duplication modal management (whatever part he's in)
  //@params no params needed, all is set by the form
  submitDuplicationManagement() {
    if (this.duplicationManagementIndex === 0) {
      this.data.SetDuplicatedPoint(this.point);
      //on va alors vérifier si on veut des fichiers. Si oui, on va faire choisir les fichiers a l'utilisateur
      if (this.formDuplicationPoint.value.files) {
        this.duplicationManagementIndex = 1;
        // this.formDialogDuplicatePoint.resetPosition()
      } else {
        //si pas, on va simplement aller dans l'interface de création/duplication
        this.data.setDuplicatedPointManagement(this.formDuplicationPoint.value as IFromDuplicationPointDetails);
        this.ref.close();
      }
    } else if (this.duplicationManagementIndex === 1) {
      //on est ds le tableau des fichiers
      //ici on va envoyer les ids des fichiers cochés dans le formulaire à renvoyer
      this.formDuplicationPoint.patchValue({
        listOfFiles: this.superTableFilesDuplication._selection.map((file) => file.id),
      });
      this.data.setDuplicatedPointManagement(this.formDuplicationPoint.value as IFromDuplicationPointDetails);
      this.ref.close();
    }
  }
}
