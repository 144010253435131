export class Destinataire {
    id: number;
     principal: boolean = false;
     destinataire:string;
     echeance:string;
     date:string;
     dateEcheance:string;
     demandeur:string;
     action:string;
     reponse:string;
     type : string;
     contactId : number;
     contactIdiAdmin : number;
     libre : string;
     name : string;
     nom: string;
     prenom : string
     constructor(data:any) {
         Object.assign(this, data);
        this.name= this.nom + ' ' + this.prenom;
     }
 
}
 