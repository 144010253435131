<i-modal
  [visible]="true"
  [modalParameters]="modalParams"
  [header]="modalHeader"
  class="modal-actions"
  (emitterOnVisibleChange)="closeDialog()">
  <ng-container *ngIf="categoryActions.length > 0">
    <div *ngFor="let category of categoryActions">
      <p class="modal-actions__category" *ngIf="true">{{category.label}}</p>
      <div *ngFor="let action of category.actions">
        <div *ngIf="action.condition"
          class="modal-actions__action"
          (click)="closeDialog(); action.action()">
          <div class="modal-actions__action__left">
            <img *ngIf="action.img" [src]="action.img" class="modal-actions__action__left__img"/>
            <i *ngIf="action.icon" [class]="action.icon + ' modal-actions__action__left__icon'"></i>
            <p class="modal-actions__action__left__label">{{ action.label }}</p>
          </div>
          <i class="bi bi-arrow-right"></i>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="categoryActions.length <= 0">
    <div *ngFor="let i of [1,1,1]">
      <p-skeleton width="8rem" styleClass="mb-2 mt-3"></p-skeleton>
      <div *ngFor="let i of [1,1,1,1]">
        <p-skeleton height="2rem" styleClass="mb-2"></p-skeleton>
      </div>
    </div>
  </ng-container>
</i-modal>