import {
  fetchActionsTableColumnsWhenHideStatutAction,
  fetchActionsTableColumnsWhenHideStatutActionNoSeanceColumn,
  fetchActionsTableColumnsWhenNotHideStatutAction,
  fetchActionsTableColumnsWhenNotHideStatutActionNoSeanceColumn,
} from './actionsTableSettings.utils';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FetchMode, IActionsListHolder, ITabInfo } from './models';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import { SuperColumn } from '@devinforius/super-compos';
import { ContactInfosService } from 'src/app/core/services/contact-infos.service';
import { UserInfo } from 'src/app/shared/models/user-info';
import { first, switchMap, tap } from 'rxjs/operators';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { Subscription, iif, of } from 'rxjs';
import { isEmptyObject } from 'src/app/shared/utils/checks.utils';
import { Service } from 'src/app/shared/models/service';
import { fetchTabsDataObservable, fetchThingsRelatedToActions } from './actionsTableRows.utils';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { Action_Types } from 'src/app/shared/models/actions_type';
import { ActionResponseModalComponent } from 'src/app/shared/components/modals/action-response-modal/action-response-modal.component';
import { LabelValue } from 'src/app/shared/models/labelvalue';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'underscore';

@Component({
  selector: 'app-actions-container',
  templateUrl: './actions-container.component.html',
  styleUrls: ['./actions-container.component.scss'],
  providers: [ContactInfosService],
})
export class ActionsContainerComponent implements OnInit, OnDestroy {
  actionTabs: ITabInfo[] = [
    {
      title: 'A faire',
      slug: 'todo',
      active: true,
      count: 0,
      tooltip: 'Le destinataire est la personne connectée, statut en cours',
    },
    {
      title: 'Terminées',
      slug: 'finished',
      count: 0,
      tooltip: 'Le destinataire est la personne connectée, statut terminées',
    },
    {
      title: 'Toutes',
      slug: 'all',
      count: 0,
      tooltip: "Le destinataire est la personne connectée, statut en cours ET terminées, Date d'échéance = toutes",
    },
    {
      title: "Actions que j'ai demandées",
      slug: 'asked',
      count: 0,
      tooltip: "L'expéditeur est la personne connectée, statut en cours ET terminées",
    },
  ];
  fetchMode: FetchMode = FetchMode.Points;
  activeTabSlug: ITabInfo['slug'] = 'todo';
  tabDataReady: boolean = false;
  hideStatutAction: boolean;
  actionsHolder: IActionsListHolder = {
    all: { items: [], totalCount: 0, pageNumber: 1, totalPages: 1 },
    asked: { items: [], totalCount: 0, pageNumber: 1, totalPages: 1 },
    finished: { items: [], totalCount: 0, pageNumber: 1, totalPages: 1 },
    todo: { items: [], totalCount: 0, pageNumber: 1, totalPages: 1 },
  };
  sizeChoice: number = 100;
  columns: SuperColumn[] = [];
  columnsSeances: SuperColumn[] = [];
  allUsers: { userId: string }[] = [];
  services: Service[] = [];
  userInfos: UserInfo;
  actionStatus: Action_Types[];
  actionTypes: LabelValue[];
  tableSetupSubscription: Subscription;
  actualPointSeanceTab = 0;

  constructor(
    public api: ApiService,
    private dataService: DataService,
    public contactInfosService: ContactInfosService,
    public icontactService: IContactService,
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.doIniate();
    this.titleService.setTitle('iDélibé - Actions');
  }

  doIniate() {
    try {
      this.spinner.show();
      this.tableSetupSubscription = this.makeTableObservablesSetup().subscribe(() => {
        this.tabDataReady = true;
        this.spinner.hide();
        if (this.hideStatutAction) {
          this.columns = fetchActionsTableColumnsWhenHideStatutAction(this);
          this.columnsSeances = fetchActionsTableColumnsWhenHideStatutActionNoSeanceColumn(this);
        } else {
          this.columns = fetchActionsTableColumnsWhenNotHideStatutAction(this);
          this.columnsSeances = fetchActionsTableColumnsWhenNotHideStatutActionNoSeanceColumn(this);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  makeTableObservablesSetup() {
    return this.dataService.getGlobalElements.pipe(
      tap((globalElementsRes) => {
        if (!isEmptyObject(globalElementsRes)) {
          this.userInfos = globalElementsRes.userInfo;
          this.services = globalElementsRes.services;
          this.actionStatus = globalElementsRes.actions_status;
          this.actionTypes = globalElementsRes.actions_type_lv;
          this.hideStatutAction = globalElementsRes?.configApp?.delibConfig?.hideStatutAction;
        }
      }),
      switchMap((globalElementsRes) => {
        const isOkToContinue = !isEmptyObject(globalElementsRes);
        const actionsData$ = fetchTabsDataObservable(this, isOkToContinue);
        // if globalElements are ok than fetch actions
        return iif(() => isOkToContinue, actionsData$);
      }),
      switchMap((actionsData) => {
        if (!actionsData) return of([]);
        return fetchThingsRelatedToActions(actionsData, this);
      })
    );
  }

  refereshTabsData() {
    this.spinner.show();
    this.makeTableObservablesSetup()
      .pipe(first())
      .toPromise()
      .then(() => {
        this.spinner.hide();
      });
  }

  // switching between actions related to points to those related to seances (vice versa)
  handleModeSwitch(eventObj: { index: number }) {
    this.actualPointSeanceTab = eventObj.index;
    this.fetchMode = eventObj.index;
    this.refereshTabsData();
  }

  handleTabChange(newSlug: ITabInfo['slug']) {
    this.activeTabSlug = newSlug;
    this.actionTabs.forEach((x) => {
      x.active = x.slug == newSlug;
    });
  }

  changePageSize(newSizeChoice: number) {
    this.spinner.show();
    this.sizeChoice = newSizeChoice;
    this.refereshTabsData();
  }

  changePageNumber(type: ITabInfo['slug'], newValue: number) {
    this.actionsHolder[type].pageNumber = newValue;
    fetchTabsDataObservable(this, true, type)
      .pipe(first())
      .toPromise()
      .then(() => {});
  }

  answerToAction(column: { pointId?: string; seanceId?: string; actionId: string }) {
    const actionStatusList = [];
    this.actionStatus.map((s, i) => {
      actionStatusList.push({
        label: s.description,
        value: s.description,
        id: s.id,
        ordreAffichage: i,
      });
    });
    const modalRef = this.modalService.open(ActionResponseModalComponent, {
      windowClass: 'super-compos-modal-content',
      size: 'md',
    });
    modalRef.componentInstance.openingWindow = modalRef;
    modalRef.componentInstance.currentAction = { id: column.actionId };
    modalRef.componentInstance.caller = 'courrier';
    modalRef.componentInstance.types = this.actionTypes;
    modalRef.componentInstance.status = actionStatusList;
    modalRef.componentInstance.pointId = column.pointId;
    modalRef.componentInstance.meetingId = column.seanceId;

    modalRef.result.then((value) => {
      this.refereshTabsData();
    });
  }

  openMail(column) {
    const config = JSON.parse(sessionStorage.getItem('globalConfig'));
    if (column.pointId) {
      window.open(config.baseUrl + '/private/points/detail/' + column.pointId);
    } else if (column.seanceId) {
      window.open(config.baseUrl + '/private/meetings/details/' + column.seanceId);
    }
  }

  ngOnDestroy(): void {
    this.tableSetupSubscription.unsubscribe();
  }
}
