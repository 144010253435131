import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SuperColumn, SuperRichEditComponent, SuperTableComponent } from '@devinforius/super-compos';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import * as async from 'async';
import { DocumentFormatApi } from 'devexpress-richedit/lib/model-api/formats/enum';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { Global_Class } from 'src/app/shared/models/global';
import { Contact } from 'src/app/shared/models/iContact/contact';
import { LabelValue } from 'src/app/shared/models/labelvalue';
import { Meeting } from 'src/app/shared/models/meeting';
import { Model_Point_Biblio } from 'src/app/shared/models/model_point_from_bibliotheque';
import { Point } from 'src/app/shared/models/point';
import { IFromDuplicationPointDetails } from 'src/app/shared/models/point/IFromDuplicationPointDetails';
import { Service } from 'src/app/shared/models/service';
import { TenantInfo } from 'src/app/shared/models/tenant-info';
import * as _ from 'underscore';

@Component({
  selector: 'app-create-point',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreatePointComponent implements OnInit {
  // #region forms
  //* Form that is used for datas displayed in general + decisionType in content tab
  public infosForm = new FormGroup({
    objetSynthese: new FormControl(null, Validators.required),
    typeSeanceId: new FormControl(null, Validators.required),
    seanceId: new FormControl(null, Validators.required),
    serviceId: new FormControl(null, Validators.required),
    typeId: new FormControl(null, Validators.required),
    confidentiel: new FormControl(),
    public: new FormControl(true),
    checkRgpd: new FormControl(),
    origine: new FormControl(),
    courrierId: new FormControl(),
    matiereId: new FormControl(),
    echevinatId: new FormControl(),
    agentTraitantId: new FormControl(),
    agentTraitantIdiAdmin: new FormControl(),
    urgenceNiveauId: new FormControl(),
    classementId: new FormControl(),
    dossierClassementId: new FormControl(),
    folder: new FormControl(),
    dossierClassementDescription: new FormControl(),
    sleeve: new FormControl(),
    dossierClassement2Description: new FormControl(),
    statutId: new FormControl(null, Validators.required),
    typeDecisionId: new FormControl(),
    FichiersIds: new FormControl(null), //Only used in duplication. For other uses it's deleted
    RTFContent: new FormControl(null), //Only used in duplication. For other uses it's deleted,
    iCourrierFichierIds: new FormControl(null),
    modelePointId: new FormControl(null),
    unlockPoint: new FormControl(),
  });
  public formFilesModification: FormGroup = new FormGroup({
    id: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    annexeNumero: new FormControl(),
    annexType: new FormControl(),
    OrdreAffichage: new FormControl(),
    visibleDelibeWeb: new FormControl(),
    visibleDelibeWebCitoyen: new FormControl(),
  });

  //endregion

  //#region tabs
  public activeTab: number = 0; //* Active tab the user's on
  public itemsTabs: Array<any> = [
    //* List of tabs displayed
    { header: 'infosAndLinks' },
    { header: 'content', disabled: true },
    { header: 'joinedFiles', disabled: true },
    // {label: this.translate.instant('summary')},
  ];
  //endregion

  //#region files
  public selectedFiles = []; //* Selected files in the file table
  public openModifyAFileInModal: boolean = false; //* boolean that displays the dialog to modify a file's datas
  public itemFiles = []; //* List of files
  //endregion

  //#region actions/menus
  public actions: Array<any> = []; //* menu that contains what will be displayed in the p-tiered button for the file table => selection button like in super-table
  public actionsRow: Array<any> = [
    //* Menu that will contains the datas displayed for the button on each row of the p-table file
    {
      label: 'btn.modify',
      tooltip: 'btn.modify',
      command: (data) => {
        this.openModifyAFileInModal = true;
        this.formFilesModification.patchValue({
          id: this.selectedRow.id,
          description: this.selectedRow.description,
          annexeNumero: this.selectedRow.annexeNumero,
          annexType:
            this.selectedRow.extension === 'pdf' && !this.selectedRow.modeleId
              ? this.selectedRow.annexeNumero
                ? true
                : false
              : false,
          OrdreAffichage: this.selectedRow.OrdreAffichage,
          visibleDelibeWeb: this.selectedRow.visibleDelibeWeb,
          visibleDelibeWebCitoyen: this.selectedRow.visibleDelibeWebCitoyen,
        });
      },
    },
  ];
  public menuModelesPoint: Array<any> = [
    {
      label: 'btn.use',
      command: (data) => {
        this.applyModelePoint(this.selectedRowModelePoint);
      },
    },
  ];
  //endregion

  //#region datas
  public globalConfiguration;
  public globalElements;
  public departments = [];
  public handler = [];
  public seances = [];
  public classeurs = [];
  public chemises = [];
  public validatedPointId = null;
  public mandatoryPointParameters; //* Will contains the mandatory parameters to set the form validators according to the settings
  public pointId = null;
  public actualPoint: Point = null;
  public update: boolean = false; //* Used to know if it's a creation or an update
  public titleComponent: string; //* Title displayed in super-title
  public annexStringTranslated; //* Translated labels
  public fileUploadChooseLabel; //* Translated labels
  public mainFileStringTranslated; //* Translated labels
  public fileUploadUploadLabel; //* Translated labels
  public fileUploadCancelLabel; //* Translated labels
  public config; //* Contains the Global/configurations datas
  public selectedRow; //* Will contain the row clicked on with the ... button in p-table
  public point: Point;
  public userInfos; //* Contains the user infos
  public selectedType = '';
  public Motivation = []; //* Contains the datas for the Motivation accordion (json)
  public Decision = []; //* Contains the datas for the Decision accordion (json)
  public Observations = []; //* Contains the datas for the Observations accordion (json)
  public NotesDeSynthese = []; //* Contains the datas for the NotesDeSynthese accordion (json)
  public ComplementDeDeliberation = []; //* Contains the datas for the ComplementDeDeliberation accordion (json)
  activeState: boolean[] = [false, false, false, false, false]; //* contains the booleans for active accordions
  public contenuStaticRTFFilesPresents; //*  Contains the presence of datas in each RTF static accordion
  public token;
  public indexCheckReloadForRTF = [0, 0, 0, 0, 0]; //* LIst of the indexes to have a passive reload of the component
  public currentIndex;
  public handlerType = 'users'; //* Displays the right dropdown for the contacts/users
  public avoidChangeSeance: boolean = false; //* Fordid the user to change the type seance and seance itself
  public avoidChangeObjetType: boolean = false; //* Forbid the user to change the object type within a type meeting 'cause of params
  public avoidChangeObjetTypeLabel = null; //* Contains the label of the forbidden to change typePoint for display purpose
  public contentEditors: Array<any> = [
    { Motivation: null },
    { Decision: null },
    { Observations: null },
    { ComplementDeDeliberation: null },
    { NotesDeSynthese: null },
  ]; //* contains the datas of each editor
  public types_seances_LV: Array<LabelValue> = [];
  public currentMeetingToPatch: Meeting = null; //* Contains the meeting to patch into the form
  public listeAgentsTraitants: Array<Contact> = []; //* list of agents traitants coming from delibe
  public listeAgentsTraitantsAdmin: Array<Contact> = []; //* list of agents traitants coming from admin
  public currentRightsSeance; //* Contains the rights of the seance, if teh seance was given beforehand
  public displayCourrierId = false;
  public seanceSelectedInfos = null;
  public avoidChangeEchevinat = null;
  public avoidChangeMatiere = null;
  public avoidChangeStatut = null;
  public showAllAccordion = false;
  public lockExpiration: boolean = false;
  writeLock: any = {
    currentUser: true,
  };

  //endregion

  //#region viewChild
  @ViewChild('uploader') uploader: FileUpload; //* view  Child of the uploader component
  @ViewChild('document_editor_motivation') public document_editor_motivation: SuperRichEditComponent; //ViewChild of the Motivation part of the accordion
  @ViewChild('document_editor_decision') public document_editor_decision: SuperRichEditComponent; //ViewChild of the part of Decision the accordion
  @ViewChild('document_editor_observation') public document_editor_observation: SuperRichEditComponent; //ViewChild of the Observation part of the accordion
  @ViewChild('document_editor_complementDeDeliberation')
  public document_editor_complementDeDeliberation: SuperRichEditComponent; //ViewChild of the Complement de deliberation part of the accordion
  @ViewChild('document_editor_notesDeSynthese') public document_editor_notesDeSynthese: SuperRichEditComponent; //ViewChild of the Notes de synthèses part of the accordion
  //endregion

  public loaded = false;
  public buttons = [];
  public count = 0; //* Count used to count the amount of files to upload
  public timerInterval: any;
  //#region point duplication
  public currentPointToDuplicate: Point = null; //* Contains the point to be duplicated
  public duplication: boolean = false; //* boolean to notice if it's a duplication ongoing
  public pointToDuplicateManagement: IFromDuplicationPointDetails = null; //* Contains what the user wants to duplicate for their point
  public fusions: Array<any> = [];
  public hasAlreadyClickOnFilesTabs: boolean = false;
  public confirmDialogBool: boolean = false;
  public itemsUploaded: any;
  public uploaders: any;
  public groupeSecurite: any;
  public openModalForFilesSelectionCourrier: boolean = false;
  public columnsFiles: Array<SuperColumn> = [
    {
      field: 'description',
      header: 'description',
    },
    {
      field: 'extension',
      header: 'extension',
    },
    {
      field: 'fileType',
      header: 'Type de fichier',
      format: (value, item) => {
        if (item.modeleId && item.extension.toLowerCase() === 'pdf') {
          return this.translate.instant('document');
        } else if (item.annexeNumero) {
          return this.translate.instant('annexN°') + ' ' + item.annexeNumero;
        } else {
          return this.translate.instant('associatedFile');
        }
      },
    },
    {
      field: 'dateUpload',
      header: "Date d'import ",
      format: (value) => {
        if (value) {
          return moment(value).format('DD/MM/YYYY');
        }
      },
    },
  ];
  public filesForDuplication: Array<any> = [];

  public columnsModelesPoints: Array<any> = [
    {
      field: 'serviceLabel',
      header: 'Service',
      format: (v) => {
        if (v) {
          return v;
        }
      },
    },
    {
      field: 'typeSeanceLabel',
      header: 'Type de séance',
      format: (v, item) => {
        if (v) {
          return v;
        }
      },
    },
    {
      field: 'objet',
      header: 'Titre',
      format: (v) => {
        if (v) {
          return v;
        }
      },
    },
    {
      field: 'createdBy',
      header: 'Créé par',
      format: (v) => {
        if (v) {
          return v;
        }
      },
    },
    {
      field: 'createdOnLabel',
      header: 'Date de création',
      format: (v, item) => {
        if (item.createdOn) {
          return moment(item.createdOn).format('DD/MM/YYYY');
        }
      },
    },
    {
      field: 'modifiedBy',
      header: 'Modifié par',
      format: (v) => {
        if (v) {
          return v;
        }
      },
    },
    {
      field: 'modifiedOnLabel',
      header: 'Date de modification',
      format: (v, item) => {
        if (item.modifiedOn) {
          return moment(item.modifiedOn).format('DD/MM/YYYY');
        }
      },
    },
    {
      field: 'matiereLabel',
      header: 'Matière',
      visible: false,
      format: (v, item) => {
        if (v) {
          return v;
        }
      },
    },
    {
      field: 'defautObjetTypeLabel',
      header: 'Type de point',
      visible: false,
      format: (v, item) => {
        if (v) {
          return v;
        }
      },
    },
    {
      field: 'description',
      header: 'Description',
      visible: false,
      format: (v) => {
        if (v) {
          return v;
        }
      },
    },
  ];
  public groupeSecuriteRightsPoints = null;
  public listOfEchevinats = [];
  public listOfEchevinatsRights = [];
  public listOfMatieres = [];
  public listOfStatuts = [];
  public listOfTypePoint = [];
  public listOfCodesActives = [];
  @ViewChild('superTableFilesDuplication') public superTableFilesDuplication: SuperTableComponent;
  public blobViewer: any;
  public modalPdfViewer: boolean = false;
  public currentPage: number = 1;
  public openModelesPointsModal = false;
  public useModelPointTooltipService = '';
  public itemsModelesPoints = [];
  public allModelesPoints = [];
  public filteredModelesPoints = [];
  public allServices = [];
  public allClasseurs = [];
  public allChemises = [];
  public selectedRowModelePoint = null;
  public appliedModelePoint = null;
  //#endregion
  public isLocked: boolean = false;
  public showHidde: boolean = true;
  pointsRights;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private dataS: DataService,
    private api: ApiService,
    private toastr: ToastrService,
    public apiAdmin: iAdminService,
    private activatedRoute: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public confirmationService: ConfirmationService,
    private iContactService: IContactService,
    private oAuthService: OAuthService,
    private titleService: Title,
  ) {
    this.token = this.oAuthService.getAccessToken();
  }

  ngOnInit(): void {
    this.spinner.show();
    //#region Init
    this.getFusionsField();
    this.activatedRoute.params.subscribe((res: any) => {
      this.buttons = [];
      //* Setting the button without needing any right
      this.buttons.push({
        text: 'btn.cancel',
        styleClass: 'blue',
        id: 'idUpdateThisPoint',
        icon: 'fas fa-ban',
        iconPosition: 'left',
        click: () => {
          if (this.update) {
            this.realeseWriteLock();
          } else {
            window.history.back();
          }
        },
      });
      if (res && res.id) {
        //* If we come for an update
        delete this.infosForm.value.modelePointId;
        this.pointId = res.id;
        this.update = true;
        this.titleService.setTitle('Modification de point');
        this.titleComponent = 'btn.updatePoint';
        this.buttons.push({
          text: 'btn.saveAndLeave',
          styleClass: 'green',
          id: 'idUpdateThisPoint',
          icon: 'fas fa-arrow-circle-right ml-2',
          iconPosition: 'right',
          click: () => {
            this.updatePoint();
            window.history.back();
          },
        });
        this.itemsTabs[1].disabled = false;
        this.itemsTabs[2].disabled = false;
      } else {
        //* Coming for a creation
        this.pointId = null;
        this.update = false;
        this.titleService.setTitle('Création de point');
        this.titleComponent = 'btn.createPoint';
        this.buttons.push({
          text: 'btn.saveAndNextStep',
          styleClass: 'green',
          id: 'idCreateThisPoint',
          icon: 'fas fa-arrow-circle-right',
          iconPosition: 'right',
          click: () => {
            this.validateAndSave();
          },
        });
        this.buttons.splice(0, 0, {
          text: 'btn.useModelPoint',
          tooltip: 'useModelPointTooltipService',
          styleClass: 'blue',
          id: 'idUsePointModel',
          disabled: true,
          click: () => {
            this.filterModelesPoints();
          },
        });
      }
      async.waterfall(
        [
          (callback) => {
            this.api.getGroupeSecuriteRightsPoints(this.pointId ? this.pointId : null).subscribe((res: any) => {
              this.groupeSecuriteRightsPoints = res;
              callback();
            });
          },
          (callback) => {
            this.apiAdmin.getTenant().subscribe(
              (res: TenantInfo) => {
                res.applications?.map((x) => {
                  if (x.name === 'iCourrier') {
                    this.displayCourrierId = true;
                  }
                });
                callback();
              },
              (err) => {
                this.toastr.error('Une erreur est survenue dans la récupération du tenant!');
                callback();
              }
            );
          },
          (callback) => {
            this.api.getConfiguration().subscribe((res: any) => {
              this.globalConfiguration = res;
              callback();
            });
          },
          (callback) => {
            //* Getting all the infos about the connected user
            this.apiAdmin.getUserInfo().subscribe((res: any) => {
              this.userInfos = res;
              callback();
            });
          },
          (callback) => {
            //* Some translations
            this.annexStringTranslated = this.translate.instant('annex');
            this.mainFileStringTranslated = this.translate.instant('mainFile');

            this.fileUploadChooseLabel = this.translate.instant('btn.choose');
            this.fileUploadUploadLabel = this.translate.instant('btn.upload');
            this.fileUploadCancelLabel = this.translate.instant('btn.cancel');
            this.useModelPointTooltipService = this.translate.instant('useModelPointTooltipService');

            this.dataS.getGlobalElements.subscribe((res: any) => {
              this.globalElements = new Global_Class(res);
              if (this.globalElements.isLoaded()) {
                callback();
              }
            });
          },
          (callback) => {
            this.checkUserRightsToSeeGlobalElementsBasedOnGroupeSecurity().then(() => {
              callback();
            });
          },
          (callback) => {
            //* Gettings the users from admin to display in Agents traitants
            this.iContactService.getUserWithDataAdmin().subscribe((res: any) => {
              this.listeAgentsTraitantsAdmin = res.filter((x) => x.active);
              callback();
            });
          },
          (callback) => {
            //* Gettings the contacts from delibe to display in agents traitanst
            this.iContactService.getLowContacts().subscribe((res: any) => {
              this.listeAgentsTraitants = res.filter((x) => !x.nePlusUtiliser);
              callback();
            });
          },
          (callback) => {
            if (this.pointId) {
              this.api.getPointByID(this.pointId).subscribe((point: any) => {
                this.actualPoint = new Point(point, this.globalElements);
                this.infosForm.patchValue(this.actualPoint);
                //* Setting the boolean to display the right dropdown of agents traitants (contacts || users)
                this.actualPoint.agentTraitantId
                  ? (this.handlerType = 'contacts')
                  : this.actualPoint.agentTraitantIdiAdmin
                    ? (this.handlerType = 'users')
                    : (this.handlerType = 'contacts');

                //* If there's a linked seance in the point to update => don't allow any change of its type and itself
                this.actualPoint.seanceLink?.inclusDansSeance
                  ? (this.avoidChangeSeance = true)
                  : (this.avoidChangeSeance = false);

                //* check if existing matter, echevinats, pointType && statut exist in groupeSecuriteRightsPoints. if not, add them for them to be displayed in dropdown.
                //Vu avec KHA le 29/08/2023 : on ajoute dans le Dropdown même si l'utilisateur n'a pas le droit. Le back va faire une vérif si l'id reste le même, alors ils empêchent pas de save.
                //Sinon, un user ne pourrait pas save à cause de ça, même s'il voulait ne changer que l'objet :(
                if (this.infosForm.value.matiereId) {
                  this.groupeSecuriteRightsPoints.changerMatiereAllowedIds.push(this.infosForm.value.matiereId);
                  this.changeMatter({ value: this.infosForm.value.matiereId });
                }
                if (this.infosForm.value.echevinatId) {
                  //1st thing to do is check is there's a matter. if not, just don't display at all the echevinat, no need. One SHOULD be linked to a matter
                  if (this.infosForm.value.matiereId) {
                    //2nd thing to do : check is echevinat is linked to the matter.
                    const currentMatter = this.globalElements.matieres_LV.find(
                      (x) => x.value === this.infosForm.value.matiereId
                    );
                    const isEchevinatInMatter = currentMatter.item.echevinats.indexOf(this.infosForm.value.echevinatId);
                    if (isEchevinatInMatter > -1) {
                      this.groupeSecuriteRightsPoints.changerEchevinatAllowedIds.push(this.infosForm.value.echevinatId);
                    } else {
                      //if the echevinat has nothing to do with the matter : remove it.
                      this.infosForm.patchValue({ echevinatId: null });
                    }
                  } else {
                    this.infosForm.patchValue({ echevinatId: null });
                  }
                }
                if (this.infosForm.value.typeId) {
                  this.groupeSecuriteRightsPoints.changerTypePointAllowedIds.push(this.infosForm.value.typeId);
                }
                if (this.infosForm.value.statutId) {
                  this.groupeSecuriteRightsPoints.changerStatutAllowedIds.push(this.infosForm.value.statutId);
                }
                this.checkIfThePointIsLocked();
                // this.lockPoint();
                this.checkUserRightsToSeeGlobalElementsBasedOnGroupeSecurity().then(() => {
                  callback();
                });
              });
            } else {
              this.infosForm.reset();
              //! vérifier qu'on ait accès au statut par défaut. Si pas on laisse vide (vu avec KHA)
              const valideForSeanceStatus = this.globalElements?.statuts_points_LV.find(
                (x) => x.item?.statutAutiliserLorsCreation === true
              );
              const isUserHasAccessToStatut = this.listOfStatuts
                .map((x) => x.value)
                .indexOf(valideForSeanceStatus.value);

              this.infosForm.patchValue({
                statutId: isUserHasAccessToStatut > -1 ? valideForSeanceStatus?.value : null,
                agentTraitantIdiAdmin: this.userInfos.contactId,
              });
              this.avoidChangeSeance = false;
              this.handlerType = 'users';
              callback();
            }
          },
          (callback) => {
            //si on est en création => on va déjà charger tous les modèles de points. Ceux-ci seront ensuite filtrés frontend en fct du service && || type de séance
            //on va en profiter pour charger les classeurs et chemises
            if (!this.pointId) {
              async.parallel(
                [
                  (cb) => {
                    this.getModelesPoints().then(() => {
                      cb();
                    });
                  },
                  (cb) => {
                    this.apiAdmin.getGlobalClasseurs().subscribe((data: any) => {
                      this.allClasseurs = data.map((x) => {
                        return { label: x.description, value: x.id };
                      });
                      cb();
                    });
                  },
                  (cb) => {
                    this.apiAdmin.getGlobalChemises().subscribe((chemises: any) => {
                      this.allChemises = chemises.map((x) => {
                        return { label: x.description, value: x.id };
                      });
                      cb();
                    });
                  },
                ],
                () => {
                  callback();
                }
              );
            } else {
              callback();
            }
          },
          (callback) => {
            this.dataS.getCurrentMeeting.subscribe((meeting: any) => {
              if (meeting !== null) {
                this.api.getRightsSeance(meeting.id).subscribe((res: any) => {
                  this.currentRightsSeance = res;
                  callback();
                });
              } else {
                this.currentRightsSeance = null;
                callback();
              }
            });
          },
          (callback) => {
            //! Vu avec KHA : si on a pas accès à l'un ou l'autre statut ici, on laisse vide par défaut.
            //* Getting the current meeting if it exists => creation from a meeting
            this.dataS.getCurrentMeeting.subscribe((meeting: any) => {
              if (meeting !== null) {
                this.currentMeetingToPatch = meeting;
                let valideForSeanceStatus;
                if (this.currentRightsSeance.isGestionnaire) {
                  valideForSeanceStatus = this.globalElements.statuts_points_LV.find(
                    (x) => x.item.validePourAjoutSeance === true
                  );
                } else {
                  valideForSeanceStatus = this.globalElements.statuts_points_LV.find(
                    (x) => x.item.statutAutiliserLorsCreation === true
                  );
                }
                //une fois le statut récupéré, on va vérifier si l'utilisateur y a accès.
                const isUserHasAccessToStatut = this.listOfStatuts
                  .map((x) => x.value)
                  .indexOf(valideForSeanceStatus.value);
                this.infosForm.patchValue({
                  typeSeanceId: this.currentMeetingToPatch?.typeSeanceId,
                  seanceId: this.currentMeetingToPatch?.id,
                  statutId: isUserHasAccessToStatut > -1 ? valideForSeanceStatus?.value : null,
                });
                callback();
              } else {
                this.currentMeetingToPatch = null;
                callback();
              }
            });
          },
          (callback) => {
            //* Getting the point to be duplicated if we come from duplication
            this.dataS.getDuplicatedPoint.subscribe((pointToDuplicate: any) => {
              if (pointToDuplicate !== null) {
                this.duplication = true;
                this.currentPointToDuplicate = pointToDuplicate;
                this.currentPointToDuplicate.statutId = 1;
                this.currentPointToDuplicate.typeSeanceId = null;
                this.currentPointToDuplicate.seanceId = null;
                this.currentPointToDuplicate.typeId = null;
                this.currentPointToDuplicate.typeDecisionId = null;
                //* Delete of the 2 vars of "Agent traitant" for them not to interfere with the callback made before which sets the "agent traitant" as the connected user
                delete this.currentPointToDuplicate.agentTraitantId;
                delete this.currentPointToDuplicate.agentTraitantIdiAdmin;
                //* Setting the button to save the duplication
                this.buttons[1] = {
                  text: 'btn.save',
                  styleClass: 'green',
                  id: 'idSaveButtonDuplicatePoint',
                  icon: 'fas fa-check',
                  iconPosition: 'right',
                  click: () => {
                    this.duplicate();
                  },
                };
                this.buttons = this.buttons.filter((item) => item.id !== 'idCreateThisPoint');
                //* setting the status => Needs to be the one for creation
                //! NOT the one of the point
                // Just be careful to have the rights of it :) if not, emptyyyyy
                const redactionStatus = this.globalElements.statuts_points_LV.find(
                  (x) => x.item.statutAutiliserLorsCreation === true
                );
                const isUserHasAccessToStatut = this.listOfStatuts.map((x) => x.value).indexOf(redactionStatus.value);
                isUserHasAccessToStatut > -1
                  ? this.infosForm.patchValue({ statutId: redactionStatus?.value })
                  : this.infosForm.patchValue({ statutId: null });
                //* Setting the infos for the form with what the user chose
                this.dataS.getDuplicatedPointManagement.subscribe((pointToDuplicateManagement) => {
                  this.pointToDuplicateManagement = pointToDuplicateManagement;
                  async.waterfall(
                    [
                      (call) => {
                        //* Patching the general infos JIRA #423 => on duplique obligatoirement les métadonnées
                        //! Attention aux droits : si on a pas le droit sur une donnée : on la met vide
                        async.waterfall(
                          [
                            //* Checking the right to have the linked type seance (+ seance ofc)
                            (cb) => {
                              this.api.getRightsPointsSeances().subscribe((res: any) => {
                                let flag = false;
                                res.typeSeanceRights.forEach((x) => {
                                  if (x.typeSeanceId === this.currentPointToDuplicate.typeSeanceId && x.addPoint) {
                                    flag = true;
                                  }
                                  //* At the same time, we're going to restrain the "types seances" displayed to the user by filtering those in which we can't add a point
                                  if (x.addPoint) {
                                    this.globalElements.types_seances_LV.map((x) => x.value).indexOf(x.typeSeanceId) >
                                    -1
                                      ? this.types_seances_LV.push(
                                          this.globalElements.types_seances_LV[
                                            this.globalElements.types_seances_LV
                                              .map((x) => x.value)
                                              .indexOf(x.typeSeanceId)
                                          ]
                                        )
                                      : null;
                                  }
                                });
                                //* If there's no right to add point in this typeseance then we should remove it + the seance itself
                                if (flag === false) {
                                  delete this.currentPointToDuplicate.typeSeanceId;
                                  delete this.currentPointToDuplicate.seanceId;
                                }
                                cb();
                              });
                            },
                            (cb) => {
                              if (this.currentPointToDuplicate.matiereId) {
                                const indexMatter = this.listOfMatieres
                                  .map((x) => x.value)
                                  .indexOf(this.currentPointToDuplicate.matiereId);
                                if (indexMatter === -1) {
                                  //Si elle n'existe pas, on supprime
                                  delete this.currentPointToDuplicate.matiereId;
                                } else {
                                  this.changeMatter({ value: this.currentPointToDuplicate.matiereId });
                                }
                              }
                              if (this.currentPointToDuplicate.echevinatId) {
                                //1st : check is a matter exists
                                if (this.currentPointToDuplicate.matiereId) {
                                  //2nd : check is echevinat exists in matter. if not, delete
                                  const currentMatter = this.globalElements.matieres_LV.find(
                                    (x) => x.value === this.currentPointToDuplicate.matiereId
                                  );
                                  const isEchevinatInMatter = currentMatter.item.echevinats.indexOf(
                                    this.currentPointToDuplicate.echevinatId
                                  );
                                  if (isEchevinatInMatter === -1) {
                                    delete this.currentPointToDuplicate.echevinatId;
                                  }
                                } else {
                                  delete this.currentPointToDuplicate.echevinatId;
                                }
                              }
                              if (this.currentPointToDuplicate.typeId) {
                                const indexTypePoint = this.listOfTypePoint
                                  .map((x) => x.value)
                                  .indexOf(this.currentPointToDuplicate.typeId);
                                if (indexTypePoint === -1) {
                                  //Si elle n'existe pas, on supprime
                                  delete this.currentPointToDuplicate.typeId;
                                }
                              }
                              if (this.currentPointToDuplicate.statutId) {
                                const indexStatut = this.listOfStatuts
                                  .map((x) => x.value)
                                  .indexOf(this.currentPointToDuplicate.statutId);
                                if (indexStatut === -1) {
                                  delete this.currentPointToDuplicate.statutId;
                                }
                              }
                              cb();
                            },
                          ],
                          () => {
                            //* Once here, all that needed to be deleted is deleted so we can patch into the infoForm
                            this.infosForm.patchValue(this.currentPointToDuplicate);
                            call();
                          }
                        );
                      },
                      (call) => {
                        //* patching the files and RTFs
                        if (pointToDuplicateManagement?.files) {
                          this.infosForm.patchValue({ FichiersIds: pointToDuplicateManagement.listOfFiles });
                        }
                        call();
                      },
                    ],
                    () => {
                      callback();
                    }
                  );
                });
              } else {
                //* We will only restrain types seances
                this.api.getRightsPointsSeances().subscribe((res: any) => {
                  res.typeSeanceRights.forEach((x) => {
                    if (x.addPoint) {
                      this.globalElements.types_seances_LV.map((x) => x.value).indexOf(x.typeSeanceId) > -1
                        ? this.types_seances_LV.push(
                            this.globalElements.types_seances_LV[
                              this.globalElements.types_seances_LV.map((x) => x.value).indexOf(x.typeSeanceId)
                            ]
                          )
                        : null;
                    }
                  });
                  this.currentPointToDuplicate = null;
                  this.duplication = false;
                  callback();
                });
              }
            });
          },
          (callback) => {
            this.getTrads().then(() => {
              callback(null);
            });
          },
          (callback) => {
            this.listOfCodesActives = this.globalElements.classement_code_LV.filter((x) => {
              //ajoute aussi si le classement est celui du point comme cela s'il est inactif on l'ajoute quand même
              if (
                x.item.actif ||
                (this.infosForm.value.classementId && this.infosForm.value.classementId === x.item.id)
              ) {
                return x;
              }
            });
            //* Management of the Classement
            //! be careful can easily break
            if (this.infosForm.value.classementId) {
              this.apiAdmin
                .getGlobalClasseurs({ classementId: this.infosForm.value.classementId })
                .subscribe((data: any) => {
                  this.classeurs = data.map((x) => {
                    return {
                      label: x.description === '' ? this.translate.instant('noDescription') : x.description,
                      value: x.id,
                    };
                  });
                  if (this.actualPoint?.dossierClassementId || this.currentPointToDuplicate?.dossierClassementId) {
                    const dossierClassementId = this.actualPoint?.dossierClassementId
                      ? this.actualPoint.dossierClassementId
                      : this.currentPointToDuplicate.dossierClassementId;
                    this.apiAdmin.getGlobalClassementById(dossierClassementId).subscribe((classement: any) => {
                      this.infosForm.patchValue({
                        folder: classement.classeur?.id,
                        sleeve: classement.chemise ? classement.chemise.id : null,
                      });
                      this.apiAdmin
                        .getGlobalChemises({ classeurId: this.infosForm.value.folder })
                        .subscribe((data: any) => {
                          this.chemises = data.map((x) => {
                            return {
                              label: x.description === '' ? this.translate.instant('noDescription') : x.description,
                              value: x.id,
                            };
                          });
                          callback();
                        });
                    });
                  } else {
                    callback();
                  }
                });
            } else {
              callback();
            }
          },
          (callback) => {
            //* Verifying of there's a type seance or an already linked meeting
            if (this.infosForm.value.typeSeanceId) {
              const currentTypeSeanceRight = this.globalElements.rightsPointsSeances.typeSeanceRights.find(
                (x) => x.typeSeanceId === this.infosForm.value.typeSeanceId
              );
              const actualTypeSeanceLabel = this.types_seances_LV.find(
                (x) => x.value === this.infosForm.value.typeSeanceId
              )?.label;
              async.parallel(
                [
                  (cb) => {
                    const data = {
                      TypeSeanceIds: [this.infosForm.value.typeSeanceId],
                      DateDebut: moment().format('YYYY-MM-DD'),
                      Cloture: false,
                      pageSize: 500,
                    };
                    if (currentTypeSeanceRight.isGestionnaire) {
                      delete data.DateDebut;
                      //le gestionnaire à accès à tout => les séances de n'imp quand + les ouvertes + les préparées
                    }
                    //* getting all the linked meetings to the type seance to ony display the seances available with this typeSeance
                    this.api.getMeetingsSearch(data).subscribe((data: any) => {
                      moment.locale(localStorage.getItem('lang')); //* Setting the moment lang of component
                      this.seances = data.items.map((x) => {
                        const formattedDate = moment(x.date, 'YYYYMMDD').format(
                          this.translate.instant('format.formatDateSeance')
                        );
                        const dateForOrdre = moment(x.date, 'YYYYMMDD').unix();
                        return {
                          label: x.description
                            ? formattedDate + ' - ' + x.description
                            : actualTypeSeanceLabel + ' - ' + formattedDate,
                          value: x.id,
                          date: dateForOrdre,
                          verrouille: x.verrouille,
                        };
                      });
                      cb();
                    });
                  },
                  (cb) => {
                    if (this.infosForm.value.typeId === null) {
                      this.api.getGlobalTypesSeances(this.infosForm.value.typeSeanceId).subscribe((data: any) => {
                        if (data[0].defautObjetTypeId) {
                          const index = this.globalElements.types_points_LV
                            .map((x) => x.value)
                            .indexOf(data[0].defautObjetTypeId);
                          if (index > -1) {
                            this.infosForm.patchValue({ typeId: data[0].defautObjetTypeId });
                            if (data[0].objetTypeNonModifiable) {
                              this.avoidChangeObjetType = true;
                              this.avoidChangeObjetTypeLabel = this.globalElements.types_points_LV[index].label;
                            } else {
                              this.avoidChangeObjetType = false;
                            }
                          }
                          cb();
                        } else {
                          this.avoidChangeObjetType = false;
                          this.infosForm.patchValue({ typeId: null });
                          cb();
                        }
                      });
                    } else {
                      cb();
                    }
                  },
                ],
                () => {
                  callback();
                }
              );
            } else {
              callback();
            }
          },
          (callback) => {
            //* getting the services to display them by parent / child
            this.apiAdmin.getGlobalServices().subscribe((response: Service[]) => {
              this.allServices = response;
              response.map((item: Service) => {
                if (this.userInfos.services.indexOf(item.id) > -1) {
                  if (item.description) {
                    let parent = [];
                    parent = response.filter((x) => x.id === item.parentId);
                    item.label =
                      parent.length > 0 ? parent[0].description + ' / ' + item.description : item.description;
                    this.departments.push({ label: item.label, value: item.id });
                  }
                }
              });
              this.departments = _.sortBy(this.departments, 'label');
              const hasMatchingDepartment = this.departments.some(dep => dep.value === this.infosForm.value.serviceId);
              if (hasMatchingDepartment) {
                this.infosForm.patchValue({ serviceId: this.infosForm.value.serviceId });
              } else {
                this.infosForm.patchValue({ serviceId: null });
              }
              callback();
            });
          },
          (callback) => {
            //* Getting mandatory parameters for form
            this.api.getGlobalPointParameters().subscribe((res: any) => {
              this.mandatoryPointParameters = res;
              if (res.echevinatObligatoire && this.infosForm.get('echevinatId')) {
                this.infosForm.get('echevinatId').setValidators([Validators.required]);
                this.infosForm.get('echevinatId').updateValueAndValidity();
              }
              if (res.classementObligatoire && this.infosForm.get('classementId')) {
                this.infosForm.get('classementId').setValidators([Validators.required]);
                this.infosForm.get('classementId').updateValueAndValidity();
              }
              if (res.matiereObligatoire && this.infosForm.get('matiereId')) {
                this.infosForm.get('matiereId').setValidators([Validators.required]);
                this.infosForm.get('matiereId').updateValueAndValidity();
              }
              if (res.origineObligatoire && this.infosForm.get('origine')) {
                this.infosForm.get('origine').setValidators([Validators.required]);
                this.infosForm.get('origine').updateValueAndValidity();
              }
              if (res.typeDecisionObligatoire && this.infosForm.get('typeDecisionId')) {
                this.infosForm.get('typeDecisionId').setValidators([Validators.required]);
                this.infosForm.get('typeDecisionId').updateValueAndValidity();
              }
              if (res.urgenceObligatoire && this.infosForm.get('urgenceNiveauId')) {
                this.infosForm.get('urgenceNiveauId').setValidators([Validators.required]);
                this.infosForm.get('urgenceNiveauId').updateValueAndValidity();
              }
              callback();
            });
          },
          (callback) => {
            //* Getting the rights for configuration => mandataire and Citoyen depend on them
            this.api.getConfiguration().subscribe((config) => {
              this.config = config;
              if (config.delibConfig.isiDelibeMandatairesEnabled) {
                this.actions.push(
                  {
                    label: this.translate.instant('makeVisibleForRepresentative'),
                    tooltip: this.translate.instant('makeVisibleForRepresentative'),
                    command: () => {
                      this.spinner.show();
                      const data = [];
                      this.selectedFiles.map((file) => {
                        const temp = this.itemFiles.find((x) => x.id === file.id);
                        temp.fileId = file.id;
                        temp.visibleDelibeWeb = true;
                        data.push(temp);
                      });
                      this.api.patchPointsFile(this.pointId, data).subscribe((data) => {
                        this.selectedFiles.map((file) => {
                          const index = this.itemFiles.map((x) => x.id).indexOf(file.id);
                          this.itemFiles[index].visibleDelibeWeb = true;
                        });
                        this.toastr.success(this.translate.instant('success.modifiedFiles'));
                        this.spinner.hide();
                      });
                    },
                  },
                  {
                    label: this.translate.instant('makeInvisibleForRepresentative'),
                    tooltip: this.translate.instant('makeInvisibleForRepresentative'),
                    command: () => {
                      this.spinner.show();
                      const data = [];
                      this.selectedFiles.map((file) => {
                        const temp = this.itemFiles.find((x) => x.id === file.id);
                        temp.fileId = file.id;
                        temp.visibleDelibeWeb = false;
                        data.push(temp);
                      });
                      this.api.patchPointsFile(this.pointId, data).subscribe((data) => {
                        this.selectedFiles.map((file) => {
                          const index = this.itemFiles.map((x) => x.id).indexOf(file.id);
                          this.itemFiles[index].visibleDelibeWeb = false;
                        });
                        this.toastr.success(this.translate.instant('success.modifiedFiles'));
                        this.spinner.hide();
                      });
                    },
                  }
                );
              }
              if (config.delibConfig.isiDelibeCitoyensEnabled) {
                this.actions.push(
                  {
                    label: this.translate.instant('makeVisibleForCitizen'),
                    tooltip: this.translate.instant('makeVisibleForCitizen'),
                    command: () => {
                      this.spinner.show();
                      const data = [];
                      this.selectedFiles.map((file) => {
                        const temp = this.itemFiles.find((x) => x.id === file.id);
                        temp.fileId = file.id;
                        temp.visibleDelibeWebCitoyen = true;
                        data.push(temp);
                      });
                      this.api.patchPointsFile(this.pointId, data).subscribe((data) => {
                        this.selectedFiles.map((file) => {
                          const index = this.itemFiles.map((x) => x.id).indexOf(file.id);
                          this.itemFiles[index].visibleDelibeWebCitoyen = true;
                        });
                        this.toastr.success(this.translate.instant('success.modifiedFiles'));
                        this.spinner.hide();
                      });
                    },
                  },
                  {
                    label: this.translate.instant('makeInvisibleForCitizen'),
                    tooltip: this.translate.instant('makeInvisibleForCitizen'),
                    command: () => {
                      this.spinner.show();
                      const data = [];
                      this.selectedFiles.map((file) => {
                        const temp = this.itemFiles.find((x) => x.id === file.id);
                        temp.fileId = file.id;
                        temp.visibleDelibeWebCitoyen = false;
                        data.push(temp);
                      });
                      this.api.patchPointsFile(this.pointId, data).subscribe((data) => {
                        this.selectedFiles.map((file) => {
                          const index = this.itemFiles.map((x) => x.id).indexOf(file.id);
                          this.itemFiles[index].visibleDelibeWebCitoyen = false;
                        });
                        this.toastr.success(this.translate.instant('success.modifiedFiles'));
                        this.spinner.hide();
                      });
                    },
                  }
                );
              }
              callback();
            });
          },
          (callback) => {
            if (this.pointId) {
              this.getPointFiles().then(() => {
                callback();
              });
            } else {
              callback();
            }
          },
          (callback) => {
            if (!this.groupeSecuriteRightsPoints.changerEchevinat && this.actualPoint) {
              const index = this.globalElements.echevinats_LV.map((x) => x.value).indexOf(this.actualPoint.echevinatId);
              this.avoidChangeEchevinat = this.globalElements?.echevinats_LV[index]?.label;
            }
            if (!this.groupeSecuriteRightsPoints.changerMatiere && this.actualPoint) {
              const index = this.globalElements.matieres_LV.map((x) => x.value).indexOf(this.actualPoint.matiereId);
              this.avoidChangeMatiere = this.globalElements?.matieres_LV[index]?.label;
            }
            if (!this.groupeSecuriteRightsPoints.changerStatut && this.actualPoint) {
              const index = this.globalElements.statuts_points_LV
                .map((x) => x.value)
                .indexOf(this.actualPoint.statutId);
              this.avoidChangeStatut = this.globalElements?.statuts_points_LV[index]?.label;
            }
            callback();
          },
        ],
        () => {
          this.loaded = true;
          this.spinner.hide();
        }
      );
    });
    //#endregion
  }

  /**
   * A method to lock a point. It logs the point ID and update status, then locks the point using the API.
   * If the update is successful, it handles the response. If there's an error, it checks for specific error statuses and messages, then performs the corresponding actions.
   */
  lockPoint() {
    if (this.update) {
      this.api.lockPoint(this.pointId).subscribe(
        (res: Point['writeLock']) => {
          const currentUser: boolean = this.userInfos.id === res.writeLockUserId;
          this.writeLock.currentUser = currentUser;
          this.isLocked = res.locked;
          this.scheduleLockExpirationProcess(res);
          this.toastr.info(this.translate.instant('info.pointLocked'));
        },
        (error) => {
          if (error.status === 403 && error.error.message === 'PointWriteLockedByOtherUser') {
            this.spinner.hide();
            this.isLocked = true;
          } else {
            // pour le moment si il y a une ereur autre que celle du dessus, il est aussi ejecté de la page.
            this.spinner.hide();
            this.isLocked = true;
          }
        }
      );
    }
  }

  /**
   * Looks at the expiration date and calls handelLockExpiration 1 minutes before the end of the expiration
   */
  private scheduleLockExpirationProcess(infos) {
    if (infos && infos.writeLockExpirationDateTime) {
      const expirationDateTime = infos.writeLockExpirationDateTime;
      const expirationMoment = moment.utc(expirationDateTime); // Calculate expiration based on UTC

      const oneMinuteBeforeExpiration = expirationMoment.subtract(1, 'minutes').toDate();
      const currentTime = new Date();
      const timeToWait = oneMinuteBeforeExpiration.getTime() - currentTime.getTime();

      if (timeToWait > 0) {
        setTimeout(() => {
          this.handleLockExpiration();
        }, timeToWait);
      }
    }
  }

  /**
   * A method that handles the lock expiration process.
   */
  private handleLockExpiration() {
    this.lockExpiration = true;
    this.timer();
  }

  /**
   * A function that starts a timer and updates the timer display every second.
   */
  timer() {
    let seconds: number = 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.timerInterval = textSec;

      if (seconds == 0) {
        this.goBack();
        clearInterval(timerInterval);
      }
    }, 1000);
  }

  /**
   * extendedTheValidityOfThePoint function extends the validity of the point by locking it and handling the response and error.
   */
  extendedTheValidityOfThePoint() {
    this.api.lockPoint(this.pointId).subscribe(
      (res: Point['writeLock']) => {
        if (res) {
          this.toastr.info(this.translate.instant('info.pointLocked'));
          this.lockExpiration = false;
        }
      },
      (error) => {
        this.toastr.error(this.translate.instant('errorOccurred'));
      }
    );
  }

  /**
   * Method to navigate back in the browser history.
   */
  goBack() {
    window.history.back();
  }

  getPointFiles() {
    return new Promise((resolve, reject) => {
      this.api.getPointFiles(this.pointId).subscribe((pointFiles: any) => {
        this.itemFiles = pointFiles;
        this.selectedFiles = this.itemFiles;
        resolve(true);
      });
      this.spinner.hide();
    });
  }

  realeseWriteLock(goBack: boolean = true) {
    this.api.ReleaseWriteLock(this.pointId).subscribe(
      (res: Point['writeLock']) => {
        if (res) {
          this.toastr.info(this.translate.instant('info.pointUnlocked'));
          this.lockExpiration = false;
          if (goBack) this.goBack();
        }
      },
      (error) => {
        this.toastr.error(this.translate.instant('errorOccurred'));
      }
    );
  }

  getFusionsField() {
    this.api.getGlobalFusionsFields().subscribe((res: any) => {
      this.fusions = res;
    });
  }

  //* On destroy resets the 2 data on dataservice
  ngOnDestroy() {
    this.dataS.SetCurrentMeeting(null);
    this.dataS.SetDuplicatedPoint(null);
  }

  /**
   * Destroy the lock of the point when the user leaves the page
   */
  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: Event) {
    if (this.update) {
      this.realeseWriteLock();
    } else {
      window.history.back();
    }
  }

  //* Triggers when user clicks on a row of files on the ... blue button
  public selectRowData(data) {
    this.selectedRow = data;
  }
  onUpload(event, uploader) {
    async.waterfall([
      (cb) => {
        this.confirmDialogBool = true;
        cb(null);
      },
      (cb) => {
        this.itemsUploaded = event.files;
        this.uploader = uploader;
      },
    ]);
  }
  addFileAnnexe(isAnnexeFile) {
    async.waterfall([
      (callback) => {
        this.spinner.show();
        this.count = 0;
        const totalItems = this.itemsUploaded.length;
        for (const item of this.itemsUploaded) {
          item.description = item.name;
          item.extension = item.name.split('.').pop();
          item.date = moment(item.lastModifiedDate, 'DD/MM/YYYY');
          item.pointId = this.pointId;
          const isPDF = item.extension.toLowerCase() === 'pdf' ? true : false;
          const document = {
            description: item.name,
            version: 1,
          };

          this.api.addPointFiles(this.pointId, document, item, item.extension, isPDF ? isAnnexeFile : false).subscribe(
            (response: any) => {
              // this.count= this.count+=1
              this.count++;
              if (this.count === totalItems) {
                callback(null);
              }
            },
            (error) => {
              console.error(error);
              this.spinner.hide();
              this.toastr.error("Une erreur est survenue lors de l'ajout de fichier(s).");
            }
          );
        }
      },
      (callback) => {
        this.getPointFiles();
        this.itemsUploaded = [];
        this.toastr.success('Merci', 'Les fichiers ont été enregistrés!');
        this.uploader.clear();
        this.spinner.hide();
        this.confirmDialogBool = false;
        callback(null);
      },
    ]);
  }

  //* triggers when the users submit its modifications for a file
  public submitModificationFile() {
    if (this.formFilesModification.valid) {
      let annexDoesntAlreadyExistInFiles = false; //le flag va permettre de déterminer si l'annexe possède un numéro identique a une autre existante
      let indexOfAnnexIdentique;
      this.api.getPointFiles(this.pointId).subscribe((allPointFiles: any) => {
        if (this.formFilesModification.value.annexeNumero) {
          const allAnnexNumbers = allPointFiles
            .filter((x) => x.id !== this.formFilesModification.value.id)
            .map((x) => {
              return x.annexeNumero;
            });
          indexOfAnnexIdentique = allAnnexNumbers.indexOf(this.formFilesModification.value.annexeNumero);
          if (indexOfAnnexIdentique > -1) {
            annexDoesntAlreadyExistInFiles = false;
          } else {
            //si c'est === -1 alors on a pas d'intersection et donc pas d'annexe avec le même numéro
            annexDoesntAlreadyExistInFiles = true;
          }
        } else {
          annexDoesntAlreadyExistInFiles = true; //Pas de n° d'annexe donc c'est ok
        }

        if (annexDoesntAlreadyExistInFiles) {
          const data = this.formFilesModification.value;

          if (!data.annexType) {
            data.annexeNumero = null;
          }

          if (!this.config.delibConfig.isiDelibeMandatairesEnabled) {
            delete data.visibleDelibeWeb;
          }
          if (!this.config.delibConfig.isiDelibeCitoyensEnabled) {
            delete data.visibleDelibeWebCitoyen;
          }

          this.api.patchPointFile(this.pointId, this.selectedRow.id, data).subscribe((data: any) => {
            this.api.getPointFiles(this.pointId).subscribe((allPointFiles2: any) => {
              const temp = this.itemFiles; //on clone l'array des items affichés dans une var temporaire
              const indexFileInAllFiles = allPointFiles2.map((x) => x.id).indexOf(this.selectedRow.id);
              const indexFileInFileUpload = temp.map((x) => x.id).indexOf(this.selectedRow.id);
              temp[indexFileInFileUpload] = allPointFiles2[indexFileInAllFiles];
              this.itemFiles = [];
              this.itemFiles = [...temp];
              this.selectedFiles = this.itemFiles;

              this.toastr.success(this.translate.instant('success.modifiedFile'));
              this.openModifyAFileInModal = false;
            });
          });
        } else {
          this.toastr.error(
            allPointFiles[indexOfAnnexIdentique].description + ' ' + this.translate.instant('error.sameAnnexNumber')
          );
        }
      });
    } else {
      this.toastr.error(this.translate.instant('error.pleaseCompleteMandatoryFields'));
    }
  }

  //* Trigger when the code is changed in general tab
  changeCode(folderId = null) {
    return new Promise((resolve, reject) => {
      if (this.infosForm.value.classementId) {
        this.apiAdmin.getGlobalClasseurs({ classementId: this.infosForm.value.classementId }).subscribe((data: any) => {
          this.classeurs = [];
          async.eachSeries(
            data,
            (x: any, next) => {
              this.classeurs.push({
                label: x.description === '' ? this.translate.instant('noDescription') : x.description,
                value: x.id,
              });
              next();
            },
            () => {
              //S'il y a un folder id, on va vérifier qu'il est bien dans la liste
              if (folderId) {
                if (this.classeurs.map((x) => x.value).indexOf(folderId) > -1) {
                  this.infosForm.patchValue({ folder: folderId, sleeve: null }); // Pas oublier de reset les données...
                }
              } else {
                this.infosForm.patchValue({ folder: null, sleeve: null }); // Pas oublier de reset les données...
              }
              resolve(true);
            }
          );
        });
      } else {
        resolve(true);
      }
    });
  }

  //* Triggers when the folder is modified in general Tab
  changeFolder(sleeveId = null) {
    return new Promise((resolve, reject) => {
      if (this.infosForm.value.folder) {
        this.apiAdmin.getGlobalChemises({ classeurId: this.infosForm.value.folder }).subscribe((data: any) => {
          this.chemises = [];
          async.eachSeries(
            data,
            (x: any, next) => {
              this.chemises.push({
                label: x.description === '' ? this.translate.instant('noDescription') : x.description,
                value: x.id,
              });
              next();
            },
            () => {
              //S'il y a une sleeveId, on va vérifier qu'elle est bien dans la liste
              if (sleeveId) {
                if (this.chemises.map((x) => x.value).indexOf(sleeveId) > -1) {
                  this.infosForm.patchValue({ sleeve: sleeveId });
                }
              } else {
                this.infosForm.patchValue({ sleeve: null }); // Pas oublier de reset les données...
              }
              resolve(true);
            }
          );
        });
      } else {
        resolve(true);
      }
    });
  }

  //* triggers when user click on the save button
  validateAndSave(leave: boolean = false) {
    if (this.activeTab === 0) {
      //informations et liens
      if (this.infosForm.valid) {
        //* Needs to patch the right data => if there's a sleeve take it if not take the folder
        this.infosForm.patchValue({
          dossierClassementId: this.infosForm.value.sleeve
            ? this.infosForm.value.sleeve
            : this.infosForm.value.folder
              ? this.infosForm.value.folder
              : null,
        });
        leave ?? this.infosForm.patchValue({ unlockPoint: true });
        if (this.pointId) {
          //* update part
          this.spinner.show();
          this.api.putUpdatePoint(this.pointId, this.infosForm.value).subscribe((res: any) => {
            this.toastr.success(this.translate.instant('success.updatedPoint'));
            if (leave) {
                this.spinner.hide();
                this.goBack();
            } else {
              this.spinner.hide();
              this.nextStep();
            }
          });
        } else {
          //on va vérifier si on a envoyé un modèle de point
          if (this.appliedModelePoint) {
            this.infosForm.value.modelePointId = this.appliedModelePoint.id;
          } else {
            delete this.infosForm.value.modelePointId;
          }
          //* creation part
          this.spinner.show();
          this.api.postCreatePoint(new Point(this.infosForm.value)).subscribe((response: any) => {
            this.toastr.success(this.translate.instant('success.createdPoint'));
            this.api.getRightsPoint(response.id).subscribe(
              (rights: any) => {
                if (!rights.edit) {
                  setTimeout(() => {
                    this.spinner.hide();
                    this.router.navigateByUrl('/private/points/detail/' + response.id);
                  }, 500);
                } else {
                  this.point = response;
                  this.pointId = response.id;
                  this.itemsTabs[1].disabled = false;
                  this.itemsTabs[2].disabled = false;
                  if (this.appliedModelePoint) {
                    this.reloadRTFPresence(true);
                  } else {
                    this.spinner.hide();
                    this.nextStep();
                  }
                }
              },
              (error) => {
                this.spinner.hide();
                if (error && error.errors.length > 0) {
                  error.errors.map((err) => {
                    this.toastr.error(err.message);
                  });
                }
              }
            );
          });
        }
      } else {
        this.invalidFormToastr();
      }
    } else if (this.activeTab === 1) {
      //contenu
      async.waterfall([
        (callback) => {
          this.saveAllRTFPromise().then((val) => {
            if (val) {
              callback();
            }
          });
        },
        (cb) => {
          if (this.pointId) {
            //* update part
            this.spinner.show();
            this.api.putUpdatePoint(this.pointId, this.infosForm.value).subscribe((res: any) => {
              this.toastr.success(this.translate.instant('success.updatedPoint'));
              cb();
            });
          }
          if (leave) {
            if (this.update) {
              this.realeseWriteLock();
            } else {
              window.history.back();
            }
          } else {
            this.nextStep();
          }
        },
      ]);
    }
  }

  duplicate() {
    //* Firstly check if you need to add the recipients && content
    const addRecipients: boolean = this.pointToDuplicateManagement?.recipients ?? false;
    const addContenu: boolean = this.pointToDuplicateManagement?.infos ?? false;

    //* Then call the request to save the duplication
    if (this.infosForm.valid) {
      this.api
        .postDuplicatePoint(this.currentPointToDuplicate.id, this.infosForm.value, addRecipients, addContenu)
        .subscribe((data) => {
          this.toastr.success(this.translate.instant('success.createdPoint'));
          this.router.navigateByUrl('/private/points/detail/' + data.id);
        });
    } else {
      this.invalidFormToastr();
    }
  }

  //* triggers when the user saves an editor (for example on the save button for Motivation)
  submitAContent(event, typeRTF) {
    this.spinner.show();
    //TODO faire pareil pour les versions dynamiques!
    const type = typeRTF;
    const content = event;
    const blob = new Blob([content], { type: 'application/rtf' });
    this.api.putOnePointRTFDatasStatic(this.pointId, blob, type).subscribe((res: any) => {
      this.toastr.success(this.translate.instant('message.success.dataSaved'));
      this.reloadRTFPresence();
      this.spinner.hide();
    });
  }

  //* Triggers when the user changes the seance type
  selectedMeetingPoint($event) {
    this.clearTypeSeance();
    this.seanceSelectedInfos = null;
    const currentTypeSeanceRight = this.globalElements.rightsPointsSeances.typeSeanceRights.find(
      (x) => x.typeSeanceId === $event.value
    );
    const actualTypeSeanceLabel = this.types_seances_LV.find(
      (x) => x.value === this.infosForm.value.typeSeanceId
    )?.label;
    async.parallel([
      (callback) => {
        const data = {
          TypeSeanceIds: [this.infosForm.value.typeSeanceId],
          DateDebut: moment().format('YYYY-MM-DD'),
          Cloture: false,
          pageSize: 500,
        };
        if (currentTypeSeanceRight && currentTypeSeanceRight.isGestionnaire) {
          delete data.DateDebut;
          //le gestionnaire à accès à tout => les séances de n'imp quand + les ouvertes + les préparées
        }
        this.api.getMeetingsSearch(data).subscribe((data: any) => {
          moment.locale(localStorage.getItem('lang')); //* Setting the moment lang of component
          this.seances = [];
          data.items.map((x) => {
            const formattedDate = moment(x.date, 'YYYYMMDD').format(this.translate.instant('format.formatDateSeance'));
            const dateForOrdre = moment(x.date, 'YYYYMMDD').unix();
            this.seances.push({
              label: x.description
                ? formattedDate + ' - ' + x.description
                : actualTypeSeanceLabel + ' - ' + formattedDate,
              value: x.id,
              date: dateForOrdre,
              verrouille: x.verrouille,
            });
          });
          callback();
        });
      },
      (callback) => {
        this.api.getGlobalTypesSeances(this.infosForm.value.typeSeanceId).subscribe((data: any) => {
          if (data[0].defautObjetTypeId) {
            const index = this.globalElements.types_points_LV.map((x) => x.value).indexOf(data[0].defautObjetTypeId);
            if (index > -1) {
              this.infosForm.patchValue({ typeId: data[0].defautObjetTypeId });
              if (data[0].objetTypeNonModifiable) {
                this.avoidChangeObjetType = true;
                this.avoidChangeObjetTypeLabel = this.globalElements.types_points_LV[index].label;
              } else {
                this.avoidChangeObjetType = false;
              }
            }
            callback();
          } else {
            this.avoidChangeObjetType = false;
            this.infosForm.patchValue({ typeId: null });
            callback();
          }
        });
      },
    ]);
  }

  displaySeanceSelected() {
    this.seanceSelectedInfos = this.seances.find((x) => x.value === this.infosForm.value.seanceId);
  }

  clearTypeSeance() {
    this.infosForm.patchValue({ seanceId: null });
    this.seanceSelectedInfos = null;
  }

  //* list of errors could be displayed to the user when they submit a form on the 1st tab
  invalidFormToastr() {
    if (!this.infosForm.controls['seanceId'].valid) {
      this.toastr.error(this.translate.instant('error.emptySeance'));
    }
    if (!this.infosForm.controls['statutId'].valid) {
      this.toastr.error(this.translate.instant('error.emptyStatus'));
    }
    if (!this.infosForm.controls['objetSynthese'].valid) {
      this.toastr.error(this.translate.instant('error.emptyTitle'));
    }
    if (!this.infosForm.controls['typeSeanceId'].valid) {
      this.toastr.error(this.translate.instant('error.emptySeanceType'));
    }
    if (!this.infosForm.controls['serviceId'].valid) {
      this.toastr.error(this.translate.instant('error.emptyDepartment'));
    }
    if (!this.infosForm.controls['echevinatId'].valid) {
      this.toastr.error(this.translate.instant('error.emptyAldermansAdminDepartment'));
    }
    if (!this.infosForm.controls['classementId'].valid) {
      this.toastr.error(this.translate.instant('error.emptyClassement'));
    }
    if (!this.infosForm.controls['matiereId'].valid) {
      this.toastr.error(this.translate.instant('error.emptyMatter'));
    }
    if (!this.infosForm.controls['origine'].valid) {
      this.toastr.error(this.translate.instant('error.emptyOrigin'));
    }
    if (!this.infosForm.controls['typeDecisionId'].valid) {
      this.toastr.error(this.translate.instant('error.emptyTypeDecision'));
    }
    if (!this.infosForm.controls['typeId'].valid) {
      this.toastr.error(this.translate.instant('error.emptyTypePoint'));
    }
    if (
      this.infosForm.controls['urgenceNiveauId'].errors?.required === true &&
      !this.infosForm.controls['urgenceNiveauId'].valid
    ) {
      this.toastr.error(this.translate.instant('error.emptyEmergency'));
    }
  }

  //* Simple update of point
  updatePoint() {
    if (this.infosForm.valid) {
      this.infosForm.patchValue({
        dossierClassementId: this.infosForm.value.sleeve
          ? this.infosForm.value.sleeve
          : this.infosForm.value.folder
            ? this.infosForm.value.folder
            : null,
        unlockPoint: true,
      });
      this.api.putUpdatePoint(this.pointId, this.infosForm.value).subscribe((res: any) => {
        this.toastr.success(this.translate.instant('success.updatedPoint'));
        setTimeout(() => {
          this.router.navigateByUrl('/private/points/detail/' + res.id);
        }, 500);
      });
    } else {
      this.invalidFormToastr();
    }
  }

  //* next step on tabs
  nextStep() {
    if (this.activeTab < 3) {
      this.activeTab++;
      const data: any = {};
      data.index = this.activeTab;
      this.changeTab(data);
      this.spinner.hide();
    }
  }

  //* when the user changes tabs/ Dynamic tab change
  changeTab(event) {
    // Si on est dans les fichiers et qu'on veut revenir sur les infos & liens,
    //on demande si il est sur pcq il va perdre les changements qu'il a fait dans les éditeurs
    if (this.activeTab === 1 && event.index === 0) {
      this.confirmationService.confirm({
        message: 'Etes-vous sûr de vouloir quitter cet écran ?  Vos données seront perdues.',
        key: 'create',
        accept: () => {
          this.showAllAccordion = false;
          const arrayRTF = ['Motivation', 'Decision', 'Observations', 'ComplementDeDeliberation', 'NotesDeSynthese'];
          arrayRTF.map((type, index) => {
            this.activeState[index] = false;
          });
          this.activeTab = 0;
        },
        reject: () => {
          setTimeout(() => {
            this.activeTab = 1;
          }, 1000);
        },
      });
    } else if (this.activeTab === 1 && event.index === 2) {
      this.confirmationService.confirm({
        message: 'Etes-vous sûr de vouloir quitter cet écran ?  Vos données seront perdues.',
        key: 'create',
        accept: () => {
          this.showAllAccordion = false;
          const arrayRTF = ['Motivation', 'Decision', 'Observations', 'ComplementDeDeliberation', 'NotesDeSynthese'];
          arrayRTF.map((type, index) => {
            this.activeState[index] = false;
          });
          this.activeTab = 2;
          this.hasAlreadyClickOnFilesTabs = true;
          this.buttons = [
            {
              text: this.translate.instant('btn.leave'),
              styleClass: 'blue',
              icon: 'fas fa-check-square',
              iconPosition: 'left',
              id: 'idLeave',
              click: () => {
                if (this.update) {
                  this.realeseWriteLock();
                } else {
                  window.history.back();
                }
              },
            },
          ];
        },
        reject: () => {
          setTimeout(() => {
            this.activeTab = 1;
          }, 1000);
        },
      });
    } else {
      this.api.getRightsPoint(this.pointId).subscribe(
        (rights: any) => {
          this.pointsRights = rights;
        }
      );
      this.activeTab = event.index;
      if ((event.index === 1 || event.index === 2) && !this.pointId) {
        this.activeTab = 0;
        this.toastr.error("Veuillez d'abord sauvegarder votre point avant d'ajouter ces données");
      } else {
        if (event.index === 1 || (this.pointId && event.index === 0)) {
          event.index === 1 ? this.reloadRTFPresence() : null;
          this.buttons = [
            {
              text: this.translate.instant('btn.saveAndLeave'),
              styleClass: 'blue',
              icon: 'fas fa-check-square',
              iconPosition: 'left',
              id: 'idSaveAndLeave',
              click: () => {
                this.validateAndSave(true);
              },
            },
            {
              text: this.translate.instant('btn.saveAndNextStep'),
              styleClass: 'green',
              id: 'idValidateAndNextStep',
              icon: 'fas fa-arrow-circle-right',
              iconPosition: 'left',
              click: () => {
                this.validateAndSave();
              },
            },
          ];
        }
        if (event.index === 2 && this.pointId) {
          this.hasAlreadyClickOnFilesTabs = true;
          this.buttons = [
            {
              text: this.translate.instant('btn.leave'),
              styleClass: 'blue',
              icon: 'fas fa-check-square',
              iconPosition: 'left',
              id: 'idLeave',
              click: () => {
                this.realeseWriteLock();
              },
            },
          ];
        }
      }
    }
  }

  public leave() {
    this.router.navigateByUrl('/private/points/detail/' + this.pointId);
  }

  //* Sets the tooltip to display on the annex button when user modifies a file
  public getTooltipContent() {
    const data = this.translate.instant('ifNotPDFThenAssociatedFile');
    return data;
  }

  //* Will set the required state of annexeNumero if the file's set as an annex
  public changeTypeOfFileInModification(event) {
    if (event.checked === true) {
      this.formFilesModification.controls['annexeNumero'].setValidators([Validators.required]);
      this.formFilesModification.controls['annexeNumero'].updateValueAndValidity();
    } else {
      this.formFilesModification.controls['annexeNumero'].clearValidators();
      this.formFilesModification.controls['annexeNumero'].updateValueAndValidity();
    }
  }

  //* Allows or don't allow user to change a file for it to become an annex
  public isAnnexReadonly() {
    const ext = this.selectedRow?.extension.toLowerCase();
    if (ext !== 'pdf') {
      return true;
    } else {
      return false;
    }
  }

  //translations dependent of subscribe Promise
  getTrads() {
    //malgré la promise on va devoir faire un subscribe pour être sûr d'avoir les traductions, parce que sinon une fois sur 5 ou 6, ca charge avant traduction ><
    return new Promise((resolve) => {
      const arrayOfKeys = this.buttons.map((x) => x.text);
      this.buttons.map((x) => arrayOfKeys.push(x.tooltip));
      this.itemsTabs.map((x) => arrayOfKeys.push(x.header));
      this.actionsRow.map((x) => arrayOfKeys.push(x.label));
      this.menuModelesPoint.map((x) => arrayOfKeys.push(x.label));
      this.translate.get(arrayOfKeys).subscribe((translations) => {
        this.buttons.map((x) => {
          x.text = translations[x.text];
          x.tooltip = translations[x.tooltip];
        });
        this.itemsTabs.map((x) => {
          x.header = translations[x.header];
        });
        this.actionsRow.map((x) => {
          x.label = translations[x.label];
          x.tooltip = translations[x.tooltip];
        });
        this.menuModelesPoint.map((x) => {
          x.label = translations[x.label];
        });
        resolve(true);
      });
    });
  }

  // Triggers when a RTF needs to be reloaded
  reloadRTF(event, type: string, index) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedType = type;
    this.currentIndex = index;
    this.reloadRTFPresence();
  }

  //* triggers when a RTF is loaded
  loadFile(type) {
    if (this.pointId) {
      this.spinner.show();
      this.api.getPointRTFDatasStatic(this.pointId, type).subscribe((res: any) => {
        if (res && res.size > 0) {
          if (type === 'Motivation' && this.document_editor_motivation) {
            setTimeout(() => {
              this.document_editor_motivation.rich.openDocument(res, type, DocumentFormatApi.Rtf, (loaded) => {
                if (loaded) {
                  this.spinner.hide();
                } else {
                  this.activeState[this.currentIndex] = false;
                  this.spinner.hide();
                  this.toastr.error(this.translate.instant('error.cannotGetRTFFile'));
                }
              });
            }, 1000);
          }
          if (type === 'Decision' && this.document_editor_decision) {
            this.document_editor_decision.rich.openDocument(res, 'Decision', DocumentFormatApi.Rtf, (loaded) => {
              if (loaded) {
                this.spinner.hide();
              } else {
                this.activeState[this.currentIndex] = false;
                this.spinner.hide();
                this.toastr.error(this.translate.instant('error.cannotGetRTFFile'));
              }
            });
          }
          if (type === 'Observations' && this.document_editor_observation) {
            this.document_editor_observation.rich.openDocument(res, 'Observations', DocumentFormatApi.Rtf, (loaded) => {
              if (loaded) {
                this.spinner.hide();
              } else {
                this.activeState[this.currentIndex] = false;
                this.spinner.hide();
                this.toastr.error(this.translate.instant('error.cannotGetRTFFile'));
              }
            });
          }
          if (type === 'ComplementDeDeliberation' && this.document_editor_complementDeDeliberation) {
            this.document_editor_complementDeDeliberation.rich.openDocument(
              res,
              'ComplementDeDeliberation',
              DocumentFormatApi.Rtf,
              (loaded) => {
                if (loaded) {
                  this.spinner.hide();
                } else {
                  this.activeState[this.currentIndex] = false;
                  this.spinner.hide();
                  this.toastr.error(this.translate.instant('error.cannotGetRTFFile'));
                }
              }
            );
          }
          if (type === 'NotesDeSynthese' && this.document_editor_notesDeSynthese) {
            this.document_editor_notesDeSynthese.rich.openDocument(
              res,
              'NotesDeSynthese',
              DocumentFormatApi.Rtf,
              (loaded) => {
                if (loaded) {
                  this.spinner.hide();
                } else {
                  this.activeState[this.currentIndex] = false;
                  this.spinner.hide();
                  this.toastr.error(this.translate.instant('error.cannotGetRTFFile'));
                }
              }
            );
          }
        } else {
          this.spinner.hide();
        }
      });
    }
  }

  onShowAll() {
    this.spinner.show();
    setTimeout(() => {
      this.showAllAccordion = !this.showAllAccordion;
      const arrayRTF = ['Motivation', 'Decision', 'Observations', 'ComplementDeDeliberation', 'NotesDeSynthese'];
      if (this.showAllAccordion) {
        //Quand on va ouvrir les accordéons
        arrayRTF.map((type, index) => {
          this.activeState[index] = true;
          this.loadFile(type);
        });
        this.spinner.hide();
      } else {
        arrayRTF.map((type, index) => {
          this.activeState[index] = false;
        });
        this.spinner.hide();
      }
    }, 100);
  }

  //* triggers when the user clicks on a accordion
  onClick(type, index) {
    this.selectedType = type;
    this.currentIndex = index;
    //on ne va charger que 1 RTF a la fois et on ne l'ouvre que si l'accordéon s'ouvre
    if (this.activeState[index] === true) {
      this.loadFile(this.selectedType);
    }
  }

  handleClick(event: Event) {
    event.stopPropagation();
  }

  //* Opens the editor on full screen in another navigator tab
  openFullScreen(event, title) {
    event.preventDefault();
    event.stopPropagation();
    const config = JSON.parse(sessionStorage.getItem('globalConfig'));
    window.open(config.baseUrl + `/private/editor/point/` + this.pointId + `/` + title);
  }

  //* reloads the datas that displays if a RTF has data sin it
  reloadRTFPresence(goToNextStep = false) {
    this.api.getPointContenuStaticRTFFilesPresents(this.pointId).subscribe((res) => {
      this.contenuStaticRTFFilesPresents = res;
      if (goToNextStep) {
        this.spinner.hide();
        this.nextStep();
      }
    });
  }

  //* Saving all RTF
  saveAllRTFPromise() {
    return new Promise((resolve, reject) => {
      const data = [];
      const names = [];
      async.eachSeries(
        this.contentEditors,
        (item: any, cbEachSeries) => {
          if (Object.values(item)[0] === null) {
            //on passe les null
            cbEachSeries(null);
          } else {
            data.push(Object.values(item)[0]);
            const name = Object.keys(item);
            names.push(name);
            cbEachSeries(null);
          }
        },
        () => {
          this.api.putMultiplePointRTFDatasStatic(this.pointId, data, names).subscribe(
            (res: any) => {
              this.toastr.success(this.translate.instant('message.success.dataSaved'));
              resolve(true);
            },
            (err) => {
              this.toastr.error('Une erreur est survenue lors de la sauvegarde');
              resolve(false);
            }
          );
        }
      );
    });
  }

  //* triggers when the user changes the tab for Contacts/users
  onClickRadioButtonHandler(event) {
    if (this.handlerType === 'users') {
      this.infosForm.patchValue({ agentTraitantId: null });
    } else if (this.handlerType === 'contacts') {
      this.infosForm.patchValue({ agentTraitantIdiAdmin: null });
    }
  }

  //* Getting the datas for each RTF
  getMotivation($event) {
    const blob = new Blob([$event], { type: 'application/rtf' });
    const data = {
      Motivation: blob,
    };
    this.contentEditors.splice(0, 1, data);
  }
  getDecision($event) {
    const blob = new Blob([$event], { type: 'application/rtf' });
    const data = {
      Decision: blob,
    };
    this.contentEditors.splice(1, 1, data);
  }
  getObservations($event) {
    const blob = new Blob([$event], { type: 'application/rtf' });
    const data = {
      Observations: blob,
    };
    this.contentEditors.splice(2, 1, data);
  }
  getComplement($event) {
    const blob = new Blob([$event], { type: 'application/rtf' });
    const data = {
      ComplementDeDeliberation: blob,
    };
    this.contentEditors.splice(3, 1, data);
  }

  getNotes($event) {
    const blob = new Blob([$event], { type: 'application/rtf' });
    const data = {
      NotesDeSynthese: blob,
    };
    this.contentEditors.splice(4, 1, data);
  }

  changeMatter(event) {
    if (event.value) {
      const currentMatter = this.globalElements.matieres_LV.find((x) => x.value === event.value);
      if (currentMatter) {
        this.listOfEchevinats = [];
        if (currentMatter.item.echevinats.length > 0) {
          currentMatter.item.echevinats.map((x) => {
            const index = this.listOfEchevinatsRights.map((x) => x.value).indexOf(x);
            if (index > -1) {
              this.listOfEchevinats.push(this.listOfEchevinatsRights[index]);
            }
          });
          //une fois cela fait, on va devoir vérifier si la matière checkée est la matière DE BASE du courrier. Cela ne se fait que si on est en modif et qu'on a une matière et un échevinat. Le but de base est de remettre l'échevinat déjà existant du point.
          if (this.actualPoint && this.actualPoint.matiereId && this.actualPoint.echevinatId) {
            if (this.actualPoint.matiereId === event.value) {
              //on vérifie quand même si l'échevinat est accessible par l'utilisateur
              const index = this.listOfEchevinats.map((x) => x.value).indexOf(this.actualPoint.echevinatId);
              if (index > -1) {
                this.infosForm.patchValue({ echevinatId: this.actualPoint.echevinatId });
              }
            } else {
              if (this.listOfEchevinats.length === 1) {
                this.infosForm.patchValue({
                  echevinatId: this.listOfEchevinats[0].value,
                });
              } else {
                this.infosForm.patchValue({
                  echevinatId: null,
                });
              }
            }
          } else {
            if (this.listOfEchevinats.length === 1) {
              this.infosForm.patchValue({
                echevinatId: this.listOfEchevinats[0].value,
              });
            } else {
              this.infosForm.patchValue({
                echevinatId: null,
              });
            }
          }
        } else {
          this.infosForm.patchValue({
            echevinatId: null,
          });
        }
      }
    } else {
      this.infosForm.patchValue({
        echevinatId: null,
      });
    }
  }

  checkUserRightsToSeeGlobalElementsBasedOnGroupeSecurity() {
    return new Promise((resolve, reject) => {
      if (
        this.groupeSecuriteRightsPoints.changerMatiereAllowedIds &&
        this.groupeSecuriteRightsPoints.changerMatiereAllowedIds.length > 0
      ) {
        this.listOfMatieres = this.globalElements.matieres_LV.filter((x) =>
          this.groupeSecuriteRightsPoints.changerMatiereAllowedIds.includes(x.value)
        );
      }

      if (
        this.groupeSecuriteRightsPoints.changerStatutAllowedIds &&
        this.groupeSecuriteRightsPoints.changerStatutAllowedIds.length > 0
      ) {
        this.listOfStatuts = this.globalElements.statuts_points_LV.filter((x) =>
          this.groupeSecuriteRightsPoints.changerStatutAllowedIds.includes(x.value)
        );
      }

      if (
        this.groupeSecuriteRightsPoints.changerTypePointAllowedIds &&
        this.groupeSecuriteRightsPoints.changerTypePointAllowedIds.length > 0
      ) {
        this.listOfTypePoint = this.globalElements.types_points_LV.filter((x) =>
          this.groupeSecuriteRightsPoints.changerTypePointAllowedIds.includes(x.value)
        );
      }

      if (
        this.groupeSecuriteRightsPoints.changerEchevinatAllowedIds &&
        this.groupeSecuriteRightsPoints.changerEchevinatAllowedIds.length > 0
      ) {
        this.listOfEchevinatsRights = this.globalElements.echevinats_LV.filter((x) =>
          this.groupeSecuriteRightsPoints.changerEchevinatAllowedIds.includes(x.value)
        );
      }

      resolve(true);
    });
  }

  importFileFromCourrierId() {
    this.api.getIcourrierFiles(this.infosForm.value.courrierId).subscribe(
      (res: any) => {
        this.toastr.success(this.translate.instant('success.file.imported'));
        this.itemFiles = res;
        this.openModalForFilesSelectionCourrier = true;
      },
      (err) => {
        if (err?.error?.message && err?.error?.message === 'Mail not found') {
          this.toastr.error("Ce courrier n'a pas pu être retrouvé, veuillez vérifier son identifiant");
        } else if(err.status === 401) {
          this.toastr.error("Vous n'êtes pas autorisé à importer ce courrier");
        } else {
          this.toastr.error('Une erreur est survenue');
        }
      }
    );
  }
  patchPointFiles() {
    const FichiersIds = [];
    try {
      if (this.superTableFilesDuplication && this.superTableFilesDuplication._selection.length > 0) {
        this.superTableFilesDuplication._selection.map((item) => {
          FichiersIds.push(item.id);
        });
        this.infosForm.patchValue({
          iCourrierFichierIds: FichiersIds,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  checkDisplayButtonUseTemplateModel() {
    //* firstly checking if we're basically in a creation 'cause ticket IDELIBE-602 asks in creation
    if (this.update === false) {
      if (this.infosForm.value.serviceId) {
        const childbtn = this.buttons.find((x) => x.id === 'idUsePointModel');
        if (childbtn) {
          childbtn.disabled = false;
          childbtn.tooltip = '';
        }
      } else {
        const childbtn = this.buttons.find((x) => x.id === 'idUsePointModel');
        if (childbtn) {
          childbtn.disabled = true;
          childbtn.tooltip = this.useModelPointTooltipService;
        }
      }
    }
  }

  getModelesPoints() {
    return new Promise<any>((resolve, reject) => {
      this.api.getGlobalModelesPoints().subscribe((res: any) => {
        this.allModelesPoints = res;
        resolve(true);
      });
    });
  }

  filterModelesPoints() {
    let modelesFiltered = this.allModelesPoints.filter(
      (x) => x.serviceId === null || x.serviceId === this.infosForm.value.serviceId
    );
    if (this.infosForm.value.typeSeanceId) {
      modelesFiltered = modelesFiltered.filter(
        (x) => x.typeSeanceId === null || x.typeSeanceId === this.infosForm.value.typeSeanceId
      );
    }
    this.filteredModelesPoints = modelesFiltered.map((x) => {
      return new Model_Point_Biblio(
        x,
        this.globalElements.types_seances_LV,
        this.globalElements.types_points_LV,
        this.allServices,
        this.globalElements.matieres_LV,
        this.globalElements.decision_type_LV,
        this.listOfCodesActives,
        this.allClasseurs,
        this.allChemises
      );
    });
    this.filteredModelesPoints = _.sortBy(this.filteredModelesPoints, 'serviceLabel');
    //ici on va juste placer les modèles sans service au dessus de tout
    const modelesNoService = this.filteredModelesPoints.filter((x) => !x.serviceId);
    const otherModeles = this.filteredModelesPoints.filter((x) => x.serviceId);
    this.filteredModelesPoints = modelesNoService.concat(otherModeles);

    this.openModelesPointsModal = true;
  }

  setRowModelePoint(event) {
    this.selectedRowModelePoint = event;
  }

  applyModelePoint(event) {
    this.appliedModelePoint = event;
    if (event) {
      //Pour chaque donnée, on va vérifier
      // 1) s'il y en a une dans la ligne sélectionné
      //2) Si elle est présente dans la liste dropdown de l'utilisateur
      async.parallel(
        [
          (callback) => {
            if (event.typeSeanceId && this.types_seances_LV.map((x) => x.value).indexOf(event.typeSeanceId) > -1) {
              this.infosForm.patchValue({ typeSeanceId: event.typeSeanceId });
              this.selectedMeetingPoint({ value: event.typeSeanceId });
              callback();
            } else {
              callback();
            }
          },
          (callback) => {
            if (event.objet) {
              this.infosForm.patchValue({ objetSynthese: event.objet });
              callback();
            } else {
              callback();
            }
          },
          (callback) => {
            if (event.matiereId && this.listOfMatieres.map((x) => x.value).indexOf(event.matiereId) > -1) {
              this.infosForm.patchValue({ matiereId: event.matiereId });
              this.changeMatter({ value: event.matiereId });
              callback();
            } else {
              callback();
            }
          },
          (callback) => {
            if (
              event.defautObjetTypeId &&
              this.listOfTypePoint.map((x) => x.value).indexOf(event.defautObjetTypeId) > -1
            ) {
              this.infosForm.patchValue({ typeId: event.defautObjetTypeId });
              callback();
            } else callback();
          },
          (callback) => {
            if (event.classementId && this.listOfCodesActives.map((x) => x.value).indexOf(event.classementId) > -1) {
              this.infosForm.patchValue({ classementId: event.classementId });
              //Ensuite on va regarder s'il y a un classeur
              if (event.fardeId) {
                this.changeCode(event.fardeId).then(() => {
                  //on va enfin vérifier s'il y a une chemise et que l'on a bien patché un classeur
                  if (event.chemiseId && this.infosForm.value.folder) {
                    this.changeFolder(event.chemiseId).then(() => {
                      callback();
                    });
                  } else {
                    callback();
                  }
                });
              } else {
                callback();
              }
            } else {
              callback();
            }
          },
          (callback) => {
            if (
              event.decisionType &&
              this.globalElements?.decision_type_LV.map((x) => x.value).indexOf(event.decisionType) > -1
            ) {
              this.infosForm.patchValue({ typeDecisionId: event.decisionType });
              callback();
            } else {
              callback();
            }
          },
        ],
        () => {
          this.openModelesPointsModal = false;
          // a partir de la, les données sont patchées, plus qu'a faire les 4 RTF
        }
      );
    }
  }

  checkIfThePointIsLocked() {
    if (this.update) {
      const currentUser: boolean = this.userInfos.id === this.actualPoint.writeLock.writeLockUserId;
      this.writeLock = {
        currentUser: currentUser,
      };
      if (!this.actualPoint.writeLock.locked) {
        this.lockPoint();
      } else {
        this.isLocked = true;
      }
    } else {
      this.isLocked = false;
      this.writeLock = {
        currentUser: true,
      };
    }
  }
}
