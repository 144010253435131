import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { DataService } from 'src/app/core/services/data.service';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { ContactNewComponent } from 'src/app/shared/components/modals/contact-new/contact-new.component';
import { Contact } from 'src/app/shared/models/iContact/contact';
import * as async from 'async';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { iAdminService } from 'src/app/core/services/iAdmin.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit {
  public contactsBACKUP: [] = [];
  public contacts: Array<Contact> = [];
  public companyLegalTypes: [] = [];
  public isLoading: boolean = false;

  public openAdvancedModal = false;
  public formSearch = new FormGroup({
    nom: new FormControl(null),
    prenom: new FormControl(null),
    rue: new FormControl(null),
    cp: new FormControl(null),
    localite: new FormControl(null),
    gsm: new FormControl(null),
    email: new FormControl(null),
    titre: new FormControl(null),
  });

  public contactTitresList: Array<any> = [];

  constructor(
    public icontactService: IContactService,
    public spinner: NgxSpinnerService,
    public dialog: DialogService,
    public router: Router,
    public dataService: DataService,
    public toastr: ToastrService,
    private titleService: Title,
    private iAdminApi: iAdminService
  ) {}

  ngOnInit(): void {
    async.waterfall(
      [
        (callback) => {
          this.titleService.setTitle('iDélibé - Contacts');
          this.iAdminApi.getContactTitres().subscribe(
            (response: any) => {
              if (response.length > 0) {
                response.map((item) => {
                  if (item.titre) {
                    this.contactTitresList.push({ label: item.titre, value: item.titre });
                  }
                });
                this.contactTitresList = _.sortBy(this.contactTitresList, 'label');
                callback();
              } else {
                callback();
              }
            },
            (error) => {
              console.error(error);
            }
          );
        },
      ],
      () => {
        this.getContacts({});
      }
    );
  }

  reinit() {
    setTimeout(() => {
      this.formSearch.reset();
      this.getContacts({});
      this.openAdvancedModal = false;
    }, 500);
  }

  recveiveReset(e) {
    this.getContacts({});
  }

  receiveCreateEvent(e) {
    this.openCreate();
  }

  searchAdvanced() {
    this.spinner.show();
    const searchObject = {
      nom: this.formSearch.value.nom !== null ? this.formSearch.value.nom.toLowerCase() : null,
      prenom: this.formSearch.value.prenom !== null ? this.formSearch.value.prenom.toLowerCase() : null,
      titre: this.formSearch.value.titre !== null ? this.formSearch.value.titre : null,
      email: this.formSearch.value.email,
      gsm: this.formSearch.value.gsm !== null ? this.formSearch.value.gsm.toLowerCase().replace(/\s/g, '') : null,
      cp: this.formSearch.value.cp !== null ? this.formSearch.value.cp.toLowerCase() : null,
      rue: this.formSearch.value.rue !== null ? this.formSearch.value.rue.toLowerCase() : null,
      localite: this.formSearch.value.localite !== null ? this.formSearch.value.localite.toLowerCase() : null,
      pageNumber: 1,
      pageSize: 2000,
      sortFields: 'nom,prenom',
    };
    this.icontactService.post(`/rechercheContacts`, searchObject).subscribe((res: any) => {
      this.contacts = [];
      if (res.length > 0) {
        res.map((x) => {
          if (x.nePlusUtiliser === false || x.nePlusUtiliser === null) {
            this.contacts.push(new Contact(x));
          }
        });
        this.contacts = _.sortBy(this.contacts, 'nom');
        this.spinner.hide();
      } else {
        this.toastr.warning("Il n'y a pas de résultats pour votre recherche");
        this.spinner.hide();
      }
    });
    this.openAdvancedModal = false;
  }

  receiveSearchAdvanced(e) {
    if (e.byFullName) {
      this.getContacts({ NomPrenomRecherche: e.byFullName });
    }
    if (e.isCompany === true || e.isCompany === false) {
      const data = {
        pageNumber: 1,
        pageSize: 2000,
        sortFields: 'nom,prenom',
        typeSociete: e.isCompany,
        nomOperator: 'startWith',
      };
      this.getContacts(data);
    }
  }

  receive_selected(contact) {
    this.router.navigateByUrl('/private/contacts/details/' + contact.id);
  }

  receiveScrollE(e) {}

  receiveSearchEvent(e) {
    const data = {
      pageNumber: 1,
      pageSize: 2000,
      sortFields: 'nom,prenom',
      nom: e,
      nomOperator: 'startWith',
    };
    this.getContacts(data);
  }

  receive_searchInput(e) {
    const data = {
      pageNumber: 1,
      pageSize: 2000,
      sortFields: 'nom,prenom',
      NomPrenomRecherche: e,
      nomOperator: 'startWith',
    };
    this.getContacts(data);
  }

  searchLetterEvent(e) {}

  getContacts(params?: any) {
    this.isLoading = false;
    this.contacts = [];
    this.icontactService.post(`/contacts/lowinformation`, params).subscribe((contacts) => {
      this.isLoading = false;
      contacts.map((x) => {
        if (x.nePlusUtiliser === false || x.nePlusUtiliser === null) {
          this.contacts.push(new Contact(x));
        }
      });
      this.contacts = _.sortBy(this.contacts, 'nom');
      this.spinner.hide();
    });
  }

  openCreate() {
    const dialog = this.dialog.open(ContactNewComponent, {
      width: '80vw',
      showHeader: false,
    });
    dialog.onClose.subscribe((res: any) => {
      this.ngOnInit();
    });
  }
}
