import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/core/services/data.service';
import { Global_Class } from 'src/app/shared/models/global';
import * as async from 'async';
import { ApiService } from 'src/app/core/services/api.service';
import { Service } from 'src/app/shared/models/service';
import { ConfirmationService, MenuItem } from 'primeng/api';
import * as moment from 'moment';
import * as _ from 'underscore';
import { Point } from 'src/app/shared/models/point';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentEditorService } from 'src/app/core/services/document-editor.service';
import { FileUpload } from 'primeng/fileupload';
import { AccordionsEditorComponent } from 'src/app/shared/components/accordions-editor/accordions-editor.component';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { Contact } from 'src/app/shared/models/iContact/contact';
import { LabelValue } from 'src/app/shared/models/labelvalue';
import { TenantInfo } from 'src/app/shared/models/tenant-info';
import { SuperColumn, SuperTableComponent } from '@devinforius/super-compos';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-create-point-icourrier',
  templateUrl: './create-icourrier.component.html',
  styleUrls: ['./create-icourrier.component.scss'],
})
export class CreatePointFromIcourrierComponent implements OnInit {
  public loaded = false;

  public activeTab: number = 0;
  public itemsTabs: Array<any> = [{ label: 'infosAndLinks' }, { label: 'joinedFilesFromCourrier' }];
  public selectedFiles = [];

  public infosForm: FormGroup = new FormGroup({
    objetSynthese: new FormControl(null, Validators.required),
    typeSeanceId: new FormControl(null, Validators.required),
    seanceId: new FormControl(null, Validators.required),
    serviceId: new FormControl(null, Validators.required),
    service: new FormControl(),
    typeId: new FormControl(),
    confidentiel: new FormControl(),
    public: new FormControl(true),
    checkRgpd: new FormControl(),
    origine: new FormControl(),
    courrierId: new FormControl(),
    matiereId: new FormControl(),
    matiere: new FormControl(),
    echevinatId: new FormControl(),
    echevinat: new FormControl(),
    agentTraitantId: new FormControl(),
    agentTraitantIdiAdmin: new FormControl(),
    agentTraitantNom: new FormControl(),
    urgenceNiveauId: new FormControl(),
    classementId: new FormControl(),
    dossierClassementId: new FormControl(),
    classementCode: new FormControl(),
    folder: new FormControl(),
    dossierClassementDescription: new FormControl(),
    sleeve: new FormControl(),
    dossierClassement2Description: new FormControl(),
    statutId: new FormControl(null, Validators.required),
    typeDecisionId: new FormControl(),
    datePassageSouhaite: new FormControl(null),
    iCourrierFichierIds: new FormControl(null),
  });

  public openModifyAFileInModal: boolean = false;

  public actions: Array<any> = [
    {
      label: this.translate.instant('btn.import'),
      tooltip: this.translate.instant('btn.import'),
      command: () => {
        this.selectedFiles.map((file) => {
          this.itemFiles.find((x) => x.id === file.id).import = true;
        });
      },
    },
    {
      label: this.translate.instant('btn.noImport'),
      tooltip: this.translate.instant('btn.noImport'),
      command: () => {
        this.selectedFiles.map((file) => {
          this.itemFiles.find((x) => x.id === file.id).import = false;
        });
      },
    },
  ];
  public actionsRow: Array<any> = [
    {
      label: this.translate.instant('btn.import'),
      tooltip: this.translate.instant('btn.import'),
      command: (data) => {
        this.selectedRow.import = true;
      },
    },
    {
      label: this.translate.instant('btn.noImport'),
      tooltip: this.translate.instant('btn.noImport'),
      command: (data) => {
        this.selectedRow.import = false;
      },
    },
  ];
  public itemFiles = [];
  public buttons = [
    {
      text: 'btn.saveAndLeave',
      styleClass: 'blue',
      icon: 'fas fa-check-square',
      iconPosition: 'left',
      id: 'idSaveAndLeave',
      click: () => {
        this.validateAndSave(true);
      },
    },
    {
      text: 'btn.save',
      styleClass: 'green',
      icon: 'fas fa-check-square',
      iconPosition: 'left',
      id: 'idSaveAndLeave',
      click: () => {
        this.validateAndSave(false);
      },
    },
  ];

  public menu: Array<MenuItem> = [];

  public globalElements;
  public mails = [];
  public departments = [];
  public handler = [];
  public seances = [];
  public classeurs = [];
  public chemises = [];

  public classifying: boolean = true;

  public validatedPointId = null;
  public mandatoryPointParameters;
  public actualPoint: Point = null;
  public titleComponent: string;
  public annexStringTranslated;
  public mainFileStringTranslated;
  public config;
  public fileUploadChooseLabel;
  public fileUploadUploadLabel;
  public fileUploadCancelLabel;

  public selectedRow;
  public formFilesModification: FormGroup = new FormGroup({
    id: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    annexeNumero: new FormControl(),
    annexType: new FormControl(),
    OrdreAffichage: new FormControl(),
    visibleDelibeWeb: new FormControl(),
    visibleDelibeWebCitoyen: new FormControl(),
  });
  @ViewChild('uploader') uploader: FileUpload;
  @ViewChild(AccordionsEditorComponent) private accordionComponent: AccordionsEditorComponent;
  public count = 0;

  public point: Point;
  public listeAgentsTraitants: Array<Contact> = [];
  public listeAgentsTraitantsAdmin: Array<Contact> = [];
  public userInfos;
  public token;
  public currentIndex;

  public handlerType = '';

  public avoidChangeObjetType: boolean = false; //* Forbid the user to change the object type within a type meeting 'cause of params
  public avoidChangeObjetTypeLabel: boolean = false; //* Contains the label of the forbidden to change typePoint for display purpose
  public types_seances_LV: Array<LabelValue> = [];
  public icourrierID: number;
  public globalConfiguration;
  public displayCourrierId = false;
  public confirmDialogBool: boolean = false;
  public itemsUploaded: any;
  public uploaders: any;
  public seanceSelectedInfos = null;

  public columnsFiles: Array<SuperColumn> = [
    {
      field: 'description',
      header: 'description',
    },
    {
      field: 'extension',
      header: 'extension',
    },
    {
      field: 'fileType',
      header: 'Type de fichier',
      format: (value, item) => {
        if (item.modeleId && item.extension.toLowerCase() === 'pdf') {
          return this.translate.instant('document');
        } else if (item.annexeNumero) {
          return this.translate.instant('annexN°') + ' ' + item.annexeNumero;
        } else {
          return this.translate.instant('associatedFile');
        }
      },
    },
    {
      field: 'dateUpload',
      header: "Date d'import ",
      format: (value) => {
        if (value) {
          return moment(value).format('DD/MM/YYYY');
        }
      },
    },
  ];

  public groupeSecuriteRightsPoints = null;
  public listOfEchevinats = [];
  public listOfEchevinatsRights = [];
  public listOfMatieres = [];
  public listOfStatuts = [];
  public listOfTypePoint = [];
  public listOfCodesActives = [];

  public pointId = null;
  @ViewChild('superTableFilesDuplication') public superTableFilesDuplication: SuperTableComponent;

  public openModalForFilesSelectionCourrier: boolean = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private dataS: DataService,
    private api: ApiService,
    private toastr: ToastrService,
    public apiAdmin: iAdminService,
    public spinner: NgxSpinnerService,
    private iContactService: IContactService,
    public confirmationService: ConfirmationService,
    private oAuthService: OAuthService
  ) {
    this.token = this.oAuthService.getAccessToken();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.titleComponent = 'btn.createPoint';

    async.waterfall(
      [
        (callback) => {
          this.api.getGroupeSecuriteRightsPoints(null).subscribe((res: any) => {
            this.groupeSecuriteRightsPoints = res;
            callback();
          });
        },
        (callback) => {
          this.apiAdmin.getTenant().subscribe(
            (res: TenantInfo) => {
              res.applications?.map((x) => {
                if (x.name === 'iCourrier') {
                  this.displayCourrierId = true;
                }
              });
              callback();
            },
            (err) => {
              this.toastr.error('Une erreur est survenue dans la récupération du tenant!');
              callback();
            }
          );
        },
        (callback) => {
          this.api.getConfiguration().subscribe((res: any) => {
            this.globalConfiguration = res;
            callback();
          });
        },
        (callback) => {
          this.apiAdmin.getUserInfo().subscribe((res: any) => {
            this.userInfos = res;
            callback();
          });
        },
        (callback) => {
          this.annexStringTranslated = this.translate.instant('annex');
          this.mainFileStringTranslated = this.translate.instant('mainFile');

          this.fileUploadChooseLabel = this.translate.instant('btn.choose');
          this.fileUploadUploadLabel = this.translate.instant('btn.upload');
          this.fileUploadCancelLabel = this.translate.instant('btn.cancel');

          this.dataS.getGlobalElements.subscribe((res: any) => {
            this.globalElements = new Global_Class(res);
            if (this.globalElements.isLoaded()) {
              callback();
            }
          });
        },
        (callback) => {
          this.checkUserRightsToSeeGlobalElementsBasedOnGroupeSecurity().then(() => {
            callback();
          });
        },
        (callback) => {
          this.iContactService.getUserWithDataAdmin().subscribe((res: any) => {
            this.listeAgentsTraitantsAdmin = res.filter((x) => x.active);
            callback();
          });
        },
        (callback) => {
          this.iContactService.getLowContacts().subscribe((res: any) => {
            this.listeAgentsTraitants = res.filter((x) => !x.nePlusUtiliser);
            callback();
          });
        },
        (callback) => {
          this.infosForm.reset();
          const valideForSeanceStatus = this.globalElements?.statuts_points_LV.find(
            (x) => x.item?.statutAutiliserLorsCreation === true
          );
          const isUserHasAccessToStatut = this.listOfStatuts.map((x) => x.value).indexOf(valideForSeanceStatus.value);
          this.infosForm.patchValue({
            statutId: isUserHasAccessToStatut > -1 ? valideForSeanceStatus?.value : null,
            agentTraitantIdiAdmin: this.userInfos.contactId,
          });
          this.handlerType = 'users';
          callback();
        },
        (callback) => {
          this.getTrads().then(() => {
            callback(null);
          });
        },
        (callback) => {
          //ici on a la gestion du classement normalement MAIS comme on peut avoir un classement dans le courrier que l'on va utiliser, on va le faire plus tard
          callback();
        },
        (callback) => {
          //on va restreindre l'accès aux types de séances
          this.api.getRightsPointsSeances().subscribe((res: any) => {
            res.typeSeanceRights.forEach((x) => {
              if (x.addPoint) {
                this.globalElements.types_seances_LV.map((x) => x.value).indexOf(x.typeSeanceId) > -1
                  ? this.types_seances_LV.push(
                      this.globalElements.types_seances_LV[
                        this.globalElements.types_seances_LV.map((x) => x.value).indexOf(x.typeSeanceId)
                      ]
                    )
                  : null;
              }
            });
            callback();
          });
        },
        (callback) => {
          this.apiAdmin.getGlobalServices().subscribe((response: Service[]) => {
            response.map((item: Service) => {
              if (this.userInfos.services.indexOf(item.id) > -1) {
                if (item.description) {
                  let parent = [];
                  parent = response.filter((x) => x.id === item.parentId);
                  item.label = parent.length > 0 ? parent[0].description + ' / ' + item.description : item.description;
                  this.departments.push({ label: item.label, value: item.id });
                }
              }
            });
            this.departments = _.sortBy(this.departments, 'label');
            callback();
          });
        },
        (callback) => {
          //* Getting mandatory parameters for form
          this.api.getGlobalPointParameters().subscribe((res: any) => {
            this.mandatoryPointParameters = res;
            if (res.echevinatObligatoire && this.infosForm.controls['echevinatId']) {
              this.infosForm.controls['echevinatId'].setValidators([Validators.required]);
              this.infosForm.controls['echevinatId'].updateValueAndValidity();
            }
            if (res.classementObligatoire && this.infosForm.controls['classementId']) {
              this.infosForm.controls['classementId'].setValidators([Validators.required]);
              this.infosForm.controls['classementId'].updateValueAndValidity();
            }
            if (res.matiereObligatoire && this.infosForm.controls['matiereId']) {
              this.infosForm.controls['matiereId'].setValidators([Validators.required]);
              this.infosForm.controls['matiereId'].updateValueAndValidity();
            }
            if (res.origineObligatoire && this.infosForm.controls['origine']) {
              this.infosForm.controls['origine'].setValidators([Validators.required]);
              this.infosForm.controls['origine'].updateValueAndValidity();
            }
            if (res.typeDecisionObligatoire && this.infosForm.controls['typeDecisionId']) {
              this.infosForm.controls['typeDecisionId'].setValidators([Validators.required]);
              this.infosForm.controls['typeDecisionId'].updateValueAndValidity();
            }
            if (res.urgenceObligatoire && this.infosForm.controls['urgenceNiveauId']) {
              this.infosForm.controls['urgenceNiveauId'].setValidators([Validators.required]);
              this.infosForm.controls['urgenceNiveauId'].updateValueAndValidity();
            }
            callback();
          });
        },
        (callback) => {
          this.getCourrierIDIfExists().then(() => {
            callback(null);
          });
        },
        (callback) => {
          this.listOfCodesActives = this.globalElements.classement_code_LV.filter((x) => {
            //ajoute aussi si le classement est celui du point comme cela s'il est inactif on l'ajoute quand même
            if (
              x.item.actif ||
              (this.infosForm.value.classementId && this.infosForm.value.classementId === x.item.id)
            ) {
              return x;
            }
          });
          callback();
        },
        (callback) => {
          //* Getting the rights for configuration => mandataire and Citoyen depend on them
          this.api.getConfiguration().subscribe((config) => {
            this.config = config;
            callback();
          });
        },
      ],
      () => {
        this.loaded = true;
        this.spinner.hide();
      }
    );
  }

  // onglet fichiers
  public selectRowData(data) {
    this.selectedRow = data;
  }

  onUpload(event, uploader) {
    async.waterfall([
      (cb) => {
        this.confirmDialogBool = true;
        cb(null);
      },
      (cb) => {
        this.itemsUploaded = event.files;
        this.uploader = uploader;
      },
    ]);
  }
  addFileAnnexe(isAnnexeFile) {
    async.waterfall([
      (callback) => {
        this.spinner.show();
        this.count = 0;
        const totalItems = this.itemsUploaded.length;
        for (const item of this.itemsUploaded) {
          item.description = item.name;
          item.extension = item.name.split('.').pop();
          item.date = moment(item.lastModifiedDate, 'DD/MM/YYYY');
          item.pointId = this.pointId;
          const isPDF = item.extension.toLowerCase() === 'pdf' ? true : false;
          const document = {
            description: item.name,
            version: 1,
          };

          this.api.addPointFiles(this.pointId, document, item, item.extension, isPDF ? isAnnexeFile : false).subscribe(
            (response: any) => {
              this.itemFiles = [...this.itemFiles, response];
              this.selectedFiles = this.itemFiles;
              this.count++;
              if (this.count === totalItems) {
                callback(null);
              }
            },
            (error) => {
              console.error(error);
              this.spinner.hide();
              this.toastr.error("Une erreur est survenue lors de l'ajout de fichier(s).");
            }
          );
        }
      },
      (callback) => {
        this.itemsUploaded = [];
        this.toastr.success('Merci', 'Les fichiers ont été enregistrés!');
        this.uploaders.clear();
        this.spinner.hide();
        callback(null);
      },
    ]);
  }

  public submitModificationFile() {
    if (this.formFilesModification.valid) {
      let annexDoesntAlreadyExistInFiles = false; //le flag va permettre de déterminer si l'annexe possède un numéro identique a une autre existante
      let indexOfAnnexIdentique;
      this.api.getPointFiles(this.pointId).subscribe((allPointFiles: any) => {
        if (this.formFilesModification.value.annexeNumero) {
          const allAnnexNumbers = allPointFiles
            .filter((x) => x.id !== this.formFilesModification.value.id)
            .map((x) => {
              return x.annexeNumero;
            });
          indexOfAnnexIdentique = allAnnexNumbers.indexOf(this.formFilesModification.value.annexeNumero);
          if (indexOfAnnexIdentique > -1) {
            annexDoesntAlreadyExistInFiles = false;
          } else {
            //si c'est === -1 alors on a pas d'intersection et donc pas d'annexe avec le même numéro
            annexDoesntAlreadyExistInFiles = true;
          }
        } else {
          annexDoesntAlreadyExistInFiles = true; //Pas de n° d'annexe donc c'est ok
        }

        if (annexDoesntAlreadyExistInFiles) {
          const data = this.formFilesModification.value;

          if (!data.annexType) {
            data.annexeNumero = null;
          }

          if (!this.config.delibConfig.isiDelibeMandatairesEnabled) {
            delete data.visibleDelibeWeb;
          }
          if (!this.config.delibConfig.isiDelibeCitoyensEnabled) {
            delete data.visibleDelibeWebCitoyen;
          }

          this.api.patchPointFile(this.pointId, this.selectedRow.id, data).subscribe((data: any) => {
            this.api.getPointFiles(this.pointId).subscribe((allPointFiles2: any) => {
              const temp = this.itemFiles; //on clone l'array des items affichés dans une var temporaire
              const indexFileInAllFiles = allPointFiles2.map((x) => x.id).indexOf(this.selectedRow.id);
              const indexFileInFileUpload = temp.map((x) => x.id).indexOf(this.selectedRow.id);
              temp[indexFileInFileUpload] = allPointFiles2[indexFileInAllFiles];
              this.itemFiles = [];
              this.itemFiles = [...temp];
              this.selectedFiles = this.itemFiles;

              this.toastr.success(this.translate.instant('success.modifiedFile'));
              this.openModifyAFileInModal = false;
            });
          });
        } else {
          this.toastr.error(
            allPointFiles[indexOfAnnexIdentique].description + ' ' + this.translate.instant('error.sameAnnexNumber')
          );
        }
      });
    } else {
      this.toastr.error(this.translate.instant('error.pleaseCompleteMandatoryFields'));
    }
  }

  changeCode() {
    return new Promise((resolve, reject) => {
      if (this.infosForm.value.classementId) {
        this.apiAdmin.getGlobalClasseurs({ classementId: this.infosForm.value.classementId }).subscribe((data: any) => {
          this.classeurs = data.map((x) => {
            return {
              label: x.description === '' ? this.translate.instant('noDescription') : x.description,
              value: x.id,
            };
          });
          this.infosForm.patchValue({ folder: null, sleeve: null }); // Pas oublier de reset les données...
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  }

  changeFolder() {
    return new Promise((resolve, reject) => {
      if (this.infosForm.value.folder) {
        this.apiAdmin.getGlobalChemises({ classeurId: this.infosForm.value.folder }).subscribe((data: any) => {
          this.chemises = data.map((x) => {
            return {
              label: x.description === '' ? this.translate.instant('noDescription') : x.description,
              value: x.id,
            };
          });
          this.infosForm.patchValue({ sleeve: null }); // Pas oublier de reset les données...
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  }

  validateAndSave(leave: boolean = false) {
    if (this.infosForm.valid) {
      this.infosForm.patchValue({
        dossierClassementId: this.infosForm.value.sleeve
          ? this.infosForm.value.sleeve
          : this.infosForm.value.folder
            ? this.infosForm.value.folder
            : null,
        iCourrierFichierIds: this.itemFiles.length > 0 ? this.itemFiles.filter((x) => x.import).map((x) => x.id) : [],
      });
      if (this.pointId) {
        //* update part
        this.spinner.show();
        this.api.putUpdatePoint(this.pointId, this.infosForm.value).subscribe(
          (res: any) => {
            this.toastr.success(this.translate.instant('success.updatedPoint'));
            if (leave) {
              setTimeout(() => {
                this.spinner.hide();
                this.router.navigateByUrl('/private/points/detail/' + res.id);
              }, 500);
            } else {
              this.spinner.hide();
            }
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(this.translate.instant('errorOccurred'));
          }
        );
      } else {
        this.spinner.show();
        this.api.postCreatePoint(new Point(this.infosForm.value)).subscribe(
          (response: any) => {
            this.toastr.success(this.translate.instant('success.createdPoint'));
            this.api.getRightsPoint(response.id).subscribe(
              (rights: any) => {
                if (!rights.edit) {
                  setTimeout(() => {
                    this.spinner.hide();
                    this.router.navigateByUrl('/private/points/detail/' + response.id);
                  }, 500);
                } else {
                  this.point = response;
                  this.pointId = response.id;
                  //Vu que les fichiers importés d'iCourrier ont été ajoutés, on va enlever la notion d' "import" sur les fichiers envoyés dans le post.
                  //Et on va afficher une petite alerte d'info pour dire que tout fichier importé quand y'a un pointId sera AJOUTE dans l'hTML
                  this.itemFiles.filter((x) => x.import).map((x) => (x.import = false));
                  if (leave) {
                    setTimeout(() => {
                      this.spinner.hide();
                      this.router.navigateByUrl('/private/points/detail/' + response.id);
                    }, 500);
                  } else {
                    this.spinner.hide();
                  }
                }
              },
              (error) => {
                this.spinner.hide();
                if (error && error.errors.length > 0) {
                  error.errors.map((err) => {
                    this.toastr.error(err.message);
                  });
                }
              }
            );
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(this.translate.instant('errorOccurred'));
          }
        );
      }
    } else {
      this.invalidFormToastr();
    }
  }

  selectedMeetingPoint($event) {
    this.seanceSelectedInfos = null;
    const currentTypeSeanceRight = this.globalElements.rightsPointsSeances.typeSeanceRights.find(
      (x) => x.typeSeanceId === $event.value
    );
    const actualTypeSeanceLabel = this.types_seances_LV.find((x) => x.value === this.infosForm.value.typeSeanceId)?.label;
    async.parallel([
      (callback) => {
        const data = {
          TypeSeanceIds: [this.infosForm.value.typeSeanceId],
          DateDebut: moment().format('YYYY-MM-DD'),
          Cloture: false,
          pageSize: 500,
        };
        if (currentTypeSeanceRight.isGestionnaire) {
          delete data.DateDebut;
          //le gestionnaire à accès à tout => les séances de n'imp quand + les ouvertes + les préparées
        }
        this.api.getMeetingsSearch(data).subscribe((data: any) => {
          moment.locale(localStorage.getItem('lang')); //* Setting the moment lang of component
          this.seances = [];
          data.items.map((x) => {
            const formattedDate = moment(x.date, 'YYYYMMDD').format(this.translate.instant('format.formatDateSeance'));
            const dateForOrdre = moment(x.date, 'YYYYMMDD').unix();
            this.seances.push({
              label: x.description
                ? formattedDate + ' - ' + x.description
                : actualTypeSeanceLabel + ' - ' + formattedDate,
              value: x.id,
              date: dateForOrdre,
              verrouille: x.verrouille,
            });
          });
          callback();
        });
      },
      (callback) => {
        this.api.getGlobalTypesSeances(this.infosForm.value.typeSeanceId).subscribe((data: any) => {
          if (data[0].defautObjetTypeId) {
            const index = this.globalElements.types_points_LV.map((x) => x.value).indexOf(data[0].defautObjetTypeId);
            if (index > -1) {
              this.infosForm.patchValue({ typeId: data[0].defautObjetTypeId });
              if (data[0].objetTypeNonModifiable) {
                this.avoidChangeObjetType = true;
                this.avoidChangeObjetTypeLabel = this.globalElements.types_points_LV[index].label;
              } else {
                this.avoidChangeObjetType = false;
              }
            }
            callback();
          } else {
            this.avoidChangeObjetType = false;
            this.infosForm.patchValue({ typeId: null });
            callback();
          }
        });
      },
    ]);
  }

  displaySeanceSelected() {
    this.seanceSelectedInfos = this.seances.find((x) => x.value === this.infosForm.value.seanceId);
  }

  clearTypeSeance() {
    this.infosForm.patchValue({ seanceId: null });
    this.seanceSelectedInfos = null;
  }

  invalidFormToastr() {
    if (!this.infosForm.controls['seanceId'].valid) {
      this.toastr.error(this.translate.instant('error.emptySeance'));
    }
    if (!this.infosForm.controls['statutId'].valid) {
      this.toastr.error(this.translate.instant('error.emptyStatus'));
    }
    if (!this.infosForm.controls['objetSynthese'].valid) {
      this.toastr.error(this.translate.instant('error.emptyTitle'));
    }
    if (!this.infosForm.controls['typeSeanceId'].valid) {
      this.toastr.error(this.translate.instant('error.emptySeanceType'));
    }
    if (!this.infosForm.controls['datePassageSouhaite'].valid) {
      this.toastr.error(this.translate.instant('error.emptyDatePassageSouhaite'));
    }
    if (!this.infosForm.controls['serviceId'].valid) {
      this.toastr.error(this.translate.instant('error.emptyDepartment'));
    }
    if (
      this.infosForm.controls['echevinatId'].errors?.required === true &&
      !this.infosForm.controls['serviceId'].valid
    ) {
      this.toastr.error(this.translate.instant('error.emptyAldermansAdminDepartment'));
    }
    if (
      this.infosForm.controls['classementId'].errors?.required === true &&
      !this.infosForm.controls['classementId'].valid
    ) {
      this.toastr.error(this.translate.instant('error.emptyClassement'));
    }
    if (this.infosForm.controls['matiereId'].errors?.required === true && !this.infosForm.controls['matiereId'].valid) {
      this.toastr.error(this.translate.instant('error.emptyMatter'));
    }
    if (this.infosForm.controls['origine'].errors?.required === true && !this.infosForm.controls['origine'].valid) {
      this.toastr.error(this.translate.instant('error.emptyOrigin'));
    }
    if (
      this.infosForm.controls['typeDecisionId'].errors?.required === true &&
      !this.infosForm.controls['typeDecisionId'].valid
    ) {
      this.toastr.error(this.translate.instant('error.emptyTypeDecision'));
    }
    if (
      this.infosForm.controls['urgenceNiveauId'].errors?.required === true &&
      !this.infosForm.controls['urgenceNiveauId'].valid
    ) {
      this.toastr.error(this.translate.instant('error.emptyEmergency'));
    }
  }

  public leave() {
    this.router.navigateByUrl('/private/points/detail/' + this.pointId);
  }

  public getTooltipContent() {
    const data = this.translate.instant('ifNotPDFThenMainFile');
    return data;
  }

  //* Will set the required state of annexeNumero if the file's set as an annex
  public changeTypeOfFileInModification(event) {
    if (event.checked === true) {
      this.formFilesModification.controls['annexeNumero'].setValidators([Validators.required]);
      this.formFilesModification.controls['annexeNumero'].updateValueAndValidity();
    } else {
      this.formFilesModification.controls['annexeNumero'].clearValidators();
      this.formFilesModification.controls['annexeNumero'].updateValueAndValidity();
    }
  }

  //* Allows or don't allow user to change a file for it to become an annex
  public isAnnexReadonly() {
    const ext = this.selectedRow?.extension.toLowerCase();
    if (ext !== 'pdf') {
      return true;
    } else {
      return false;
    }
  }

  getTrads() {
    return new Promise((resolve) => {
      const arrayOfKeys = this.buttons.map((x) => x.text);
      this.itemsTabs.map((x) => arrayOfKeys.push(x.label));
      this.translate.get(arrayOfKeys).subscribe((translations) => {
        this.buttons.map((x) => {
          x.text = translations[x.text];
        });
        this.itemsTabs.map((x) => {
          x.label = translations[x.label];
        });
        resolve(true);
      });
    });
  }

  onClickRadioButtonHandler(event) {
    if (this.handlerType === 'users') {
      this.infosForm.patchValue({ agentTraitantId: null });
    } else if (this.handlerType === 'contacts') {
      this.infosForm.patchValue({ agentTraitantIdiAdmin: null });
    }
  }

  /**
   * * GET icourrier data to patch form
   */
  getCourrierIDIfExists() {
    return new Promise((resolve, reject) => {
      let courrier = null;
      if (localStorage.getItem('iDelibe_icourrier_id_send') !== null) {
        this.icourrierID = +localStorage.getItem('iDelibe_icourrier_id_send');
        async.waterfall(
          [
            (cb) => {
              this.api.getIcourrierByIdData(this.icourrierID).subscribe(
                (res: any) => {
                  courrier = res;
                  this.infosForm.patchValue({
                    courrierId: res.id,
                    serviceId: res.serviceProprietaireId ? res.serviceProprietaireId : null,
                    objetSynthese: res.objet,
                  });
                  localStorage.removeItem('iDelibe_icourrier_id_send');
                  cb(null);
                },
                (error) => {
                  localStorage.removeItem('iDelibe_icourrier_id_send');
                  this.toastr.error('La récupération des infos du courrier a échoué');
                }
              );
            },
            (cb) => {
              this.infosForm.patchValue({
                classementId: courrier.classementId,
              });
              this.changeCode().then(() => {
                cb(null);
              });
            },
            (cb) => {
              this.infosForm.patchValue({
                folder: courrier.classeurId,
              });
              this.changeFolder().then(() => {
                cb(null);
              });
            },
            (cb) => {
              this.infosForm.patchValue({
                sleeve: courrier.chemiseId,
              });
              cb(null);
            },
            (cb) => {
              if (localStorage.getItem('iDelibe_duplicateFileFromiCourrier') === 'true') {
                this.api.getIcourrierFiles(this.icourrierID).subscribe(
                  (res: any) => {
                    this.itemFiles = res;
                    this.itemFiles.map((x) => {
                      x.import = true;
                    });
                    localStorage.removeItem('iDelibe_duplicateFileFromiCourrier');
                    cb();
                  },
                  (error) => {
                    localStorage.removeItem('iDelibe_duplicateFileFromiCourrier');
                  }
                );
              } else {
                cb();
              }
            },
          ],
          () => {
            resolve(true);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  checkUserRightsToSeeGlobalElementsBasedOnGroupeSecurity() {
    return new Promise((resolve, reject) => {
      if (
        this.groupeSecuriteRightsPoints.changerMatiereAllowedIds &&
        this.groupeSecuriteRightsPoints.changerMatiereAllowedIds.length > 0
      ) {
        this.listOfMatieres = this.globalElements.matieres_LV.filter((x) =>
          this.groupeSecuriteRightsPoints.changerMatiereAllowedIds.includes(x.value)
        );
      }

      if (
        this.groupeSecuriteRightsPoints.changerStatutAllowedIds &&
        this.groupeSecuriteRightsPoints.changerStatutAllowedIds.length > 0
      ) {
        this.listOfStatuts = this.globalElements.statuts_points_LV.filter((x) =>
          this.groupeSecuriteRightsPoints.changerStatutAllowedIds.includes(x.value)
        );
      }

      if (
        this.groupeSecuriteRightsPoints.changerTypePointAllowedIds &&
        this.groupeSecuriteRightsPoints.changerTypePointAllowedIds.length > 0
      ) {
        this.listOfTypePoint = this.globalElements.types_points_LV.filter((x) =>
          this.groupeSecuriteRightsPoints.changerTypePointAllowedIds.includes(x.value)
        );
      }

      if (
        this.groupeSecuriteRightsPoints.changerEchevinatAllowedIds &&
        this.groupeSecuriteRightsPoints.changerEchevinatAllowedIds.length > 0
      ) {
        this.listOfEchevinatsRights = this.globalElements.echevinats_LV.filter((x) =>
          this.groupeSecuriteRightsPoints.changerEchevinatAllowedIds.includes(x.value)
        );
      }

      resolve(true);
    });
  }

  changeMatter(event) {
    if (event.value) {
      const currentMatter = this.globalElements.matieres_LV.find((x) => x.value === event.value);
      if (currentMatter) {
        this.listOfEchevinats = [];
        if (currentMatter.item.echevinats.length > 0) {
          currentMatter.item.echevinats.map((x) => {
            const index = this.listOfEchevinatsRights.map((x) => x.value).indexOf(x);
            if (index > -1) {
              this.listOfEchevinats.push(this.listOfEchevinatsRights[index]);
            }
          });
          if (this.listOfEchevinats.length === 1) {
            this.infosForm.patchValue({
              echevinatId: this.listOfEchevinats[0].value,
            });
          } else {
            this.infosForm.patchValue({
              echevinatId: null,
            });
          }
        } else {
          this.infosForm.patchValue({
            echevinatId: null,
          });
        }
      }
    } else {
      this.infosForm.patchValue({
        echevinatId: null,
      });
    }
  }
}
