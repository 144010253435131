import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IModalHeader } from '@devinforius/super-compos/lib/i-modal/models/i-modal-header.model';
import { IModalParams } from '@devinforius/super-compos/lib/i-modal/models/i-modal-parameters.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { Point } from 'src/app/shared/models/point';
import { ActionType, CategoryActions } from 'src/app/shared/models/point/category-actions.model';
import { MeRightPoint } from 'src/app/shared/models/point/me-right-point.model';
import { TenantInfo } from 'src/app/shared/models/tenant-info';

@Component({
  selector: 'app-actions-point',
  templateUrl: './actions-point.component.html',
  styleUrls: ['./actions-point.component.scss'],
})
export class ActionsPointComponent implements OnInit {
  // Inuput
  @Input() public point: Point;
  @Input() public canOpenPoint: boolean = true;

  // Output
  @Output() public action: EventEmitter<any> = new EventEmitter();

  // Variables
  categoryActions: CategoryActions[] = [];
  meRightPoint: MeRightPoint;
  tenantICourrier: boolean = false;
  canCreateObjectModel: boolean = false;
  modalParams: IModalParams;
  modalHeader: IModalHeader;

  constructor(
    public api: ApiService,
    public apiAdmin: iAdminService,
    public toastr: ToastrService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.setModalConfiguration();
    forkJoin({
      rights: this.getRights(),
      tenant: this.getTenant(),
      rightAdmin: this.getRightAdmin(),
    }).subscribe({
      next: () => {
        this.setAction();
      },
      error: (error) => {
        console.error('Error in one of the observables:', error);
      },
    });
  }

  setModalConfiguration(): void {
    this.modalHeader = {
      title: this.translate.instant('action'),
      iconTitle: 'bi bi-lightning-charge-fill',
      showNotification: false,
      showParameters: false,
      closable: true,
    };
    this.modalParams = {
      isDraggable: true,
      isResizable: true,
      isModal: true,
      style: { width: '30vw', minWidth: '30vw', maxWidth: '30vw' },
    };
  }

  getRights(): Observable<boolean> {
    return this.api.getRightsPoint(this.point.id).pipe(
      catchError((error) => {
        console.error('Error fetching right info:', error);
        this.toastr.error(this.translate.instant('error.retrievingRightPoint'));
        return of(null);
      }),
      map((res: MeRightPoint) => {
        this.meRightPoint = res;
        return true;
      })
    );
  }

  getTenant(): Observable<boolean> {
    return this.apiAdmin.getTenant().pipe(
      catchError((error) => {
        console.error('Error fetching tenant info:', error);
        this.toastr.error(this.translate.instant('error.retrievingTenantInfo'));
        return of(null);
      }),
      map((res: TenantInfo) => {
        res.applications.some((app) => app.id === 5) ? (this.tenantICourrier = true) : (this.tenantICourrier = false);
        return true;
      })
    );
  }

  getRightAdmin(): Observable<boolean> {
    return forkJoin({
      rightManagement: this.api.getRightsManagement().pipe(
        catchError((error) => {
          console.error('Error fetching rights management:', error);
          this.toastr.error(this.translate.instant('error.retrievingDelibeRightsInfo'));
          return of(null);
        })
      ),
      adminRights: this.apiAdmin.getRightsManagement().pipe(
        catchError((error) => {
          console.error('Error fetching admin rights management:', error);
          this.toastr.error(this.translate.instant('error.retrievingAdminRightsInfo'));
          return of(null);
        })
      ),
    }).pipe(
      map(({ rightManagement, adminRights }) => {
        const modelObject = rightManagement?.menu?.modeleObjets || false;
        const admin =
          adminRights?.menu?.delibeRight?.gestionBibliotheque ||
          adminRights?.menu?.delibeRight?.gestionBibliothequePrive ||
          false;
        this.canCreateObjectModel = modelObject && admin;
        return true;
      })
    );
  }

  setAction(): void {
    this.categoryActions = [
      {
        label: this.translate.instant('gestion'),
        actions: [
          {
            label: this.translate.instant('btn.openPoint'),
            img: './assets/img/open.svg',
            action: () => this.handleClick(ActionType.OpenPoint, this.point),
            condition: this.canOpenPoint,
          },
          {
            label: this.translate.instant('btn.modify'),
            img: './assets/img/edit.svg',
            action: () => this.handleClick(ActionType.UpdatePoint, this.point),
            condition: this.meRightPoint.edit,
          },
          {
            label: this.translate.instant('btn.duplicate'),
            img: './assets/img/duplicate.svg',
            action: () => this.handleClick(ActionType.DuplicatePoint, true),
            condition: this.meRightPoint.duplicate,
          },
          {
            label: this.translate.instant('report'),
            img: './assets/img/postpone.svg',
            action: () => this.handleClick(ActionType.PostponePoint, true),
            condition: this.meRightPoint.report,
          },
          {
            label: this.translate.instant('createPointModel'),
            icon: 'bi bi-plus-circle-fill',
            action: () => this.handleClick(ActionType.OpenCreationModelPointBasedOnExistingPoint, true),
            condition: this.canCreateObjectModel,
          },
          {
            label: this.translate.instant('createOutgoingMail'),
            img: './assets/img/iCourrier.png',
            action: () => this.handleClick(ActionType.CreateOutgoingMail, this.point),
            condition: this.tenantICourrier,
            isIconClass: true,
          },
          {
            label: this.translate.instant('btn.removeFromMeeting'),
            img: './assets/img/delete.svg',
            action: () => this.handleClick(ActionType.DeletePoint, this.point),
            condition: this.meRightPoint.removeSeance,
          },
        ],
      },
      {
        label: this.translate.instant('title.pointInMeeting'),
        actions: [
          {
            label: this.translate.instant('up'),
            img: './assets/img/arrow-up.svg',
            action: () => this.handleClick(ActionType.Up, this.point),
            condition: this.meRightPoint.isGestionnaire,
          },
          {
            label: this.translate.instant('down'),
            img: './assets/img/arrow-down-new.svg',
            action: () => this.handleClick(ActionType.Down, this.point),
            condition: this.meRightPoint.isGestionnaire,
          },
          {
            label: this.translate.instant('addEventBefore'),
            img: './assets/img/event.svg',
            action: () => this.handleClick(ActionType.AddEventBefore, true),
            condition: this.meRightPoint.isGestionnaire,
          },
        ],
      },
      {
        label: this.translate.instant('title.pointMarking'),
        actions: [
          {
            label: this.translate.instant('global.closed'),
            img: './assets/img/closed.svg',
            action: () => this.handleClick(ActionType.FinishUnfinishPoint, this.point),
            condition: this.meRightPoint.cloture,
          },
          {
            label: this.translate.instant('btn.opened'),
            img: './assets/img/unclosed.svg',
            action: () => this.handleClick(ActionType.FinishUnfinishPoint, this.point),
            condition: this.meRightPoint.decloture,
          },
          {
            label: this.translate.instant('btn.unlocked'),
            img: './assets/img/unlocked.svg',
            action: () => this.handleClick(ActionType.LockUnlock, this.point),
            condition: this.meRightPoint.deverrouille && this.meRightPoint.isGestionnaire,
          },
          {
            label: this.translate.instant('btn.locked'),
            img: './assets/img/locked.svg',
            action: () => this.handleClick(ActionType.LockUnlock, this.point),
            condition: !this.meRightPoint.verrouille && this.meRightPoint.isGestionnaire,
          },
          {
            label: this.translate.instant('btn.toModify'),
            img: './assets/img/tofix.svg',
            action: () => this.handleClick(ActionType.MarkACorriger, this.point),
            condition: this.meRightPoint.corrections,
          },
          {
            label: this.translate.instant('btn.validateTheCorrection'),
            img: './assets/img/fixed.svg',
            action: () => this.handleClick(ActionType.ValidateCorrection, this.point),
            condition: this.meRightPoint.validerCorrections,
          },
          {
            label: this.translate.instant('btn.unanimouslyApproved'),
            img: './assets/img/approved.svg',
            action: () => this.handleClick(ActionType.ApproveUnanimously, this.point),
            condition: this.meRightPoint.isGestionnaire,
          },
        ],
      },
    ];

    // Filtrer les catégories d'actions pour ne pas afficher celles dont toutes les conditions sont fausses
    this.categoryActions = this.categoryActions.filter((category) =>
      category.actions.some((action) => action.condition)
    );
  }

  handleClick(action: any, dataToSend: any): void {
    this.action.emit({ action, dataToSend });
  }

  closeDialog(): void {
    this.handleClick(ActionType.CloseModal, false);
  }
}
