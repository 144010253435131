import { Participant } from './participant';

export class TypeSeance {
  description: string;
  label?: string;
  defautObjetTypeId: number;
  defautObjetTypeLabel: string;
  objetTypeNonModifiable: any = false;
  couleurTexteCourrier: string;
  seanceLieuId: number;
  droitsEncodageId: number;
  droitsEncodageLabel: string;
  droitsGestionId: number;
  droitsGestionLabel: string;
  serviceProprietaireId: number;
  serviceProprietaireLabel: string;
  typeVoteId: number;
  typeVoteLabel: string;
  participants: Array<Participant> = [];
  id: number;
  value?: number;
  modeleExportWebId: any;
  participantsWebSupplementaires: Array<number> = [];
  typesPoints: Array<any> = [];
  constructor(data: any, groupes?, services?, objets?, votes?, signatures?, groupes_pol?, roles?) {
    Object.assign(this, data);
    this.label = this.description;
    this.value = this.id;
    if (groupes && groupes.length > 0) {
      if (this.droitsEncodageId) {
        this.droitsEncodageLabel = groupes.find((x) => x.id === this.droitsEncodageId).description;
      }
      if (this.droitsGestionId) {
        this.droitsGestionLabel = groupes.find((x) => x.id === this.droitsGestionId).description;
      }
    }
    if (services && services.length > 0) {
      if (this.serviceProprietaireId) {
        this.serviceProprietaireLabel = services.find((x) => x.id === this.serviceProprietaireId).description;
      }
    }
    if (objets && objets.length > 0) {
      if (this.defautObjetTypeId) {
        this.defautObjetTypeLabel = objets.find((x) => x.id === this.defautObjetTypeId).description;
      }
    }
    if (votes && votes.length > 0) {
      if (this.typeVoteId) {
        this.typeVoteLabel = votes.find((x) => x.value === this.typeVoteId).label;
      }
    }
    if (this.participants && this.participants.length > 0) {
      this.participants.map((item) => {
        item.contactLabel = groupes.find((x) => x.id === item.contactId).description;
        item.typeSeance = this.description;
        item.groupePolitiqueLabel = groupes_pol.find((x) => x.id === item.groupePolitiqueId).description;
        item.signatureLabel = signatures.find((x) => x.id === item.signatureId).description;
        item.role = roles.find((x) => x.id === item.roleId).description;
      });
    }

    if (this.typesPoints && this.typesPoints.length > 0) {
      this.typesPoints.map((item) => {
        item.typeSeance = objets.find((x) => x.id === item.objetTypeId).description;
      });
    }
  }
}
