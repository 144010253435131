<section class="home">
    <div class="d-flex flex-column justify-content-center" style="height: 80vh;">
      <div class="d-flex justify-content-center">
          <img src="../../../assets/img/iDelibe.svg" style="width: 250px;"/>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center">
          <div>
              <h1 class="mt-5 text-center mb-0 homeTitle">iDélibé</h1>
              <h2 class="text-center mb-3 homeSubtitle"></h2>
              <p class="mt-4 text-center mx-auto w-100 homeText">Bonjour,<br>
                Pour des questions de sécurité, vous allez être redirigé vers notre plateforme d'authentification afin de vous connecter à l'application.<br>
                Si vous ne voulez pas attendre la redirection ou si celle-ci ne fonctionne pas, veuillez cliquer sur le bouton ci-dessous.</p>
          </div>
          <div class="mt-5">
              <p>Assistance Inforius - <a href="tel:081744340">081/744 340</a> - <a href="mailto:infodoc@inforius.be">infodoc@inforius.be</a></p>
              <super-button styleClass="green d-block m-auto" text="Me connecter à la plateforme" (click)="login()">Connexion</super-button>
          </div>
      </div>
    </div>
</section>

<!-- <section class="login__container">
	<div class="login__left">
		<div class="login__cards">
			<div class="white-text">
				<img src="./assets/img/iDelibe.svg" class="h-auto d-block m-auto" width="150">
				<h1 class="text-white">Connexion à <b>iDelibe</b></h1>
			</div>
				<section class="login__form">
					<div class="login__content" id="connexion">
						<div class="d-flex justify-content-center">
							<super-button text="Me connecter à la plateforme" styleClass="green mt-4 d-block"  (click)="login()">Connexion</super-button>
						</div>
						<p class="mt-4">Assistance Inforius - <a href="tel:081744340">081/744 340</a> - <a href="mailto:infodoc@inforius.be">infodoc@inforius.be</a></p>
						<div align="center" class="mt-5">
							<a href="http://www.inforius.be/" target="_blank" rel="noopener noreferrer" ><img src='./assets/img/inforius.png' style="width: 200px; height: auto;"></a>
						</div>
					</div>
				</section>
		</div>
	</div>
	<div class="login__right">
		<img src="./assets/img/login.svg" width="800" height="800">
	</div>
</section> -->