import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import * as async from 'async';
import * as moment from 'moment';
import { File as CustomFile } from 'src/app/shared/models/files';
import { SuperColumn, SuperTableComponent } from '@devinforius/super-compos';
import { Global_Class } from '../../models/global';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements OnInit {
  @ViewChild('superTableFiles') public superTableFiles: SuperTableComponent;
  @Input() set rights(value) {
    this.fileRights = value;
    if (value && value.files.edit) {
      this.filesMenu = [
        {
          label: this.translate.instant('btn.modify'),
          command: (item) => {
            window.setTimeout(() => {
              this.openModification = true;
              const index = this.files.map((x) => x.id).indexOf(this.fileClicked.id);
              this.selectedFile = this.files[index];
              this.formFilesModification.patchValue({
                id: this.selectedFile.id,
                description: this.selectedFile.description,
                annexeNumero: this.selectedFile.annexeNumero,
                OrdreAffichage: this.selectedFile.OrdreAffichage,
                annexe:
                  this.selectedFile.extension === 'pdf' && !this.selectedFile.modeleId
                    ? this.selectedFile.annexeNumero
                      ? true
                      : false
                    : false,
                visibleDelibeWeb: this.selectedFile.visibleDelibeWeb,
                visibleDelibeWebCitoyen: this.selectedFile.visibleDelibeWebCitoyen,
              });
            }, 500);
          },
        },
      ];
    } else {
      this.filesMenu = [];
    }
  } //* the rights to add/delete,... files
  public fileRights;
  @Input() files; //* the files +> Will be given from the parent component
  @Input() pointId; //* the id of the point
  @Input() meeting = null; //* the meeting of the parent component (may be null if coming form a point)
  @Input() point = null; //* the point of the parent component (may be null if coming form a meeting)
  @Input() hasLoaded;
  @Input() createUpdatePoint: boolean = false;
  @Output() emitSuccessUpload: EventEmitter<any> = new EventEmitter();

  public fileUploadChooseLabel; //* labels displayed on the primeNG component fileUpload
  public fileUploadUploadLabel; //* labels displayed on the primeNG component fileUpload
  public fileUploadCancelLabel; //* labels displayed on the primeNG component fileUpload

  public count = 0; //* Will be used to have a count for the amount of files uploaded
  public globalElement: Global_Class;

  public columnsFiles: Array<SuperColumn> = [];
  public actions: Array<any> = []; //* forselection list of actions
  public filesMenu: Array<any> = []; //* list of actions to be done with the blue table row menu ...

  public openModification: boolean = false; //* boolean used to display or hide the modification modal
  public fileClicked = null; //* The file on which the used clicked !== from 'selectedFile' to avoid crash
  public selectedFile = null; //* the file of the selected row !== from 'fileClicked' to avoid crash
  public formFilesModification: FormGroup = new FormGroup({
    id: new FormControl(null),
    description: new FormControl(null, Validators.required),
    annexeNumero: new FormControl(),
    annexe: new FormControl(),
    OrdreAffichage: new FormControl(),
    visibleDelibeWeb: new FormControl(),
    visibleDelibeWebCitoyen: new FormControl(),
  });
  public blobViewer: any;
  public modalPdfViewer: boolean = false;
  public currentPage: number = 1;
  public confirmDialogBool: boolean = false;
  public itemsUploaded: any;
  public uploader: any;
  public globalConfig = JSON.parse(sessionStorage.getItem('globalConfig'));
  public notifyUserSubscription: Subscription;
  public currentFileToOpen: any;
  public uploadedFilesFromDragAndDrop: File[] = [];
  public isFileOverComponent: boolean = false;
  maxFileSize: number = 104857600; // 100Mo
  MaxFilesSize: number = 314572800; // 300Mo

  constructor(
    private translate: TranslateService,
    public routing: Router,
    private spinner: NgxSpinnerService,
    public api: ApiService,
    private toastr: ToastrService,
    private dataService: DataService,
    public confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.dataService.getGlobalElements.subscribe((res: any) => {
      this.globalElement = new Global_Class(res);
      if (this.globalElement.isLoaded()) {
        this.getTrads();
      }
    });
  }

  //* method that will get the translations
  getTrads() {
    this.fileUploadChooseLabel = this.translate.instant('btn.choose');
    this.fileUploadUploadLabel = this.translate.instant('btn.upload');
    this.fileUploadCancelLabel = this.translate.instant('btn.cancel');
    this.columnsFiles = [
      {
        field: 'description',
        header: this.translate.instant('description'),
        format: (value, item) => {
          if (item && item.description) {
            return item.description;
          } else {
            return item.name;
          }
        },
      },
      {
        field: 'extension',
        header: this.translate.instant('extension'),
      },
      {
        field: 'version',
        header: 'version',
      },
      {
        field: 'apercu',
        header: this.translate.instant('preview'),
        format: (value, item) => {
          if (item.extension.toLowerCase() === 'pdf') {
            return '<i class="fas fa-eye"></i>';
          }
        },
        click: (value) => {
          if (value.extension.toLowerCase() === 'pdf') {
            this.openPreviewFile(value);
          }
        },
      },
      {
        field: 'fileType',
        header: this.translate.instant('fileType'),
        format: (value, item) => {
          if (item.modeleId && item.extension.toLowerCase() === 'pdf') {
            return this.translate.instant('document');
          } else if (item.annexeNumero) {
            return this.translate.instant('annexN°') + ' ' + item.annexeNumero;
          } else {
            return this.translate.instant('associatedFile');
          }
        },
      },
      {
        field: 'ordreAffichage',
        header: this.translate.instant('order'),
      },
      {
        field: 'dateUpload',
        header: this.translate.instant('addDateOrGenerationDate'),
        format: (value) => {
          const date = moment(value).isValid() ? moment(value).format('DD/MM/YYYY HH:mm') : '';
          return date;
        },
      },
    ];
    this.getRights();
  }

  //* Will the the user's rights to do actions on the files
  getRights() {
    if (this.globalElement.configApp.delibConfig.isiDelibeMandatairesEnabled) {
      this.actions.push(
        {
          label: this.translate.instant('makeVisibleForRepresentative'),
          tooltip: this.translate.instant('makeVisibleForRepresentative'),
          click: () => {
            this.spinner.show();
            const data = [];
            this.superTableFiles._selection.map((file) => {
              const temp = this.files.find((x) => x.id === file.id);
              temp.fileId = file.id;
              temp.visibleDelibeWeb = true;
              data.push(temp);
            });
            if (this.meeting) {
              this.api.patchSeancesFile(this.meeting.id, data).subscribe((data) => {
                this.toastr.success(this.translate.instant('success.modifiedFiles'));
                this.emitSuccessUpload.emit(true);
              });
            } else if (this.point) {
              this.api.patchPointsFile(this.point.id, data).subscribe((data) => {
                this.toastr.success(this.translate.instant('success.modifiedFiles'));
                this.emitSuccessUpload.emit(true);
              });
            }
          },
        },
        {
          label: this.translate.instant('makeInvisibleForRepresentative'),
          tooltip: this.translate.instant('makeInvisibleForRepresentative'),
          click: () => {
            this.spinner.show();
            const data = [];
            this.superTableFiles._selection.map((file) => {
              const temp = this.files.find((x) => x.id === file.id);
              temp.fileId = file.id;
              temp.visibleDelibeWeb = false;
              data.push(temp);
            });
            if (this.meeting) {
              this.api.patchSeancesFile(this.meeting.id, data).subscribe((data) => {
                this.toastr.success(this.translate.instant('success.modifiedFiles'));
                this.emitSuccessUpload.emit(true);
              });
            } else if (this.point) {
              this.api.patchPointsFile(this.point.id, data).subscribe((data) => {
                this.toastr.success(this.translate.instant('success.modifiedFiles'));
                this.emitSuccessUpload.emit(true);
              });
            }
          },
        }
      );
      this.columnsFiles.push({
        field: 'visibleDelibeWeb',
        header: this.translate.instant('webVisibility') + ' ' + this.translate.instant('webVisibility.mandataire'),
        format: (value) => {
          if (value) {
            return `<b style="color: #07E299;"><i class="bi bi-check-circle-fill"></i></b>`;
          } else {
            return `<b style="color: #FF5671;"><i class="bi bi-x-circle-fill"></i></b>`;
          }
        },
      });
    }
    if (this.globalElement.configApp.delibConfig.isiDelibeCitoyensEnabled) {
      this.actions.push(
        {
          label: this.translate.instant('makeVisibleForCitizen'),
          tooltip: this.translate.instant('makeVisibleForCitizen'),
          click: () => {
            this.spinner.show();
            const data = [];
            this.superTableFiles._selection.map((file) => {
              const temp = this.files.find((x) => x.id === file.id);
              temp.fileId = file.id;
              temp.visibleDelibeWebCitoyen = true;
              data.push(temp);
            });
            if (this.meeting) {
              this.api.patchSeancesFile(this.meeting.id, data).subscribe((data) => {
                this.toastr.success(this.translate.instant('success.modifiedFiles'));
                this.emitSuccessUpload.emit(true);
              });
            } else if (this.point) {
              this.api.patchPointsFile(this.point.id, data).subscribe((data) => {
                this.toastr.success(this.translate.instant('success.modifiedFiles'));
                this.emitSuccessUpload.emit(true);
              });
            }
          },
        },
        {
          label: this.translate.instant('makeInvisibleForCitizen'),
          tooltip: this.translate.instant('makeInvisibleForCitizen'),

          //* triggers when the user deletes files
          click: () => {
            this.spinner.show();
            const data = [];
            this.superTableFiles._selection.map((file) => {
              const temp = this.files.find((x) => x.id === file.id);
              temp.fileId = file.id;
              temp.visibleDelibeWebCitoyen = false;
              data.push(temp);
            });
            if (this.meeting) {
              this.api.patchSeancesFile(this.meeting.id, data).subscribe((data) => {
                this.toastr.success(this.translate.instant('success.modifiedFiles'));
                this.emitSuccessUpload.emit(true);
              });
            } else if (this.point) {
              this.api.patchPointsFile(this.point.id, data).subscribe((data) => {
                this.toastr.success(this.translate.instant('success.modifiedFiles'));
                this.emitSuccessUpload.emit(true);
              });
            }
          },
        }
      );
      this.columnsFiles.push({
        field: 'visibleDelibeWebCitoyen',
        header: this.translate.instant('webVisibility') + ' ' + this.translate.instant('webVisibility.citoyen'),
        format: (value) => {
          if (value) {
            return '<b style="color: #07E299;"><i class="bi bi-check-circle-fill"></i></b>';
          } else {
            return `<b style="color: #FF5671;"><i class="bi bi-x-circle-fill"></i></b>`;
          }
        },
      });
    }
  }

  //* Will trigger on click of the ... row button
  receive_menu_Files(event) {
    this.fileClicked = new CustomFile(event);
  }

  //* Will verify if the selected file is a PDF or not for it to be considered as an annex
  public isAnnexReadonly() {
    const ext = this.fileClicked?.extension.toLowerCase();
    if (ext !== 'pdf') {
      return true;
    } else {
      return false;
    }
  }

  //* triggers when the user uploads files
  onUpload(event, uploader) {
    const isAnnexeFile: boolean = false;
    async.waterfall([
      (cb) => {
        this.confirmDialogBool = true;
        cb(null);
      },
      (cb) => {
        this.itemsUploaded = event.files;
        this.uploader = uploader;
      },
    ]);
  }

  addFileAnnexe(isAnnexeFile) {
    async.waterfall([
      (cb) => {
        this.spinner.show();
        this.count = 0;
        const totalItems = this.itemsUploaded.length;
        for (const file of this.itemsUploaded) {
          file.description = file.name;
          file.extension = file.name.split('.').pop();
          file.date = moment(file.lastModifiedDate, 'DD/MM/YYYY');
          file.pointId = this.pointId;
          const isPDF = file.extension.toLowerCase() === 'pdf' ? true : false;
          const document = {
            description: file.name,
            version: 1,
          };
          if (this.meeting) {
            this.api
              .addSeanceFiles(this.meeting.id, document, file, file.extension, isPDF ? isAnnexeFile : false)
              .subscribe(
                (response) => {
                  this.files.push(new CustomFile(response));
                  this.count++;
                  if (this.count === totalItems) {
                    cb(null);
                  }
                },
                (error) => {
                  console.error(error);
                  this.spinner.hide();
                  this.toastr.error("Une erreur est survenue lors de l'ajout du fichier : " + file.description);
                }
              );
          } else if (this.point) {
            this.api.addPointFiles(this.point.id, document, file, file.extension, isAnnexeFile).subscribe(
              (response) => {
                this.files.push(new CustomFile(response));
                this.count++;
                if (this.count === totalItems) {
                  cb(null);
                }
              },
              (error) => {
                console.error(error);
                this.spinner.hide();
                this.toastr.error("Une erreur est survenue lors de l'ajout du fichier : " + file.description);
              }
            );
          }
        }
      },
      (cb) => {
        this.itemsUploaded = null;
        this.toastr.success('Merci', 'Les fichiers ont été enregistrés!');
        this.spinner.hide();
        this.uploader.clear();
        this.emitSuccessUpload.emit(true);
        this.confirmDialogBool = false;
        cb(null);
      },
    ]);
  }

  public deleteSelectionFiles(items) {
    this.spinner.show();
    let count = 0;
    async.waterfall([
      (cb) => {
        if (items && items.length > 0) {
          items.map((item) => {
            if (this.meeting) {
              this.api.deleteSeanceFile(this.meeting.id, item.id).subscribe((res: any) => {
                this.toastr.success(this.translate.instant('success.deletedPointFile', { item: item.description }));
                count = count + 1;
                if (count === items.length) {
                  cb(null);
                }
              });
            } else if (this.pointId) {
              this.api.deletePointFile(this.pointId, item.id).subscribe((res: any) => {
                this.toastr.success(this.translate.instant('success.deletedPointFile', { item: item.description }));
                count = count + 1;
                if (count === items.length) {
                  cb(null);
                }
              });
            }
          });
        }
      },
      (cb) => {
        this.spinner.hide();
        this.emitSuccessUpload.emit(true);
      },
    ]);
  }

  //* Triggers when the user download a file (on the row click of a file)
  downloadFile(event) {
    if (this.fileRights.files.open) {
      if (this.meeting) {
        this.api.downloadSeanceFile(this.meeting.id, event.id).subscribe((res: any) => {
          const downloadURL = URL.createObjectURL(res);
          if ((event.description || event.name) && event.extension) {
            saveAs(downloadURL, (event.description ? event.description : event.name) + '.' + event.extension);
          } else {
            this.toastr.error("Impossible de sauver le fichier, il n'y a pas de nom ou d'extension");
          }
        });
      } else if (this.point) {
        this.api.downloadPointFile(this.point.id, event.id).subscribe((res: any) => {
          const downloadURL = URL.createObjectURL(res);
          if ((event.description || event.name) && event.extension) {
            saveAs(downloadURL, (event.description ? event.description : event.name) + '.' + event.extension);
          } else {
            this.toastr.error("Impossible de sauver le fichier, il n'y a pas de nom ou d'extension");
          }
        });
      }
    }
  }

  //* triggers when the user confirms the modification of a file (inside the modal used for)
  submitModificationFile() {
    if (this.formFilesModification.valid) {
      let indexOfAnnexIdentique;
      let annexDoesntAlreadyExistInFiles = false; //le flag va permettre de déterminer si l'annexe possède un numéro identique a une autre existante
      const allPointFiles = this.files.filter((x) => x.id !== this.formFilesModification.value.id);
      if (this.formFilesModification.value.annexeNumero) {
        const allAnnexNumbers = allPointFiles.map((x) => {
          return x.annexeNumero;
        });
        indexOfAnnexIdentique = allAnnexNumbers.indexOf(this.formFilesModification.value.annexeNumero);
        if (indexOfAnnexIdentique > -1) {
          annexDoesntAlreadyExistInFiles = false;
        } else {
          //si c'est === -1 alors on a pas d'intersection et donc pas d'annexe avec le même numéro
          annexDoesntAlreadyExistInFiles = true;
        }
      } else {
        annexDoesntAlreadyExistInFiles = true; //Pas de n° d'annexe donc c'est ok
      }

      if (annexDoesntAlreadyExistInFiles) {
        const data = this.formFilesModification.value;

        if (!data.annexe) {
          data.annexeNumero = null;
        }

        if (!this.globalElement.configApp.delibConfig.isiDelibeMandatairesEnabled) {
          delete data.visibleDelibeWeb;
        }
        if (!this.globalElement.configApp.delibConfig.isiDelibeCitoyensEnabled) {
          delete data.visibleDelibeWebCitoyen;
        }
        if (this.meeting) {
          this.api
            .patchSeanceFile(this.meeting.id, this.formFilesModification.value.id, data)
            .subscribe((data: any) => {
              this.openModification = false;
              this.toastr.success(this.translate.instant('success.modifiedFile'));
              this.emitSuccessUpload.emit(true);
            });
        } else if (this.point) {
          this.api.patchPointFile(this.point.id, this.formFilesModification.value.id, data).subscribe((data: any) => {
            this.openModification = false;
            this.toastr.success(this.translate.instant('success.modifiedFile'));
            this.emitSuccessUpload.emit(true);
          });
        }
      } else {
        this.toastr.error(
          allPointFiles[indexOfAnnexIdentique].description + ' ' + this.translate.instant('error.sameAnnexNumber')
        );
      }
    } else {
      this.toastr.error(this.translate.instant('error.pleaseCompleteMandatoryFields'));
    }
  }

  //* Method that will retrieve the translation for the toolitp displayed
  public getTooltipContent() {
    const data = this.translate.instant('ifNotPDFThenAssociatedFile');
    return data;
  }

  //* Will set the required state of annexeNumero if the file's set as an annex
  public changeTypeOfFileInModification(event) {
    if (event.checked === true) {
      if (this.formFilesModification.controls['annexeNumero']) {
        this.formFilesModification.controls['annexeNumero'].setValidators([Validators.required]);
        this.formFilesModification.controls['annexeNumero'].updateValueAndValidity();
      }
    } else {
      this.formFilesModification.controls['annexeNumero'].clearValidators();
      this.formFilesModification.controls['annexeNumero'].updateValueAndValidity();
    }
  }

  openPreviewFile(item) {
    if (item && item.id) {
      this.api[this.meeting ? 'downloadSeanceFile' : 'downloadPointFile'](
        this.meeting ? this.meeting.id : this.point.id,
        item.id
      ).subscribe((res: any) => {
        this.blobViewer = URL.createObjectURL(res);
        this.modalPdfViewer = true;
      });
    }
  }

  openMenu(event) {
    this.filesMenu = [{}];
    if (event.modeleId !== null) {
      this.filesMenu = [
        {
          label: this.translate.instant('btn.modifyGenerated'),
          command: (item) => {
            this.currentFileToOpen = item;
            // localStorage.setItem('openFromFilesTable','true');
            if (this.meeting) {
              window.open(
                this.globalConfig.baseUrl +
                  '/private/editor/documentmodel/' +
                  this.currentFileToOpen.modeleId +
                  '/seanceId/' +
                  this.meeting.id.toString() +
                  '?generatedAndSaved=' +
                  true,
                '_blank'
              );
            } else if (this.point) {
              window.open(
                this.globalConfig.baseUrl +
                  '/private/editor/documentmodel/' +
                  this.currentFileToOpen.modeleId +
                  '/pointId/' +
                  this.point.id.toString() +
                  '?generatedAndSaved=' +
                  true,
                '_blank'
              );
            }
          },
        },
      ];
    } else {
      this.filesMenu = [
        {
          label: this.translate.instant('btn.modify'),
          command: (item) => {
            window.setTimeout(() => {
              this.openModification = true;
              const index = this.files.map((x) => x.id).indexOf(this.fileClicked.id);
              this.selectedFile = this.files[index];
              this.formFilesModification.patchValue({
                id: this.selectedFile.id,
                description: this.selectedFile.description,
                annexeNumero: this.selectedFile.annexeNumero,
                OrdreAffichage: this.selectedFile.OrdreAffichage,
                annexe:
                  this.selectedFile.extension === 'pdf' && !this.selectedFile.modeleId
                    ? this.selectedFile.annexeNumero
                      ? true
                      : false
                    : false,
                visibleDelibeWeb: this.selectedFile.visibleDelibeWeb,
                visibleDelibeWebCitoyen: this.selectedFile.visibleDelibeWebCitoyen,
              });
            }, 500);
          },
        },
      ];
    }
  }

  onFileDropped(isAnnexe: boolean, fileUpload: FileUpload, event: FileList | any): void {
    let size: number = 0;
    const files: File[] = [];
    if(event && event.currentFiles && event.currentFiles.length > 0) {
      event.currentFiles.forEach((f: File) => {
        if (f.size > this.maxFileSize) {
          const fileSizeInMo: string = (f.size / 1024 / 1024).toFixed(2);
          this.toastr.error(this.translate.instant('error.invalidFileSizeMessage3'), `Fichier trop grand: ${fileSizeInMo} Mo`);
          return;
        }
        files.push(f);
        size += f.size;
        this.isFileOverComponent = false;
      });
      fileUpload.clear();
    } else {
      const filesArray: File[] = Array.from(event);
      filesArray.forEach((f: File) => {
        if (f.size > this.maxFileSize) {
          const fileSizeInMo: string = (f.size / 1024 / 1024).toFixed(2);
          this.toastr.error(this.translate.instant('error.invalidFileSizeMessage3'), `Fichier trop grand: ${fileSizeInMo} Mo`);
          return;
        }
        files.push(f);
        size += f.size;
        this.isFileOverComponent = false;
      });
    }
    if (size > this.MaxFilesSize) {
      const weightInMo: string = (size / 1024 / 1024).toFixed(2);
      this.toastr.error(this.translate.instant('error.invalidFileSizeMessage3'), 'valeur total: ' + weightInMo + ' Mo');
    } else {
      this.uploadFile(files, isAnnexe);
    }
  }

  uploadFile(files: File[], isAnnexe: boolean): void {
    this.spinner.show();
    if (this.meeting) {
      this.api.addSeanceFilesMultiple(this.meeting.id, files, isAnnexe).subscribe(
        () => {
          this.toastr.success(this.translate.instant('success.addFile'));
          this.emitSuccessUpload.emit(true);
          this.spinner.hide();
        }, (error) => {
          console.error('error ==>', error);
          this.toastr.error(error.error.message || 'An error occurred while uploading the file');
          this.spinner.hide();
        }
      );
    } else if (this.point || this.pointId) {
      this.api.addPointFilesMultiple(this.pointId, files, isAnnexe).subscribe(
        () => {
          this.toastr.success(this.translate.instant('success.addFile'));
          this.emitSuccessUpload.emit(true);
          this.spinner.hide();
        }, (error) => {
          console.error('error ==>', error);
          this.toastr.error(error.error.message || 'An error occurred while uploading the file');
          this.spinner.hide();
        }
      );
    }
  }
}
