import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import { Button } from 'src/app/shared/models/button';
import { Global_Class } from 'src/app/shared/models/global';
import { LabelValue } from 'src/app/shared/models/labelvalue';
import * as async from 'async';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { ReportingComponent } from 'src/app/shared/components/modals/reporting/reporting.component';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
  public buttons: Array<Button> = [
    {
      text: this.translate.instant('btn.createMeetings'),
      styleClass: 'green',
      click: () => {
        this.submit();
      },
    },
  ];
  private isLoading = false;
  public dateType: string = 'manuel';
  public dateArray: Array<any> = [];
  public globalElements: Global_Class;
  public form: FormGroup = new FormGroup({
    typeSeanceId: new FormControl(null, Validators.required),
    seanceDates: new FormControl(null, Validators.required),
    dateType: new FormControl('manuel'),
    startDate: new FormControl(null),
    weeks_reccurence: new FormControl(null),
    period: new FormControl(null),
  });
  public weeks: Array<any> = [
    { label: this.translate.instant('recurrenceEveryWeek'), value: 1 },
    { label: this.translate.instant('recurrenceEveryTwoWeeks'), value: 2 },
    { label: this.translate.instant('recurrenceEveryThreeWeeks'), value: 3 },
  ];
  public daysWeeks: Array<any> = [
    { label: this.translate.instant('Lundi'), value: 1 },
    { label: this.translate.instant('Mardi'), value: 2 },
    { label: this.translate.instant('Mercredi'), value: 3 },
    { label: this.translate.instant('Jeudi'), value: 4 },
    { label: this.translate.instant('Vendredi'), value: 5 },
    { label: this.translate.instant('Samedi'), value: 6 },
    { label: this.translate.instant('Dimanche'), value: 0 },
  ];
  public recurrenceArray: Array<any> = [];
  public types_seances_LV: Array<LabelValue> = [];
  constructor(
    public dataS: DataService,
    public toastr: ToastrService,
    public api: ApiService,
    public translate: TranslateService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public dialog: DialogService,
    private titleService: Title,
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.titleService.setTitle('Création de séance');
    this.dataS.getGlobalElements.subscribe((res: any) => {
      this.globalElements = new Global_Class(res);
      this.getTranslations();
      this.getSeancesTypes();
    });
  }

  getTranslations() {
    const arrayOfKeys = [
      'recurrenceEveryWeek',
      'recurrenceEveryTwoWeeks',
      'recurrenceEveryThreeWeeks',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
      'Dimanche',
      'btn.createMeetings',
    ];
    this.translate.get(arrayOfKeys).subscribe((res) => {
      this.weeks[0].label = res.recurrenceEveryWeek;
      this.weeks[1].label = res.recurrenceEveryTwoWeeks;
      this.weeks[2].label = res.recurrenceEveryThreeWeeks;
      this.daysWeeks[0].label = res.Lundi;
      this.daysWeeks[1].label = res.Mardi;
      this.daysWeeks[2].label = res.Mercredi;
      this.daysWeeks[3].label = res.Jeudi;
      this.daysWeeks[4].label = res.Vendredi;
      this.daysWeeks[5].label = res.Samedi;
      this.daysWeeks[6].label = res.Dimanche;
      this.buttons[0].text = res['btn.createMeetings'];
    });
  }

  getSeancesTypes() {
    this.api.getRightsPointsSeances().subscribe((data: any) => {
      data.typeSeanceRights.map((x) => {
        if (x.addSeance) {
          this.globalElements.types_seances_LV.map((x) => x.value).indexOf(x.typeSeanceId) > -1
            ? this.types_seances_LV.push(
                this.globalElements.types_seances_LV[
                  this.globalElements.types_seances_LV.map((x) => x.value).indexOf(x.typeSeanceId)
                ]
              )
            : null;
        }
      });
      this.spinner.hide();
    });
  }

  deleteIndex(index) {
    const array = [];
    async.waterfall([
      (cb) => {
        if (this.form.value.seanceDates.length > 0) {
          this.form.value.seanceDates.map((item, i) => {
            if (i !== index) {
              array.push(item);
            }
          });
        }
        cb(null);
      },
      (cb) => {
        setTimeout(() => {
          this.form.patchValue({
            seanceDates: array,
          });
        }, 500);
      },
    ]);
  }

  submit() {
    if (!this.isLoading && this.form.valid) {
      this.isLoading = true;
      this.api.postSeance(this.form.value).subscribe(
        (res: any) => {
          this.dialog.open(ReportingComponent, {
            width: '80vw',
            showHeader: false,
            data: {
              successItems: res.recordsCreated,
              errorItems: res.recordsExisting,
            },
          });
          this.router.navigateByUrl('/private/meetings');
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    } else {
      if (this.form.valid === false) {
        if (this.form.value.typeSeanceId === null) {
          this.toastr.error(this.translate.instant('error.emptySeanceType'));
        }
        if (this.form.value.seanceDates === null) {
          this.toastr.error(this.translate.instant('error.emptyMeetingDate'));
        }
      }
    }
  }

  generateHebdo() {
    this.form.patchValue({
      seanceDates: [],
    });
    if (this.form.value.dateType === 'hebdo') {
      for (let i = 0; i < this.form.value.period; i++) {
        this.recurrenceArray.push(null);
      }
      const array2 = [];
      this.recurrenceArray.map((item, index, array) => {
        if (index === 0) {
          array2.push(moment(this.form.value.startDate).toDate());
        } else {
          array2.push(
            moment(array2[index - 1])
              .add(7 * this.form.value.weeks_reccurence, 'days')
              .toDate()
          );
        }
      });
      this.form.patchValue({
        seanceDates: array2,
      });
    }
  }

  getDatesOfDayOfWeek(month = 6) {
    const initialDate = this.form.value.startDate;
    const datesOfDayOfWeek = [];
    while (initialDate.getDay() !== this.form.value.weeks_reccurence) {
      initialDate.setDate(initialDate.getDate() + 1);
    }
    for (let i = 0; i < this.form.value.period; i++) {
      const nextDate = new Date(initialDate.getTime());
      datesOfDayOfWeek.push(nextDate);
      initialDate.setDate(initialDate.getDate() + 7);
    }

    this.form.patchValue({
      seanceDates: datesOfDayOfWeek,
    });
    return datesOfDayOfWeek;
  }
}
