import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LabelValue } from '../../models/labelvalue';
import * as _ from 'underscore';
@Component({
  selector: 'super-autocomplete',
  templateUrl: './super-autocomplete.component.html',
  styleUrls: ['./super-autocomplete.component.scss'],
})
export class SuperAutoCompleteComponent implements OnInit {
  @Input() set items(value: Array<LabelValue>) {
    if (value && value.length > 0) {
      this.elements = value;
      this.elementsBACKUP = value;
    }
  }
  @Input() minLength: number = 2;
  @Input() placeholder: string;
  @Input() labelField: string = 'autoCompleteField';
  public elements: Array<any> = [];
  public elementsBACKUP: Array<any> = [];
  @Input() set actualValue(value) {
    this.dataBind = value;
  }
  public loading: boolean = false;
  public dataBind: any;
  @Output() selectedElement: any = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  filter(event) {
    if (event && event.query && this.elementsBACKUP?.length > 0) {
      this.elements = [];
      this.elementsBACKUP.map((item) => {
        if (item.autoCompleteField.toLowerCase().includes(event.query.toLowerCase())) {
          item[this.labelField] = item.autoCompleteField;
          this.elements.push(item);
        }
      });
      this.elements = _.uniq(this.elements, this.labelField);
    }
  }

  emit($event) {
    this.selectedElement.emit($event);
  }
}
