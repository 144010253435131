import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SelectItem, ConfirmationService } from 'primeng/api';
import * as _ from 'underscore';
import { LazyLoadEvent } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-super-list-simple',
  templateUrl: './super-list-simple.component.html',
  styleUrls: ['./super-list-simple.component.scss'],
})
export class SuperListSimpleEDelibeComponent {
  public lists: Array<any> = [];
  @Input() set items(value) {
    this.virtualItems = [];
    this.totalRecords = 0;
    if (value.length > 0) {
      this.virtualItems = _.sortBy(_.uniq(value, 'id'), 'name');
      this.totalRecords = value.length;
    }
  }
  @Input() columns: any[];
  @Input() boolShowHeader?: boolean;
  // tslint:disable-next-line:ban-types
  @Input() click: Function;
  @Input() typeContact: any[];
  @Input() boolMultiSelect?: boolean;
  @Input() destinataire?: boolean;
  @Input() alignLast?: string;
  @Input() boolImageMode?: boolean;
  @Input() text?: string;
  @Input() height?: string = '520px';
  @Input() icon?: string;
  @Input() iconColor?: string;
  @Input() deletable: boolean;
  @Input() showGroup: boolean;
  @Input() btnClick: Function;
  @Output() multiSelectValue: EventEmitter<any> = new EventEmitter();
  @Output() selectedValue: EventEmitter<any> = new EventEmitter();
  public activeOptionsID: number;
  public status: SelectItem[];
  public totalRecords: number;
  public selectAll = false;
  public selected: any[] = [];
  public loading: boolean;
  activeLine = 0;
  boolShowAction = false;
  public virtualItems: Array<any> = [];
  public searchTypeContact: string = '';
  public searchString: string = '';
  public typeGroupSearch: boolean;
  public startTo: number;
  public length: number;
  public typeGroup = [
    { label: 'Filtrer par groupe', value: 'oui' },
    { label: 'Filtrer par service', value: 'non' },
  ];
  public rowsToShow: number = 100;
  public date = Date.now();

  public activeStates = [
    { label: 'Tous', value: 'all' },
    { label: 'Actifs', value: true },
    { label: 'Inactifs', value: false },
  ];
  public activeStateRecipient = true;
  @Input() isAllowedToFilterByStateActive; //va permettre d'afficher le tri par select-button
  @Input() typeOfContact;
  @Input() fieldToFilter;
  constructor(
    private confirmationService: ConfirmationService,
    public http: HttpClient
  ) {}

  toggleLine(arg) {
    if (arg.id) {
      this.activeLine = arg.id;
    }
  }

  fct(click, item) {
    this.selectedValue.emit({ item });
  }

  selectItem(event, item) {
    item.checked = event.currentTarget.checked;
    this.emit();
  }

  selectAllItems() {
    this.selectAll = !this.selectAll;
    this.virtualItems.map((item) => {
      item.checked = this.selectAll;
    });
    this.emit();
  }

  emit() {
    this.selected = this.virtualItems.filter((x) => x.checked === true);
    this.multiSelectValue.emit(this.selected);
  }

  deleteRecipient(item) {
    this.deletable = true;
    this.confirmationService.confirm({
      message: 'Etes-vous sûr de vouloir supprimer cette ligne ?',
      key: 'deleteRecipient',
      accept: () => {
        const indexToDelete = this.lists.indexOf(item);
        this.lists[indexToDelete].checked = false;
        this.lists.splice(indexToDelete, 1);
        this.emit();
      },
    });
  }

  layLoadData(event: LazyLoadEvent) {
    this.loading = true;
    this.lists = [];
    // check the filters
    if (event.filters != undefined && event.filters['name'] != undefined) {
      this.lists = this.search(this.virtualItems, 'name', event.filters.name.value);
      this.loading = false;
    } else {
      setTimeout(() => {
        if (event && _.isString(event.sortField) && _.isNumber(event.sortOrder)) {
          this.virtualItems = _.sortBy(this.virtualItems, event.sortField);
          if (event.sortOrder === -1) this.virtualItems.reverse();
        }
        this.startTo = event ? +event.first : 0;
        this.length = event ? +event.rows : 30;
        const chunk: Array<any> = [];
        for (let i = 0; i < length; i++) {
          const item = this.virtualItems[this.startTo + i];
          if (_.isObject(item)) chunk.push(item);
        }
        this.lists = _.sortBy(chunk, 'name');
        if (this.isAllowedToFilterByStateActive) {
          this.filterItems();
        }
        this.loading = false;
      }, 1000);
    }
  }

  public search(array, key, search, active = true) {
    if (active) {
      if (_.isString(search) && (search.length == 0 || !search || search == null)) return array;
      if (!key || !search) {
        throw 'Il faut une key => searchByKey:key:value';
      }
      if (array && array.length > 0) {
        const txt = String(search).toLowerCase().replace(/\s/g, '');
        if (txt.length > 0) {
          const filtered = [];
          array.map((item) => {
            const value = item[key];
            if (typeof value == 'string') {
              const val = String(value).toLowerCase().replace(/\s/g, '');
              const test = val.search(txt) >= 0;
              if (test) {
                filtered.push(item);
              }
            }
          });
          return filtered;
        } else return array;
      }
    } else return array;
  }

  public filter(key1, value1, key2, value2) {
    this.loading = true;
    let temp = [];
    temp = this.virtualItems;
    this.lists = [];
    let toPush = [];
    if (value1 === '' && value2 === 'all') {
      toPush = temp;
    } else {
      temp.map((x) => {
        if (value1 === '') {
          if (x[key2] === value2) {
            x.label = x.name.trimStart();
            toPush.push(x);
          }
        } else if (value2 === 'all') {
          if (x[key1] === value1) {
            x.label = x.name.trimStart();
            toPush.push(x);
          }
        } else {
          if (x[key1] === value1 && x[key2] === value2) {
            x.label = x.name.trimStart();
            toPush.push(x);
          }
        }
      });
    }
    toPush = _.sortBy(toPush, 'name');
    setTimeout(() => {
      const startTo = this.startTo;
      const length = this.length;
      const chunk: Array<any> = [];
      for (let i = 0; i < length; i++) {
        const item = toPush[startTo + i];
        if (_.isObject(item)) chunk.push(item);
      }
      this.lists = _.uniq(chunk, 'id');
      this.lists = _.sortBy(this.lists, 'name');
      this.totalRecords = toPush.length;
      this.loading = false;
    }, 1000);
  }

  getAvatar(url) {
    this.http.get(url).subscribe((res: any) => {
      return res;
    });
  }

  filterItems() {
    this.lists = [];
    this.filter(
      'typeContactID',
      this.searchTypeContact,
      this.fieldToFilter ? this.fieldToFilter : 'userActive',
      this.activeStateRecipient
    );
  }
}
