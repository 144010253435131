<section *ngIf="loaded && items">
    <super-title [text]="title" level="1" [buttons]="buttons"></super-title>
     <div>
         <i-table #superTable
               [loading]="loadingTable"
               height="85vh" 
               [id]="'idTableauDeBordPoints'" 
               [items]="items" 
               [columns]="columns" 
               [config]="tableConfigurations" 
               [menu]="menu" 
               [customActions]="forselection" 
               [customActionManualReset]="true"
               (pageChange)="pageChange($event)"
               (onSelectedRow)="showDetails($event)" 
               (onMenuRowClick)="receive_menu($event)" 
               (onColumnSort)="columnSort($event)"
         ></i-table>
    </div>
    
    
</section>
<section *ngIf="!loaded">
    <super-title [text]="title" level="1" [buttons]="buttons"></super-title>
    <skeleton-table [items]="columns"></skeleton-table>
</section>

<p-dialog #formDialogDuplicatePoint styleClass="super-compos-modal-content" [(visible)]="openDuplicationManagementModal" [modal]="true" [style]="{width: '30vw'}" appendTo="body" [closable]="false">
    <p-header>
         <div class="d-flex align-items-center justify-content-between">
              <h5 class="modal-title super-compos-modal-title">
                   {{'duplicate.duplicationManagement'|translate}}
              </h5>
              <div class="d-flex">
                   <super-button styleClass="blue" (click)="openDuplicationManagementModal = false" [text]="'btn.cancel'|translate"></super-button>
                   <super-button styleClass="green ml-3" (click)="submitDuplicationManagement()" text="Confirmer"></super-button>
              </div>
         </div>
    </p-header>
    <section [formGroup]="formDuplicationPoint" >
         <div class="row" *ngIf="duplicationManagementIndex ===0"> <!-- 1st part of the modal directly accessed when its opened -->
              <div class="col-12 mb-2">
                   <p-checkbox [label]="'duplicate.pointContent'|translate" binary="true" formControlName="infos"></p-checkbox>
              </div>
              <div class="col-12 mb-2" *ngIf="files.length >0">
                   <p-checkbox [label]="'duplicate.filesSelection'|translate" binary="true" formControlName="files"></p-checkbox>
              </div>
              <div class="col-12">
                   <p-checkbox [label]="'duplicate.recipients'|translate" binary="true" formControlName="recipients"></p-checkbox>
              </div>
         </div>
         <div class="row" *ngIf="duplicationManagementIndex ===1">  <!-- 2nd part of the modal accessed when the user chose to add files to the duplication-->
              <div class="col-12">
                   <super-table [initialSelection]="filesForDuplication" [forselectionDefaultOption]="false" #superTableFilesDuplication [forselection]="null" [items]="filesForDuplication|sort:'OrdreAffichage':'ASC'" [columns]="columnsFiles" id="super-files-tables-Duplication" [height]="'calc( 100vh - 400px)'" [multiselect]="true">
                   </super-table>
              </div>
         </div>
    </section>
</p-dialog>
<p-confirmDialog key="modalTable" #cd appendTo="body" styleClass="super-compos-modal-content confirmModal" header="Confirmation"
icon="pi pi-exclamation-triangle">
<p-footer>
    <button type="button" pButton icon="pi pi-times" label="Non" (click)="cd.reject()"></button>
    <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="Oui"
        (click)="cd.accept()"></button>
</p-footer>
</p-confirmDialog>