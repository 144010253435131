import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SuperColumn, SuperTableComponent } from '@devinforius/super-compos';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/core/services/api.service';
import { Button } from 'src/app/shared/models/button';
import { Destinataire } from 'src/app/shared/models/destinataire';
import { File } from 'src/app/shared/models/files';
import { Historique } from 'src/app/shared/models/historique';
import { Point } from 'src/app/shared/models/point';
import * as async from 'async';
import { Global_Class } from 'src/app/shared/models/global';
import { DataService } from 'src/app/core/services/data.service';
import { EventAddComponent } from 'src/app/shared/components/modals/event-add/event-add.component';
import { ToastrService } from 'ngx-toastr';
import { IContactService } from 'src/app/core/services/i-contact.service';
import * as _ from 'underscore';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { Contact } from 'src/app/shared/models/iContact/contact';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LabelValue } from 'src/app/shared/models/labelvalue';
import { Title } from '@angular/platform-browser';
import { Dialog } from 'primeng/dialog';
import { UserRightsService } from 'src/app/core/services/user-rights.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DuplicationPointComponent } from 'src/app/shared/components/modals/duplication/duplication-point.component';
import { PointReportComponent } from 'src/app/shared/components/modals/point-report/point-report.component';
import { Subscription } from 'rxjs';
import { SignalrService } from 'src/app/core/services/signalr.service';

import {
  NotifyUserSignalrPayload,
  SignalrDocumentMergeCompletedData,
  SignalrPayloadEventId,
} from 'src/app/shared/models/signalr_payloads';
import { MarktocorrectionComponent } from 'src/app/shared/components/modals/marktocorrection/marktocorrection.component';
import { DetailsService } from '../../services/details.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ContactInfosService } from 'src/app/core/services/contact-infos.service';
import { SignFileComponent } from 'src/app/shared/components/signFile/sign-file.component';
import { ActionType } from 'src/app/shared/models/point/category-actions.model';

@Component({
  selector: 'app-fiche-point',
  templateUrl: './fiche-point.component.html',
  styleUrls: ['./fiche-point.component.scss'],
  providers: [ConfirmationService], //obligée, malgré les key, j'ai un dédoublement de dialog... ticket 455 créé pour faire qu'un seul p-confirm dans private
})
export class FichePointComponent implements OnInit {
  //#region View Child
  @ViewChild('uploader') uploader: any;
  @ViewChild('overlayButton') overlayButton: OverlayPanel;
  @ViewChild('formDialogDuplicatePoint') formDialogDuplicatePoint: Dialog;
  @ViewChild('superTableFiles') public superTableFiles: SuperTableComponent;
  @ViewChild('superTableFilesDuplication') public superTableFilesDuplication: SuperTableComponent;
  //#endregion
  StatusBtnText: any;
  StatusBtnChildren: any[];
  StatusBtnTooltip: string;
  public userCanModifyPoint: boolean = false;
  public userCanAddEventBefore: boolean = false;
  public userCanDuplicatePoint: boolean = false;
  public userCanPostPonePoint: boolean = false;
  public userCanValidateCorrections: boolean = false;
  amountOfEventsForMeetingNonPreparee: number;
  points: any[];
  pointsTreeWithEvents: null;
  amountOfPointsForMeeting: number;
  nombreNiveauxTree: any;

  //* Will receive the pointId from other component
  @Input() set pointId(value) {
    if (value) {
      this.detailsService.currentID = value;
      this.load();
      this.getFiles();
    }
  }
  //* Will emit when the point was changed and those modifications needs to be adjusted in other components
  @Output() onChangePoint: EventEmitter<any> = new EventEmitter();
  public participantArray: Array<any> = [];
  public loaded: boolean = false;
  public destiPrincipal: Destinataire;
  public files: Array<File> = [];
  public filesForDuplication: Array<File> = [];
  public destinataires: Array<Destinataire> = [];
  public destinatairesItems: Array<Destinataire> = [];
  public actionStatus: any[] = [];
  public actionTypes: any[] = [];
  public historiques: Array<Historique> = [];
  public point: Point = null;
  public globalElements: Global_Class;
  public globalElement: Global_Class;
  public hasLoaded = false;
  public contacts: Array<Contact> = [];
  public dataOnClick;
  public displayActionsModal: boolean = false;
  public displayDocumentsModal: boolean = false;
  public config;
  public actions: Array<any> = [];
  public setNewStatut; //* Will receive the new status when the point's status is changed
  public pointsRights;
  public votes: any;
  public votesParticipants: Array<any> = [];
  public userInfos: any = null;
  public modelesLV: Array<LabelValue> = [];
  public globalConfig = JSON.parse(sessionStorage.getItem('globalConfig'));
  public listOfContactsAdmin: Array<Contact> = [];
  public rightsPointsSeances = null;
  //#region tabs
  public itemsTabs: Array<any> = [];
  dateExtrait: Date = new Date();
  public activeTab: string = 'infosGenerales';
  //#endregion

  public columnHistorique: Array<SuperColumn> = [
    {
      field: 'modifiedAt',
      header: 'Date & Heure',
      format: (value) => {
        if (value) {
          return moment(value).format('DD/MM/YYYY à HH:mm');
        }
      },
    },
    {
      field: 'modifiedByLabel',
      header: 'Utilisateur',
    },
    {
      field: 'typeHistorique',
      header: 'Type',
    },
    {
      field: 'description',
      header: 'Description',
    },
  ];
  //#endregion

  //#region buttons
  public buttons: Array<Button> = [
    {
      text: '',
      id: 'superButton__ellipsisButton',
      styleClass: 'superButton__ellipsisButton',
      icon: 'fas fa-ellipsis-h',
      iconPosition: 'left',
      click: () => {},
      children: [],
    },
    {
      text: this.translate.instant('btn.documents'),
      styleClass: 'blue dropdown-custom',
      icon: 'icon-docs',
      iconPosition: 'left',
      id: 'buttonDocument',
      click: (event) => {
        this.saveEventClickForReloadOverlayButton = event;
        const target = document.getElementById('buttonDocument');
        this.overlayButton.toggle(event, target);
      },
    },
    {
      text: this.translate.instant('status'),
      id: 'superButton__statusButton',
      styleClass: 'blue superButton__statusButton',
      click: () => {},
      children: [],
    },
    {
      text: '',
      id: 'superButton__validateNewStatut',
      styleClass: 'green superButton__saveStatutModification d-none',
      icon: 'icon-checkGreen',
      iconPosition: 'right',
      click: () => {
        this.validateNewStatut();
      },
    },
  ];
  public buttonBackup: Array<any> = [];
  //#endregion

  //#region files
  public selectedFile;
  public filesMenu: Array<any> = [];
  public fileUploadChooseLabel;
  public fileUploadUploadLabel;
  public fileUploadCancelLabel;
  //#endregion

  //#region forms
  public formFilesModification: FormGroup = new FormGroup({
    id: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    annexeNumero: new FormControl(),
    fileType: new FormControl(),
    visibleDelibeWeb: new FormControl(),
    visibleDelibeWebCitoyen: new FormControl(),
  });
  public form = new FormGroup({
    modeleId: new FormControl(null),
    updateBefore: new FormControl(false),
    regenerateMode: new FormControl(null),
  });
  public formDuplicationPoint = new FormGroup({
    infos: new FormControl(true),
    files: new FormControl(null),
    listOfFiles: new FormControl(null),
    recipients: new FormControl(null),
  });
  public formNewModelePoint = new FormGroup({
    description: new FormControl(null, Validators.required),
  });
  //#endregion

  //#region document
  public documentsOverlayItems: Array<any> = [];
  public showDocumentOverlay: boolean = false;
  public showRegenerateModal: boolean = false;
  public documentAlreadyInList: boolean = false;
  public statusDocumentsGlobal: Array<LabelValue> = [];
  public dropdownDisabled: boolean = false;
  public actualDocumentInModal: any;
  //#endregion

  public modalReference: NgbModalRef;
  public currentWeek = moment().format('ww');
  public openModification: boolean = false; //* Boolean to set the display of the file modification modal
  public count = 0; //* Simple var to have a count

  public modalPdfViewer: boolean = false;
  public blobViewer: any;
  public currentPage: number = 1;
  public contactsAdmin: Array<Contact> = [];
  public isGestionnaire: boolean;
  //#endregion
  public disabledLink: boolean = false;
  public confirmDialogBool: boolean = false;
  public itemsUploaded: any;
  public uploaders: any;
  public saveEventClickForReloadOverlayButton: any;
  public notifyUserSubscription: Subscription;
  public openCreationModelPointBasedOnExistingPoint = false;
  public allowCreationModelePoint = false;
  writeLock: any;
  isLoadingConforme: boolean = false;
  loadingConformeStates: { [key: number]: boolean } = {};
  constructor(
    public detailsService: DetailsService,
    public iContactService: IContactService,
    public toastr: ToastrService,
    public router: ActivatedRoute,
    public translate: TranslateService,
    public api: ApiService,
    public route: Router,
    public spinner: NgxSpinnerService,
    public dialogService: DialogService,
    public confirmationService: ConfirmationService,
    public data: DataService,
    public adminApi: iAdminService,
    private titleService: Title,
    public userRightsService: UserRightsService,
    public signalRService: SignalrService,
    public contactInfosService: ContactInfosService,
    public activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.notifyUserSubscription = this.signalRService.notifyUser.subscribe((payload: NotifyUserSignalrPayload) => {
      if (payload.payloadEventId == SignalrPayloadEventId.DocumentMergeCompleted && payload.data.modeleId) {
        const data: SignalrDocumentMergeCompletedData = payload.data;
        if (+data.pointId === +this.detailsService.currentID) {
          this.getModelesDocuments();
          this.getFiles();
        }
      }
    });
    //on va écouter les changements sur le storage et viser particulièrement ceux qui ont la key SignalREventsMerge car ce sont les changements avec réception de SignalR
    window.addEventListener('storage', (event) => {
      if (event.key === 'SignalREventsMerge') {
        // comparer les tableaux => tout ce qui a dans newValue qui n'existe pas dans oldvalue
        const oldValues = JSON.parse(event.oldValue ? event.oldValue : '[]');
        const newValues = JSON.parse(event.newValue ? event.newValue : '[]');
        let differentValues = [];
        if (oldValues.length === 0) {
          //si on a pas d'ancienne value, on va juste tester s'il existe au moins un pointId qui est égal au notre actuellement
          //si oui on reload les files et modèles
          if (newValues.map((x) => x.pointId).indexOf(+this.detailsService.currentID) > -1) {
            this.getModelesDocuments();
            this.getFiles();
          }
        } else {
          //si on a une oldValue, alors on va déjà devoir trouver ce qui existe dans newValue qui n'existait pas dans oldValue
          differentValues = this.getDifference(newValues, oldValues);
          if (differentValues.map((x) => x.pointId).indexOf(+this.detailsService.currentID) > -1) {
            this.getModelesDocuments();
            this.getFiles();
          }
        }
      } else if (event.key === 'SignalRManualReloadPoint' && +event.newValue === +this.detailsService.currentID) {
        this.getModelesDocuments();
        this.getFiles();
      }
    });
  }

  getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return (
          object1.requestId === object2.requestId &&
          moment(object1.date).unix() === moment(object2.date).unix() &&
          object1.modeleId === object2.modeleId &&
          object1.seanceId === object2.seanceId &&
          object1.pointId === object2.pointId
        );
      });
    });
  }

  //* method that sets the items tabs reachable by the user with its rights
  public setItemTabs() {
    const arrayOfKeys = ['generalInfos', 'Votes', 'files', 'recipients', 'historic', 'connections'];
    this.translate.get(arrayOfKeys).subscribe((res) => {
      this.itemsTabs = [
        { label: res['generalInfos'], value: 'generalInfos', count: null },
        { label: res['files'], value: 'files', count: null },
        { label: res['recipients'], value: 'recipients', count: null },
        { label: res['historic'], value: 'historic', count: null },
        { label: res['connections'], value: 'connections', count: null },
      ];
      if (this.point.seanceLink.inclusDansSeance && this.isGestionnaire) {
        this.itemsTabs.splice(1, 0, { label: res['Votes'], value: 'votes', count: null });
      }
    });
  }

  public load() {
    async.waterfall(
      [
        (callback) => {
          this.data.getGlobalElements.subscribe((data: any) => {
            this.globalElements = new Global_Class(data);
            if (this.globalElements.isLoaded()) {
              this.statusDocumentsGlobal = this.globalElements?.configApp.modeleGenerationRequestStatutes;
              callback(null);
            }
          });
        },
        (cb) => {
          this.spinner.show();
          this.getContactsAndContactsAdmin().then(() => {
            cb();
          });
        },
        (callback) => {
          this.getPointById().then(() => {
            callback();
          });
        },
        (cb) => {
          this.getModelesDocuments().then((res) => {
            cb(null);
          });
        },
        (callback) => {
          this.getUserInfosAndRights().then(() => {
            this.checkIfThePointIsLocked();
            callback();
          });
        },
        (callback) => {
          this.getPointsRights().then(() => {
            callback();
          });
        },
        (cb) => {
          this.setItemTabs();
          cb();
        },
        (callback) => {
          this.getRightsManagement().then(() => {
            callback();
          });
        },
        (callback) => {
          this.getFiles().then(() => {
            callback();
          });
        },
        (callback) => {
          this.setFilesRights().then(() => {
            callback();
          });
        },
        (callback) => {
          this.getVotes();
          callback();
        },
        (callback) => {
          this.getTranslation();
          if (this.activeTab === 'vote') {
            this.getVotes().then(() => {
              callback();
            });
          }
          if (this.activeTab === 'files') {
            this.getFiles();
            callback();
          }
          if (this.activeTab === 'destinataires') {
            this.getDestinataires();
            callback();
          }
          if (this.activeTab === 'history') {
            this.getHistorique();
            callback();
          }
        },
      ],
      () => {
        this.loaded = true;
      }
    );
  }

  /**
   * checks if the point is locked and by whom
   */
  checkIfThePointIsLocked() {
    this.getPointById().then(() => {
      this.loaded = true;
      const foundContact = this.listOfContactsAdmin.find((x) => x.userId === this.point.writeLock.writeLockUserId);
      const currentUser: boolean = this.userInfos.id === this.point.writeLock.writeLockUserId;
      this.writeLock = {
        locked: this.point.writeLock.locked,
        contact: this.point.writeLock.writeLockUserId !== null ? (foundContact ? foundContact.fullName : '') : '',
        currentUser: currentUser,
      };
    });
  }

  //* Sets the contacts of Delibe api and Admin api
  getContactsAndContactsAdmin() {
    return new Promise<any>((resolve, reject) => {
      async.parallel(
        [
          (callback) => {
            this.iContactService.getLowContactsAdmin().subscribe((response: any) => {
              this.listOfContactsAdmin = response;
              callback();
            });
          },
          (callback) => {
            this.iContactService.getLowContacts().subscribe(
              (contacts) => {
                this.contacts = contacts;
                this.data.setAllContacts(this.contacts);
                callback();
              },
              (error) => {
                console.error(error);
              }
            );
          },
        ],
        () => {
          resolve(true);
        }
      );
    });
  }

  //* Sets the user infos and if they have the right to add point in at least one meeting
  getUserInfosAndRights() {
    return new Promise<any>((resolve, reject) => {
      async.parallel(
        [
          (callback) => {
            this.adminApi.getUserInfo().subscribe((res: any) => {
              this.userInfos = res;
              callback();
            });
          },
          async (callback) => {
            this.rightsPointsSeances = await this.userRightsService.getUserAbleToAddPoint();
            callback();
          },
        ],
        () => {
          resolve(true);
        }
      );
    });
  }

  //* Checks the user's rights on the points and adapts menus, buttons,... with them
  getPointsRights() {
    return new Promise<any>((resolve, reject) => {
      this.api.getRightsPoint(this.detailsService.currentID).subscribe((res: any) => {
        this.pointsRights = res;
        this.isGestionnaire = res.isGestionnaire;
        if (res.files.edit) {
          this.filesMenu = [
            {
              label: this.translate.instant('btn.modify'),
              command: (item) => {
                window.setTimeout(() => {
                  this.openModification = true;
                  const index = this.files.map((x) => x.id).indexOf(this.dataOnClick.id);
                  this.selectedFile = this.files[index];
                  this.formFilesModification.patchValue({
                    id: this.selectedFile.id,
                    description: this.selectedFile.description,
                    annexeNumero: this.selectedFile.annexeNumero,
                    fileType:
                      this.selectedFile.extension === 'pdf' ? (this.selectedFile.annexeNumero ? true : false) : false,
                    visibleDelibeWeb: this.selectedFile.visibleDelibeWeb,
                    visibleDelibeWebCitoyen: this.selectedFile.visibleDelibeWebCitoyen,
                  });
                }, 500);
              },
            },
          ];
        } else {
          this.filesMenu = [];
        }

        //#region Setup first button childrens
        if (res.edit) {
          this.userCanModifyPoint = true;

          this.buttons[0].children = [
            {
              text: this.translate.instant('pointModification'),
              tooltip: this.translate.instant('pointModification'),
              click: () => {
                this.route.navigateByUrl('/private/points/update/' + this.detailsService.currentID);
              },
            },
          ];
          //!Afficher uniquement si on est pas dans la vue plein écran
          // if (this.router.snapshot.routeConfig.path !== "points/detail/:pointID") {
            this.titleService.setTitle('Point ' + this.point.id + ' - ' + this.point.objetSynthese);

          this.userCanAddEventBefore = true;

          this.buttons[0].children.push({
            text: this.translate.instant('btn.addEventBefore'),
            tooltip: this.translate.instant('btn.addEventBefore'),
            click: (data) => {
              const ref = this.dialogService.open(EventAddComponent, {
                data: {
                  header: this.translate.instant('insertEvent'),
                  meetingId: this.point.seanceId,
                  type: 'before',
                  pointClicked: this.point,
                },
                width: '80vw',
                styleClass: 'super-compos-modal-content ',
                showHeader: false,
                closable: false,
                dismissableMask: false,
                baseZIndex: 1000,
              });
              ref.onClose.subscribe((res: any) => {
                if (res) {
                  this.onChangePoint.emit(true);
                }
              });
            },
          });
        }
        //* Setting the duplicate menu if the user has at least one right to add a point
        if (this.rightsPointsSeances) {
          this.userCanDuplicatePoint = true;

          this.buttons[0].children.splice(1, 0, {
            text: this.translate.instant('btn.duplicate'),
            tooltip: this.translate.instant('duplicatePoint'),
            click: () => {
              const ref = this.dialogService.open(DuplicationPointComponent, {
                data: {
                  pointId: this.detailsService.currentID,
                  globalElements: this.globalElements,
                  listOfContactsAdmin: this.listOfContactsAdmin,
                  contacts: this.contacts,
                },
                width: '50vw',
                styleClass: 'super-compos-modal-content ',
                showHeader: false,
                closable: false,
                dismissableMask: false,
                baseZIndex: 1000,
              });
              ref.onClose.subscribe((res: any) => {
                if (res !== false) {
                  this.route.navigateByUrl('/private/points/duplicate');
                }
              });
            },
          });
        }
        //il faut absolument que le point ne soit pas en "reporté" pour le reporter et il DOIT être lié à une séance
        const statutReport = this.globalElements.statuts_points.find((x) => x.typeReport === true);
        if (
          statutReport?.id !== this.point.statutId &&
          this.point.seanceLink &&
          this.point.seanceLink.inclusDansSeance
        ) {
          this.userCanPostPonePoint = true;

          //IDELIBE-557 / IDELIBE-559
          this.buttons[0].children.splice(2, 0, {
            text: this.translate.instant('btn.postponePoint'),
            tooltip: this.translate.instant('postponePoint'),
            id: 'postponeButton',
            click: () => {
              const ref2 = this.dialogService.open(PointReportComponent, {
                data: {
                  points: [this.point],
                  seanceId: this.point.seanceId,
                },
                width: '50vw',
                styleClass: 'super-compos-modal-content ',
                showHeader: false,
                closable: false,
                dismissableMask: false,
                baseZIndex: 1000,
              });
              ref2.onClose.subscribe(() => {
                this.load();
              });
            },
          });
        }

        //* Setting the menu to be able to validate the corrections
        if (res.validerCorrections && this.point.modificationAFaireParContactId === this.userInfos.contactId) {
          this.userCanValidateCorrections = true;
          this.buttons[0].children.push({
            text: this.translate.instant('validateCorrections'),
            tooltip: this.translate.instant('validateCorrections'),
            click: () => {
              this.confirmationService.confirm({
                message: 'Avez-vous sauvé vos modifications ? Souhaitez-vous continuer ?',
                key: 'fiche-point-confirm',
                accept: () => {
                  this.api.validerCorrection(this.point.id).subscribe((res) => {
                    this.toastr.success(this.translate.instant('success.validatedCorrections'));
                    if (this.router.snapshot.routeConfig.path === 'points/detail/:pointID') {
                      this.loaded = false;
                      this.getPointById().then(() => (this.loaded = true));
                    } else {
                      this.onChangePoint.emit(true);
                    }
                  });
                },
              });
            },
          });
        }
        //#endregion
        this.setDropdownStatut({ id: this.point.statutId });
        resolve(true);
      });
    });
  }

  public getPointById() {
    return new Promise((resolve) => {
      this.api.getPointByID(this.detailsService.currentID).subscribe(
        (res: Point) => {
          this.point = new Point(res, this.globalElements, null, this.listOfContactsAdmin, this.contacts);
          resolve(true);
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.toastr.error('error.errorWhileFetchingPointInfos');
          console.error(err);
        }
      );
    });
  }

  getRightsManagement() {
    return new Promise((resolve, reject) => {
      let rightsManagementDelibe = null;
      let rightsManagementAdmin = null;
      async.parallel(
        [
          (callback) => {
            this.api.getRightsManagement().subscribe((rightManagement: any) => {
              rightsManagementDelibe = rightManagement;
              callback();
            });
          },
          (callback) => {
            this.adminApi.getRightsManagement().subscribe((res: any) => {
              rightsManagementAdmin = res;
              callback();
            });
          },
        ],
        () => {
          if (rightsManagementDelibe.menu.modeleObjets) {
            if (
              rightsManagementAdmin.menu.delibeRight.gestionBibliotheque ||
              rightsManagementAdmin.menu.delibeRight.gestionBibliothequePrive
            ) {
              //on a accès coté délibé a la création d'un modèle et côté iAdmin à tous les services => Pas de check supplémentaire
              this.allowCreationModelePoint = true;
            } else if (rightsManagementAdmin.menu.delibeRight.gestionBibliothequeService) {
              //ici on va devoir checker si on a accès au service du point, car gestionBibliothequeService force l'utilisateur à avoir accès au service du point
              if (this.userInfos.services.indexOf(this.point.serviceId) > -1) {
                this.allowCreationModelePoint = true;
              } else {
                this.allowCreationModelePoint = false;
              }
            } else {
              //Au cas ou :) Y'a des users qui n'ont pas de droit prédéfini :( Pas normal mais ca existe en local
              this.allowCreationModelePoint = false;
            }
          } else {
            //Si pas cet accès, ca sert a rien d'aller plus loin
            this.allowCreationModelePoint = false;
          }
          resolve(true);
        }
      );
    });
  }

  //* Sets the user's rights on files
  setFilesRights() {
    return new Promise((resolve, reject) => {
      this.config = this.globalElements.configApp;
      resolve(true);
    });
  }

  //* method that changes the view of the new chosen status
  changeStatutsDropDown(statut) {
    if (this.pointsRights.changerStatut) {
      this.buttons[this.buttons.length - 2].text = statut.description;
      this.buttons[this.buttons.length - 2].tooltip = statut.description;
      this.buttons[this.buttons.length - 2].styleClass = 'orange';
      _.last(this.buttons).styleClass = 'green superButton__saveStatutModification d-block';
      this.setNewStatut = statut;
    } else {
      this.toastr.error(this.translate.instant('error.noRightStatusChange'));
    }

    if (this.pointsRights.changerStatut) {
      this.StatusBtnText = statut.description;
      this.buttons[this.buttons.length - 2].tooltip = statut.description;
      this.buttons[this.buttons.length - 2].styleClass = 'orange';
      _.last(this.buttons).styleClass = 'green superButton__saveStatutModification d-block';
      this.setNewStatut = statut;
      this.validateNewStatut();
    } else {
      this.toastr.error(this.translate.instant('error.noRightStatusChange'));
    }
  }

  //* Used to manage the change of status
  validateNewStatut() {
    this.api.modifyPointStatut(this.detailsService.currentID, this.setNewStatut.id).subscribe((res: any) => {
      this.checkStatusForReport(this.setNewStatut);
      this.toastr.success(this.translate.instant('success.modifiedStatus'));
      this.buttons[this.buttons.length - 2].styleClass = 'blue';
      _.last(this.buttons).styleClass = 'green superButton__saveStatutModification d-none';
      this.setDropdownStatut(this.setNewStatut);
    });
  }

  //* Manages the dropdown of status
  setDropdownStatut(statut) {
    this.StatusBtnChildren = [];

    if (this.pointsRights.changerStatut && !this.point.cloture) {
      const statutsInDropdown = this.globalElements.statuts_points.filter((x) => {
        return x.id !== statut.id && this.pointsRights.changerStatutAllowedIds.indexOf(x.id) > -1;
      });
      statutsInDropdown.map((statut) => {
        this.StatusBtnChildren.push({
          text: statut.description,
          click: () => {
            this.changeStatutsDropDown(statut);
          },
        });
      });
      this.buttonBackup = this.StatusBtnChildren;
    }

    const statutOfPoint = this.globalElements.statuts_points.filter((x) => {
      return x.id === statut.id;
    });
    this.StatusBtnText = statutOfPoint[0]?.description;
    this.StatusBtnTooltip = statutOfPoint[0]?.description;

    //TODO ajouter la couleur styleClass pour avoir la couleur de fond du statut
  }

  checkStatusForReport(newStatus) {
    if (this.point.seanceLink && this.point.seanceLink.inclusDansSeance) {
      const statutReport = this.globalElements.statuts_points.find((x) => x.typeReport === true);
      if (statutReport?.id !== newStatus.id) {
        //si malgré un changement de statut, on est pas dans le statut de report
        //IDELIBE-557 / IDELIBE-559
        const doesButtonExists = this.buttons[0].children.find((x) => x.id === 'postponeButton');
        if (!doesButtonExists) {
          this.buttons[0].children.splice(2, 0, {
            text: this.translate.instant('btn.postponePoint'),
            tooltip: this.translate.instant('postponePoint'),
            click: () => {
              const ref2 = this.dialogService.open(PointReportComponent, {
                data: {
                  points: [this.point],
                  seanceId: this.point.seanceId,
                },
                width: '50vw',
                styleClass: 'super-compos-modal-content ',
                showHeader: false,
                closable: false,
                dismissableMask: false,
                baseZIndex: 1000,
              });
              ref2.onClose.subscribe((res: any) => {
                if (res) {
                  this.load();
                }
              });
            },
          });
        }
      } else {
        //si on est dans le statut de report, on va vérifier si on doit supprimer le bouton de report
        const doesButtonExists = this.buttons[0].children.find((x) => x.id === 'postponeButton');
        if (doesButtonExists) {
          this.buttons[0].children.splice(2, 1);
        }
      }
    } else {
      //si on est pas lié a une séance on affiche même pas le report
    }
  }

  //* Sets the datas of the chosen file
  receive_menu(event) {
    this.dataOnClick = new File(event);
  }

  //*When the user changes tab
  tabChange(tab) {
    switch (tab.value) {
      case 'votes':
        this.getVotes();
        break;
      case 'files':
        this.getFiles();
        break;
      case 'recipients':
        this.getDestinataires();
        break;
      case 'historic':
        this.getHistorique();
        break;
      default:
        console.warn('Onglet non reconnu:', tab.value);
    }
  }

  //* Gets the votes and participants of the point
  getVotes() {
    return new Promise((resolve, reject) => {
      async.waterfall(
        [
          (cb) => {
            this.api.getVotesByPointID(this.point?.seanceId, this.point?.id).subscribe((res: any) => {
              this.votes = res;
              cb(null);
            });
          },
          (cb) => {
            this.api.getVotesParticipants(this.point?.seanceId, this.point?.id).subscribe((res: any) => {
              this.votesParticipants = res;
              cb(null);
            });
          },
        ],
        () => {
          this.spinner.hide();
        }
      );
    });
  }

  setReloadParticipants() {
    this.api.getVotesParticipants(this.point?.seanceId, this.point?.id).subscribe((res: any) => {
      this.votesParticipants = res;
    });
  }

  //*get the point's files
  getFiles(forDuplication: boolean = false) {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this.hasLoaded = false;
      this.getTranslation();
      this.api.getPointFiles(this.detailsService.currentID).subscribe(
        (data) => {
          data.map((x) => new File(x));
          this.hasLoaded = true;
          this.files = data;
          forDuplication ? (this.filesForDuplication = data.filter((x) => !x.modeleId)) : null;
          const fileTab = this.itemsTabs.find((x) => x.value === 'files');
          fileTab ? (fileTab.count = this.files.length) : null;
          this.spinner.hide();
          resolve(true);
        },
        (err) => {
          this.files = [];
          this.filesForDuplication = [];
          this.spinner.hide();
          resolve(true);
        }
      );
    });
  }
  onUpload(event, uploader) {
    const isAnnexeFile: boolean = false;
    async.waterfall([
      (cb) => {
        this.confirmDialogBool = true;
        cb(null);
      },
      (cb) => {
        this.itemsUploaded = event.files;
        this.uploader = uploader;
      },
    ]);
  }
  addFileAnnexe(isAnnexeFile) {
    async.waterfall([
      (cb) => {
        this.spinner.show();
        this.count = 0;
        const totalItems = this.itemsUploaded.length;
        for (const file of this.itemsUploaded) {
          file.description = file.name;
          file.extension = file.name.split('.').pop();
          file.date = moment(file.lastModifiedDate, 'DD/MM/YYYY');
          file.pointId = this.detailsService.currentID;
          const isPDF = file.extension.toLowerCase() === 'pdf' ? true : false;

          const document = {
            description: file.name,
            version: 1,
          };
          this.api
            .addPointFiles(this.detailsService.currentID, document, file, file.extension, isPDF ? isAnnexeFile : false)
            .subscribe(
              (response) => {
                this.files.push(new File(response));
                this.count++;
                if (this.count === totalItems) {
                  cb(null);
                }
              },
              (error) => {
                console.error(error);
                this.spinner.hide();
                this.toastr.error("Une erreur est survenue lors de l'ajout du fichier : " + file.description);
              }
            );
        }
      },
      (cb) => {
        this.itemsUploaded = [];
        this.toastr.success('Merci', 'Les fichiers ont été enregistrés!');
        this.uploaders.clear();
        this.spinner.hide();
        this.getFiles();
        cb(null);
      },
    ]);
  }

  //* triggers when the user submit the file modification
  submitModificationFile() {
    if (this.formFilesModification.valid) {
      let allAnnexNumbers;
      let flagForAnnexNumber = false; //le flag va permettre de déterminer si l'annexe possède un numéro identique a une autre existante
      let indexOfAnnexIdem;
      const allPointFiles = this.files;
      if (this.formFilesModification.value.annexeNumero) {
        allAnnexNumbers = allPointFiles
          .map((x) => {
            return x.annexeNumero;
          })
          .filter((x) => x);
        indexOfAnnexIdem = allAnnexNumbers.indexOf(this.formFilesModification.value.annexeNumero);
        if (indexOfAnnexIdem > -1) {
          flagForAnnexNumber = false;
        } else {
          //si c'est === -1 alors on a pas d'intersection et donc pas d'annexe avec le même numéro
          flagForAnnexNumber = true;
        }
      } else {
        flagForAnnexNumber = true; //Pas de n° d'annexe donc c'est ok
      }

      if (flagForAnnexNumber) {
        const data = this.formFilesModification.value;

        if (!data.fileType) {
          data.annexeNumero = null;
        }

        if (!this.config.delibConfig.isiDelibeMandatairesEnabled) {
          delete data.visibleDelibeWeb;
        }
        if (!this.config.delibConfig.isiDelibeCitoyensEnabled) {
          delete data.visibleDelibeWebCitoyen;
        }

        this.api
          .patchPointFile(this.detailsService.currentID, this.formFilesModification.value.id, data)
          .subscribe((data: any) => {
            this.getFiles();
            this.toastr.success(this.translate.instant('success.modifiedFile'));
            this.openModification = false;
          });
      } else {
        this.toastr.error(
          allPointFiles[indexOfAnnexIdem].description + ' ' + this.translate.instant('error.sameAnnexNumber')
        );
      }
    } else {
      this.toastr.error(this.translate.instant('error.emptyTitle'));
    }
  }

  //* triggers when the file is downloaded
  downloadFile(event) {
    if (this.pointsRights.files.open) {
      this.api.downloadPointFile(this.detailsService.currentID, event.id).subscribe((res: any) => {
        const downloadURL = URL.createObjectURL(res);
        if ((event.description || event.name) && event.extension) {
          saveAs(downloadURL, (event.description ? event.description : event.name) + '.' + event.extension);
        } else {
          this.toastr.error("Impossible de sauver le fichier, il n'y a pas de nom ou d'extension");
        }
      });
    }
  }

  //* gets the point's recipients
  getDestinataires() {
    this.destinataires = [];
    const tempFree = [];
    const tempUser = [];
    let tempUsersIds = [];
    let tempUserNames = [];
    async.waterfall(
      [
        (callback) => {
          this.api.getDestinatairesFull(this.detailsService.currentID).subscribe(
            (data) => {
              this.destinataires = data;
              callback();
            },
            (error) => {
              console.error(error);
              this.spinner.hide();
              this.toastr.error(this.translate.instant('error.impossibleLoadRecipients'));
            }
          );
        },
        (callback) => {
          this.destinataires.map((dest) => {
            // destinataire libre
            if (dest.type === 'DESTINATAIRE' && !dest.contactId && !dest.contactIdiAdmin) {
              const destinataire = new Destinataire(dest);
              destinataire.libre = dest.libre;
              destinataire.name = dest.libre;
              tempFree.push(destinataire);
            }
            // destinataire basique
            if (dest.type === 'DESTINATAIRE' && dest.libre === null) {
              let contact = null;
              if (dest.contactIdiAdmin) {
                contact = this.listOfContactsAdmin.find((x) => x.id === dest.contactIdiAdmin);
              } else if (dest.contactId) {
                contact = this.contacts.find((x) => x.id === dest.contactId);
              }
              if (contact) {
                const destinataire = new Destinataire(dest);
                destinataire.nom = contact.nom ? contact.nom : '';
                destinataire.prenom = contact.prenom ? contact.prenom : '';
                destinataire.name = contact.nom + ' ' + contact.prenom;
                tempUser.push(destinataire);
              }
            }
          });
          callback();
        },
        (callback) => {
          setTimeout(() => {
            tempUser.map((x) => {
              if (x.transmissionUserId) {
                tempUsersIds.push(x.transmissionUserId);
              }
            });
            callback();
          }, 500);
        },
        (callback) => {
          setTimeout(() => {
            tempUsersIds = _.uniq(tempUsersIds);
            if (tempUsersIds.length > 0) {
              this.iContactService.getUserWithDataAdmin({ UserIds: tempUsersIds }).subscribe((res: any) => {
                tempUserNames = res;
                callback();
              });
            } else {
              callback();
            }
          }, 500);
        },
        (callback) => {
          tempUser.map((x) => {
            if (x.transmissionUserId) {
              tempUserNames.map((name) => {
                if (name.userId === x.transmissionUserId) {
                  x.transmissionUserName =
                    (name.contactForUser.prenom ? name.contactForUser.prenom : '') +
                    ' ' +
                    (name.contactForUser.nom ? name.contactForUser.nom : '');
                }
              });
            }
          });
          tempFree.map((x) => {
            if (x.transmissionUserId) {
              tempUserNames.map((name) => {
                if (name.userId === x.transmissionUserId) {
                  x.transmissionUserName =
                    (name.contactForUser.prenom ? name.contactForUser.prenom : '') +
                    ' ' +
                    (name.contactForUser.nom ? name.contactForUser.nom : '');
                }
              });
            }
          });
          callback();
        },
        (callback) => {
          setTimeout(() => {
            let temp = [];
            temp = tempFree.concat(tempUser);
            this.destinatairesItems = _.sortBy(temp, 'transmisDate').reverse();
            callback();
          }, 500);
        },
      ],
      () => {
        this.spinner.hide();
      }
    );
  }

  //* get the point's historic
  getHistorique() {
    this.historiques = [];
    this.api.getPointHistoricByID(this.detailsService.currentID).subscribe((historic: any) => {
      historic.map((item) => {
        const user = this.listOfContactsAdmin.find((x) => {
          return x.id === item.modifiedBy;
        });
        item.modifiedByLabel = user ? (user.name ? user.name : user.prenom + ' ' + user.nom) : '';
        this.historiques.push(new Historique(item));
      });
      this.spinner.hide();
    });
  }

  //* check if the var sent is odd
  isOdd(num) {
    return num % 2;
  }

  //* gets the opposote color of the given color
  getOppositeColor(bgColor) {
    if (bgColor) {
      const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
      const r = parseInt(color.substring(0, 2), 16); // hexToR
      const g = parseInt(color.substring(2, 4), 16); // hexToG
      const b = parseInt(color.substring(4, 6), 16); // hexToB
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }
  }

  //* goes back to meeting (only when you're in point, will make things easier)
  back() {
    const tabType = this.point.seanceLink?.inclusDansSeance ? 'pointIncluded' : 'pointPlanned';
    sessionStorage.setItem('iDelibeReloadMeetingOnRightTab', tabType);
    this.route.navigateByUrl('/private/meetings/details/' + this.point.seanceLink?.id);
  }

  //TODO déplacer côté component destinataires dans shared
  // setPrincipal(newPrincipal?) {
  //   if (newPrincipal) {
  //     this.destinatairesItems.map((item) => {
  //       if (newPrincipal.type === "DESTINATAIRE" && item.type === "DESTINATAIRE") {
  //         item.principal = false;
  //       }
  //     });

  //     if (newPrincipal.type === "DESTINATAIRE") {

  //       this.destiPrincipal = newPrincipal;
  //       let destPInput = new DestinataireInput({
  //         type: this.destiPrincipal.type,
  //         destinataireId: this.destiPrincipal.id,
  //       });

  //       this.api.setDestinatairePrincipal(this.detailsService.currentID, destPInput).subscribe(
  //         (response) => {
  //           this.toastr.success(this.translate.instant('mainRecipientReplaced'));
  //           const newDestP = this.items.find((i) => i.id === this.destiPrincipal.id);
  //           newDestP.principal = true;
  //           this.getDestinataires();
  //         },
  //         (error) => {
  //           this.toastr.error("Impossible de rendre ce destinataire principal");
  //         }
  //         );
  //     }
  //   }
  // }

  //* Refresh of recipients
  receive_refresh(event) {
    if (event) {
      this.getDestinataires();
      this.api.getRightsPoint(this.detailsService.currentID).subscribe((res: any) => {
        this.pointsRights = res;
      });
    }
  }

  tabVoteChange(event) {}

  //* return img for the given value
  private showBoolean(value) {
    if (value) {
      return `<b style="color: #07E299;"><i class="bi bi-check-circle-fill"></i></b>`;
    } else if (value === -1) {
      return `<b style="color: #FFAF2F;"><i class="bi bi-hourglass-split"></i></b>`;
    } else {
      return `<b style="color: #FF5671;"></b>`;
    }
  }

  //* translations
  getTranslation() {
    this.fileUploadChooseLabel = this.translate.instant('btn.choose');
    this.fileUploadUploadLabel = this.translate.instant('btn.upload');
    this.fileUploadCancelLabel = this.translate.instant('btn.cancel');
    this.buttons[1].text = this.translate.instant('btn.documents');
  }

  //* triggers when the votes are changed and saved
  changeVote(data) {
    this.api.changeVotes(this.point.seanceId, this.detailsService.currentID, data).subscribe((res: any) => {
      this.toastr.success('Les votes ont été enregistrés');
      this.getVotes();
    });
  }

  //* get the point's document templates
  getModelesDocuments() {
    this.documentsOverlayItems = [];
    return new Promise((resolve, reject) => {
      this.api.getModelesDocumentsByPointID(this.detailsService.currentID).subscribe((res: any) => {
        if (res && res.length > 0) {
          this.documentsOverlayItems = res;
          this.modelesLV = [];
          res.map((x) => {
            if (x.rights.canGenerateModele) {
              this.modelesLV.push(new LabelValue(x));
            }
          });
        }
        resolve(res);
      });
    });
  }

  //* Displays the chosen document template
  openModelDoc(item) {
    let blob = null;
    async.waterfall([
      (cb) => {
        if (item.rights.canDownloadPdf === true) {
          this.api.downloadModeleDocument(this.detailsService.currentID, item.id).subscribe((res: any) => {
            blob = res;
            cb(null);
          });
        } else {
          this.toastr.warning("Il n'y a pas de document disponible");
        }
      },
      (cb) => {
        //on ouvre dans un pdf viewer
        this.modalPdfViewer = true;
        this.blobViewer = URL.createObjectURL(blob);
      },
    ]);
  }
  downloadModelDoc(item) {
    this.api.downloadModeleDocument(this.detailsService.currentID, item.id).subscribe((res: any) => {
      const downloadURL = URL.createObjectURL(res);
      saveAs(downloadURL, item.description + '.pdf');
    });
  }

  //* previews the document template
  preview(item) {
    this.spinner.show();
    this.disabledLink = true;
    this.api.previewRequestsModelDoc(this.detailsService.currentID, item.id, false).subscribe(
      (res: any) => {
        this.toastr.success("Demande d'aperçu envoyée");
        this.resetOverlay();
        this.spinner.hide();
        setTimeout(() => {
          this.disabledLink = false;
        }, 1000);
        this.getModelesDocuments();
      },
      (error) => {
        this.toastr.error("Impossible de demander l'aperçu");
        setTimeout(() => {
          this.disabledLink = false;
        }, 1000);
        this.spinner.hide();
      }
    );
  }

  //* cancels the document template 's preview
  cancelPreview(item) {
    // change to cancel
    this.spinner.show();
    this.api
      .previewRequestsCancelModelDoc(this.detailsService.currentID, item.id, item.myLastGenerationRequest.id)
      .subscribe(
        (res: any) => {
          this.toastr.success("Demande d'aperçu annulée");
          this.resetOverlay();
          this.getModelesDocuments();
          this.spinner.hide();
        },
        (error) => {
          this.toastr.error("Impossible d'annuler la demande d'aperçu");
          this.spinner.hide();
        }
      );
  }

  //* generate a document template
  generate() {
    this.spinner.show();
    if (this.form.value.regenerateMode === 'update') {
      //si on est sur le radiobutton : Modifier
      window.open(
        this.globalConfig.baseUrl +
          '/private/editor/documentmodel/' +
          this.form.value.modeleId +
          '/pointId/' +
          this.detailsService.currentID.toString() +
          '?generatedAndSaved=' +
          true,
        '_blank'
      );
      this.spinner.hide();
    } else {
      this.api
        .generateModelesDoc(
          this.detailsService.currentID,
          this.form.value.modeleId,
          !this.form.value.updateBefore,
          this.form.value.updateBefore
        )
        .subscribe(
          (res: any) => {
            this.toastr.success('Votre demande a bien été enregistrée');
            this.spinner.hide();
            this.getModelesDocuments();
            this.showRegenerateModal = false;
          },
          (error) => {
            if (error.status === 403) {
              this.toastr.warning("Vous n'avez pas le droit de faire ceci");
            }
          }
        );
    }
  }

  editDocument(item) {
    //* On ouvre la modale comme generate sans qu'on puisse modifier le modele dans le dropdown qui est sélectionner.
    //* Si on coche Regénérer et remplacer et on modifie avant de générer : Il faut downloadRtf et l'ouvrir dans l'éditeur ( le document n'est pas encore pret, il va etre recréer, refusionner et remerger et puis il aura une notif.) AutoSave = false
    //* Si on coche Regénérer et remplacer sans modifie avant de générer : méthode generate avec autoSave = true
    //* Si on coche modifier : on fait un downloadRtf et l'ouvrir dans l'éditeur
    this.dropdownDisabled = true;
    this.form.reset();
    this.showRegenerateModal = true;
    this.actualDocumentInModal = item;
    this.form.patchValue({
      modeleId: item.id,
    });
    item.value = item.id;
    this.checkIfAlreadyInDocumentListPoint(item);
  }

  generateExtraitConforme(item: any): void {
    if (!this.loadingConformeStates[item.id]) {
      this.loadingConformeStates[item.id] = true;
      const ref = this.dialogService.open(SignFileComponent, {
        data: {
          seanceId: this.point.seanceId,
          massGeneration: false,
          points: [this.point],
          modelSelected: item.id
         },
        showHeader: true,
        header: 'Générer les extraits conformes pour les points',
        width: '50vw',
        baseZIndex: 1000,
        
      });
      ref.onClose.subscribe((res: any) => {
        if(res === undefined){
          this.loadingConformeStates[item.id] = false;
        }
        if (res !== false) {
          setTimeout(() => {
            this.loadingConformeStates[item.id] = false;
          }, 6000);
        }
      });
    }
  }

  setActualDocument(event) {
    this.actualDocumentInModal = this.modelesLV.find((x) => x.id === event.value);
  }

  generateModeleDoc() {
    this.form.reset();
    this.showRegenerateModal = true;
    this.documentAlreadyInList = false;
    this.form.patchValue({ modeleId: null });
    this.dropdownDisabled = false;
  }

  checkIfAlreadyInDocumentListPoint(event) {
    if (event.value) {
      const check = this.documentsOverlayItems.find((x) => x.id === event.value);
      // ici on va checker si le document est déjà généré et sauvegardé
      // on va aussi checker si le document n'est pas un extrait, car si c'est un extrait, 
      // on ne peut pas le modifier, cela créerait des problèmes aux niveau de l'extrait conforme qui doit être le moement que l'extrait 
      if (check.isAlreadyGeneratedAndSaved && !check.isExtrait) { 
        this.documentAlreadyInList = true;
      } else {
        this.documentAlreadyInList = false;
      }
    }
  }

  resetOverlay() {
    //on va reset l'overlaypanel pour qu'il s'adapte en taille
    this.overlayButton.hide();
    const target = document.getElementById('buttonDocument');
    this.overlayButton.toggle(this.saveEventClickForReloadOverlayButton, target);
  }

  openGenerateAndPatch(item) {
    this.spinner.show();
    this.documentAlreadyInList = false;
    this.form.reset();
    this.showRegenerateModal = true;
    this.form.patchValue({
      modeleId: item.id,
    });
    this.spinner.hide();
  }

  openPreviewFile(item) {
    if (item && item.id) {
      this.api.downloadSeanceFile(this.pointId, item.id).subscribe((res: any) => {
        this.blobViewer = URL.createObjectURL(res);
        this.modalPdfViewer = true;
      });
    }
  }

  createNewModelPoint() {
    if (this.formNewModelePoint.valid) {
      this.api
        .postModelePointFromPoint(this.detailsService.currentID, this.formNewModelePoint.value)
        .subscribe((res: any) => {
          this.toastr.success(this.translate.instant('success.modelePointCreated'));
          this.openCreationModelPointBasedOnExistingPoint = false;
        });
    } else {
      this.toastr.error(this.translate.instant('error.emptyDescription'));
    }
  }

  goToSeance() {
    if (this.activatedRoute.snapshot.routeConfig.path === 'point/:pointID') {
      //On ne fait rien on est déjà dans la route d'affichage du point avec la séance
    }
    if (this.activatedRoute.snapshot.routeConfig.path === 'points/detail/:pointID') {
      //La on est dans la route d'affichage du point seul, en plein écran => on va router vers la séance
      const tabType = this.point.seanceLink?.inclusDansSeance ? 'pointIncluded' : 'pointPlanned';
      sessionStorage.setItem('iDelibeReloadMeetingOnRightTab', tabType);
      this.route.navigateByUrl('/private/meetings/details/' + this.point.seanceLink.id);
    }
  }

  toggleDocumentsOverlay(event) {
    this.saveEventClickForReloadOverlayButton = event;
    this.displayDocumentsModal = true;
  }

  showActionsModal() {
    this.displayActionsModal = true;
  }

  duplicatePoint(event: boolean) {
    if(event){
      const ref = this.dialogService.open(DuplicationPointComponent, {
        data: {
          pointId: this.detailsService.currentID,
          globalElements: this.globalElements,
          listOfContactsAdmin: this.listOfContactsAdmin,
          contacts: this.contacts,
        },
        width: '50vw',
        styleClass: 'super-compos-modal-content ',
        showHeader: false,
        closable: false,
        dismissableMask: false,
        baseZIndex: 1000,
      });
      ref.onClose.subscribe((res: any) => {
        if (res !== false) {
          this.route.navigateByUrl('/private/points/duplicate');
        }
      });
    }
  }

  postponePoint(event: boolean) {
    if (event) {
      const ref2 = this.dialogService.open(PointReportComponent, {
        data: {
          points: [this.point],
          seanceId: this.point.seanceId,
        },
        width: '50vw',
        styleClass: 'super-compos-modal-content ',
        showHeader: false,
        closable: false,
        dismissableMask: false,
        baseZIndex: 1000,
      });
      ref2.onClose.subscribe(() => {
        this.load();
      });
    }
  }

  addEventBefore(event: boolean) {
    if(event){
      this.displayActionsModal = false;

      const ref = this.dialogService.open(EventAddComponent, {
        data: {
          header: this.translate.instant('insertEvent'),
          meetingId: this.point.seanceId,
          type: 'before',
          pointClicked: this.point,
        },
        width: '80vw',
        styleClass: 'super-compos-modal-content ',
        showHeader: false,
        closable: false,
        dismissableMask: false,
        baseZIndex: 1000,
      });
      ref.onClose.subscribe((res: any) => {
        if (res) {
          this.onChangePoint.emit(true);
        }
      });
    }
  }

  //* Allow to delete a point
  deletePoint(point) {
    this.displayActionsModal = false;

    this.confirmationService.confirm({
      message: this.translate.instant('removeFromMeetingConfirm', { pointName: point.title }),
      key: 'actionsMenuConfirmModal',
      accept: () => {
        this.api.removePoint(point.seanceId, this.point.id).subscribe(
          (res: any) => {
            this.toastr.success('Le point a bien été retiré de la séance');
          },
          (err) => {
            console.error(err);
            if (err.error.code === 14101) {
              this.toastr.error(this.translate.instant('nonDeletablePointBecauseEventsLinkedTo'));
            }
          }
        );
      },
    });
  }

  pointUpDown(event) {
    let firstPointIndex = null;
    let firstPoint = null;
    let secondPoint = null;
    let data = null;
    this.api.getMeetingsPointsInclus(this.point.seanceId).subscribe((res: any) => {
      this.points = res.points.map((item) => (item = new Point(item, this.globalElement)));
      this.displayActionsModal = false;
      // On récupère l'index de l'élément qu'on a décider de monter ou descendre
      firstPointIndex = this.points.findIndex((x) => x.id === event.point.id);
      firstPoint = this.points[firstPointIndex];
      if (event.direction === 'up' && firstPointIndex !== 0) {
        secondPoint = this.points[firstPointIndex - 1];
      }
      if (event.direction === 'down' && firstPointIndex !== this.points.length - 1) {
        secondPoint = this.points[firstPointIndex + 1];
      }
      // on a qu'a inverser leurs propriétés ordre et ordreRéel
      if (secondPoint !== null) {
        data = {
          numerotationListe: [
            {
              pointId: firstPoint.id,
              ordre: secondPoint.ordreReel,
              ordreReel: secondPoint.ordreReel,
              triService: firstPoint.triService,
            },
            {
              pointId: secondPoint.id,
              ordre: firstPoint.ordreReel,
              ordreReel: firstPoint.ordreReel,
              triService: secondPoint.triService,
            },
          ],
        };
        this.api.putRenumerotationPoints(this.point.seanceId, data).subscribe((res: any) => {
          this.api.renumerotationSuccess.next(true);
          this.toastr.success('Renumerotation des points effectuées avec succès');
        });
      }
    });
  }

  //* Sets point(s) to be locked/unlocked
  lockUnlock(point) {
    async.waterfall([
      (cb) => {
        const difference_status = [];
        const item = point;
        if (item.modificationAFaireParContactId && item.modificationAutorisee) {
          this.toastr.error(
            'Il y a au moins un point en correction. La correction doit être terminée avant de verrouiller/déverrouiller les points.'
          );
        } else {
          difference_status.push(item.verrouille);
        }
        if (difference_status.length > 0) {
          if (this.allAreEqual(difference_status)) {
            cb(null);
          } else {
            this.toastr.error("Certains points sont verrouillés et d'autres ne le sont pas.");
          }
        }
      },
      (cb) => {
        let pointsIds;
        this.displayActionsModal = false;
        this.confirmationService.confirm({
          message: this.translate.instant(
            'Etes-vous certain de vouloir verrouiller/déverrouiller ce point? (Cette action ne supprime pas les documents générés)'
          ),
          key: 'actionsMenuConfirmModal',
          accept: () => {
            pointsIds = [];
            pointsIds.push(point.id);
            this.api.pointsVerrouiller(this.point.seanceId, { pointIds: pointsIds }).subscribe((res: any) => {
              this.point.verrouille == true ? (this.point.verrouille = false) : (this.point.verrouille = true);
              this.toastr.success('Les modifications ont bien été appliquées');
              if (res.pointIdsWithIssues > 0) {
                const pointsError = res.pointIdsWithIssues;
                this.toastr.info(
                  this.translate.instant('info.points') +
                    ' : ' +
                    pointsError.join(', ') +
                    ' ' +
                    this.translate.instant('info.cannotLock')
                );
              }
            });
          },
        });
      },
    ]);
  }

  //* sets point(s) to be closed/ opened
  finishUnfinishPoint(point) {
    async.waterfall([
      (cb) => {
        const difference_status = [];
        const item = point;
        if (item.modificationAFaireParContactId && item.modificationAutorisee) {
          this.toastr.error(
            'Il y a au moins un point en correction. La correction doit être terminée avant de clôturer/déclôturer les points.'
          );
        } else {
          difference_status.push(item.cloture);
        }
        if (difference_status.length > 0) {
          if (this.allAreEqual(difference_status)) {
            cb(null);
          } else {
            this.toastr.error("Opération non autorisée. Certains points sont clôturés et d'autres ne le sont pas.");
          }
        }
      },
      (cb) => {
        let pointsIds;
        this.displayActionsModal = false;
        this.confirmationService.confirm({
          message: this.translate.instant(
            'Etes-vous certain de vouloir clôturer/déclôturer ce point? (Cette action ne supprime pas les documents générés)'
          ),
          key: 'actionsMenuConfirmModal',
          accept: () => {
            pointsIds = [];
            pointsIds.push(point.id);
            this.api.pointsCloturer(this.point.seanceId, { pointIds: pointsIds }).subscribe((res: any) => {
              this.point.cloture == true ? (this.point.cloture = false) : (this.point.cloture = true);
              this.point.cloture == false ? (this.point.verrouille = true) : (this.point.verrouille = false);
              this.setDropdownStatut({ id: this.point.statutId });
              this.toastr.success('Les modifications ont bien été appliquées');
            });
          },
        });
      },
    ]);
  }

  //* set points to be 'a corriger'
  markACorriger(point) {
    async.waterfall([
      (cb) => {
        // Si on a sélectionner que un seul point
        if (point.length === 1) {
          if (point.cloture === '✅') {
            this.toastr.error("Le point est clôturé. Il ne peut être renvoyé à l'agent pour modification.");
          } else {
            cb(null);
          }
        } else {
          this.confirmationService.confirm({
            message: 'Voulez-vous envoyer les points aux créateurs pour modification?',
            key: 'actionsMenuConfirmModal',
            accept: () => {
              cb(null);
            },
          });
        }
      },
      (cb) => {
        this.displayActionsModal = false;
        const ref = this.dialogService.open(MarktocorrectionComponent, {
          showHeader: false,
          width: '60vw',
          styleClass: 'super-compos-modal-content ',
          data: {
            header: 'Envoyer un point en correction',
            selections: [point],
            mode: 'solo',
            meetingID: this.point.seanceId,
          },
        });
        ref.onClose.subscribe((res: any) => {
          if (res) {
            const url = `/private/meetings/details/${this.point.seanceId}`;
            this.route.navigate([url]);
            window.location.reload();
            cb();
          }
        });
      },
    ]);
  }

  //* sets points to be approved unanimously
  approveUnanimously(point) {
    let existingVote: boolean = false;
    if (
      this.votes.nbrVotesGlobalAbstention > 0 ||
      this.votes.nbrVotesGlobalNegatif > 0 ||
      this.votes.nbrVotesGlobalPositif > 0
    ) {
      existingVote = true;
    }
    if (existingVote) {
      this.confirmationService.confirm({
        message:
          "Cette opération va écraser tous les votes déjà encodés et va mettre tous les points dans un statut d'approbation à l'unanimité, voulez-vous continuer ?",
        key: 'actionsMenuConfirmModal',
        accept: () => {
          this.setVotesApproved(point);
        },
      });
    } else {
      this.confirmationService.confirm({
        message: "Voulez-vous mettre tous les points dans un statut d'approbation à l'unanimité ?",
        key: 'actionsMenuConfirmModal',
        accept: () => {
          this.setVotesApproved(point);
        },
      });
    }
  }

  //* Sets points to have their corrections validated
  validateCorrection(point) {
    const isVerrouille: string = point.verrouille
      ? 'Si oui, vous ne serez plus autorisé à modifier le point ultérieurement.'
      : '';
    this.confirmationService.confirm({
      message:
        'Une correction du point vous a été demandée. Confirmez-vous cette modification comme effectuée ?' +
        isVerrouille,
      key: 'actionsMenuConfirmModal',
      accept: () => {
        this.api.validerCorrection(point.id).subscribe((res: any) => {
          this.toastr.success('La correction a été confirmé');
          const url = `/private/meetings/details/${this.point.seanceId}`;
          this.route.navigate([url]);
          window.location.reload();
        });
      },
    });
  }

  //* sets votes to be approved
  public setVotesApproved(point) {
    let pointsIds;
    this.displayActionsModal = false;
    pointsIds = [];
    pointsIds.push(point.id);
    this.api.changeMassVotes(this.point.seanceId, pointsIds).subscribe((res: any) => {
      this.toastr.success(this.translate.instant('success.voteUpdatedUnanimously'));
    });
  }

  //* verify if items are all equals
  allAreEqual(array) {
    const result = array.every((element) => {
      if (element === array[0]) {
        return true;
      }
    });
    return result;
  }

  goNextPoint() {
    this.detailsService.goToNextPoint(this.point.seanceId);
  }

  goPreviousPoint() {
    this.detailsService.goToPreviousPoint(this.point.seanceId);
  }

  updateWriteLock(writeLockUpdated) {
    this.writeLock = writeLockUpdated;
    const foundContact = this.contacts.find((x) => x.userId === writeLockUpdated.writeLockUserId);
    const currentUser: boolean = this.userInfos.id === writeLockUpdated.writeLockUserId;
    this.writeLock = {
      locked: writeLockUpdated.locked,
      contact: writeLockUpdated.writeLockUserId !== null ? (foundContact ? foundContact.fullName : '') : '',
      currentUser: currentUser,
    };
  }

  setEditRightsFalse() {
    this.userCanDuplicatePoint = false;
    this.userCanModifyPoint = false;
    this.pointsRights.changerStatut = false;
  }

  createOutgoingMail(point: Point) {
    //On va juste envoyer l'id car l'objet de la délibé peut être super longue et donc faire dépasser le nbre de caractères acceptés par l'url
    const encodediDelibeID = encodeURIComponent(point.id);
    const targetUrl = this.globalConfig.frontICourrierUrl + `/public/home?infosPoint=${encodediDelibeID}`;
    window.open(targetUrl, '_blank');
  }

  handleClickAction(event: { action: ActionType, dataToSend: any }) {
    switch (event.action) {
      case ActionType.UpdatePoint:
        this.route.navigateByUrl('/private/points/update/' + this.detailsService.currentID);
        break;
      case ActionType.DuplicatePoint:
        this.duplicatePoint(event.dataToSend);
        break;
      case ActionType.PostponePoint:
        this.postponePoint(event.dataToSend);
        break;
      case ActionType.OpenCreationModelPointBasedOnExistingPoint:
        this.openCreationModelPointBasedOnExistingPoint = event.dataToSend;
        break;
      case ActionType.CreateOutgoingMail:
        this.createOutgoingMail(event.dataToSend);
        break;
      case ActionType.DeletePoint:
        this.deletePoint(event.dataToSend);
        break;
      case ActionType.Up:
      case ActionType.Down:
        this.pointUpDown({ direction: event.action, point: event.dataToSend });
        break;
      case ActionType.AddEventBefore:
        this.addEventBefore(event.dataToSend);
        break;
      case ActionType.FinishUnfinishPoint:
        this.finishUnfinishPoint(event.dataToSend);
        break;
      case ActionType.LockUnlock:
        this.lockUnlock(event.dataToSend);
        break;
      case ActionType.MarkACorriger:
        this.markACorriger(event.dataToSend);
        break;
      case ActionType.ValidateCorrection:
        this.validateCorrection(event.dataToSend);
        break;
      case ActionType.ApproveUnanimously:
        this.approveUnanimously(event.dataToSend);
        break;
      case ActionType.CloseModal:
        this.displayActionsModal = event.dataToSend;
        break;
      default:
        console.error(`Unknown action: ${event.action}`);
    }
  }
}
