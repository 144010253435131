
export enum SignalrPayloadEventId {
    Test = 1000,
    DocumentMergeCompleted,
    PointContenuRtfsChanged,
    SeanceExportDelibeMandataireCompletedData,
    SeanceExportDelibeCitoyenCompletedData,
    ExtraitConformeMergeCompleted
}

export enum PointRtfFileType { 
    Motivation = 0,
    Decision,
    Observations,
    NotesDeSynthese,
    ComplementDeDeliberation
}

export interface NotifyUserSignalrPayload { 
    /**
     * The event identifier
     */
    readonly payloadEventId: SignalrPayloadEventId;
    readonly tenantId: string;
    readonly userId: number;
    readonly data: any | null;
  }

  export interface SignalrDocumentMergeCompletedData {
    readonly requestId: string;
    readonly statutId: number | null;
    readonly userId: number | null;
    readonly seanceId: number | null;
    readonly pointId: number | null;
    readonly lastRequestDateTime: string;
    readonly modeleId: number|null;
    readonly modeleDescription: string;
    readonly seanceDescription: string;
    readonly pointObjetSynthese: string;
    readonly uiOpenRtfToEdit: boolean;
  }

  export interface SignalrPointContenuRtfsChangedData {
    readonly pointId: number;
    readonly pointObjetSynthese : string;
    readonly date : string;
    /**
     * Changed files types
     */
    readonly fileTypes: Array<PointRtfFileType>;
  }

  export interface SignalrTestData {
    readonly message: string | null;
  }

  export interface SignalrSeanceExportDelibeMandataireCompletedData {
    readonly seanceId: number;
    readonly seanceDescription: string;
  }