import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import { MeetingSearchComponent } from 'src/app/shared/components/modals/meeting-search/meeting-search.component';
import { Button } from 'src/app/shared/models/button';
import { Global_Class } from 'src/app/shared/models/global';
import { Meeting } from 'src/app/shared/models/meeting';
import * as async from 'async';
import * as _ from 'underscore';
import { catchError, tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { ITableConfig, SuperColumn } from '@devinforius/super-compos';
import { Observable, of } from 'rxjs';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss'],
})
export class MeetingsComponent implements OnInit {
  public buttons: Array<Button> = [];
  public forselection = [];
  public globalElements: Global_Class;
  public orderCategoryASC = { open: 1, pending: 2, closed: 3 };
  public orderCategoryDESC = { closed: 1, pending: 2, open: 3 };
  public statusTab: Array<any> = [
    {
      header: 'Ouvertes',
      id: 'open',
      icon: 'bi bi-folder2-open',
    },
    {
      header: 'Verrouillées',
      id: 'pending',
      icon: 'bi bi-lock',
    },
    {
      header: 'Cloturées',
      id: 'closed',
      icon: 'bi bi-archive',
    },
  ];
  public tabsArrayXLActiveIndex;
  public selectedStatus: string = 'open';

  public columns: Array<SuperColumn> = [
    {
      field: 'statusOfSeance',
      header: 'status',
      filterable: true,
      sortable: true,
      format: (value, item) => {
        if (item.status === 'open') {
          const state = this.translate.instant('global.open');
          return "<div class='status-green'>" + state + '</div>';
        }
        if (item.status === 'pending') {
          const state = this.translate.instant('global.pending');
          return "<div class='status-blue'>" + state + '</div>';
        }
        if (item.status === 'closed') {
          const state = this.translate.instant('global.closed');
          return "<div class='status-red'>" + state + '</div>';
        }
      },
    },
    {
      field: 'description',
      header: 'name',
      format: (value, item) => {
        if (item.description) {
          return item.description;
        } else {
          if (item.date) {
            moment.locale(localStorage.getItem('lang'));
            const formattedDate = moment(item.date).format(this.translate.instant('format.formatDateSeance'));
            return this.translate.instant('seanceRemplaceDescriptionEmpty', { date: formattedDate });
            // return "Séance du " + moment(item.date).format('DD/MM/YYYY');
          }
        }
      },
      filterable: true,
      sortable: true,
    },
    {
      field: 'pointsInclusCount',
      header: 'Points',
      filterable: true,
      sortable: true,
    },
    {
      field: 'date',
      header: 'meetingDate',
      format: (value) => {
        if (value) {
          const format = this.translate.instant('format.formattedDate');
          return moment(value, 'YYYYMMDD').format(format);
        }
      },
      sort: (a, b, order) => {
        const format = this.translate.instant('format.formattedDate');
        const val1 = moment(a, format).isValid() ? moment(a, format).unix() : null;
        const val2 = moment(b, format).isValid() ? moment(b, format).unix() : null;
        if (val1 === null) return 1;
        else if (val2 === null) return -1;
        else return val1 < val2 ? 1 * order : -1 * order;
      },
      filterable: true,
      sortable: true,
    },
    {
      field: 'heureDebutLabel',
      header: 'Heure de début',
      format: (value, item) => {
        if (item.heureDebut) {
          const hours = item.heureDebut.substring(0, 2);
          const mins = item.heureDebut.substring(2, 4);
          return hours + ':' + mins;
        }
      },
      filterable: true,
      sortable: true,
    },
    {
      field: 'heureFinLabel',
      header: 'Heure de fin',
      format: (value, item) => {
        if (item.heureFin) {
          const hours = item.heureFin.substring(0, 2);
          const mins = item.heureFin.substring(2, 4);
          return hours + ':' + mins;
        }
      },
      filterable: true,
      sortable: true,
    },
    {
      field: 'typeSeanceId',
      header: 'type',
      format: (value) => {
        const type = this.globalElements.types_seances_LV.find((x) => x.value === value);
        if (type) {
          return type.label;
        }
      },
      filterable: true,
    },
    {
      field: 'lieuId',
      header: 'place',
      format: (value) => {
        const type = this.globalElements.lieux_LV.find((x) => x.value === value);
        if (type) {
          return type.label;
        }
      },
      filterable: true,
    },
  ];
  public items: Array<Meeting> = [];
  public menu: MenuItem[] = [
    {
      label: 'btn.openMeeting',
      icon: 'bi bi-arrow-up-right-square',
      tooltip: 'btn.openMeeting',
      command: (data) => {
        this.showDetails(data);
      },
    }
  ];

  public notAllowedMeetingsToBeEdited = [];
  public enCorrectionIds = [];
  public notValidForSeance = [];
  public loading: boolean = false;
  public rows = 50;
  public first = 0;
  public totalRecords: number;
  public dataSearch: any = null;
  public sortField: string;
  public sortOrder: string;
  public tableConfigurations: ITableConfig;
  tableState: any;
  constructor(
    public router: Router,
    public translate: TranslateService,
    private api: ApiService,
    private data: DataService,
    public dialog: DialogService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public confirmService: ConfirmationService,
    public dataService: DataService,
    public adminService: iAdminService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.titleService.setTitle('Séances');
    this.data.setSidebarSmall(false);
    async.waterfall([
      (cb) => {
        this.data.getGlobalElements.subscribe((res: any) => {
          this.globalElements = new Global_Class(res);
          if (this.globalElements.isLoaded()) {
            cb(null);
          }
        });
      },
      (cb) => {
        let isAllowedToAddSeance = false;
        //le droit d'ajouter une séance n'existe que si l'utilisateur a le droit de créer au moins un type de séance!
        for (let i = 0; i < this.globalElements?.rightsPointsSeances?.typeSeanceRights.length; i++) {
          if (this.globalElements.rightsPointsSeances.typeSeanceRights[i].addSeance === true) {
            isAllowedToAddSeance = true;
            break;
          }
        }
        if (isAllowedToAddSeance) {
          this.buttons.push({
            text: this.translate.instant('btn.createMeeting'),
            styleClass: 'green',
            icon: 'fas fa-plus-circle',
            iconPosition: 'left',
            click: () => {
              this.openCreateSeance();
            },
          });
        }
        cb();
      },
      (cb) => {
        this.getTranslation().then(() => {
          const temp = JSON.parse(localStorage.getItem('i-table-state-super-seances-table-2'));
          if(temp){
            temp.sortField === 'statusOfSeance' ? (temp.sortField = 'status') : null;
            temp.sortField === 'heureDebutLabel' ? (temp.sortField = 'heureDebut') : null;
            temp.sortField === 'heureFinLabel' ? (temp.sortField = 'heureFin') : null;
            const order = temp.sortOrder ? (temp.sortOrder === 1 ? (temp.sortOrder = 'ASC') : (temp.sortOrder = 'DESC')) : '';
            this.getMeetings({ Ouvert: true }, 1, temp.sortField, order, true).subscribe();
          } else {
            this.getMeetings({ Ouvert: true }, 1, null, null, true).subscribe();
          }
          window.onbeforeunload = () => this.ngOnDestroy();
        });
      },
    ]);
    this.getMeRightsManagement();
  }

  getITableConfigurations() {
    this.tableConfigurations = new ITableConfig({
      pageSize: this.rows,
      pageFirst: this.first,
      lazy: true,
      totalRecords: this.totalRecords,
      selectionActions: { export: false, remove: false },
      isRowClickable: true,
      resizableColumns: true,
      reorderableColumns: true,
      rowPerPageOptions: [50, 200, 500, 1000, 5000],
      height: this.dataService.advancedSearchEnabled ? 'calc( 100vh - 270px )' : 'calc( 100vh - 160px )',
      baseSortColumn: { field: 'description', currentSort: -1 },
      multiselect: true,
      tableStyle: { width: '100%'}
    });
  }

  getMeRightsManagement() {
    this.adminService.getRightsManagement().subscribe(
      (res: any) => {
        if (res.menu.delibeRight.suppressionSeance) {
          this.menu.push({
            label: this.translate.instant('btn.deleteMeeting'),
            icon: 'bi bi-trash',
            tooltip: this.translate.instant('btn.deleteMeeting'),
            command: (data) => {
              this.deleteMeeting(data);
            },
          });
        }
      },
      (error: any) => {
        this.toastr.error(this.translate.instant('error.retrievingAdminRightsInfo'));
      }
    );
  }

  deleteMeeting(data) {
    this.confirmService.confirm({
      message: this.translate.instant('confirm.archiveMeeting'),
      key: 'meetingModal',
      accept: () => {
        const temp = JSON.parse(localStorage.getItem('i-table-state-super-seances-table-2'));
        this.dataSearch = {
          includeDeleted: false,
          countInclus: true,
          pageNumber: 1,
          pageSize: this.rows,
        };
        switch (this.selectedStatus) {
          case 'open':
            this.dataSearch.Ouvert = true;
            break;
          case 'pending':
            this.dataSearch.Verrouille = true;
            break;
          case 'closed':
            this.dataSearch.Cloture = true;
            break;
        }
        let sortOrder; 
        temp.sortOrder ? (temp.sortOrder === 1 ? (sortOrder = 'ASC') : (sortOrder = 'DESC')) : '';
        this.api.deleteMeeting(data.id).subscribe(
          (res) => {
            this.toastr.success(this.translate.instant('success.deletedMeeting', { item: data.id }));
            this.getMeetings(this.dataSearch, 1, temp.sortField, sortOrder, true).subscribe();
          },
          (error) => {
            this.toastr.error(this.translate.instant('error.errorDeleteMeeting'));
          }
        );
      },
    });


  }

  changeMultipleMeetingsStatus(selectedMeetings, type) {
    //En premier lieu on va vérifier si tous les statuts sont égaux => Si pas on bloque
    const firstStatut = selectedMeetings[0].status;
    let isStatusDifferent = false;
    for (let i = 0; i < selectedMeetings.length; i++) {
      if (selectedMeetings[i].status !== firstStatut) {
        isStatusDifferent = true;
        break;
      }
    }
    if (isStatusDifferent) {
      this.toastr.error(this.translate.instant('allStatutsNotTheSame'));
    } else {
      //si tous les statuts sont égaux, on, va vérifier 1. si l'utilisateur a le droit de modifier et 2. les points en correction pour chaque séance
      const listOfSeanceIds = selectedMeetings.map((x) => x.id);
      this.api.getSeancesStates(listOfSeanceIds).subscribe((res: any) => {
        this.notAllowedMeetingsToBeEdited = [];
        this.enCorrectionIds = [];
        this.notValidForSeance = [];
        for (let i = 0; i < res.length; i++) {
          if (!res[i].canEdit) {
            const indexEdit = selectedMeetings.map((x) => x.id).indexOf(res[i].seanceId);
            this.notAllowedMeetingsToBeEdited.push(selectedMeetings[indexEdit].description);
          }
          if (res[i].pointsInCorrection.length > 0) {
            const index = selectedMeetings.map((x) => x.id).indexOf(res[i].seanceId);
            this.enCorrectionIds.push({
              descSeance: selectedMeetings[index].description,
              points: res[i].pointsInCorrection,
            });
          }
          if (res[i].pointsWithoutStatusValideForSeance.length > 0) {
            this.notValidForSeance.push({
              descSeance: res[i].seanceId,
              points: res[i].pointsWithoutStatusValideForSeance,
            });
          }
        }
        if (this.notAllowedMeetingsToBeEdited.length > 0) {
          this.toastr.error(
            "Vous n'avez pas le droit d'édition d'au moins une séance. Veuillez choisir des séances pour lesquelles vous avez ce droit"
          );
          //TODO faire le component d'affichage des séances à problème
        } else if (this.enCorrectionIds.length > 0) {
          this.toastr.error(
            'Il existe des séances dont des points sont en correction. Veuillez les corriger avant de changer le statut'
          );
          //TODO faire le component d'affichage des séances à problème
        } else {
          //Si on est arrivé ici c'est que toutes les conditions front sont respectées pour afficher les confirmations d'envoi de requête
          let confirmMessage = '';
          if (type === 'open') {
            confirmMessage = this.translate.instant('confirmMessageToOpenSeance');
            if (this.notValidForSeance.length > 0) {
              confirmMessage =
                confirmMessage +
                this.translate.instant('confirmMessageHasNotValidPoints', { amount: this.notValidForSeance.length });
            }
          }
          if (type === 'pending') {
            confirmMessage = this.translate.instant('confirmMessageToPendingSeance');
            if (this.notValidForSeance.length > 0) {
              confirmMessage =
                confirmMessage +
                this.translate.instant('confirmMessageHasNotValidPoints', { amount: this.notValidForSeance.length });
            }
          }
          if (type === 'closed') {
            confirmMessage = this.translate.instant('confirmMessageToClosedSeance');
            if (this.notValidForSeance.length > 0) {
              confirmMessage =
                confirmMessage +
                this.translate.instant('confirmMessageHasNotValidPoints', { amount: this.notValidForSeance.length });
            }
          }

          this.confirmService.confirm({
            message: confirmMessage,
            key: 'meetingModal',
            accept: () => {
              const data = {
                ids: selectedMeetings.map((x) => x.id),
                statut: type === 'open' ? 'Ouverte' : type === 'pending' ? 'Preparee' : 'Fermee',
              };
              this.api.setSeanceStatutes(data).subscribe(
                (response) => {
                  this.toastr.success(this.translate.instant('success.modifiedMeetingsStatus'));
                  if (response.pointIdsWithIssues > 0) {
                    const pointsError = response.pointIdsWithIssues;
                    this.toastr.info(
                      this.translate.instant('info.points') +
                        ' : ' +
                        pointsError.join(', ') +
                        ' ' +
                        this.translate.instant('info.cannotLock')
                    );
                  }
                  this.getMeetings().subscribe();
                },
                (error) => {
                  console.error(error);
                }
              );
            },
          });
        }
      });
    }
    //pour changer le statut : seances/changeStatutes avec les ids en liste + l'énum du statut (voir schema)
  }

  ngOnDestroy() {
    const temp = JSON.parse(localStorage.getItem('i-table-state-super-seances-table-2'));
    if (temp) {
      temp.rows = this.rows;
      temp.first = 0;
      localStorage.setItem('i-table-state-super-seances-table-2', JSON.stringify(temp));
    }
  }

  openCreateSeance() {
    this.router.navigateByUrl('/private/meetings/create');
  }

  showDetails(event) {
    this.router.navigateByUrl('/private/meetings/details/' + event.id);
  }

  getMeetings(data?: any, pageNumber?: number, sortField?: any, sortOrder?: any, setConfig?: boolean) {
    this.spinner.show();
    if (!data) {
      data = {
        includeDeleted: false,
        countInclus: true,
        pageNumber: pageNumber,
        pageSize: this.rows,
        Ouvert: true,
      };
    }
    data.pageSize = this.rows;
    data.countInclus = true;
    data.pageNumber = pageNumber;
    sortField && sortField !== '' ? (data.SortFields = sortField) : null;
    sortOrder && sortOrder !== '' ? (data.SortOrder = sortOrder) : null;
    return this.api.getMeetingsSearch(data).pipe(
      tap((response: any) => {
        const items = Array.isArray(response.items) ? response.items : typeof response === 'object' ? [response] : [];
        this.totalRecords = response.totalCount;
        this.rows = response.pageSize;
        this.first = response.pageNumber - 1;
        this.spinner.hide();
        setConfig === true ? this.getITableConfigurations() : null;
        this.items = items.map((x) => new Meeting(x, this.globalElements));
      }),
      catchError((error) => {
        this.spinner.hide();
        this.toastr.error(this.translate.instant('error.occurred'));
        return of([]);
      })
    );
  }

  onLazyLoad(event) {
    this.rows = event.rows;
    const pageNumber = event.first / event.rows + 1;
    let data;
    switch (this.selectedStatus) {
      case 'open':
        data = {
          Ouvert: true,
        };
        break;
      case 'pending':
        data = {
          Verrouille: true,
        };
        break;
      case 'closed':
        data = {
          Cloture: true,
        };
        break;
    }
      //Quand on est sur la première page et qu'on veut changer les filtres => ca n'envoie pas de requête...
      // if ( this.first !== pageNumber){
      // Pour éviter de faire deux fois l'appel la première fois
      if (!this.dataService.advancedSearchEnabled) {
        this.getMeetings(data, pageNumber, this.sortField, this.sortOrder).subscribe();
      } else {
        this.getMeetings(this.removeEmpty(this.dataSearch), pageNumber,this.sortField, this.sortOrder).subscribe();
      }
      // }
      
    }
    
    columnSort(event) {
    let sortField;
    let sortOrder;
    let data;
    switch (this.selectedStatus) {
      case 'open':
        data = {
          Ouvert: true,
        };
        break;
      case 'pending':
        data = {
          Verrouille: true,
        };
        break;
      case 'closed':
        data = {
          Cloture: true,
        };
        break;
    }
    this.changeSortFieldAndSortOrder(event[0].field, event[0].sort).then((result: any) => {
      sortField = result.field;
      sortOrder = result.order;
      if (!this.dataService.advancedSearchEnabled) {
        this.getMeetings(data, 1, sortField, sortOrder).subscribe();
      } else {
        this.getMeetings(this.removeEmpty(this.dataSearch), 1, sortField, sortOrder).subscribe();
      }
    });
  }

  showAdvancedSearch() {
    const dialog = this.dialog.open(MeetingSearchComponent, {
      width: '60vw',
      showHeader: false,
      data: this.selectedStatus,
    });
    dialog.onClose.subscribe((res: any) => {
      this.dataSearch = null;
      for (const val in res) {
        if (res[val] instanceof Date) {
          res[val] = moment(res[val]).format('YYYY-MM-DD');
        }
      }
      if (res) {
        this.dataSearch = res;
        this.dataService.advancedSearchMeeting = res;
        const item: any = JSON.parse(localStorage.getItem('i-table-state-super-seances-table-2'));
        if(item && item.sortField){
          this.changeSortFieldAndSortOrder(item.sortField, item.sortOrder).then((result: any) => {
            item.sortField = result.field;
            item.sortOrder = result.order;
            this.getMeetings(this.removeEmpty(this.dataSearch), 1, item.sortField, item.sortOrder).subscribe();
          });
        } else {
          this.getMeetings(this.removeEmpty(this.dataSearch), 1).subscribe();
        }
      } else if (res === null) {
        //si on fait une réinit
        this.dataService.advancedSearchMeeting = null;
        const item: any = JSON.parse(localStorage.getItem('i-table-state-super-seances-table-2'));
        if (item && item.sortField) {
          this.changeSortFieldAndSortOrder(item.sortField, item.sortOrder).then((result: any) => {
            item.sortField = result.field;
            item.sortOrder = result.order;
            this.getMeetings(null, 1, item.sortField, item.sortOrder).subscribe();
          });
        } else {
          this.getMeetings(null, 1).subscribe();
        }
      }
    });
  }

  changeSortFieldAndSortOrder(sortField, sortOrder) {
    return new Promise((resolve, reject) => {
      switch (sortField) {
        case 'statusOfSeance': {
          sortField = 'status';
          break;
        }
        case 'heureDebutLabel': {
          sortField = 'heureDebut';
          break;
        }
        case 'heureFinLabel': {
          sortField = 'heureFin';
          break;
        }
        // case 'typeSeanceId': {
        //   sortField = 'seanceTypeName';
        //   break;
        // }
        // case 'lieuId': {
        //   sortField = 'lieuxName';
        //   break;
        // }
        default: {
          break;
        }
      }
      sortOrder ? (sortOrder === 1 ? (sortOrder = 'ASC') : (sortOrder = 'DESC')) : '';
      this.sortField = sortField;
      this.sortOrder = sortOrder;
      resolve({ field: sortField, order: sortOrder });
    });
  }

  removeEmpty(obj) {
    if (obj) {
      return Object.keys(obj)
        .filter(function (k) {
          return obj[k] != null;
        })
        .reduce(function (acc, k) {
          if (!Array.isArray(obj[k]) && !(obj[k] instanceof Date)) {
            acc[k] = typeof obj[k] === 'object' ? this.removeEmpty(obj[k]) : obj[k];
          } else {
            acc[k] = obj[k];
          }
          return acc;
        }, {});
    } else {
      return obj;
    }
  }

  receive_menu(event) {
    this.showDetails(event);
  }

  getTranslation() {
    return new Promise((resolve, reject) => {
      async.waterfall(
        [
          (cb) => {
            const arrayOfKeys = ['modifyToStatusOpen', 'modifyToStatusPending', 'modifyToStatusClosed'];
            this.translate.get(arrayOfKeys).subscribe((res) => {
              this.forselection = [
                {
                  label: res.modifyToStatusOpen,
                  tooltip: res.modifyToStatusOpen,
                  click: (selection) => {
                    this.changeMultipleMeetingsStatus(selection, 'open');
                  },
                },
                {
                  label: res.modifyToStatusPending,
                  tooltip: res.modifyToStatusPending,
                  click: (selection) => {
                    this.changeMultipleMeetingsStatus(selection, 'pending');
                  },
                },
                {
                  label: res.modifyToStatusClosed,
                  tooltip: res.modifyToStatusClosed,
                  click: (selection) => {
                    this.changeMultipleMeetingsStatus(selection, 'closed');
                  },
                },
              ];
            });
            cb(null);
          },
          (cb) => {
            this.columns.map((col) => (col.header = this.translate.instant(col.header)));
            this.menu.map((col) => (col.label = this.translate.instant(col.label)));
            cb();
          },
        ],
        () => {
          resolve(true);
        }
      );
    });
  }

  changeTab(event) {
    this.selectedStatus = event.id;
    let data;
    switch (event.id) {
      case 'open':
        this.tabsArrayXLActiveIndex = 0;
        data = {
          Ouvert: true,
        };
        break;
      case 'pending':
        this.tabsArrayXLActiveIndex = 1;
        data = {
          Verrouille: true,
        };
        break;
      case 'closed':
        this.tabsArrayXLActiveIndex = 2;
        data = {
          Cloture: true,
        };
        break;
    }
    if (this.dataSearch) {
      const keysToReset = ['Ouvert', 'Verrouille', 'Cloture'];
      keysToReset.forEach((key) => (this.dataSearch[key] = null));
      this.dataSearch = { ...this.dataSearch, ...data };
    }
    const searchData = this.dataSearch ? this.removeEmpty(this.dataSearch) : data;
    const hasSortField = this.sortField && this.sortField.trim() !== '';
    const hasSortOrder = this.sortOrder && this.sortOrder.trim() !== '';
    if (hasSortField && hasSortOrder) {
      this.getMeetings(searchData, 1, this.sortField, this.sortOrder, true).subscribe();
    } else {
      this.getMeetings(searchData, 1, null, null, true).subscribe();
    }
    this.dataService.advancedSearchEnabled = false;
  }

  resetAdvancedSearch() {
    this.dataSearch = null;
    this.dataService.advancedSearchEnabled = false;
    this.dataService.advancedSearchMeeting = null;
    this.tabsArrayXLActiveIndex = 0;
    this.getMeetings(null, 1, null, null, true).subscribe();
  }
}
