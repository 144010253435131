<p-table [value]="columns" responsiveLayout="scroll">
     <ng-template pTemplate="header">
     <tr>
          <th *ngFor="let item of columns" >{{item?.header}}</th>
     </tr>
     </ng-template>
     <ng-template pTemplate="body" let-product>
     <tr>
          <td *ngFor="let item of columns"><p-skeleton></p-skeleton></td>
     </tr>
     </ng-template>
</p-table>