<div class="setHeightDiv p-3">
  <h5 class="mb-3">Version actuelle : {{ currentVersion }}</h5>
  <p-accordion *ngIf="data" [multiple]="false">
    <p-accordionTab
      *ngFor="let tab of data; let i = index"
      header="VERSION {{ tab.version }}"
      [selected]="data[0].version === tab.version">
      <p-scrollPanel [style]="{ width: '100%', height: '350px' }" class="mb-3">
        <div *ngFor="let card of tab.text">
          <h3>{{ card.title }}</h3>
          <div *ngIf="card.content.length > 0">
            <div *ngFor="let p of card.content">
              <p>{{ p }}</p>
            </div>
          </div>
          <ul *ngIf="card.list.length > 0">
            <div *ngFor="let list of card.list">
              <li>{{ list }}</li>
            </div>
          </ul>
        </div>
      </p-scrollPanel>
    </p-accordionTab>
  </p-accordion>
  <div class="d-flex flex-column align-items-end">
    <super-button
      btnSize="btn-xs"
      styleClass="green mt-3"
      id="ReadedButton"
      (click)="ref.close()"
      text="J'ai bien lu, merci!"></super-button>
  </div>
</div>
