export class Signatures {
     description: string;
     baseIdentifiantDocument: string;
     texte01: string;
     texte02: string;
     texte03: string;
     texte04: string;
     texte05: string;
     texte01_short: string;
     texte02_short: string;
     texte03_short: string;
     texte04_short: string;
     texte05_short: string;
     priorite:number;
     public id:number;
     constructor(data:any) {
          Object.assign(this, data);
          if ( this.texte01 ){
               this.texte01_short = this.texte01.substring(0,20) + '...';
          }
          if ( this.texte02 ){
               this.texte02_short = this.texte02.substring(0,20) + '...';
          }
          if ( this.texte03 ){
               this.texte03_short = this.texte03.substring(0,20) + '...';
          }
          if ( this.texte04 ){
               this.texte04_short = this.texte04.substring(0,20) + '...';
          }
          if ( this.texte05 ){
               this.texte05_short = this.texte05.substring(0,20) + '...';
          }

     }
}
 