<div class="container-fluid">
  <super-title text="Actions" class="box" level="1"></super-title>
</div>
<div class="container-fluid">
  <p-tabView styleClass="testou" (onChange)="handleModeSwitch($event)">
    <p-tabPanel header="Points"> </p-tabPanel>
    <p-tabPanel header="Séances"> </p-tabPanel>
  </p-tabView>
</div>
<div class="container-fluid main-side">
  <super-tabs class="box pb-4" [items]="actionTabs" (slug)="handleTabChange($event)"></super-tabs>
  <ng-container [ngSwitch]="activeTabSlug">
    <!-- todo Zone  -->
    <ng-container *ngSwitchCase="'todo'">
      <div class="actions-zone p-col-12" *ngIf="tabDataReady; else loading">
        <!-- Pagination  -->
        <app-actions-pagination
          [sizeChoice]="sizeChoice"
          (sizeChoiceChange)="changePageSize($event)"
          [pageNumber]="actionsHolder.todo.pageNumber"
          (pageNumberChange)="changePageNumber('todo', $event)"
          [totalPages]="actionsHolder.todo.totalPages"></app-actions-pagination>
        <!-- Table zone -->
        <div class="actions-zone-table">
          <super-table
            *ngIf="actualPointSeanceTab === 0"
            id="actionsSuperTableWeeks0"
            row="100"
            class="super-table box"
            [columns]="columns"
            [items]="actionsHolder.todo.items"
            [multiselect]="false"
            [enableConfirmColumn]="true"
            [paginator]="false">
          </super-table>
          <super-table
            *ngIf="actualPointSeanceTab === 1"
            id="actionsSuperTableWeeks1"
            row="100"
            class="super-table box"
            [columns]="columnsSeances"
            [items]="actionsHolder.todo.items"
            [multiselect]="false"
            [enableConfirmColumn]="true"
            [paginator]="false">
          </super-table>
        </div>
      </div>
    </ng-container>

    <!-- finished zone  -->
    <ng-container *ngSwitchCase="'finished'">
      <div class="actions-zone p-col-12" *ngIf="tabDataReady; else loading">
        <!-- Pagination  -->
        <app-actions-pagination
          [sizeChoice]="sizeChoice"
          (sizeChoiceChange)="changePageSize($event)"
          [pageNumber]="actionsHolder.finished.pageNumber"
          (pageNumberChange)="changePageNumber('finished', $event)"
          [totalPages]="actionsHolder.finished.totalPages"></app-actions-pagination>
        <!-- Table zone -->
        <div class="actions-zone-table">
          <super-table
            *ngIf="actualPointSeanceTab === 0"
            id="actionsSuperTableFinished0"
            row="100"
            class="super-table box"
            [columns]="columns"
            [items]="actionsHolder.finished.items"
            [multiselect]="false"
            [paginator]="false"
            [enableConfirmColumn]="true">
          </super-table>
          <super-table
            *ngIf="actualPointSeanceTab === 1"
            id="actionsSuperTableFinished1"
            row="100"
            class="super-table box"
            [columns]="columnsSeances"
            [items]="actionsHolder.finished.items"
            [multiselect]="false"
            [paginator]="false"
            [enableConfirmColumn]="true">
          </super-table>
        </div>
      </div>
    </ng-container>

    <!-- All -->
    <ng-container *ngSwitchCase="'all'">
      <div class="actions-zone p-col-12" *ngIf="tabDataReady; else loading">
        <!-- Pagination  -->
        <app-actions-pagination
          [sizeChoice]="sizeChoice"
          (sizeChoiceChange)="changePageSize($event)"
          [pageNumber]="actionsHolder.all.pageNumber"
          (pageNumberChange)="changePageNumber('all', $event)"
          [totalPages]="actionsHolder.all.totalPages"></app-actions-pagination>
        <!-- Table zone -->
        <div class="actions-zone-table">
          <super-table
            *ngIf="actualPointSeanceTab === 0"
            id="actionsSuperTableAll0"
            row="100"
            class="super-table box"
            [columns]="columns"
            [items]="actionsHolder.all.items"
            [multiselect]="false"
            [paginator]="false"
            [enableConfirmColumn]="true">
          </super-table>
          <super-table
            *ngIf="actualPointSeanceTab === 1"
            id="actionsSuperTableAll1"
            row="100"
            class="super-table box"
            [columns]="columnsSeances"
            [items]="actionsHolder.all.items"
            [multiselect]="false"
            [paginator]="false"
            [enableConfirmColumn]="true">
          </super-table>
        </div>
      </div>
    </ng-container>

    <!-- Asked -->
    <ng-container *ngSwitchCase="'asked'">
      <div class="actions-zone p-col-12" *ngIf="tabDataReady; else loading">
        <!-- Pagination  -->
        <app-actions-pagination
          [sizeChoice]="sizeChoice"
          (sizeChoiceChange)="changePageSize($event)"
          [pageNumber]="actionsHolder.asked.pageNumber"
          (pageNumberChange)="changePageNumber('asked', $event)"
          [totalPages]="actionsHolder.asked.totalPages"></app-actions-pagination>
        <!-- Table zone -->
        <div class="actions-zone-table">
          <super-table
            *ngIf="actualPointSeanceTab === 0"
            id="actionsSuperTableAsked0"
            row="100"
            class="super-table box"
            [columns]="columns"
            [items]="actionsHolder.asked.items"
            [multiselect]="false"
            [paginator]="false"
            [enableConfirmColumn]="true">
          </super-table>
          <super-table
            *ngIf="actualPointSeanceTab === 1"
            id="actionsSuperTableAsked1"
            row="100"
            class="super-table box"
            [columns]="columnsSeances"
            [items]="actionsHolder.asked.items"
            [multiselect]="false"
            [paginator]="false"
            [enableConfirmColumn]="true">
          </super-table>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #loading>
  <div class="p-col-12 h-100">
    <div class="box d-flex align-items-center justify-content-center py-4 text-center">
      <h2 class="super-compos-section-h4 py-4">
        Félicitations, il n'y a plus aucune action dans cette catégorie! <br />
        Vous avez bien mérité votre café.
      </h2>
    </div>
    <div class="box d-flex align-items-center justify-content-center py-4">
      <img
        src="./assets/img/undraw_coffee_break_j3of.svg"
        width="35%"
        alt="Aucunes actions dans cette catégorie"
        class="img-fluid" />
    </div>
  </div>
</ng-template>
