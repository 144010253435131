import { Decision_Type } from './decision_type';
import { Echevinat } from './echevinat';
import { Groupe_Politique } from './groupe_pol';
import { LabelValue } from './labelvalue';
import { Matieres } from './matieres';
import { Meeting } from './meeting';
import { Place } from './place';
import { Status_Objets } from './status_objet';
import { TypePoint } from './type_point';
import { TypeSeance } from './type_seance';
import { UrgenceLevel } from './urgence_level';
import { UserInfo } from './user-info';
import { Code } from './code';
import { Classeur } from './classeur';
import { Chemise } from './chemise';
import { Service } from './service';
import { Roles } from './roles';
import { Signatures } from './signature';
import { ConfigApp } from './configApp';
import { Action_Types } from './actions_type';
import { Action_Status } from './actions_status';
export class Global_Class {
  echevinats: Array<Echevinat>;
  echevinats_LV: Array<LabelValue> = [];
  groupes_pol: Array<Groupe_Politique>;
  groupes_pol_LV: Array<LabelValue> = [];
  types_seances: Array<TypeSeance>;
  types_seances_LV: Array<LabelValue> = [];
  matieres: Array<Matieres>;
  matieres_LV: Array<LabelValue> = [];
  seances: Array<Meeting>;
  types_points: Array<TypePoint>;
  types_points_LV: Array<LabelValue> = [];
  urgence_level: Array<UrgenceLevel>;
  urgence_level_LV: Array<LabelValue> = [];
  statuts_points: Array<Status_Objets>;
  statuts_points_LV: Array<LabelValue> = [];
  decision_type: Array<Decision_Type>;
  decision_type_LV: Array<LabelValue> = [];
  classement_code: Array<Code>;
  classement_code_LV: Array<LabelValue> = [];
  classeurs: Array<Classeur>;
  classeurs_LV: Array<LabelValue> = [];
  chemises: Array<Chemise>;
  chemises_LV: Array<LabelValue> = [];
  lieux: Array<Place>;
  lieux_LV: Array<LabelValue> = [];
  services: Array<Service>;
  services_LV: Array<LabelValue> = [];
  roles: Array<Roles>;
  roles_LV: Array<LabelValue> = [];
  signatures: Array<Signatures>;
  signatures_LV: Array<LabelValue> = [];
  rightsManagement: Array<any>;
  rightsPointsSeances;
  configApp: ConfigApp;
  public loaded: boolean = false;
  public userInfo: UserInfo;
  public actions_type: Array<Action_Types> = [];
  public actions_type_lv: Array<LabelValue> = [];
  public actions_status: Array<Action_Status> = [];
  public actions_status_lv: Array<LabelValue> = [];
  constructor(data: any) {
    Object.assign(this, data);
    if (this.echevinats && this.echevinats.length > 0) {
      this.echevinats.map((item) => {
        this.echevinats_LV.push(
          new LabelValue(new LabelValue({ label: item.description, value: item.id, item: item }))
        );
      });
    }
    if (this.groupes_pol && this.groupes_pol.length > 0) {
      this.groupes_pol.map((item) => {
        this.groupes_pol_LV.push(new LabelValue({ label: item.description, value: item.id, item: item }));
      });
    }
    if (this.types_seances && this.types_seances.length > 0) {
      this.types_seances.map((item) => {
        this.types_seances_LV.push(new LabelValue({ label: item.description, value: item.id, item: item }));
      });
    }

    if (this.matieres && this.matieres.length > 0) {
      this.matieres.map((item) => {
        this.matieres_LV.push(new LabelValue({ label: item.description, value: item.id, item: item }));
      });
    }

    if (this.types_points && this.types_points.length > 0) {
      this.types_points.map((item) => {
        this.types_points_LV.push(new LabelValue({ label: item.description, value: item.id, item: item }));
      });
    }
    if (this.urgence_level && this.urgence_level.length > 0) {
      this.urgence_level.map((item) => {
        this.urgence_level_LV.push(new LabelValue({ label: item.description, value: item.id, item: item }));
      });
    }
    if (this.statuts_points && this.statuts_points.length > 0) {
      this.statuts_points.map((item) => {
        this.statuts_points_LV.push(new LabelValue({ label: item.description, value: item.id, item: item }));
      });
    }
    if (this.decision_type && this.decision_type.length > 0) {
      this.decision_type.map((item) => {
        this.decision_type_LV.push(new LabelValue({ label: item.description, value: item.id, item: item }));
      });
    }
    if (this.classement_code && this.classement_code.length > 0) {
      this.classement_code.map((item) => {
        const desc = item.description ? '(' + item.description + ')' : '';
        this.classement_code_LV.push(new LabelValue({ label: item.code + desc, value: item.id, item: item }));
      });
    }
    if (this.classeurs && this.classeurs.length > 0) {
      this.classeurs.map((item) => {
        this.classeurs_LV.push(
          new LabelValue({
            label: item.description === '' ? 'N/A Pas de description' : item.description,
            value: item.id,
            item: item,
          })
        );
      });
    }
    if (this.chemises && this.chemises.length > 0) {
      this.chemises.map((item) => {
        this.chemises_LV.push(
          new LabelValue({
            label: item.description === '' ? 'N/A Pas de description' : item.description,
            value: item.id,
            item: item,
          })
        );
      });
    }

    if (this.lieux && this.lieux.length > 0) {
      this.lieux.map((item) => {
        this.lieux_LV.push(new LabelValue({ label: item.description, value: item.id, item: item }));
      });
    }
    if (this.services && this.services.length > 0) {
      this.services.map((item) => {
        this.services_LV.push(new LabelValue({ label: item.description, value: item.id, item: item }));
      });
    }
    if (this.roles && this.roles.length > 0) {
      this.roles.map((item) => {
        this.roles_LV.push(new LabelValue({ label: item.description, value: item.id, item: item }));
      });
    }
    if (this.signatures && this.signatures.length > 0) {
      this.signatures.map((item) => {
        this.signatures_LV.push(new LabelValue({ label: item.description, value: item.id, item: item }));
      });
    }

    if (this.actions_type && this.actions_type.length > 0) {
      this.actions_type.map((item) => {
        this.actions_type_lv.push(new LabelValue({ label: item.description, value: item.id, item: item }));
      });
    }

    if (this.actions_status && this.actions_status.length > 0) {
      this.actions_status.map((item) => {
        this.actions_status_lv.push(new LabelValue({ label: item.description, value: item.id, item: item }));
      });
    }
    this.isLoaded();
  }

  isLoaded() {
    if (
      (this.echevinats && this.echevinats.length > 0) ||
      (this.groupes_pol && this.groupes_pol.length > 0) ||
      (this.types_seances && this.types_seances.length > 0) ||
      (this.matieres && this.matieres.length > 0) ||
      (this.types_points && this.types_points.length > 0) ||
      (this.urgence_level && this.urgence_level.length > 0) ||
      (this.statuts_points && this.statuts_points.length > 0) ||
      (this.classement_code && this.classement_code.length > 0) ||
      (this.classeurs && this.classeurs.length > 0) ||
      (this.chemises && this.chemises.length > 0) ||
      (this.lieux && this.lieux.length > 0) ||
      (this.actions_type && this.actions_type.length > 0) ||
      (this.actions_status && this.actions_status.length)
    ) {
      return true;
    } else {
      return false;
    }
  }
}

export class GlobalConfig {
  clientName: string;
  clientType: string;
  delibConfig: {
    is3PEnabled: boolean;
    isiDelibeCitoyensEnabled: boolean;
    isiDelibeMandatairesEnabled: boolean;
    moduleEnabled: boolean;
  };
  description: string;
  email: string;
  phone: string;
  theme: string;
  urlDocumentation: string;
  webSite: string;
}
