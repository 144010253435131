<div *ngIf="totalRecords > 0">
  <p-selectButton
    (onChange)="filterItems()"
    [options]="activeStates"
    [(ngModel)]="activeStateRecipient"
    *ngIf="isAllowedToFilterByStateActive"></p-selectButton>
  <p-table
    #dt
    [value]="lists"
    [columns]="columns"
    [responsive]="true"
    [rows]="rowsToShow"
    [scrollHeight]="height"
    [scrollable]="true"
    [totalRecords]="totalRecords"
    class="super-compos-table"
    dataKey="name"
    [paginator]="true"
    (onLazyLoad)="layLoadData($event)"
    [lazy]="true"
    [loading]="loading">
    <ng-template pTemplate="caption">
      <div class="row">
        <div
          class="p-fluid"
          [ngClass]="{ 'col-md-6': typeContact && typeContact.length > 0, 'col-md-12': !typeContact }">
          <input
            pInputText
            type="text"
            (input)="dt.filter($event.target.value, 'name', 'contains')"
            placeholder="Rechercher dans la liste" />
        </div>
        <div class="col-6" *ngIf="typeContact && typeContact.length > 0">
          <p-dropdown
            styleClass="w-100"
            [options]="typeContact | sort: 'label' : 'ASC'"
            baseZIndex="1000"
            placeholder="Rechercher via un type de contact"
            [showClear]="true"
            appendTo="body"
            [(ngModel)]="searchTypeContact"
            (onChange)="filter('typeContactID', searchTypeContact, 'userActive', activeStateRecipient)"
            id="searchByTypeContact">
            <ng-template let-item pTemplate="item">
              <div class="d-flex align-items-center justify-content-between">
                <div class="truncateLabel">{{ item.label }}</div>
                <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr *ngIf="boolShowHeader">
        <th *ngFor="let col of columns; let i = index" [pSortableColumn]="col.field">
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-columns="columns" let-item let-expanded="expanded">
      <tr [ngClass]="{ 'active-line': activeLine === item.id }" (click)="toggleLine(item); fct(click, item)">
        <td *ngIf="boolMultiSelect" class="tdCheckboxWidth">
          <label class="containerc containerc--block">
            <input type="checkbox" (change)="selectItem($event, item)" [(ngModel)]="item.checked" />
            <span class="checkmark d-block position-relative"></span>
          </label>
        </td>
        <td *ngIf="boolImageMode" class="tdCheckboxWidth">
          <ngx-avatar *ngIf="!item.picture" name="{{ item.name }}" size="50" initialsSize="2"></ngx-avatar>
        </td>
        <td *ngFor="let col of columns; last as last">
          <div class="tdApList" [ngClass]="{ 'text-right': last && alignLast === 'right' }">
            {{ item[col.field] }}
          </div>
        </td>

        <td *ngIf="deletable">
          <button class="btn btn-danger form-control mr-2 ml-2" (click)="deleteRecipient(item)">Supprimer</button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-confirmDialog
  baseZIndex="560"
  key="deleteRecipient"
  #cd
  styleClass="super-compos-modal-content confirmModal"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  *ngIf="deletable">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Non" (click)="cd.reject()"></button>
    <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="Oui" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>
