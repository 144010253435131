<p-header>
  <div class="modal-header super-compos-modal-header">
    <h4 class="modal-title super-compos-modal-title">
      <span class="super-compos-modal-title super-compos-modal-title-span">{{ 'advancedSearch' | translate }}</span>
    </h4>
    <div class="d-flex justify-content-end">
      <super-button
        text="{{ 'back' | translate }}"
        (click)="dialogRef.close()"
        styleClass="blue mr-2"
        pTooltip="Retour"
        tooltipZIndex="99999999"
        tooltipPosition="bottom">
      </super-button>
      <super-button
        text="{{ 'btn.reset' | translate }}"
        (click)="reset()"
        styleClass="red mr-2"
        pTooltip="Réinitialiser"
        tooltipZIndex="99999999"
        tooltipPosition="bottom">
      </super-button>
      <super-button
        id="btnCourrierCreate"
        text="{{ 'search' | translate }}"
        (click)="onCloseDialog()"
        pTooltip="{{ 'search' | translate }}"
        styleClass="green"
        tooltipPosition="bottom"
        tooltipZIndex="99999999">
      </super-button>
    </div>
  </div>
</p-header>
<form class="modal-body" [formGroup]="form">
  <div class="row mt-4">
    <div class="col-md-12 mb-3 p-fluid">
      <label class="input-label d-block">{{ 'includeDeleted' | translate }}</label>
      <p-inputSwitch formControlName="IncludeDeleted"></p-inputSwitch>
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="input-label">{{ 'description' | translate }}</label>
      <input #meetingSearchInput type="text" formControlName="Description" class="form-control" pInputText />
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="super-compos-label-input">{{ 'seanceType' | translate }}</label>
      <!-- <p-dropdown [virtualScroll]="true" itemSize="30" [options]="globalElements?.types_seances_LV" formControlName="TypeSeanceIds" [placeholder]="'selectATypeOfMeeting'|translate" appendTo="body" [showClear]="true" [filter]="true">
                    <ng-template let-item pTemplate="item">
                         <div class="d-flex align-items-center justify-content-between">
                                 <div class="truncateLabel">{{item.label}}</div>
                                 <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                         </div>
                     </ng-template>
               </p-dropdown> -->
      <p-multiSelect
        appendTo="body"
        [options]="globalElements?.types_seances_LV | sort: 'label' : 'ASC'"
        [filter]="true"
        [placeholder]="'selectOneOrMoreElements' | translate"
        formControlName="TypeSeanceIds">
        <ng-template let-title pTemplate="item">
          <div pTooltip="{{ title?.label }}" tooltipPosition="right" class="truncateLabel">{{ title.label }}</div>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="input-label d-block">{{ 'places' | translate }}</label>
      <p-multiSelect
        appendTo="body"
        [options]="globalElements?.lieux_LV | sort: 'label' : 'ASC'"
        [filter]="true"
        [placeholder]="'selectOneOrMoreElements' | translate"
        formControlName="LieuxIds">
        <ng-template let-title pTemplate="item">
          <div pTooltip="{{ title?.label }}" tooltipPosition="right" class="truncateLabel">{{ title.label }}</div>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="super-compos-label-input">{{ 'date' | translate }}</label>
      <p-calendar
        formControlName="Date"
        [selectOtherMonths]="true"
        [showIcon]="true"
        appendTo="body"
        dateFormat="dd/mm/yy"></p-calendar>
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="input-label d-block">{{ 'status' | translate }}</label>
      <p-multiSelect
        appendTo="body"
        [options]="seanceStatus"
        [filter]="true"
        [placeholder]="'selectOneOrMoreElements' | translate"
        formControlName="statutId">
        <ng-template let-title pTemplate="item">
          <div pTooltip="{{ title?.label }}" tooltipPosition="right" class="truncateLabel">{{ title.label }}</div>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="col-md-6 mb-3 p-fluid"></div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="super-compos-label-input">{{ 'validFrom' | translate }}</label>
      <p-calendar
        formControlName="DateDebut"
        [selectOtherMonths]="true"
        [showIcon]="true"
        appendTo="body"
        dateFormat="dd/mm/yy"></p-calendar>
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="super-compos-label-input">{{ 'validTo' | translate }}</label>
      <p-calendar
        formControlName="DateFin"
        [selectOtherMonths]="true"
        [showIcon]="true"
        appendTo="body"
        dateFormat="dd/mm/yy"></p-calendar>
    </div>
  </div>
</form>
