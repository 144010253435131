<article class="element-from-list align-items-center" *ngIf="item" (click)="goTo(item)" [ngClass]="{'element-from-list--active': item?.id && item?.id == detailsService.currentID,
     'element-border-vote--orange':item?.verrouille,
     'element-border-vote--red':item?.cloture,
     'pl-2': item?.verrouille || item?.cloture}">
     <span class="element-order ml-2" *ngIf="item?.ordre >= 0">{{item?.ordre}}</span>
     <i *ngIf="item?.type === 'event'" class="bi bi-bell-fill mr-2"></i>
     <div class="element-content"
          [ngClass]="{'element-status-green': item?.status === 'open','element-status-orange': item?.status === 'pending','element-status-red': item?.status === 'closed'}">
          <h3 class="element-title"
               *ngIf="!item?.isIntroduction && !item?.isConclusion && !item?.isTitle && item?.type !== 'event'">
               {{item?.title}}</h3>
          <h3 class="element-title mb-0"
               *ngIf="item?.isIntroduction || item?.isConclusion || item?.isTitle || item?.type === 'event'">
               {{item?.title|translate}}</h3>
          <div class="d-flex align-items-center" *ngIf="item?.type !== 'event'">
               <span class="element-cat mr-2" *ngIf="item?.matiere"
                    [pTooltip]="getTooltip('matter',item?.matiere)">{{item?.matiere|maxLength:18}}</span>
               <span class="element-cat mr-2" *ngIf="item?.service"
                    [pTooltip]="getTooltip('department',item?.service)">{{item?.service|maxLength:18}}</span>
               <span class="element-cat" *ngIf="item?.typePoint"
                    [pTooltip]="getTooltip('pointType',item?.typePoint)">{{item?.typePoint|maxLength:18}}</span>
          </div>
          <div class="element-votecontent justify-content-between mr-2"
               *ngIf="item?.type !== 'event' && !item?.isIntroduction && !item?.isConclusion && !item?.isTitle">
               <div class="d-flex mt-1">
                    <span [innerHTML]="item?.pointVotesHTML" [pTooltip]="item?.pointVotesHTMLTooltip"></span>
               </div>
               <div *ngIf="item?.modificationAutorisee && item?.modificationAFaireParContactId"
                    class="element-votes element-votes__abstention mr-2" pTooltip="En correction"
                    tooltipPosition="bottom">En correction</div>
               <div *ngIf="item?.confidentiel" class="d-flex align-items-center justify-content-end ">
                    <span class="element-public">{{'confidential'|translate}}</span>
                    <i class="bi bi-eye-slash-fill ml-2"></i>
               </div>
               <p class="idPoint mb-0">{{ item?.id | hundredPoint }}</p>
          </div>
     </div>
</article>