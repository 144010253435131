<div class="modal-header super-compos-modal-header">
    <h4 class="modal-title super-compos-modal-title">
        <span class="noItalic super-compos-modal-title super-compos-modal-title-span">{{updateMode ? 'Mettre à jour' : text}}</span>
    </h4>
    <div class="d-flex justify-content-end">
        <super-button id="modalButtonContactCancel" styleClass="blue mr-2" text="Retour" pTooltip="Retour" tooltipZIndex="99999999" tooltipPosition="bottom" (click)="close()"></super-button>
        <super-button id="modalButtonContactSave" [disabled]="isDisabled" *ngIf="updateMode === false" text="Enregistrer" styleClass="green" tooltipPosition="bottom" tooltipZIndex="99999999" [pTooltip]="btnTooltip" (click)="onSubmit()"></super-button>
        <super-button id="modalButtonContactUpdate" [disabled]="isDisabled" *ngIf="updateMode === true" text="Enregistrer" styleClass="green" tooltipPosition="bottom" tooltipZIndex="99999999" [pTooltip]="btnTooltip" (click)="update()"></super-button>
    </div>

</div>
<div class="modal-body">
    <div [formGroup]="contactForm" class="form-contact mb" (ngSubmit)="onSubmit()">
        <p-tabView>
            <p-tabPanel header="Informations" leftIcon="fal fa-info-circle">
                <div class="row mb-2" *ngIf="!hideSwitch">
                    <div class="col-12">

                        <label class="super-compos-label-input">Personne Physique ou société*</label>
                        <p-dropdown [virtualScroll]="true" itemSize="30" styleClass="w-100" [options]="typeSocieteList" [required]="true" placeholder="Sélectionnez" formControlName="typeSociete" name="typeSociete" (onChange)="switchToCompany()">
                            <ng-template let-item pTemplate="item">
                                <div class="d-flex align-items-center justify-content-between">
                                        <div class="truncateLabel">{{item.label}}</div>
                                        <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="row mb-2" *ngIf="!companyMode">
                    <div class="col-4 mb-2">
                        <label class="super-compos-label-input">Prénom</label>
                        <input formControlName="prenom" autocomplete="new-password" type="text" class="super-compos-input-text form-control w-100" placeholder="Prénom" name="prenom">
                    </div>
                    <div class="col-4 mb-2">
                        <label class="super-compos-label-input">Nom*</label>
                        <input id="inputNewContactName" formControlName="nom" type="text" autocomplete="new-password" class="super-compos-input-text form-control w-100" placeholder="Nom" name="nom">
                        <div *ngIf="f.nom.invalid" class="input-error">
                            Le nom est requis et ne doit pas dépasser 200 caractères
                        </div>
                    </div>
                    <div class="col-md-4 mb-2">
                      <label class="super-compos-label-input">Email</label>
                      <input formControlName="mail" type="text" class="super-compos-input-text form-control w-100" placeholder="Email" name="mail" autocomplete="new-password">
                  </div>
                </div>
                <div class="row" *ngIf="!companyMode">
                    <div class="col-6 mb-2">
                        <label class="super-compos-label-input">Titre</label>
                        <p-dropdown [virtualScroll]="true" itemSize="30" styleClass="w-100" [options]="contactTitresList" placeholder="Sélectionnez un titre" [showClear]="true" formControlName="titre" id="titre">
                            <ng-template let-item pTemplate="item">
                                <div class="d-flex align-items-center justify-content-between">
                                        <div class="truncateLabel">{{item.label}}</div>
                                        <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="col-6">
                        <label class="super-compos-label-input">Langue*</label>
                        <p-dropdown [virtualScroll]="true" itemSize="30" styleClass="w-100" [options]="langues" [required]="true" placeholder="Sélectionnez une langue" [showClear]="true" formControlName="langueId" name="langueId">
                            <ng-template let-item pTemplate="item">
                                <div class="d-flex align-items-center justify-content-between">
                                        <div class="truncateLabel">{{item.label}}</div>
                                        <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <div *ngIf="f.langueId.invalid" class="input-error">
                            La langue est requise
                        </div>
                    </div>
                    <div class="col-6">
                        <label class="super-compos-label-input">Numéro de registre national</label>
                        <input formControlName="numeroCarteNISS" type="text" class="super-compos-input-text form-control w-100" placeholder="Numéro de registre national" name="numeroCarteNISS">
                    </div>
                </div>
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$ Company Mode $$$$$$$$$$$$$$$$$$$$$$$$$ -->
                <div *ngIf="companyMode">
                    <div class="row mb-2">
                        <div class="col-6">
                            <label class="super-compos-label-input">Nom*</label>
                            <input formControlName="nom" type="text" class="super-compos-input-text form-control w-100" placeholder="Nom" name="nom">
                            <div *ngIf="f.nom.invalid" class="input-error">
                                Le nom est requis et ne doit pas dépasser 200 caractères
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="super-compos-label-input">Secteur d'activité</label>
                            <p-dropdown [virtualScroll]="true" itemSize="30" styleClass="w-100" [required]="true" [options]="secteursList|sort:'label':'ASC'" placeholder="Sélectionnez un secteur d'activité" [showClear]="true" formControlName="secteurActivite" id="secteurActivite">
                                <ng-template let-item pTemplate="item">
                                    <div class="d-flex align-items-center justify-content-between">
                                            <div class="truncateLabel">{{item.label}}</div>
                                            <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <label class="super-compos-label-input">TVA</label>
                            <div class="d-flex justify-content-between row">
                                <div class="col-md-4">
                                    <p-dropdown [virtualScroll]="true" itemSize="30" [required]="true" [options]="codeTvaList|sort:'label':'ASC'" placeholder="Code Pays" [showClear]="false" formControlName="tvaPrefix" (onChange)="onTvaCodeChg($event.value)" name="tvaPrefix" autocomplete="BE" styleClass="w-100">
                                        <ng-template let-item pTemplate="item">
                                            <div class="d-flex align-items-center justify-content-between">
                                                    <div class="truncateLabel">{{item.label}}</div>
                                                    <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                                <div class="col-md-8">
                                    <p-inputMask *ngIf="!tvaFrMode" formControlName="tva" [mask]="tvaMask" appendTo="body" [characterPattern]="tvaRegex" styleClass="super-compos-input-text form-control w-100" [placeholder]="tvaPlaceholder" name="tva" [pTooltip]="tvaTooltip" tooltipZIndex="99999999"
                                        tooltipPosition="top" [autoClear]="false">
                                    </p-inputMask>
                                    <p-inputMask *ngIf="tvaFrMode" formControlName="tva" mask="FR-**999999999" styleClass="super-compos-input-text form-control w-100" placeholder="FR-**999999999" name="tva" pTooltip="Code pays + 11 chiffres (le premier et le deuxième caractère peut être une lettre)."
                                        tooltipZIndex="99999999" tooltipPosition="top" [autoClear]="false">
                                    </p-inputMask>
                                    <div *ngIf="f.tva.invalid" class="input-error" placeholder="TVA">
                                        La TVA est requise
                                    </div>
                                </div>
                            </div>

                            <!-- <input formControlName="tva" type="text" class="super-compos-input-text form-control w-100"
                                placeholder="TVA"> -->


                        </div>
                        <div class="col-6">
                            <label class="super-compos-label-input">Forme juridique</label>
                            <p-dropdown [virtualScroll]="true" itemSize="30" styleClass="w-100" [required]="true" [options]="formesJuridiques|sort:'label':'ASC'" placeholder="Sélectionnez une forme juridique" [showClear]="true" formControlName="societeFormeJuridiqueId" id="societeFormeJuridiqueId">
                                <ng-template let-item pTemplate="item">
                                    <div class="d-flex align-items-center justify-content-between">
                                            <div class="truncateLabel">{{item.label}}</div>
                                            <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <label class="super-compos-label-input">Bce</label>
                            <!-- <input formControlName="bce" type="text" class="super-compos-input-text form-control w-100"
                                placeholder="Bce"> -->
                            <p-inputMask *ngIf="codePays === 'BE'" formControlName="bce" [mask]="bceMask" styleClass="super-compos-input-text form-control w-100" [placeholder]="bcePlaceholder" [title]="bceTooltip" name="bce">
                            </p-inputMask>
                            <input *ngIf="codePays !== 'BE'" formControlName="bce" type="text" class="super-compos-input-text form-control w-100" placeholder="Bce" name="bce">
                            <!-- <p-inputMask *ngIf="codePays !== 'BE'" formControlName="bce"
                                styleClass="super-compos-input-text form-control w-100" [placeholder]="bcePlaceholder"
                                [title]="bceTooltip">
                            </p-inputMask> -->
                        </div>
                        <div class="col-6">
                            <label class="super-compos-label-input">Langue*</label>
                            <p-dropdown [virtualScroll]="true" itemSize="30" styleClass="w-100" [options]="langues|sort:'label':'ASC'" [required]="true" placeholder="Sélectionnez une langue" [showClear]="true" formControlName="langueId" name="languId">
                                <ng-template let-item pTemplate="item">
                                    <div class="d-flex align-items-center justify-content-between">
                                            <div class="truncateLabel">{{item.label}}</div>
                                            <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <div *ngIf="f.langueId.invalid" class="input-error">
                                La langue est requise
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <label class="super-compos-label-input">
                                Gérant
                            </label>
                            <input formControlName="gerantSociete" type="text" class="super-compos-input-text form-control w-100" placeholder="Nom + prénom" name="gerantSociete">
                        </div>
                    </div>
                </div>
                <div class="form__part2 mb-4">
                    <h3 class="super-compos-titre-h3 mt-3">Options du contact</h3>
                    <div class="row mb-2">
                        <div class="col-6">
                            <label class="super-compos-label-input">Type de contact * <i *ngIf="connectedContactAccessContacts" (click)="openNewTypeContact()" class="fas fa-plus-circle blueIcon clickableIcon"></i></label>
                            <p-dropdown [virtualScroll]="true" itemSize="30" styleClass="w-100" [required]="true" [options]="typeContacts|sort:'label':'ASC'" placeholder="Sélectionnez un type de contact" [showClear]="true" formControlName="typeContactId" id="typeContactId">
                                <ng-template let-item pTemplate="item">
                                    <div class="d-flex align-items-center justify-content-between">
                                            <div class="truncateLabel">{{item.label}}</div>
                                            <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <div *ngIf="f.typeContactId.invalid" class="input-error">
                                Le type de contact est requis
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="super-compos-label-input">Nom interne</label>
                            <input formControlName="nomInterne" type="text" class="super-compos-input-text form-control w-100" placeholder="Nom interne" name="nomInterne" autocomplete="new-password">
                        </div>
                    </div>
                </div>
                <div class="form__part4 mb-3">
                    <div class="row mb-3">
                        <div class="col-md-12 d-flex align-items-center justify-content-between">
                            <h3 class="super-compos-titre-h3 mt-3">Adresses</h3>
                            <super-button text="Ajouter une adresse" styleClass="green" size="small" (click)="add()" id="addAddress"></super-button>
                        </div>
                        <div class="col-md-12 mt-2">
                            <div class="alert alert-warning" *ngIf="getAdressPrincipal.controls.length === 0" role="alert">
                                Pas d'adresse encodée.(Il est recommandé d'en ajouter une)
                            </div>
                        </div>

                    </div>
                    <div class="form__part4__primary">
                        <div formArrayName="listContactsAdresses" *ngFor="let item of getAdressPrincipal.controls; let i = index;">
                            <form class="row mb-2 align-content-center" autocomplete="new-password" [formGroupName]="i">
                                <div class="col-md-12 row pr-0 mr-0">
                                    <div class="col-12 mb-2 pr-0">
                                        <label class="super-compos-label-input d-block">Type d'adresse</label>
                                        <p-dropdown [virtualScroll]="true" itemSize="30" styleClass="w-100" [options]="typesAdresses" placeholder="Sélectionnez un type d'adresse" [showClear]="true" formControlName="type" id="typeAdd{{i}}">
                                            <ng-template let-item pTemplate="item">
                                                <div class="d-flex align-items-center justify-content-between">
                                                        <div class="truncateLabel">{{item.label}}</div>
                                                        <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                        <!-- <div *ngIf="item.get('type').invalid" class="input-error">
                                            Le type d'adresse est requis
                                        </div> -->
                                    </div>
                                    <div class="col-md-12 mt-3 mb-2">
                                        <h3 class="contact__subtitle">Adresse structurée</h3>
                                    </div>
                                    <div class="col-md-3 mb-2 p-fluid">
                                        <label class="super-compos-label-input">Pays</label>
                                        <p-autoComplete id="autocompleteAddressContactCountry{{i}}" formControlName="pays" type="search" #Dropdown baseZIndex="10" styleClass="w-100" appendTo="body" [suggestions]="filteredCountries" (completeMethod)="search($event, 'countries')" field="description" dataKey="id" placeholder="Pays" (onSelect)="getPostalCodes($event)">
                                        </p-autoComplete>
                                        <!-- <div *ngIf="item.get('pays')?.invalid" class="input-error">
                                            Le pays est requis
                                        </div> -->
                                    </div>
                                    <div class="col-md-3 mb-2 p-fluid input-api">
                                        <label class="super-compos-label-input">Code postal - Localité <i *ngIf="connectedContactAccessContacts" (click)="openNewPostalCodeModal(i,'structure')" class="fas fa-plus-circle blueIcon clickableIcon"></i></label>

                                        <super-autocomplete [actualValue]="item.get('structure_LocaliteId').value" *ngIf="countryID && countryID.description !== 'France'" [items]="postalCodesList" placeholder="Code postal - Localité" (selectedElement)="patchValue($event,'structure_LocaliteId',i)"></super-autocomplete>
                                        <p-autoComplete id="dropdownCPLocaliteFranceContact{{i}}" *ngIf="countryID && countryID.description === 'France'" baseZIndex="10" #Dropdown2 (onFocus)="focusAutoComplete()" formControlName="structure_LocaliteId" appendTo="body" [suggestions]="filteredPostalCodes" type="search" inputStyleClass="input-api" styleClass="w-100" (completeMethod)="search($event, 'postalCode')" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" minLength="5"
                                        field="autoCompleteField" dataKey="id" placeholder="Code postal - Localité" (onSelect)="getStreets($event,i)">
                                        </p-autoComplete>

                                        <!-- <div *ngIf="item.get('structure_LocaliteId')?.invalid" class="input-error">
                                            Code postal - Localité requis
                                        </div> -->
                                    </div>
                                    <div class="col-md-3 mb-2 p-fluid">
                                        <label class="super-compos-label-input">Rue <i *ngIf="connectedContactAccessContacts" (click)="openNewStreetModal(i,'structure')" class="fas fa-plus-circle blueIcon clickableIcon"></i></label>
                                        <super-autocomplete *ngIf="streetsList && streetsList.length > 0" [actualValue]="item.get('structure_RueId').value" labelField="rueNom" minLength="3" [items]="streetsList" placeholder="Rue" (selectedElement)="patchValue($event,'structure_RueId',i)"></super-autocomplete>

                                        <!-- <div *ngIf="item.get('structure_RueId')?.invalid" class="input-error">
                                            La rue est requise
                                        </div> -->
                                    </div>
                                    <div class="col-md-2 mb-2 p-fluid">
                                        <label class="super-compos-label-input">Numéro</label>
                                        <input id="inputNumeroContact{{i}}" formControlName="structure_RueNumero" autocomplete="new-password" type="search" class="super-compos-input-text form-control w-100" placeholder="Numéro">
                                        <!-- <div *ngIf="item.get('structure_RueNumero')?.invalid" class="input-error">
                                            Le numéro est requis
                                        </div> -->
                                    </div>
                                    <div class="col-md-1 mb-2 pr-0 p-fluid">
                                        <label class="super-compos-label-input">Boite</label>
                                        <input formControlName="structure_RueBoite" type="search" autocomplete="new-password" class="super-compos-input-text form-control w-100" placeholder="Boite">
                                    </div>
                                    <div class="col-md-12 mt-2 pr-0 mb-2 d-flex align-items-center">
                                        <super-button id="impressionAddressContactButton" text="{{!showImpressionInput? 'Afficher' : 'Masquer'}} l'adresse d'impression" styleClass="blue" size="small" (click)="showImpressionInput= !showImpressionInput"></super-button>
                                        <super-button id="copyImpressionAddressContactButton" *ngIf="showImpressionInput" pTooltip="Convertir l'adresse structuré en impression" tooltipPositon="bottom" text="Générer l'adresse d'impression" styleClass="blue ml-3" size="small" (click)="convertImpression(i)"></super-button>
                                    </div>
                                    <div class="col-md-12 mt-3 mb-2" *ngIf="showImpressionInput">
                                        <h3 class="contact__subtitle" pTooltip="Adresse qui sera imprimée sur les courriers générés" tooltipPosition="bottom">Adresse d'impression</h3>
                                    </div>
                                    <div class="col-md-3 mb-2" *ngIf="showImpressionInput">
                                        <label class="super-compos-label-input">Pays</label>
                                        <input type="search" id="impressionPaysContactInput" pInputText autocomplete="new-password" formControlName="impression_Pays" class="super-compos-input-text form-control w-100" placeholder="Pays">
                                    </div>
                                    <div class="col-md-3 mb-2" *ngIf="showImpressionInput">
                                        <label class="super-compos-label-input">Code postal <i *ngIf="connectedContactAccessContacts" (click)="openNewPostalCodeModal(i,'impression')" class="fas fa-plus-circle blueIcon clickableIcon"></i></label>
                                        <input type="search" id="impressionCodePostalContactInput" pInputText autocomplete="new-password" formControlName="impression_CodePostal" class="super-compos-input-text form-control w-100" placeholder="Code postal">
                                    </div>
                                    <div class="col-md-3 mb-2" *ngIf="showImpressionInput">
                                        <label class="super-compos-label-input">Localité <i *ngIf="connectedContactAccessContacts" (click)="openNewPostalCodeModal(i,'impression')" class="fas fa-plus-circle blueIcon clickableIcon"></i></label>
                                        <input type="search" id="impressionLocaliteContactInput" pInputText autocomplete="new-password" formControlName="impression_Localite" class="super-compos-input-text form-control w-100" placeholder="Localité">
                                    </div>
                                    <div class="col-md-3 mb-2" *ngIf="showImpressionInput">
                                        <label class="super-compos-label-input">Rue <i *ngIf="connectedContactAccessContacts" (click)="openNewStreetModal(i,'impression')" class="fas fa-plus-circle blueIcon clickableIcon"></i></label>
                                        <textarea id="impressionRueContactTextarea" pInputText autocomplete="new-password" formControlName="impression_Adresse" class="super-compos-input-text form-control w-100" placeholder="Rue"></textarea>
                                    </div>
                                    <div class="col-md-12 mt-3 mb-2">
                                        <h3 class="contact__subtitle">Informations</h3>
                                    </div>
                                    <div class="col-2 mb-2 ui-group">
                                        <label class="super-compos-label-input d-block">Code pays</label>
                                        <p-dropdown [virtualScroll]="true" itemSize="30" baseZIndex="1000" [showClear]="true" [required]="true" [options]="codeTvaList|sort:'label':'ASC'" placeholder="Code Pays" styleClass="w-20" [showClear]="false" appendTo="body" (onChange)="onCountryChange($event.value)" autocomplete="BE" styleClass="w-100">
                                            <ng-template let-country pTemplate="selectedItem">
                                                <i class="flag-icon flag-icon-{{country?.label.toLowerCase()}}"></i>
                                                <span class="ml-2">{{country?.label}}</span>
                                            </ng-template>
                                            <ng-template let-country pTemplate="item">
                                                <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                                    <i class="flag-icon flag-icon-{{country?.label.toLowerCase()}}"></i>
                                                    <span class="ml-2 truncateLabel" pTooltip="{{country?.label}}" tooltipPosition="right">{{country?.label}}</span>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                    <div class="col-3 mb-2">
                                        <label class="super-compos-label-input d-block">{{clientType === 'JVS' ? 'Tel. portable' : 'GSM'}}</label>
                                        <p-inputMask *ngIf="prefixNumber" appendTo="body" styleClass="w-100 form-control" formControlName="gsm" name="gsm" mask="{{prefixNumber}} 999 99 99 99"></p-inputMask>

                                        <input *ngIf="!prefixNumber" type="number" formControlName="gsm" autocomplete="new-password" class="noArrow super-compos-input-text form-control w-100" name="gsm">
                                    </div>
                                    <div class="col-3 mb-2">
                                        <label class="super-compos-label-input">Téléphone</label>
                                        <input type="text" formControlName="telephone" class="super-compos-input-text form-control w-100" name="telephone" autocomplete="new-password">
                                    </div>
                                    <div class="col-md-4 mb-2" *ngIf="i !== 0">
                                        <label class="super-compos-label-input">Email</label>

                                        <input (input)="checkEmailPattern(i)" formControlName="mail" type="text" class="super-compos-input-text form-control w-100" placeholder="Email" name="mail" autocomplete="new-password">
                                        <div *ngIf="!checkEmailPattern(i)" class="input-error" >
                                            Le format de l'adresse n'est pas correct
                                        </div>
                                    </div>

                                    <div class="col-6 mb-2">
                                        <label class="super-compos-label-input">Fax</label>
                                        <input formControlName="fax" autocomplete="new-password" type="phone" class="super-compos-input-text form-control w-100" placeholder="Fax" name="fax">
                                    </div>
                                    <div class="col-6 mb-2">
                                        <label class="super-compos-label-input">Site internet</label>
                                        <input formControlName="site" autocomplete="new-password" type="text" class="super-compos-input-text form-control w-100" placeholder="Site" name="site">
                                    </div>
                                </div>
                                <div class="col-md-12 mt-2 mb-4">
                                    <super-button text="Supprimer l'adresse" styleClass="red d-inline-block" size="small" class="d-inline-block" (click)="remove(i)">delete</super-button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
                <!--  $$$ End Adress switch $$$ -->
                <!-- $$$$$$ShowMore$$$$$$$$$$$$ -->
                <div class="form__showMore">
                    <div class="d-flex justify-content-center">
                        <super-button text="Afficher les informations supplémentaires" (click)="showMore()" styleClass="blue showMoreBtn mb-5" icon="fas fa-search-plus" *ngIf="!fullMode">
                        </super-button>
                        <super-button text="Cacher les informations supplémentaires" (click)="showMore()" styleClass="blue showMoreBtn mb-5" icon="fas fa-search-minus" *ngIf="fullMode">
                        </super-button>
                    </div>

                    <div *ngIf="fullMode">
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <h3 class="super-compos-titre-h3 mt-3">Infos Complémentaires</h3>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-12">
                                <label class="super-compos-label-input">Site internet</label>
                                <input formControlName="siteWeb" type="text" class="super-compos-input-text form-control w-100" placeholder="Site web" name="siteWeb">
                            </div>
                        </div>
                        <div class="row mb-2" *ngIf="!companyMode">
                            <div class="col-6">
                                <label class="super-compos-label-input">Etat civil</label>
                                <p-dropdown [virtualScroll]="true" itemSize="30" styleClass="w-100" [options]="etatCivils|sort:'label':'ASC'" placeholder="Sélectionnez un état civil" [showClear]="true" formControlName="etatCivil" name="etatCivil">
                                    <ng-template let-item pTemplate="item">
                                        <div class="d-flex align-items-center justify-content-between">
                                                <div class="truncateLabel">{{item.label}}</div>
                                                <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                            <div class="col-6">
                                <label class="super-compos-label-input">Sexe</label>
                                <p-dropdown [virtualScroll]="true" itemSize="30" styleClass="w-100" [options]="sexes|sort:'label':'ASC'" placeholder="Sélectionnez un sexe" [showClear]="true" formControlName="sexe" name="sexe">
                                    <ng-template let-item pTemplate="item">
                                        <div class="d-flex align-items-center justify-content-between">
                                                <div class="truncateLabel" >{{item.label}}</div>
                                                <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6">
                                <label class="super-compos-label-input">{{ companyMode? "Lieu de création" :"Date de naissance" }}</label>
                                <p-calendar *ngIf="locale4Calendar" [selectOtherMonths]="true" [baseZIndex]="100000" [defaultDate]="datebirthdefault" [locale]="locale4Calendar" formControlName="dateNaissance" name="dateNaissance" icon="fas fa-calendar-alt" [showIcon]="true" inputStyleClass="super-compos-input-text w-100" dateFormat="dd/mm/yy"
                                    placeholder="Date de naissance" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange">
                                </p-calendar>

                            </div>
                            <div class="col-6" *ngIf="!companyMode">
                                <label class="super-compos-label-input">Lieu de naissance</label>
                                <input formControlName="naissanceLieu" type="text" class="super-compos-input-text form-control w-100" placeholder="Lieu de naissance" name="naissanceLieu">
                            </div>
                        </div>


                        <div class="row mb-2">
                            <div class="col-6">
                                <label class="super-compos-label-input">Nationalité</label>
                                <p-dropdown [virtualScroll]="true" itemSize="30" styleClass="w-100" [options]="nationalites|sort:'label':'ASC'" placeholder="Sélectionnez une nationalité" [showClear]="true" formControlName="nationalite" name="nationalite">
                                    <ng-template let-item pTemplate="item">
                                        <div class="d-flex align-items-center justify-content-between">
                                                <div class="truncateLabel">{{item.label}}</div>
                                                <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>

                        </div>
                        <div class="row mb-2">
                            <div class="col-12">
                                <label class="super-compos-label-input">Remarques</label>
                                <textarea id="float" class="w-100" pInputTextarea [rows]="3" formControlName="commentaires" name="commentaires"></textarea>
                            </div>
                        </div>
                        <!-- * Connecteurs -->
                        <div *ngIf="connectedUserRights.menu.administrationDelibeMandataires" class="row mb-3">
                            <div class="col-md-12">
                                <h3 class="super-compos-titre-h3 mt-3">Connecteurs</h3>
                            </div>
                        </div>
                        <div *ngIf="connectedUserRights.menu.administrationDelibeMandataires" class="row mb-2">
                            <div class="col-4 mb-3">
                                <h4 class="super-compos-section-h4">
                                    Accès iDélibé Mandataire
                                </h4>
                                <p-checkbox formControlName="accesModuleExterneDelibe" binary="true"></p-checkbox>
                            </div>
                            <div class="col-8 mb-3" *ngIf="contactForm?.value.accesModuleExterneDelibe">
                                <h4 class="super-compos-section-h4">
                                    Login iDélibé Mandataire
                                </h4>
                                <input formControlName="loginDelibeWeb" type="text" class="super-compos-input-text form-control w-100" placeholder="Login de connection à iDélibé Mandataire" name="loginDelibeWeb">
                            </div>
                        </div>
                        <!-- * Signatures -->
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <h3 class="super-compos-titre-h3 mt-3">Signatures</h3>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6">
                                <label class="super-compos-label-input">Texte à afficher en dessous de la signature 1</label>
                                <input formControlName="signature1" type="text" class="super-compos-input-text form-control w-100" placeholder="Signature 1">
                            </div>
                            <div class="col-6">
                                <label class="super-compos-label-input">Texte à afficher en dessous de la signature 2</label>
                                <input formControlName="signature2" type="text" class="super-compos-input-text form-control w-100" placeholder="Signature 2">
                            </div>
                        </div>
                        <div class="row mb-5">
                            <div class="col-6">
                                <p-fileUpload #pFileUpload name="myfileUpload[]" multiple="false" accept="image/*" fileLimit="1" [showUploadButton]="false" [showCancelButton]="false" chooseLabel="Signature electronique" styleClass="myUpload" [customUpload]="true" (onSelect)="onSelectFiles($event)">
                                </p-fileUpload>
                            </div>
                            <div class="col-6">
                                <input formControlName="signatureCertifactNom" type="text" class="super-compos-input-text form-control w-100" placeholder="Nom du certificat pour la signature">

                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>

        </p-tabView>

    </div>
    <p-confirmDialog #cd appendTo="body" key="Close" styleClass="super-compos-modal-content confirmModal" header="Confirmation" icon="pi pi-exclamation-triangle" baseZIndex="99999">
        <p-footer>
            <button type="button" pButton icon="pi pi-times" label="Non" (click)="cd.reject()"></button>
            <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="Oui" (click)="cd.accept()"></button>
        </p-footer>
    </p-confirmDialog>
    <p-confirmDialog #xd appendTo="body" key="Address" styleClass="super-compos-modal-content confirmModal" header="Confirmation" icon="pi pi-exclamation-triangle" baseZIndex="99999">
        <p-footer>
            <button type="button" pButton icon="pi pi-times" label="Non" (click)="xd.reject()"></button>
            <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="Oui" (click)="xd.accept()"></button>
        </p-footer>
    </p-confirmDialog>
</div>

<p-confirmDialog key="contact" baseZIndex="999999" #dd appendTo="body" styleClass="super-compos-modal-content confirmModal" header="Confirmation" icon="pi pi-exclamation-triangle">
    <p-footer>
        <button type="button" pButton icon="pi pi-times" label="Non" (click)="dd.reject()"></button>
        <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="Oui" (click)="dd.accept()"></button>
    </p-footer>
</p-confirmDialog>

<p-dialog styleClass="super-compos-modal-content" [(visible)]="showCreateCP" [modal]="true" appendTo="body" [closable]="false">
    <p-header>
         <div class="d-flex align-items-center justify-content-between">
              <h5 class="modal-title super-compos-modal-title">
                   Gestion d'un code postal
              </h5>
              <div class="d-flex">
                   <super-button styleClass="blue" (click)="showCreateCP = false" text="Annuler"></super-button>
                   <super-button styleClass="green ml-3" [disabled]="!formCP.valid" (click)="submitNewCP()" text="Confirmer"></super-button>
              </div>
         </div>
    </p-header>
    <form [formGroup]="formCP" class="mt-3 mb-3 row">
         <div class="col-md-6 mb-3">
              <label class="super-compos-label-input">Pays</label>
              <p-dropdown styleClass="w-100" [virtualScroll]="true" itemSize="30" appendTo="body" [options]="countries" [filter]="true" placeholder="Sélectionnez un pays" [showClear]="true" formControlName="paysID">
                <ng-template let-item pTemplate="item">
                    <div class="d-flex align-items-center justify-content-between">
                            <div class="truncateLabel">{{item.label}}</div>
                            <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                    </div>
                </ng-template>
            </p-dropdown>
         </div>
         <div class="col-md-6 mb-3">
              <label class="super-compos-label-input">Code Postal *</label>
              <input class="form-control" type="text" pInputText formControlName="codePostal" placeholder="Ex: 4000">
         </div>
         <div class="col-md-6 mb-3">
              <label class="super-compos-label-input">Localité</label>
              <input class="form-control" type="text" pInputText formControlName="localite" placeholder="Ex: 1">
         </div>
         <div class="col-md-6 mb-3">
              <label class="super-compos-label-input">Langue</label>
              <p-dropdown styleClass="w-100" [virtualScroll]="true" itemSize="30" appendTo="body" [options]="langues" [filter]="true" placeholder="Sélectionnez une langue" [showClear]="true" formControlName="langueID">
                <ng-template let-item pTemplate="item">
                    <div class="d-flex align-items-center justify-content-between">
                            <div class="truncateLabel">{{item.label}}</div>
                            <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                    </div>
                </ng-template>
            </p-dropdown>
         </div>
    </form>
</p-dialog>

<p-dialog styleClass="super-compos-modal-content" [(visible)]="showCreateStreet" [modal]="true" appendTo="body" [closable]="false">
    <p-header>
         <div class="d-flex align-items-center justify-content-between">
              <h5 class="modal-title super-compos-modal-title">
                   Gestion d'une rue
              </h5>
              <div class="d-flex">
                   <super-button styleClass="blue" (click)="showCreateStreet = false" text="Annuler"></super-button>
                   <super-button styleClass="green ml-3" [disabled]="!formStreet.valid" (click)="submitNewStreet()" text="Confirmer"></super-button>
              </div>
         </div>
    </p-header>
    <form [formGroup]="formStreet" class="mt-3 mb-3 row">
         <div class="col-md-6 mb-3">
              <label class="super-compos-label-input">Rue</label>
              <input class="form-control" type="text" pInputText formControlName="rue" placeholder="Ex: Rue des belles vignes">
         </div>
         <div class="col-md-6 mb-3">
              <label class="super-compos-label-input">Code Postal *</label>
              <input class="form-control" type="text" pInputText formControlName="codePostal" placeholder="Ex: 4000">
         </div>
         <div class="col-md-6 mb-3">
              <label class="super-compos-label-input">Code rue</label>
              <input class="form-control" type="text" pInputText formControlName="codeRue" placeholder="Ex: 1">
         </div>
         <div class="col-md-6 mb-3">
              <label class="super-compos-label-input">Code langue</label>
              <input class="form-control" type="text" pInputText formControlName="codeLangue" placeholder="Ex: FR">
         </div>
    </form>
</p-dialog>

<p-dialog styleClass="super-compos-modal-content" [(visible)]="showCreateContactType" [modal]="true" appendTo="body" [closable]="false">
    <p-header>
         <div class="d-flex align-items-center justify-content-between">
              <h5 class="modal-title super-compos-modal-title">
                   Gestion d'un type de contact
              </h5>
              <div class="d-flex">
                   <super-button styleClass="blue mr-3" text="Annuler" (click)="showCreateContactType = false"></super-button>
                   <super-button styleClass="green" (click)="submitNewContactType()" text="Confirmer"></super-button>
              </div>
         </div>
    </p-header>
    <form [formGroup]="formContactType" class="mt-3 row">
         <div class="col-md-12 mb-3">
              <label class="super-compos-label-input">
                   Verrouillé
              </label>
              <p-inputSwitch formControlName="verrouille"></p-inputSwitch>
         </div>
         <div class="col-md-6 mb-3">
              <label class="super-compos-label-input">Description *</label>
              <input class="form-control" type="text" pInputText formControlName="typeContact" placeholder="Description du type de contact">
         </div>
         <div class="col-md-6 mb-3">
              <label class="super-compos-label-input">Ordre de tri</label>
              <input class="form-control" type="text" pInputText formControlName="ordreTri" placeholder="Ordre dans la liste des types de contact">
         </div>
         <div class="col-md-6 mb-3">
              <label class="super-compos-label-input">Filtrer les contacts par type</label>
              <p-dropdown styleClass="w-100" [virtualScroll]="true" itemSize="30" appendTo="body" [options]="typesLV|sort:'label':'ASC'" [filter]="true" placeholder="Sélectionnez un type de contact" [showClear]="true" (onChange)="filterContact($event)">
                <ng-template let-item pTemplate="item">
                    <div class="d-flex align-items-center justify-content-between">
                            <div class="truncateLabel">{{item.label}}</div>
                            <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                    </div>
                </ng-template>
            </p-dropdown>
         </div>
         <div class="col-md-6 mb-3">
              <label class="super-compos-label-input">Type lié à</label>
              <p-dropdown styleClass="w-100" [virtualScroll]="true" itemSize="30" appendTo="body" [options]="contactsLinked" [filter]="true" placeholder="Sélectionnez un contact" [showClear]="true" formControlName="carnetContactID">
                <ng-template let-item pTemplate="item">
                    <div class="d-flex align-items-center justify-content-between">
                            <div class="truncateLabel">{{item.label}}</div>
                            <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                    </div>
                </ng-template>
               </p-dropdown>
         </div>
         <div class="col-md-6 mb-3">

              <label class="super-compos-label-input">Création autorisée par</label>
              <p-dropdown styleClass="w-100" [virtualScroll]="true" itemSize="30" appendTo="body" [options]="groups|sort:'label':'ASC'" [filter]="true" placeholder="Sélectionnez un groupe" [showClear]="true" formControlName="creationGereeParGroupeID">
                <ng-template let-item pTemplate="item">
                    <div class="d-flex align-items-center justify-content-between">
                            <div class="truncateLabel">{{item.label}}</div>
                            <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                    </div>
                </ng-template>
              </p-dropdown>
         </div>
         <div class="col-md-6 mb-3">
              <label class="super-compos-label-input">Modification autorisée par</label>
              <p-dropdown styleClass="w-100" [virtualScroll]="true" itemSize="30" appendTo="body" [options]="groups|sort:'label':'ASC'" [filter]="true" placeholder="Sélectionnez un groupe" [showClear]="true" formControlName="modificationGereeParGroupeId">
                <ng-template let-item pTemplate="item">
                    <div class="d-flex align-items-center justify-content-between">
                            <div class="truncateLabel">{{item.label}}</div>
                            <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                    </div>
                </ng-template>
               </p-dropdown>
         </div>
         <div class="col-md-6 mb-3">
              <label class="super-compos-label-input">Suppression autorisée par</label>
              <p-dropdown styleClass="w-100" [virtualScroll]="true" itemSize="30" appendTo="body" [options]="groups|sort:'label':'ASC'" [filter]="true" placeholder="Sélectionnez un groupe" [showClear]="true" formControlName="suppressionGereeParGroupeId">
                <ng-template let-item pTemplate="item">
                    <div class="d-flex align-items-center justify-content-between">
                            <div class="truncateLabel">{{item.label}}</div>
                            <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                    </div>
                </ng-template>
               </p-dropdown>
         </div>
         <div class="col-md-6 mb-3">
              <label class="super-compos-label-input">Visualisation autorisée par</label>
              <p-dropdown styleClass="w-100" [virtualScroll]="true" itemSize="30" appendTo="body" [options]="groups|sort:'label':'ASC'" [filter]="true" placeholder="Sélectionnez un groupe" [showClear]="true" formControlName="visualisationGereeParGroupeID">
                <ng-template let-item pTemplate="item">
                    <div class="d-flex align-items-center justify-content-between">
                            <div class="truncateLabel">{{item.label}}</div>
                            <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                    </div>
                </ng-template>
               </p-dropdown>
         </div>
    </form>
</p-dialog>
