import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DataService } from './data.service';
import { Contact } from 'src/app/shared/models/iContact/contact';
import { map } from 'rxjs/operators';
import { UserContact } from 'src/app/shared/models/iContact/user-contact';

@Injectable({
  providedIn: 'root',
})

/*
  ? info refacto service
  ? getContactsSimple() ==> get(`/contacts/simple`)
  ? getContactsLow() ==> get(`/contacts/lowinformation`)
  ? getContactsByIDS() ==> get(`/rechercheContacts`)
  ? getContactsById() ==> get(`/contacts/${id}`)
  ? addContact() ==> post(`/contacts`)
  ? updateContact() ==> put(`/contacts/${id}`)
  ? deleteContact() ==> delete(`/contacts/${id}`)
  ? deleteContactAdress() ==> delete(`/contacts/${contactId}/contactAdresses/${contactAdressId}`)
 */
export class IContactService {
  // public apiContactBaseUrl = environment.apiContactBaseUrl;
  public apiContactBaseUrl: string;
  public apiContactBaseUrl2: string;
  public apiContactGlobalUrl: string;
  public apiContactBaseUrlAdmin: string;
  public headers: any;
  public params: HttpParams = new HttpParams();
  public jsonUsersUrl = 'assets/falseData/users.json';

  constructor(
    public http: HttpClient,
    public data: DataService
  ) {
    const config = JSON.parse(sessionStorage.getItem('globalConfig'));
    this.apiContactBaseUrl = config.apiIContactBaseUrl + '/v1';
    this.apiContactBaseUrl2 = config.apiIContactBaseUrl + '/v2';
    this.apiContactGlobalUrl = this.apiContactBaseUrl + '/Global';
    this.apiContactBaseUrlAdmin = config.apiIContactBaseUrlAdmin + '/api/v1';

    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.data.getLowContacts.subscribe((res: any) => {
      if (res) {
      } else {
        this.http.post(`${this.apiContactBaseUrl}/contacts/lowinformation`, {}).subscribe((res: any) => {
          this.data.setAllContacts(res);
        });
      }
    });
  }

  /**
   * Method get , execute a get call to the API
   * @param url of the call
   * @param options : param of the url (cfr /items)
   * Content-type and Accept-Language are in the headers
   */
  public get = (url: string, options: any = {}): Observable<any> => {
    let params: HttpParams = options.params || new HttpParams();
    this.params.keys().map((k) => {
      params = params.set(k, this.params.get(k));
    });
    options.params = params;
    options.headers = this.headers;
    return this.http.get(`${this.apiContactBaseUrl}${url}`, options);
  };
  getLowContacts(data?) {
    if (!data) {
      data = {};
    }
    return this.http.post<Contact[]>(`${this.apiContactBaseUrl}/contacts/lowinformation`, data).pipe(
      map((res) => {
        return res.map((x) => new Contact(x));
      })
    );
  }
  getLowContactsAdmin(data?) {
    if (!data) {
      data = {};
    }
    return this.http.post<Contact[]>(`${this.apiContactBaseUrlAdmin}/contacts/lowinformation`, data).pipe(
      map((res) => {
        return res.map((x) => new Contact(x));
      })
    );
  }

  getContact(contactId: number) {
    return this.http.get(`${this.apiContactBaseUrl}/contacts/${contactId}`);
  }
  getContactAdmin(contactId: number) {
    return this.http.get(`${this.apiContactBaseUrlAdmin}/contacts/${contactId}`);
  }

  getUserWithDataAdmin(data?: any): Observable<UserContact[]> {
    let params = new HttpParams();
    if (data) {
      Object.keys(data).forEach((key) => (params = params.set(key, data[key])));
    }
    return this.http.get<UserContact[]>(`${this.apiContactBaseUrlAdmin}/Global/users?${params}`).pipe(
      map((res) => {
        return res.map((x) => new UserContact(x));
      })
    );
  }

  getRemplacementWithData(data?: any): Observable<any> {
    return this.http.get(
      `https://infodoc-services-acc.inforius.be/icontactsBwApi/api/v1/contacts/remplacants?contactIds=${data}`,
      this.headers
    );
  }

  /**
   * Execute a PUT
   * @param url to send data
   * @param data to update
   */
  public put = (url: string, data: any = {}): Observable<any> => {
    this.headers.set('Content-Type', 'application/json');
    let headers: HttpHeaders = data.headers || new HttpHeaders();
    this.headers.keys().map((k) => {
      headers = headers.set(k, this.headers.get(k));
    });

    data.headers = headers;
    return this.http.put(`${this.apiContactBaseUrl}${url}`, data, { headers });
  };

  /**
   * Method who delete specific data from url
   * @param url who specify which item need to be deleted
   */
  public delete = (url: string): Observable<any> => this.http.delete(`${this.apiContactBaseUrl}${url}`);

  /**
   * Method execute a post
   * @param url to send data
   * @param data to send
   */
  public post = (url: string, data: any): Observable<any> => {
    return this.http.post<any>(`${this.apiContactBaseUrl}${url}`, data);
  };

  // false data ***************************
  getJSONUsers(): Observable<any> {
    return this.http.get(`${this.jsonUsersUrl}`);
  }

  getConges() {
    return this.http.get<any>(`${this.apiContactBaseUrl}/Management/Conges`, this.headers);
  }
  postConges(data: any, addWeekend: boolean) {
    return this.http.post<any>(
      `${this.apiContactBaseUrl}/Management/Conges?addWeekend=${addWeekend}`,
      data,
      this.headers
    );
  }
  putConges(id: number, data: any) {
    return this.http.put<any>(`${this.apiContactBaseUrl}/Management/Conges/${id}`, data, this.headers);
  }
  deleteConges(id: number) {
    return this.http.delete<any>(`${this.apiContactBaseUrl}/Management/Conges/${id}`, this.headers);
  }

  getRemplacant(contactsIds) {
    return this.http.get<any>(`${this.apiContactBaseUrl}/contacts/remplacants?contactIds=${contactsIds}`, this.headers);
  }

  getSocietesV2(contactId: number) {
    return this.http.get<any>(`${this.apiContactBaseUrl2}/Global/contacts/${contactId}/LiensMembres`, this.headers);
  }
  postSocietesV2(contactId: number, societeId: number, data: any) {
    return this.http.post<any>(
      `${this.apiContactBaseUrl2}/Global/contacts/${contactId}/LiensMembres/${societeId}`,
      data,
      this.headers
    );
  }
  putSocietesV2(id: number, lienContactId: number, data: any) {
    return this.http.put<any>(
      `${this.apiContactBaseUrl2}/Global/contacts/${id}/LiensMembres/${lienContactId}`,
      data,
      this.headers
    );
  }

  putSocieteUniqV2(id: number, societeId: number) {
    return this.http.put<any>(
      `${this.apiContactBaseUrl2}/Global/contacts/${id}/linkToSociete?societeId=${societeId}`,
      this.headers
    );
  }

  deleteSocieteUniqV2(id: number) {
    return this.http.put<any>(`${this.apiContactBaseUrl2}/Global/contacts/${id}/linkToSociete`, this.headers);
  }
  deleteSocietesV2(id: number, lienContactId: number, switchToUnique: boolean) {
    return this.http.delete<any>(
      `${this.apiContactBaseUrl2}/Global/contacts/${id}/LiensMembres/${lienContactId}?switchToUnique=${switchToUnique}`,
      this.headers
    );
  }

  enableMultiSociete(contactId: number) {
    return this.http.patch<any>(
      `${this.apiContactBaseUrl2}/Global/contacts/${contactId}/SwitchToMultiSocietes`,
      this.headers
    );
  }

  getFrenchAPIPostCode(postcode: number) {
    return this.http.get<any>(
      `https://api-adresse.data.gouv.fr/search/?q=${postcode}&type=municipality&limit=100`,
      this.headers
    );
  }
  getAccessRightsForAllUsers(size, active, pageNumber = 1) {
    const sizeQuery = size === 'pageSize200' ? 200 : -1;
    const params = {
      pageSize: sizeQuery,
      pageNumber: pageNumber,
      active: active,
    };
    active === null ? delete params.active : null;
    return this.http.get<any[]>(`${this.apiContactBaseUrl}/Management/Users`, { params: params });
  }

  getFrenchAPIStreet(cityCode: number) {
    return this.http.get<any>(
      `https://api-adresse.data.gouv.fr/search/?q=${cityCode}&citycode=${cityCode}&type=street&limit=100`,
      this.headers
    );
  }

  postCodesPostauxRues(data: any) {
    return this.http.post<any>(`${this.apiContactBaseUrl}/Management/CodesPostauxRues`, data, this.headers);
  }
}
