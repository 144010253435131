import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/core/services/api.service';
import { Point3PSearchQuery } from 'src/app/shared/models/request/SearchActionsQuery';
import { SuperTabsXl } from 'src/app/shared/models/super-tabs-xl';
import * as moment from 'moment';
import { Points3P } from 'src/app/shared/models/points3P';
import { DataService } from 'src/app/core/services/data.service';
import { GlobalConfig, Global_Class } from 'src/app/shared/models/global';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SuperTableComponent } from '@devinforius/super-compos';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-import3p',
  templateUrl: './import3-p.component.html',
  styleUrls: ['./import3-p.component.scss'],
})
export class Import3PComponent implements OnInit {
  @ViewChild('superTable') SuperTable: SuperTableComponent;
  @ViewChild('superTableArchive') superTableArchive: SuperTableComponent;
  public tabs: Array<SuperTabsXl> = [];
  public displayModalArchive: boolean = false;
  public displayModalImport: boolean = false;
  public buttons = [];
  public menu = [];
  public forSelection = [];
  public columns: Array<any> = [];
  public columnsArchive: Array<any> = [];
  public query: Point3PSearchQuery = {
    Traite: false,
    pageNumber: 1,
    pageSize: 50,
  };
  public items: Array<Points3P> = [];
  public activeTab: number = 0;
  public globalElement: Global_Class;
  public elementSelected: any;
  public rightUser: GlobalConfig;
  public rightImport3P: any;
  public form = new FormGroup({
    serviceId: new FormControl(null, Validators.required),
    statutId: new FormControl(null),
    typeSeanceId: new FormControl(null, Validators.required),
    matiereId: new FormControl(null),
    seanceId: new FormControl(null, Validators.required),
    typeId: new FormControl(null),
    echevinatId: new FormControl(null),
    origine: new FormControl(null),
    urgenceNiveauId: new FormControl(null),
    classementId: new FormControl(null),
    typeDecisionId: new FormControl(null),
  });
  public pointSelected: Points3P;
  public arrayDataPatch: Array<any> = [];
  public currentIndex: number;
  public loaded: boolean = false;

  public listOfCodesActives = [];
  public listOfEchevinats = [];
  public listOfEchevinatsRights = [];
  public listOfMatters = [];
  public listOfStatuts = [];
  public listOfTypePoint = [];
  public groupeSecuriteRightsPoints = null;

  public mandatoryPointParameters = null;
  constructor(
    private translate: TranslateService,
    public api: ApiService,
    public data: DataService,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public router: Router,
    public adminService: iAdminService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public async ngOnInit(): Promise<void> {
    this.translate.currentLang = '';
    await this.translate.use(localStorage.getItem('lang')).toPromise();
    this.setVarWithTrad();
    this.getData();
    this.getColumns();
  }

  setVarWithTrad() {
    this.tabs = [
      {
        header: this.translate.instant('import3P.pointsToProcess'),
        index: 0,
      },
      {
        header: this.translate.instant('import3P.pointsProcessed'),
        index: 1,
      },
    ];
    this.buttons = [
      {
        text: this.translate.instant('btn.importPointsSelected'),
        styleClass: 'green',
        click: () => {
          this.openModalImportPoint();
        },
      },
    ];
    this.menu = [
      {
        label: this.translate.instant('sidebar.actions'),
      },
      {
        label: this.translate.instant('btn.upload'),
        command: () => {
          this.displayModalImport = true;
          this.openModalImportPoint();
        },
      },
      {
        label: this.translate.instant('btn.archive'),
        command: () => {
          this.displayModalArchive = true;
        },
      },
    ];
    this.forSelection = [
      {
        label: this.translate.instant('btn.archive'),
        tooltip: this.translate.instant('btn.archive'),
        icon: 'bi bi-archive-fill',
        click: () => {
          this.elementSelected = this.SuperTable._selection;
          this.displayModalArchive = true;
        },
      },
      {
        label: this.translate.instant('btn.import'),
        tooltip: this.translate.instant('btn.import'),
        icon: 'bi bi-file-earmark-arrow-up-fill',
        click: () => {
          this.elementSelected = this.SuperTable._selection;
          this.openModalImportPoint();
        },
      },
    ];
  }

 getData() {
  forkJoin({
    securityGroups: this.api.getGroupeSecuriteRightsPoints(null),
    import3PSearch: this.api.get3PPointSearch(this.query),
    globalElements: this.getGlobalElements(),
    globalConfig: this.getGlobalConfig(),
  }).subscribe({
    next: (forkJoinResults: any) => {
      this.groupeSecuriteRightsPoints = forkJoinResults.securityGroups;
      this.items = forkJoinResults.import3PSearch.items.map((item) => new Points3P(item));
      this.setDatasWithSecurityRights();
        this.adminService.getRightsManagement().subscribe({
          next: (managementUser: any) => {
            this.rightImport3P = managementUser.menu.delibeRight.import3P;
            this.loaded = true;
            this.changeDetectorRef.detectChanges();
          },
          error: (error) => {
            console.error('Error fetching management user:', error);
            this.loaded = true;
            this.changeDetectorRef.detectChanges();
          }
        });
    },
    error: (error) => {
      console.error('Error with forkJoin requests:', error);
      this.loaded = true;
      this.changeDetectorRef.detectChanges();
    }
  });
}

  getGlobalElements() {
    return new Promise((resolve) => {
      this.data.getGlobalElements.subscribe((res: any) => {
        this.globalElement = new Global_Class(res);
        if (this.globalElement.isLoaded()) {
          resolve(res);
        }
      });
    });
  }

  getGlobalConfig() {
    return new Promise((resolve) => {
      this.adminService.getGlobalConfig().subscribe((config: any) => {
        this.rightUser = config;
        resolve(config);
      });
    });
  }

  setDatasWithSecurityRights() {
    if (
      this.groupeSecuriteRightsPoints.changerMatiereAllowedIds &&
      this.groupeSecuriteRightsPoints.changerMatiereAllowedIds.length > 0
    ) {
      this.listOfMatters = this.globalElement.matieres_LV.filter((x) =>
        this.groupeSecuriteRightsPoints.changerMatiereAllowedIds.includes(x.value)
      );
    }

    if (
      this.groupeSecuriteRightsPoints.changerStatutAllowedIds &&
      this.groupeSecuriteRightsPoints.changerStatutAllowedIds.length > 0
    ) {
      this.listOfStatuts = this.globalElement.statuts_points_LV.filter((x) =>
        this.groupeSecuriteRightsPoints.changerStatutAllowedIds.includes(x.value)
      );
    }

    if (
      this.groupeSecuriteRightsPoints.changerTypePointAllowedIds &&
      this.groupeSecuriteRightsPoints.changerTypePointAllowedIds.length > 0
    ) {
      this.listOfTypePoint = this.globalElement.types_points_LV.filter((x) =>
        this.groupeSecuriteRightsPoints.changerTypePointAllowedIds.includes(x.value)
      );
    }

    if (
      this.groupeSecuriteRightsPoints.changerEchevinatAllowedIds &&
      this.groupeSecuriteRightsPoints.changerEchevinatAllowedIds.length > 0
    ) {
      this.listOfEchevinatsRights = this.globalElement.echevinats_LV.filter((x) =>
        this.groupeSecuriteRightsPoints.changerEchevinatAllowedIds.includes(x.value)
      );
    }
  }

  getColumns() {
    this.columns = [
      {
        field: 'subject',
        header: this.translate.instant('global.title'),
        format: (value) => {
          if (value) {
            return value;
          }
        },
        filterable: true,
      },
      {
        field: 'approveBy',
        header: this.translate.instant('vote.approvedBy'),
        format: (value) => {
          if (value) {
            return value;
          }
        },
        filterable: true,
      },
      {
        field: 'description',
        header: this.translate.instant('description'),
        format: (value) => {
          if (value) {
            return value;
          }
        },
        filterable: true,
      },
      {
        field: 'sessionDateMoment',
        header: this.translate.instant('seanceDate'),
        format: (value) => {
          if (value) {
            return value;
          }
        },
        filterable: true,
      },
      {
        field: 'decisionFilePresentIcon',
        header: this.translate.instant('decision'),
        format: (value, item) => {
          if (item.decisionFilePresent) {
            return `
            <div class="d-flex align-items-center justify-content-center" style="height: 50px; width: 50px;">
              <div class="bi bi-file-earmark-check-fill"></div>
            </div>
          `;
          } else {
            return ``;
          }
        },
        // ce click est la possibilité d'ouvrir le fichier
        // click: (value, item) => {
        //   let config = JSON.parse(sessionStorage.getItem('globalConfig'));
        //   window.open(config.baseUrl + '/private/editor/point3P/' + value.id + '/Decision', '_blank');
        // },
        filterable: true,
      },
      {
        field: 'motivationFilePresentIcon',
        header: this.translate.instant('motivation'),
        format: (value, item) => {
          if (item.motivationFilePresent) {
            return `
              <div class="d-flex align-items-center justify-content-center" style="height: 50px; width: 50px;">
                <div class="bi bi-file-earmark-check-fill"></div>
              </div>
            `;
          } else {
            return ``;
          }
        },
        // ce click est la possibilité d'ouvrir le fichier
        // click: (value, item) => {
        //   let config = JSON.parse(sessionStorage.getItem('globalConfig'));
        //   window.open(config.baseUrl + '/private/editor/point3P/' + value.id + '/Motivation', '_blank');
        // },
        filterable: true,
      },
    ];
    this.getcolumnsTab2();
  }

  getcolumnsTab2() {
    this.columnsArchive = Object.assign([], this.columns);
    this.columnsArchive.push(
      {
        field: 'pointIdLabel',
        header: 'id',
        format: (value, item) => {
          if (item.pointId) {
            return `
            <div class="idPoint">
              ${item.pointId}
            </div>
          `;
          }
        },
        tooltip: (rowData) => {
          if (rowData.point?.objet) {
            return rowData.point.objet;
          }
        },
        click: (rowData, column) => {},
      },
      {
        field: 'statutIdLabel',
        header: this.translate.instant('archived'),
        format: (value, item) => {
          if (+item.statutId === 2) {
            return `
              <div class="d-flex align-items-center justify-content-center" style="height: 50px; width: 50px;">
                <div class="bi bi-archive-fill"></div>
              </div>
            `;
          } else {
            return ``;
          }
        },
      }
    );
  }

  submitImport() {
    const pointToSend = [];
    if (
      typeof this.elementSelected === 'object' &&
      this.elementSelected !== null &&
      !Array.isArray(this.elementSelected) &&
      this.elementSelected.id
    ) {
      this.elementSelected = [this.elementSelected];
    }
    this.elementSelected.map((element) => {
      pointToSend.push({
        point3PId: element.id,
        statutId: this.form.value.statutId,
        serviceId: this.form.value.serviceId,
        typeSeanceId: this.form.value.typeSeanceId,
        matiereId: this.form.value.matiereId,
        seanceId: this.form.value.seanceId,
        typeId: this.form.value.typeId,
        objetSynthese: element.objetSynthese,
        echevinatId: this.form.value.echevinatId,
        classementId: this.form.value.classementId,
        origine: this.form.value.origine,
        typeDecisionId: this.form.value.typeDecisionId,
        urgenceNiveauId: this.form.value.urgenceNiveauId,
      });
    });
    if (this.form.valid) {
      this.spinner.show();
      this.api.createFrom3P(pointToSend).subscribe(
        (res: any) => {
          this.toastr.success(this.translate.instant('success.importedPoints'));
          this.getData();
          this.spinner.hide();
          this.cancelImport();
        },
        (error) => {
          this.toastr.error(this.translate.instant('error.importedPoints'));
          this.spinner.hide();
          this.cancelImport();
        }
      );
    } else {
      this.toastr.error(this.translate.instant('error.pleaseFulfillRequiredFields'));
    }
  }

  cancelImport() {
    this.displayModalImport = false;
    this.elementSelected = [];
    this.superTableArchive.cancelSelection(); //on est obligé, la super table est buggée =>faut empêcher le reset a la main dans le html et reset après coup
  }

  selectedChoice($event) {
    this.elementSelected = $event;
  }

  selectedMeetingType() {
    this.api
      .getMeetingsSearch({ TypeSeanceIds: [this.form.value.typeSeanceId], pageSize: 500 })
      .subscribe((data: any) => {
        this.globalElement.seances = data.items.map((x) => {
          const formattedDate = moment(x.date, 'YYYYMMDD').format(this.translate.instant('format.formatDateSeance'));
          return {
            label: x.description
              ? x.description
              : this.translate.instant('seanceRemplaceDescriptionEmpty', { date: formattedDate }),
            value: x.id,
          };
        });
      });
  }

  clearTypeSeance() {
    this.form.patchValue({ seanceId: null });
  }

  changeTab(event) {
    this.activeTab = event.index;
    this.query = {
      Traite: this.activeTab === 0 ? false : true,
      DateChangeStatutMin: this.activeTab === 1 ? moment().subtract(2, 'd').format('YYYY/MM/DD') : '',
      pageNumber: 1,
      pageSize: 50,
    };
    this.getData();
  }

  // Quand on clique sur un bouton de menu (...) dans la table.
  // event est la ligne selectionnée
  receive_menu($event) {
    this.elementSelected = [$event];
  }

  archive() {
    const ids = this.elementSelected.map((x) => x.id);
    this.api.archivePoint3P(ids).subscribe(
      (res: any) => {
        if ((res.count = 1)) {
          this.toastr.success(this.translate.instant('success.archivedPoint'));
        } else if (res.count > 1) {
          this.toastr.success(this.translate.instant('success.archivedMultiPoint'));
        }
        this.getData();
        this.cancelArchive();
      },
      (error) => {
        this.toastr.error(this.translate.instant('errorOccurred'));
        this.cancelArchive();
      }
    );
  }

  cancelArchive() {
    this.displayModalArchive = false;
    this.elementSelected = [];
    this.SuperTable.cancelSelection(); //on est obligé, la super table est buggée =>faut empêcher le reset a la main dans le html et reset après coup
  }

  openModalImportPoint() {
    this.api.getGlobalPointParameters().subscribe((res: any) => {
      this.mandatoryPointParameters = res;
      if (res.echevinatObligatoire) {
        this.form.get('echevinatId').setValidators([Validators.required]);
        this.form.get('echevinatId').updateValueAndValidity();
      }
      if (res.classementObligatoire) {
        this.form.get('classementId').setValidators([Validators.required]);
        this.form.get('classementId').updateValueAndValidity();
      }
      if (res.matiereObligatoire) {
        this.form.get('matiereId').setValidators([Validators.required]);
        this.form.get('matiereId').updateValueAndValidity();
      }
      if (res.origineObligatoire) {
        this.form.get('origine').setValidators([Validators.required]);
        this.form.get('origine').updateValueAndValidity();
      }
      if (res.typeDecisionObligatoire) {
        this.form.get('typeDecisionId').setValidators([Validators.required]);
        this.form.get('typeDecisionId').updateValueAndValidity();
      }
      if (res.urgenceObligatoire) {
        this.form.get('urgenceNiveauId').setValidators([Validators.required]);
        this.form.get('urgenceNiveauId').updateValueAndValidity();
      }

      this.form.reset();
      this.displayModalImport = true;

      if (this.elementSelected.id || this.elementSelected.length === 1) {
        if (Array.isArray(this.elementSelected) && this.elementSelected.length === 1) {
          this.elementSelected = this.elementSelected[0];
        }
        //1 seul element selectionné

        //Service
        if (this.elementSelected.serviceId) {
          const matchingElement = this.globalElement?.services_LV.find(
            (element) => element.value === this.elementSelected.serviceId
          );
          if (matchingElement) {
            this.form.get('serviceId').setValue(matchingElement.value);
          }
        }

        //type de séance
        if (this.elementSelected.typeSeanceId) {
          const matchingElement = this.globalElement?.types_seances_LV.find(
            (element) => element.value === this.elementSelected.typeSeanceId
          );
          if (matchingElement) {
            this.form.get('typeSeanceId').setValue(matchingElement.value);
            this.selectedMeetingType();
          }
        }

        //type de point
        if (this.elementSelected.typePointId) {
          const matchingElement = this.listOfTypePoint.find(
            (element) => element.value === this.elementSelected.typePointId
          );
          if (matchingElement) {
            this.form.get('typeId').setValue(matchingElement.value);
          }
        }

        //matière
        if (this.elementSelected.matiereId) {
          const matchingElement = this.listOfMatters.find(
            (element) => element.value === this.elementSelected.matiereId
          );
          if (matchingElement) {
            this.form.get('matiereId').setValue(matchingElement.value);
            this.changeMatter({ value: this.form.value.matiereId });
          }
        }

        //Échevinat
        //1st thing to do is check is there's a matter. if not, just don't display at all the echevinat, no need. One SHOULD be linked to a matter
        if (this.elementSelected.echevinatId && this.form.value.matiereId) {
          //2nd thing to do : check is echevinat is linked to the matter.
          const currentMatter = this.listOfMatters.find((matter) => matter.value === this.form.value.matiereId);
          if (currentMatter) {
            const isEchevinatInMatter = currentMatter.item.echevinats.indexOf(this.elementSelected.echevinatId);
            const matchingElement = this.listOfEchevinatsRights.find(
              (echevinat) => echevinat.value === this.elementSelected.echevinatId
            );
            //ici on doit vérifier si l'échevinat est contenu dans la matière ET que l'utilisateur a les droits de l'échevinat
            if (isEchevinatInMatter > -1 && matchingElement) {
              this.form.patchValue({ echevinatId: this.elementSelected.echevinatId });
            }
          }
        }

        //Statut du point
        const matchingElement = this.listOfStatuts.find((element) => element.item.statutAutiliserLorsCreation === true);
        if (matchingElement) {
          this.form.get('statutId').setValue(matchingElement.value);
        }

        //liste des codes
        if (this.mandatoryPointParameters.classementObligatoire) {
          this.listOfCodesActives = this.globalElement.classement_code_LV.filter((codeLV) => {
            if (codeLV.item.actif) {
              return codeLV;
            }
          });
        }
      } else if (this.elementSelected.length > 1) {
        //plusieurs elements sélectionnés

        //Pour chaque liste de données, on va récupérer la 1ere donnée qui n'est pas nulle. Elle va servir de base pour vérifier si toutes les données sélectionnées ont le même id qu'elle || sont nulles.
        //* Vu avec Cédric Van Pelt le 29/04/2024 : si une donnée est nulle => on la considère comme égale à l'id.
        //En effet, si on ne se base que sur this.elementSelected[0], le premier element pourrait être null et donc tout fausser!

        //Service
        const firstElementWithServiceId = this.elementSelected.find((element) => element.serviceId);
        if (
          this.elementSelected.every((element) => {
            return element.serviceId === firstElementWithServiceId.serviceId || element.serviceId === null;
          })
        ) {
          const matchingElement = this.globalElement?.services_LV.find(
            (service) => service.value === firstElementWithServiceId.serviceId
          );
          if (matchingElement) {
            this.form.get('serviceId').setValue(matchingElement.value);
          }
        }

        //Type de séance
        const firstElementWithTypeSeanceId = this.elementSelected.find((element) => element.typeSeanceId);
        if (
          this.elementSelected.every((element) => {
            return element.typeSeanceId === firstElementWithTypeSeanceId.typeSeanceId || element.typeSeanceId === null;
          })
        ) {
          const matchingElement = this.globalElement?.types_seances_LV.find(
            (typeSeance) => typeSeance.value === firstElementWithTypeSeanceId.typeSeanceId
          );
          if (matchingElement) {
            this.form.get('typeSeanceId').setValue(matchingElement.value);
            this.selectedMeetingType();
          }
        }

        //type de point
        const firstElementWithTypePointId = this.elementSelected.find((element) => element.typePointId);
        if (
          this.elementSelected.every((element) => {
            return element.typePointId === firstElementWithTypePointId.typePointId || element.typePointId === null;
          })
        ) {
          const matchingElement = this.listOfTypePoint.find(
            (typePoint) => typePoint.value === firstElementWithTypePointId.typePointId
          );

          if (matchingElement) {
            this.form.get('typeId').setValue(matchingElement.value);
          }
        }

        //Matière
        const firstElementWithMatiereId = this.elementSelected.find((element) => element.matiereId);
        if (
          this.elementSelected.every((element) => {
            return element.matiereId === firstElementWithMatiereId.matiereId || element.matiereId === null;
          })
        ) {
          const matchingElement = this.listOfMatters.find(
            (matter) => matter.value === firstElementWithMatiereId.matiereId
          );

          if (matchingElement) {
            this.form.get('matiereId').setValue(matchingElement.value);
            this.changeMatter({ value: this.form.value.matiereId });
          }
        }

        //Échevinat
        //pour faire le check des échevinats, il faut qu'il y ait une matière. S'il n'y en a pas ca ne sert même a rien d 'avancer!
        if (this.form.value.matiereId) {
          const firstElementWithEchevinatId = this.elementSelected.find((element) => element.echevinatId);
          if (
            this.elementSelected.every((element) => {
              return element.echevinatId === firstElementWithEchevinatId.echevinatId || element.echevinatId === null;
            })
          ) {
            //Si on arrive ici, c'est que tous les échevinats ont le mm id.
            // On va donc pouvoir checker si cet id est contenu dans la matière checkée
            const currentMatter = this.listOfMatters.find((x) => x.value === this.form.value.matiereId);
            if (currentMatter) {
              const isEchevinatInMatter = currentMatter.item.echevinats.indexOf(
                firstElementWithEchevinatId.echevinatId
              );
              if (isEchevinatInMatter > -1) {
                this.form.patchValue({ echevinatId: firstElementWithEchevinatId.echevinatId });
              }
            }
          }
        }

        //statut de point
        const matchingElement = this.listOfStatuts.find((element) => element.item.statutAutiliserLorsCreation === true);
        if (matchingElement) {
          this.form.get('statutId').setValue(matchingElement.value);
        }

        //liste des codes
        if (this.mandatoryPointParameters.classementObligatoire) {
          this.listOfCodesActives = this.globalElement.classement_code_LV.filter((codeLV) => {
            if (codeLV.item.actif) {
              return codeLV;
            }
          });
        }
      }
    });
  }

  clicRowTable(event) {
    // open point delibe if event.point exist
    if (event.point?.id) {
      window.open(`/private/points/detail/${event.point.id}`, '_blank');
    }
  }

  getDescription(): string {
    if (Array.isArray(this.elementSelected) && this.elementSelected.length > 1) {
      return `${this.translate.instant('point_metadatas')} ${this.elementSelected
        .map((e) => e.description)
        .join(', ')}`;
    } else {
      return `${this.translate.instant('point_metadata')}`;
    }
  }

  changeMatter(event) {
    if (event.value) {
      const currentMatter = this.listOfMatters.find((x) => x.value === event.value);
      if (currentMatter) {
        this.listOfEchevinats = [];
        if (currentMatter.item.echevinats.length > 0) {
          currentMatter.item.echevinats.map((x) => {
            const index = this.listOfEchevinatsRights.map((x) => x.value).indexOf(x);
            if (index > -1) {
              this.listOfEchevinats.push(this.listOfEchevinatsRights[index]);
            }
          });
          if (this.listOfEchevinats.length === 1) {
            this.form.patchValue({
              echevinatId: this.listOfEchevinats[0].value,
            });
          } else {
            this.form.patchValue({
              echevinatId: null,
            });
          }
        } else {
          this.form.patchValue({
            echevinatId: null,
          });
        }
      }
    } else {
      this.form.patchValue({
        echevinatId: null,
      });
    }
  }
}
