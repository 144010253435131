export const removeEmpty = (obj) => {
  return Object.keys(obj)
    .filter(function (k) {
      return obj[k] != null;
    })
    .reduce(function (acc, k) {
      if (!Array.isArray(obj[k]) && !(obj[k] instanceof Date)) {
        acc[k] = typeof obj[k] === 'object' ? this.removeEmpty(obj[k]) : obj[k];
      } else {
        acc[k] = obj[k];
      }
      return acc;
    }, {});
};
