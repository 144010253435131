import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as _ from 'underscore';
@Component({
  selector: 'list-element',
  templateUrl: './list-element.component.html',
  styleUrls: ['./list-element.component.scss'],
})
export class ListElementComponent implements OnInit {
  @Input() set items(value) {
    this.elements = value;
    this.currentPointID = _.first(this.elements)?.id;
  }
  @Output() emittedPointValue: EventEmitter<any> = new EventEmitter();
  @Output() reloadList: EventEmitter<any> = new EventEmitter();
  public elements: Array<any> = [];
  public currentPointID: number = null;
  constructor(public router: Router) {}

  ngOnInit(): void {
    (this.router.events as any)
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.currentPointID = +event.url.slice(-1);
        }
      });
  }

  public emitPointValueClicked(event) {
    this.emittedPointValue.emit(event);
    this.currentPointID = event.pointId;
  }

  reload($event) {
    if ($event) {
      this.reloadList.emit(true);
    }
  }
}
