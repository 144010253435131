import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as async from 'async';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { UserInfo } from 'src/app/shared/models/user-info';
import { DataService } from 'src/app/core/services/data.service';
import { Global_Class } from 'src/app/shared/models/global';
import { ApiService } from 'src/app/core/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { Point } from 'src/app/shared/models/point';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-search-display',
  templateUrl: './search-display.component.html',
  styleUrls: ['./search-display.component.scss'],
})
export class PointSearchDisplayComponent implements OnInit {
  private notifyUserSubscription: Subscription;
  public user: UserInfo = null;
  public globalElements;
  public statutsPoints;
  public isTitle: boolean = false;
  public isSearch: boolean = false;
  public items: Array<Point> = [];
  public fullData;
  public title = '';
  public dataToSendToQuery: any = {
    PageNumber: 0,
  };
  public pageSize: number = 50;

  public point;
  public justifyOptions: Array<any> = [
    { icon: 'icon-side-view', value: 'side-by-side' },
    { icon: 'fas fa-align-justify', value: 'table' },
  ];
  public countsTags;
  public currentDisposition: string = 'table';
  public urlQuery;
  public loadingMorePoints = false;
  public boxes = [];
  public selected;
  constructor(
    public activatedRoute: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public apiAdmin: iAdminService,
    private dataS: DataService,
    private api: ApiService,
    private translate: TranslateService,
    public router: Router
  ) {}

  emitOnScrollDown() {
    this.spinner.show();
    this.dataToSendToQuery.PageNumber += 1;
    this.api.getSearchPoint(this.dataToSendToQuery).subscribe((response: any) => {
      const temp = response.items.map((x) => new Point(x, this.globalElements));
      this.items = this.items.concat(temp);
      this.fullData = response;
      this.spinner.hide();
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((route: any) => {
      this.spinner.show();
      async.waterfall(
        [
          (callback) => {
            this.api.getPointsCount('PourInfo,PourAction,ACorriger,EnRedaction').subscribe((res: any) => {
              this.countsTags = res;
              this.boxes = [
                { name: 'title.allPoints', menu: 'all', title: 'Tous les points', id: 'all', type: 'searchTab' },
                {
                  name: 'title.aCorriger',
                  menu: 'aCorriger',
                  count: this.countsTags.aCorriger,
                  title: 'Mes points à corriger',
                  id: 'aCorriger',
                  type: 'searchTab',
                },
                {
                  name: 'title.pourInfo',
                  menu: 'pourInfo',
                  count: this.countsTags.pourInfo,
                  title: 'Pour info',
                  id: 'pourInfo',
                  type: 'searchTab',
                },
                {
                  name: 'title.pourAction',
                  menu: 'pourAction',
                  count: this.countsTags.pourAction,
                  title: 'Pour action',
                  id: 'pourAction',
                  type: 'searchTab',
                },
                {
                  name: 'title.mesPointsEnRedaction',
                  menu: 'mesPointsEnRedaction',
                  count: this.countsTags.enRedaction,
                  title: 'Mes points en rédaction',
                  id: 'mesPointsEnRedaction',
                  type: 'searchTab',
                },
              ];
              callback();
            });
          },
          (callback) => {
            this.apiAdmin.getUserInfo().subscribe((userInfo) => {
              this.user = new UserInfo(userInfo);
              callback();
            });
          },
          (callback) => {
            this.dataS.getGlobalElements.subscribe((res: any) => {
              this.globalElements = new Global_Class(res);
              if (this.globalElements.isLoaded()) {
                callback(null);
              }
            });
          },
          (callback) => {
            this.api.getGlobalAllPointsStatuts().subscribe((res: any) => {
              this.statutsPoints = res;
              this.statutsPoints.map((statut) => {
                this.boxes.push({
                  name: statut.description,
                  menu: statut.id,
                  title: statut.description,
                  id: statut.id.toString(),
                  type: 'search',
                });
              });
              callback();
            });
          },
          (callback) => {
            if (this.dataS.advancedSearchPoint) {
              this.title = 'Recherche avancée sur les points';
              this.dataToSendToQuery = this.dataS.advancedSearchPoint;
              this.dataToSendToQuery.PageNumber = 1;
              this.api.getSearchPoint(this.dataS.advancedSearchPoint).subscribe((res: any) => {
                this.items = res.items.map((x) => new Point(x, this.globalElements));
                this.fullData = res;
                this.spinner.hide();
              });
            } else {
              callback(null);
            }
          },
          (callback) => {
            if (route.title) {
              this.isTitle = true;
              this.isSearch = false;
              this.items = [];
              this.fullData = null;
              this.urlQuery = route.title;
              const temp = this.boxes.filter((x) => {
                if (x.menu == route.title) {
                  return true;
                }
              });
              if (temp && temp.length > 0) {
                this.selected = temp[0].title;
              }
              switch (route.title) {
                case 'all': {
                  this.title = this.translate.instant('title.allPoints');
                  this.dataToSendToQuery = {
                    PageSize: this.pageSize,
                    PageNumber: 1,
                  };
                  this.api.getSearchPoint(this.dataToSendToQuery).subscribe((response: any) => {
                    this.items = response.items.map((x) => new Point(x, this.globalElements));
                    this.fullData = response;
                    this.spinner.hide();
                    callback();
                  });
                  break;
                }
                case 'aCorriger': {
                  this.dataToSendToQuery = {
                    PageSize: this.pageSize,
                    PageNumber: 1,
                    ACorriger: true,
                  };
                  this.title = this.translate.instant('title.aCorriger');
                  this.api.getSearchPoint(this.dataToSendToQuery).subscribe((response: any) => {
                    this.items = response.items.map((x) => new Point(x, this.globalElements));
                    this.fullData = response;
                    this.spinner.hide();
                    callback();
                  });
                  break;
                }
                case 'pourInfo': {
                  this.dataToSendToQuery = {
                    PageSize: this.pageSize,
                    PageNumber: 1,
                    Tag: 'pourInfo',
                  };
                  this.title = this.translate.instant('title.pourInfo');
                  this.api.getSearchPoint(this.dataToSendToQuery).subscribe((response: any) => {
                    this.items = response.items.map((x) => new Point(x, this.globalElements));
                    this.fullData = response;
                    this.spinner.hide();
                    callback();
                  });
                  break;
                }
                case 'pourAction': {
                  this.dataToSendToQuery = {
                    PageSize: this.pageSize,
                    PageNumber: 1,
                    Tag: 'pourAction',
                  };
                  this.title = this.translate.instant('title.pourAction');
                  this.api.getSearchPoint(this.dataToSendToQuery).subscribe((response: any) => {
                    this.items = response.items.map((x) => new Point(x, this.globalElements));
                    this.fullData = response;
                    this.spinner.hide();
                    callback();
                  });
                  break;
                }
                case 'mesPointsEnRedaction': {
                  const statut = this.statutsPoints.find((x) => (x.statutAutiliserLorsCreation = true));
                  this.dataToSendToQuery = {
                    PageSize: this.pageSize,
                    PageNumber: 1,
                    CreeParContactId: this.user.contactId,
                    statutId: statut.id,
                  };
                  this.title = this.translate.instant('title.mesPointsEnRedaction');
                  this.api.getSearchPoint(this.dataToSendToQuery).subscribe((response: any) => {
                    this.items = response.items.map((x) => new Point(x, this.globalElements));
                    this.fullData = response;
                    this.spinner.hide();
                    callback();
                  });
                  break;
                }

                default: {
                  //Si on arrive ici c'est qu'on a l'id du statut
                  const index = this.globalElements.statuts_points_LV.map((x) => x.value).indexOf(+route.title);
                  if (index > -1) {
                    // this.title = this.translate.instant(route.title);
                    this.title = this.globalElements.statuts_points[index].description;
                    this.dataToSendToQuery = {
                      PageSize: this.pageSize,
                      PageNumber: 1,
                      statutId: route.title,
                    };
                    this.api.getSearchPoint(this.dataToSendToQuery).subscribe((response: any) => {
                      this.items = response.items.map((x) => new Point(x, this.globalElements));
                      this.fullData = response;
                      this.spinner.hide();
                      callback();
                    });
                    break;
                  } else {
                    callback();
                    break;
                  }
                }
              }
            } else if (route.search) {
              this.isTitle = false;
              this.isSearch = true;
              this.urlQuery = route.search;
              const temp = this.boxes.filter((x) => {
                if (+x.menu === +route.search) {
                  return true;
                }
              });
              if (temp.length > 0) this.selected = temp[0].title;
              this.title = this.translate.instant('title.searchPoint');
              this.dataToSendToQuery = {
                PageSize: this.pageSize,
                PageNumber: 1,
                rechercheGlobale: route.search,
              };
              this.api.getSearchPoint(this.dataToSendToQuery).subscribe((response: any) => {
                this.items = response.items.map((x) => new Point(x, this.globalElements));
                this.fullData = response;
                this.spinner.hide();
                callback();
              });
            }
          },
        ],
        () => {
          this.spinner.hide();
        }
      );
    });
  }

  ngOnDestroy() {
    // unsubscribe signalr event, prevent memory leak when component is destroyed
    if (this.notifyUserSubscription) {
      this.notifyUserSubscription.unsubscribe();
    }
  }

  public goTo(item) {
    this.point = item;
  }

  getTooltip(type, data) {
    const typeTranslated = this.translate.instant([type]);
    return typeTranslated[type] + ': ' + data;
  }

  changeDisplay() {
    if (this.currentDisposition === 'side-by-side') {
      if (this.isTitle) {
        const route = 'private/points/searchTab/' + this.urlQuery;
        this.router.navigate([route]);
      } else if (this.isSearch) {
        const route = 'private/points/search/' + this.urlQuery;
        this.router.navigate([route]);
      } else {
        const route = 'private/points/search/advancedSearch';
        this.router.navigate([route]);
      }
    }
  }

  onChange(event) {
    if (event.value) {
      const title = event.value;
      this.title = title;
      const currentBox = this.boxes.filter((x) => x.title === title);
      this.urlQuery = currentBox[0].menu;
      this.selected = currentBox[0].title;
      this.router.navigateByUrl('private/points/display/' + currentBox[0].type + '/' + this.urlQuery);
    }
  }

  removeEmpty(obj) {
    return Object.keys(obj)
      .filter(function (k) {
        return obj[k] != null;
      })
      .reduce(function (acc, k) {
        if (!Array.isArray(obj[k]) && !(obj[k] instanceof Date)) {
          acc[k] = typeof obj[k] === 'object' ? this.removeEmpty(obj[k]) : obj[k];
        } else {
          acc[k] = obj[k];
        }
        return acc;
      }, {});
  }
}
