import { ContactAdresse } from './contact-adresse';

export class UserContact {
    public id:number;
    public active : boolean
    public userId: number;
    public login: string;
    public contactForUser: {
        contactId: number;
        nom?: string;
        prenom?: string;
        numeroCarteNISS?: string;
        dateNaissance?: any;
        adresse?: ContactAdresse;
    }
    public externalId
    public isSSOOkIcon
    public iconTooltip
    public name?: any;
    public label : string;
    public value : number;

    constructor(data?) {
        Object.assign(this, data);
        this.name = (this.contactForUser.nom ? this.contactForUser.nom + ' ' : '') + (this.contactForUser.prenom ? this.contactForUser.prenom : '');
        if ( this.name ){
            this.name = this.name.trimStart();
        }
        this.label = this.name;
        if(this.contactForUser){
            this.value = this.contactForUser.contactId;
        }
        this.id = this.userId;
    }



}
