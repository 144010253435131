import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../services/data.service';
import { DialogService } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root',
})
export class BasicHttpInterceptorService implements HttpInterceptor {
  public hasFinish: boolean = false;
  constructor(
    public oAuthService: OAuthService,
    public toastr: ToastrService,
    public dataService: DataService,
    public dialog: DialogService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToForward = req;
    const token = this.oAuthService.getAccessToken();
    if (requestToForward.url.indexOf('/connect/token') >= 0) {
    } else {
      if (token !== '') {
        let tokenValue = null;
        tokenValue = 'Bearer ' + token;
        requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
      }
    }
    return next.handle(requestToForward);
  }
}
