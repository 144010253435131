import {
  IPointActionSearchResult,
  ISeanceActionSearchResult,
} from 'src/app/shared/models/response/ActionsSearchResult';

export interface ITabInfo {
  title: string;
  slug: 'todo' | 'finished' | 'all' | 'asked';
  active?: boolean;
  count: number;
  tooltip: string;
}

export interface IActionsListHolder {
  todo: IActionHolderElement;
  all: IActionHolderElement;
  finished: IActionHolderElement;
  asked: IActionHolderElement;
}

export type ActionItem = (IPointActionSearchResult | ISeanceActionSearchResult);

interface IActionHolderElement {
  items: ActionItem[];
  totalCount: number;
  pageNumber: number;
  totalPages: number;
}

export enum FetchMode {
  Points,
  Seances,
}
