import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Config } from 'src/app/shared/models/config';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public config!: Config;
  private configGlobal = new BehaviorSubject<any>({});
  readonly configGlobalValue = this.configGlobal.asObservable();

  constructor(private http: HttpClient) {
  }

  load() {
    if (environment.production === true) {
      this.http.get('assets/config/configProd.json?timestamp'+moment().format('DD-MM-YYYY-HH-mm-ss')).subscribe(data => {
          Object.assign(this, data);
          sessionStorage.setItem('globalConfig', JSON.stringify(data));
          this.setConfigGlobal(data);
          return data;
        });
    } else {
      this.http.get('assets/config/config.json?timestamp='+moment().format('DD-MM-YYYY-HH-mm-ss')).subscribe(data => {
          Object.assign(this, data);
          this.setConfigGlobal(data);
          sessionStorage.setItem('globalConfig', JSON.stringify(data));
          return data;
        });
    }



  }

  init() {
    (async () => {


      // Production custom config
      if (environment.production) {
        const response = await fetch('assets/config/configProd.json?timestamp='+moment().format('DD-MM-YYYY-HH-mm-ss'));
        const customConfig = await response.json();
        this.config = Object.assign(this, customConfig);
        // Local config
      } else {
        const response = await fetch('assets/config/config.json?timestamp='+moment().format('DD-MM-YYYY-HH-mm-ss'));
        const customConfig = await response.json();
        this.config = customConfig;
      }
      await this.setConfigGlobal(this.config);
      // sessionStorage.setItem("config", JSON.stringify(this.config));

    })();
  }

  setConfigGlobal(value:any) {
    return this.configGlobal.next(value);
  }
}
