export enum DashboardTypes {
  IDelibeBackofficeFavoris = 1,
  IDelibeBackofficeRechercheSauvegardee = 2,
  IDelibeBackofficeCustomViews = 3,
}

export interface UserAppConfigurationRequest {
  name: string;
  description: string;
  value: string;
  shared: boolean;
  displayOrder?: number | null;
  hideInDashboard: boolean;
  menu:string,
  title:string
}
