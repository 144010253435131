<div class="row h-100">
     <section class="col-md-4 super-compos-left-shadow  overflow-hidden pr-2">
         <super-contacts-list (onReset)="recveiveReset($event)" (onCreate)="receiveCreateEvent($event)" (searchEvent)="receiveSearchAdvanced($event)" [items]="contacts|sort:'lastName':'ASC'" (selectedValue)="receive_selected($event)" (scrollEvent)="receiveScrollE($event)" (searchLetterEvent)="receiveSearchEvent($event)" (searchInputEvent)="receive_searchInput($event)"
             [companyLegalTypes]="companyLegalTypes" (onAdvancedSearchClicked)="openAdvancedModal = true" [isLoading]="isLoading" [showCompanySelect]="true">
         </super-contacts-list>
 
     </section>
 
     <section class="col-md-8">
         <router-outlet></router-outlet>
     </section>
 
 </div>

 <p-dialog header="Recherche avancée sur les contacts" [(visible)]="openAdvancedModal" [modal]="true" appendTo="body"
    [style]="{width: '50vw'}">
    <form class="row" [formGroup]="formSearch" autocomplete="false | unknown-autocomplete-value">
        <div class="col-md-6 mb4">
            <super-button text="Rechercher" styleClass="blue w-100 d-block text-center" (click)="searchAdvanced()">
            </super-button>
        </div>
        <div class="col-md-6 mb-4">
            <super-button text="Réinitialiser la recherche" styleClass="red w-100 d-block text-center"
                (click)="reinit()" id="reinitField"></super-button>
        </div>
        <div class="col-md-4 mb-3">
            <label class="super-compos-label-input">Nom</label>
            <input type="text" formControlName="nom" class="form-control" placeholder="Ex: Dupont"
                autocomplete="new-password">
        </div>
        <div class="col-md-4 mb-3">
            <label class="super-compos-label-input">Prénom</label>
            <input type="text" formControlName="prenom" autocomplete="new-password" class="form-control"
                placeholder="Ex: Jean">
        </div>
      
        <div class="col-md-4 mb-3">
            <label class="super-compos-label-input">Adresse principale</label>
            <input type="text" formControlName="rue" class="form-control" placeholder="Ex: Avenue de la rue"
                autocomplete="new-password">
        </div>
        <div class="col-md-4 mb-3">
            <label class="super-compos-label-input d-block">Code postal</label>
            <input type="text" formControlName="cp" class="form-control" placeholder="Ex: 4000"
                autocomplete="new-password">
        </div>
        <div class="col-md-4 mb-3">
            <label class="super-compos-label-input">Localité</label>
            <input type="text" formControlName="localite" class="form-control" placeholder="Ex: Liège"
                autocomplete="new-password">
        </div>
        <div class="col-md-4 mb-3">
            <label class="super-compos-label-input">GSM</label>
            <input type="text" formControlName="gsm" class="form-control" placeholder="Ex: +3249356211"
                autocomplete="new-password">
        </div>
        <div class="col-md-4 mb-3">
            <label class="super-compos-label-input">Email</label>
            <input type="mail" formControlName="email" class="form-control" placeholder="Ex: mail@me.be"
                autocomplete="new-password">
        </div>
        <div class="col-md-4 mb-3">
            <label class="super-compos-label-input d-block">Titre</label>
            <p-dropdown appendTo="body" [virtualScroll]="true" itemSize="30" [options]="contactTitresList" [filter]="true" placeholder="Titre" styleClass="w-100" [showClear]="true"
                formControlName="titre" autocomplete="new-password">
                <ng-template let-title pTemplate="item">
                    <div pTooltip="{{title?.label}}" tooltipPosition="right" class="truncateLabel">{{title.label}}</div>
                </ng-template>
            </p-dropdown>
        </div>
        <input type="submit" style="display: none;">
    </form>
</p-dialog>