export class DestinataireInput {
    public type: string; // DESTINATAIRE
    public destinataireId: number;
    public contacts?:any
    public statut?: string;
    public action?: any;
    public transmisDate?: any;
    public echeance?: any;
    public message?: string;
    public accuseDeReponse?: any;
    public idsToReplace ?: any;

    constructor(data?: any) {
        for (const prop in data) {
            if (data.hasOwnProperty(prop)) {
                this[prop] = data[prop];
            }

        }
    }
}
