<div *ngIf="!loader">
  <super-title [text]="'Association des documents du lot'" [buttons]="buttons" class="mb-5 title"></super-title>
  <app-iscan
    class=""
    [title]="'Scan'"
    [language]="language"
    [viewButtonInImage]="true"
    [viewSeparationButton]="true"
    [encodingButton]="[]"
    [quickButton]="quickButton"
    [widthSplitter]="[40, 60]"
    [formatedQrCode]="formatedQrCode"
    [cardDescription]="cardDescription"
    [nameFile]="infoFile?.name"
    (inputFile)="inputFile($event)"
    (idCard)="openPointWithIdCard($event)"
    (quickButtonClicked)="quickButtonClicked($event)">
  </app-iscan>
</div>

<p-confirmDialog 
  [header]="'confirm.confirmHeader' | translate" 
  icon="pi pi-exclamation-triangle" 
  [acceptLabel]="'Yes' | translate" 
  [rejectLabel]="'No' | translate" 
></p-confirmDialog>

<ngx-spinner
  [name]="'spinnerTreatment'"
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
  [fullScreen]="true"
  ><p style="color: white">
    {{ 'saveFileProgress' | translate }}... {{ spinnerCountSave }}/{{ spinnerCountTotal }}
  </p></ngx-spinner
>

<lib-search-modal-scan
  *ngIf="openEditDescription"
  [infosCard]="infosCard"
  [openEditDescription]="openEditDescription"
  (closeModal)="openEditDescription = false; newDescription($event)"
>
</lib-search-modal-scan>
