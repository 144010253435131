import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { Global_Class } from 'src/app/shared/models/global';
import { Contact } from 'src/app/shared/models/iContact/contact';
import { Point } from 'src/app/shared/models/point';
import * as _ from 'underscore';
import * as async from 'async';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { UserContact } from 'src/app/shared/models/iContact/user-contact';

@Component({
  selector: 'app-marktocorrection',
  templateUrl: './marktocorrection.component.html',
  styleUrls: ['./marktocorrection.component.scss'],
})
export class MarktocorrectionComponent implements OnInit {
  public header: string;
  public meetingID: number;
  public mode: string;
  public selections: Array<Point> = [];
  public contacts: Array<Contact> = [];
  public form: FormGroup = new FormGroup({
    pointIds: new FormControl(null),
    contactId: new FormControl(null),
    message: new FormControl(null),
    statutId: new FormControl(null),
  });
  public globalElements: Global_Class = null;
  constructor(
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private dataService: DataService,
    private toastr: ToastrService,
    private api: ApiService,
    private iContactService: IContactService,
    private spinner: NgxSpinnerService,
    private iAdminService: iAdminService
  ) {}

  ngOnInit(): void {
    this.getModalData();
    this.dataService.getGlobalElements.subscribe((res: any) => {
      this.globalElements = new Global_Class(res);
    });
  }

  getModalData() {
    this.header = this.config.data.header;
    this.meetingID = this.config.data?.meetingID;
    this.mode = this.config.data?.mode;
    this.selections =
      this.config.data.selections.length > 0 ? this.config.data.selections : [this.config.data.selections];
    if (this.selections.length > 0) {
      if (this.selections.length === 1) {
        this.mode = 'solo';
        // On préselectionne le créateur dans les contacts
        const point = _.first(this.selections);
        this.form.patchValue({
          contactId: point.createurID,
        });
        this.getContacts(point);
      } else {
        this.mode = 'multiple';
      }
      const pointIds = [];
      this.selections.map((item) => {
        pointIds.push(item.id);
      });
      this.form.patchValue({
        pointIds: pointIds,
      });
    }
  }

  getContacts(point) {
    //* 1st step : get all users from the point's service
    this.iAdminService.getUsersByServiceId(point.serviceId).subscribe((userIds: any) => {
      let tempUsers = [];
      let tempContacts = [];
      async.parallel(
        [
          (callback) => {
            //* 2nd step retrieve the infos of those users
            this.iContactService.getUserWithDataAdmin({ UserIds: userIds.join(',') }).subscribe((users: any) => {
              tempUsers = users.map((x) => new UserContact(x));
              callback();
            });
          },
          (callback) => {
            //*3rd step, get the infos about the contact creator
            this.iContactService.getUserWithDataAdmin({ ContactIds: point.createurID }).subscribe((users: any) => {
              tempContacts = users.map((x) => new UserContact(x));
              callback();
            });
          },
        ],
        () => {
          let temp = tempUsers.concat(tempContacts);
          temp = _.uniq(temp, 'id');
          this.contacts = temp.filter((x) => x.active);
        }
      );
    });
  }

  submit() {
    if (this.mode === 'solo') {
      const point = _.first(this.selections);
      if (point.modificationAutorisee && point?.modificationAFaireParContactId === this.form.value.contactId) {
        this.toastr.warning('Le point' + point?.title + ' a déjà été envoyé à cet agent, il sera réenvoyé.');
      }
      if (this.form.valid) {
        this.spinner.show();
        console.log('this.meetingId ==>', this.meetingID);
        console.log('this.form.value ==>', this.form.value);
        this.api.markACorriger(this.meetingID, this.form.value).subscribe((res: any) => {
          this.toastr.success('Le point a bien été envoyé en correction');
          this.spinner.hide();
          this.ref.close(true);
        });
      } else {
        this.toastr.error('Des champs sont requis');
      }
    } else {
      async.eachSeries(
        this.selections,
        (point, cb) => {
          if (point.modificationAutorisee && point?.modificationAFaireParContactId === this.form.value.contactId) {
            this.toastr.warning('Le point' + point?.title + ' a déjà été envoyé à cet agent, il sera réenvoyé.');
          }
          cb();
        },
        () => {
          this.api.markACorriger(this.meetingID, this.form.value).subscribe((res: any) => {
            this.toastr.success('Les points ont bien été envoyés en correction');
            this.spinner.hide();
            this.ref.close(true);
          });
        }
      );
    }
  }
}
