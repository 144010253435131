<!-- TODO : ajouter les accordions de manière dynamique+ afficher dynamiquement le titre et son tooltip + y ajouter l'éditeur + lier, si besoin, aux données existantes renvoyées -->
<div class="d-flex align-items-center justify-content-end mb-3">
  <super-button
    [text]="'btn.save' | translate"
    (click)="saveAllRTF()"
    styleClass="green"
    *ngIf="rightsOnPoint?.edit && (!writeLock.locked || writeLock.currentUser) && displaySaveBtn"></super-button>
</div>
<div style="display: flex; justify-content: space-between; margin-bottom: 10px; align-items: center">
  <h1 style="color: #24344b; font-weight: 600; font-size: 16px; margin-bottom: 0px"></h1>
  <a
    style="color: #12ceff; font-weight: 600; font-size: 16px; text-transform: uppercase; cursor: pointer"
    (click)="onShowAll()"
    ><i class="fad fa-arrows-v"></i>&nbsp;{{
      !showAllAccordion ? ('Tout ouvrir' | translate) : ('Tout fermer' | translate)
    }}</a
  >
</div>
<p-accordion [multiple]="true" (onOpen)="loadFile()">
  <p-accordionTab (click)="onClick('Motivation', 0)" [(selected)]="activeState[0]">
    <ng-template pTemplate="header">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex align-items-center">
          <h2 class="accordion-header-title" pTooltip="Motivation">
            Motivation
            <i
              class="icon-checkGreen green-icon"
              *ngIf="contenuStaticRTFFilesPresents && contenuStaticRTFFilesPresents.motivation"></i>
          </h2>
        </div>
        <div class="accordion-icon">
          <i
            *ngIf="rightsOnPoint?.edit"
            [ngClass]="{
              'icon-edit': true,
              'blue-icon': !writeLock.locked || writeLock.currentUser,
              'grey-icon': writeLock.locked && !writeLock.currentUser,
              'middle-icon': true,
              'mr-3': true
            }"
            [pTooltip]="
              writeLock.locked && !writeLock.currentUser
                ? ('PointLockedBy' | translate) + ' ' + writeLock.contact
                : null
            "
            (click)="accessEdition('Motivation', $event)"></i>
          <i
            *ngIf="pointID"
            (click)="openFullScreen($event, 'Motivation', Motivation)"
            class="icon-fullscreen blue-icon middle-icon mr-3"></i>
          <i
            pTooltip="Actualiser"
            tooltipPosition="bottom"
            (click)="reloadRTF($event, 'Motivation', 0)"
            class="far fa-redo-alt blue-icon middle-icon"></i>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <super-richedit
        [font]="globalElement?.configApp?.delibConfig?.defaultMiseEnFormeParams"
        [showHidde]="true"
        #document_editor_motivation
        [mergeFields]="fusions"
        (save)="save($event, 'Motivation')"></super-richedit>
    </ng-template>
  </p-accordionTab>
  <p-accordionTab (click)="onClick('Decision', 1)" [(selected)]="activeState[1]">
    <ng-template pTemplate="header">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex align-items-center">
          <h2 class="accordion-header-title" pTooltip="Décision">
            Décision
            <i
              class="icon-checkGreen green-icon"
              *ngIf="contenuStaticRTFFilesPresents && contenuStaticRTFFilesPresents?.decision"></i>
          </h2>
        </div>
        <div class="accordion-icon">
          <i
            *ngIf="rightsOnPoint?.edit"
            [ngClass]="{
              'icon-edit': true,
              'blue-icon': !writeLock.locked || writeLock.currentUser,
              'grey-icon': writeLock.locked && !writeLock.currentUser,
              'middle-icon': true,
              'mr-3': true
            }"
            [pTooltip]="
              writeLock.locked && !writeLock.currentUser
                ? ('PointLockedBy' | translate) + ' ' + writeLock.contact
                : null
            "
            (click)="accessEdition('Decision', $event)"></i>
          <i
            *ngIf="pointID"
            (click)="openFullScreen($event, 'Decision', Decision)"
            class="icon-fullscreen blue-icon middle-icon mr-3"></i>
          <i
            pTooltip="Actualiser"
            tooltipPosition="bottom"
            (click)="reloadRTF($event, 'Decision', 1)"
            class="far fa-redo-alt blue-icon middle-icon">
          </i>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <super-richedit
        [font]="globalElement?.configApp?.delibConfig?.defaultMiseEnFormeParams"
        [showHidde]="true"
        #document_editor_decision
        [mergeFields]="fusions"
        (save)="save($event, 'Decision')"></super-richedit>
    </ng-template>
  </p-accordionTab>
  <p-accordionTab (click)="onClick('Observations', 2)" [(selected)]="activeState[2]">
    <ng-template pTemplate="header">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex align-items-center">
          <h2 class="accordion-header-title" pTooltip="Observations">
            Observations
            <i
              class="icon-checkGreen green-icon"
              *ngIf="contenuStaticRTFFilesPresents && contenuStaticRTFFilesPresents?.observations"></i>
          </h2>
        </div>
        <div class="accordion-icon">
          <i
            *ngIf="rightsOnPoint?.edit"
            [ngClass]="{
              'icon-edit': true,
              'blue-icon': !writeLock.locked || writeLock.currentUser,
              'grey-icon': writeLock.locked && !writeLock.currentUser,
              'middle-icon': true,
              'mr-3': true
            }"
            [pTooltip]="
              writeLock.locked && !writeLock.currentUser
                ? ('PointLockedBy' | translate) + ' ' + writeLock.contact
                : null
            "
            (click)="accessEdition('Observations', $event)"></i>
          <i
            *ngIf="pointID"
            (click)="openFullScreen($event, 'Observations', Observations)"
            class="icon-fullscreen blue-icon middle-icon mr-3"></i>
          <i
            pTooltip="Actualiser"
            tooltipPosition="bottom"
            (click)="reloadRTF($event, 'Observations', 2)"
            class="far fa-redo-alt blue-icon middle-icon">
          </i>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <super-richedit
        [font]="globalElement?.configApp?.delibConfig?.defaultMiseEnFormeParams"
        [showHidde]="true"
        #document_editor_observation
        [mergeFields]="fusions"
        (save)="save($event, 'Observations')"></super-richedit>
    </ng-template>
  </p-accordionTab>
  <p-accordionTab (click)="onClick('ComplementDeDeliberation', 3)" [(selected)]="activeState[3]">
    <ng-template pTemplate="header">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex align-items-center">
          <h2 class="accordion-header-title" pTooltip="Complément de délibération">
            Complément de délibération
            <i
              class="icon-checkGreen green-icon"
              *ngIf="contenuStaticRTFFilesPresents && contenuStaticRTFFilesPresents.complementDeDeliberation"></i>
          </h2>
        </div>
        <div class="accordion-icon">
          <i
            *ngIf="rightsOnPoint?.edit"
            [ngClass]="{
              'icon-edit': true,
              'blue-icon': !writeLock.locked || writeLock.currentUser,
              'grey-icon': writeLock.locked && !writeLock.currentUser,
              'middle-icon': true,
              'mr-3': true
            }"
            [pTooltip]="
              writeLock.locked && !writeLock.currentUser
                ? ('PointLockedBy' | translate) + ' ' + writeLock.contact
                : null
            "
            (click)="accessEdition('ComplementDeDeliberation', $event)"></i>
          <i
            *ngIf="pointID"
            (click)="openFullScreen($event, 'ComplementDeDeliberation', ComplementDeDeliberation)"
            class="icon-fullscreen blue-icon middle-icon mr-3"></i>
          <i
            pTooltip="Actualiser"
            tooltipPosition="bottom"
            (click)="reloadRTF($event, 'ComplementDeDeliberation', 3)"
            class="far fa-redo-alt blue-icon middle-icon">
          </i>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <super-richedit
        [font]="globalElement?.configApp?.delibConfig?.defaultMiseEnFormeParams"
        [showHidde]="true"
        #document_editor_complementDeDeliberation
        [mergeFields]="fusions"
        (save)="save($event, 'ComplementDeDeliberation')"></super-richedit>
    </ng-template>
  </p-accordionTab>
  <p-accordionTab
    (click)="onClick('NotesDeSynthese', 4)"
    *ngIf="globalConfig?.delibConfig?.isiDelibeCitoyensEnabled"
    [(selected)]="activeState[4]">
    <ng-template pTemplate="header">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex align-items-center">
          <h2 class="accordion-header-title" pTooltip="Notes de synthèse">
            Notes de synthèse
            <i
              class="icon-checkGreen green-icon"
              *ngIf="contenuStaticRTFFilesPresents && contenuStaticRTFFilesPresents?.notesDeSynthese"></i>
          </h2>
        </div>
        <div class="accordion-icon">
          <i
            *ngIf="rightsOnPoint?.edit"
            [ngClass]="{
              'icon-edit': true,
              'blue-icon': !writeLock.locked || writeLock.currentUser,
              'grey-icon': writeLock.locked && !writeLock.currentUser,
              'middle-icon': true,
              'mr-3': true
            }"
            [pTooltip]="
              writeLock.locked && !writeLock.currentUser
                ? ('PointLockedBy' | translate) + ' ' + writeLock.contact
                : null
            "
            (click)="accessEdition('NotesDeSynthese', $event)"></i>
          <i
            *ngIf="pointID"
            (click)="openFullScreen($event, 'NotesDeSynthese', NotesDeSynthese)"
            class="icon-fullscreen blue-icon middle-icon mr-3"></i>
          <i
            pTooltip="Actualiser"
            tooltipPosition="bottom"
            (click)="reloadRTF($event, 'NotesDeSynthese', 4)"
            class="far fa-redo-alt blue-icon middle-icon">
          </i>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <super-richedit
        [font]="globalElement?.configApp?.delibConfig?.defaultMiseEnFormeParams"
        [showHidde]="true"
        #document_editor_notesDeSynthese
        [mergeFields]="fusions"
        (save)="save($event, 'NotesDeSynthese')"></super-richedit>
    </ng-template>
  </p-accordionTab>
</p-accordion>
<ng-template #isLoading>
  <div class="row h-100">
    <div class="col-md-12 d-flex justify-content-center align-items-center">
      <img src="./assets/img/loupe-loader.gif" />
    </div>
  </div>
</ng-template>
