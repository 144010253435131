<div class="buttonUpload">
  <p-fileUpload 
    #fubauto1 
    multiple="multiple" 
    mode="basic" 
    class="buttonUpload__left" 
    accept="application/pdf" 
    (onSelect)="onFileDropped(true, fubauto1, $event)" 
    [auto]="true" 
    [chooseLabel]="'btn.importAnnexe' | translate"
  ></p-fileUpload>
  <p-fileUpload 
    #fubauto2 
    multiple="multiple" 
    mode="basic" 
    (onSelect)="onFileDropped(false, fubauto2, $event)" 
    [auto]="true" 
    [chooseLabel]="'btn.importAssociate' | translate"
  ></p-fileUpload>
</div>

<div
id="drop_zone"
class="file--big--container"
dragDropDirective
(fileDropped)="onFileDropped(false, null, $event)">

<div class="alert alert-info" *ngIf="files.length === 0 && hasLoaded">{{ 'noFileFound' | translate }}.</div>
  <div class="table__dropzone">
    <div class="divOver__textImg">
      <i class="bi bi-file-earmark-plus fa-3x"></i>
      <span class="textOver">{{'dropAFileToAddIt' | translate}}</span>
    </div>
  </div>

  <super-table
    id="super-files-tables"
    class="tableFile"
    #superTableFiles
    *ngIf="files.length > 0"
    [items]="files"
    [columns]="columnsFiles"
    id="fileTable"
    [height]="'calc(100vh - 340px)'"
    (onClickDelete)="deleteSelectionFiles($event)"
    [hideDeleteSelection]="fileRights?.files.delete === false"
    (onClickRow)="downloadFile($event)"
    [multiselect]="true"
    [forselection]="actions"
    [menu]="filesMenu"
    (menuItemsRowClick)="receive_menu_Files($event); openMenu($event)">
  </super-table>
</div>
  
<skeleton-table *ngIf="files.length === 0 && !hasLoaded" [items]="columnsFiles"></skeleton-table>


<p-dialog
  styleClass="super-compos-modal-content"
  [(visible)]="openModification"
  [modal]="true"
  [style]="{ width: '50vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">
        {{ 'fileModification' | translate }}
      </h5>
      <div class="d-flex">
        <super-button
          styleClass="blue"
          (click)="openModification = false"
          [text]="'btn.cancel' | translate"></super-button>
        <super-button styleClass="green ml-3" (click)="submitModificationFile()" text="Confirmer"></super-button>
      </div>
    </div>
  </p-header>
  <div [formGroup]="formFilesModification">
    <div class="row">
      <div class="col-md-9 mb-2">
        <label class="input-label d-block">{{ 'global.title' | translate }}/ {{ 'description' | translate }}</label>
        <input type="text" pInputText formControlName="description" class="w-100" />
      </div>
      <div class="col-md-3 mb-2">
        <label class="input-label d-block">{{ 'displayOrder' | translate }}</label>
        <input type="text" pInputText formControlName="OrdreAffichage" class="w-100" />
      </div>
      <div class="col-md-2">
        <label class="input-label d-block mb-2"
          >{{ 'fileType' | translate }} <i class="fas fa-info-circle smallIcon" [pTooltip]="getTooltipContent()"></i
        ></label>
        <div class="d-flex align-items-center justify-content-start">
          <label class="input-label mr-2">{{ 'annex' | translate }}</label>
          <p-inputSwitch
            [readonly]="isAnnexReadonly()"
            (onChange)="changeTypeOfFileInModification($event)"
            formControlName="annexe"></p-inputSwitch>
        </div>
      </div>
      <div class="col-md-2" *ngIf="formFilesModification.value.annexe === true">
        <label class="input-label d-block">{{ 'annexNumber' | translate }} *</label>
        <input type="number" pInputText formControlName="annexeNumero" class="w-100" />
      </div>
      <div class="col-md-4" *ngIf="globalElement.configApp.delibConfig.isiDelibeMandatairesEnabled">
        <label class="input-label d-block"
          >{{ 'webVisibility' | translate }} : {{ 'webVisibility.mandataire' | translate }}</label
        >
        <p-checkbox
          [label]="'webVisibility.mandataire' | translate"
          binary="true"
          formControlName="visibleDelibeWeb"></p-checkbox>
      </div>
      <div class="col-md-4" *ngIf="globalElement.configApp.delibConfig.isiDelibeCitoyensEnabled">
        <label class="input-label d-block"
          >{{ 'webVisibility' | translate }} : {{ 'webVisibility.citoyen' | translate }}</label
        >
        <p-checkbox
          [label]="'webVisibility.citoyen' | translate"
          binary="true"
          formControlName="visibleDelibeWebCitoyen"></p-checkbox>
      </div>
    </div>
  </div>
</p-dialog>

<p-confirmDialog
  #cd
  appendTo="body"
  styleClass="super-compos-modal-content confirmModal"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  key="file">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Non" (click)="cd.reject()"></button>
    <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="Oui" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>
<p-dialog
  styleClass="super-compos-modal-content"
  [(visible)]="confirmDialogBool"
  [modal]="true"
  [style]="{ width: '30vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">Confirmation</h5>
      <div class="d-flex">
        <super-button
          styleClass="blue"
          (click)="confirmDialogBool = false"
          [text]="'btn.close' | translate"></super-button>
      </div>
    </div>
  </p-header>
  <section>
    <p>
      Souhaitez-vous ajouter le/les fichiers <b><u>PDF</u></b> comme annexe(s) ?
    </p>
    <p>
      Attention cela ne concerne pas les autres extensions de fichiers qui sont toutes considérées comme des fichiers
      associés.
    </p>
    <div class="d-flex align-items-center justify-content-end">
      <!-- 1st part of the modal directly accessed when its opened -->
      <super-button styleClass="red mr-2" (click)="addFileAnnexe(false)" [text]="'Refuser'"></super-button>
      <super-button styleClass="green" (click)="addFileAnnexe(true)" [text]="'Confirmer'"></super-button>
    </div>
  </section>
</p-dialog>

<p-sidebar [(visible)]="modalPdfViewer" position="right" [style]="{ width: '50vw' }">
  <section>
    <ngx-extended-pdf-viewer
      *ngIf="blobViewer"
      [src]="blobViewer"
      height="100vh"
      [useBrowserLocale]="true"
      [zoom]="'auto'"
      [showFindButton]="true"
      [showPagingButtons]="true"
      [showZoomButtons]="true"
      [showPresentationModeButton]="true"
      [showOpenFileButton]="false"
      [showPrintButton]="false"
      [showDownloadButton]="false"
      [showBookmarkButton]="false"
      [showSecondaryToolbarButton]="true"
      [showRotateButton]="true"
      [showHandToolButton]="true"
      [showScrollingButton]="true"
      [showSpreadButton]="false"
      [showPropertiesButton]="true"
      [customToolbar]="additionalButtons"
      [(page)]="currentPage">
    </ngx-extended-pdf-viewer>
    <ng-template #additionalButtons>
      <div id="toolbarViewer">
        <div id="toolbarViewerLeft">
          <pdf-toggle-sidebar></pdf-toggle-sidebar>
          <div class="toolbarButtonSpacer"></div>
          <pdf-find-button [showFindButton]="true" [textLayer]="true"></pdf-find-button>
          <pdf-paging-area></pdf-paging-area>
        </div>
        <div id="toolbarViewerRight">
          <pdf-presentation-mode></pdf-presentation-mode>
          <pdf-select-tool></pdf-select-tool>
          <pdf-rotate-page></pdf-rotate-page>
          <pdf-hand-tool></pdf-hand-tool>
          <pdf-bookmark></pdf-bookmark>
          <pdf-toggle-secondary-toolbar></pdf-toggle-secondary-toolbar>
          <!-- <pdf-print></pdf-print> -->
        </div>
        <pdf-zoom-toolbar></pdf-zoom-toolbar>
      </div>
    </ng-template>
  </section>
</p-sidebar>
