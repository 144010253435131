<!-- <section class="overflow-100"> -->
     <div class="userDetails pl-5">
          <div
              class="w-100 super-compos-sectionseparator-horizontal pl-0 pr-0 header-container d-flex align-items-center justify-content-between mb-4 userDetails__header">
              <div class="align-items-left d-flex"
                  *ngIf="contact && contact?.typeSociete === false || contact?.typeSociete === null">
                  <h1 class="super-compos-titre-h1 border-0 m-0" id="h1ContactDetails" pTooltip="{{contact?.name}}"  tooltipPosition="bottom">
                      {{contact?.name|maxLength:30}}
                  </h1>
                  <p class="userDetails__header__function mb-0">{{contact?.fonction}} <span
                          *ngIf="contact?.listSocieteLies?.length > 0">pour</span> <span
                          *ngFor="let c of contact?.listSocieteLies">{{c?.nom}} </span></p>
              </div>
              <div class="align-items-left d-flex" *ngIf="contact && contact?.typeSociete === true">
                  <h1 class="super-compos-titre-h1 border-0 m-0" id="h1SocieteDetails">
                      {{contact?.nom}}
                  </h1>
      
              </div>
              <div class="d-flex align-items-center justify-content-between">
                  <super-button text="Modifier" styleClass="green mb-2"
                      id="btnEditContact" (click)="goToEdition()">
                  </super-button>
                  <super-button text="Supprimer" class="d-inline-block ml-3 mb-2"
                      styleClass="red super-compos-primary-btn-delete" id="btnDelete" (click)="deleteContact()"></super-button>
              </div>
          </div>
          <section class="overflow-details">
              <div class="userDetails__contact mb-3">
                  <h3 class="super-compos-titre-h3 ml-0">
                      Coordonnées de contact
                  </h3>
      
                  <div class="contact">
                      <div class="contact__body" *ngIf="contact && contact.listContactsAdresses">
                          <div class="row">
                              <div *ngFor="let a of contact.listContactsAdresses; let i = index;"
                                  class="col-lg-4 col-md-4 col-sm-12" [ngClass]="{'super-compos-sectionseparator-vertical': i !== 0}">
                                  <div class="row">
                                      <div class="col-12">
                                          <h4 class="super-compos-section-h4">
                                              {{a?.type}}
                                          </h4>
                                      </div>
                                  </div>
                                  <div class="d-flex align-items-center">
                                      <i class="fas fa-phone-rotary mb-2"></i>
                                      <div class="mb-2 p-0 ml-3">
                                          {{a?.adresse?.telephone ? a?.adresse?.telephone : 'Pas de téléphone défini'}}
                                      </div>
                                  </div>
                                  <div class="d-flex align-items-center">
                                      <i class="fas fa-mobile mb-2"></i>
                                      <div class="mb-2 p-0 ml-3">
                                          {{a?.adresse?.gsm ? a?.adresse?.gsm : 'Pas de portable défini'}}</div>
                                  </div>
                                  <div class="d-flex align-items-center">
                                      <i class="fas fa-at mb-2"></i>
                                      <div class="mb-2 p-0 ml-3">
                                          <span
                                              id="emailContactDetails">{{a?.adresse?.mail ? a?.adresse?.mail : 'Pas de mail défini'}}</span>
                                      </div>
                                  </div>
                                  <div class="d-flex align-items-center">
                                      <i class="fas fa-fax mb-2"></i>
                                      <div class="mb-2 p-0 ml-3">
                                          {{a?.adresse?.fax ? a?.adresse?.fax : 'Pas de fax défini' }}</div>
                                  </div>
                              </div>
                          </div>
                          <div *ngIf="contact && contact.typeSociete === true">
                              <div class="row">
                                  <div class="col-4" *ngIf="contact?.bce">
                                      <h4 class="super-compos-section-h4">
                                          Bce
                                      </h4>
                                      {{contact?.bce}}
                                  </div>
                                  <div class="col-4" *ngIf="contact?.gerantSociete">
                                      <h4 class="super-compos-section-h4">
                                          Gérant
                                      </h4>
                                      {{contact?.gerantSociete}}
                                  </div>
                                  <div class="col-4" *ngIf="contact?.secteurActivite">
                                      <h4 class="super-compos-section-h4">
                                          Secteur
                                      </h4>
                                      {{contact?.secteurActivite}}
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-4" *ngIf="contact && contact.tva">
                                      <h4 class="super-compos-section-h4">
                                          N°TVA
                                      </h4>
                                      {{contact?.tva}}
                                  </div>
                                  <div class="col-4" *ngIf="contact && !contact.tva">
                                      <h4 class="super-compos-section-h4">
                                          N°TVA
                                      </h4>
                                      Pas de numéro belge
                                  </div>
                                  <div class="col-4" *ngIf="contact?.societeFormeJurIdique">
                                      <h4 class="super-compos-section-h4">
                                          FormeJuridique
                                      </h4>
                                      {{contact?.societeFormeJurIdique?.description}}
                                  </div>
                                  <div class="col-4" *ngIf="contact?.signature1">
                                      <h4 class="super-compos-section-h4">
                                          Signature
                                      </h4>
                                      {{contact?.signature1}}
                                  </div>
                              </div>
                          </div>
      
                      </div>
                  </div>
              </div>
              <div class="userDetails__adress mb-4" *ngIf="markers.length > 0">
                  <h3 class="super-compos-titre-h3 mt-1">Adresse(s)</h3>
                  <div class="row d-flex justify-content-between align-items-center">
      
                      <div class="col-md-12" *ngIf="adressLoaded">
                         <google-map height="400px" width="100%" [zoom]="12" [center]="gmap_center" [options]="gmap_options">
                              <map-marker #somemarker="mapMarker"*ngFor="let marker of markers" (mapClick)="openInfo(somemarker,marker)" [position]="marker.position" 
                                [options]="marker.options">
                              </map-marker>
                              <map-info-window>{{ infoContent }}</map-info-window>
                          </google-map>
                      </div>
                      <div class="col-md-12 " *ngIf="!adressLoaded ">
                          <img src="./assets/img/loupe-loader.gif " class="img-fluid ">
                      </div>
                  </div>
              </div>
              <div class="row d-flex justify-content-between align-items-center mb-2 ">
                  <div *ngIf="contact && contact.listContactsAdresses " class="col-md-8 row ">
                      <span *ngFor="let ad of contact.listContactsAdresses " class="col-md-6 ">
                          <div
                              *ngIf="ad?.adresse?.impression_Adresse ||ad?.adresse?.impression_CodePostal || ad?.adresse?.impression_Localite || ad?.adresse?.impression_Pays ">
                              <h4 class="super-compos-section-h4 m-0 ">
                                  {{ad.type}}
                              </h4>
                              <p class="m-0 " *ngIf="ad?.adresse?.impression_Adresse !== 'null, null null' && ad?.adresse?.impression_Adresse !== 'null' ">{{ad?.adresse?.impression_Adresse}}
      
                              <p class="m-0 " id="codePostalContactDetails">{{ad?.adresse?.impression_CodePostal}}</p>
                              <p class="m-0 " id="localiteContactDetails">{{ad?.adresse?.impression_Localite}}</p>
                              <p class="m-0 " id="paysContactDetails">{{ad?.adresse?.impression_Pays}}</p>
                              <p class="m-0 "></p>
                          </div>
                      </span>
                  </div>
              </div>
              <div class="mb-4 ">
                  <div class="userDetails__members mb-3 ">
                    <super-accordion title="Membre & société liés">
                         <super-accordion-tab *ngIf="liensSocMembres.length > 0" [header]="'Contacts liés (' + liensSocMembres?.length +')'" [showEditBtn]="false">
                             <ng-template pTemplate="content">
                                   <super-table (onClickRow)="onContactSelect($event) " class="super-table "
                                   (onClickDelete)="deleteContact() " [columns]="colSociety " [items]="liensSocMembres"
                                   id="MembersLink3 " height="40" [multiselect]="false ">
                                   </super-table>
                             </ng-template>
                         </super-accordion-tab>
                         <super-accordion-tab *ngIf="liensSocSoc.length > 0" [header]="'Contacts liés (' + liensSocSoc?.length +')'" [showEditBtn]="false">
                              <ng-template pTemplate="content">
                                   <super-table class="super-table" (onClickRow)="onSocietySelect($event)"
                                   (onClickDelete)="deleteSociety($event)" [columns]="colSociety"
                                   [items]="liensSocSoc" id="socLink" height="40"
                                   [multiselect]="false">
                                    </super-table>
                              </ng-template>
                          </super-accordion-tab>
                     </super-accordion>

                  </div>
              </div>
      
              <div class="userDetails__infos"
                  *ngIf="contact && contact?.typeSociete && (contact?.siteWeb || contact?.siteWeb2)">
                  <h3 class="super-compos-titre-h3 mt-1">Infos Complémentaires</h3>
                  <h4 class="super-compos-section-h4">
                      Site Web
                  </h4>
                  <ul *ngIf="contact?.siteWeb || contact?.siteWeb2">
                      <li>
                          <a [href]="contact?.siteWeb" target="_blank" rel="noopener noreferrer"
                              class="linkColor">{{ contact?.siteWeb }}</a>
                      </li>
                      <li *ngIf="contact?.siteWeb2">
                          <a [href]="contact?.siteWeb2" target="_blank" rel="noopener noreferrer"
                              class="linkColor">{{ contact?.siteWeb2 }}</a>
                      </li>
                  </ul>
              </div>
      
              <div class="form__showMore" *ngIf="contact && contact?.typeSociete === false || contact?.typeSociete === null">
                  <div class="d-flex justify-content-center">
                      <super-button text="Afficher les informations supplémentaires" (click)="fullMode = !fullMode"
                          styleClass="blue showMoreBtn mb-5" icon="fas fa-search-plus" *ngIf="!fullMode"
                          id="showMore">
                      </super-button>
                      <super-button text="Cacher les informations supplémentaires" (click)="fullMode = !fullMode"
                          styleClass="blue showMoreBtn mb-5" icon="fas fa-search-minus" *ngIf="fullMode"
                          id="showMore">
                      </super-button>
                  </div>
      
                  <div *ngIf="fullMode">
                      <div class="userDetails__infos">
                        <!-- * Infos complémentaires -->
                          <h3 class="super-compos-titre-h3 mt-1">Infos Complémentaires</h3>
                          <div class="row">
                              <div class="col-4 mb-3">
                                  <h4 class="super-compos-section-h4">
                                      Type de contact
                                  </h4>
                                  <span
                                      id="contactType">{{ contact?.typeContact ? contact?.typeContact.typeContact: 'Non défini' }}</span>
                              </div>
                              <div class="col-4 mb-3">
                                  <h4 class="super-compos-section-h4">
                                      Titre
                                  </h4>
                                  <span id="contactTitre">{{ contact?.titre? contact?.titre: 'Non défini' }}</span>
                              </div>
                              <div class="col-4 mb-3">
                                  <h4 class="super-compos-section-h4">
                                      Langue
                                  </h4>
                                  <span
                                      id="contactLangue">{{ contact?.langue?.description? contact?.langue?.description: 'Français' }}</span>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-4 mb-3">
                                  <h4 class="super-compos-section-h4">
                                      Nom interne
                                  </h4>
                                  <span id="nomInterne">{{contact?.nomInterne? contact?.nomInterne: 'Non défini'}}</span>
                              </div>
                              <div class="col-4 mb-3">
                                  <h4 class="super-compos-section-h4">
                                      Lieu de naissance
                                  </h4>
                                  <span
                                      id="naissanceLieu">{{contact?.naissanceLieu? contact.naissanceLieu : 'Non défini'}}</span>
      
                              </div>
                              <div class="col-4 mb-3">
                                  <h4 class="super-compos-section-h4">
                                      Date de naissance
                                  </h4>
                                  {{contact?.dateNaissance? $moment(contact?.dateNaissance).format('DD/MM/YYYY'): 'Non définie'}}
                              </div>
      
                          </div>
                          <div class="row">
                              <div class="col-4 mb-3">
                                  <h4 class="super-compos-section-h4">
                                      Numéro de registre national
                                  </h4>
                                  {{contact?.numeroCarteNISS? contact?.numeroCarteNISS: 'Non défini'}}
                              </div>
                              <div class="col-4 mb-3">
                                  <h4 class="super-compos-section-h4">
                                      Etat civil
                                  </h4>
                                  {{contact?.etatCivil? contact.etatCivil: 'Non défini'}}
                              </div>
                              <div class="col-4 mb-3">
                                  <h4 class="super-compos-section-h4">
                                      Nationalité
                                  </h4>
                                  {{contact?.nationalite? contact?.nationalite: 'Non définie'}}
                                </div>
                          </div>
                          <div class="row">
                              <div class="col-4 mb-3">
                                  <h4 class="super-compos-section-h4">
                                      Sexe
                                  </h4>
                                  {{contact?.sexe? contact.sexe: 'Non défini'}}
                              </div>
                              <div class="col-8 mb-3">
                                  <h4 class="super-compos-section-h4">
                                      Remarque
                                  </h4>
                                  <span id="commentaires">
                                      {{contact?.commentaires? contact.commentaires: 'Non définie'}}
                                  </span>
                              </div>
                          </div>

                          <!--* Connecteurs -->
                          <h3 *ngIf="connectedUserRights.menu.administrationDelibeMandataires" class="super-compos-titre-h3 mt-1">Connecteurs</h3>
                          <div *ngIf="connectedUserRights.menu.administrationDelibeMandataires" class="row">
                              <div class="col-4 mb-3">
                                  <h4 class="super-compos-section-h4">
                                      Accès iDélibé Mandataire
                                  </h4>
                                  <span id="accessiDelibeMandataire">{{ contact?.accesModuleExterneDelibe ? '<b style="color: #07E299;"><i class="bi bi-check-circle-fill"></i></b>' : '<b style="color: #FF5671;"><i class="bi bi-x-circle-fill"></i></b>' }}</span>
                              </div>
                              <div class="col-4 mb-3" *ngIf="contact?.accesModuleExterneDelibe">
                                  <h4 class="super-compos-section-h4">
                                      Login iDélibé Mandataire
                                  </h4>
                                  <span
                                      id="loginDelibeWeb">{{ contact?.loginDelibeWeb}}</span>
                              </div>
                          </div>
                      </div>
      
                  </div>
              </div>
          </section>
    </div>
<!-- </section> -->
      
          <p-confirmDialog key="detailModal" #cd appendTo="body" styleClass="super-compos-modal-content confirmModal" header="Confirmation"
              icon="pi pi-exclamation-triangle">
              <p-footer>
                  <button type="button" pButton icon="pi pi-times" label="Non" (click)="cd.reject()"></button>
                  <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="Oui"
                      (click)="cd.accept()"></button>
              </p-footer>
          </p-confirmDialog>
      