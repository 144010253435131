import { StatusExportIDelibeMandataire } from './export/status-export-delibe-mandataire';
import { IAppConfiguration, IDelibConfig } from './response/IAppConfiguration';

export class ConfigApp {
  public clientName: string;
  public clientType: string;
  public delibConfig: IDelibConfig;
  public description: string;
  public email: string;
  public isSmtpConfigured: boolean;
  public phone: string;
  public searchTags: any = null;
  public theme: string;
  public urlDocumentation: string;
  public webSite: string;
  public modeleGenerationRequestStatutes: Array<any> = [];
  public seanceExportIDelibeStatutes: Array<StatusExportIDelibeMandataire> = [];
  constructor(data: IAppConfiguration) {
    Object.assign(this, data);
    if (this.modeleGenerationRequestStatutes && this.modeleGenerationRequestStatutes.length > 0) {
      this.modeleGenerationRequestStatutes.map((item) => {
        item.label = item.description;
        item.value = item.id;
      });
    }
    if (this.seanceExportIDelibeStatutes && this.seanceExportIDelibeStatutes.length > 0) {
      this.seanceExportIDelibeStatutes.map((item) => {
        item.label = item.description;
        item.value = item.id;
      });
    }
  }
}
