<section class="meetings-details h-100">
    <as-split direction="horizontal" [dir]="'ltr'">
        <as-split-area class="ninja-content-primary" [size]="30">
            <!-- <super-title [text]="title" level="1"></super-title> -->
            <div class="mb-4 super-compos-sectionseparator-horizontal">
                <div class="w-100 pl-0 pr-0 header-container d-flex align-items-center justify-content-center pb-2">
                    <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center j ml-3 p-fluid">
                            <p-dropdown [virtualScroll]="true" itemSize="30" styleClass="w-100" [options]="boxes|sort:'title':'ASC'" placeholder="Choisir une catégorie" optionValue="title" optionLabel="title" *ngIf="boxes.length > 0" [filter]="true" [(ngModel)]="selected" [showClear]="true" (onChange)="onChange($event)">
                                <ng-template let-item pTemplate="selectedItem">
                                <span><i class="{{item?.icon}} mr-3 dropdown-icon"></i>{{item?.title}} {{item?.count ? '(' + item?.count + ')' : ''}}</span>
                                </ng-template>
                                <ng-template let-cat pTemplate="item">
                                    <div class="truncateLabel" pTooltip="{{cat?.title}}" tooltipPosition="right">
                                        <!-- <i class="{{cat?.icon}} mr-3"></i> -->
                                        {{cat.title}}</div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <section class="d-flex justify-content-end align-items-center mt-3 mb-2 mr-2">
                <p-selectButton class="displayByMediaQueries" [options]="justifyOptions" optionLabel="icon" optionValue="value" [(ngModel)]="currentDisposition" (click)="changeDisplay()">
                    <ng-template let-item>
                         <i [class]="item.icon"></i>
                    </ng-template>
               </p-selectButton>
            </section>
            <section class="super-list-container" *ngIf="items.length > 0" class="superListContainer" #superListContainer
                infinite-scroll
                infiniteScrollDistance="1"
                infiniteScrollUpDistance="1.5"
                (scrolled)="emitOnScrollDown()"
                [infiniteScrollContainer]="superListContainer">
                <div *ngFor="let item of items">
                    <article class="element-from-list" *ngIf="item" (click)="goTo(item)"  
                        [ngClass]="{'element-from-list--active': item?.id && item?.id === point?.id,
                        'element-border-vote--green':item?.pointVotes && item?.pointVotes.voteType !== 'PasDeVote' &&item?.pointVotes.resultatVote === 'Approuve',
                        'element-border-vote--orange':item?.pointVotes && item?.pointVotes.voteType !== 'PasDeVote' &&item?.pointVotes.resultatVote === 'Reporte',
                        'element-border-vote--red':item?.pointVotes && item?.pointVotes.voteType !== 'PasDeVote' &&item?.pointVotes.resultatVote === 'Refuse' }">
                        <div class="element-content" [ngClass]="{'element-status-green': item?.status === 'open','element-status-orange': item?.status === 'pending','element-status-red': item?.status === 'closed'}">
                            <h3 class="element-title" >{{item?.title}}</h3>
                            <div class="d-flex align-items-center">
                                 <span class="element-cat mr-2" *ngIf="item?.matiere" [pTooltip]="getTooltip('matter',item?.matiere)">{{item?.matiere|maxLength:18}}</span>
                                 <span class="element-cat mr-2" *ngIf="item?.service" [pTooltip]="getTooltip('department',item?.service)">{{item?.service|maxLength:18}}</span>
                                 <span class="element-cat" *ngIf="item?.typePoint" [pTooltip]="getTooltip('pointType',item?.typePoint)">{{item?.typePoint|maxLength:18}}</span>
                            </div>
                            <div class="element-votecontent justify-content-end mr-2">
                                 <div *ngIf="item?.modificationAutorisee && item?.modificationAFaireParContactId" class="element-votes element-votes__abstention mr-2" pTooltip="En correction" tooltipPosition="bottom">En correction</div>
                            </div>
                            <div *ngIf="item?.confidentiel" class="d-flex align-items-center justify-content-end ">
                                 <span class="element-public">{{'confidential'|translate}}</span>
                                 <i class="element-icon icon-lock"></i>
                            </div>
                       </div>
                    </article>
                </div>
           </section>
           <div class="field" *ngIf="items.length === 0">
                <div class="alert alert-primary text-center">
                     Il n'y a pas de résultat pour votre recherche
                </div>
                <skeleton-list></skeleton-list>
           </div>

        </as-split-area>
        <as-split-area [size]="70" class="ninja-content-secondary">
            <div class="ml-4" *ngIf="point && point?.id">
                <app-fiche-point [pointId]="point?.id"></app-fiche-point>
            </div>
            <div *ngIf="!point"  class="pt-3 container-right d-flex justify-content-center align-items-center">
                <img src="./assets/img/waitPoint.svg" class="img-fluid" width="500" height="500">
            </div>
        </as-split-area>
    </as-split>
</section>
