import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { sizeOfPagesOptions } from '../actionsTableSettings.utils';
import { ITabInfo } from '../models';

@Component({
  selector: 'app-actions-pagination',
  templateUrl: './actions-pagination.component.html',
  styleUrls: ['./actions-pagination.component.scss'],
})
export class ActionsPaginationComponent implements OnInit {
  sizeOfPages = sizeOfPagesOptions;

  @Input() sizeChoice: number;
  @Output() sizeChoiceChange = new EventEmitter();

  @Input() pageNumber: number;
  @Output() pageNumberChange = new EventEmitter();

  @Input() totalPages: number;

  constructor() {}

  ngOnInit(): void {}

  hanleSizeChoiceChange(newValue: number) {
    this.sizeChoice = newValue;
    this.sizeChoiceChange.emit(newValue);
  }

  handlePageNumberChange(newValue: number) {
    this.pageNumber = newValue;
    this.pageNumberChange.emit(newValue);
  }
}
