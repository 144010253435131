import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DataService } from 'src/app/core/services/data.service';
import { Global_Class } from 'src/app/shared/models/global';
import * as async from 'async';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/core/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-status-change',
  templateUrl: './status-change.component.html',
  styleUrls: ['./status-change.component.scss'],
})
export class StatusChangeComponent implements OnInit {
  public mode = 'point';
  public header = '';
  public globalElements: Global_Class;
  public confirmButtonDisabled = true;
  public listOfPoints = [];

  public statusChosen;

  public loaded = false;
  public pointsWithError = [];
  public columns = [
    { field: 'title', header: 'Titre' },
    {
      field: 'checkIfStatusIsOk',
      header: 'Changement de statut possible',
      format: () => {
        return '<b style="color: #FF5671;"><i class="bi bi-x-circle-fill"></i></b>';
      },
    },
  ];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dataS: DataService,
    public spinner: NgxSpinnerService,
    public api: ApiService,
    public toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    async.parallel(
      [
        (callback) => {
          this.header = this.config.data.header;
          //set le type de statut que l'on va modifier pour afficher le bon HTML
          if (this.config.data.points === true) {
            this.mode === 'point';
            this.listOfPoints = this.config.data.listOfPoints;
            callback();
          }
        },
        (callback) => {
          this.dataS.getGlobalElements.subscribe((res: any) => {
            this.globalElements = new Global_Class(res);
            callback();
          });
        },
      ],
      () => {
        this.spinner.hide();
      }
    );
  }

  onChangeStatutPoints(event) {
    const data = {
      pointsToChange: [],
    };
    async.eachSeries(
      this.listOfPoints,
      (point, next) => {
        data.pointsToChange.push({
          pointId: point.id,
          newStatutId: event.value,
        });
        next();
      },
      () => {
        this.api.postChangeStatusPoint(data).subscribe((res: any) => {
          const pointsError = [];
          res.pointsToChange.map((x) => {
            if (!x.canChange) {
              pointsError.push(x);
            }
          });
          if (pointsError.length === 0) {
            this.ref.close(this.statusChosen);
          } else {
            this.pointsWithError = this.listOfPoints.filter((x) => pointsError.map((x) => x.pointId).includes(x.id));
            if (pointsError.length === 1) {
              this.toastr.error(
                'Il y a ' + pointsError.length + ' point qui ne peut pas changer de statut',
                "Le changement de statut ne peut pas s'opérer"
              );
            } else {
              this.toastr.error(
                'Il y a ' + pointsError.length + ' points qui ne peuvent pas changer de statut',
                "Le changement de statut ne peut pas s'opérer"
              );
            }
          }
        });
      }
    );
  }
}
