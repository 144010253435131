<div class="row">
  <div class="col-md-12 mb-2" *ngIf="displayTitle" style="z-index: 9999">
    <super-title [text]="title" level="1" [buttons]="buttons"></super-title>
  </div>
  <div
    class="col-md-12 mb-3 mt-3 d-flex align-items-center justify-content-end"
    *ngIf="!displayTitle"
    style="z-index: 9999">
    <super-button text="Enregistrer" styleClass="green" (click)="save()"></super-button>
  </div>
  <div *ngIf="displayErrorNoRightSave" class="alert alert-danger">
    Vous ne possédez pas les droits de générer un document et par conséquent de le sauvegarder
  </div>

  <div class="col-md-12 mb-3">
    <super-richedit
      [font]="globalElement?.configApp?.delibConfig?.defaultMiseEnFormeParams"
      #editor
      [mergeFields]="fusions"
      height="90vh"
      [readOnly]="readOnly"></super-richedit>
  </div>
</div>
