<div class="mailsFolders__container">
  <div class="mailsFolders__header">
    <div class="d-flex align-items-center" *ngIf="dragEnabled">
      <p-inputSwitch [(ngModel)]="editMode"></p-inputSwitch>
      <div class="folders__mainTitle ml-2 mt-0">{{ 'menuModifications' | translate }}</div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="mailsFolders__body">
          <div class="row">
            <div
              class="col-12"
              *ngIf="boxesIndex.length > 0"
              cdkDropList
              (cdkDropListDropped)="drop(boxesIndex, $event)">
              <div
                class="foldersContainer"
                cdkDrag
                [cdkDragDisabled]="!editMode"
                *ngFor="let boxes of boxesIndex; index as i"
                [ngClass]="{ 'd-none': i === 1 && boxes.length === 0 }">
                <div
                  cdkDropList
                  [id]="indexes[i]"
                  [cdkDropListConnectedTo]="getOthers(i)"
                  [cdkDropListData]="boxes"
                  (cdkDropListDropped)="drop(boxes, $event)">
                  <div
                    class="col-12 folders__content"
                    cdkDrag
                    [cdkDragDisabled]="!editMode"
                    *ngFor="let box of boxes"
                    (click)="function(box, box.click, $event)">
                    <div class="folders__title">
                      <i class="{{ box.icon }}"></i>
                      <h3 class="super-compos-titre-h3 displayInline">{{ box?.name | translate }}</h3>
                    </div>
                    <div class="folders__count">
                      {{ box?.count }}
                      <div class="rotateArrow ml-2 arrowPosition" *ngIf="editMode === false">
                        <svg
                          width="18px"
                          height="18px"
                          viewBox="0 0 18 18"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink">
                          <g
                            id="Design"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                            fill-opacity="0.8">
                            <g id="CON04_ContactsList" transform="translate(-1282.000000, -321.000000)" fill="#24344B">
                              <g id="noun_down_2007438-copy-6" transform="translate(1282.000000, 321.000000)">
                                <g id="Group">
                                  <path
                                    d="M18,9 C18,4.0293 13.9707,0 9,0 C4.0293,0 0,4.0293 0,9 C0,13.9707 4.0293,18 9,18 C13.9707,18 18,13.9707 18,9 Z M1.125,9 C1.125,4.6503 4.6503,1.125 9,1.125 C13.3488,1.125 16.875,4.6503 16.875,9 C16.875,13.3497 13.3488,16.875 9,16.875 C4.6503,16.875 1.125,13.3497 1.125,9 Z"
                                    id="Shape"
                                    fill-rule="nonzero"></path>
                                  <path
                                    d="M13.3470314,7.69490803 C13.5522614,7.47814319 13.5505582,7.12900965 13.3444767,6.91313318 C13.1383952,6.69725672 12.802874,6.69636834 12.5950893,6.91046804 L12.5950893,6.91046804 L8.99973314,10.6603222 L5.40522851,6.90957966 L5.40522851,6.90957966 C5.19744383,6.69547996 4.86277425,6.69725672 4.65584115,6.9122448 C4.44890805,7.12812127 4.44805647,7.47725481 4.65328642,7.69401965 L4.65328642,7.69401965 L8.62163315,11.8338728 L8.62163315,11.8338728 C8.62248472,11.8347612 8.6233363,11.8356496 8.62418788,11.8374264 C8.83197256,12.0541912 9.16919687,12.0541912 9.37698155,11.8374264 C9.37783312,11.836538 9.3786847,11.8356496 9.37953628,11.8338728 L9.37953628,11.8338728 L13.3470314,7.69490803 L13.3470314,7.69490803 Z"
                                    id="Path"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cdk-placeholder" [style.height.px]="getHeight(boxes)" *cdkDragPlaceholder></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog [contentStyle]="{ 'min-width': '360px' }" [(visible)]="openModal" modal="true" appendTo="body">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">{{ 'addNewFilter' | translate }}</h5>
      <button class="super-compos-secondary-btn-noshadow" (click)="submit()">{{ 'btn.confirm' | translate }}</button>
    </div>
  </p-header>
  <div class="modal-body">
    <form class="row" [formGroup]="filterForm" (submit)="submit()">
      <div class="col-md-12 mb-3">
        <label class="super-compos-super-compos-label-input font-weight-bold text-uppercase" for="name">{{
          'title.session' | translate
        }}</label>
        <input type="text" class="form-control" formControlName="Title" autofocus />
      </div>
    </form>
  </div>
</p-dialog>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  acceptLabel="Oui"
  rejectLabel="Non"
  appendTo="body"
  key="boxConfirm">
</p-confirmDialog>
