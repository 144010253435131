import { Injectable } from '@angular/core';
import { GlobalRightPointsSeances } from 'src/app/shared/models/response/IGlobalRightPointsSeances';
import { ApiService } from './api.service';
import { iAdminService } from './iAdmin.service';

@Injectable({
  providedIn: 'root',
})
export class UserRightsService {
  constructor(private api: ApiService, private adminService:iAdminService) {}

  async getUserAbleToAddPoint(): Promise<boolean> {
    const userRights = (await this.api.getRightsPointsSeances().toPromise()) as GlobalRightPointsSeances;
    const userHasAddPointRight = userRights.typeSeanceRights.some((x) => x.addPoint);
    return userHasAddPointRight;
  }

  async getIAdminGlobalConfig(): Promise<boolean> {
    const GlobalRight:any = (await this.adminService.getGlobalConfig().toPromise());
    const userHasRightToImport3P = GlobalRight.delibConfig.is3PEnabled;
    return userHasRightToImport3P;
  }
}
