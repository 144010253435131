import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'underscore';
import * as async from 'async';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserInfo } from 'src/app/shared/models/user-info';
import { UserContact } from 'src/app/shared/models/iContact/user-contact';
import { Contact } from 'src/app/shared/models/iContact/contact';
import { ApiService } from 'src/app/core/services/api.service';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { DataService } from 'src/app/core/services/data.service';
import { Point } from 'src/app/shared/models/point';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { DestinataireInput } from 'src/app/shared/models/destinataire_input';
import { Meeting } from 'src/app/shared/models/meeting';
import { Global_Class } from 'src/app/shared/models/global';

@Component({
  selector: 'app-action-create-modal',
  templateUrl: './action-create-modal.component.html',
  styleUrls: ['./action-create-modal.component.scss'],
})
export class ActionCreateModalComponent implements OnInit {
  public openingWindow: any;
  public model: any = {};
  public status: any[] = [];
  public responsibles: any;
  public responsiblesServices: any[] = [];
  public selectedDest: any[] = [];
  public today = new Date();
  public currentPoint: Point;
  public currentMeeting: Meeting;
  public listCourrier: any[] = [];
  public actionDestinataire: any[] = [];
  public mailDestinataire: any[] = [];
  public contactID: number;
  public userInfos: UserInfo;
  public contacts: UserContact[] = [];
  public allcontacts: Array<Contact> = [];
  public flagNoCurrentCourrier: boolean;
  public types: any[] = [];

  public actionForm: FormGroup = new FormGroup({
    Description: new FormControl(null),
    EndDate: new FormControl(null),
    Statut: new FormControl(null),
    Type: new FormControl(null),
    Responsibles: new FormControl(null),
    ActionDestinataire: new FormControl(null),
    AccuseReponse: new FormControl(null),
  });
  public configApp: any;
  public services: Array<any>;
  public destinataires: Array<any>;
  public serviceList: Array<any> = [];
  public updateDestinataireBool: boolean = false;
  public globalElements: Global_Class = null;
  constructor(
    private adminApi: iAdminService,
    private api: ApiService,
    private iContactService: IContactService,
    private toastr: ToastrService,
    public confirmationService: ConfirmationService,
    public dataService: DataService,
    public spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    if (this.currentPoint === null) {
      this.flagNoCurrentCourrier = true;
    }
    this;
    let destinatairesSelected = [];
    async.waterfall([
      (cb) => {
        this.dataService.getGlobalElements.subscribe((res: any) => {
          this.globalElements = new Global_Class(res);
          if (this.globalElements.isLoaded()) {
            cb(null);
          }
        });
      },
      (cb) => {
        this.adminApi.getGlobalServices().subscribe(
          (response) => {
            const arrayParent = [];
            const arrayChildren = [];
            response.map((item) => {
              if (item.parentId === 0) {
                item.children = [];
                if (item.description !== null) {
                  arrayParent.push(item);
                }
              } else {
                if (item.description !== null) {
                  arrayChildren.push(item);
                }
              }
            });

            if (arrayParent.length > 0 && arrayChildren.length > 0) {
              arrayParent.map((parent) => {
                arrayChildren.map((child) => {
                  child.value = child.id;
                  child.label = child.description;
                  if (parent.id === child.parentId) {
                    parent.label = parent.description;
                    parent.value = parent.id;
                    parent.children.push({ label: child.description, value: child.id, parent: parent });
                  } else {
                    parent.label = parent.description;
                    parent.value = parent.id;
                  }
                });
              });
            } else {
              arrayParent.map((parent) => {
                parent.label = parent.description;
                parent.value = parent.id;
                parent.children = [];
              });
            }
            this.serviceList = arrayParent;
            this.serviceList = _.sortBy(this.serviceList, 'label');
            this.serviceList = _.uniq(this.serviceList, 'label');
            // Pour avoir les enfants & parents au meme niveau
            const tempParent = [];
            const tempChildren = [];
            this.serviceList.map((item) => {
              if (item.children.length > 0) {
                item.children.map((child) => {
                  tempChildren.push(child);
                });
              }
              tempParent.push(item);
            });
            let fusionnedArray = [];
            fusionnedArray = arrayParent.concat(arrayChildren);
            fusionnedArray.map((item) => {
              if (item.parent && item.parent.label) {
                item.name = item.parent.label + ' / ' + item.label;
              } else {
                item.name = item.label;
              }
              item.isGroup = 'non';
            });
            this.services = fusionnedArray;
            cb(null);
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (cb) => {
        this.configApp = this.globalElements.configApp;
        this.setSender();
        this.getActionDestinataires();
        cb(null);
      },
      (cb) => {
        if (this.configApp?.delibConfig?.hideStatutAction === false) {
          this.setStatus();
        }
        this.setTypes();
        cb(null);
      },
      (cb) => {
        if (this.contacts && this.contacts.length > 0) {
          this.setManager();
        }
        cb(null);
      },
      (cb) => {
        this.getPointDestinataires();
        cb(null);
      },
      (cb) => {
        if (this.destinataires && this.destinataires.length > 0) {
          this.destinataires.map((item) => {
            if (item.type === 'DESTINATAIRE' && item.foreColor !== 'green') {
              this.iContactService.get('/contacts?Ids=' + item.contactId).subscribe((res: any) => {
                const currentContact = _.first(res);
                destinatairesSelected.push({
                  label: (item.nom ? item.nom : '') + ' ' + (item.prenom ? item.prenom : ''),
                  contactId: item.contactId,
                  value: currentContact.user ? currentContact.user.id : null,
                });
              });
            }
          });
          setTimeout(() => {
            if (destinatairesSelected.length > 0) {
              destinatairesSelected = _.uniq(destinatairesSelected);
              cb(null);
            }
          }, 1000);
        } else {
          cb(null);
        }
      },
      (cb) => {
        this.actionForm.patchValue({
          EndDate: this.today,
          ActionDestinataire: destinatairesSelected,
        });
      },
    ]);
  }

  /**
   * Method call the api to retreive all user for multiselect destinataire
   */
  getActionDestinataires(usersids?: Array<any>) {
    this.contacts = [];
    let data = null;
    if (usersids && usersids.length > 0) {
      data = {
        UserIds: usersids.toString(),
      };
    } else {
      data = null;
    }

    this.iContactService.getUserWithDataAdmin().subscribe(
      (response: any) => {
        if (response.length > 0) {
          response.map((user) => {
            if (user.active) {
              this.contacts.push(new UserContact(user));
            }
          });
          // update the multiselect
          this.setManager();
        }
      },
      (error) => {
        console.error(error);
        this.toastr.error('Impossible de charger les utilisateurs!');
      }
    );
  }

  /**
   * Retreive all destinataire of the current mail
   */
  getPointDestinataires() {
    if (this.currentPoint) {
      this.api.getDestinatairesFull(this.currentPoint.id).subscribe(
        (response) => {
          this.mailDestinataire = response;
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      this.api.getDestinatairesFullMeeting(this.currentMeeting.id).subscribe(
        (response) => {
          this.mailDestinataire = response;
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  public setCurrentCourrier(event) {
    if (this.currentPoint) {
      this.api.getPointByID(event.value).subscribe((response) => {
        this.currentPoint = new Point(response);
      });
    } else {
      this.api.getMeetingsByID(event.value).subscribe((response) => {
        this.currentMeeting = new Meeting(response);
      });
    }
  }

  /**
   * Display a warning when the selected date is in the past
   * @param event Selected date in the p-calendar
   */
  checkSelectedDate(event) {
    event.getTime() < this.today.getTime()
      ? this.toastr.warning('Vous avez sélectionné une date antérieur à celle du jour.', 'Attention !')
      : '';
  }

  /**
   * Method called when you create an action
   */
  public submit() {
    this.spinner.show();
    this.model = this.actionForm.value;

    if (
      this.actionForm.value.ActionDestinataire &&
      this.actionForm.value.ActionDestinataire.length > 0 &&
      this.actionForm.value.Type &&
      this.actionForm.value.Type !== null
    ) {
      const destInput = new DestinataireInput();
      destInput.contacts = [];

      destInput.statut = this.actionForm.value.Statut;
      destInput.action = this.actionForm.value.Type;
      destInput.transmisDate = moment(this.today).format();
      destInput.echeance = this.actionForm.value.EndDate;
      destInput.message = this.actionForm.value.Description;
      destInput.accuseDeReponse = this.actionForm.value.AccuseReponse ? this.actionForm.value.AccuseReponse : false;
      const tempContact = [];
      const arrayIDToCheck = [];
      let arrayIDToReplace = [];
      this.actionForm.value.ActionDestinataire.map((x) => {
        tempContact.push({
          contactIdiAdmin: x.contactId,
          contactUserId: x.value ? x.value : null,
          historiqueDescription: x.label,
        });
        arrayIDToCheck.push(x.contactId);
        arrayIDToReplace.push(x.id);
      });

      destInput.contacts = tempContact;
      async.waterfall([
        (cb) => {
          if (destInput.accuseDeReponse) {
            if (arrayIDToCheck && arrayIDToCheck.length > 0) {
              this.iContactService.getRemplacant(arrayIDToCheck).subscribe((res: any) => {
                const hasAbsentPeopleArray = [];
                if (res && res.length > 0) {
                  res.map((remplacant) => {
                    destInput.contacts.map((dest) => {
                      if (remplacant.absent === true && dest.contactId === remplacant.contactId) {
                        hasAbsentPeopleArray.push(remplacant);
                      }
                    });
                  });
                }
                if (hasAbsentPeopleArray.length > 0) {
                  this.confirmationService.confirm({
                    message: 'Souhaitez-vous remplacer automatiquement les personnes qui sont en congés ?',
                    key: 'actionCreateModalConfirm',
                    accept: () => {
                      const temp = [];
                      res.map((item) => {
                        if (item.absent) {
                          destInput.contacts.map((dest: any) => {
                            if (dest.contactId === item.contactId) {
                              this.iContactService
                                .get('/contacts/' + item.remplaceParContactId)
                                .subscribe((res: any) => {
                                  if (res) {
                                    temp.push(dest, {
                                      contactId: item.remplaceParContactId,
                                      contactUserId: null,
                                      historiqueDescription: res.prenom + ' ' + res.nom,
                                    });
                                  }
                                });
                            } else {
                              temp.push(dest);
                            }
                          });
                        }
                      });
                      setTimeout(() => {
                        destInput.contacts = temp;
                        if (temp.length > 0) {
                          cb(null);
                        }
                      }, 2000);
                    },
                    reject: () => {
                      cb(null);
                    },
                  });
                } else {
                  cb(null);
                }
              });
            }
          } else {
            cb(null);
          }
        },
        (cb) => {
          if (this.updateDestinataireBool === false) {
            this.executeAddDestinataire(destInput, true);
          } else {
            arrayIDToReplace = [];
            if (this.destinataires && this.destinataires.length > 0 && destInput.contacts.length > 0) {
              this.destinataires.map((item) => {
                destInput.contacts.map((c) => {
                  if (item.contactId === c.contactId) {
                    arrayIDToReplace.push(item.id);
                  }
                });
              });
            } else {
              arrayIDToReplace = [];
            }
            if (arrayIDToReplace.length > 0) {
              destInput.idsToReplace = _.uniq(arrayIDToReplace);
              this.updateDestinataire(destInput, true);
            }
          }
          /**
           * Check if selected user is in the destinataire of the current mail
           * If not : add it to the list
           * call the method to execute the req
           */
          const tempNewDest = [];
          this.mailDestinataire.map((mailDestinataire) => {
            this.actionForm.value.ActionDestinataire.map((actionDestinataire) => {
              mailDestinataire.contactId !== actionDestinataire.contactId ? tempNewDest.push(mailDestinataire) : '';
            });
          });

          if (tempNewDest.length > 0) {
            const newMaliDest = new DestinataireInput();
            newMaliDest.contacts = [];
            tempNewDest.map((dest) => {
              newMaliDest.contacts.push({
                contactId: dest.contactId,
                historiqueDescription: dest.label,
              });
            });
          }
        },
      ]);
    } else {
      this.toastr.error('Veuillez remplir les champs obligatoires!');
      this.spinner.hide();
    }
  }
  show(ev) {}

  /**
   * Method execute a api call and close the modal
   * @param dest mailDestinataireInput : data of new destinataire or action
   * @param isAction : boolean , change the final message if it's an action
   */
  public executeAddDestinataire(dest: DestinataireInput, isAction) {
    if (this.currentPoint) {
      if (dest && dest !== null) {
        this.api.addDestinatairesInPoint(this.currentPoint.id, dest).subscribe(
          (response) => {
            isAction
              ? this.toastr.success("Création de l'action réussie!")
              : this.toastr.success('Ajout du nouveau destinataire réussi!');
            this.spinner.hide();
            this.openingWindow.close(dest);
          },
          (error) => {
            console.error(error);
            isAction ? this.toastr.error("Une erreur s'est produite lors de la création de l'action!") : '';
          }
        );
      }
    } else {
      if (dest && dest !== null) {
        this.api.addDestinatairesInMeeting(this.currentMeeting.id, dest).subscribe(
          (response) => {
            isAction
              ? this.toastr.success("Création de l'action réussie!")
              : this.toastr.success('Ajout du nouveau destinataire réussi!');
            this.spinner.hide();
            this.openingWindow.close(dest);
          },
          (error) => {
            console.error(error);
            isAction ? this.toastr.error("Une erreur s'est produite lors de la création de l'action!") : '';
          }
        );
      }
    }
  }

  public updateDestinataire(dest: DestinataireInput, isAction) {
    if (this.currentPoint) {
      if (dest && dest !== null) {
        this.api.addOrReplaceDestinatairesInPoint(this.currentPoint.id, dest).subscribe(
          (response) => {
            isAction
              ? this.toastr.success("Création de l'action réussie!")
              : this.toastr.success('Ajout du nouveau destinataire réussi!');
            this.spinner.hide();
            this.openingWindow.close(dest);
          },
          (error) => {
            console.error(error);
            isAction ? this.toastr.error("Une erreur s'est produite lors de la création de l'action!") : '';
          }
        );
      }
    } else {
      if (dest && dest !== null) {
        this.api.addOrReplaceDestinatairesInMeeting(this.currentMeeting.id, dest).subscribe(
          (response) => {
            isAction
              ? this.toastr.success("Création de l'action réussie!")
              : this.toastr.success('Ajout du nouveau destinataire réussi!');
            this.spinner.hide();
            this.openingWindow.close(dest);
          },
          (error) => {
            console.error(error);
            isAction ? this.toastr.error("Une erreur s'est produite lors de la création de l'action!") : '';
          }
        );
      }
    }
  }

  /**
   * Get username of the connected user
   */
  public setSender() {
    this.adminApi.getUserInfo().subscribe((res) => {
      this.userInfos = res;
      this.actionForm.patchValue({
        Responsibles: res.userName,
      });
    });
  }

  /**
   * Set the value of the status dropDown
   */
  setStatus() {
    this.api.getAllActionsStatutes().subscribe(
      (response) => {
        if (response.length > 0) {
          response.map((res) => {
            if (res.notUsed === false) {
              this.status.push({
                label: res.description,
                value: res.description,
                id: res.id,
                ordreAffichage: res.ordreAffichage,
              });
            }
          });
        } else {
          this.toastr.error("Vous n'avez pas de statut définis pour les actions!");
        }
      },
      (error) => {
        console.error(error);
        this.toastr.error('Erreur lors du chargement des status!');
      }
    );
  }

  setTypes() {
    this.api.getAllActionsTypes().subscribe(
      (response) => {
        response.map((type) => {
          this.types.push({
            id: type.id,
            label: type.description,
            value: type.description,
          });
        });
      },
      (error) => {
        console.error(error);
        this.toastr.error('Erreur lors du chargement des types!');
      }
    );
  }

  /**
   * Manager == contact who will execute the action | dropdown of all contacts
   */
  setManager(alreadySelected?: Array<any>) {
    this.contacts.map((c) => {
      this.actionDestinataire.push({
        label: c.name,
        value: c.userId,
        contactId: c.contactForUser.contactId,
      });
    });
    if (alreadySelected && alreadySelected.length > 0) {
      alreadySelected.map((c) => {
        this.actionDestinataire.push({
          label: c.label,
          value: c.value,
          contactId: c.contactId,
        });
      });
      this.actionDestinataire = _.uniq(this.actionDestinataire, 'value');
    }

    if (this.selectedDest.length > 0) {
      this.selectedDest.map((dest) => {
        this.actionDestinataire.push({
          label: dest.name,
          value: dest.id,
        });
      });
    }
    this.actionDestinataire = _.uniq(this.actionDestinataire, 'value');
    this.actionDestinataire = _.sortBy(this.actionDestinataire, 'label');
  }

  /**
   * Basic close modal
   */
  closeActionModal() {
    this.openingWindow.close();
  }

  filterContact(event) {
    if (event.value) {
      this.adminApi.getUsersByServiceId(event.value).subscribe((res: any) => {
        let alreadySelected = [];
        alreadySelected = this.actionForm.value.ActionDestinataire;
        this.getContacts(res, alreadySelected);
      });
    } else {
      this.getActionDestinataires();
    }
  }

  getContacts(usersids, alreadySelected?: Array<any>) {
    this.contacts = [];
    let data = null;
    if (usersids && usersids.length > 0) {
      data = {
        UserIds: usersids.toString(),
      };
    } else {
      data = null;
    }
    this.iContactService.getUserWithDataAdmin(data).subscribe(
      (response: any) => {
        this.actionDestinataire = [];
        if (response.length > 0) {
          response.map((user) => {
            this.contacts.push(new UserContact(user));
          });
          this.setManager(alreadySelected);
        }
      },
      (error) => {
        console.error(error);
        this.toastr.error('Impossible de charger les utilisateurs!');
      }
    );
  }
}
