import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Point } from 'src/app/shared/models/point';
import { EventAddComponent } from '../../modals/event-add/event-add.component';
import { DetailsService } from 'src/app/modules/private/pages/meetings/services/details.service';

@Component({
  selector: 'element-from-list',
  templateUrl: './element.component.html',
  styleUrls: ['./element.component.scss'],
})
export class ElementComponent implements OnInit {
  public displayAlert: boolean = false;
  @Input() set element(value: Point) {
    this.item = value;
    const participants = value.pointVotes?.nbrParticipants - value.pointVotes?.nbrParticipantsAbsents;
    this.participantsPresentsCount = participants + 'votant' + (participants > 1 ? 's' : '');
    const allVotesCount =
      (value.pointVotes?.nbrVotesGlobalAbstention ? value.pointVotes?.nbrVotesGlobalAbstention : 0) +
      (value.pointVotes?.nbrVotesGlobalNegatif ? value.pointVotes?.nbrVotesGlobalNegatif : 0) +
      (value.pointVotes?.nbrVotesGlobalPositif ? value.pointVotes?.nbrVotesGlobalPositif : 0);
    if (participants !== allVotesCount) {
      this.displayAlert = true;
    } else {
      this.displayAlert = false;
    }
  }
  @Input() set activeElement(value) {
    this.currentPointID = value;
  }

  public participantsPresentsCount;

  @Output() emittedPointValue: EventEmitter<any> = new EventEmitter();
  @Output() reloadList: EventEmitter<any> = new EventEmitter();
  public item: Point;
  public currentSeanceID: number;
  public currentPointID: number;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private translate: TranslateService,
    public detailsService: DetailsService,
    public dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res: any) => {
      this.currentSeanceID = +res.id;
    });
  }

  goTo(point: Point) {
    if (point.id && point.type !== 'event') {
      this.emittedPointValue.emit({ pointId: point.id });
    } else if (point.isIntroduction || point.isConclusion || point.isTitle) {
      const type = point.isIntroduction ? 'Introduction' : point.isConclusion ? 'Conclusion' : 'Titre';
      this.emittedPointValue.emit({ other: type });
    } else {
      const ref = this.dialog.open(EventAddComponent, {
        data: {
          meetingId: point.seanceId,
          header: point.title,
          points: null,
          mode: 'update',
          pointClicked: point,
          event: point ? point : null,
        },
        width: '80vw',
        styleClass: 'super-compos-modal-content ',
        showHeader: false,
        closable: false,
        dismissableMask: false,
        baseZIndex: 1000,
      });
      ref.onClose.subscribe((res: any) => {
        this.reloadList.emit(true);
      });
    }
    this.currentPointID = point.id;
  }

  getTooltip(type, data) {
    const typeTranslated = this.translate.instant([type]);
    return typeTranslated[type] + ': ' + data;
  }
}
