import { formatNumber } from '@angular/common';
import { Handler } from './handler';
import { PointVotes } from './pointVotes';
import { SeanceLink } from './seanceLink';

export class Point {
  title: string;
  category: string;
  public: boolean = true;
  publicText: string = '';
  order: number = 0;
  id: number;
  stringId: string;
  public status: string;
  service: string;
  serviceId: number;
  seanceId: number;
  statutId: number;
  borderClass?: string;
  rowClass?: string;
  isIntroduction?: boolean = false;
  isConclusion?: boolean = false;
  isTitle?: boolean = false;
  meetingId?: number;
  folder?: string;
  sleeve?: string;
  public seanceLink: SeanceLink;

  typePoint?: string;
  //modèle pour l'envoi d'une requête API
  typeSeanceId?: number;
  matiereId?: number;
  datePassageSouhaite?: Date;
  typeId?: number;
  origine?: string;
  confidentiel?: boolean;
  confidentielLabel: string;
  courrierId?: number;
  courrierIdLabel?: any;
  courriersInitiateursListe?: string;
  agentTraitantId?: number;
  typeDecisionId?: number;
  checkRgpd?: boolean;
  objetSynthese?: string;
  classementId?: number;
  dossierClassementId?: number;
  projetId?: number;
  echevinatId?: number;
  urgenceNiveauId?: number;
  serviceOrdrePassage?: number;
  accordFinancier?: boolean;

  pointId?: number; //utilisé pour la renumérotation des points ds la séance
  type: string;
  agentTraitant?: Handler;
  agentTraitantNom?: string;
  agentTraitantIdiAdmin?: string;
  backcolor?: string;
  checkRGPD?: boolean;
  classementCode?: string;
  classementCodeId?: number;
  cloture?: boolean;
  courrierObjet?: any;
  courrierReference?: any;
  creePar?: string;
  creeParContactId?: number;
  dossierClassement2Description?: string;
  dossierClassement2Id?: number;
  dossierClassementComplet?: any;
  dossierClassementDescription?: string;
  dtSeanceDate?: Date;
  echeance?: any;
  echevinat?: string;
  enCreation?: boolean;
  ficheDelibeId?: number;
  matiere?: string;
  modificationAFaireParContactId?: number;
  modificationAutorisee?: boolean;
  ordre?: any;
  typeDecision?: any;
  typeSeance?: string;
  urgenceNiveau: any;
  validePourSeance?: boolean;
  verrouille?: boolean;
  point: any;
  pointVotes: PointVotes;
  ordreReel: number;
  texte: string;
  evenements: Array<any> = [];
  modifieParContactId: number;
  public index;
  public createurID: number;
  public contenuStaticRTFFilesPresents?: any;
  classementLabel?: string;
  dossierClassementLabel?: string;
  typeDecisionLabel?: string;
  typeSeanceLabel?: string;
  urgenceNiveauLabel?: string;
  agentTraitantLabel?: string;
  creeParContactLabel?: string;
  echevinatLabel?: string;
  FichiersIds?: number[]; //only used for duplication of point
  RTFContent?: boolean; //only used for duplication of point
  public observationSummary: string = '';
  public data: any;
  public enCorrection: boolean;
  public clotureIcon: string;
  public serviceLabel: string;
  public statusLabel: string;
  public verrouilleLabel: string;
  pointVotesHTML: any;
  pointVotesHTMLTooltip?: any;
  public triService: number;
  public ordreDocs: string;
  public ordreReelHTML: string;
  public trueOrder: any;
  public fichierIds: Array<number> = [];
  public pointFichiers?: any;
  public writeLock: {
    locked: boolean;
    writeLockDateTime: Date;
    writeLockExpirationDateTime: Date;
    writeLockUserId: number;
  };
  public statusIcons: string;
  constructor(
    data: any,
    globalElements?,
    pointVotes?: any,
    listOfContactsAdmin?: any,
    listOfContacts?: any,
    observation?: string
  ) {
    Object.assign(this, data.point ? data.point : data);
    this.pointVotes = data.pointVotes;

    this.createurID = this.creeParContactId;
    //On commence par set le titre et le texte en vide, afin d'avoir toujours quelque chose d'affiché
    if (this.type !== 'event' && this.title === null) {
      this.title = 'Point sans titre';
    } else if (this.type === 'event' && (this.title === null || this.title === '' || !this.title)) {
      this.title = 'Évènement sans titre';
    }

    //pas de else car on veut absolument set le this.texte
    if (this.type === 'event' && (this.texte === null || this.texte === '' || !this.texte)) {
      //on set aussi le texte afin d'avoir le texte disponible lorsque l'on va afficher le titre de la modale d'affichage d'event
      this.texte = 'Évènement sans titre';
    }

    //ici on va convertir l'affichage de l'ordre réel
    //on peut avoir 001 s'il y a un ordre
    //on peut avoir du vide s'il s'agit de l'intro, la conclusion ou le titre
    //on peut avoir le drapeau s'il s'agit d'un event
    let ordreReelConverted = '';
    if (this.type !== 'event') {
      if (!this.isIntroduction && !this.isConclusion && !this.isTitle) {
        // va convertir l'ordre réel sous la forme 001
        ordreReelConverted = formatNumber(data.ordreReel, 'en-US', '3.0-0');
      }
    } else {
      ordreReelConverted = '🏴';
    }

    this.ordreReel = data.ordreReel ? data.ordreReel : this.ordreReel;
    this.trueOrder = formatNumber(data.ordreReel, 'en-US', '3.0-0');
    this.ordreDocs = data.ordreDocument;
    this.triService = data.triService;
    this.evenements = data.evenements;
    if (this.ordreReel) {
      this.ordreReelHTML =
        '<div align="center"><b style="font-size: 18px !important;">' +
        this.ordreReel?.toString().padStart(3, '0') +
        '</b>' +
        '<br>' +
        this.ordreDocs +
        '</div>';
    }

    //Set des titre en fonction des types
    //1. Si c'est un point on a un objetSynthese

    this.title = this.objetSynthese;
    //2. Si c'est un event
    if (this.type === 'event') {
      //this.texte sera soit 'Évènement sans titre' s'il n'y a pas de texte, soit le texte renvoyé du backend quand on récupère les points avec leurs events
      this.title = this.texte;
    }

    if (this.confidentiel === true) {
      this.confidentielLabel = '<i class="icon-lock mr-2"></i>';
    } else {
      this.confidentielLabel = '<i class="icon-unlock mr-2"></i>';
    }

    if (this.point) {
      Object.assign(this, this.point);
    }
    this.seanceLink = new SeanceLink(this.seanceLink);
    if (this.ordreReel) {
      this.ordre = this.ordreReel;
    }
    if (pointVotes) {
      this.pointVotes = pointVotes;
      let displayAlert: boolean = false;
      const isAVoteType = this?.pointVotes?.voteType !== 'PasDeVote' ? true : false;
      const participants =
        this?.pointVotes?.nbrParticipants -
        this?.pointVotes?.nbrParticipantsAbsents -
        this?.pointVotes?.nbrParticipantsNeVotantPas;
      const participantsPresentsCount = participants + ' votant' + (participants > 1 ? 's' : '');
      this.pointVotes.participantsPresentsCount = participantsPresentsCount;
      const allVotesCount =
        (this?.pointVotes?.nbrVotesGlobalAbstention ? this?.pointVotes?.nbrVotesGlobalAbstention : 0) +
        (this?.pointVotes?.nbrVotesGlobalNegatif ? this?.pointVotes?.nbrVotesGlobalNegatif : 0) +
        (this?.pointVotes?.nbrVotesGlobalPositif ? this?.pointVotes?.nbrVotesGlobalPositif : 0);
      if (isAVoteType && participants !== allVotesCount) {
        this.pointVotes.displayAlertForExport = true;
        displayAlert = true;
      } else {
        displayAlert = false;
        this.pointVotes.displayAlertForExport = false;
      }

      let pointVotesHTMLTooltip = '';
      if (this.pointVotes.displayAlertForExport) {
        pointVotesHTMLTooltip =
          '⚠️ Le nombre de votes encodés ne correspond pas au nombre de votants ! (' +
          this.pointVotes.participantsPresentsCount +
          ')' +
          `
               `;
      }
      pointVotesHTMLTooltip =
        pointVotesHTMLTooltip +
        'Vote(s) positif(s) : ' +
        (this?.pointVotes?.nbrVotesGlobalPositif ? this?.pointVotes?.nbrVotesGlobalPositif : 0) +
        `
            Vote(s) abstention(s) : ` +
        (this?.pointVotes?.nbrVotesGlobalAbstention ? this?.pointVotes?.nbrVotesGlobalAbstention : 0) +
        `
            Vote(s) négatif(s) : ` +
        (this?.pointVotes?.nbrVotesGlobalNegatif ? this?.pointVotes?.nbrVotesGlobalNegatif : 0);
      this.pointVotesHTMLTooltip = pointVotesHTMLTooltip;

      const retour1 = `
             <div class="element-votecontent">
               <div class="element-votes-table element-votes-table__positif mr-2" title="Vote positif">${
                 this?.pointVotes?.nbrVotesGlobalPositif ? this?.pointVotes?.nbrVotesGlobalPositif : 0
               }</div>
               <div class="element-votes-table element-votes-table__abstention mr-2" title="Vote abstention">${
                 this?.pointVotes?.nbrVotesGlobalAbstention ? this?.pointVotes?.nbrVotesGlobalAbstention : 0
               }</div>
               <div class="element-votes-table element-votes-table__negatif mr-2" title="Vote négatif">${
                 this?.pointVotes?.nbrVotesGlobalNegatif ? this?.pointVotes?.nbrVotesGlobalNegatif : 0
               }</div>
               `;
      const retour2 = `<div class="element-votes-table mr-2" title="⚠️ Le nombre de votes encodés ne correspond pas au nombre de votants !"><i class="fas fa-exclamation-triangle orange-icon"></i></div>`;
      const retour3 = `</div>`;
      this.pointVotesHTML = retour1 + (displayAlert ? retour2 : '') + retour3;
    }
    this.courrierId ? (this.courrierIdLabel = this.courrierId) : (this.courrierIdLabel = 'Pas de courrier lié');

    if (globalElements) {
      const matiere = globalElements?.matieres_LV.find((x) => {
        return x.value == this.matiereId;
      });
      if (matiere) {
        this.matiere = matiere.label;
      } else {
        this.matiere = '';
      }

      const statut = globalElements?.statuts_points_LV.find((x) => {
        return x.value == this.statutId;
      });
      if (statut) {
        this.statusLabel = statut.label;
      } else {
        this.statusLabel = '';
      }

      const service = globalElements?.services_LV.find((x) => {
        return x.value == this.serviceId;
      });
      if (service) {
        this.service = service.label;
      } else {
        this.service = '';
      }

      const typePoint = globalElements?.types_points_LV.find((x) => {
        return x.value == this.typeId;
      });
      if (typePoint) {
        this.typePoint = typePoint.label;
      } else {
        this.typePoint = '';
      }

      const classement = globalElements?.classement_code_LV.find((x) => {
        return x.value == this.classementId;
      });
      if (classement) {
        this.classementLabel = classement.label;
      } else {
        this.classementLabel = '';
      }

      const dossierClassement = globalElements?.classeurs_LV.find((x) => {
        return x.value == this.dossierClassementId;
      });
      if (dossierClassement) {
        this.dossierClassementLabel = dossierClassement.label;
      } else {
        this.dossierClassementLabel = '';
      }

      const typeDecision = globalElements?.decision_type_LV.find((x) => {
        return x.value == this.typeDecisionId;
      });
      if (typeDecision) {
        this.typeDecisionLabel = typeDecision.label;
      } else {
        this.typeDecisionLabel = '';
      }

      const typeSeance = globalElements?.types_seances_LV.find((x) => {
        return x.value == this.typeSeanceId;
      });
      if (typeSeance) {
        this.typeSeanceLabel = typeSeance.label;
      } else {
        this.typeSeanceLabel = '';
      }

      const urgenceNiveau = globalElements?.urgence_level_LV.find((x) => {
        return x.value == this.urgenceNiveauId;
      });
      if (urgenceNiveau) {
        this.urgenceNiveauLabel = urgenceNiveau.label;
      } else {
        this.urgenceNiveauLabel = '';
      }
      const echevinat = globalElements?.echevinats_LV.find((x) => {
        return x.value == this.echevinatId;
      });
      if (echevinat) {
        this.echevinatLabel = echevinat.label;
      } else {
        this.echevinatLabel = '';
      }
    }
    if (listOfContactsAdmin) {
      if (listOfContacts) {
        //on a besoin des deux pour les agents traitants
        let agentTraitantLabel;
        if (this.agentTraitantIdiAdmin) {
          agentTraitantLabel = listOfContactsAdmin.find((x) => x.id === this.agentTraitantIdiAdmin);
        } else if (this.agentTraitant || this.agentTraitantId) {
          agentTraitantLabel = listOfContacts.find((x) => x.id === this.agentTraitant || x.id === this.agentTraitantId);
        }
        if (agentTraitantLabel) {
          this.agentTraitantLabel = agentTraitantLabel.fullName;
        } else {
          this.agentTraitantLabel = '';
        }
      }
      //pas pour la création par qqun
      const creeParContactId = listOfContactsAdmin.find((x) => x.id === this.creeParContactId);
      if (creeParContactId) {
        this.creeParContactLabel = creeParContactId.fullName;
      } else {
        this.creeParContactLabel = '';
      }
    }
    if (observation) {
      this.observationSummary = observation;
    }

    this.modificationAutorisee && this?.modificationAFaireParContactId
      ? (this.enCorrection = true)
      : (this.enCorrection = false);
    this.clotureIcon = this.showBoolean(this.cloture);
    if (this.serviceId && globalElements?.services_LV) {
      const service = globalElements.services_LV.find((x) => x.value === this.serviceId);
      if (service) {
        this.serviceLabel = service?.label;
      } else {
        this.serviceLabel = '';
      }
    }
    if (this.classementId && globalElements?.classement_code_LV) {
      const element = globalElements.classement_code_LV.find((x) => x.value === this.classementId);
      if (element) {
        this.classementLabel = element?.label;
      } else {
        this.classementLabel = '';
      }
    }
    this.verrouilleLabel = this.showBoolean(this.verrouille);
    let icons = '';
    const codeIcon = '<i class="bi bi-lock-fill tac-status-icon mr-2"></i>';
    icons = icons + (this.verrouille && !this.cloture ? codeIcon : '');
    this.enCorrection ? (icons = icons + '<i class="bi bi-pen tac-status-icon mr-2"></i>') : (icons = icons);
    this.cloture
      ? (icons = icons + '<i class="bi bi bi-check-circle-fill tac-status-icon mr-2"></i>')
      : (icons = icons);
    this.confidentiel ? (icons = icons + '<i class="bi bi-eye-slash-fill tac-status-icon"></i>') : (icons = icons);
    this.writeLock?.locked ? (icons += '<img src="assets/favicon/editIcon.svg" class="ml-50" alt="Edit Icon">'): null;
    this.statusIcons = icons;

    if (this.verrouille) this.borderClass = 'orange';
    if (this.cloture) this.borderClass = 'red';
    if (this.verrouille) this.rowClass = 'border-vote--orange';
    if (this.cloture) this.rowClass = 'border-vote--red';
  }

  clearDuplicationData() {
    delete this.RTFContent;
  }

  private showBoolean(value) {
    if (value) {
      return `<b style="color: #07E299;"><i class="bi bi-check-circle-fill"></i></b>`;
    } else if (value === -1) {
      return `<b style="color: #FFAF2F;"><i class="bi bi-hourglass-split"></i></b>`;
    } else {
      return `<b style="color: #FF5671;"></b>`;
    }
  }
}
