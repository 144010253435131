import { Component, HostListener, Input, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { QrCodeExplode } from 'src/app/shared/pipes/qrCode.pipe';
import { ScanService } from 'src/app/core/services/scan.service';
import { InfoByQrCode } from 'src/app/shared/models/scan';
import { DataService } from 'src/app/core/services/data.service';
import { ApiService } from 'src/app/core/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { IScanService } from '@devinforius/super-compos';
import { CardDescription, Pages } from '@devinforius/super-compos/lib/models/scan';

@Component({
  selector: 'lib-detail-scan',
  templateUrl: './detail-scan.component.html',
  styleUrls: ['./detail-scan.component.css'],
})
export class DetailScanComponent implements OnInit {
  @Input() infoFile: any;
  public guid: string;
  public buttons = [
    {
      text: 'Confirmer',
      styleClass: 'blue',
      click: () => {
        this.confirmAndSave();
      },
    },
    {
      text: 'Annuler',
      styleClass: 'red',
      click: () => {
        this.back();
      },
    },
  ];
  public formatedQrCode: any[];
  private idQrCode = { courrierIds: [] };
  public cardDescription: InfoByQrCode[];
  public language: string;
  public loader: boolean = true;

  public spinnerCountSave: number = 0;
  public spinnerCountTotal: number = 0;
  private nameInputFile;
  private arrayOfNames: string[] = [];
  private modifyCardIndex: number;
  loadingDownload: { [key: number]: boolean } = {};
  quickButton = [{
    id: 1,
    tooltip: 'Modifier ce scan',
    icon: 'bi bi-pencil-square'
  }, {
    id: 2,
    tooltip: 'Supprimer le lien de ce scan',
    icon: 'bi bi-input-cursor'
  }, {
    id: 3,
    tooltip: 'Afficher ce fichier',
    icon: 'bi bi-mouse'
  }, {
    id: 4,
    tooltip: 'Télécharger ce fichier',
    icon: 'bi bi-download'
  }]
  modalHeader = {
    title: 'Modifier ce scan',
    iconTitle: 'bi bi-pen-fill',
    showNotification: false,
    showParameters: false,
    closable: true,
  };
  modalParams = { 
    isDraggable: true,
    isResizable: true,
    isModal: true,
    style:{ height: '75vh', width: '50vw', minWidth: '50vw', maxWidth: '50vw' }
  };
  category = [{
    name: 'Point',
  },{
    name : 'Séance'
  }]
  categorySelected = this.category[0];
  openEditDescription: boolean = false;
  infosCard: CardDescription;
  public dropdownValue: string = 'Titre';
  public dropdownLabel: string = 'Titre';
  public showOverlayDropdown: boolean = false;
  public onFocusInput = false;
  public pointSearch = '';

  constructor(
    public api: IScanService,
    public router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private scanService: ScanService,
    private dataService: DataService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService
  ) {
    dataService.checkIfUnsavedChanges = true;
    if (this.router.getCurrentNavigation()?.extras.state) {
      const file = this.router.getCurrentNavigation()?.extras.state;
      this.infoFile = {
        name: file.name,
        lastModified: file.lastModified,
        size: file.size,
        type: file.type,
      };
      localStorage.setItem('infoFile', JSON.stringify(this.infoFile));
    } else {
      this.infoFile = JSON.parse(localStorage.getItem('infoFile') || '');
    }
  }

  ngOnInit(): void {
    this.getGuid();
    this.getLanguage();
  }

  setArrayOfNames(): void {
    this.cardDescription.forEach((element, index) => {
      const transformed = this.infoFile.name.slice(0, this.infoFile.name.lastIndexOf('.pdf')).replace(/\s/g, '_');
      this.arrayOfNames.push(transformed);
    });
  }

  @HostListener('window:storage', ['$event'])
  onStorageChange(event: StorageEvent): void {
    if (event.key === 'lang') {
      this.getLanguage();
    }
  }

  getLanguage(): void {
    this.language = localStorage.getItem('lang');
  }

  getGuid(): void {
    this.spinner.show();
    this.loader = true;
    this.guid = this.route.snapshot.params['id'];
    this.api.getAllFilesDataFromGuid(this.guid).then((res) => {
      this.setQrCode(res.pages);
      this.spinner.hide();
      setTimeout(() => {
        this.loader = false;
      }, 200);
    });
  }

  back(): void {
    this.router.navigate(['private/iscan']);
  }

  setQrCode(data): void {
    const qrCodeExplode = new QrCodeExplode();
    const objectQrCode = [];
    data.map((item) => {
      if (item.qrcode) {
        const qrCodeTransformed = qrCodeExplode.transform(item.qrcode);
        if (qrCodeTransformed) {
          item.qrCodeInfoTransformed = qrCodeExplode.transform(item.qrcode);
          objectQrCode.push({ old: item.qrcode, new: item.qrCodeInfoTransformed});
          this.idQrCode.courrierIds.push(qrCodeTransformed.id);
        }
      }
    });
    if (this.idQrCode.courrierIds.length > 0) {
      this.setInfoByIdQrCode(data);
    }
    this.formatedQrCode = objectQrCode;
  }

  setInfoByIdQrCode(data): void {
    this.scanService.getInfoCourrierByIdQrCode(this.idQrCode).subscribe((res: InfoByQrCode[]) => {
      let file = null;
      this.cardDescription = [];
      for (const iterator of data) {
        if(iterator.file != file){
          file = iterator.file;
          if(iterator.qrCodeInfoTransformed && iterator.qrCodeInfoTransformed?.id > 0) {
            const found = res.find((item) => item.courrierId === iterator.qrCodeInfoTransformed.id);
            if(found) {
              this.cardDescription.push(found);
            } else {
              this.cardDescription.push({
                courrierId: iterator.qrCodeInfoTransformed.id,
                description: this.translate.instant('noPointLinkedToQrCode') + ' (' + iterator.qrCodeInfoTransformed.id + ')',
                id: null,
                type: 'Error',
              });
            }
          } else {
            this.cardDescription.push({
              courrierId: null,
              description: this.translate.instant('QRCodeAvailable'),
              id: null,
              type: 'Error',
            });
          }

        }
      }
      this.setArrayOfNames();
    });
  }

  confirmAndSave(): void {
    if (this.cardDescription.some((element) => element.type === 'Error')) {
      this.confirmationService.confirm({
        message:
          this.translate.instant('warning.scanError') + '</br>' + this.translate.instant('warning.DoYouWantContinue'),
        accept: () => {
          this.saveFile();
        },
      });
    } else if(this.cardDescription.some((element) => element.type === null)) {
      this.confirmationService.confirm({
        message:
          this.translate.instant('warning.scanNotLink') + '</br>' + this.translate.instant('warning.DoYouWantContinue'),
        accept: () => {
          this.saveFile();
        },
      });
    }
      else {
      this.saveFile();
    }
  }

  saveFile(): void {
    if (this.cardDescription) {
      this.spinner.show(`spinnerTreatment`);
      this.dataService.checkIfUnsavedChanges = false;
      this.spinnerCountTotal = this.cardDescription.length;
      this.spinnerCountSave = 0;
      this.cardDescription.forEach((element, index) => {
        if (element.type === 'Point' || element.type === 'Séance') {
          const matchingPages: Pages[] = this.api.files[index].images.filter((page) => page.file === index);
          const matchingPagesIndexes = matchingPages.map((page) => page.index);
          const indexes: string = JSON.stringify(matchingPagesIndexes);
          this.api.getScanPDF(this.guid, indexes).subscribe((pdf) => {
            const document = {
              description: this.arrayOfNames[index],
              version: 1,
            };
            const file: any = new File([pdf], this.arrayOfNames[index] + `.pdf`, {
              type: 'application/json',
            });
            file.description = this.arrayOfNames[index] + `.pdf`;
            file.extension = 'pdf';
            file.date = this.infoFile.lastModifiedDate;
            file.pointId = element.id;
            const errorMessage = [];
            if (element.type === 'Point') {
              this.apiService.addPointFiles(element.id, document, file, 'pdf', false).subscribe(
                (res) => {
                  this.spinnerCountSave++;
                  if (this.spinnerCountSave === this.spinnerCountTotal) {
                    setTimeout(() => {
                      this.toastr.success('All file saved successfully');
                      this.spinner.hide('spinnerTreatment');
                      this.router.navigate(['private/iscan']);
                    }, 500);
                  }
                },
                (error) => {
                  errorMessage.push({ description: file.description, pointId: file.pointId });
                  this.spinner.hide('spinnerTreatment');
                  this.toastr.error(
                    `An error occurred while saving the file<br>Name: ${file.description}<br>Id point: ${file.pointId}`
                  );
                }
              );
            } else if (element.type === 'Séance') {
              this.apiService.addSeanceFiles(element.id, document, file, 'pdf', false).subscribe(
                (res) => {
                  this.spinnerCountSave++;
                  if (this.spinnerCountSave === this.spinnerCountTotal) {
                    setTimeout(() => {
                      this.toastr.success('All file saved successfully');
                      this.spinner.hide('spinnerTreatment');
                      this.router.navigate(['private/iscan']);
                    }, 500);
                  }
                },
                (error) => {
                  errorMessage.push({ description: file.description, pointId: file.pointId });
                  this.spinner.hide('spinnerTreatment');
                  this.toastr.error(
                    `An error occurred while saving the file<br>Name: ${file.description}<br>Id point: ${file.pointId}`
                  );
                }
              );
            }
          });
        } else {
          this.spinnerCountSave++;
          this.toastr.error(this.translate.instant('onePointInError'));
          if (this.spinnerCountSave === this.spinnerCountTotal) {
            setTimeout(() => {
              this.spinner.hide('spinnerTreatment');
              this.router.navigate(['private/iscan']);
            }, 500);
          }
        }
      });
      localStorage.removeItem('infoFile');
    } else {
      localStorage.removeItem('infoFile');
      this.toastr.error(this.translate.instant('noFileWithQRCodeAvailable'));
      this.spinner.hide('spinnerTreatment');
    }
  }

  inputFile(event): void {
    this.arrayOfNames[event.images[0].file] = event.description;
  }

  openPointWithIdCard(event: CardDescription): void {
    if(event.type === 'Point') {
      window.open('private/points/detail/' + event.id, '_blank');
    } else if(event.type === 'Séance') {
      window.open('private/meetings/details/' + event.id, '_blank');
    }
  }

  quickButtonClicked(event): void {
    this.modifyCardIndex = event.file.images[0].file;
    if(event.button.id === 1) {
      this.infosCard = {... event.cardDescription};
      this.openEditDescription = true;
    } else if(event.button.id === 2) {
      this.cardDescription[this.modifyCardIndex].description = null;
      this.cardDescription[this.modifyCardIndex].id = null;
      this.cardDescription[this.modifyCardIndex].type = null;
    } else if(event.button.id === 3) {
      this.scroll('file_' + this.modifyCardIndex);
    } else if(event.button.id === 4) {
      this.loadingDownloadFile(this.guid, event.file);
    }
  }

  onClickDropdownInput(type: string): void {
    this.dropdownLabel = type;
    this.dropdownValue = type;
    this.showOverlayDropdown = false;
  }

  newDescription(event): void {
    if(event) {
      if(event?.objetSynthese) {
        this.cardDescription[this.modifyCardIndex].description = event.objetSynthese;
        this.cardDescription[this.modifyCardIndex].id = event.id;
        this.cardDescription[this.modifyCardIndex].type = 'Point';
      } else {
        this.cardDescription[this.modifyCardIndex].description = event?.description;
        this.cardDescription[this.modifyCardIndex].id = event?.id;
        this.cardDescription[this.modifyCardIndex].type = 'Séance';
      }
    }
  }

  scroll(id: string, nocolor?: boolean): void {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const el1 = document.getElementById(id + '_images');
    if (el1 && !nocolor) {
      el1.style.transition = 'background-color 0.1s ease-in';
      el1.style.setProperty('background-color', '#DB952520', 'important');
      setTimeout(() => {
        el1.style.transition = 'background-color 0.8s ease-out';
        el1.style.backgroundColor = '';
      }, 700);
    }
  }

  loadingDownloadFile(guid: string, file): void {
    this.api.download(guid, file).subscribe(()=> {
      this.toastr.success('Téléchargement terminé');
    },(error) => {
      this.toastr.error('Erreur de téléchargement');
    });
  }
}
