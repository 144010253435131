import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataService } from 'src/app/core/services/data.service';
import { Global_Class } from '../../models/global';
import { Point } from '../../models/point';
import * as async from 'async';
import * as _ from 'underscore';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { ApiService } from 'src/app/core/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { Contact } from '../../models/iContact/contact';
import { TenantInfo } from '../../models/tenant-info';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SuperColumn, SuperTableComponent } from '@devinforius/super-compos';
import * as moment from 'moment';
@Component({
  selector: 'info-general',
  templateUrl: './info-general.component.html',
  styleUrls: ['./info-general.component.scss'],
})
export class InfoGeneralComponent implements OnInit {
  itemLoaded: boolean;
  @Input() set item(value) {
    this.object = value;
    if (this.object) {
      this.load();
    }
  }
  @Input() set right(value) {
    this.rightsOnPoint = value;
  }
  @Input() allowConfidentialCol = true;
  public rightsOnPoint: any;
  public globalConfig: any;
  public object: Point = null;
  public globalElements: Global_Class;
  public loaded: boolean = false;
  public isPointFullScreen = false;
  @Input() contactsAdmin: Array<Contact> = [];
  @Input() contacts: Array<Contact> = [];
  public globalConfiguration;
  public displayCourrierId = false;
  public itemsFiles: Array<any> = [];
  public openModalForFilesSelectionCourrier: boolean = false;
  public columnsFiles: Array<SuperColumn> = [
    {
      field: 'name',
      header: 'description',
      visible: true,
    },
    {
      field: 'extension',
      header: 'extension',
      visible: true,
    },
    // {
    //   field: 'apercu',
    //   header: 'Aperçu',
    //   format: (value, item) => {
    //     if (item.extension.toLowerCase() === 'pdf') {
    //       return '<i class="fas fa-eye"></i>';
    //     }
    //   },
    //   click: (value) => {},
    // },
    {
      field: 'fileType',
      header: this.translate.instant('fileType'),
      format: (value, item) => {
        if (item.modeleId && item.extension.toLowerCase() === 'pdf') {
          return this.translate.instant('document');
        } else if (item.annexeNumero) {
          return this.translate.instant('annexN°') + ' ' + item.annexeNumero;
        } else {
          return this.translate.instant('associatedFile');
        }
      },
    },
    {
      field: 'dateUpload',
      header: 'Date de dépot',
      format: (value) => {
        if (value) {
          return moment(value).format('DD/MM/YYYY');
        }
      },
    },
  ];
  @ViewChild('superTableFilesDuplication') public superTableFilesDuplication: SuperTableComponent;
  @Input() writeLock: any;
  @Output() writeLockUpdated = new EventEmitter<any>();

  constructor(
    public dataService: DataService,
    public apiAdmin: iAdminService,
    public api: ApiService,
    public spinner: NgxSpinnerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public iContactService: IContactService,
    public toastr: ToastrService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.routeConfig.path === 'points/detail/:pointID') {
      this.isPointFullScreen = true;
    } else {
      this.isPointFullScreen = false;
    }
  }

  goToSeance() {
    if (this.activatedRoute.snapshot.routeConfig.path === 'point/:pointID') {
      //On ne fait rien on est déjà dans la route d'affichage du point avec la séance
    }
    if (this.activatedRoute.snapshot.routeConfig.path === 'points/detail/:pointID') {
      //La on est dans la route d'affichage du point seul, en plein écran => on va router vers la séance
      const tabType = this.object.seanceLink?.inclusDansSeance ? 'pointIncluded' : 'pointPlanned';
      sessionStorage.setItem('iDelibeReloadMeetingOnRightTab', tabType);
      this.router.navigateByUrl('/private/meetings/details/' + this.object.seanceLink.id);
    }
  }

  public load() {
    this.loaded = false;
    this.spinner.show();
    async.waterfall(
      [
        (callback) => {
          this.apiAdmin.getTenant().subscribe((res: TenantInfo) => {
            res.applications?.map((x) => {
              if (x.name === 'iCourrier') {
                this.displayCourrierId = true;
              }
            });
            callback();
          });
        },
        (callback) => {
          this.api.getConfiguration().subscribe((res: any) => {
            this.globalConfiguration = res;
            callback();
          });
        },
        (callback) => {
          this.dataService.getGlobalElements.subscribe((res: any) => {
            this.globalElements = new Global_Class(res);
            if (this.globalElements.isLoaded()) {
              callback();
            }
          });
        },
        (callback) => {
          this.globalConfig = this.globalElements.configApp;
          callback();
        },
        (callback) => {
          if (this.contactsAdmin.length === 0) {
            this.iContactService.getLowContactsAdmin().subscribe((res: any) => {
              this.contactsAdmin = res;
              callback();
            });
          } else callback();
        },
        (callback) => {
          if (this.contacts.length === 0) {
            this.iContactService.getLowContacts().subscribe((res: any) => {
              this.contacts = res;
              callback();
            });
          } else callback();
        },
        (callback) => {
          this.object = new Point(this.object, this.globalElements, null, this.contactsAdmin, this.contacts);
          callback();
        },
        (callback) => {
          if (this.object.classementId) {
            this.object.classementCode = this.globalElements?.classement_code_LV.find((x) => {
              return x.value === this.object.classementId;
            })?.label;
            callback();
          } else {
            this.object.classementCode = null;
            this.object.dossierClassementDescription = null;
            this.object.dossierClassement2Description = null;
            callback();
          }
        },
        (callback) => {
          //gestion du classement via données du get : dossierClassementId
          if (this.object.dossierClassementId) {
            this.apiAdmin.getGlobalClassementById(this.object.dossierClassementId).subscribe((classement: any) => {
              this.object.dossierClassementDescription = classement?.classeur.description;
              this.object.dossierClassement2Description = classement?.chemise?.description;
              callback();
            });
          } else {
            callback();
          }
        },
      ],
      () => {
        this.spinner.hide();
        this.loaded = true;
      }
    );
  }

  importFileFromCourrierId() {
    this.api.getIcourrierFiles(this.object.courrierId).subscribe(
    (res: any) => {
      console.log('res ==>', res);
      this.toastr.success(this.translate.instant('success.file.imported'));
      this.itemsFiles = res;
      this.openModalForFilesSelectionCourrier = true;
    },
    (err) => {
      if (err?.error?.message && err?.error?.message === 'Mail not found') {
        this.toastr.error("Ce courrier n'a pas pu être retrouvé, veuillez vérifier son identifiant");
      } else if(err.status === 401) {
        this.toastr.error("Vous n'êtes pas autorisé à importer ce courrier");
      } else {
        this.toastr.error('Une erreur est survenue');
      }
    }
  )}
  
  patchPointFiles() {
    const FichiersIds = [];
    try {
      if (this.superTableFilesDuplication && this.superTableFilesDuplication._selection.length > 0) {
        this.superTableFilesDuplication._selection.map((item) => {
          FichiersIds.push({ mailId: this.object.courrierId, fileId: item.id });
        });
        this.api.importFromIcourrierFilesPoint(this.object.id, { files: FichiersIds }).subscribe((res: any) => {
          this.toastr.success(this.translate.instant('success.file.imported'));
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  updateWriteLock(writeLockUpdated: any) {
    this.writeLockUpdated.emit(writeLockUpdated);
  }
}
