export class UrgenceLevel {
     description:string;
     niveauUrgence:number;
     couleurFond: string;
     couleurTexte: string;
     icone:string;
     iconeI:string;
     deliberation:boolean = false;
     couleurFondDiv:string;
     couleurTextDiv:string;
     id:number;
     constructor(data:any) {
          Object.assign(this, data);
          if ( this.icone ){
               this.iconeI = `<i class="${this.icone}"></i>`;
          }
          if ( this.couleurFond ){
               this.couleurFondDiv = `<div style="background-color:${this.couleurFond}; width:100px; height:25px"></div>`;
          }
          if ( this.couleurTexte ){
               this.couleurTextDiv = `<div style="background-color:${this.couleurTexte};width:100px; height:25px"></div>`;
          }
     }
}
 