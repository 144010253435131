<p-autoComplete 
[suggestions]="elements" 
[minLength]="minLength" 
baseZIndex="10" 
type="search" 
[placeholder]="placeholder" 
#Dropdown 
[(ngModel)]="dataBind"
appendTo="body" 
styleClass="w-100" 
[field]="labelField" 
dataKey="id" 
(completeMethod)="filter($event)"
(onSelect)="emit($event)">
</p-autoComplete>
