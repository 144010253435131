import { NiveauIntroConcluTitreData } from './niveau_intro_conclusion_titre_data';

export class NiveauIntroConcluTitre {

    data : NiveauIntroConcluTitreData
   

    constructor(data:any) {
        Object.assign(this, data);
        this.data = new NiveauIntroConcluTitreData(this.data);
    }
}