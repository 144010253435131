import * as moment from 'moment';

export class SeanceLink {

    date: Date;
    description: string;
    id: number
    inclusDansSeance : boolean;
    ordreReel? : any;

    constructor(data:any) {
        Object.assign(this, data);
        if(!this.description){
            this.description = 'Séance du ' + moment(this.date).format('DD/MM/YYYY');
        }
    }
}