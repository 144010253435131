import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as async from 'async';
import { NgxSpinnerService } from 'ngx-spinner';
import { SuperRichEditComponent } from '@devinforius/super-compos';
import { ApiService } from 'src/app/core/services/api.service';
import { Participant } from 'src/app/shared/models/participant';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { Contact } from 'src/app/shared/models/iContact/contact';
import { DocumentEditorService } from 'src/app/core/services/document-editor.service';
import { ToastrService } from 'ngx-toastr';
import { LabelValue } from 'src/app/shared/models/labelvalue';
import { DataService } from 'src/app/core/services/data.service';
import { Global_Class } from 'src/app/shared/models/global';
import { Point } from 'src/app/shared/models/point';
import { ConfirmationService } from 'primeng/api';
import { DocumentFormatApi } from 'devexpress-richedit/lib/model-api/formats/enum';
import { UserContact } from 'src/app/shared/models/iContact/user-contact';
import { OAuthService } from 'angular-oauth2-oidc';
@Component({
  selector: 'app-event-add',
  templateUrl: './event-add.component.html',
  styleUrls: ['./event-add.component.scss'],
})
export class EventAddComponent implements OnInit {
  public meetingId: number = null;
  public header = null;
  public types = [];
  public numbers = [];

  public form: FormGroup = new FormGroup({
    PointId: new FormControl(null, Validators.required),
    participants: new FormArray([]),
    TexteRtf: new FormControl(null),
  });

  public participants = [];
  public participantsChosenEntree = [];
  public participantsChosenSortie = [];
  @ViewChild('document_editor') public documenteditorcontainer: SuperRichEditComponent;
  public token;
  public contacts: Array<Contact> = [];
  public usersAdmin: Array<UserContact> = [];
  public mode: string = 'create';
  public sfdt: any = null;
  public eventToPatch: any;
  public entry_exit_LV: Array<LabelValue> = [
    {
      label: 'Entrée',
      value: 'E',
    },
    {
      label: 'Sortie',
      value: 'S',
    },
  ];
  public globalElements: Global_Class = null;
  public listOfModelesEvent = [];
  public typeSeanceId: number;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public translate: TranslateService,
    public spinner: NgxSpinnerService,
    public api: ApiService,
    public contactService: IContactService,
    public editorService: DocumentEditorService,
    public toastr: ToastrService,
    public dataService: DataService,
    public confirmationService: ConfirmationService,
    private oAuthService: OAuthService
  ) {
    this.token = this.oAuthService.getAccessToken();
  }
  get f() {
    return this.form.controls;
  }

  get getParticipants(): FormArray {
    return this.form.get('participants') as FormArray;
  }
  displayModelesEventBoolean = false;
  public modeleDocChosen = null;
  public fusions: Array<any> = [];
  ngOnInit(): void {
    this.spinner.show();
    this.getFusionsField();
    async.waterfall(
      [
        (callback) => {
          this.dataService.getGlobalElements.subscribe((res: any) => {
            this.globalElements = new Global_Class(res);
            if (this.globalElements.isLoaded()) {
              callback(null);
            }
          });
        },
        (callback) => {
          this.header = this.config.data.header;
          this.meetingId = this.config.data?.meetingId;
          this.mode = this.config.data?.mode ? this.config.data.mode : 'create';
          if (this.mode === 'update') {
            this.eventToPatch = this.config.data?.event;
            if (this.eventToPatch.details.length > 0) {
              this.eventToPatch.details.map((item) => {
                this.add();
              });
            }
            this.form.patchValue({
              PointId: this.eventToPatch?.avantPointId,
              participants: this.eventToPatch.details,
            });

            const blob = new Blob([this.eventToPatch?.texteRtf], { type: 'application/rtf' });
            if (this.documenteditorcontainer) {
              this.documenteditorcontainer.rich.openDocument(blob, 'document', DocumentFormatApi.Rtf, (cb) => {
                if (cb) {
                  this.spinner.hide();
                }
              });
            }
          } else {
            this.form.patchValue({
              PointId: this.config.data.pointClicked && this.config.data.pointClicked.id,
            });
          }
          callback();
        },
        (callback) => {
          if (this.config.data.points) {
            this.numbers = this.config.data.points
              .filter((x) => {
                return !x.isTitle && !x.isIntroduction && !x.isConclusion && x.type !== 'event';
              })
              .map((x) => {
                return { label: (x.ordre ? x.ordre : '') + ' ' + x.title, value: x.id };
              });
            callback();
          } else {
            this.api.getMeetingsPointsInclus(this.meetingId).subscribe((res: any) => {
              const points = res.points.map((x) => {
                x.point.ordreReel = x.ordreReel;
                return new Point(x.point);
              });
              this.numbers = points.map((x) => {
                return { label: (x.ordre ? x.ordre : '') + ' ' + x.title, value: x.id };
              });
              callback();
            });
          }
        },
        (callback) => {
          if (this.config.data.typeSeanceId) {
            this.typeSeanceId = this.config.data.typeSeanceId;
            callback();
          } else {
            this.api.getMeetingsByID(this.meetingId).subscribe((res: any) => {
              this.typeSeanceId = res.typeSeanceId;
              callback();
            });
          }
        },
        (callback) => {
          const arrayOfKeys = ['before_label', 'after_label'];
          this.translate.get(arrayOfKeys).subscribe((res) => {
            this.types = [{ label: res['before_label'], value: 'before' }];
            callback();
          });
        },
        (callback) => {
          this.contactService.getUserWithDataAdmin().subscribe(
            (res: any) => {
              this.usersAdmin = res;
              callback();
            },
            (error) => {
              console.error(error);
            }
          );
        },
        (callback) => {
          this.contactService.getLowContacts().subscribe(
            (contacts) => {
              this.contacts = contacts;
              callback();
            },
            (error) => {
              console.error(error);
            }
          );
        },
        (callback) => {
          if (this.meetingId) {
            this.api.getParticipantsByMeetingID(this.meetingId).subscribe((res: any) => {
              this.participants = res.map((x) => new Participant(x, this.contacts, this.usersAdmin));
              callback();
            });
          } else {
            callback();
          }
        },
      ],
      () => {
        //Autant attendre le plus possible que l'éditeur soit présent dans le DOM
        if (this.documenteditorcontainer && this.mode === 'update') {
          const blob = new Blob([this.eventToPatch?.texteRtf], { type: 'application/rtf' });
          this.documenteditorcontainer.rich.openDocument(blob, 'event', DocumentFormatApi.Rtf);
        }
        this.spinner.hide();
      }
    );
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  getFusionsField() {
    this.api.getGlobalFusionsFields().subscribe((res: any) => {
      this.fusions = res;
    });
  }

  submitEvent(mode) {
    let texteRtf = null;
    if (this.form.valid) {
      this.spinner.show();
      async.waterfall([
        (cb) => {
          if (this.documenteditorcontainer) {
            this.documenteditorcontainer.rich.exportToFile((file) => {
              const blob = new Blob([file], { type: 'application/rtf' });
              const reader = new FileReader();
              reader.onload = function () {
                texteRtf = reader.result;
                cb(null);
              };
              reader.readAsText(blob);
            }, DocumentFormatApi.Rtf);
          }
        },
        (cb) => {
          this.form.patchValue({
            TexteRtf: texteRtf,
          });
          cb(null);
        },
        (cb) => {
          this.removeEventWithNoParticipant().then(() => {
            if (mode === 'create') {
              this.api.postEventByMeetingID(this.meetingId, this.form.value).subscribe(
                (res: any) => {
                  this.toastr.success("L'évènement à bien été ajouté à la séance");
                  this.ref.close(true);
                  this.spinner.hide();
                },
                (error) => {
                  this.spinner.hide();
                  this.toastr.error("Une erreur s'est produite");
                }
              );
            } else {
              this.api.putEventByMeetingID(this.meetingId, this.eventToPatch.id, this.form.value).subscribe(
                (res: any) => {
                  this.toastr.success("L'évènement à bien été mis à jour");
                  this.ref.close(true);
                  this.spinner.hide();
                },
                (error) => {
                  this.spinner.hide();
                  this.toastr.error("Une erreur s'est produite");
                }
              );
            }
          });
        },
      ]);
    } else {
      this.toastr.error('Veuillez choisir un point');
    }
  }

  removeEventWithNoParticipant() {
    return new Promise((resolve, reject) => {
      const indexesToDelete = [];
      // firstly we get all the indexes to delete
      this.form.value.participants?.map((x, i) => {
        if (x.participantId === null) {
          indexesToDelete.push(i);
        }
      });
      if (indexesToDelete.length > 0) {
        //then we reverse the array and remove the items from the formArray
        //! We HAVE to reverse because if we don't, and remove in the asc order, the array of participants will move and the 3rd item will be 2nd... indexes won't match anymore
        //! if we reverse, and delete for ex the 4th one, only items after will move!
        indexesToDelete.reverse().map((x) => {
          this.getParticipants.removeAt(x);
        });
        resolve(true);
      } else {
        resolve(true);
      }
    });
  }

  updateEvent() {}

  add(data?: any) {
    this.getParticipants.push(
      new FormGroup({
        participantId: new FormControl(null),
        entreeSortie: new FormControl(null),
        roleId: new FormControl(null),
        signatureId: new FormControl(null),
      })
    );
  }

  remove(index) {
    this.getParticipants.removeAt(index);
  }

  displayModelesEvent() {
    this.api.getManagementModelesEvenements().subscribe((data: any) => {
      if (data && data.length > 0) {
        this.listOfModelesEvent = data.filter((x) => x.typeSeanceId === this.typeSeanceId);
      }
      this.displayModelesEventBoolean = true;
    });
  }

  changeModeleEventRTF() {
    this.confirmationService.confirm({
      message:
        'Attention, si vous acceptez, le texte ci-dessous sera complètement remplacé par le texte du modèle. Êtes-vous sûr de vouloir le remplacer?',
      key: 'addEventConfirm',
      accept: () => {
        this.spinner.show();
        this.loadInfoOfModeleEventChosen();
      },
      reject: () => {
        this.modeleDocChosen = null;
      },
    });
  }

  loadInfoOfModeleEventChosen() {
    const rtf = this.listOfModelesEvent.find((x) => x.id === this.modeleDocChosen.id).evenementRtf;
    const blob = new Blob([rtf], { type: 'application/rtf' });
    if (this.documenteditorcontainer) {
      this.documenteditorcontainer.rich.openDocument(blob, 'document', DocumentFormatApi.Rtf, (cb) => {
        if (cb) {
          this.spinner.hide();
        }
      });
    }
  }
}
