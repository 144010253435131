import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IScanService } from '@devinforius/super-compos';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-home-scan',
  templateUrl: './home-scan.component.html',
  styleUrls: ['./home-scan.component.css'],
})
export class HomeScanComponent implements OnInit {
  private guid: string;

  constructor(
    public router: Router,
    private iScanService: IScanService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    let globalConfig = sessionStorage.getItem('globalConfig');
    if (globalConfig) {
      let configObject = JSON.parse(globalConfig) as any;
      this.iScanService.setUrl(configObject.apiIscanBaseUrl, configObject.ssoIscanUrl);
    }
  }

  uploadFile(event) {
    this.spinner.show();
    this.iScanService.upload(event, 'qrcode').subscribe({
      next: (resp: any) => {
        this.guid = resp.guid;
        this.router.navigate(['/private/iscan/detail/' + this.guid], { state: event.currentFiles[0] });
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        this.toastr.error(error.message, 'Erreur');
      },
    });
  }
}
