<section class="fiche-point-content mt-4" *ngIf="point">
  <div
    class="row"
    *ngIf="
      point?.verrouille || point?.cloture || (point?.modificationAutorisee && point?.modificationAFaireParContactId)
    ">
    <div class="col-md-12">
      <div class="alert alert-info alert-dismissible fade show" role="alert">
        <p-tag *ngIf="point?.verrouille && point?.cloture === false" styleClass="mr-3" value="Point verrouillé"></p-tag>
        <p-tag *ngIf="point?.cloture && point?.verrouille" value="Point cloturé"></p-tag>
        <p-tag
          *ngIf="point?.modificationAutorisee && point?.modificationAFaireParContactId"
          value="En correction"
          class="pTagOrange"></p-tag>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
  <div class="fiche-point-header">
    <div class="alert alert-warning mt-4" *ngIf="writeLock?.locked">
      <span *ngIf="!writeLock?.currentUser"
        >{{ 'PointLockedBy' | translate }} : <strong>{{ writeLock.contact }}</strong></span
      >
      <span *ngIf="writeLock?.currentUser"
        >{{ 'info.pointLockedNow' | translate }}</span
      >
    </div>

    <p-overlayPanel #overlayButton>
      <ng-template pTemplate>
        <p *ngIf="documentsOverlayItems.length > 0" class="dropdown-tooltip">
          <span class="fontStyleForSemiTitleOfOverlay">{{ 'clickondocument' | translate }}</span>
        </p>
        <div *ngFor="let item of documentsOverlayItems">
          <a class="dropdown-item">
            <div class="d-flex align-items-center">
              <i
                *ngIf="item.rights.canDownloadPdf === true"
                (click)="downloadModelDoc(item)"
                class="fas fa-download blue-icon mr-2"
                pTooltip="Télécharger le document"
                tooltipPosition="bottom"></i>
              <i
                (click)="openModelDoc(item)"
                class="fas fa-eye blue-icon"
                pTooltip="Prévisualiser le document en plein écran"
                tooltipPosition="bottom"
                *ngIf="item?.isAlreadyGeneratedAndSaved === true || item?.lastFinishedGenerationRequest"></i>
              <div (click)="openModelDoc(item)" class="ml-3">
                <span class="dropdown-item-title">
                  {{ item?.description }}
                </span>
                <span class="dropdown-item-info" *ngIf="!item?.isAlreadyGeneratedAndSaved">
                  {{
                    item?.lastFinishedGenerationRequest
                      ? 'Aperçu du ' +
                        (item?.lastFinishedGenerationRequest?.lastRequestDateTime | momentFormat: 'DD/MM/YYYY à HH:mm')
                      : "Pas encore d'aperçu"
                  }}
                </span>
                <span class="dropdown-item-info" *ngIf="item?.isAlreadyGeneratedAndSaved">
                  Généré
                  {{
                    item?.generatedAndSavedAtDateTime
                      ? 'le ' + (item?.generatedAndSavedAtDateTime | momentFormat: 'DD/MM/YYYY à HH:mm')
                      : ''
                  }}
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <p-tag
                styleClass="ml-3 mr-3"
                *ngIf="item?.myLastGenerationRequest?.statutId === 1"
                severity="info"
                value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
                [rounded]="true"></p-tag>
              <p-tag
                styleClass="ml-3 mr-3"
                *ngIf="item?.myLastGenerationRequest?.statutId === 2"
                severity="warning"
                value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
                [rounded]="true"></p-tag>
              <p-tag
                styleClass="ml-3 mr-3"
                *ngIf="item?.myLastGenerationRequest?.statutId === 3"
                severity="success"
                value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
                [rounded]="true"></p-tag>
              <p-tag
                styleClass="ml-3 mr-3"
                *ngIf="
                  item?.myLastGenerationRequest !== null &&
                  item?.myLastGenerationRequest?.statutId !== 1 &&
                  item?.myLastGenerationRequest?.statutId !== 2 &&
                  item?.myLastGenerationRequest?.statutId !== 3
                "
                severity="danger"
                value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
                [rounded]="true"></p-tag>

              <img
                src="./assets/img/preview-eye.svg"
                width="26"
                height="26"
                class="img-fluid {{ disabledLink ? 'disabled' : '' }}"
                pTooltip="Créer un document pour avoir un aperçu en PDF"
                tooltipPosition="left"
                *ngIf="item?.isAlreadyGeneratedAndSaved == false || item?.rights?.canSubmitModelePreviewRequest"
                (click)="preview(item)" />

              <img
                src="./assets/img/preview-checked.svg"
                width="26"
                height="26"
                class="img-fluid"
                pTooltip="Générer le document"
                tooltipPosition="left"
                *ngIf="item?.isAlreadyGeneratedAndSaved == false && item?.rights?.canGenerateModele"
                (click)="openGenerateAndPatch(item)" />

              <button
                pButton
                icon="far fa-ban"
                *ngIf="item?.rights?.canCancelModelePreviewRequest"
                pTooltip="Annuler la prévisualisation"
                tooltipPosition="left"
                (click)="cancelPreview(item)"
                class="button-rounded-custom mr-1"></button>

              <button
                pButton
                icon="fas fa-edit"
                (click)="editDocument(item)"
                pTooltip="Modifier le document"
                tooltipPosition="left"
                *ngIf="item?.isAlreadyGeneratedAndSaved"
                class="button-rounded-custom"></button>
            </div>
          </a>
        </div>
        <div class="dropdown-other-link">
          <!-- Avoir le droit gestionnaire sur la séance -->
          <span
            (click)="generateModeleDoc(); getModelesDocuments()"
            class="dropdown-link-span d-block mb-2"
            [ngClass]="{
              'setBorderTop mt-3': documentsOverlayItems.length > 0,
              'mt-1': documentsOverlayItems.length === 0
            }"
            >Générer un document</span
          >
          <span (click)="getModelesDocuments(); resetOverlay()" class="dropdown-link-span d-block"
            >Actualiser les documents</span
          >
        </div>
      </ng-template>
    </p-overlayPanel>
  </div>

  <div
    class="d-flex justify-content-between align-items-center pt-2 pb-3 px-2"
    style="border-bottom: 1px solid #60cdff"
    [ngClass]="{
      'border-bottom-orange': point?.verrouille,
      'border-bottom-red': point?.cloture
    }">
    <div class="header__left d-flex align-items-center justify-content-start w-100 mr-5">
      <i
        class="bi bi-arrow-left-circle-fill tacBackIcon mr-3 cursor-pointer"
        style="font-size: 24px"
        (click)="back()"></i>
      <div>
        <h1 class="mb-2 pointTitle pb-0">
          {{ point?.seanceLink?.ordreReel ? point.seanceLink.ordreReel + ' -' : null }}{{ point?.objetSynthese }}
        </h1>
        <p class="idPoint mb-0">{{ point?.id | hundredPoint }}</p>
        <div class="d-flex align-items-center">
          <span class="header__leftarrow" (click)="goPreviousPoint()"><i class="bi bi-arrow-left"></i></span>
          <span class="header__rightarrow" (click)="goNextPoint()"><i class="bi bi-arrow-right"></i></span>

          <!-- STATUT DU POINT -->
          <super-button
            [disabled]="writeLock?.locked"
            styleClass="blue superButton__statusButton mr-2 ml-2"
            [text]="StatusBtnText"
            [pTooltip]="StatusBtnTooltip"
            [children]="StatusBtnChildren"></super-button>

          <div
            style="height: 40px; border: 1px solid #ffaf2f; background-color: #fff8ec; border-radius: 6px"
            *ngIf="point?.verrouille && point?.cloture === false && !isGestionnaire"
            class="ml-3 mr-3 d-flex align-items-center p-2">
            <p class="mb-0 orange-text">
              <i class="bi bi-lock-fill mr-1"></i> Ce point est verrouillé, vous ne pouvez pas le modifier
            </p>
          </div>
          <div
            style="height: 40px; border: 1px solid #ff5671; background-color: #fff0f3; border-radius: 6px"
            *ngIf="point?.cloture"
            class="ml-3 mr-3 d-flex align-items-center p-2">
            <p class="mb-0 red-text">
              <i class="bi bi-check-circle-fill mr-1"></i> Ce point est cloturé, vous ne pouvez pas le modifier
            </p>
          </div>
          <div
            style="height: 40px; border: 1px solid #ccc; border-radius: 6px"
            *ngIf="point?.modificationAutorisee && point?.modificationAFaireParContactId"
            class="ml-3 mr-3 d-flex align-items-center p-2">
            <p class="mb-0 orange-text"><i class="bi bi-pen-fill mr-1"></i></p>
            <p class="mb-0">En correction</p>
          </div>
        </div>
        <!-- <p class="mb-0" *ngIf="point?.seanceLink?.id"><span class="cursor-pointer" pTooltip="Afficher la séance" (click)="goToSeance()">{{point?.seanceLink?.description}} <i class="bi bi-box-arrow-up-right ml-2"></i></span></p> -->
      </div>
    </div>
    <div class="courrierView__header__menuR actionsNotPDL">
      <!-- ! flex-direction: row-reverse; a ne pas enlever! Parce que le css ne fonctionne que pour un élément après, pas avant => on a reverse afin que l'élément à gauche soit, dans le DOM, a droite (ca sert pour enlever la border shadow :D ) -->
      <div class="d-flex justify-content-center cursorPointer" style="flex-direction: row-reverse; height: 100%">
        <div
          class="boldFont cursorPointer alignImgTxt pl-3 pr-3 pt-3 pb-3 actionClass d-flex flex-column justify-content-between"
          (click)="showActionsModal()">
          <img src="./assets/img/actions.svg" width="20" height="20" class="img-fluid mb-2" />
          <p class="mb-0">ACTIONS</p>
        </div>
        <div
          class="boldFont cursorPointer alignImgTxt pl-3 pr-3 pt-3 pb-3 modifierClass d-flex flex-column justify-content-between"
          (click)="this.route.navigateByUrl('/private/points/update/' + detailsService.currentID)"
          *ngIf="userCanModifyPoint && !point.cloture">
          <img src="./assets/img/modifier.svg" width="20" height="20" class="img-fluid mb-2" />
          <p class="mb-0">MODIFIER</p>
        </div>
        <div
          class="boldFont cursorPointer alignImgTxt pl-3 pr-3 pt-3 pb-3 repondreClass d-flex flex-column justify-content-between"
          (click)="toggleDocumentsOverlay($event)"
          #buttonDocument>
          <img src="./assets/img/documents.svg" width="20" height="20" class="img-fluid mb-2" style="margin-top: 4px" />
          <p class="mb-0">DOCUMENTS</p>
        </div>
      </div>
    </div>
  </div>

  <br />

  <p-tabView [(activeIndex)]="activeTab" [scrollable]="itemsTabs.length >= 6" (onChange)="tabChange(itemsTabs[activeTab])">
    <p-tabPanel *ngFor="let tab of itemsTabs" [header]="tab.label + (tab.count ? ' (' + tab.count + ')' : '')">
      <div class="fiche-point-content-info" *ngIf="tab.value === 'generalInfos' && loaded">
        <info-general
          *ngIf="point"
          [contactsAdmin]="listOfContactsAdmin"
          [contacts]="contacts"
          [item]="point"
          [right]="pointsRights"
          [writeLock]="writeLock"
          (writeLockUpdated)="updateWriteLock($event)"></info-general>
      </div>
      <div class="fiche-point-content-info" *ngIf="tab.value === 'votes'">
        <app-votes
          [point]="point"
          [contacts]="contacts"
          [contactsAdmin]="listOfContactsAdmin"
          [votes]="votes"
          [participants]="votesParticipants"
          (setVote)="changeVote($event)"
          (reloadParticipants)="setReloadParticipants()"></app-votes>
      </div>
      <div class="fiche-point-content-files" *ngIf="tab.value === 'files'">
        <app-files
          [hasLoaded]="hasLoaded"
          [rights]="pointsRights"
          [files]="files"
          [pointId]="detailsService.currentID"
          [point]="point"
          (emitSuccessUpload)="getFiles()"></app-files>
      </div>
      <div class="fiche-point-content-files" *ngIf="tab.value === 'recipients'">
        <destinataire
          [items]="destinatairesItems"
          [rights]="pointsRights"
          [point]="point"
          (refreshData)="receive_refresh($event)"></destinataire>
      </div>
      <div class="fiche-point-content-files" *ngIf="tab.value === 'historic'">
        <super-table
          [items]="historiques"
          [columns]="columnHistorique"
          *ngIf="historiques.length > 0"
          id="super-historique-tables"
          [height]="'calc( 100vh - 200px)'"
          [multiselect]="false"></super-table>
        <skeleton-table *ngIf="historiques.length === 0" [items]="columnHistorique"></skeleton-table>
      </div>
      <div class="fiche-point-content-files" *ngIf="tab.value === 'connections'">
        <app-connections [courriersIds]="point.courriersInitiateursListe" [pointId]="point.id" (emitLinkDeleted)="getPointById()"></app-connections>
      </div>
    </p-tabPanel>
  </p-tabView>
</section>

<section *ngIf="!point" class="mt-4">
  <!-- <no-info text="Impossible d'afficher ce point"></no-info> -->
  <ngx-spinner
    bdColor="rgb(255,255,255)"
    size="medium"
    color="#60cdff"
    type="ball-clip-rotate"
    [fullScreen]="false"></ngx-spinner>
</section>

<p-dialog
  styleClass="super-compos-modal-content"
  [(visible)]="showRegenerateModal"
  [modal]="true"
  [style]="{ width: '60vw', minWidth: '60vw', maxWidth: '60vw', minHeight: '50vh' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">Générer le document</h5>
      <super-button styleClass="red" (click)="showRegenerateModal = false" text="Annuler"></super-button>
    </div>
  </p-header>
  <section [formGroup]="form">
    <label class="super-compos-label-input">Type de document</label>
    <p-dropdown
      [virtualScroll]="true"
      itemSize="30"
      formControlName="modeleId"
      (onChange)="checkIfAlreadyInDocumentListPoint($event); setActualDocument($event)"
      [options]="modelesLV"
      styleClass="w-100"
      [placeholder]="'selectModeles' | translate"
      appendTo="body"
      [filter]="true"
      [readonly]="dropdownDisabled">
      <ng-template let-item pTemplate="item">
        <div class="d-flex align-items-center justify-content-between">
          <div class="truncateLabel">{{ item.label }}</div>
          <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
        </div>
      </ng-template>
    </p-dropdown>
    <section class="separator-border mt-4" *ngIf="documentAlreadyInList === false">
      <div class="mt-3 mb-4 text-center d-flex align-items-center justify-content-center flex-column">
        <p-checkbox
          *ngIf="form.value.modeleId"
          [label]="'updateDocumentBeforeGenerate' | translate"
          binary="true"
          formControlName="updateBefore"></p-checkbox>
        <super-button
          styleClass="green mt-4"
          [disabled]="!form.value.modeleId"
          text="{{ 'generateNew' | translate }}"
          (click)="generate()"></super-button>
      </div>
    </section>
    <section class="separator-border mt-4" *ngIf="documentAlreadyInList">
      <b class="mt-2">Êtes-vous sûr?</b>
      <p>
        Si vous regénérez le document, les modifications que vous avez apportées directement au document seront perdues.
      </p>
      <div>
        <p-radioButton formControlName="regenerateMode" value="replace" inputId="regen"></p-radioButton>
        <label class="mb-0 ml-2" for="regen"><b>Regénérer et remplacer</b></label>
      </div>
      <div class="ml-4 mt-2 mb-2" *ngIf="form.value.regenerateMode === 'replace'">
        <p-checkbox
          [label]="'updateDocumentBeforeGenerate' | translate"
          binary="true"
          formControlName="updateBefore"></p-checkbox>
      </div>
      <div *ngIf="actualDocumentInModal?.isAlreadyGeneratedAndSaved">
        <p-radioButton formControlName="regenerateMode" value="update" inputId="update"></p-radioButton>
        <label class="mb-0 ml-2" for="update"><b>Modifier</b></label>
      </div>
      <div class="mt-3 mb-4 text-center d-flex align-items-center justify-content-center flex-column">
        <super-button
          styleClass="green mt-4"
          text="{{ 'generateNew' | translate }}"
          (click)="generate()"></super-button>
      </div>
    </section>
  </section>
</p-dialog>

<p-dialog
  styleClass="super-compos-modal-content"
  [(visible)]="openCreationModelPointBasedOnExistingPoint"
  [modal]="true"
  [style]="{ width: '30vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">Créer un modèle de point</h5>
      <div class="d-flex">
        <super-button styleClass="green mr-2" (click)="createNewModelPoint()" text="Sauvegarder"></super-button>
        <super-button
          styleClass="red"
          (click)="openCreationModelPointBasedOnExistingPoint = false"
          text="Annuler"></super-button>
      </div>
    </div>
  </p-header>
  <section [formGroup]="formNewModelePoint">
    <div class="row">
      <div class="col-md-12 mb-2">
        <label class="input-label d-block">Description du modèle *</label>
        <input type="text" pInputText formControlName="description" class="w-100" placeholder="Description du modèle" />
      </div>
    </div>
  </section>
</p-dialog>

<p-sidebar [(visible)]="modalPdfViewer" [fullScreen]="true">
  <section>
    <ngx-extended-pdf-viewer
      *ngIf="blobViewer"
      [src]="blobViewer"
      height="100vh"
      [useBrowserLocale]="true"
      [zoom]="'auto'"
      [showFindButton]="true"
      [showPagingButtons]="true"
      [showZoomButtons]="true"
      [showPresentationModeButton]="true"
      [showOpenFileButton]="false"
      [showPrintButton]="false"
      [showDownloadButton]="false"
      [showBookmarkButton]="false"
      [showSecondaryToolbarButton]="true"
      [showRotateButton]="true"
      [showHandToolButton]="true"
      [showScrollingButton]="true"
      [showSpreadButton]="false"
      [showPropertiesButton]="true"
      [customToolbar]="additionalButtons"
      [(page)]="currentPage">
    </ngx-extended-pdf-viewer>
    <ng-template #additionalButtons>
      <div id="toolbarViewer">
        <div id="toolbarViewerLeft">
          <pdf-toggle-sidebar></pdf-toggle-sidebar>
          <div class="toolbarButtonSpacer"></div>
          <pdf-find-button [showFindButton]="true" [textLayer]="true"></pdf-find-button>
          <pdf-paging-area></pdf-paging-area>
        </div>
        <div id="toolbarViewerRight">
          <pdf-presentation-mode></pdf-presentation-mode>
          <pdf-select-tool></pdf-select-tool>
          <pdf-rotate-page></pdf-rotate-page>
          <pdf-hand-tool></pdf-hand-tool>
          <pdf-bookmark></pdf-bookmark>
          <pdf-toggle-secondary-toolbar></pdf-toggle-secondary-toolbar>
          <!-- <pdf-print></pdf-print> -->
        </div>
        <pdf-zoom-toolbar></pdf-zoom-toolbar>
      </div>
    </ng-template>
  </section>
</p-sidebar>

<p-confirmDialog
  #cd
  appendTo="body"
  styleClass="super-compos-modal-content confirmModal"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  key="fiche-point-confirm">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Non" (click)="cd.reject()"></button>
    <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="Oui" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>
<p-dialog
  styleClass="super-compos-modal-content"
  [(visible)]="confirmDialogBool"
  [modal]="true"
  [style]="{ width: '30vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">Confirmation</h5>
      <div class="d-flex">
        <super-button
          styleClass="blue"
          (click)="confirmDialogBool = false"
          [text]="'btn.close' | translate"></super-button>
      </div>
    </div>
  </p-header>
  <section>
    <p>
      Souhaitez-vous ajouter le/les fichiers <b><u>PDF</u></b> comme annexe(s) ?
    </p>
    <p>
      Attention cela ne concerne pas les autres extensions de fichiers qui sont toutes considérées comme des fichiers
      associés.
    </p>
    <div class="d-flex align-items-center justify-content-end">
      <!-- 1st part of the modal directly accessed when its opened -->
      <super-button styleClass="red mr-2" (click)="addFileAnnexe(false)" [text]="'Refuser'"></super-button>
      <super-button styleClass="green" (click)="addFileAnnexe(true)" [text]="'Confirmer'"></super-button>
    </div>
  </section>
</p-dialog>

<app-actions-point
  *ngIf="displayActionsModal"
  [point]="point"
  [canOpenPoint]="false"
  (action)="handleClickAction($event)"
  >
</app-actions-point>

<p-dialog
  [(visible)]="displayDocumentsModal"
  modal="true"
  [closable]="true"
  [showHeader]="false"
  [resizable]="false"
  [style]="{ width: '40vw', minWidth: '40vw', maxWidth: '40vw' }">
  <div class="d-flex justify-content-between align-items-center">
    <p class="actions__title mb-0">Documents</p>
    <i class="bi bi-x-circle" (click)="displayDocumentsModal = false" style="font-size: 20px; cursor: pointer"></i>
  </div>
  <ng-template pTemplate>
    <p *ngIf="documentsOverlayItems.length > 0" class="text-muted mb-4">
      <i class="bi bi-info-circle-fill mr-2"></i>{{ 'clickondocument' | translate }}
    </p>
    <div *ngFor="let item of documentsOverlayItems" class="mt-1 border-bottom">
      <a class="dropdown-item my-2">
        <div class="d-flex align-items-center">
          <i
            *ngIf="item.rights.canDownloadPdf === true"
            (click)="downloadModelDoc(item)"
            class="bi bi-download blue-icon biggerIcon mr-2"
            pTooltip="Télécharger le document"
            tooltipPosition="bottom"></i>
          <i
            (click)="openModelDoc(item)"
            class="bi bi-eye-fill blue-icon biggerIcon"
            pTooltip="Prévisualiser le document en plein écran"
            tooltipPosition="bottom"
            *ngIf="item?.isAlreadyGeneratedAndSaved === true || item?.lastFinishedGenerationRequest"></i>
          <div (click)="openModelDoc(item)" class="ml-3">
            <p class="docTitle mb-0">
              {{ item?.description }}
            </p>
            <p class="dropdown-item-info docInfo mb-0" *ngIf="!item?.isAlreadyGeneratedAndSaved">
              {{
                item?.lastFinishedGenerationRequest
                  ? 'Aperçu du ' +
                    (item?.lastFinishedGenerationRequest?.lastRequestDateTime
                      | momentFormat
                        : 'DD/MM/YYYY à
                                   HH:mm')
                  : "Pas encore d'aperçu"
              }}
            </p>
            <p class="dropdown-item-info mb-0" *ngIf="item?.isAlreadyGeneratedAndSaved">
              Généré
              {{
                item?.generatedAndSavedAtDateTime
                  ? 'le ' + (item?.generatedAndSavedAtDateTime | momentFormat: 'DD/MM/YYYY à HH:mm')
                  : ''
              }}
            </p>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="item?.myLastGenerationRequest?.statutId === 1"
            severity="info"
            value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
            [rounded]="true"></p-tag>
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="item?.myLastGenerationRequest?.statutId === 2"
            severity="warning"
            value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
            [rounded]="true"></p-tag>
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="item?.myLastGenerationRequest?.statutId === 3"
            severity="success"
            value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
            [rounded]="true"></p-tag>
          <p-tag
            styleClass="ml-3 mr-3"
            *ngIf="
              item?.myLastGenerationRequest !== null &&
              item?.myLastGenerationRequest?.statutId !== 1 &&
              item?.myLastGenerationRequest?.statutId !== 2 &&
              item?.myLastGenerationRequest?.statutId !== 3
            "
            severity="danger"
            value="{{ statusDocumentsGlobal | label_by_id: item?.myLastGenerationRequest?.statutId | translate }}"
            [rounded]="true"></p-tag>

          <span
            class="documentsActionBtn"
            *ngIf="item?.isAlreadyGeneratedAndSaved == false || item?.rights?.canSubmitModelePreviewRequest"
            (click)="preview(item)"
            pTooltip="Créer un document pour avoir un aperçu en PDF"
            tooltipPosition="left"
            >APERÇU</span
          >

          <span
            class="documentsActionBtn ml-3"
            *ngIf="item?.isAlreadyGeneratedAndSaved == false && item?.rights?.canGenerateModele"
            (click)="openGenerateAndPatch(item)"
            pTooltip="Générer"
            tooltipPosition="left"
            >Générer</span
          >

          <span
            class="documentsActionBtnCancel ml-3"
            *ngIf="item?.rights?.canCancelModelePreviewRequest"
            pTooltip="Annuler la prévisualisation"
            tooltipPosition="left"
            (click)="cancelPreview(item)"
            tooltipPosition="left"
            >Annuler</span
          >

          <span
            class="documentsActionBtnEdit ml-3"
            (click)="editDocument(item)"
            pTooltip="Modifier le document"
            tooltipPosition="left"
            *ngIf="item?.isAlreadyGeneratedAndSaved"
            >Modifier</span
          >
          <span
            *ngIf="item?.rights.canGenerateExtraitConf" 
            class="documentsActionBtnConforme ml-3"
            (click)="generateExtraitConforme(item)"
            pTooltip="Générer un extrait conforme"
            tooltipPosition="left"
          >
            <ng-container *ngIf="!loadingConformeStates[item.id]">Conforme</ng-container>
            <ng-container *ngIf="loadingConformeStates[item.id]">
              <i class="fa fa-spinner fa-spin"></i> Loading...
            </ng-container>
          </span>
        </div>
      </a>
    </div>
    <div class="d-flex justify-content-between mt-4">
      <!-- Avoir le droit gestionnaire sur la séance -->
      <super-button
        styleClass="blue"
        text="Générer un document"
        icon="bi bi-file-earmark-plus-fill"
        iconPosition="left"
        (click)="generateModeleDoc(); getModelesDocuments()">
      </super-button>
    </div>
  </ng-template>
</p-dialog>

<p-confirmDialog
  #acm
  appendTo="body"
  styleClass="super-compos-modal-content confirmModal"
  header="Confirmation"
  icon="bi bi-exclamation-triangle-fill"
  key="actionsMenuConfirmModal">
  <p-footer>
    <button type="button" pButton icon="bi bi-x-lg" class="w-100" label="Non" (click)="acm.reject();"></button>
    <button type="button" pButton icon="bi bi-check-lg" class="w-100" label="Oui" (click)="acm.accept()"></button>
  </p-footer>
</p-confirmDialog>
