export class Status_Objets {
  description: string;
  droitsVisualisation: number;
  droitsVisualisationLabel: string;
  droitsUtilisation: number;
  droitsUtilisationLabel: string;
  droitsModification: number;
  droitsModificationLabel: string;
  droitsTableauDeBord: number;
  droitsTableauDeBordLabel: string;
  statutAutiliserLorsCreation: boolean = false;
  validePourAjoutSeance: boolean = false;
  statutAutiliserLorsDeAnnulation: boolean = false;
  viderDecision: boolean = false;
  typeReport: boolean = false;
  visibleTableauDeBord: boolean = false;
  backcolor: boolean = false;
  modeleAvisDfnecessaire: boolean = false;
  exclureVerrouilles: boolean = false;
  exclureClotures: boolean = false;
  groupeSecuriteFichierAjout: number;
  groupeSecuriteFichierAjoutLabel: string;
  groupeSecuriteFichierSuppression: number;
  groupeSecuriteFichierSuppressionLabel: string;
  groupeSecuriteFichierModification: number;
  groupeSecuriteFichierModificationLabel: string;
  utilisable: boolean = false;
  backcolorDiv: string;
  id: number;
  constructor(data: any, groupes?) {
    Object.assign(this, data);
    if (this.backcolor) {
      this.backcolorDiv = `<div style="background-color:${this.backcolor};width:100px; height:25px"></div>`;
    }

    if (groupes && groupes.length > 0) {
      if (this.droitsVisualisation) {
        this.droitsVisualisationLabel = groupes.find((x) => x.id === this.droitsVisualisation).description;
      }
      if (this.droitsUtilisation) {
        this.droitsUtilisationLabel = groupes.find((x) => x.id === this.droitsUtilisation).description;
      }
      if (this.droitsModification) {
        this.droitsModificationLabel = groupes.find((x) => x.id === this.droitsModification).description;
      }
      if (this.droitsTableauDeBord) {
        this.droitsTableauDeBordLabel = groupes.find((x) => x.id === this.droitsTableauDeBord).description;
      }
      if (this.groupeSecuriteFichierAjout) {
        this.groupeSecuriteFichierAjoutLabel = groupes.find(
          (x) => x.id === this.groupeSecuriteFichierAjout
        ).description;
      }
      if (this.groupeSecuriteFichierSuppression) {
        this.groupeSecuriteFichierSuppressionLabel = groupes.find(
          (x) => x.id === this.groupeSecuriteFichierSuppression
        ).description;
      }
      if (this.groupeSecuriteFichierModification) {
        this.groupeSecuriteFichierModificationLabel = groupes.find(
          (x) => x.id === this.groupeSecuriteFichierModification
        ).description;
      }
    }
  }
}
