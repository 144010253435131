import * as moment from 'moment';

export class Points3P {
  id: number;
  externalId: string;
  subject: string;
  approveBy: string;
  description: string;
  sessionDate: string;
  sessionDateMoment: string;
  seanceId: number;
  creationDate: string;
  modificationDate: string;
  creationDateMoment: string;
  modificationDateMoment: string;
  statut: number;
  statutId: number;
  motivationFilePresent: boolean;
  motivationFilePresentIcon: string;
  decisionFilePresent: boolean;
  decisionFilePresentIcon: string;
  serviceCode: string;
  matiereCode: string;
  echevinatCode: string;
  typeSeanceCode: string;
  typePointCode: string;
  serviceId: number;
  matiereId: number;
  echevinatId: number;
  typeSeanceId: number;
  typePointId: number;
  typeId: number;
  isPatched: boolean;
  point3PId: number;
  objetSynthese: string;
  constructor(data: any) {
    Object.assign(this, data);
    this.motivationFilePresentIcon = this.showBoolean(this.motivationFilePresent);
    this.decisionFilePresentIcon = this.showBoolean(this.decisionFilePresent);
    if (this.sessionDate) {
      this.sessionDateMoment = moment(this.sessionDate).format('DD/MM/YYYY');
    }
    if (this.creationDate) {
      this.creationDateMoment = moment(this.creationDate).format('DD/MM/YYYY');
    }
    if (this.modificationDate) {
      this.modificationDateMoment = moment(this.modificationDate).format('DD/MM/YYYY');
    }
    if (this.statut) {
      this.statutId = this.statut;
    }
    if (this.id) {
      this.point3PId = this.id;
    }
    if (this.description) {
      this.objetSynthese = this.description;
    } else {
      this.objetSynthese = '';
    }
  }
  private showBoolean(value) {
    if (value) {
      return `<b style="color: #07E299;"><i class="bi bi-check-circle-fill"></i></b>`;
    } else if (value === -1) {
      return `<b style="color: #FFAF2F;"><i class="bi bi-hourglass-split"></i></b>`;
    } else {
      return `<b style="color: #FF5671;"></b>`;
    }
  }
}
