<p-header>
  <div class="modal-header super-compos-modal-header">
    <h4 class="modal-title super-compos-modal-title">
      <span class="super-compos-modal-title super-compos-modal-title-span">{{ 'advancedSearch' | translate }}</span>
    </h4>
    <div class="d-flex justify-content-end">
      <super-button
        text="{{ 'back' | translate }}"
        (click)="dialogRef.close(null)"
        styleClass="blue mr-2"
        pTooltip="Retour"
        tooltipZIndex="99999999"
        tooltipPosition="bottom">
      </super-button>
      <super-button
        text="{{ 'btn.reset' | translate }}"
        (click)="reset()"
        styleClass="red mr-2"
        pTooltip="Réinitialiser"
        tooltipZIndex="99999999"
        tooltipPosition="bottom">
      </super-button>
      <super-button
        id="btnCourrierCreate"
        text="{{ 'search' | translate }}"
        (click)="onCloseDialog()"
        pTooltip="{{ 'search' | translate }}"
        styleClass="green"
        tooltipPosition="bottom"
        tooltipZIndex="99999999">
      </super-button>
    </div>
  </div>
</p-header>
<form class="modal-body" [formGroup]="form">
  <div class="row mt-4">
    <div class="col-md-6 mb-4">
      <super-button
        text="Sauver"
        styleClass="blue w-100 d-block text-center"
        (click)="savedModal = true; updateMode = false"
        id="reinitField"></super-button>
    </div>
    <div class="col-md-6 mb-4">
      <super-button
        text="Voir les recherches sauvegardées"
        styleClass="blue w-100 d-block text-center"
        (click)="fetchSavedSearchs()"></super-button>
    </div>
    <div class="col-md-3 mb-3 p-fluid">
      <label class="input-label d-block">{{ 'excludePointsNonIncluded' | translate }}</label>
      <p-inputSwitch formControlName="ExclurePointsNonInclus"></p-inputSwitch>
    </div>
    <div class="col-md-3 mb-3 p-fluid">
      <label class="input-label d-block">{{ 'excludePointsDeleted' | translate }}</label>
      <p-inputSwitch formControlName="ExclurePointsSupprimes"></p-inputSwitch>
    </div>
    <div class="col-md-3 mb-3 p-fluid">
      <label class="input-label d-block">{{ 'title.aCorriger' | translate }}</label>
      <p-inputSwitch formControlName="ACorriger"></p-inputSwitch>
    </div>
    <div class="col-md-3 mb-3 p-fluid">
      <label class="input-label d-block">{{ 'searchInContenu' | translate }}</label>
      <p-inputSwitch formControlName="CheckContenuRTF" (onChange)="toggleSwitch($event)"></p-inputSwitch>
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="input-label">{{ showTitleAndContent ? ('global.titleAndContent' | translate) : ('global.title' | translate) }}</label>
      <input type="text" formControlName="ObjetSynthese" class="form-control" pInputText />
    </div>
    <div class="col-md-6 mb-3 p-fluid" *ngIf="groupeSecuriteRightsPoints?.viewActualTypePoint">
      <label class="super-compos-label-input">{{ 'type' | translate }}</label>
      <p-multiSelect
        [virtualScroll]="true"
        itemSize="30"
        [options]="listOfTypePoint"
        formControlName="TypeIds"
        [placeholder]="'selectOneOrMoreElements' | translate"
        appendTo="body"
        [filter]="true">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="truncateLabel">{{ item.label }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="super-compos-label-input">{{ 'seanceType' | translate }}</label>
      <p-multiSelect
        [virtualScroll]="true"
        itemSize="30"
        [options]="globalElements?.types_seances_LV"
        formControlName="TypeSeanceIds"
        (onChange)="filterMeeting($event)"
        [placeholder]="'selectOneOrMoreElements' | translate"
        appendTo="body"
        [filter]="true">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="truncateLabel">{{ item.label }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="super-compos-label-input">{{ 'meeting' | translate }}</label>
      <p-multiSelect
        [virtualScroll]="true"
        itemSize="30"
        [options]="meetings"
        formControlName="SeanceIds"
        *ngIf="meetings.length > 0"
        [placeholder]="'selectOneOrMoreElements' | translate"
        appendTo="body"
        [filter]="true">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="truncateLabel">{{ item.label }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-multiSelect>
      <div class="alert alert-warning" *ngIf="meetings.length === 0">Veuillez sélectionner un type de séance</div>
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="super-compos-label-input">{{ 'department' | translate }}</label>
      <p-multiSelect
        [virtualScroll]="true"
        itemSize="30"
        [options]="userInfos.fullView ? globalElements?.services_LV : userInfos.services"
        formControlName="ServiceIds"
        [placeholder]="'selectOneOrMoreElements' | translate"
        appendTo="body"
        [filter]="true">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="truncateLabel">{{ item.label }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="col-md-6 mb-3 p-fluid" *ngIf="groupeSecuriteRightsPoints?.viewActualStatut">
      <label class="super-compos-label-input">{{ 'pointStatus' | translate }}</label>
      <p-multiSelect
        [virtualScroll]="true"
        itemSize="30"
        [options]="listOfStatuts"
        formControlName="StatutIds"
        [placeholder]="'selectOneOrMoreElements' | translate"
        appendTo="body"
        [filter]="true">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="truncateLabel">{{ item.label }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="col-md-6 mb-3 p-fluid" *ngIf="groupeSecuriteRightsPoints?.viewActualMatiere">
      <label class="super-compos-label-input">{{ 'matter' | translate }}</label>
      <p-multiSelect
        (onChange)="changeMatter($event)"
        [virtualScroll]="true"
        itemSize="30"
        [options]="listOfMatieres"
        formControlName="MatiereIds"
        [placeholder]="'selectOneOrMoreElements' | translate"
        appendTo="body"
        [filter]="true"
        (onClear)="changeMatter(null)">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="truncateLabel">{{ item.label }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="col-md-6 mb-3 p-fluid" *ngIf="groupeSecuriteRightsPoints?.viewActualEchevinat">
      <label class="super-compos-label-input">{{ 'echevinat' | translate }}</label>
      <p-multiSelect
        (onChange)="changeEchevinat($event)"
        [virtualScroll]="true"
        itemSize="30"
        [options]="listOfEchevinats"
        formControlName="EchevinatIds"
        [placeholder]="'selectOneOrMoreElements' | translate"
        appendTo="body"
        [filter]="true"
        (onClear)="changeEchevinat(null)">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="truncateLabel">{{ item.label }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="super-compos-label-input">{{ 'decisionType' | translate }}</label>
      <p-multiSelect
        [virtualScroll]="true"
        itemSize="30"
        [options]="globalElements?.decision_type_LV"
        formControlName="TypeDecisionIds"
        [placeholder]="'selectOneOrMoreElements' | translate"
        appendTo="body"
        [filter]="true">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="truncateLabel">{{ item.label }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="col-md-6 mb-3 p-fluid" *ngIf="!globalConfiguration?.delibConfig?.globalConfiguration">
      <label class="super-compos-label-input">{{ 'handler' | translate }}</label>
      <div class="field-radiobutton d-flex align-items-center justify-content-left">
        <div class="d-flex">
          <p-radioButton
            (onClick)="onClickRadioButtonHandler($event)"
            value="users"
            [(ngModel)]="handlerType"
            [ngModelOptions]="{ standalone: true }"
            inputId="users"></p-radioButton>
          <label for="users" class="mb-0 ml-2 mr-4">{{ 'users' | translate }}</label>
        </div>
        <div class="w-100">
          <p-multiSelect
            [virtualScroll]="true"
            itemSize="30"
            *ngIf="handlerType === 'users'"
            [options]="users"
            styleClass="w-100"
            formControlName="AgentTraitantIdiAdmins"
            [placeholder]="'selectOneOrMoreAUser' | translate"
            appendTo="body"
            [filter]="true">
            <ng-template let-item pTemplate="item">
              <div class="d-flex align-items-center justify-content-between">
                <div class="truncateLabel">{{ item.label }}</div>
                <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
              </div>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <div class="field-radiobutton d-flex align-items-center justify-content-left">
        <div class="d-flex">
          <p-radioButton
            (onClick)="onClickRadioButtonHandler($event)"
            value="contacts"
            [(ngModel)]="handlerType"
            [ngModelOptions]="{ standalone: true }"
            inputId="contacts"></p-radioButton>
          <label for="contacts" class="mb-0 ml-2 mr-4">{{ 'contacts' | translate }}</label>
        </div>
        <div class="w-100">
          <p-multiSelect
            [virtualScroll]="true"
            itemSize="30"
            *ngIf="handlerType === 'contacts'"
            styleClass="w-100"
            [options]="contacts"
            formControlName="AgentTraitantIds"
            [placeholder]="'selectOneOrMoreAContact' | translate"
            appendTo="body"
            [filter]="true">
            <ng-template let-item pTemplate="item">
              <div class="d-flex align-items-center justify-content-between">
                <div class="truncateLabel">{{ item.label }}</div>
                <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
              </div>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="super-compos-label-input">{{ 'classifying' | translate }}</label>
      <p-multiSelect
        [virtualScroll]="true"
        itemSize="30"
        [options]="globalElements?.classement_code_LV"
        formControlName="ClassementIds"
        [placeholder]="'selectOneOrMoreElements' | translate"
        appendTo="body"
        [filter]="true">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="truncateLabel">{{ item.label }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="super-compos-label-input">{{ 'folder' | translate }}</label>
      <p-multiSelect
        [virtualScroll]="true"
        itemSize="30"
        [options]="globalElements?.classeurs_LV"
        formControlName="DossierClassementIds"
        [placeholder]="'selectOneOrMoreElements' | translate"
        appendTo="body"
        [filter]="true">
        <ng-template let-item pTemplate="item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="truncateLabel">{{ item.label }}</div>
            <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="super-compos-label-input">{{ 'date' | translate }}</label>
      <p-calendar
        formControlName="Date"
        [selectOtherMonths]="true"
        [showIcon]="true"
        appendTo="body"
        dateFormat="dd/mm/yy"></p-calendar>
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="super-compos-label-input">{{ 'startDate' | translate }}</label>
      <p-calendar
        formControlName="DateDebut"
        [selectOtherMonths]="true"
        [showIcon]="true"
        appendTo="body"
        dateFormat="dd/mm/yy"></p-calendar>
    </div>
    <div class="col-md-6 mb-3 p-fluid">
      <label class="super-compos-label-input">{{ 'endDate' | translate }}</label>
      <p-calendar
        formControlName="DateFin"
        [selectOtherMonths]="true"
        [showIcon]="true"
        appendTo="body"
        dateFormat="dd/mm/yy"></p-calendar>
    </div>
  </div>
</form>

<p-dialog
  header="Recherche sauvegardée"
  [(visible)]="openSavedSearchs"
  [modal]="true"
  appendTo="body"
  [style]="{ width: '50vw' }">
  <super-table
    [paginator]="true"
    class="super-table"
    [columns]="columns"
    [items]="savedSearchsItems"
    id="itemsSaved"
    [multiselect]="false"></super-table>
</p-dialog>

<p-dialog [(visible)]="savedModal" [modal]="true" [closable]="false" appendTo="body" [style]="{ width: '50vw' }">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">Sauvegarder une recherche</h5>
      <div class="d-flex align-items-center">
        <super-button styleClass="blue" (click)="savedModal = false" text="Annuler"></super-button>
        <super-button
          styleClass="green ml-3"
          (click)="saveSearch()"
          *ngIf="updateMode === false"
          text="Confirmer"></super-button>
        <super-button
          styleClass="green ml-3"
          (click)="updateSearch()"
          *ngIf="updateMode"
          text="Mettre à jour"></super-button>
      </div>
    </div>
  </p-header>
  <div class="row" [formGroup]="formSearchSaved">
    <div class="col-md-4">
      <label class="super-compos-label-input">Description</label>
      <input pInputText type="text" formControlName="description" />
    </div>
    <div class="col-md-4">
      <label class="super-compos-label-input">Ordre d'affichage</label>
      <input pInputText type="number" formControlName="displayOrder" />
    </div>
    <div class="col-md-4">
      <label class="super-compos-label-input">Caché dans le dashboard</label>
      <p-checkbox name="hideInDashboard" formControlName="hideInDashboard" binary="true"></p-checkbox>
    </div>
  </div>
</p-dialog>
