<section class="point-create mt-4" *ngIf="loaded">
  <super-title [text]="titleComponent | translate" [buttons]="buttons"></super-title>
  <div class="alert alert-warning mt-4" *ngIf="isLocked && writeLock.currentUser">
    <span
      >{{'info.pointLockedNow' | translate }}</span
    >
  </div>
  <section class="fiche-point-content mt-4">
    <super-tabs-xl [tabs]="itemsTabs" (activeTab)="changeTab($event)" [activeIndex]="activeTab"></super-tabs-xl>
    <div class="row mt-4 mb-4 justify-content-center scrollable" *ngIf="activeTab === 0">
      <div class="col-lg-9 col-md-12 col-sm-12">
        <div class="info-title">{{ 'createPoint.title.infos' | translate }}</div>
        <section id="fullForm" class="mt-4" [formGroup]="infosForm">
          <div class="row">
            <div class="col-lg-9 col-md-12 col-sm-12">
              <span class="info-content">{{ 'global.title' | translate }} *</span>
              <textarea pInputTextarea formControlName="objetSynthese" rows="1" style="width: 100%;" autoResize="true"></textarea>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12">
              <span class="info-content">{{ 'status' | translate }} *</span>
              <p-dropdown
                *ngIf="groupeSecuriteRightsPoints.changerStatut"
                [virtualScroll]="true"
                itemSize="30"
                formControlName="statutId"
                [options]="listOfStatuts"
                styleClass="w-100"
                [placeholder]="'selectAStatus' | translate"
                appendTo="body"
                [showClear]="true"
                [filter]="true">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">{{ item.label }}</div>
                    <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                  </div>
                </ng-template>
              </p-dropdown>
              <p *ngIf="!groupeSecuriteRightsPoints.changerStatut">{{ avoidChangeStatut }}</p>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 mt-4">
              <span class="info-content">{{ 'meetingTypeLinked' | translate }} *</span>
              <p-dropdown
                *ngIf="!avoidChangeSeance"
                [virtualScroll]="true"
                itemSize="30"
                formControlName="typeSeanceId"
                (onChange)="selectedMeetingPoint($event)"
                [options]="types_seances_LV | sort: 'label' : 'ASC'"
                styleClass="w-100"
                [placeholder]="'selectAMeetingType' | translate"
                appendTo="body"
                [showClear]="true"
                [filter]="true"
                (onClear)="clearTypeSeance()">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">{{ item.label }}</div>
                    <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                  </div>
                </ng-template>
              </p-dropdown>
              <p *ngIf="avoidChangeSeance">{{ actualPoint?.typeSeanceLabel }}</p>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 mt-4">
              <span class="info-content"
                >{{ 'meetingDate' | translate }} *
                <i
                  class="bi bi-info-circle"
                  [pTooltip]="'pleaseChooseATypeSeanceLinkedToBeAbleToSelectASeance' | translate"></i
              ></span>
              <p-dropdown
                [virtualScroll]="true"
                itemSize="30"
                *ngIf="!avoidChangeSeance"
                [disabled]="!infosForm.value.typeSeanceId"
                optionLabel="label"
                optionValue="value"
                formControlName="seanceId"
                [options]="seances | sort: 'date' : 'ASC'"
                styleClass="w-100"
                [placeholder]="
                  avoidChangeSeance && !infosForm.value.typeSeanceId
                    ? 'Aucune date de séance sélectionnable'
                    : ('selectAMeeting' | translate)
                "
                appendTo="body"
                [showClear]="true"
                [filter]="true"
                (onChange)="displaySeanceSelected()">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">{{ item.label }}</div>
                    <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                  </div>
                </ng-template>
              </p-dropdown>
              <span
                *ngIf="seanceSelectedInfos"
                [ngClass]="{
                  seanceSelectedGreen: !seanceSelectedInfos?.verrouille,
                  seanceSelectedOrange: seanceSelectedInfos?.verrouille
                }">
                {{ seanceSelectedInfos?.verrouille ? 'La séance est préparée' : 'La séance est ouverte' }}</span
              >
              <p *ngIf="avoidChangeSeance">
                Séance du {{ actualPoint?.seanceLink?.date | momentFormat: 'DD/MM/YYYY' }}
              </p>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
              <span class="info-content">{{ 'department' | translate }} *</span>
              <p-dropdown
                formControlName="serviceId"
                [options]="departments"
                [virtualScroll]="true"
                itemSize="30"
                panelStyleClass="departmentDD"
                styleClass="w-100"
                [placeholder]="'selectADepartment' | translate"
                appendTo="body"
                [showClear]="true"
                [filter]="true"
                (onChange)="checkDisplayButtonUseTemplateModel()">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">
                      {{ item.label }}
                    </div>
                    <div>
                      <i class="fas fa-info-circle" [pTooltip]="item.label"></i>
                    </div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
              <span class="info-content"
                >{{ 'matter' | translate }} <span *ngIf="mandatoryPointParameters.matiereObligatoire"> *</span></span
              >
              <p-dropdown
                *ngIf="groupeSecuriteRightsPoints.changerMatiere"
                [virtualScroll]="true"
                itemSize="30"
                formControlName="matiereId"
                [options]="listOfMatieres"
                styleClass="w-100"
                [placeholder]="'selectAMatter' | translate"
                appendTo="body"
                [showClear]="true"
                [filter]="true"
                (onChange)="changeMatter($event)">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">{{ item.label }}</div>
                    <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                  </div>
                </ng-template>
              </p-dropdown>
              <p *ngIf="!groupeSecuriteRightsPoints.changerMatiere">{{ avoidChangeMatiere }}</p>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
              <span class="info-content"
                >{{ 'echevinat' | translate }} <span *ngIf="mandatoryPointParameters.echevinatObligatoire"> *</span>
                <i
                  class="bi bi-info-circle"
                  [pTooltip]="'pleaseChooseAMatterToBeAbleToSelectAnEchevinat' | translate"></i
              ></span>
              <p-dropdown
                [virtualScroll]="true"
                itemSize="30"
                formControlName="echevinatId"
                [options]="listOfEchevinats"
                styleClass="w-100"
                [disabled]="!infosForm.value.matiereId"
                *ngIf="groupeSecuriteRightsPoints.changerEchevinat"
                [placeholder]="
                  !infosForm.value.matiereId && !groupeSecuriteRightsPoints.changerEchevinat
                    ? ('noEchevinatSelectable' | translate)
                    : ('selectAnEchevinat' | translate)
                "
                appendTo="body"
                [showClear]="true"
                [filter]="true">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">{{ item.label }}</div>
                    <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                  </div>
                </ng-template>
              </p-dropdown>
              <p *ngIf="!groupeSecuriteRightsPoints.changerEchevinat">{{ avoidChangeEchevinat }}</p>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
              <span class="info-content">{{ 'pointType' | translate }} *</span>
              <p-dropdown
                *ngIf="!avoidChangeObjetType && groupeSecuriteRightsPoints.changerTypePoint"
                [virtualScroll]="true"
                itemSize="30"
                formControlName="typeId"
                [options]="listOfTypePoint"
                styleClass="w-100"
                [placeholder]="'selectAPointType' | translate"
                appendTo="body"
                [showClear]="true"
                [filter]="true">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">{{ item.label }}</div>
                    <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                  </div>
                </ng-template>
              </p-dropdown>
              <p *ngIf="avoidChangeObjetType || !groupeSecuriteRightsPoints.changerTypePoint">
                {{ avoidChangeObjetTypeLabel }}
              </p>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
              <span class="info-content">{{ 'privacy' | translate }}</span>
              <p-checkbox
                [label]="'confidentialPoint' | translate"
                inputId="privacy"
                binary="true"
                formControlName="confidentiel"></p-checkbox>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
              <span class="info-content">{{ 'RGPDControl' | translate }}</span>
              <p-checkbox
                [label]="'passedRGPDControl' | translate"
                inputId="RGPDControl"
                binary="true"
                formControlName="checkRgpd"></p-checkbox>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12 mt-4">
              <span class="info-content"
                >{{ 'origin' | translate }} <span *ngIf="mandatoryPointParameters.origineObligatoire"> *</span></span
              >
              <input type="text" pInputText formControlName="origine" class="w-100" />
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
              <span *ngIf="displayCourrierId" class="info-content">{{ 'linkedMail' | translate }}</span>
              <div *ngIf="!duplication" class="d-flex align-items-center justify-content-between">
                <input
                  *ngIf="displayCourrierId"
                  type="number"
                  pInputText
                  formControlName="courrierId"
                  class="w-100"
                  [disabled]="true" />
                <button
                  pButton
                  pRipple
                  type="button"
                  [pTooltip]="'importFileFromCourrier' | translate"
                  tooltipPosition="bottom"
                  icon="bi bi-box-arrow-in-down"
                  label=""
                  class="p-button-rounded ml-3"
                  *ngIf="infosForm.value.courrierId"
                  (click)="importFileFromCourrierId()"></button>
              </div>
              <input
                *ngIf="displayCourrierId && duplication"
                type="number"
                pInputText
                formControlName="courrierId"
                class="w-100"
                [disabled]="true" />
            </div>
            <div
              class="col-lg-7 col-md-12 col-sm-12 mt-4"
              *ngIf="!globalConfiguration?.delibConfig?.cacherAgentTraitant">
              <span class="info-content">{{ 'handler' | translate }}</span>
              <div class="field-radiobutton d-flex align-items-center justify-content-left">
                <div class="d-flex">
                  <p-radioButton
                    (onClick)="onClickRadioButtonHandler($event)"
                    value="users"
                    [(ngModel)]="handlerType"
                    [ngModelOptions]="{ standalone: true }"
                    inputId="users"></p-radioButton>
                  <label for="users" class="mb-0 ml-2 mr-4">{{ 'users' | translate }}</label>
                </div>
                <div class="w-100">
                  <p-dropdown
                    [virtualScroll]="true"
                    itemSize="30"
                    [virtualScroll]="true"
                    itemSize="30"
                    *ngIf="handlerType === 'users'"
                    [options]="listeAgentsTraitantsAdmin | sort: 'fullNameForOrdering' : 'ASC'"
                    styleClass="w-100"
                    formControlName="agentTraitantIdiAdmin"
                    [placeholder]="'selectOneOrMoreAUser' | translate"
                    appendTo="body"
                    [showClear]="true"
                    [filter]="true">
                    <ng-template let-item pTemplate="item">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="truncateLabel">{{ item.label }}</div>
                        <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>
              <div class="field-radiobutton d-flex align-items-center justify-content-left">
                <div class="d-flex">
                  <p-radioButton
                    (onClick)="onClickRadioButtonHandler($event)"
                    value="contacts"
                    [(ngModel)]="handlerType"
                    [ngModelOptions]="{ standalone: true }"
                    inputId="contacts"></p-radioButton>
                  <label for="contacts" class="mb-0 ml-2 mr-4">{{ 'contacts' | translate }}</label>
                </div>
                <div class="w-100">
                  <p-dropdown
                    [virtualScroll]="true"
                    itemSize="30"
                    *ngIf="handlerType === 'contacts'"
                    styleClass="w-100"
                    [options]="listeAgentsTraitants | sort: 'fullNameForOrdering' : 'ASC'"
                    formControlName="agentTraitantId"
                    [placeholder]="'selectOneOrMoreAContact' | translate"
                    appendTo="body"
                    [showClear]="true"
                    [filter]="true">
                    <ng-template let-item pTemplate="item">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="truncateLabel">{{ item.label }}</div>
                        <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div
              [ngClass]="{ 'offset-lg-1': !globalConfiguration?.delibConfig?.cacherAgentTraitant }"
              class="col-lg-4 col-md-12 col-sm-12 mt-4"
              *ngIf="!globalConfiguration?.delibConfig?.contenuMasquerUrgence">
              <span class="info-content"
                >{{ 'emergency' | translate }} <span *ngIf="mandatoryPointParameters.urgenceObligatoire"> *</span></span
              >
              <p-dropdown
                [virtualScroll]="true"
                itemSize="30"
                formControlName="urgenceNiveauId"
                [options]="globalElements?.urgence_level_LV"
                styleClass="w-100"
                [placeholder]="'selectAnEmergency' | translate"
                appendTo="body"
                [showClear]="true"
                [filter]="true">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">{{ item.label }}</div>
                    <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
              <span class="info-content"
                >{{ 'decisionType' | translate }}
                <span *ngIf="mandatoryPointParameters.typeDecisionObligatoire"> *</span>
              </span>
              <p-dropdown
                [virtualScroll]="true"
                itemSize="30"
                formControlName="typeDecisionId"
                [options]="globalElements?.decision_type_LV"
                styleClass="w-100"
                [placeholder]="'selectADecisionType' | translate"
                appendTo="body"
                [showClear]="true"
                [filter]="true">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">{{ item.label }}</div>
                    <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
        </section>
        <section [formGroup]="infosForm" *ngIf="listOfCodesActives.length > 0">
          <div class="info-title-second">{{ 'classifying' | translate }}</div>
          <div class="row">
            <div class="col-12 mt-4">
              <span class="info-content"
                >{{ 'code' | translate }} <span *ngIf="mandatoryPointParameters.classementObligatoire"> *</span></span
              >
              <p-dropdown
                (onChange)="changeCode()"
                formControlName="classementId"
                [virtualScroll]="true"
                itemSize="50"
                [options]="listOfCodesActives | sort: 'label' : 'ASC'"
                styleClass="w-100"
                [placeholder]="'selectACode' | translate"
                appendTo="body"
                [showClear]="true"
                [filter]="true">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">{{ item.label }}</div>
                    <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-12 mt-4" *ngIf="infosForm.value.classementId">
              <span class="info-content">{{ 'folder' | translate }}</span>
              <p-dropdown
                (onChange)="changeFolder()"
                formControlName="folder"
                [virtualScroll]="true"
                itemSize="50"
                [options]="classeurs | sort: 'label' : 'ASC'"
                styleClass="w-100"
                [placeholder]="'selectAFolder' | translate"
                appendTo="body"
                [showClear]="true"
                [filter]="true">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">{{ item.label }}</div>
                    <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-12 mt-4" *ngIf="infosForm.value.folder">
              <span class="info-content">{{ 'sleeve' | translate }}</span>
              <p-dropdown
                formControlName="sleeve"
                [virtualScroll]="true"
                itemSize="50"
                [options]="chemises | sort: 'label' : 'ASC'"
                styleClass="w-100"
                [placeholder]="'selectASleeve' | translate"
                appendTo="body"
                [showClear]="true"
                [filter]="true">
                <ng-template let-item pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="truncateLabel">{{ item.label }}</div>
                    <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="row mt-4 mb-4 justify-content-center scrollable" *ngIf="activeTab === 1">
      <div class="col-lg-9 col-md-11 col-sm-12">
        <div class="info-title">{{ 'createPoint.title.content' | translate }}</div>
        <section class="mt-4" [formGroup]="infosForm">
          <div style="display: flex; justify-content: space-between; margin-bottom: 10px; align-items: center">
            <h1 style="color: #24344b; font-weight: 600; font-size: 16px; margin-bottom: 0px"></h1>
            <a
              style="color: #12ceff; font-weight: 600; font-size: 16px; text-transform: uppercase; cursor: pointer"
              (click)="onShowAll()"
              ><i class="fad fa-arrows-v"></i>&nbsp;{{
                !showAllAccordion ? ('Tout ouvrir' | translate) : ('Tout fermer' | translate)
              }}</a
            >
          </div>
          <!--! Attention ceci est du code dédoublé de <app-accordion-editor> car l'envoi de ce compo ci vers  app-acc.... n'a jamais réussi a faire sauvegarder les éditeurs dans le compo enfant -->
          <p-accordion [multiple]="true">
            <p-accordionTab (click)="onClick('Motivation', 0)" [(selected)]="activeState[0]">
              <ng-template pTemplate="header">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <div class="d-flex align-items-center">
                    <h2 class="accordion-header-title" pTooltip="Motivation">
                      Motivation
                      <i
                        class="icon-checkGreen green-icon"
                        *ngIf="contenuStaticRTFFilesPresents && contenuStaticRTFFilesPresents.motivation"></i>
                    </h2>
                  </div>
                  <div class="accordion-icon">
                    <i
                      *ngIf="pointId"
                      (click)="openFullScreen($event, 'Motivation')"
                      class="icon-fullscreen blue-icon middle-icon mr-3"></i>
                    <i
                      pTooltip="Actualiser"
                      tooltipPosition="bottom"
                      (click)="reloadRTF($event, 'Motivation', 0)"
                      class="far fa-redo-alt blue-icon middle-icon">
                    </i>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="content">
                <super-richedit
                  (click)="handleClick($event)"
                  #document_editor_motivation
                  (getRtf)="getMotivation($event)"
                  (save)="submitAContent($event, 'Motivation')"
                  [mergeFields]="fusions"
                  [showHidde]="true"
                  [font]="globalElements?.configApp?.delibConfig?.defaultMiseEnFormeParams"></super-richedit>
                <!-- <super-editor #document_editor_motivation [showSave]="true" [disabledEdition]="false" (save)="submitAContent($event, 'Motivation')" [showTexts]="false" [showExport]="false" [showVarsBtn]="false" [showPrint]="false" [title]="'Motivation'" [sfdt]="Motivation" [token]="token" (contentChange)="getMotivation($event)"></super-editor> -->
              </ng-template>
            </p-accordionTab>
            <p-accordionTab (click)="onClick('Decision', 1)" [(selected)]="activeState[1]">
              <ng-template pTemplate="header">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <div class="d-flex align-items-center">
                    <h2 class="accordion-header-title" pTooltip="Décision">
                      Décision
                      <i
                        class="icon-checkGreen green-icon"
                        *ngIf="contenuStaticRTFFilesPresents && contenuStaticRTFFilesPresents?.decision"></i>
                    </h2>
                  </div>
                  <div class="accordion-icon">
                    <i
                      *ngIf="pointId"
                      (click)="openFullScreen($event, 'Decision')"
                      class="icon-fullscreen blue-icon middle-icon mr-3"></i>
                    <i
                      pTooltip="Actualiser"
                      tooltipPosition="bottom"
                      (click)="reloadRTF($event, 'Decision', 1)"
                      class="far fa-redo-alt blue-icon middle-icon">
                    </i>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="content">
                <super-richedit
                  *ngIf="globalElements.isLoaded()"
                  (click)="handleClick($event)"
                  #document_editor_decision
                  (getRtf)="getDecision($event)"
                  (save)="submitAContent($event, 'Decision')"
                  [mergeFields]="fusions"
                  [showHidde]="true"
                  [font]="globalElements?.configApp?.delibConfig?.defaultMiseEnFormeParams"></super-richedit>
                <!-- <super-editor #document_editor_decision [showSave]="true" (save)="submitAContent($event, 'Decision')" [showTexts]="false" [showExport]="false" [disabledEdition]="false" [showPrint]="false" [showVarsBtn]="false" [title]="'Decision'" [sfdt]="Decision" [token]="token" (contentChange)="getDecision($event)"></super-editor> -->
              </ng-template>
            </p-accordionTab>
            <p-accordionTab (click)="onClick('Observations', 2)" [(selected)]="activeState[2]">
              <ng-template pTemplate="header">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <div class="d-flex align-items-center">
                    <h2 class="accordion-header-title" pTooltip="Observations">
                      Observations
                      <i
                        class="icon-checkGreen green-icon"
                        *ngIf="contenuStaticRTFFilesPresents && contenuStaticRTFFilesPresents?.observations"></i>
                    </h2>
                  </div>
                  <div class="accordion-icon">
                    <i
                      *ngIf="pointId"
                      (click)="openFullScreen($event, 'Observations')"
                      class="icon-fullscreen blue-icon middle-icon mr-3"></i>
                    <i
                      pTooltip="Actualiser"
                      tooltipPosition="bottom"
                      (click)="reloadRTF($event, 'Observations', 2)"
                      class="far fa-redo-alt blue-icon middle-icon">
                    </i>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="content">
                <super-richedit
                  (click)="handleClick($event)"
                  #document_editor_observation
                  (getRtf)="getObservations($event)"
                  (save)="submitAContent($event, 'Observations')"
                  [mergeFields]="fusions"
                  [showHidde]="true"
                  [font]="globalElements?.configApp?.delibConfig?.defaultMiseEnFormeParams"></super-richedit>
                <!-- <super-editor #document_editor_observation [showSave]="true" (save)="submitAContent($event, 'Observations')" [showTexts]="false" [showExport]="false" [disabledEdition]="false" [showPrint]="false" [title]="'Observations'" [sfdt]="Observations" [token]="token" (contentChange)="getObservations($event)"></super-editor> -->
              </ng-template>
            </p-accordionTab>
            <p-accordionTab (click)="onClick('ComplementDeDeliberation', 3)" [(selected)]="activeState[3]">
              <ng-template pTemplate="header">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <div class="d-flex align-items-center">
                    <h2 class="accordion-header-title" pTooltip="Complément de délibération">
                      Complément de délibération
                      <i
                        class="icon-checkGreen green-icon"
                        *ngIf="
                          contenuStaticRTFFilesPresents && contenuStaticRTFFilesPresents.complementDeDeliberation
                        "></i>
                    </h2>
                  </div>
                  <div class="accordion-icon">
                    <i
                      *ngIf="pointId"
                      (click)="openFullScreen($event, 'ComplementDeDeliberation')"
                      class="icon-fullscreen blue-icon middle-icon mr-3"></i>
                    <i
                      pTooltip="Actualiser"
                      tooltipPosition="bottom"
                      (click)="reloadRTF($event, 'NotesDeSynthese', 3)"
                      class="far fa-redo-alt blue-icon middle-icon">
                    </i>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="content">
                <super-richedit
                  (click)="handleClick($event)"
                  #document_editor_complementDeDeliberation
                  (getRtf)="getComplement($event)"
                  (save)="submitAContent($event, 'ComplementDeDeliberation')"
                  [mergeFields]="fusions"
                  [showHidde]="true"
                  [font]="globalElements?.configApp?.delibConfig?.defaultMiseEnFormeParams"></super-richedit>
                <!-- <super-editor #document_editor_complementDeDeliberation [showSave]="true" (save)="submitAContent($event, 'ComplementDeDeliberation')" [disabledEdition]="false" [showTexts]="false" [showExport]="false" [showVarsBtn]="false" [showPrint]="false" [title]="'Complément de délibération'" [sfdt]="ComplementDeDeliberation" [token]="token" (contentChange)="getComplement($event)"></super-editor> -->
              </ng-template>
            </p-accordionTab>
            <p-accordionTab
              (click)="onClick('NotesDeSynthese', 4)"
              *ngIf="config.delibConfig.isiDelibeCitoyensEnabled"
              [(selected)]="activeState[4]">
              <ng-template pTemplate="header">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <div class="d-flex align-items-center">
                    <h2 class="accordion-header-title" pTooltip="Notes de synthèse">
                      Notes de synthèse
                      <i
                        class="icon-checkGreen green-icon"
                        *ngIf="contenuStaticRTFFilesPresents && contenuStaticRTFFilesPresents?.notesDeSynthese"></i>
                    </h2>
                  </div>
                  <div class="accordion-icon">
                    <i
                      *ngIf="pointId"
                      (click)="openFullScreen($event, 'NotesDeSynthese')"
                      class="icon-fullscreen blue-icon middle-icon mr-3"></i>
                    <i
                      pTooltip="Actualiser"
                      tooltipPosition="bottom"
                      (click)="reloadRTF($event, 'ComplementDeDeliberation', 4)"
                      class="far fa-redo-alt blue-icon middle-icon">
                    </i>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="content">
                <super-richedit
                  (click)="handleClick($event)"
                  #document_editor_notesDeSynthese
                  (getRtf)="getNotes($event)"
                  (save)="submitAContent($event, 'NotesDeSynthèse')"
                  [mergeFields]="fusions"
                  [showHidde]="true"
                  [font]="globalElements?.configApp?.delibConfig?.defaultMiseEnFormeParams"></super-richedit>
                <!-- <super-editor #document_editor_notesDeSynthese [showSave]="true" (save)="submitAContent($event, 'NotesDeSynthèse')" [showTexts]="false" [disabledEdition]="false" [showExport]="false" [showPrint]="false" [showVarsBtn]="false" [title]="'Notes de synthèse'" [sfdt]="NotesDeSynthese" [token]="token" (contentChange)="getNotes($event)"></super-editor> -->
              </ng-template>
            </p-accordionTab>
          </p-accordion>
          <ng-template #isLoading>
            <div class="row h-100">
              <div class="col-md-12 d-flex justify-content-center align-items-center">
                <img src="./assets/img/loupe-loader.gif" />
              </div>
            </div>
          </ng-template>
        </section>
      </div>
    </div>
    <div class="row mt-4 mb-4 justify-content-center scrollable" *ngIf="activeTab === 2">
      <div class="col-lg-9 col-md-11 col-sm-12">
        <div class="info-title">{{ 'createPoint.title.joinedFiles' | translate }}</div>
        <section class="mt-1">
          <div class="row justify-content-center">
            <app-files
              class="w-100"
              [rights]="pointsRights"
              [files]="itemFiles"
              [pointId]="pointId"
              [point]="point"
              [createUpdatePoint]="true"
              (emitSuccessUpload)="getPointFiles()"></app-files>
          </div>
        </section>
      </div>
    </div>
  </section>
</section>

<p-dialog
  [(visible)]="openModifyAFileInModal"
  styleClass="super-compos-modal-content"
  [modal]="true"
  [style]="{ width: '50vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">
        {{ 'fileModification' | translate }}
      </h5>
      <div class="d-flex">
        <super-button
          styleClass="blue"
          (click)="openModifyAFileInModal = false"
          [text]="'btn.cancel' | translate"></super-button>
        <super-button styleClass="green ml-3" (click)="submitModificationFile()" text="Confirmer"></super-button>
      </div>
    </div>
  </p-header>
  <div [formGroup]="formFilesModification">
    <div class="row">
      <div class="col-md-12 mb-2">
        <label class="input-label d-block">{{ 'global.title' | translate }}/ {{ 'description' | translate }}</label>
        <input type="text" pInputText formControlName="description" class="w-100" />
      </div>
      <div class="col-md-2">
        <label class="input-label d-block mb-2"
          >{{ 'fileType' | translate }} <i class="fas fa-info-circle smallIcon" [pTooltip]="getTooltipContent()"></i
        ></label>
        <div class="d-flex align-items-center justify-content-start">
          <label class="input-label mr-2">{{ 'annex' | translate }}</label>
          <p-inputSwitch
            [readonly]="isAnnexReadonly()"
            formControlName="annexType"
            (onChange)="changeTypeOfFileInModification($event)"></p-inputSwitch>
        </div>
      </div>
      <div class="col-md-2" *ngIf="formFilesModification.value.annexType === true">
        <label class="input-label d-block">{{ 'annexNumber' | translate }} *</label>
        <input type="number" pInputText formControlName="annexeNumero" class="w-100" />
      </div>
      <div class="col-md-4" *ngIf="config?.delibConfig.isiDelibeMandatairesEnabled">
        <label class="input-label d-block"
          >{{ 'webVisibility' | translate }} : {{ 'webVisibility.mandataire' | translate }}</label
        >
        <p-checkbox
          [label]="'webVisibility.mandataire' | translate"
          binary="true"
          formControlName="visibleDelibeWeb"></p-checkbox>
      </div>
      <div class="col-md-4" *ngIf="config?.delibConfig.isiDelibeCitoyensEnabled">
        <label class="input-label d-block"
          >{{ 'webVisibility' | translate }} : {{ 'webVisibility.citoyen' | translate }}</label
        >
        <p-checkbox
          [label]="'webVisibility.citoyen' | translate"
          binary="true"
          formControlName="visibleDelibeWebCitoyen"></p-checkbox>
      </div>
    </div>
  </div>
</p-dialog>
<p-confirmDialog
  #cd
  appendTo="body"
  styleClass="super-compos-modal-content confirmModal"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  key="create">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Non" (click)="cd.reject()"></button>
    <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="Oui" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>
<p-dialog
  styleClass="super-compos-modal-content"
  [(visible)]="confirmDialogBool"
  [modal]="true"
  [style]="{ width: '30vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">Confirmation</h5>
      <div class="d-flex">
        <super-button
          styleClass="blue"
          (click)="confirmDialogBool = false"
          [text]="'btn.close' | translate"></super-button>
      </div>
    </div>
  </p-header>
  <section>
    <p>
      Souhaitez-vous ajouter le/les fichiers <b><u>PDF</u></b> comme annexe(s) ?
    </p>
    <p>
      Attention cela ne concerne pas les autres extensions de fichiers qui sont toutes considérées comme des fichiers
      associés.
    </p>
    <div class="d-flex align-items-center justify-content-end">
      <!-- 1st part of the modal directly accessed when its opened -->
      <super-button styleClass="red mr-2" (click)="addFileAnnexe(false)" [text]="'Refuser'"></super-button>
      <super-button styleClass="green" (click)="addFileAnnexe(true)" [text]="'Confirmer'"></super-button>
    </div>
  </section>
</p-dialog>
<p-dialog
  [(visible)]="openModalForFilesSelectionCourrier"
  styleClass="super-compos-modal-content"
  [modal]="true"
  [style]="{ width: '30vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">Sélection des fichiers à importer</h5>
      <div class="d-flex">
        <super-button
          styleClass="blue mr-3"
          (click)="openModalForFilesSelectionCourrier = false"
          [text]="'btn.close' | translate"></super-button>
        <super-button
          styleClass="green"
          (click)="openModalForFilesSelectionCourrier = false; patchPointFiles()"
          [text]="'btn.submit' | translate"></super-button>
      </div>
    </div>
  </p-header>
  <section>
    <div class="col-12">
      <super-table
        [initialSelection]="itemFiles"
        [forselectionDefaultOption]="false"
        #superTableFilesDuplication
        [forselection]="null"
        [items]="itemFiles | sort: 'OrdreAffichage' : 'ASC'"
        [columns]="columnsFiles"
        id="super-files-tables-courrier"
        [height]="'calc( 100vh - 400px)'"
        [multiselect]="true">
      </super-table>
    </div>
  </section>
</p-dialog>
<p-dialog
  [(visible)]="openModelesPointsModal"
  styleClass="super-compos-modal-content"
  [style]="{ width: '70vw' }"
  [modal]="true"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">Utiliser un modèle de la bibliothèque</h5>
      <div class="d-flex">
        <super-button
          styleClass="blue mr-3"
          (click)="openModelesPointsModal = false"
          [text]="'btn.close' | translate"></super-button>
      </div>
    </div>
  </p-header>
  <section>
    <div class="col-12">
      <super-table
        [forselection]="null"
        [items]="filteredModelesPoints | sort: 'serviceLabel' : 'ASC'"
        [columns]="columnsModelesPoints"
        id="super-table-modeles-points-display"
        [height]="'calc( 100vh - 300px)'"
        (onClickRow)="applyModelePoint($event)"
        [menu]="menuModelesPoint"
        (menuItemsRowClick)="setRowModelePoint($event)">
      </super-table>
    </div>
  </section>
</p-dialog>

<p-sidebar [(visible)]="modalPdfViewer" [fullScreen]="true">
  <section>
    <ngx-extended-pdf-viewer
      *ngIf="blobViewer"
      [src]="blobViewer"
      height="100vh"
      [useBrowserLocale]="true"
      [zoom]="'auto'"
      [showFindButton]="true"
      [showPagingButtons]="true"
      [showZoomButtons]="true"
      [showPresentationModeButton]="true"
      [showOpenFileButton]="false"
      [showPrintButton]="false"
      [showDownloadButton]="false"
      [showBookmarkButton]="false"
      [showSecondaryToolbarButton]="true"
      [showRotateButton]="true"
      [showHandToolButton]="true"
      [showScrollingButton]="true"
      [showSpreadButton]="false"
      [showPropertiesButton]="true"
      [customToolbar]="additionalButtons"
      [(page)]="currentPage">
    </ngx-extended-pdf-viewer>
    <ng-template #additionalButtons>
      <div id="toolbarViewer">
        <div id="toolbarViewerLeft">
          <pdf-toggle-sidebar></pdf-toggle-sidebar>
          <div class="toolbarButtonSpacer"></div>
          <pdf-find-button [showFindButton]="true" [textLayer]="true"></pdf-find-button>
          <pdf-paging-area></pdf-paging-area>
        </div>
        <div id="toolbarViewerRight">
          <pdf-presentation-mode></pdf-presentation-mode>
          <pdf-select-tool></pdf-select-tool>
          <pdf-rotate-page></pdf-rotate-page>
          <pdf-hand-tool></pdf-hand-tool>
          <pdf-bookmark></pdf-bookmark>
          <pdf-toggle-secondary-toolbar></pdf-toggle-secondary-toolbar>
          <!-- <pdf-print></pdf-print> -->
        </div>
        <pdf-zoom-toolbar></pdf-zoom-toolbar>
      </div>
    </ng-template>
  </section>
</p-sidebar>
<!-- Modal si le point est verrouillé par un autre utilisateur -->
<p-dialog
  styleClass="super-compos-modal-content"
  [(visible)]="isLocked"
  [modal]="true"
  [style]="{ width: '30vw' }"
  appendTo="body"
  [closable]="false"
  *ngIf="!writeLock.currentUser && update">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">
        {{ 'errorOccurred' | translate }}
      </h5>
      <div class="d-flex">
        <super-button styleClass="red" [text]="'returnPreviousPage' | translate" (click)="goBack()"></super-button>
      </div>
    </div>
  </p-header>
  <section class="h-[10vh] flex">
    <div class="col-md-12 mb-2 align-items-center justify-content-center">
      <label class="input-label d-block">{{ 'error.pointLocked' | translate }}</label>
    </div>
  </section>
</p-dialog>

<!-- Modal si le verrouillage arrive à expiration -->
<p-dialog
  styleClass="super-compos-modal-content"
  [(visible)]="lockExpiration"
  [modal]="true"
  [style]="{ width: '30vw' }"
  appendTo="body"
  [closable]="false">
  <p-header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="modal-title super-compos-modal-title">
        {{ 'title.lockExpires' | translate }}
      </h5>
      <div class="d-flex">
        <div class="mr-3 d-flex align-items-center custom-border-round">
          {{ timerInterval }}
        </div>
        <super-button
          styleClass="blue"
          [text]="'btn.extend' | translate"
          (click)="extendedTheValidityOfThePoint()"
          class="mr-3"></super-button>
        <super-button
          styleClass="red"
          [text]="'btn.cancel' | translate"
          (click)="realeseWriteLock()"
          [pTooltip]="'Suppression des données modifiés'"></super-button>
      </div>
    </div>
  </p-header>
  <section class="h-[10vh] flex">
    <div class="col-md-12 mb-2 align-items-center justify-content-center">
      <label class="input-label d-block"
        >{{ 'textLockPoint.text1' | translate }}<br />{{ 'textLockPoint.text2' | translate }}</label
      >
    </div>
  </section>
</p-dialog>
