import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardsService } from './core/guards/auth-guards.service';
import { AssistanceComponent } from './modules/private/pages/assistance/assistance.component';
import { PrivateComponent } from './modules/private/private.component';
import { HomeComponent } from './modules/public/home/home.component';
import { PublicComponent } from './modules/public/public.component';
import { EditorComponent } from './shared/components/editor/editor.component';
import { MeetingsComponent } from './modules/private/pages/meetings/meetings.component';
import { DetailsComponent } from './modules/private/pages/meetings/details/details.component';
import { FichePointComponent } from './modules/private/pages/meetings/details/fiche-point/fiche-point.component';
import { PointsComponent } from './modules/private/pages/points/points.component';
import { CreateComponent } from './modules/private/pages/meetings/create/create.component';
import { CreatePointComponent } from './modules/private/pages/points/create/create.component';
import { PointTablesComponent } from './modules/private/pages/points/tables/tables.component';
import { InfosMeetingComponent } from './shared/components/infos-meeting/infos-meeting.component';
import { IntroConclusionPointComponent } from './shared/components/intro-conclusion-point/intro-conclusion-point.component';
import { ContactsComponent } from './modules/private/pages/contacts/contacts.component';
import { ContactDetailsComponent } from './modules/private/pages/contacts/details/details.component';
import { PointSearchDisplayComponent } from './modules/private/pages/points/search-display/search-display.component';
import { DetailsPointsComponent } from './modules/private/pages/points/details/details.component';
import { ActionsContainerComponent } from './modules/private/pages/actions/actions-container/actions-container.component';
import { CreatePointFromIcourrierComponent } from './modules/private/pages/points/create-from-icourrier/create-icourrier.component';
import { Import3PComponent } from './modules/private/pages/points/import3-p/import3-p.component';
import { ConfirmDeactivateGuard } from './core/guards/confirm-deactivate.guard';
import { HomeScanComponent } from './shared/components/scan/home/home-scan.component';
import { DetailScanComponent } from './shared/components/scan/details/detail-scan.component';
import { ConfirmDeactivateScanGuard } from './core/guards/confirm-deactivate-scan.guard';

const routes: Routes = [
  {
    path: 'private',
    component: PrivateComponent,
    canActivate: [AuthGuardsService],
    children: [
      {
        path: '',
        component: PointsComponent,
      },
      {
        path: 'contacts',
        component: ContactsComponent,
        children: [
          {
            path: 'details/:id',
            component: ContactDetailsComponent,
          },
        ],
      },
      {
        path: 'meetings',
        component: MeetingsComponent,
      },
      // {
      //   path:'meetings/:id/infos',component:InfosMeetingComponent,
      // },
      {
        path: 'meetings/:id/infos',
        component: InfosMeetingComponent,
      },
      {
        path: 'meetings/create',
        component: CreateComponent,
      },
      {
        path: 'meetings/detail/:id/:type',
        component: IntroConclusionPointComponent,
        data: { sidebarSmall: true },
      },
      {
        path: 'meetings/details/:id',
        component: DetailsComponent,
        data: { sidebarSmall: true },
        children: [
          {
            path: 'point/:pointID',
            component: FichePointComponent,
            canDeactivate: [ConfirmDeactivateGuard],
          },
          {
            path: 'infos',
            component: InfosMeetingComponent,
          },
          {
            path: ':type',
            component: IntroConclusionPointComponent,
          },
        ],
      },
      {
        path: 'points',
        component: PointsComponent,
        data: { sidebarSmall: true },
        children: [
          {
            path: 'searchTab/:title',
            component: PointTablesComponent,
          },
          {
            path: 'search/:search',
            component: PointTablesComponent,
          },
        ],
      },
      {
        path: 'points/display/searchTab/:title',
        component: PointSearchDisplayComponent,
        children: [{ path: 'point/:pointID', component: FichePointComponent, canDeactivate: [ConfirmDeactivateGuard] }],
      },
      {
        path: 'points/display/search/:search',
        component: PointSearchDisplayComponent,
        children: [{ path: 'point/:pointID', component: FichePointComponent, canDeactivate: [ConfirmDeactivateGuard] }],
      },
      {
        path: 'points/create',
        component: CreatePointComponent,
      },
      {
        path: 'points/import3P',
        component: Import3PComponent,
      },
      {
        path: 'points/create-from-icourrier',
        component: CreatePointFromIcourrierComponent,
      },
      {
        path: 'points/update/:id',
        component: CreatePointComponent,
      },
      {
        path: 'points/duplicate',
        component: CreatePointComponent,
      },
      {
        path: 'points/detail/:pointID',
        component: DetailsPointsComponent,
        canDeactivate: [ConfirmDeactivateGuard],
      },
      {
        path: 'editor',
        component: EditorComponent,
        canDeactivate: [ConfirmDeactivateGuard],
      },
      {
        path: 'editor/point/:pointID/:typeRTF',
        component: EditorComponent,
        canDeactivate: [ConfirmDeactivateGuard],
      },
      {
        path: 'editor/point3P/:pointID3P/:fileType',
        component: EditorComponent,
        canDeactivate: [ConfirmDeactivateGuard],
      },
      {
        path: 'editor/meeting/:meetingID/:type',
        component: IntroConclusionPointComponent,
      },
      {
        path: 'editor/documentmodel/:modeleID',
        component: EditorComponent,
        canDeactivate: [ConfirmDeactivateGuard],
      },
      {
        path: 'editor/documentmodel/:modeleID/seanceId/:seanceId',
        component: EditorComponent,
        canDeactivate: [ConfirmDeactivateGuard],
      },
      {
        path: 'editor/documentmodel/:modeleID/pointId/:pointId',
        component: EditorComponent,
        canDeactivate: [ConfirmDeactivateGuard],
      },
      {
        path: 'assistance',
        component: AssistanceComponent,
      },
      {
        path: 'actions',
        data: { title: 'actions', icon: 'icon-rocket', navMenu: true },
        component: ActionsContainerComponent,
      },
      {
        path: 'iscan',
        component: HomeScanComponent,
        canDeactivate: [ConfirmDeactivateScanGuard],
      },
      {
        path: 'iscan/detail/:id',
        component: DetailScanComponent,
        canDeactivate: [ConfirmDeactivateScanGuard],
      },
    ],
  },
  {
    path: 'public',
    component: PublicComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: '**',
        redirectTo: 'home',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'public',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
