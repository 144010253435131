import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'underscore';

@Pipe({ name: 'filter' })
// | filter:key:value
// item[key] strictement égale à value
// value peut etre un string, number, Array
// Si search est un array, on passe en revue les items.
//   Il se peut cependant que les items de search ne correspondent pas directement aux items de array.
//   arg est la clé qui permet de comparer ces deux derniers tableaux ... farfelus ... peut etre ... on verra
export class FilterByKeyValue implements PipeTransform {
  transform(array: any[], key: string, search: any, arg?: string): any[] {
    function compareIt(x: Object): boolean {
      const compare = x[key]; //la valeur de l'object de l'array;
      if (_.isBoolean(search) || search === 'false' || search === 'true') {
        return search === compare.toString();
      } else if (_.isArray(search)) {
        if (_.isString(compare) || _.isNumber(compare)) {
          for (let i = 0; i < search.length; i++) {
            if (search[i] === compare) return true;
          }
          return false;
        } else if (_.isArray(compare)) {
          if (arg) {
            // On prend l'arg du compare
            for (let i = 0; i < search.length; i++) {
              for (let j = 0; j < compare.length; j++) {
                if (compare[j][arg] === search[i]) return true;
              }
            }
            return false;
          } else {
            for (let i = 0; i < search.length; i++) {
              if (compare.indexOf(search[i]) >= 0) return true;
            }
            return false;
          }
        } else return false;
      } else if (_.isString(search)) {
        if (_.isString(compare)) return search === compare;
        else if (_.isArray(compare)) {
          for (let i = 0; i < compare.length; i++) {
            if (String(compare[i]) === search) return true;
          }
          return false;
        } else return false;
      } else if (_.isNumber(search)) {
        if (_.isNumber(compare)) return search === compare;
        else if (_.isArray(compare)) {
          for (let i = 0; i < compare.length; i++) {
            if (Number(compare[i]) === search) return true;
          }
          return false;
        } else return false;
      } else return false;
    }

    if (!key) {
      throw 'Il faut une key => filter:key:value';
    } else if (search === undefined || search === null) return array;
    else if (_.isString(search) && (!search || search === undefined || search === null)) return array;
    else if (_.isNumber(search) && (search === undefined || search === null)) return array;
    else if ((_.isString(search) || _.isArray(search)) && search.length === 0) return array;
    else if (array && array.length > 0) {
      return array.filter((x) => compareIt(x));
    } else {
      return array;
    }
  }
}

function sanitizeName(chaine) {
  const tab1 = " 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ-";
  const tab2 = '__aaaaaaaaaaaaooooooooooooeeeeeeeecciiiiiiiiuuuuuuuuynn_';
  const rep1 = tab1.split('');
  const rep2 = tab2.split('');
  const myarray = [];
  _.each(rep1, (rep, i) => {
    myarray[rep] = rep2[i];
  });
  myarray['Œ'] = 'OE';
  myarray['œ'] = 'oe';
  chaine.replace(/\//g, '_');
  chaine.replace(/\\/g, '_');

  return chaine.replace(/./g, function ($0) {
    return myarray[$0] ? myarray[$0] : $0;
  });
}
@Pipe({ name: 'advancedSearch' })
// | search:value:{options}
// value:string = On fait une recherche sur le contenu string de tout l'item de l'array.
// options : {
//   excludedKeys : [],
//   accentCase : false,
//   onlyKeys : [],
// }
export class AdvancedSearch implements PipeTransform {
  transform(array: any[], search: string, options?: any, active = true): any[] {
    if (active) {
      const accentCase = options.accentCase || true;
      const excludedKeys: Array<string> =
        options.excludedKeys && Array.isArray(options.excludedKeys) && options.excludedKeys.length > 0
          ? options.excludedKeys
          : [];
      const onlyKeys: Array<string> =
        options.onlyKeys && Array.isArray(options.onlyKeys) && options.onlyKeys.length > 0 ? options.onlyKeys : [];
      if (!search || search.length == 0 || search == null) return array; // Si on a pas de texte de recherche, alors ca ne vaut pas la peine de la faire
      if (array && array.length > 0) {
        // Si on a pas un array valable, alors ca ne vaut pas la peine
        let txt = String(search).toLowerCase().replace(/\s/g, ''); // On doit comparer ce qui est comparable
        if (accentCase) txt = sanitizeName(txt);
        if (txt.length > 0) {
          const temp = [];
          for (let i = 0; i < array.length; i++) {
            // On passe en revue tous les item de l'array (ancien mode pour break)
            const item = array[i];
            let keys = Object.keys(item);
            if (onlyKeys.length > 0) keys = _.intersection(keys, onlyKeys);
            if (excludedKeys.length > 0) keys = _.difference(keys, excludedKeys);
            for (let j = 0; j < keys.length; j++) {
              const key = keys[j];
              const value = item[key];
              if (typeof value == 'string') {
                let val = String(value).toLowerCase().replace(/\s/g, ''); // On doit comparer ce qui est comparable
                if (accentCase) val = sanitizeName(val);
                const test = val.search(txt) >= 0; // On cherche :)
                if (test) {
                  // On trouve
                  temp.push(item);
                  break;
                }
              }
            }
          }
          return temp;
        } else return array;
      }
    } else {
      return array;
    }
  }
}

@Pipe({ name: 'searchByKey' })
// | searchByKey:key:value
// La différence avec filter est que il fait une recherche sur le contenu d'un string. (ce n'est pas ===)
export class SearchByKeyValue implements PipeTransform {
  transform(array: any[], key: string, search: string, active = true): any[] {
    if (active) {
      if (_.isString(search) && (search.length == 0 || !search || search == null)) return array;

      if (!key || !search) {
        throw 'Il faut une key => searchByKey:key:value';
      }

      if (array && array.length > 0) {
        const txt = String(search).toLowerCase().replace(/\s/g, '');
        if (txt.length > 0) {
          const filtered = [];
          array.map((item) => {
            const value = item[key];
            if (typeof value == 'string') {
              const val = String(value).toLowerCase().replace(/\s/g, '');
              const test = slugify(val).search(slugify(txt)) >= 0;

              if (test) {
                filtered.push(item);
              }
            }
          });
          return filtered;
        } else return array;
      }
    } else return array;
  }
}

function slugify(str) {
  const map = {
    a: 'á|à|ã|â|À|Á|Ã|Â',
    e: 'é|è|ê|É|È|Ê',
    i: 'í|ì|î|Í|Ì|Î',
    o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ',
  };

  for (const pattern in map) {
    str = str.replace(new RegExp(map[pattern], 'g'), pattern);
  }

  return str;
}

@Pipe({ name: 'startWithByKey' })
// | searchByKey:key:value
// La différence avec filter est que il fait une recherche sur le contenu d'un string. (ce n'est pas ===)
export class StartWithByKeyValue implements PipeTransform {
  transform(array: any[], key: string, search: string, active = true): any[] {
    if (active) {
      if (_.isString(search) && (search.length == 0 || !search || search == null)) return array;
      if (!key || !search) {
        throw 'Il faut une key => searchByKey:key:value';
      }

      if (array && array.length > 0) {
        const letter = String(search).toLowerCase().replace(/\s/g, '');
        if (letter.length > 0) {
          const filtered = [];
          array.map((item) => {
            const value = item[key];
            if (typeof value == 'string') {
              const val = String(value).toLowerCase().replace(/\s/g, '');
              const test = val.startsWith(letter) === true;
              if (test) {
                filtered.push(item);
              }
            }
          });
          return filtered;
        } else return array;
      }
    } else return array;
  }
}

@Pipe({ name: 'removeEmptyLabel' })
// | searchByKey:key:value
// La différence avec filter est que il fait une recherche sur le contenu d'un string. (ce n'est pas ===)
export class removeEmptyLabel implements PipeTransform {
  transform(array: any[], key: string): any[] {
    if (array && array.length > 0) {
      let temp = [];
      temp = array.filter((x) => x[key] !== null && x[key] !== '' && x[key] !== ' ');
      return temp;
    } else {
      return array;
    }
  }
}

@Pipe({ name: 'search' })
// | search:value:[excludedKeys]
// On fait une recherche sur le contenu string de tout l'item de l'array.
// On peut exclure des clés sur lequels on ne veut pas faire la recherche.
export class SearchByValue<T extends Object> implements PipeTransform {
  transform(array: T[], search: string, excludedKeys: Array<string>): T[] {
    let excludedKeysClone = [...excludedKeys];
    if (!search.length) return array; // Si on a pas de texte de recherche, alors ca ne vaut pas la peine de la faire
    if (array.length) {
      // Si on a pas un array valable, alors ca ne vaut pas la peine
      const txt = String(search).toLowerCase().replace(/\s/g, ''); // On doit comparer ce qui est comparable
      if (txt.length > 0) {
        const temp = [];
        for (let i = 0; i < array.length; i++) {
          // On passe en revue tous les item de l'array (ancien mode pour break)
          const item = array[i];
          const keys = Object.keys(item);
          for (let j = 0; j < keys.length; j++) {
            const key = keys[j];
            const testKey = excludedKeysClone.indexOf(key) >= 0;
            if (!testKey) {
              const value = item[key];
              if (typeof value == 'string') {
                const val = String(value).toLowerCase().replace(/\s/g, ''); // On doit comparer ce qui est comparable
                const test = val.search(txt) >= 0; // On cherche :)
                if (test) {
                  // On trouve
                  temp.push(item);
                  break;
                }
              }
            }
          }
        }
        return temp;
      } else return array;
    }
  }
}

@Pipe({ name: 'minDate' })
// | minDate:key:value
// key = la clé de l'objet qui définit la date minimum
// value est la valeur de comparaison
// Si object[key] <= minDate, on l'enleve de l'array
export class minDate implements PipeTransform {
  transform(array: any[], key: string, minDate: any): any[] {
    if (array && array.length > 0) {
      // Si on a pas un array valable, alors ca ne vaut pas la peine
      const mindate: any = moment(minDate);
      if (!key) {
        throw 'Il faut une key => minDate:key:value';
      } else if (!mindate._isValid) {
        throw "minDate n'est pas valide !!!";
      } else {
        return array.filter((item: any) => {
          const date: any = moment(item[key]);
          return date._isValid && mindate <= date;
        });
      }
    }
  }
}

@Pipe({ name: 'isToday' })
// | isToday:key
// value est la valeur de comparaison
// tslint:disable-next-line: class-name
export class isToday implements PipeTransform {
  transform(array: any[], key: string, active: boolean = false): any[] {
    if (active) {
      const today = moment();
      if (array && array.length > 0) {
        const newArray = array.filter((item) => today.diff(item[key], 'days') === 0);
        if (newArray && newArray.length > 0) {
          return newArray;
        } else {
          return array;
        }
      }
    } else {
      return array;
    }
  }
}

@Pipe({ name: 'isTomorrow' })
// | isToday:key
// value est la valeur de comparaison
// tslint:disable-next-line: class-name
export class isTomorrow implements PipeTransform {
  transform(array: any[], key: string, active: boolean = false): any[] {
    if (active) {
      const today = moment();
      if (array && array.length > 0) {
        const newArray = array.filter((item) => today.diff(item[key], 'days') === 1);
        if (newArray && newArray.length > 0) {
          return newArray;
        } else {
          return array;
        }
      }
    } else {
      return array;
    }
  }
}

@Pipe({ name: 'isWeek' })
// | isToday:key
// value est la valeur de comparaison
// tslint:disable-next-line: class-name
export class isWeek implements PipeTransform {
  transform(array: any[], key: string, active: boolean = false): any[] {
    if (active) {
      const today = moment();

      if (array && array.length > 0) {
        const newArray = array.filter(
          (item) => today.diff(item[key], 'days') !== 0 && today.diff(item[key], 'days') <= 7
        );
        if (newArray && newArray.length > 0) {
          return newArray;
        } else {
          return array;
        }
      }
    } else {
      return array;
    }
  }
}

@Pipe({ name: 'isMonth' })
// | isToday:key
// value est la valeur de comparaison
// tslint:disable-next-line: class-name
export class isMonth implements PipeTransform {
  transform(array: any[], key: string, active: boolean = false): any[] {
    if (active) {
      const today = moment();

      if (array && array.length > 0) {
        const newArray = array.filter(
          (item) =>
            today.diff(item[key], 'days') !== 0 &&
            today.diff(item[key], 'days') >= 1 &&
            today.diff(item[key], 'days') <= 30
        );
        if (newArray && newArray.length > 0) {
          return newArray;
        } else {
          return array;
        }
      }
    } else {
      return array;
    }
  }
}

@Pipe({ name: 'isYear' })
// | isToday:key
// value est la valeur de comparaison
// tslint:disable-next-line: class-name
export class isYear implements PipeTransform {
  transform(array: any[], argument: string): any[] {
    const today = moment();

    if (array && array.length > 0) {
      const newArray = array.filter(
        (item) =>
          today.diff(item['UpdateDt'] === null ? item['Dt'] : item['UpdateDt'], 'days') !== 0 &&
          today.diff(item['UpdateDt'] === null ? item['Dt'] : item['UpdateDt'], 'days') >= 31
      );
      if (newArray && newArray.length > 0) {
        return newArray;
      } else {
        return array;
      }
    }
  }
}

@Pipe({ name: 'maxDate' })
// | maxDate:key:value
// key = la clé de l'objet qui définit la date minimum
// value est la valeur de comparaison
// Si object[key] >= maxDate, on l'enleve de l'array
export class maxDate implements PipeTransform {
  transform(array: any[], key: string, maxDate: any): any[] {
    if (array && array.length > 0) {
      // Si on a pas un array valable, alors ca ne vaut pas la peine
      const maxdate: any = moment(maxDate);
      if (!key) {
        throw 'Il faut une key => maxDate:key:value';
      } else if (!maxdate._isValid) {
        throw "maxDate n'est pas valide !!!";
      } else {
        return array.filter((item: any) => {
          const date: any = moment(item[key]);
          return date._isValid && maxdate >= date;
        });
      }
    }
  }
}

@Pipe({ name: 'filterByObject' })
export class FilterByObject implements PipeTransform {
  transform(array: any[], obj: any): any[] {
    // On nettoie d'abord l'obj, on vire les array vide ou les undefined ou null ou autre truc vides pourris
    const temp = {};
    _.each(obj, (val, key) => {
      let check = false;
      if (_.isString(val) && val !== undefined && val !== null && val.length > 0) {
        check = true;
      } else if (_.isNumber(val) && val !== NaN && val !== undefined && val !== null) {
        check = true;
      } else if (_.isArray(val) && val.length > 0) {
        check = true;
      }
      if (check) temp[key] = val;
    });
    obj = temp;

    function doIt(item, key, search) {
      const val = item[key]; // la valeur d'un objet de l'array
      // item est un élément de array
      // key est la clé sur laquelle on cherche les match (item && search)
      // search est la valeur de comparaison avec val, soit un nombre, string, array, etc...

      if (_.isArray(search) && search.length === 2 && _.isDate(search[0]) && _.isDate(search[1])) {
        const date: any = new Date(val);
        const firstDate = new Date(search[0]);
        const lastDate = new Date(search[1]);
        if (firstDate < date && lastDate > date) {
          return true;
        }
      }
      // UNIQUEMENT SI C EST UN RANGE
      else if (_.isArray(search) && search.length === 2 && _.isNumber(search[0]) && _.isNumber(search[1])) {
        if (val >= search[0] && val <= search[1]) return true;
      }
      // SI ON COMPARE AVEC PLUSIEURS STRINGS OR NUMBERS
      else if (_.isArray(search) && search.length > 0 && _.isArray(val)) {
        for (let i = 0; i < val.length; i++) {
          const v = val[i];
          for (let j = 0; j < search.length; j++) {
            const _val = search[j];
            if (_.isString(v) && _.isString(_val) && _val.search(v) >= 0) return true;
            else if (_.isNumber(v) && _.isNumber(val) && +v === +_val) return true;
            else if (_.isNumber(v) && _.isArray(val) && +v === +_val) return true;
          }
        }
      } else if (_.isArray(search) && search.length > 0 && _.isNumber(val)) {
        for (let j = 0; j < search.length; j++) {
          const _val = search[j];
          if (+val === +_val) {
            return true;
          }
        }
      } else if (_.isArray(search) && search.length > 0 && _.isString(val)) {
        for (let j = 0; j < search.length; j++) {
          const _val = search[j];
          if (_.isString(_val) && val.search(_val) >= 0) return true;
          else if (_val === val) return true;
        }
      } else if (_.isNumber(search) && Number(val) === Number(search)) {
        return true;
      } else if (_.isString(search) && String(val) == String(search)) {
        return true;
      }
      return false;
    }

    if (_.isArray(array) && array.length > 0 && _.isObject(obj)) {
      array.map((item: any) => {
        item.__match = true;

        _.each(obj, (search: any, key: string) => {
          const check = doIt(item, key, search);

          item.__match = item.__match && doIt(item, key, search);
        });
      });

      return array.filter((x) => x.__match);
    } else return array;
  }
}

@Injectable({
  providedIn: 'root',
})
@Pipe({ name: 'filterByStateActiveInactiveContact' })
// state = l'état du contact à avoir : actif/inactif/all
// value est la valeur de comparaison
// Si object[key] >= maxDate, on l'enleve de l'array
export class FilterByStateActiveInactiveContact implements PipeTransform {
  transform(array: any[], state: any, type: string): any[] {
    if (array && array.length > 0) {
      // Si on a pas un array valable, alors ca ne vaut pas la peine
      if (state === 'all') {
        return array;
      } else {
        if (type === 'contact') {
          return array.filter((item: any) => {
            if (state === 'actif') {
              return item.userActive === true;
            } else if (state === 'inactif') {
              return item.userActive === false;
            } else {
              throw "Il faut un état : actif/inactif/all rien d'autre";
            }
          });
        }
        if (type === 'user') {
          return array.filter((item: any) => {
            if (state === 'actif') {
              return item.active === true;
            } else if (state === 'inactif') {
              return item.active === false;
            } else {
              throw "Il faut un état : actif/inactif/all rien d'autre";
            }
          });
        }
      }
    }
  }
}
