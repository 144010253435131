import { PostContactAdresse } from './post-contact-adresse';
import { PostSocieteLie } from './post-societe-lie';

export class PostContact {
    public id?: number;
    public userId?: number;
    public typeSociete?: boolean; // true if it's a company
    public typeContactId?: number;
    public titre?: string;
    public nom?: string;
    public prenom?: string;
    public nomInterne?: string;
    public fonction?: string;
    public langueId?: number;
    public nePlusUtiliser?: boolean;
    public membrePersonnel?: boolean;
    public numeroCarteNISS?: string;
    public pasDeRNBelge?: boolean;
    public dateNaissance?: string; // ($date-time)
    public naissanceLieu?: string; // ($date-time)
    public dateDeces?: string; // ($date-time)
    public etatCivil?: string;
    public sexe?: string;
    public nationalite?: string;
    public radiationRN?: boolean;
    public radiationRNDate?: string; // ($date - time)
    public numeroMatricule?: string;
    public numeroRegistrePersonnel?: string;
    public dateEntreeFonction?: string; // ($date - time)
    public dateSortieFonction?: string; // ($date - time)
    public fonctionES?: string;
    public secteurActivite: string;
    public gerantSociete?: string;
    public societeFormeJuridiqueId: number;
    public societeParente?: number;
    public societeParenteLienEtablissement?: string;
    public commentaires: string;
    public signatureCertifactNom?: string;
    public signatureDescription?: string;
    public signature1?: string;
    public signature2?: string;
    public comptaCodeClient?: string;
    public comptaCodeFournisseur?: string;
    public comptaPaiementClient?: string;
    public comptaPaiementFournisseur?: string;
    public comptaNom1?: string;
    public comptaNom2?: string;
    public comptaBanqueIBAN?: string;
    public compteBanque?: string;
    public compteBanque2?: string;
    public tva?: string;
    public tvA_Digits?: string;
    public pasDeTVABelge?: boolean;
    public siteWeb?: string;
    public siteWeb2?: string;
    public accesModuleExterneDelibe?: boolean;
    public accesModuleExterneCourrier?: boolean;
    public accesModuleExternePST?: boolean;
    public verrouille?: boolean;
    public eProjectActif?: boolean;
    public eProjectLogin?: string;
    public eProjectPassword?: string;
    public eProjectLangue?: string;
    public bce?: string;
    public exchangeMailLogin?: string;
    public contactIdReferenceInterne?: number;
    public listContactsAdresses?: PostContactAdresse[];
    public listSocieteLies?: PostSocieteLie[];
    public typeContact;
    constructor(data?: any) {
        for (const prop in data) {
            if (data.hasOwnProperty(prop)) {
                this[prop] = data[prop];
            }

        }
    }
}
