export class Echevinat {
     description:string;
     utilisable:boolean = true;
     droitsUtilisation:number =  0;
     droitsVisualisation:number = 0;
     tri: string
     id: number;
     constructor(data:any) {
         Object.assign(this, data);
     }
 
}
 