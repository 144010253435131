import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SuperComposLib } from '@devinforius/super-compos';
import { PrimengModule } from './shared/pipes/@primeng.module';
import { DialogService } from 'primeng/dynamicdialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { BasicHttpInterceptorService } from './core/providers/basic-http-interceptor';
import { AuthConfig, OAuthService, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { ConfigService } from './core/services/config.service';
import { environment } from 'src/environments/environment';
import * as async from 'async';
import { PublicComponent } from './modules/public/public.component';
import { HomeComponent } from './modules/public/home/home.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PrivateComponent } from './modules/private/private.component';
import { AssistanceComponent } from './modules/private/pages/assistance/assistance.component';
import { EditorComponent } from './shared/components/editor/editor.component';
import { AvatarModule } from 'ngx-avatar';
import { MeetingsComponent } from './modules/private/pages/meetings/meetings.component';
import { DetailsComponent } from './modules/private/pages/meetings/details/details.component';
import { ListElementComponent } from './shared/components/list-element/list-element.component';
import { ElementComponent } from './shared/components/list-element/element/element.component';
import { AngularSplitModule } from 'angular-split';
import { PipesModule } from './shared/pipes/@pipes.module';
import { FichePointComponent } from './modules/private/pages/meetings/details/fiche-point/fiche-point.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { PointsComponent } from './modules/private/pages/points/points.component';
import { InfoGeneralComponent } from './shared/components/info-general/info-general.component';
import { CreateComponent } from './modules/private/pages/meetings/create/create.component';
import { SuperBoxListCustomComponent } from './shared/components/super-box-list/super-box-list.component';
import { CreatePointComponent } from './modules/private/pages/points/create/create.component';
import { AccordionsEditorComponent } from './shared/components/accordions-editor/accordions-editor.component';
import { NoInfoComponent } from './shared/components/no-info/no-info.component';
import { PointTablesComponent } from './modules/private/pages/points/tables/tables.component';
import { SkeletonTableComponent } from './shared/components/skeleton-table/skeleton-table.component';
import { SkeletonCardComponent } from './shared/components/skeleton-card/skeleton-card.component';
import { SkeletonListComponent } from './shared/components/skeleton-list/skeleton-list.component';
import { StatusChangeComponent } from './shared/components/modals/status-change/status-change.component';
import { MeetingSearchComponent } from './shared/components/modals/meeting-search/meeting-search.component';
import { ParticipantsChangeComponent } from './shared/components/modals/participants-change/participants-change.component';
import { InfosMeetingComponent } from './shared/components/infos-meeting/infos-meeting.component';
import { IntroConclusionPointComponent } from './shared/components/intro-conclusion-point/intro-conclusion-point.component';
import { InfosMeetingEditComponent } from './shared/components/modals/infos-meeting-edit/infos-meeting-edit.component';
import { PointReportComponent } from './shared/components/modals/point-report/point-report.component';
import { EventAddComponent } from './shared/components/modals/event-add/event-add.component';
import { ErrorInterceptorService } from './core/providers/error-http-interceptor';
import { ErrorModalComponent } from './shared/components/modals/error-modal/error-modal.component';
import { ActionResponseModalComponent } from './shared/components/modals/action-response-modal/action-response-modal.component';
import { RecipientModalComponent } from './shared/components/modals/recipient-modal/recipient-modal.component';
import { SuperListSimpleEDelibeComponent } from './shared/components/super-list-simple/super-list-simple.component';
import { ContactNewComponent } from './shared/components/modals/contact-new/contact-new.component';
import { ActionCreateModalComponent } from './shared/components/modals/action-create-modal/action-create-modal.component';
import { DestinataireComponent } from './shared/components/destinataire/destinataire.component';
import { ContactsComponent } from './modules/private/pages/contacts/contacts.component';
import { ContactDetailsComponent } from './modules/private/pages/contacts/details/details.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MarktocorrectionComponent } from './shared/components/modals/marktocorrection/marktocorrection.component';
import { VotesComponent } from './shared/components/votes/votes.component';
import { NgbTimepicker, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { PointsSearchComponent } from './shared/components/modals/points-search/points-search.component';
import { PointSearchDisplayComponent } from './modules/private/pages/points/search-display/search-display.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DetailsPointsComponent } from './modules/private/pages/points/details/details.component';
import { ActionsContainerComponent } from './modules/private/pages/actions/actions-container/actions-container.component';
import { ContactInfosService } from './core/services/contact-infos.service';
import { ActionsPaginationComponent } from './modules/private/pages/actions/actions-container/actions-pagination/actions-pagination.component';
import { FilesComponent } from './shared/components/files/files.component';
import { CreatePointFromIcourrierComponent } from './modules/private/pages/points/create-from-icourrier/create-icourrier.component';
import { DialogReleasesNotesComponent } from './shared/components/modals/dialog-releases-notes/dialog-releases-notes.component';
import { MassVotesComponent } from './shared/components/modals/mass-votes/mass-votes.component';
import { SuperAutoCompleteComponent } from './shared/components/super-autocomplete/super-autocomplete.component';
import { Import3PComponent } from './modules/private/pages/points/import3-p/import3-p.component';
import { ReportingComponent } from './shared/components/modals/reporting/reporting.component';
import { TableWithInputComponent } from './shared/components/table-with-input/table-with-input.component';
import { DuplicationPointComponent } from './shared/components/modals/duplication/duplication-point.component';
import { ConfirmDeactivateGuard } from './core/guards/confirm-deactivate.guard';
import { GuardConfirmModalComponent } from './shared/components/modals/guard-confirm-modal/guard-confirm-modal.component';
import { GenerateDocLotComponent } from './shared/components/modals/generate-doc-lot/generate-doc-lot.component';
import { CreateModelPointFromExistingPoint } from './modules/private/pages/meetings/details/fiche-point/createModelPointFromExistingPoint/createModelPointFromExistingPoint.component';
import { filter } from 'rxjs/operators';
import { DetailScanComponent } from './shared/components/scan/details/detail-scan.component';
import { HomeScanComponent } from './shared/components/scan/home/home-scan.component';
import { ConfirmDeactivateScanGuard } from './core/guards/confirm-deactivate-scan.guard';
import { HundredPointPipe } from './shared/pipes/format.pipe';
import { DragDropDirectiveDirective } from './shared/directives/drag-drop-directive.directive';
import { ConnectionsComponent } from './modules/private/pages/points/connections/connections.component';
import { SignFileComponent } from './shared/components/signFile/sign-file.component';
import { ActionsPointComponent } from './modules/private/pages/points/actions-point/actions-point.component';
import { SearchModalScanComponent } from './shared/components/scan/search-modal/search-modal.component';
export function appInit(configService: ConfigService) {
  return () => {
    configService.load();
  };
}

export function storageFactory(): OAuthStorage {
  return localStorage;
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}
@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    HomeComponent,
    PrivateComponent,
    AssistanceComponent,
    EditorComponent,
    MeetingsComponent,
    DetailsComponent,
    ActionsPointComponent,
    ListElementComponent,
    ElementComponent,
    FichePointComponent,
    InfoGeneralComponent,
    CreateComponent,
    PointsComponent,
    SuperBoxListCustomComponent,
    CreatePointComponent,
    AccordionsEditorComponent,
    NoInfoComponent,
    PointTablesComponent,
    SkeletonTableComponent,
    SkeletonCardComponent,
    SignFileComponent,
    SkeletonListComponent,
    StatusChangeComponent,
    ParticipantsChangeComponent,
    InfosMeetingComponent,
    IntroConclusionPointComponent,
    InfosMeetingEditComponent,
    MeetingSearchComponent,
    PointReportComponent,
    EventAddComponent,
    ErrorModalComponent,
    ActionResponseModalComponent,
    RecipientModalComponent,
    SuperListSimpleEDelibeComponent,
    ContactNewComponent,
    ActionCreateModalComponent,
    DestinataireComponent,
    ContactNewComponent,
    ContactDetailsComponent,
    ContactsComponent,
    MarktocorrectionComponent,
    VotesComponent,
    PointsSearchComponent,
    PointSearchDisplayComponent,
    DetailsPointsComponent,
    ActionsContainerComponent,
    ActionsPaginationComponent,
    FilesComponent,
    CreatePointFromIcourrierComponent,
    DialogReleasesNotesComponent,
    MassVotesComponent,
    SuperAutoCompleteComponent,
    Import3PComponent,
    ReportingComponent,
    TableWithInputComponent,
    DuplicationPointComponent,
    GuardConfirmModalComponent,
    GenerateDocLotComponent,
    CreateModelPointFromExistingPoint,
    DetailScanComponent,
    HomeScanComponent,
    HundredPointPipe,
    DragDropDirectiveDirective,
    ConnectionsComponent,
    SearchModalScanComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AppRoutingModule,
    AvatarModule,
    PrimengModule,
    SuperComposLib,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    OAuthModule.forRoot(),
    AngularSplitModule,
    PipesModule,
    DragDropModule,
    ScrollingModule,
    GoogleMapsModule,
    NgbTimepickerModule,
    NgxExtendedPdfViewerModule,
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [ConfigService],
    },
    ConfirmDeactivateGuard,
    ConfirmDeactivateScanGuard,
    DialogService,
    ConfirmationService,
    ContactInfosService,
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: HTTP_INTERCEPTORS, useClass: BasicHttpInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  public conf: any;
  public authConfig: AuthConfig = {};
  constructor(
    private oauthService: OAuthService,
    private http: HttpClient
  ) {
    async.waterfall([
      (cb: any) => {
        if (environment.production === true) {
          this.http.get('assets/config/configProd.json').subscribe((data) => {
            Object.assign(this, data);
            this.conf = data;
            this.authConfig = {
              issuer: this.conf['issuer'],
              postLogoutRedirectUri: this.conf['postLogoutRedirectUri'],
              redirectUri: this.conf['redirectUri'],
              clientId: this.conf['clientId'],
              oidc: this.conf['oidc'],
              requireHttps: this.conf['requireHttps'],
              sessionChecksEnabled: false,
              userinfoEndpoint: this.conf['userinfoEndpoint'],
              useSilentRefresh: this.conf['useSilentRefresh'],
              scope: this.conf['scope'],
              showDebugInformation: this.conf['showDebugInformation'],
              silentRefreshRedirectUri: this.conf['silentRefreshRedirectUri'],
              responseType: this.conf['responseType'],
            };
            sessionStorage.setItem('globalConfig', JSON.stringify(data));
            cb(null);
          });
        } else {
          this.http.get('assets/config/config.json').subscribe((data) => {
            Object.assign(this, data);
            this.conf = data;
            this.authConfig = {
              issuer: this.conf['issuer'],
              postLogoutRedirectUri: this.conf['postLogoutRedirectUri'],
              redirectUri: this.conf['redirectUri'],
              clientId: this.conf['clientId'],
              oidc: this.conf['oidc'],
              requireHttps: this.conf['requireHttps'],
              sessionChecksEnabled: false,
              userinfoEndpoint: this.conf['userinfoEndpoint'],
              useSilentRefresh: this.conf['useSilentRefresh'],
              scope: this.conf['scope'],
              showDebugInformation: this.conf['showDebugInformation'],
              silentRefreshRedirectUri: this.conf['silentRefreshRedirectUri'],
              responseType: this.conf['responseType'],
            };
            sessionStorage.setItem('globalConfig', JSON.stringify(data));
            cb(null);
          });
        }
      },
      (cb: any) => {
        this.oauthService.configure(this.authConfig);
        this.oauthService.setupAutomaticSilentRefresh({}, 'access_token');

        // Automatically load user profile
        this.oauthService.events.pipe(filter((e) => e.type === 'token_received')).subscribe((_) => {
          console.info('Token received and successfully validated --> load user profile from SSO');
          this.oauthService.loadUserProfile().then(
            () => {
              // console.info('User profile loaded from SSO --> navigate to active page');
            },
            () => {
              // console.info('User profile loading from SSO failed! --> navigate to active page');
            }
          );
        });

        // this.oauthService.events.subscribe((e) => {
        //   console.info("oauth/oidc event", e);
        // });

        this.oauthService.loadDiscoveryDocumentAndTryLogin().then(
          (success) => {
            console.info('On promise After loadDiscoveryDocumentAndTryLogin: success=' + success);
          },
          (error) => {
            console.error('On promise After loadDiscoveryDocumentAndTryLogin: error=' + error);
          }
        );

        cb(null);
      },
    ]);
  }
}
