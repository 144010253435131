import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { retry, catchError, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../services/data.service';
import { RouterLink } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { ErrorModalComponent } from 'src/app/shared/components/modals/error-modal/error-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
  public hasFinish: boolean = false;
  constructor(
    public oAuthService: OAuthService,
    private injector: Injector,
    public toastr: ToastrService,
    public dataService: DataService,
    public dialog: DialogService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestToForward = req;
    return next.handle(requestToForward).pipe(
      catchError(
        (err) =>
          new Observable<HttpEvent<any>>((observer) => {
            if (err instanceof HttpErrorResponse) {
              const errResp = <HttpErrorResponse>err;
              if (
                errResp &&
                errResp.status !== 404 &&
                !errResp.url.includes('/Me/Avatar') &&
                requestToForward.method !== 'GET'
              ) {
                this.hasFinish = false;
                const instance = err.error ? err.error?.instance : '';
                if (instance && err.status) {
                  this.dataService.setError({
                    route: instance ? instance.replace('/api/v1/', '') : '',
                    status: err?.status,
                    message: err?.error?.message,
                    code: err?.error?.code,
                    method: requestToForward?.method,
                  });
                }
                if (this.hasFinish === false) {
                  const link = `Cliquer ici pour plus d'information`;
                  this.toastr.error(link, 'Des erreurs se sont produites').onTap.subscribe((res: any) => {
                    this.openModal();
                  });
                }
              }
            }
            observer.error(err);
            observer.complete();
            this.hasFinish = true;
          })
      )
    );
  }

  openModal() {
    const ref = this.dialog.open(ErrorModalComponent, {
      width: '80vw',
      styleClass: 'super-compos-modal-content ',
      showHeader: false,
      closable: false,
      dismissableMask: false,
      baseZIndex: 1000,
    });
    ref.onClose.subscribe((res: any) => {
      this.dataService.setError([]);
    });
  }
}
