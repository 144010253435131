import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DialogService } from 'primeng/dynamicdialog';
import { DataService } from 'src/app/core/services/data.service';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { DialogReleasesNotesComponent } from 'src/app/shared/components/modals/dialog-releases-notes/dialog-releases-notes.component';

@Component({
  selector: 'app-assistance',
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.scss'],
})
export class AssistanceComponent implements OnInit {
  public AssistanceText = `Répondre à vos questions est la priorité de notre équipe Assistance.`;
  public config: any = null;
  public urlDoc: string = '';
  public documentations: Array<any> = [];
  public actualVersion: string;
  public currentVersion: string;
  constructor(
    private titleService: Title,
    public dialogService: DialogService,
    public apiAdmin: iAdminService,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('iDélibé - Assistance');
    this.getData();
    const globalConfig = JSON.parse(sessionStorage.getItem('globalConfig'));
    if (globalConfig) {
      this.currentVersion = globalConfig.currentVersion;
    }
  }

  openModal() {
    const dialog = this.dialogService.open(DialogReleasesNotesComponent, {
      width: '60vw',
      data: {
        url: this.urlDoc,
      },
    });
  }
  getData() {
    this.apiAdmin.getGlobalConfig().subscribe((res: any) => {
      this.config = res;
      this.urlDoc = res.urlDocumentation;
      this.config.phone = this.config && this.config.phone ? this.config.phone : '081/744 340';
      this.config.email = this.config && this.config.email ? this.config.email : 'infodoc@inforius.be';
    });
  }
}
