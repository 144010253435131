import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TenantInfo } from 'src/app/shared/models/tenant-info';
import { UserInfo } from 'src/app/shared/models/user-info';
import { map, switchMap, tap } from 'rxjs/operators';
import { GlobalConfig } from 'src/app/shared/models/global';
import { SearchMailInfos } from 'src/app/shared/models/iCourrier/mail-infos';

@Injectable({
  providedIn: 'root',
})
export class iCourrierService {
  public apiBaseUrl: string;
  public body: FormData;
  public headers: any;
  public params: HttpParams = new HttpParams();

  constructor(public http: HttpClient) {
    const config = JSON.parse(sessionStorage.getItem('globalConfig'));
    this.apiBaseUrl = config.apiICourrierBaseUrl;
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.body = new FormData();
    this.params = new HttpParams();
  }

  searchCourriersInfos(listOfCourrierIds: number[]): Observable<SearchMailInfos[]> {
    return this.http.get<SearchMailInfos[]>(
      `${this.apiBaseUrl}/Connecteurs/iDelibe/Mails/SearchBasic?ids=${listOfCourrierIds}`
    );
  }

  deleteIDelibeLinks(mailId: number, pointId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiBaseUrl}/Connecteurs/iDelibe/Mails/${mailId}/DelibeLiens/${pointId}`);
  }
}
