<div class="modal-header super-compos-modal-header">
    <h5 class="modal-title super-compos-modal-title">
        {{header}}
    </h5>
    <div class="d-flex align-items-center">
        <super-button styleClass="blue mr-3" [text]="'btn.cancel'|translate" (click)="ref.close()"></super-button>
    </div>
</div>
<div class="modal-body" >
    <section class="w-100 mb-5 mt-5">
        <div class="row" *ngIf="mode === 'point'">
            <div class="col-md-12">
                <label class="super-compos-label-input mr-4">{{'changeStatutsOfPointsTo'|translate}}</label>
                <p-dropdown [virtualScroll]="true" (onChange)="onChangeStatutPoints($event)" itemSize="30" [options]="globalElements?.statuts_points_LV" [placeholder]="'selectAPointStatus'|translate" appendTo="body" [showClear]="true" [filter]="true" [(ngModel)]="statusChosen">
                    <ng-template let-item pTemplate="item">
                        <div class="d-flex align-items-center justify-content-between">
                                <div class="truncateLabel">{{item.label}}</div>
                                <div> <i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="col-md-12 mt-3" *ngIf="pointsWithError.length > 0">
                <super-table 
                id="superTableChangeStatusPointsMultiple"
                [columns]="columns"
                [items]="pointsWithError"
              >
              </super-table>
            </div>
        </div>
    </section>
</div>


