import { TenantInfoApplications } from './tenant-into-applications';

export class TenantInfo {
     public id:string;
     description : string;
     name : string;
     applications : TenantInfoApplications[]
     
     constructor(data:any) {
          Object.assign(this, data);
          
     }
}
 