<super-title [text]="'sidebar.meetings' | translate" [buttons]="buttons"></super-title>
<super-tabs-xl
  [tabs]="statusTab"
  (activeTab)="changeTab($event)"
  [activeIndex]="tabsArrayXLActiveIndex"></super-tabs-xl>
<section *ngIf="items.length > 0" class="scrollable">
  <!-- ! si on change l'id de la table, ne pas oublier de le changer dans le ngOnDestroy ! -->
  <i-table #superTable
  class="tableWithTDMaxWidthModifiedWithMediaQuery"
  [id]="'super-seances-table-2'"
  [config]="tableConfigurations"
  [items]="items"
  [columns]="columns"
  [menu]="menu"
  [customActions]="forselection"
  [customActionManualReset]="true"
  (onSelectedRow)="showDetails($event)"
  (pageChange)="onLazyLoad($event)"
  (onColumnSort)="columnSort($event)">
  <super-button text="Recherche avancée" (click)="showAdvancedSearch()" styleClass="blue" count="{{dataService.advancedSearchEnabled ? '!' : null}}"></super-button>
  <super-button *ngIf="dataService.advancedSearchEnabled" (click)="resetAdvancedSearch()" [tooltip]="'réinitialisation de la recherche avancée'" [styleClass]="'red'" size="small" class="ml-2" [icon]="'bi bi-arrow-counterclockwise'"></super-button>
  </i-table>
</section>

<section *ngIf="items.length === 0">
  <div class="alert alert-primary">{{ 'noresult' | translate }}</div>
</section>

<!-- modale d'erreur : faire un if else pour afficher d'abord les droits d'éditions et si la personne les a alors on affiche les points en correction! -->
<p-confirmDialog
  #cd
  appendTo="body"
  styleClass="super-compos-modal-content confirmModal"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  key="meetingModal">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="{{'No' | translate}}" (click)="cd.reject()"></button>
    <button class="pButtonRed" type="button" pButton icon="pi pi-check" label="{{'Yes' | translate}}" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>
