import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'skeleton-table',
  templateUrl: './skeleton-table.component.html',
  styleUrls: ['./skeleton-table.component.scss']
})
export class SkeletonTableComponent implements OnInit {
  @Input() set items(value){
    if ( value ){
      this.columns = value;
    }
  }
  public columns:Array<any> = [];
  constructor() { }

  ngOnInit(): void {
  }

}
