import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { LogLevel } from '@microsoft/signalr';
import { OAuthService } from 'angular-oauth2-oidc';
import { Subject } from 'rxjs';
import { NotifyUserSignalrPayload } from 'src/app/shared/models/signalr_payloads';
@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  constructor(private oAuthService: OAuthService) {}
  public data: any[];
  private hubConnection: signalR.HubConnection;

  private notifyUserSignalrSource = new Subject();
  notifyUser = this.notifyUserSignalrSource.asObservable();

  public register = () => {
    // Implement IRetryPolicy interface to continuously reconnect
    class FiveSecondsReconnectPolicy implements signalR.IRetryPolicy {
      nextRetryDelayInMilliseconds(retryContext: signalR.RetryContext): number {
        // retry after 2 seconds else 5 seconds
        return retryContext.elapsedMilliseconds < 2000 ? 2000 : 5000;
      }
    }

    const config = JSON.parse(sessionStorage.getItem('globalConfig'));
    const signalrHubUrl = config.apiGatewayBaseUrl + '/SignalR/NotificationsHub';

    const reconnectPolicy = new FiveSecondsReconnectPolicy();
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(signalrHubUrl, { accessTokenFactory: () => this.oAuthService.getAccessToken() })
      .configureLogging(LogLevel.Information) // TODO: disable logs in production mode
      .withAutomaticReconnect(reconnectPolicy) // continuously reconnect every 5 seconds
      .build();

    this.InitHubEvents();
  };

  public startConnection = (succesCallback, errorCallback) => {
    this.hubConnection
      .start()
      .then(() => {
        if (succesCallback) {
          succesCallback();
        }
      })
      .catch((err) => {
        //TODO: alert user if needed
        if (errorCallback) {
          errorCallback();
        }
      });
  };

  private InitHubEvents() {
    const $this = this;
    this.hubConnection.onreconnected(() => {});
    this.hubConnection.onclose(() => {
      // TODO: alert user  if needed after signalr deconnexion
    });

    this.hubConnection.on('notifyUser', (payload: NotifyUserSignalrPayload) => {
      this.notifyUserSignalrSource.next(payload);
    });
  }

  public sendMessageToMe(message: string) {
    this.hubConnection.send('sendMessageToMe', message).then(() => {});
  }

  public stop() {
    this.hubConnection.stop();
  }
}
