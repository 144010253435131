import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as async from 'async';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';
import { DataService } from 'src/app/core/services/data.service';
import { Global_Class } from 'src/app/shared/models/global';
import { iAdminService } from 'src/app/core/services/iAdmin.service';
import { IContactService } from 'src/app/core/services/i-contact.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-participants-change',
  templateUrl: './participants-change.component.html',
  styleUrls: ['./participants-change.component.scss'],
})
export class ParticipantsChangeComponent implements OnInit {
  public header = '';
  public actualParticipant = null;
  public roles = [];
  public signatures = [];
  public contacts = [];
  public globalElement: Global_Class;

  public form: FormGroup = new FormGroup({
    signatureId: new FormControl(),
    roleId: new FormControl(),
    sousTri: new FormControl(),
    groupePolitiqueId: new FormControl(),
    pasDeDroitDeVote: new FormControl(),
    invite: new FormControl(),
    absent: new FormControl(),
    absentType: new FormControl(), //Radiobuttons d'absence
    contactId: new FormControl(null),
    contactIdiAdmin: new FormControl(null),
    ordrePreseance: new FormControl(null),
    absentSeance: new FormControl(),
    absentDebutSeance: new FormControl(),
  });

  public forselection = [
    // TODO compléter la méthode
    {
      label: this.translate.instant('btn.deleteParticipants'),
      tooltip: this.translate.instant('btn.deleteParticipants'),
      click: (selection) => {},
    },
  ];

  public tabItems = [
    { header: 'Contacts', value: 'contacts', valueBadge: 0 },
    { header: 'Utilisateurs', value: 'users', valueBadge: 0 },
  ];
  public selectedTabs: any = null;
  public users = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public translate: TranslateService,
    public api: ApiService,
    public dataService: DataService,
    public apiAdmin: iAdminService,
    public iContactService: IContactService,
    public spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.header = this.config.data.header;
    this.actualParticipant = this.config.data.participant;
    async.waterfall(
      [
        (callback) => {
          this.dataService.getGlobalElements.subscribe((res: any) => {
            this.globalElement = new Global_Class(res);
            if (this.globalElement.isLoaded()) {
              callback(null);
            }
          });
        },
        (callback) => {
          if (this.actualParticipant) {
            this.form.patchValue({
              signatureId: this.actualParticipant.signatureId,
              roleId: this.actualParticipant.roleId,
              sousTri: this.actualParticipant.sousTri,
              groupePolitiqueId: this.actualParticipant.groupePolitiqueId,
              pasDeDroitDeVote: this.actualParticipant.pasDeDroitDeVote,
              invite: this.actualParticipant.invite,
              absent: this.actualParticipant.absent === 1 || this.actualParticipant.absent === true ? true : false,
              absentType: this.actualParticipant.absentDebutSeance
                ? 'atStartMeeting'
                : this.actualParticipant.absentSeance
                  ? 'duringAllMeeting'
                  : null,
              contactId: this.actualParticipant.contactId,
              contactIdiAdmin: this.actualParticipant.contactIdiAdmin,
              absentDebutSeance: this.actualParticipant.absentDebutSeance,
              ordrePreseance: this.actualParticipant.ordrePreseance,
              absentSeance: this.actualParticipant.absentSeance,
            });
            callback();
          } else {
            const addedParticipants = this.config.data.alreadyAddedParticipants.map((x) => {
              return { contactId: x.contactId, contactIdiAdmin: x.contactIdiAdmin };
            });
            // on ne va garder que les actifs et ceux qui ne sont pas déjà ajoutés!
            this.contacts = this.config.data.contacts.filter(
              (x) => !x.nePlusUtiliser && addedParticipants.map((x) => x.contactId).indexOf(x.id) === -1
            );
            this.users = this.config.data.users.filter(
              (x) =>
                x.active && addedParticipants.map((x) => x.contactIdiAdmin).indexOf(x.contactForUser?.contactId) === -1
            );
            this.tabItems = [
              { header: 'Contacts', value: 'contacts', valueBadge: this.contacts.length },
              { header: 'Utilisateurs', value: 'users', valueBadge: this.users.length },
            ];
            this.selectedTabs = this.tabItems[0];
            callback();
          }
        },
      ],
      () => {
        this.spinner.hide();
      }
    );
  }

  public changeCheckbox(event) {
    if (event.checked) {
      this.form.patchValue({ absentType: 'duringAllMeeting' });
    }
  }

  public submitModificationParticipant() {
    if (this.form.value.absent) {
      if (this.form.value.absentType === 'atStartMeeting') {
        this.form.patchValue({ absentDebutSeance: true, absentSeance: false });
      } else if (this.form.value.absentType === 'duringAllMeeting') {
        this.form.patchValue({ absentSeance: true, absentDebutSeance: false });
      }
    } else {
      this.form.patchValue({ absent: false, absentDebutSeance: false, absentSeance: false });
    }
    delete this.form.value.absentType;
    this.ref.close({ form: this.form.value, actualParticipant: this.actualParticipant });
  }

  switchTabs(e) {
    this.form.patchValue({ contactId: null, contactIdiAdmin: null });
    this.selectedTabs = e;
  }
}
