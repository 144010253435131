<section class="meetings-details h-100">
  <as-split direction="horizontal" [dir]="'ltr'">
    <as-split-area class="ninja-content-primary" [size]="30">
      <div class="mr-4">
        <super-title
          [text]="'sidebar.points' | translate"
          level="1"
          sub=""
          [hasSubtitle]="true"
          [buttons]="buttons"></super-title>
        <div
          class="blockButtonSearch d-flex align-items-center justify-content-between tac-search-field w-100 mt-"
          *ngIf="activeModeSearch !== 'serviceSearch' && advancedSearchDatas === null">
          <div class="divForAbsolute w-100 mr-2">
            <span class="d-flex align-items-center p-input-icon-left w-100">
              <i class="pi pi-search"></i>
              <input
                #pointSearchInput
                type="text"
                class="form-control super-compos-search-field pr-5 pl-5 w-100 advSearch__field"
                [placeholder]="'sidebar.points' | translate"
                [(ngModel)]="pointSearch"
                (keydown.enter)="searchPoint()"
                (focus)="onFocusInput = true"
                (blur)="onFocusInput = false"/>
            </span>
            <div class="buttonAbsolute">
              <div class="blockButtonDropDown">
                <super-button
                  #searchButton
                  [text]="dropdownLabel"
                  icon="bi bi-arrows-vertical"
                  iconPosition="right"
                  styleClass="gray tac-nowrap overwirteClassButton advSearch__btn tac-dropdown-search dropdownPosition marginForAbsolute"
                  (click)="showOverlayDropdown = !showOverlayDropdown; onFocusInput = !onFocusInput">
                </super-button>
                <div
                  class="dropdown-menu dropdownPosition"
                  [ngClass]="{ 'd-block': showOverlayDropdown }"
                  data-bs-auto-close="outside">
                  <a
                    class="dropdown-item"
                    data-bs-auto-close="outside"
                    *ngIf="dropdownValue !== 'Titre'"
                    (click)="onClickDropdownInput('Titre')">
                    <div class="d-flex align-items-center">
                      <span>Titre</span>
                    </div>
                  </a>
                  <a
                    class="dropdown-item"
                    data-bs-auto-close="outside"
                    *ngIf="dropdownValue !== 'Titre + Contenus'"
                    (click)="onClickDropdownInput('Titre + Contenus')">
                    <div class="d-flex align-items-center">
                      <span>Titre + Contenus</span>
                    </div>
                  </a>
                  <a
                    class="dropdown-item"
                    data-bs-auto-close="outside"
                    *ngIf="dropdownValue !== 'Id'"
                    (click)="onClickDropdownInput('Id')">
                    <div class="d-flex align-items-center">
                      <span>Id</span>
                    </div>
                  </a>
                </div>
              </div>
              <!-- <super-button
                icon="bi bi-search"
                styleClass="blue overwirteClassButton2 ml-2 tac-nowrap advSearch__btn marginForAbsolute"
                pTooltip="Rechercher"
                (click)="onSearchButtonClick()">
              </super-button> -->
            </div>
          </div>
          <p-selectButton
          class="displayByMediaQueries"
          [options]="justifyOptions"
          optionLabel="icon"
          optionValue="value"
          [(ngModel)]="currentDisposition"
          (click)="changeDisplay()">
          <ng-template let-item>
            <i [class]="item.icon"></i>
          </ng-template>
        </p-selectButton>
        </div>
        <p class="text-muted mt-2" *ngIf="onFocusInput && dropdownValue !== 'Id'">
          <i class="bi bi-info-circle"></i>
          Vous pouvez ajouter dans votre recherche <strong>&&</strong> pour faire un ET ainsi que <strong>||</strong> pour faire un OU</p>
        <div class="d-flex align-items-center justify-content-center mt-4 mb-3">
          <super-button
            [text]="'advancedSearch' | translate"
            styleClass="blue"
            (click)="openAdvancedSearch()"></super-button>
        </div>
        <div class="alert alert-primary d-flex align-items-center justify-content-between" *ngIf="searchExist">
          {{ 'info.resultBasedOnAdvancedSearch' | translate }}
          <super-button [text]="'btn.reset' | translate" styleClass="blue" (click)="reset()"></super-button>
        </div>
        <super-box-list-custom [dragEnabled]="false" [boxArray]="boxes" *ngIf="boxes.length > 0">
        </super-box-list-custom>
      </div>
    </as-split-area>
    <as-split-area [size]="70" class="ninja-content-secondary">
      <div class="ml-4 mt-4">
        <router-outlet></router-outlet>
      </div>
      <div class="d-flex align-items-center justify-content-center h-100">
        <img src="./assets/img/decoration/no-result.svg" width="800" height="800" class="img-fluid" />
      </div>
    </as-split-area>
  </as-split>
</section>
