import { Pays } from './pays';

export class LocaliteCp {
    public id: number;
    public codePostal?: string;
    public localite?: string;
    public pays?: Pays;
    city?:any;
    public autoCompleteField: string;
    public nameFr:string;
    public nameNl:string;
    public nameDe:string;
    public postalcode:string;
    public dataKey:number;
    constructor(data?: any) {
        for (const prop in data) {
            if (data.hasOwnProperty(prop)) {
                this[prop] = data[prop];
            }

        }
        if (this.postalcode) {
            this.autoCompleteField = this.postalcode + ' - ' + (this.nameFr ? this.nameFr : '');
            this.codePostal = this.postalcode;
        } else {
            this.autoCompleteField = this.codePostal + ' - ' + this.localite;
        }
        if ( this.nameFr ){
            this.city = this.nameFr;
            this.localite = this.nameFr;
        }
        this.id = +this.id;


    }
}

