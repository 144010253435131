import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-guard-confirm-modal',
  templateUrl: './guard-confirm-modal.component.html',
  styleUrls: ['./guard-confirm-modal.component.scss'],
})
export class GuardConfirmModalComponent implements OnInit {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {}

  reject() {
    this.ref.close(false);
  }

  accept() {
    this.ref.close(true);
  }
}
