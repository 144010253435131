import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[dragDropDirective]',
})
export class DragDropDirectiveDirective {
  @Output() fileDropped = new EventEmitter<any>();
  @Output() dragover = new EventEmitter<any>();
  @Output() dragleave = new EventEmitter<any>();
  @Output() dragenter = new EventEmitter<any>(); // Ajout de l'événement dragenter

  @HostBinding('class.fileOver__Background') isInside: boolean;

  constructor(private el: ElementRef) { }

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    this.preventAndStop(evt);
    this.isInside = true;
    if(!this.isInside) {
      this.dragover.emit(evt); // Émettre l'événement dragover
    }
  }

  // Dragenter listener
  @HostListener('dragenter', ['$event']) onDragEnter(evt) {
    this.preventAndStop(evt);
    this.isInside = true;
    if(!this.isInside) {
      this.dragenter.emit(evt); // Émettre l'événement dragenter
    }
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    this.preventAndStop(evt);
    this.isInside = false;
    if(this.isInside) {
      this.dragleave.emit(evt); // Émettre l'événement dragleave
    }
  }

  // Drop listener
  @HostListener('drop', ['$event']) public onDrop(evt) {
    this.preventAndStop(evt);
    this.isInside = false;
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

  // Méthode pour prévenir le comportement par défaut et stopper la propagation
  private preventAndStop(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
  }
}