import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-point-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsPointsComponent implements OnInit {
  public pointId: number;
  constructor(
    public route: ActivatedRoute,
    public toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res: any) => {
      if (res) {
        this.pointId = res.pointID;
      } else {
        this.toastr.error('Impossible de charger le point');
      }
    });
  }
}
