import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { saveAs } from 'file-saver';
import * as _ from 'underscore';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-dialog-releases-notes',
  templateUrl: './dialog-releases-notes.component.html',
  styleUrls: ['./dialog-releases-notes.component.scss'],
})
export class DialogReleasesNotesComponent implements OnInit {
  public isversionNotUpdated = false;
  public actualVersion: string = '';
  public release_notes = [];
  public urlDoc: string = '';
  public data: any = [
    {
      version: '1.7.3',
      text: [
        {
          title: 'AMÉLIORATIONS',
          content: [],
          list: [
            `La mise à jour du titre du point lorsque celui-ci est modifié.`,	
            `Problème de multiplication d’un point dans une séance qui entraine une mauvaise numérotation`
          ],
        },
        {
          title: 'CORRECTIONS',
          content: [],
          list: [
            `iScan: possibilité de supprimer une page et de pouvoir identifier manuellement le fichier grâce à l’ID du point ou de la séance, s’il y a un problème de lecture de QR Code`,
            `Possibilité d’ajouter plusieurs documents en une fois dans un point`,
            `Suppression d’une séance par le gestionnaire si le droit lui est octroyé`,  
            `Ajout d’un focus dans le menu point, qui met le curseur directement dans la zone de recherche.`,
            `Onglet du navigateur contient plus de détails pour identifier le point ou la séance `,
          ],
        },
      ],
    },{
      version: '1.7.2',
      text: [
        {
          title: 'CORRECTIONS ',
          content: [],
          list: [
            `Créer un point via 3P`,
            `Affichage des liens`,
            `Affichage liste séance`,
            `Création d’un courrier sortant`,
            `Recherche avancée dans le menu séance`,
            `Tri colonne qui ne restait pas en mémoire dans le menu séance`,
            `Filtre dans le menu point`
          ],
        },
      ],
    },{
      version: '1.7.1',
      text: [
        {
          title: 'NOUVELLES FONCTIONNALITÉS',
          content: [],
          list: [`Extrait conforme avec un droit qui permet de modifier les signatures et de les générer en lot et/ou individuellement par utilisateur (droit à activer)`
          ],
        },{
          title: 'AMÉLIORATIONS ',
          content: [],
          list: [
            `Une icone a été ajoutée dans la vue séance qui permet de visualiser directement si un point est en cours de modification par un utilisateur`
          ],
        },
        {
          title: 'CORRECTIONS ',
          content: [],
          list: [
            `Modification multiple des statuts d'un point`,
            `Envoi multiple de point en correction`,
            `Ajouter des fichiers dans une séance`,
            `Action visible dans l'onglet destinataire des points non valide pour séance`,
            `Permettre de descendre plus bas sur l'écran d'un point pour les petits écran`,
          ],
        },
      ],
    },{
      version: '1.7.0',
      text: [
        {
          title: 'NOUVELLES FONCTIONNALITÉS',
          content: [],
          list: [
            "Mise en forme des ruptures dans l'OJ et le PV",
            'Champ de fusion id du point',
            'Lien icourrier-idélibé'
          ],
        },
        {
          title: 'CORRECTIONS ',
          content: [],
          list: [
            'Recherche avancée',
            'Chargement historique',
            'Affichage des séances après avoir fait un tri sur la date de séance'
          ],
        },
      ],
    },{
      version: '1.6.0',
      text: [
        {
          title: 'NOUVELLES FONCTIONNALITÉS',
          content: [],
          list: [
            'Verrouillage de la séance entraine le verrouillage automatique des points.'
          ],
        },
        {
          title: 'AMÉLIORATIONS ',
          content: [],
          list: [
            'Tableau menu point : affichage par défaut des colonnes, redimensionner colonnes.',
            'Duplication de point : vider le statut, le type de séance, la date de séance et le type de décision + le bouton dupliquer est disponible pour chaque point dans la vue tableau des points de la séance',
            "Gestionnaire : bloquer l'ajout d'un point dans une séance clôturée",
            "Extrait de délibération : les agents ne peuvent générer d'aperçu des extraits. Ils peuvent y avoir accès dans l'onglet fichier du fichier dès que le gestionnaire l'aura générer.",
            "Création d'un point : la fenêtre du titre d'un point s'adapte à la longueur de l'objet du point.",
            'Recherche rapide dans le menu point : permet de rechercher sur des chiffres.'
          ],
        },
        {
          title: 'CORRECTIONS ',
          content: [],
          list: [
            'Vote : ordre de préséance respecté.',
            'Limiter les actions des agents dans un point.'
          ],
        },
      ],
    },{
      version: '1.5.1',
      text: [
        {
          title: 'AMÉLIORATIONS ',
          content: [],
          list: [
            "Affichage du n° ID du point + recherche sur l'ID",
            'Verrouiller la séance, verrouille les points aussi'
          ],
        },
        {
          title: 'CORRECTIONS ',
          content: [],
          list: [
            "Bouton pour importer des fichiers d'une fiche courrier dans le point fonctionne sans devoir cliquer sur modifier",
            'Bouton 3P fonctionne correctement et affiche bien les points',
            "Recherche avancée - les agents ne voient dans la liste des services que les services dont ils sont membres (je vous rassure avant ils voyaient tous les services mais n'avaient de toute façon pas de résultat sauf si un paramètre particulier était activé)",
            "Catégorie de point affiche que les points dans la catégorie sélectionnée (sera effectif demain matin car on l'a oublié dans la version de cette fin de journée)",
            'iScan - les fichiers ajoutés sont considérés comme des fichiers associés et plus des annexes',
            "iScan - les fichiers ajoutés dans le point sont bien complet au téléchargement et à l'aperçu",
            'Bouton supprimer des fichier est revenu',
            'Recherche avancée - tous les critères sont bien pris en compte',
            "Modification d'un point - toutes les données peuvent être modifiés'",
          ],
        },
      ],
    },{
      version: '1.5.0',
      text: [
        {
          title: 'NOUVELLES FONCTIONNALITÉS',
          content: [],
          list: [
            'Ajout de la recherche dans le contenu du point', 
            "Possibilité d'ajouter des ET et des OU dans la recherche du point"
          ],
        },
        {
          title: 'AMÉLIORATIONS ',
          content: [],
          list: [
            "Affichage des Agents traitant par ordre alphabétique dans la création d'un point",
            'Empêcher la modification du statut du point lorsque le point est verrouillé en écriture',
            "Suppression de la pagination du listing des points d'une séance",
          ],
        },
        {
          title: 'CORRECTIONS ',
          content: [],
          list: [
            'iScan, mauvais liens entre le fichier et le point ou la séance',
            'Redirection et tris dans le listing des séances',
            'Affichage de mauvais fichiers dans les points sans fichier'
          ],
        },
      ],
    },{
      version: '1.3.0 - 1.4.0',
      text: [
        {
          title: 'NOUVELLES FONCTIONNALITÉS',
          content: [],
          list: ['Lecteur de code QR', 'Lien avec 3P'],
        },
        {
          title: 'AMÉLIORATIONS ',
          content: [],
          list: [
            "Génération par lot : il est possible d'écraser des documents déjà générés",
            'Éditeur : couleur noir par défaut + afficher les espaces par défaut',
            "Affichage tableau séance : garde en mémoire l'ordre et l'affichage des colonnes dans l'onglet des points dans la séance et des points prévus à la séance.",
            'Gestion de service : pouvoir ajouter un/plusieurs utilisateurs dans un/plusieurs services',
            'Changement du visuel pour le choix des votes',
          ],
        },
        {
          title: 'CORRECTIONS ',
          content: [],
          list: [
            "Afficher le nom de l'utilisateur qui a verrouillé le point en écriture",
            'Enlever le bandeau de verrouillage dès que le point est déverrouillé',
            "Caractères spéciaux ne poseront plus problème pour l'export vers mandataire",
            'Ajouter un point dans la bibliothèque via le bouton action dans le point',
            "Le texte ne s'affichait plus dans la motivation… via la modification du point",
            'Empêcher de créer plusieurs fois les mêmes séances',
            "Traiter l'action dans le menu action",
            'Filtre dans les tableaux',
          ],
        },
      ],
    },{
      version: '1.2.0',
      text: [
        {
          title: 'NOUVELLES FONCTIONNALITÉS',
          content: [
            "Accès concurrent : lorsqu'une personne modifie un point, celui-ci est bloqué en édition pour les autres utilisateurs.",
          ],
          list: [],
        },
        {
          title: 'AMÉLIORATIONS',
          content: [
            'Amélioration visuelle de la vue du menu séance, de la vue tableau et de la vue liste dans la séance.',
          ],
          list: [],
        },
        {
          title: 'CORRECTIONS',
          content: [],
          list: [
            "Blocage à l'enregistrement d'une modification d'un modèle de point",
            "Blocage à l'enregistrement d'une modification d'un point",
            "Duplication du contenu d'un point",
          ],
        },
      ],
    },
  ];
  public currentVersion: string;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public dataService: DataService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.config.header = 'Notes de mise à jour';
    this.urlDoc = this.config.data.url;
    const globalConfig = JSON.parse(sessionStorage.getItem('globalConfig'));
    if (globalConfig) {
      this.currentVersion = globalConfig.currentVersion;
    }
  }

  download(file) {
    const downloadURL = URL.createObjectURL(file);
    saveAs(downloadURL, file);
  }

  save(item) {
    saveAs(this.urlDoc + '/' + item.url, item.name);
  }
}
