import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'QrCodeExplode',
})
export class QrCodeExplode implements PipeTransform {
  transform(value: string): any {
    const pattern = new RegExp('eCourrier(\\s|.)*\\*ID > (\\d*)(\\s|.)*');
    if (!pattern.test(value)) {
      return null;
    } else {
      const parts = value.split('\r\n');
      const titleDescriptionParts = parts[0].split(' - ');
      const title = titleDescriptionParts.shift();
      const description = titleDescriptionParts.join(' - ');
      const id = parseInt(parts[1].split(' > ')[1], 10);
      return { title, description, id };
    }
  }
}
