<p-header>
  <div class="modal-header super-compos-modal-header">
    <h4 class="modal-title super-compos-modal-title">
      <span class="super-compos-modal-title super-compos-modal-title-span">{{ 'seance_reporting' | translate }}</span>
    </h4>
    <div class="d-flex justify-content-end">
      <super-button
        text="{{ 'quit' | translate }}"
        (click)="dialogRef.close(null)"
        styleClass="blue mr-2"
        pTooltip="Quitter"
        tooltipZIndex="99999999"
        tooltipPosition="bottom">
      </super-button>
    </div>
  </div>
</p-header>
<div class="modal-body modal-hidden">
  <div class="row">
    <section class="success_items col-md-12" *ngIf="successItems.length > 0">
      <super-title text="{{ 'success.seanceCreated' | translate }}"></super-title>
      <super-table
        id="success_items"
        row="100"
        class="super-table"
        [columns]="columns"
        (onClickRow)="goTo($event)"
        [items]="successItems"
        [multiselect]="false"
        [enableConfirmColumn]="false"
        [paginator]="false">
      </super-table>
    </section>
    <section class="errors_items col-md-12" *ngIf="errorItems.length > 0">
      <super-title text="{{ 'existing_meeting' | translate }}"></super-title>
      <super-table
        id="errors_items"
        (onClickRow)="goTo($event)"
        row="100"
        class="super-table"
        [columns]="columns"
        [items]="errorItems"
        [multiselect]="false"
        [enableConfirmColumn]="false"
        [paginator]="false">
      </super-table>
    </section>
  </div>
</div>
