<section *ngIf="loaded; else loading">
  <section class="import-3P mt-3">
    <super-title [text]="'btn.importPoints' | translate"></super-title>
    <super-tabs-xl [tabs]="tabs" [activeIndex]="+activeTab" (activeTab)="changeTab($event)"></super-tabs-xl>
    <section class="import-3p-tocreate" *ngIf="activeTab === 0">
      <div class="alert alert-info" *ngIf="items.length === 0">{{ 'import3P.noPointUploading' | translate }}</div>
      <super-table
        #superTable
        class="super-table"
        *ngIf="items.length > 0"
        (columnSelected)="selectedChoice($event)"
        [items]="items"
        [columns]="columns"
        id="super-table-points3P"
        forSelectionManualReset="true"
        [forselection]="forSelection"
        [forselectionDefaultOption]="true"
        [multiselect]="true"
        [menu]="menu"
        (menuItemsRowClick)="receive_menu($event)"
        [height]="'calc(100vh)'">
      </super-table>
    </section>

    <section *ngIf="activeTab === 1">
      <super-text [type]="'label'">{{ 'import3P.importedPointsFromTheLast24hours' | translate }}</super-text>
      <div class="alert alert-info" *ngIf="items.length === 0">{{ 'import3P.noPointUploaded' | translate }}</div>
      <super-table
        #superTableArchive
        class="super-table"
        *ngIf="items.length > 0"
        (columnSelected)="selectedChoice($event)"
        [items]="items"
        [columns]="columnsArchive"
        id="super-table-points3P-archive"
        [multiselect]="false"
        [height]="'calc(100vh)'"
        (onClickRow)="clicRowTable($event)"></super-table>
    </section>
  </section>

  <ng-template #noRight>
    <div class="alert alert-info">{{ 'import3P.pleaseCheckYourRightsToAccessThisPage' | translate }}</div>
  </ng-template>
</section>

<ng-template #loading>
  <div class="alert alert-info">{{ 'loading...' | translate }}</div>
</ng-template>

<!-- Modal de confirmation d'archivage  -->
<p-dialog
  [header]="'import3P.archiveDocuments' | translate"
  [(visible)]="displayModalArchive"
  [modal]="true"
  [style]="{ width: '25vw' }"
  [baseZIndex]="10000"
  [draggable]="true"
  [resizable]="false">
  <p *ngIf="elementSelected?.length === 1 || elementSelected?.id" class="m-0">
    {{ 'confirm.archiveDocument' | translate }}
  </p>
  <p *ngIf="elementSelected?.length > 1" class="m-0">
    {{ 'confirm.archiveDocuments' | translate: { count: elementSelected.length } }}
  </p>
  <div class="buttonModal">
    <div class="row mt-4">
      <div class="col-md-12 d-flex align-items-center justify-content-end">
        <super-button
          class="mr-2"
          [text]="'btn.archive' | translate"
          styleClass="green"
          (click)="archive()"></super-button>
        <super-button
          [text]="'btn.cancel' | translate"
          styleClass="red"
          (click)="cancelArchive(); displayModalArchive = false"></super-button>
      </div>
    </div>
  </div>
</p-dialog>

<!-- Modal de l'import  -->
<p-dialog
  [header]="
    elementSelected?.length
      ? ('import3P.importingXDocuments' | translate: { count: elementSelected?.length })
      : ('import3P.importingOneDocument' | translate)
  "
  [(visible)]="displayModalImport"
  [modal]="true"
  [baseZIndex]="10000"
  [draggable]="true"
  [style]="{ width: '90vw' }"
  [resizable]="false">
  <form class="form" [formGroup]="form">
    <div class="row mb-3">
      <div class="col-md-12">
        <super-title [text]="getDescription()" level="3"></super-title>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-3">
        <label class="input-label d-block">{{ 'department' | translate }} *</label>
        <p-dropdown
          [showClear]="true"
          [filter]="true"
          [virtualScroll]="true"
          itemSize="30"
          [options]="globalElement?.services_LV"
          styleClass="w-100"
          formControlName="serviceId"
          appendTo="body"
          [placeholder]="'selectADepartment' | translate">
          <ng-template let-element pTemplate="item">
            <div pTooltip="{{ element?.label }}" tooltipPosition="right" class="truncateLabel">{{ element.label }}</div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="col-md-3 mb-3">
        <label class="input-label d-block">{{ 'meetingTypeLinked' | translate }} *</label>
        <p-dropdown
          [showClear]="true"
          [filter]="true"
          [virtualScroll]="true"
          itemSize="30"
          (onChange)="selectedMeetingType()"
          [options]="globalElement?.types_seances_LV"
          styleClass="w-100"
          formControlName="typeSeanceId"
          appendTo="body"
          [placeholder]="'selectAMeetingType' | translate"
          (onClear)="clearTypeSeance()">
          <ng-template let-element pTemplate="item">
            <div pTooltip="{{ element?.label }}" tooltipPosition="right" class="truncateLabel">{{ element.label }}</div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="col-md-3 mb-3">
        <label class="input-label d-block"
          >{{ 'meetingDate' | translate }} *
          <i
            class="bi bi-info-circle"
            [pTooltip]="'pleaseChooseATypeSeanceLinkedToBeAbleToSelectASeance' | translate"></i
        ></label>
        <p-dropdown
          [showClear]="true"
          [filter]="true"
          [virtualScroll]="true"
          itemSize="30"
          [disabled]="!form.value.typeSeanceId"
          [options]="globalElement?.seances"
          styleClass="w-100"
          formControlName="seanceId"
          appendTo="body"
          [placeholder]="'selectAMeeting' | translate">
          <ng-template let-element pTemplate="item">
            <div pTooltip="{{ element?.label }}" tooltipPosition="right" class="truncateLabel">{{ element.label }}</div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="col-md-3 mb-3">
        <label class="input-label d-block">{{ 'pointType' | translate }} </label>
        <p-dropdown
          [showClear]="true"
          [filter]="true"
          [virtualScroll]="true"
          itemSize="30"
          [options]="listOfTypePoint"
          styleClass="w-100"
          formControlName="typeId"
          appendTo="body"
          [placeholder]="'selectAPointType' | translate">
          <ng-template let-element pTemplate="item">
            <div pTooltip="{{ element?.label }}" tooltipPosition="right" class="truncateLabel">{{ element.label }}</div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="col-md-3 mb-3">
        <label class="input-label d-block">{{ 'status' | translate }} </label>
        <p-dropdown
          [showClear]="true"
          [filter]="true"
          [virtualScroll]="true"
          itemSize="30"
          [options]="listOfStatuts"
          styleClass="w-100"
          formControlName="statutId"
          appendTo="body"
          [placeholder]="'selectAStatus' | translate">
          <ng-template let-element pTemplate="item">
            <div pTooltip="{{ element?.label }}" tooltipPosition="right" class="truncateLabel">{{ element.label }}</div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="col-md-3 mb-3">
        <label class="input-label d-block"
          >{{ 'matter' | translate }} <span *ngIf="mandatoryPointParameters?.matiereObligatoire">*</span></label
        >
        <p-dropdown
          [showClear]="true"
          [filter]="true"
          [virtualScroll]="true"
          itemSize="30"
          [options]="listOfMatters"
          styleClass="w-100"
          formControlName="matiereId"
          appendTo="body"
          [placeholder]="'selectAMatter' | translate"
          (onChange)="changeMatter($event)">
          <ng-template let-element pTemplate="item">
            <div pTooltip="{{ element?.label }}" tooltipPosition="right" class="truncateLabel">{{ element.label }}</div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="col-md-3 mb-3">
        <label class="input-label d-block"
          >{{ 'echevinat' | translate }} <span *ngIf="mandatoryPointParameters?.echevinatObligatoire">*</span>
          <i class="bi bi-info-circle" [pTooltip]="'pleaseChooseAMatterToBeAbleToSelectAnEchevinat' | translate"></i
        ></label>
        <p-dropdown
          [showClear]="true"
          [filter]="true"
          [virtualScroll]="true"
          [disabled]="!form.value.matiereId"
          itemSize="30"
          [options]="listOfEchevinats"
          styleClass="w-100"
          formControlName="echevinatId"
          appendTo="body"
          [placeholder]="
            !form.value.matiereId ? ('noEchevinatSelectable' | translate) : ('selectAnEchevinat' | translate)
          ">
          <ng-template let-element pTemplate="item">
            <div pTooltip="{{ element?.label }}" tooltipPosition="right" class="truncateLabel">{{ element.label }}</div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="col-md-3 mb-3" *ngIf="mandatoryPointParameters?.origineObligatoire">
        <label class="input-label d-block">{{ 'origin' | translate }} </label>
        <input type="text" pInputText formControlName="origine" class="w-100" />
      </div>
      <div class="col-md-3 mb-3" *ngIf="mandatoryPointParameters?.typeDecisionObligatoire">
        <label class="input-label d-block">{{ 'decisionType' | translate }} </label>
        <p-dropdown
          [virtualScroll]="true"
          itemSize="30"
          formControlName="typeDecisionId"
          [options]="globalElement?.decision_type_LV"
          styleClass="w-100"
          [placeholder]="'selectADecisionType' | translate"
          appendTo="body"
          [showClear]="true"
          [filter]="true">
          <ng-template let-item pTemplate="item">
            <div class="d-flex align-items-center justify-content-between">
              <div class="truncateLabel">{{ item.label }}</div>
              <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="col-md-3 mb-3" *ngIf="mandatoryPointParameters?.urgenceObligatoire">
        <label class="input-label d-block">{{ 'emergency' | translate }} </label>
        <p-dropdown
          [virtualScroll]="true"
          itemSize="30"
          formControlName="urgenceNiveauId"
          [options]="globalElement?.urgence_level_LV"
          styleClass="w-100"
          [placeholder]="'selectAnEmergency' | translate"
          appendTo="body"
          [showClear]="true"
          [filter]="true">
          <ng-template let-item pTemplate="item">
            <div class="d-flex align-items-center justify-content-between">
              <div class="truncateLabel">{{ item.label }}</div>
              <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="col-12" *ngIf="mandatoryPointParameters?.classementObligatoire">
        <label class="input-label d-block">{{ 'code' | translate }} *</label>
        <p-dropdown
          formControlName="classementId"
          [virtualScroll]="true"
          itemSize="50"
          [options]="listOfCodesActives | sort: 'label' : 'ASC'"
          styleClass="w-100"
          [placeholder]="'selectACode' | translate"
          appendTo="body"
          [showClear]="true"
          [filter]="true">
          <ng-template let-item pTemplate="item">
            <div class="d-flex align-items-center justify-content-between">
              <div class="truncateLabel">{{ item.label }}</div>
              <div><i class="fas fa-info-circle" [pTooltip]="item.label"></i></div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12 d-flex align-items-center justify-content-end">
        <super-button
          class="mr-2"
          [text]="'btn.import' | translate"
          styleClass="green"
          (click)="submitImport()"></super-button>
        <super-button [text]="'btn.cancel' | translate" styleClass="red" (click)="cancelImport()"></super-button>
      </div>
    </div>
  </form>
</p-dialog>
