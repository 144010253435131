import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CardDescription } from '@devinforius/super-compos/lib/models/scan';
import { ApiService } from 'src/app/core/services/api.service';
import { ITableConfig, SuperColumn } from '@devinforius/super-compos';
import { DataService } from 'src/app/core/services/data.service';
import { Global_Class } from 'src/app/shared/models/global';
import { Tabs } from '@devinforius/super-compos/lib/models/tabs';
import { Point } from 'src/app/shared/models/point';
import { Meeting } from 'src/app/shared/models/meeting';

@Component({
  selector: 'lib-search-modal-scan',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.css'],
})
export class SearchModalScanComponent implements OnInit, OnChanges {
  modalHeader = {
    title: 'Modifier ce scan',
    iconTitle: 'bi bi-pen-fill',
    showNotification: false,
    showParameters: false,
    closable: true,
  };
  modalParams = { 
    isDraggable: true,
    isResizable: true,
    isModal: true,
    style:{ height: '80vh', width: '50vw', minWidth: '50vw', maxWidth: '50vw' }
  };
  category = [{
    name: 'Point',
  },{
    name : 'Séance'
  }]
  categorySelected = this.category[0];
  dateSelected: Date = new Date();
  public columnsPoint: Array<SuperColumn> = [
    {
      field: 'id',
      header: 'id',
    },
    {
      field: 'objetSynthese',
      header: 'Titre',
    },
    {
      field: 'seanceLink.description',
      header: 'Séance',
    }
  ];
  public columnsMeeting: Array<SuperColumn> = [
    {
      field: 'description',
      header: 'Nom',
    },
    {
      field: 'typeSeanceId',
      header: 'Type',
      format: (value) => {
        const type = this.globalElements?.types_seances_LV.find((x) => x.value === value);
        if (type) {
          return type.label;
        }
      },
    },
    {
      field: 'pointsInclusCount',
      header: 'Points',
      format: (value) => {
        if (value <=0 ) {
          return 0;
        }
      },
    },
  ];
  public activeTab: number = 0;
  public tabs: Array<Tabs> = [
    { title: 'Point', active: this.activeTab === 0, slug: 'Point', icon: '' },
    { title: 'Séance', active: this.activeTab === 1, slug: 'Meeting', icon: '' },
  ];
  @Input() openEditDescription: boolean = false;
  @Input() infosCard: CardDescription
  @Output() public closeModal: EventEmitter<any> = new EventEmitter();

  public dropdownValue: string = 'Titre';
  public dropdownLabel: string = 'Titre';
  public showOverlayDropdown: boolean = false;
  public onFocusInput = false;
  public pointSearch = '';
  public tableConfigurations: ITableConfig;
  public totalRecords: number = 0;
  public itemsPoint: Point[] = [];
  public itemsMeeting: Meeting[] = [];
  globalElements: Global_Class;
  public rows = 10;
  public first = 0;
  loadingPointTable: boolean = false;
  loadingMeetingTable: boolean = false;

  constructor(
    private toastr: ToastrService,
    public api: ApiService,
    private data: DataService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.data.getGlobalElements.subscribe((res: Global_Class) => {
      this.globalElements = new Global_Class(res);
    });
    this.setConfigurationTable();
    this.infosCard?.type === 'Error' ? this.categorySelected = {name: 'Point'} : this.categorySelected = {name: this.infosCard?.type};
    if(this.infosCard?.type === ('Point' || 'Séance')) {
      this.infosCard?.type === 'Point' ? this.activeTab = 0 : this.activeTab = 1;
      this.pointSearch = this.infosCard?.id.toString();
      this.dropdownValue = 'Id';
      this.dropdownLabel = 'Id';
      this.searchPoint(this.infosCard?.type);
    }
    else {
      this.activeTab = 0;
      this.dropdownValue = 'Titre';
      this.dropdownLabel = 'Titre';
    }
  }

  ngOnChanges(): void {
    this.infosCard?.type === 'Error' ? this.categorySelected = {name: 'Point'} : this.categorySelected = {name: this.infosCard?.type};
    if(this.infosCard?.type === ('Point' || 'Séance')) {
      this.infosCard?.type === 'Point' ? this.activeTab = 0 : this.activeTab = 1;
      this.pointSearch = this.infosCard?.id.toString();
      this.dropdownValue = 'Id';
      this.dropdownLabel = 'Id';
      this.searchPoint(this.infosCard?.type);
    }
    else {
      this.activeTab = 0;
      this.dropdownValue = 'Titre';
      this.dropdownLabel = 'Titre';
    }
  }

  setConfigurationTable(): void {
    this.tableConfigurations = new ITableConfig({
      pageSize: this.rows,
      pageFirst: this.first,
      lazy: true,
      totalRecords: this.totalRecords,
      showPaginator: true,
      showColumnSelector: false,
      showForSelectionRow: false,
      isRowClickable: true,
      multiselect: false,
      viewSearch: false,
      resizableColumns: true,
      reorderableColumns: true,
      rowPerPageOptions: [10, 20, 50, 100, 500],
      height: '50vh',
      tableStyle: { width: '100%'}
    });
  }

  onClickDropdownInput(type: string): void {
    this.dropdownLabel = type;
    this.dropdownValue = type;
    this.showOverlayDropdown = false;
  }

  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
  
  searchPoint(status: string, pageNumber?: number): void {
    if(status === 'Point') {
      this.loadingPointTable = true;
      const dataToSendToQuery = {
        RechercheGlobale: this.pointSearch,
        SortFields: 'objetSynthese',
        SortOrder: 'DESC',
        pageSize: this.rows,
        pageNumber: pageNumber ? pageNumber : 1,
      };
      if(this.dropdownValue === 'Id' && !Number(this.pointSearch)) {
        this.toastr.error(`La valeur saisie n'est pas un nombre`);
        this.loadingPointTable = false;
        return;
      }
      this.dropdownValue === 'Id' ? dataToSendToQuery.RechercheGlobale = `**${this.pointSearch}` : null;
      this.api.getSearchPoint(dataToSendToQuery).subscribe((res: any) => {
        this.itemsPoint = res.items;
        this.totalRecords = res.totalCount;
        this.setConfigurationTable();
        this.loadingPointTable = false;
      }, (error) => {
        this.loadingPointTable = false;
        this.toastr.error(this.translate.instant('error.occurred'));
      });
    } else if(status === 'Séance') {
      this.loadingMeetingTable = true;
      const dataToSendToQuery = {
        Date: this.formatDate(this.dateSelected),
        SortFields: 'description',
        SortOrder: 'DESC',
        countInclus: true,
        pageSize: this.rows,
        pageNumber: pageNumber ? pageNumber : 1,

      };

      this.api.getMeetingsSearch(dataToSendToQuery).subscribe((res: any) => {
        this.itemsMeeting = res.items;
        this.totalRecords = res.totalCount;
        this.setConfigurationTable();
        this.loadingMeetingTable = false;
      }, (error) => {
        this.loadingMeetingTable = false;
        this.toastr.error(this.translate.instant('error.occurred'));
      });
    }
  }

  closeDialog(event?): void {
    this.openEditDescription = false;
    this.infosCard = null;
    this.dropdownLabel = null;
    this.dropdownValue = null;
    this.pointSearch = null;
    this.itemsPoint = [];
    this.itemsMeeting = [];
    this.closeModal.emit(event);
  }

  selectData(event): void {
    this.closeDialog(event);
  }

  onLazyLoad(event, type: string): void {
    this.rows = event.rows;
    const pageNumber = event.first / event.rows + 1;
    this.searchPoint(type, pageNumber);
  }

  tabChange(index) {
    this.setConfigurationTable();
    if (index === 'Point') {
      this.activeTab = 0;
    }
    if (index === 'Meeting') {
      this.activeTab = 1;
    }
  }
}
