export class Historique {
     description:string;
     date:string;
     public user:string;
     public type:string;
     classementId: number;
     confidentiel:boolean;
     courrierInitiateur:string;
     courriersInitiateursListe:any;
     dossierClassementId:number;
     echevinat:string
     id:number;
     matiere:string;
     modifiedAt : number;
     modifiedTime: Date;
     objet:string
     objetId:number
     origine:string;
     seanceId: number;
     service:string;
     statut:string;
     typeDecision:number;
     typeHistorique:string
     typeSeance:string;
     modifiedByLabel : string
     constructor(data:any) {
         Object.assign(this, data);
     }
 
}
 